import React from 'react';
import classes from './FulfillmentDashboard.module.scss';
import Greeting from './Greeting';
import Interactions from './Interactions';
import EstimatedDeliveryDates from './EstimatedDeliveryDates';
import RecentlyViewedStudies from './RecentlyViewedStudies';
import { Container } from 'reactstrap';

const FulfillmentDashboard = ({ commonProps }) => {
  return (
    <div className={classes.wrapper}>
      <Greeting />
      <Container className={classes.container}>
        <div>
          <Interactions />
        </div>
        <div className={classes.widgets}>
          <EstimatedDeliveryDates {...commonProps} />
          <RecentlyViewedStudies />
        </div>
      </Container>
    </div>
  );
};

export default FulfillmentDashboard;
