import React from 'react';
import classes from './TabRAReview.module.scss';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import CommentFeature from 'views/pages/DealDesk/DealDeskModal/CommentFeature';
import moment from 'moment';

export const TabRAReview = () => {
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const data = dealDesk?.data?.ra_review;

  const addressKeys = [
    'Main Partner Contact',
    'Partner Manager (PM)',
    'Tax Credit Specialist (TCS)',
    'Legal Entity Name',
    'Who is the RA being addressed to?',
    'Signatory Email',
    'Referral Agreement %',
    'Any modifications to the referral %?',
    'If so, why?',
  ];

  const showContent = (data, key) => {
    const dateFields = [
      'Date of Incorporation',
      'R&D - Estimated Delivery Date',
      'ERTC - Estimated Delivery Date',
    ];
    if (dateFields.includes(key)) {
      return moment(data[key]).format('MM/DD/YYYY');
    }
    return data[key];
  };

  return (
    <div className={classes.tabRAReview}>
      <Row>
        {addressKeys.map((key, index) => {
          //   if (!get(data, key, '')) return '';

          return (
            <Col md={12} key={index}>
              <div className={classes.label}>
                {key}{' '}
                <CommentFeature
                  field={`ra_review.${key}`}
                  label={key}
                  value={showContent(data, key)}
                />
              </div>
              <div className={classes.value}>{data[key] ?? '-'}</div>
            </Col>
          );
        })}

        {/* {Object.keys(data)
          .filter(key =>
            [...addressKeys, 'Notes'].includes(key) ? false : true
          )
          .map((key, index) => (
            <Col md={6} key={index}>
              <div className={classes.label}>
                {key}{' '}
                <CommentFeature
                  field={`el_review.${key}`}
                  label={key}
                  value={showContent(data, key)}
                />
              </div>
              <div className={classes.value}>{showContent(data, key)}</div>
            </Col>
          ))} */}
      </Row>
      <Row>
        {/* {get(data, 'Notes') && (
          <Col md={12}>
            <div className={classes.label}>
              Notes{' '}
              <CommentFeature
                field={`el_review.Notes`}
                label="Notes"
                value={data['Notes']}
              />
            </div>
            <div
              className={classes.value}
              dangerouslySetInnerHTML={{ __html: data['Notes'] }}
            />
          </Col>
        )} */}
      </Row>
    </div>
  );
};
