import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import classes from './Modal.module.scss';

import { Modal as BaseModal } from 'reactstrap';

const Modal = props => {
  const {
    title,
    isOpen,
    toggle,
    children,
    className,
    headerClassName,
    actionButton = '',
    noHeader,
    closeButtonVariant = 'version_1',
    ...rest
  } = props;

  return (
    <BaseModal
      fade={false}
      className={cs('modal-dialog-centered', classes.root, className)}
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >
      {!noHeader && (
        <div className={cs(headerClassName, 'modal-header')}>
          <h5 className="modal-title">{title}</h5>
          <div className="d-flex align-items-center">
            {actionButton}
            <button
              aria-label="Close"
              className={cs('close', closeButtonVariant)}
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
        </div>
      )}
      {children}
    </BaseModal>
  );
};

const ModalBody = props => {
  const { className, children, ...rest } = props;

  return (
    <div className={cs('modal-body', className)} {...rest}>
      {children}
    </div>
  );
};

const ModalFooter = props => {
  const { className, children, ...rest } = props;

  return (
    <div className={cs('modal-footer', className)} {...rest}>
      {children}
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  noHeader: PropTypes.bool,
};
Modal.defaultProps = {
  noHeader: false,
};

export { ModalBody, ModalFooter };
export default Modal;
