import Request from './request';

export const getDataBarChart = async data => {
  return Request.call({
    url: '/timelogs/report/chart/bar',
    method: 'POST',
    data,
  });
};

export const getDataDoughnutChart = async data => {
  return Request.call({
    url: '/timelogs/report/chart/pie',
    method: 'POST',
    data,
  });
};

export const postDataSummary = async (data, sort, isSubgroup = false) => {
  let url = `/timelogs/report/summary?sort=${sort.replace(
    'title',
    'group_title'
  )}`;

  if (isSubgroup)
    url = `/timelogs/report/summary?sort=${sort.replace(
      'title',
      'subgroup_title'
    )}&isSubgroup=true`;

  return Request.call({
    url: url,
    method: 'POST',
    data,
  });
};

export const postDataDetail = async (data, sort, page = 1) => {
  let url = `/timelogs/report/list?sort=${sort}&page=${page}`;

  return Request.call({
    url: url,
    method: 'POST',
    data,
  });
};

export const patchBillableById = async (id, is_billable) => {
  let url = `/timelogs/${id}/billable`;

  return Request.call({
    url: url,
    method: 'PATCH',
    data: { is_billable },
  });
};

export const patchApproveById = async (id, is_approved) => {
  let url = `/timelogs/${id}/approve`;

  return Request.call({
    url: url,
    method: 'PATCH',
    data: { is_approved },
  });
};

export const postExportDetailReport = async (data, sort) => {
  let url = `/timelogs/report/download/list?sort=${sort}`;

  let filename = 'timelogs_details_report';
  if (data.date_from && data.date_to) {
    filename += `_from_${data.date_from}_to_${data.date_to}`;
  }

  filename += '.csv';

  await Request.download(
    {
      url: url,
      method: 'POST',
      data,
    },
    filename
  );
};

export const postExportSummaryReport = async (data, sort) => {
  let convertStory = data.selectedGroupIds
    ? sort.replace('title', 'group_title,subgroup_title')
    : sort.replace('title', 'group_title');

  let url = `/timelogs/report/download/summary?sort=${convertStory}`;

  let filename = 'timelogs_summary_report';
  if (data.date_from && data.date_to) {
    filename += `_from_${data.date_from}_to_${data.date_to}`;
  }

  filename += '.csv';

  await Request.download(
    {
      url: url,
      method: 'POST',
      data,
    },
    filename
  );
};
