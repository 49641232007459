import Types from 'store/types/knowledgeBaseTypes';
import get from 'lodash/get';

const initialState = {
  categories: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  allCategories: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
  },
  reOrderCategory: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  createCategory: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  articles: null,
  article: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  mostRated: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
  },
};

export default function KnowledgeBaseReducer(state = initialState, action) {
  switch (action.type) {
    case Types.REORDER_CATEGORY_INPROGRESS:
      return {
        ...state,
        reOrderCategory: {
          ...state.reOrderCategory,
          isInProgress: true,
        },
      };
    case Types.REORDER_CATEGORY_SUCCESS:
      return {
        ...state,
        reOrderCategory: {
          ...state.reOrderCategory,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.REORDER_CATEGORY_FAILURE:
      return {
        ...state,
        reOrderCategory: {
          ...state.reOrderCategory,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_CATEGORY_STATE:
      return {
        ...state,
        allCategories: {
          ...state.allCategories,
          data: action.data,
        },
      };
    case Types.CATEGORIES_LOADING:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.CATEGORIES_ERROR:
      return {
        ...state,
        categories: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.GET_ALL_CATEGORIES_LOADING:
      return {
        ...state,
        allCategories: {
          ...state.allCategories,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategories: {
          ...state.categories,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_ALL_CATEGORIES_ERROR:
      return {
        ...state,
        allCategories: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.ARTICLES_LOADING:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.category]: {
            ...get(state, `articles[${action.category}]`, null),
            loading: true,
            isError: false,
            message: '',
          },
        },
      };

    case Types.ARTICLES_SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.category]: {
            loading: false,
            isError: false,
            message: '',
            data: action.data,
            meta: action.meta,
          },
        },
      };

    case Types.ARTICLES_ERROR:
      return {
        ...state,
        articles: {
          ...state.articles,
          [action.category]: {
            loading: false,
            isError: true,
            message: action.message,
            data: [],
            meta: null,
          },
        },
      };

    case Types.ARTICLE_LOADING:
      return {
        ...state,
        article: {
          ...state.article,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.ARTICLE_SUCCESS:
      return {
        ...state,
        article: {
          ...state.article,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.ARTICLE_ERROR:
      return {
        ...state,
        article: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.CREATE_CATEGORY_INPROGRESS:
      return {
        ...state,
        createCategory: {
          isInProgress: true,
        },
      };

    case Types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        allCategories: {
          ...state.categories,
          data: action.data,
        },
        createCategory: {
          isInProgress: false,
          isError: false,
          status: 0,
          message: '',
          id: action.categoryId,
        },
      };
    case Types.RESET_CREATE_CATEGORY:
      return {
        ...state,
        createCategory: initialState.createCategory,
      };
    case Types.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        createCategory: {
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.ARTICLES_MOST_LOADING:
      return {
        ...state,
        mostRated: {
          ...state.mostRated,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.ARTICLES_MOST_SUCCESS:
      return {
        ...state,
        mostRated: {
          ...state.mostRated,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.ARTICLES_MOST_ERROR:
      return {
        ...state,
        mostRated: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    default:
      return state;
  }
}
