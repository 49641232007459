/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { fetchCodatDetails } from 'store/actions/onboarding';
import { useDispatch } from 'react-redux';
import Loading from 'components/Loading';
import { syncDocument } from 'store/actions/documents';

const CodatCallback = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = queryString.parse(location.search || '');
  const { companyId } = queryParams;
  const history = useHistory();

  useEffect(() => {
    const checkCodat = async () => {
      if (companyId) {
        const status = await dispatch(fetchCodatDetails(companyId));
        let redirectUrl = localStorage.getItem('codatRedirect');
        let documentId = localStorage.getItem('documentId');
        localStorage.removeItem('codatRedirect');
        localStorage.removeItem('documentId');
        if (status) {
          await dispatch(syncDocument(documentId, 'codat', true));
        } else {
          redirectUrl = redirectUrl.replace('success=true', '');
        }
        history.push(`${redirectUrl}` || '/admin/onboarding/integration');
      }
    };
    checkCodat();
  }, [companyId, dispatch, history]);

  return (
    <div className="main-root-loading">
      <Loading />
    </div>
  );
};

export default CodatCallback;
