import React from 'react';
import SelectAsync from 'components/FormFields/SelectAsync';
import UserInfo from 'components/UserInfo';
import classes from './PeopleSelector.module.scss';

const PeopleSelector = props => {
  return (
    <SelectAsync
      {...props}
      selectorClassName={classes.selector}
      getOptionValue={option => option.name}
      getOptionLabel={option => <UserInfo info={option} />}
    />
  );
};

export default PeopleSelector;
