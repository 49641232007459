import { combineReducers } from 'redux';
import details from './details';
import attachments from './attachments';
import assignments from './assignments';
import comments from './comments';
import complexityOptions from './complexities';
import priorityOptions from './priorities';
import statusOptions from './storyStatuses';
import scoreMatrix from './scoreMatrix';
import activity from './activity';
import taskTypes from './taskTypes';

export default combineReducers({
  details,
  attachments,
  assignments,
  comments,
  complexityOptions,
  priorityOptions,
  statusOptions,
  scoreMatrix,
  activity,
  taskTypes,
});
