import Types from '../types/notifications';
import findIndex from 'lodash/findIndex';
import { get, orderBy } from 'lodash';

const initialState = {
  notificationState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
    shouldMakeCall: true,
  },
  markAllRead: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function NotificationReducers(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_NOTIFICATIONS_INPROGRESS: {
      return {
        ...state,
        notificationState: {
          ...state.notificationState,
          isInProgress: true,
        },
      };
    }
    case Types.FETCH_NOTIFICATIONS_SUCCESS: {
      let { data } = action;
      const shouldMakeCall = !(data.data.length === 0);
      let notifications = [];
      let existingData = get(state, 'notificationState.data.data');
      if (existingData) {
        notifications = existingData;
        (data.data || []).forEach(notification => {
          let index = findIndex(notifications, {
            id: notification.id,
          });
          if (index !== -1) {
            notifications[index] = notification;
          } else {
            notifications.push(notification);
          }
        });

        data.data = orderBy(notifications, ['created_at'], 'desc');
      }

      return {
        ...state,
        notificationState: {
          ...state.notificationState,
          isInProgress: false,
          status: 1,
          data: data,
          shouldMakeCall: shouldMakeCall,
        },
      };
    }
    case Types.FETCH_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notificationState: {
          ...state.notificationState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    }

    case Types.READ_NOTIFICATION_SUCCESS: {
      let { payload = {} } = action;
      const id = payload.id;

      let existingData = get(state, 'notificationState.data.data', []);
      let notifIndex = findIndex(existingData, { id });
      if (notifIndex > -1) {
        // if notification found then mark as read
        existingData[notifIndex] = {
          ...existingData[notifIndex],
          read_at: new Date().toISOString(),
        };
      }

      return {
        ...state,
        notificationState: {
          ...state.notificationState,
          data: {
            ...get(state, 'notificationState.data', {}),
            data: [...existingData],
          },
        },
      };
    }

    case Types.ADD_NEW_NOTIFICATION: {
      let { payload = {} } = action;

      let unreadCount = get(state, 'notificationState.data.count.unread', 0);
      let totalCount = get(state, 'notificationState.data.count.total', 0);
      let existingData = get(state, 'notificationState.data.data', []);
      let notifIndex = findIndex(existingData, { id: payload.id });
      if (notifIndex > -1) {
        // if it already exists then just replace data
        existingData[notifIndex] = payload;
      } else {
        // else just keep it first
        existingData.unshift(payload);
      }

      return {
        ...state,
        notificationState: {
          ...state.notificationState,
          data: {
            ...get(state, 'notificationState.data', {}),
            data: [...existingData],
            count: {
              unread: unreadCount + 1,
              total: totalCount + 1,
            },
          },
        },
      };
    }
    case Types.READ_ALL_NOTIFICATION_INPROGRESS:
      return {
        ...state,
        markAllRead: {
          ...state.markAllRead,
          isInProgress: true,
        },
      };
    case Types.READ_ALL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        markAllRead: {
          ...state.markAllRead,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.READ_ALL_NOTIFICATION_FAILURE:
      return {
        ...state,
        markAllRead: {
          ...state.markAllRead,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
