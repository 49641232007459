import Types from 'store/types/AuthTypes';
import AssignmentTypeTypes from 'store/types/assignmentTypeCustomizations';
import ProfileTypes from 'store/types/profile';
import Echo from 'laravel-echo';
import socketIoClient from 'socket.io-client';
import { store } from 'store';
import { onNotificationReceive } from 'store/actions/notifications';
import {
  logoutDeactivatedUser,
  showProfileUser,
} from 'store/actions/authActions';
import { getUserPreference } from 'store/actions/profile';
import {
  doGetRunningTimerFromSocket,
  doStopTimer,
} from 'store/actions/timerActions';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  fetchStoryComments,
  setStoryCommentData,
  updateCommentsFromSocket,
  updateTypingUsers,
} from 'store/actions/Story/comments';
import eventEmitter from './eventEmitter';
import { syncDocumentCompleted } from 'store/actions/documents';
import { updateCommentsFromSocketForCompany } from 'store/actions/partners';
import { addNewComment } from 'store/actions/dealDeskActions';
import { updateDocumentData } from 'store/actions/utility';
class Socket {
  echo;
  user;
  privateChannel;

  start(user) {
    // stop earlier socket connection if there is any before starting new socket connection
    this.stop();
    // set user
    this.user = user;

    const token = user.auth_token;
    if (!token) return false;

    const authToken = `Bearer ${token}`;
    const echoHost =
      process.env.REACT_APP_ECHO_SERVER_HOST || window.location.hostname;
    const echoPort = process.env.REACT_APP_ECHO_SERVER_PORT || '6001';

    // init echo
    this.echo = new Echo({
      auth: {
        headers: {
          Authorization: authToken,
        },
      },
      broadcaster: 'socket.io',
      host: echoHost + ':' + echoPort,
      client: socketIoClient,
    });
    this.listenNotification();
    this.listenTimer();
    this.listenBillingChanges();
    this.listenAppChanges();
    this.listenForSyncDataComplete();
  }

  listenNotification() {
    const id = this.user.id;
    this.privateChannel = this.echo.private(`user.notifications.${id}`);
    this.privateChannel.notification(data => {
      if (data.name === 'Status Update') {
        store.dispatch(updateDocumentData(data));
      }
      store.dispatch(onNotificationReceive(data));
    });
  }

  joinAndListenComments(parentType, parentId, params = {}) {
    const id = this.user.id;
    let socketPath = '';
    this.parentType = parentType;
    this.parentId = parentId;
    this.initialCallDone = false;

    if (this.parentType === 'ClosePartner') {
      socketPath = `comments.${this.parentType}`;
    } else {
      socketPath = `comments.${this.parentType}.${this.parentId}`;
    }

    this.echo
      .join(socketPath)
      .here(users => {
        //if (parentType === 'Initiative') return '';

        if (!this.initialCallDone) {
          this.initialCallDone = true;
          const user = users.find(u => u.user.id === id);
          if (user) {
            store.dispatch(setStoryCommentData(user.unread_comments, true));

            // fetching one more set of read comments so that we do not call the api from lazy load
            store.dispatch(
              fetchStoryComments(
                this.parentId,
                user.unread_comments && user.unread_comments.length
                  ? user.unread_comments[0].id
                  : undefined,
                true,
                this.parentType,
                params
              )
            );
          }
        }
      })
      .listen('.comments.get', async data => {
        const user = get(data, 'user', null);
        if (user && user.id !== id) {
          const event = get(data, 'event_type', '');
          const comment = get(data, 'comment', '');
          if (parentType === 'Company') {
            store.dispatch(updateCommentsFromSocketForCompany(event, comment));
          } else if (parentType === 'Initiative') {
            store.dispatch(addNewComment(comment));
          } else {
            store.dispatch(updateCommentsFromSocket(event, comment));
          }
        }
      });
  }

  /*
    As soon as user opens the modal we call this function so that user joins the
    whispering channel and receive next whisper events
   */
  listenTypingWhisper() {
    this.echo
      .private(`comments.${this.parentType}.${this.parentId}`)
      .listenForWhisper('typing', user => {
        store.dispatch(updateTypingUsers(user));
      });
  }

  /*
    This function will send the whisper event to channel and set typing to true,
    As user continuously types we are not sending that as events, we are clearing
    timeout so that after last event timeout function will be called to stop typing
   */
  sendTypingWhisper() {
    if (!this.isTyping) {
      this.echo
        .private(`comments.${this.parentType}.${this.parentId}`)
        .whisper('typing', {
          id: this.user.id,
          name: this.user.name,
          typing: true,
        });
    }
    this.isTyping = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.stopTypingWhisper(), 3000);
  }

  /*
    This function will send whisper event to channel to indicate stop typing activity.
    This function will be triggered from either timeout set in sendTypingWhisper or
    when user posts/closes the comment.
    We are checking if user typing is true then only we are sending this event so,
    we do not have number of events on other user's end.
   */
  stopTypingWhisper() {
    if (this.isTyping) {
      this.echo
        .private(`comments.${this.parentType}.${this.parentId}`)
        .whisper('typing', {
          id: this.user.id,
          name: this.user.name,
          typing: false,
        });
      this.isTyping = false;
    }
  }

  /*
    As soon as user leaves the modal we call this function so that user leaves the
    whispering channel and do not receive next whisper events
   */
  stopListenTypingWhisper() {
    this.echo
      .private(`comments.${this.parentType}.${this.parentId}`)
      .stopListeningForWhisper('typing');
  }

  leaveComments() {
    this.echo.leave(`comments.${this.parentType}.${this.parentId}`);
  }

  listenTimer() {
    const id = this.user.id;
    this.echo
      .join(`user.timer.${this.user.id}`)
      .here(users => {
        const user = users.find(item => item.user.id === id);
        if (user) {
          const runningTimer = get(user, 'timelog', null);
          store.dispatch(doGetRunningTimerFromSocket(runningTimer));
        }
      })
      .listen('.timer.started', async data => {
        const runningTimer = get(data, 'timelog', null);
        store.dispatch(doGetRunningTimerFromSocket(runningTimer));
      })
      .listen('.timer.stopped', async () => {
        await store.dispatch(doStopTimer());
      });
  }

  listenBillingChanges() {
    const companyId = get(this, 'user.company.id');
    this.echo
      .join(`app.company.${companyId}`)
      .here(_users => {})
      .listen('.company.get', async (data = {}) => {
        const state = store.getState();
        const currentSubscription =
          get(state, 'auth.user.company.subscription') || {};
        const comingSubscription = get(data, 'company.subscription', {}) || {};
        if (
          !!comingSubscription.plan_id &&
          (isEmpty(currentSubscription) ||
            comingSubscription.plan_id !== currentSubscription.plan_id)
        ) {
          // if plan is changed then emit change event
          eventEmitter.emit('subsciption-change', comingSubscription);
        }
        store.dispatch({
          type: Types.UPDATE_USER_COMPANY,
          payload: data.company,
        });
        if (get(data, 'task_types')) {
          store.dispatch({
            type: AssignmentTypeTypes.ASSIGNMENT_TYPES_CHANGE,
          });
        }
      });
  }

  listenAppChanges() {
    const id = this.user.id;
    this.echo
      .join(`app.user.${id}`)
      .here(_users => {})
      .listen('.user.get', async _data => {
        if (get(_data, 'profile'))
          store.dispatch({
            type: Types.GET_PROFILE_SUCCESS,
            user: get(_data, 'profile'),
          });
        if (get(_data, 'preferences'))
          store.dispatch({
            type: ProfileTypes.FETCH_PREFERENCE_SUCCESS,
            data: get(_data, 'preferences'),
          });
      })
      .listen('.user.deactivated', async _data => {
        // when user is deactivated we will receive the event and we will logout the user
        store.dispatch(logoutDeactivatedUser(_data));
      })
      .listen('.dataSync.completed', data => {
        store.dispatch(syncDocumentCompleted(data));
      });
  }

  stop() {
    // this method used to unregister all subscription on socket

    if (this.echo) {
      this.echo.disconnect();
      this.user = null;
    }
  }

  listenForSyncDataComplete() {
    const id = this.user.id;
    this.privateChannel = this.echo.join(`presence-app.user.${id}`);
    this.privateChannel.listen('dataSync.completed', data => {
      store.dispatch(syncDocumentCompleted(data));
    });
  }
}

export default new Socket();
