import get from 'lodash/get';
import StoryTypes from 'store/types/story';
import {
  uploadStoryAttachment as uploadStoryAttachmentApi,
  deleteStoryAttachment as deleteStoryAttachmentApi,
} from 'api/story';
import { download } from 'api/clientProfile';
import { fileDownload } from 'helpers/constants';

export const uploadStoryAttachment = (storyId, data) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.FILE_UPLOAD_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await uploadStoryAttachmentApi(storyId, data);
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      const message = get(resp, 'message');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: StoryTypes.FILE_UPLOAD_SUCCESS,
          data: responseData,
        });
      } else {
        dispatch({
          type: StoryTypes.FILE_UPLOAD_FAILURE,
          message: message,
        });
      }
      return { status, errorStatus };
    } catch (error) {
      dispatch({
        type: StoryTypes.FILE_UPLOAD_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteStoryAttachment = mediaId => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.FILE_DELETE_INPROGRESS,
      data: mediaId,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteStoryAttachmentApi(mediaId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: StoryTypes.FILE_DELETE_SUCCESS,
          data: mediaId,
        });
      } else {
        dispatch({
          type: StoryTypes.FILE_DELETE_FAILURE,
          message: message,
          data: mediaId,
        });
      }
    } catch (error) {
      dispatch({
        type: StoryTypes.FILE_DELETE_FAILURE,
        message: error,
        data: mediaId,
      });
    }
  };
};

export const downloadAttachment = doc => {
  try {
    download(doc.id).then(response => fileDownload(response, doc.name));
  } catch (error) {}
};
