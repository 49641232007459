import StoryTypes from 'store/types/story';

import {
  postAddAssignment,
  patchUpdateAssignment,
  postAddChecklist,
  putUpdateChecklist,
  deleteChecklist,
  postReOrderChecklist,
  postReOrderAssignments,
  deleteAssigment,
  postAddDependency,
  deleteDependency,
  postDuplicateAssigment,
  postUpdateChecklistStatus,
  postCompleteAssignment,
  postRestoreAssignment,
} from 'api/story';
import { postAddDeliverable, deleteDeliverable } from 'api/deliverables';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import get from 'lodash/get';

export const doPostAddAssignment = storyId => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.ADD_ASSIGNMENT_LOADING,
    });

    const resp = await postAddAssignment(storyId);

    if (resp.status === 1) {
      dispatch({
        type: StoryTypes.ADD_ASSIGNMENT_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: StoryTypes.ADD_ASSIGNMENT_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doPatchUpdateAssignment = (storyId, taskId, data) => {
  return async dispatch => {
    dispatch({
      type: StoryTypes.UPDATE_ASSIGNMENT_LOADING,
    });

    const resp = await patchUpdateAssignment(storyId, taskId, data);

    if (resp.status === 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: resp.data,
      });

      dispatch({
        type: StoryTypes.UPDATE_ASSIGNMENT_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: StoryTypes.UPDATE_ASSIGNMENT_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doAddCheckList = (taskId, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: StoryTypes.ADD_CHECKLIST_LOADING,
    });

    const resp = await postAddChecklist(taskId, data);

    if (resp.status === 1) {
      const storeData = getState();
      const selectedStory = get(storeData, 'story.details.data');
      let story = { ...selectedStory };
      let index = story.tasks.findIndex(
        item => item.id === parseInt(resp.data.id)
      );

      if (index > -1) story.tasks[index] = resp.data;

      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: story,
      });

      dispatch({
        type: StoryTypes.ADD_CHECKLIST_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: StoryTypes.ADD_CHECKLIST_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doEditCheckList = (taskId, checkListId, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: StoryTypes.ADD_CHECKLIST_LOADING,
    });

    const resp = await putUpdateChecklist(taskId, checkListId, data);

    if (resp.status === 1) {
      const storeData = getState();
      const selectedStory = get(storeData, 'story.details.data');
      let story = { ...selectedStory };
      let index = story.tasks.findIndex(
        item => item.id === parseInt(resp.data.id)
      );

      if (index > -1) story.tasks[index] = resp.data;

      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: story,
      });

      dispatch({
        type: StoryTypes.ADD_CHECKLIST_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: StoryTypes.ADD_CHECKLIST_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doDeleteCheckList = (taskId, checkListId) => {
  return async (dispatch, getState) => {
    const resp = await deleteChecklist(taskId, checkListId);

    if (resp.status === 1) {
      const storeData = getState();
      const selectedStory = get(storeData, 'story.details.data');
      let story = { ...selectedStory };
      let index = story.tasks.findIndex(
        item => item.id === parseInt(resp.data.id)
      );

      if (index > -1) story.tasks[index] = resp.data;

      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: story,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostReOrderCheckList = (taskId, data, cb = null) => {
  return async (dispatch, getState) => {
    const storeData = getState();
    const selectedStory = get(storeData, 'story.details.data');
    let story = { ...selectedStory };
    let index = story.tasks.findIndex(item => item.id === taskId);
    if (index > -1) story.tasks[index].checklists = data;
    dispatch({
      type: StoryTypes.FETCH_STORY_SUCCESS,
      data: story,
    });

    if (cb) cb();

    const resp = await postReOrderChecklist(taskId, data);

    if (resp.status !== 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: selectedStory,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostReOrderAssignments = (storyId, data, cb = null) => {
  return async (dispatch, getState) => {
    const storeData = getState();
    const selectedStory = get(storeData, 'story.details.data');
    let story = { ...selectedStory };
    story.tasks = data;

    dispatch({
      type: StoryTypes.FETCH_STORY_SUCCESS,
      data: story,
    });

    if (cb) cb();

    const resp = await postReOrderAssignments(storyId, data);

    if (resp.status !== 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: selectedStory,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doDeleteAssignment = (storyId, taskId) => {
  return async dispatch => {
    const resp = await deleteAssigment(storyId, taskId);

    if (resp.status === 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostAddDependency = (taskId, dependencyId) => {
  return async (dispatch, getState) => {
    const resp = await postAddDependency(taskId, dependencyId);

    if (resp.status === 1) {
      const storeData = getState();
      const selectedStory = get(storeData, 'story.details.data');
      let story = { ...selectedStory };
      let index = story.tasks.findIndex(item => item.id === taskId);
      if (index > -1) story.tasks[index] = resp.data;

      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: story,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doDeleteDependency = taskId => {
  return async dispatch => {
    const resp = await deleteDependency(taskId);

    if (resp.status === 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostDuplicateAssignment = (storyId, taskId) => {
  return async dispatch => {
    const resp = await postDuplicateAssigment(storyId, taskId);

    if (resp.status === 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostUpdateChecklistStatus = (taskId, checklistId, status) => {
  return async (dispatch, getState) => {
    const resp = await postUpdateChecklistStatus(taskId, checklistId, status);

    if (resp.status === 1) {
      const storeData = getState();
      const selectedStory = get(storeData, 'story.details.data');
      let story = { ...selectedStory };
      let index = story.tasks.findIndex(item => item.id === taskId);
      if (index > -1) story.tasks[index] = resp.data;

      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: story,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostCompleteAssignment = (storyId, taskId) => {
  return async dispatch => {
    const resp = await postCompleteAssignment(storyId, taskId);

    if (resp.status === 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostRestoreAssignment = (storyId, taskId) => {
  return async dispatch => {
    const resp = await postRestoreAssignment(storyId, taskId);

    if (resp.status === 1) {
      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doAddDeliverables = (taskId, data) => {
  return async (dispatch, getState) => {
    const resp = await postAddDeliverable(taskId, data);

    if (resp.status === 1) {
      const storeData = getState();
      const selectedStory = get(storeData, 'story.details.data');
      let story = { ...selectedStory };
      let index = story.tasks.findIndex(
        item => item.id === parseInt(resp.data.id)
      );

      if (index > -1) story.tasks[index] = resp.data;

      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: story,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doDeleteDeliverable = (taskId, id) => {
  return async (dispatch, getState) => {
    const resp = await deleteDeliverable(taskId, id);

    if (resp.status === 1) {
      const storeData = getState();
      const selectedStory = get(storeData, 'story.details.data');
      let story = { ...selectedStory };
      let index = story.tasks.findIndex(
        item => item.id === parseInt(resp.data.id)
      );

      if (index > -1) story.tasks[index] = resp.data;

      dispatch({
        type: StoryTypes.FETCH_STORY_SUCCESS,
        data: story,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};
