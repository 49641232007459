import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import isEqual from 'lodash/isEqual';
import useDebounce from 'react-use/lib/useDebounce';

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const AutoSaveFormik = ({ formik: { values }, onSave, miliSeconds }) => {
  const previousValues = usePrevious(values);

  useDebounce(
    () => {
      const save = () => {
        if (
          previousValues &&
          Object.keys(previousValues).length &&
          !isEqual(previousValues, values)
        ) {
          onSave(values);
        }
      };
      save();
    },
    miliSeconds,
    [onSave, previousValues, values]
  );

  return <></>;
};

AutoSaveFormik.propTypes = {
  onSave: PropTypes.func,
  miliSeconds: PropTypes.number,
};

AutoSaveFormik.defaultProps = {
  miliSeconds: 10000,
};

export default connect(AutoSaveFormik);
