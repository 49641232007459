import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailVerificationValidity } from 'store/actions/authActions';
import VerifyForm from './VerifyEmail.Form';
import InviteUsersFrom from 'views/pages/Auth/InviteUsers/InviteUsers.Form';
import CompanyDetailsForm from 'views/pages/Auth/CompanyDetails/CompanyDetails.Form.js';
// nodejs library that concatenates classes
import classnames from 'classnames';
import queryString from 'query-string';
import get from 'lodash/get';
// reactstrap components
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import classes from './VerifyEmail.module.scss';
// core components
import { useHistory } from 'react-router';
import AuthTypes from 'store/types/AuthTypes';
import analytics, { analyticsConstants } from 'helpers/analytics';

const VerifyEmail = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.authentication,
      ...rest,
    });
  };
  const [step, setStep] = useState(0);
  const [isVerifyLoading, setIsVerifyLoading] = useState(true);
  const userDetails = useSelector(({ auth }) => get(auth, 'user'));
  const isActiveSession = useSelector(({ auth }) =>
    get(auth, 'isActiveSession')
  );

  const CorrectForm = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <CompanyDetailsForm
            submitCallback={() => {
              analyticsSendEvent({
                action:
                  analyticsConstants.action.user_registration_set_company_info,
              });
              setStep(step + 1);
            }}
          />
        );
      case 2:
        return (
          <InviteUsersFrom
            submitCallback={() => {
              analyticsSendEvent({
                action: analyticsConstants.action.user_registration_invite_team,
              });
              history.push('/admin/dashboard');
            }}
          />
        );
      default:
        return (
          <VerifyForm
            submitCallback={() => {
              setStep(step + 1);
            }}
          />
        );
    }
  }, [history, step]);

  const params = queryString.parse(props.location.search);
  useEffect(() => {
    dispatch({ type: AuthTypes.SET_REGISTRATION_FLOW, data: true });
    return () => {
      dispatch({ type: AuthTypes.SET_REGISTRATION_FLOW, data: false });
    };
  }, [dispatch]);
  useEffect(() => {
    const checkInviteStatus = async () => {
      if (userDetails && isActiveSession) {
        history.push('/admin/dashboard');
      } else {
        const data = {
          email: get(params, 'email', ''),
          token: get(params, 'token', ''),
        };
        const response = await dispatch(verifyEmailVerificationValidity(data));
        if (get(response, 'status') !== 1) {
          if (get(response, 'errorStatus') === 409) {
            history.push('/admin/dashboard');
          } else {
            history.push({
              pathname: `/auth/invitations/invalid`,
              state: {
                email: data.email,
                isInvalidInvite: true,
                redirectTo: `/auth/register`,
                btnText: 'Resend Email',
                description:
                  'If you cannot find the latest link, you can resend it here:',
              },
            });
          }
        }
      }
      setIsVerifyLoading(false);
    };
    checkInviteStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVerifyLoading ? (
    <div
      className={classnames(
        'position-fixed h-100 w-100',
        classes.placeholderElement
      )}
    />
  ) : (
    <>
      <AuthHeader title="" />
      <Container className={classnames('pb-5', classes.container)}>
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className={classnames(classes.card, 'border-0')}>
              <CardBody className="px-lg-5 py-lg-5">
                <VerifyForm
                  submitCallback={() => {
                    history.push('/admin/dashboard');
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VerifyEmail;
