import PropTypes from 'prop-types';
import { useAccess } from 'helpers/permission';

const Can = props => {
  const {
    children,
    permissions,
    extraCondition,
    eitherOr,
    conditionOperator,
  } = props;

  const hasPermission = useAccess(permissions, eitherOr);

  let finalCondition = hasPermission;
  if (typeof extraCondition !== 'undefined') {
    // if we have passed extraCondition then only add it to final condition
    if (conditionOperator === 'and') {
      finalCondition = hasPermission && extraCondition;
    } else if (conditionOperator === 'or') {
      finalCondition = hasPermission || extraCondition;
    }
  }

  if (finalCondition) {
    return children;
  } else {
    return null;
  }
};

Can.prototype = {
  children: PropTypes.node,
  permissions: PropTypes.array.isRequired, // list of permissions that is required to access children
  eitherOr: PropTypes.bool, // whether all permission is required or some
  extraCondition: PropTypes.bool,
  conditionOperator: PropTypes.oneOf(['and', 'or']), // this decides how to use extraCondition with permission
};

Can.defaultProps = {
  eitherOr: true,
  conditionOperator: 'and',
};

export default Can;
