import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import PdfViewer from '../DocumentProcessor/DocumentViewer/PdfViewer';
import { previewSignedContract } from 'api/dealDeskApi';

const ViewContract = () => {
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [document, setDocument] = useState({});

  useEffect(() => {
    const getData = async token => {
      const doc = await previewSignedContract(queryProps.token);
      setDocument(doc);
    };

    getData();
  }, [queryProps.token]);

  return (
    <>
      <div>
        {document && document.url && (
          <PdfViewer url={document.url} showDownload={true}></PdfViewer>
        )}
      </div>
    </>
  );
};

export default ViewContract;
