import React, { useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
import classes from '../StoryAssignments.module.scss';

import cs from 'classnames';
import CheckBox from 'components/FormFields/CheckBox';
import useClickAway from 'react-use/lib/useClickAway';
import Input from 'components/FormFields/Input';
import {
  doEditCheckList,
  doDeleteCheckList,
  doPostUpdateChecklistStatus,
} from 'store/actions/Story/assignments';
import { useDispatch } from 'react-redux';
import ContentWithoutEditor from 'components/ContentWithoutEditor';
import { analyticsConstants } from 'helpers/analytics';

const CheckListItem = ({
  data,
  taskId,
  callback,
  provided,
  isEditAllowed,
  isOwner,
  isDependency,
  completedAt,
  analyticsSendEvent,
}) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState('');
  const [description, setDesc] = useState(data.description);
  const ref = useRef(null);

  const handleUpdateDescription = async () => {
    if (description === data.description) return false;

    await dispatch(
      doEditCheckList(taskId, data.id, {
        description: description,
      })
    );
    callback();
  };

  const handleDeleteDescription = async () => {
    analyticsSendEvent({
      action: analyticsConstants.action.remove_assignment_checklist_item,
    });
    await dispatch(doDeleteCheckList(taskId, data.id));
    callback();
  };

  useClickAway(ref, () => {
    setEdit('');

    if (description.trim() !== '' && edit === data.id) {
      handleUpdateDescription();
    } else if (description.trim() === '') {
      handleDeleteDescription();
    }
  });

  useEffect(() => {
    setDesc(data.description);
  }, [data]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      setEdit('');
      handleUpdateDescription();
    }
  };

  const handleChangeStatus = async e => {
    if (isDependency) return;
    if (isEditAllowed || isOwner) {
      const status = e.target.checked;
      analyticsSendEvent({
        action:
          analyticsConstants.action[
            `${status ? '' : 'un'}mark_assignment_checklist_item`
          ],
      });
      await dispatch(doPostUpdateChecklistStatus(taskId, data.id, status));
      callback();
    }
  };

  const renderCheckListEdit = () => {
    return (
      <>
        <div className="d-flex align-items-center w-100">
          <CheckBox
            id={`description-${data.id}`}
            name={`description-${data.id}`}
            className={classes.checkbox}
            checked={data.status}
            onChange={handleChangeStatus}
          />
          <Input
            autoFocus
            onKeyDown={handleKeyDown}
            value={description}
            onChange={e => {
              setDesc(e.target.value);
            }}
          />
          <i
            className={cs('fas fa-trash', classes.deleteIconCheckList)}
            onClick={() => handleDeleteDescription()}
          />
        </div>
      </>
    );
  };

  const renderCheckList = () => {
    return (
      <>
        <div className="d-flex align-items-start">
          {isEditAllowed && (
            <i
              {...provided.dragHandleProps}
              className={cs('fas fa-grip-vertical', classes.dragIconCheckList)}
            />
          )}

          <CheckBox
            id={`description-${data.id}`}
            name={`description-${data.id}`}
            className={classes.checkbox}
            inputClassName={cs({
              [classes.noPointer]: isOwner === false,
            })}
            checked={data.status}
            onChange={handleChangeStatus}
            disabled={isOwner === false || completedAt}
          />
        </div>
        <div
          className={cs(classes.description, {
            [classes.noPointer]: isEditAllowed === false || completedAt,
          })}
          onClick={() => {
            if (!isEditAllowed || completedAt) return;
            setEdit(data.id);
          }}
        >
          <ContentWithoutEditor>{data.description}</ContentWithoutEditor>
        </div>
      </>
    );
  };

  return (
    <div
      className={cs(classes.CheckListItemContainer, {
        [classes.noHover]:
          (isEditAllowed === false && isOwner === false) || completedAt,
      })}
      ref={ref}
    >
      {edit ? renderCheckListEdit() : renderCheckList()}
    </div>
  );
};

CheckListItem.propTypes = {};
CheckListItem.defaultProps = {};

export default CheckListItem;
