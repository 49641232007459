import Types from 'store/types/company';

const initialState = {
  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  updateDetails: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function ComponentReducers(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_COMPANY_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: true,
        },
      };
    case Types.FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_COMPANY_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_COMPANY_INPROGRESS:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          isInProgress: true,
        },
      };
    case Types.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          isInProgress: false,
          status: 1,
        },
        details: {
          ...state.details,
          data: action.data,
        },
      };
    case Types.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_COMPANY_METADATA_INPROGRESS: {
      const { type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          isInProgress: true,
        },
      };
    }
    case Types.FETCH_COMPANY_METADATA_SUCCESS: {
      const { type, data } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          isInProgress: false,
          status: 1,
          data,
        },
      };
    }
    case Types.FETCH_COMPANY_METADATA_FAILURE: {
      const { type, message } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          isInProgress: false,
          isError: true,
          message,
        },
      };
    }
    default:
      return state;
  }
}
