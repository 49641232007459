import RSelect from 'components/FormFields/RSelect';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import classes from './Messages.module.scss';
import { useDispatch } from 'react-redux';
import { getDashboardMessages } from 'store/actions/userDashboard';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import DateRangePicker from 'components/FormFields/DateRangePicker';

const TYPE = [
  { value: '', label: 'All' },
  { value: 'Company', label: 'General Messages' },
  { value: 'Initiative', label: 'Study Messages' },
  { value: 'DocumentType', label: 'Document Bucket Messages' },
];

const MessageFilter = ({ handleSetFilters, hasMoreThanOneStudy = false }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    // company: null,
    study: null,
    from: null,
    type: null,
    date: null,
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = useState();
  const handleSelect = (name, select) => {
    setFilters(prev => ({ ...prev, [name]: select }));
  };

  useEffect(() => {
    const { study = null, from = null, type = null, date = null } = filters;

    const params = {
      ...(study?.id
        ? { initiative_id: study?.id, parent_type: 'Initiative' }
        : {}),
      ...(type?.value ? { parent_type: type?.value } : {}),
      ...(from?.id ? { from: from?.id } : {}),
      ...(startDate && endDate
        ? {
            from_date: startDate.format('YYYY-MM-DD'),
            to_date: endDate.format('YYYY-MM-DD'),
          }
        : {}),
    };
    const fetchMessages = async () => {
      await dispatch(
        getDashboardMessages('list', params, {
          page: 1,
        })
      );
    };
    fetchMessages();
    handleSetFilters(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dateRange]);

  return (
    <div
      className={cx(classes['filterWrapper'], {
        [classes['three-column']]: !hasMoreThanOneStudy,
      })}
    >
      <div className={classes.label}>
        <p>Filter by:</p>
      </div>
      <div
        className={cx(classes['filters'], {
          [classes['three-column']]: !hasMoreThanOneStudy,
        })}
      >
        {/* <RSelect
          name="company"
          className="mb-0"
          value={filters.company}
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          placeholder="Company"
          options={dummyOptions}
          onChange={selectedOption => {
            handleSelect('company', selectedOption);
          }}
        /> */}

        {hasMoreThanOneStudy && (
          <RSelectAsync
            url="/initiatives/list/dropdown"
            value={filters.study}
            getOptionLabel={option => option.name}
            placeholder="Select Study"
            onChange={selectedOption => {
              handleSelect('study', selectedOption);
            }}
          />
        )}
        <RSelectAsync
          url="/users"
          value={filters.from}
          getOptionLabel={option => option.name}
          placeholder="From"
          onChange={selectedOption => {
            handleSelect('from', selectedOption);
          }}
          isClearable={true}
        />
        <RSelect
          name="type"
          className="mb-0"
          value={filters.type}
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          placeholder="Type"
          options={TYPE}
          onChange={selectedOption => {
            handleSelect('type', selectedOption);
          }}
        />
        <DateRangePicker
          placeholder="Date"
          showIcon={false}
          startDate={startDate}
          datePickerClass={classes.datePicker}
          endDate={endDate}
          value={dateRange}
          onChange={dates => {
            if (dates.startDate && dates.endDate) {
              setStartDate(dates.startDate);
              setEndDate(dates.endDate);
              const rangeDate =
                dates.startDate.format('YYYY-MM-DD') +
                ' - ' +
                dates.endDate.format('YYYY-MM-DD');
              setDateRange(rangeDate);
            } else {
              setDateRange(dateRange);
            }
          }}
        />
      </div>
    </div>
  );
};

export default MessageFilter;
