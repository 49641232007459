import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_TIMEZONES_INPROGRESS',
  'FETCH_TIMEZONES_SUCCESS',
  'FETCH_TIMEZONES_FAILURE',
  'FETCH_INDUSTRIES_INPROGRESS',
  'FETCH_INDUSTRIES_SUCCESS',
  'FETCH_INDUSTRIES_FAILURE',
  'FETCH_ACCOUNT_TYPES_INPROGRESS',
  'FETCH_ACCOUNT_TYPES_SUCCESS',
  'FETCH_ACCOUNT_TYPES_FAILURE',
  'FETCH_COMPANY_SIZE_INPROGRESS',
  'FETCH_COMPANY_SIZE_SUCCESS',
  'FETCH_COMPANY_SIZE_FAILURE',
  'FETCH_LEAD_SOURCES_INPROGRESS',
  'FETCH_LEAD_SOURCES_SUCCESS',
  'FETCH_LEAD_SOURCES_FAILURE',
  'FETCH_YEARS_LIST_INPROGRESS',
  'FETCH_YEARS_LIST_SUCCESS',
  'FETCH_YEARS_LIST_FAILURE',
  'FETCH_CONTACT_TERMS_INPROGRESS',
  'FETCH_CONTACT_TERMS_SUCCESS',
  'FETCH_CONTACT_TERMS_FAILURE',
  'FETCH_QUARTERS_INPROGRESS',
  'FETCH_QUARTERS_SUCCESS',
  'FETCH_QUARTERS_FAILURE',
  'FETCH_CONTROLLER_GROUP_FILLING_TYPES_INPROGRESS',
  'FETCH_CONTROLLER_GROUP_FILLING_TYPES_SUCCESS',
  'FETCH_CONTROLLER_GROUP_FILLING_TYPES_FAILURE',
  'FETCH_ENITIY_TYPES_INPROGRESS',
  'FETCH_ENTITY_TYPES_SUCCESS',
  'FETCH_ENTITY_TYPES_FAILURE',
  'FETCH_ACCOUNT_LEVELS_INPROGRESS',
  'FETCH_ACCOUNT_LEVELS_SUCCESS',
  'FETCH_ACCOUNT_LEVELS_FAILURE',
  'FETCH_STUDY_PHASES_INPROGRESS',
  'FETCH_STUDY_PHASES_SUCCESS',
  'FETCH_STUDY_PHASES_FAILURE'
);
