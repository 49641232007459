import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cs from 'classnames';
import AuthHeader from 'components/Headers/AuthHeader';
import { Card, Col, Container, Row } from 'reactstrap';
import classes from './Invitation.module.scss';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import Loading from 'components/Loading';
import qs from 'query-string';
import history from 'helpers/history';
import get from 'lodash/get';
import { checkInviteStatus } from 'store/actions/authActions';

const Invitation = props => {
  const dispatch = useDispatch();
  const currentQueryString = get(props, 'location.search', '');
  const queryString = qs.parse(currentQueryString);
  const email = get(queryString, 'email', '');
  const companyId = get(queryString, 'companyId', '');
  const token = get(queryString, 'token', '');
  const userId = get(queryString, 'user_id', '');

  const getUserStatusAndRedirect = async () => {
    const resp = await dispatch(checkInviteStatus(companyId, { email, token }));
    const status = get(resp, 'status', 0);
    const userStatus = get(resp, 'data.user_status');

    /*
     As of now we don't provide functionality to create account, but keeping each case individual
     so in future we can change for specific status easily
     */
    if (userStatus === 'Pending') {
      if (status) {
        // if status pending and link is active (status 200)
        history.push(`/auth/invitations/accept-and-setup${currentQueryString}`);
      } else {
        // if status pending and link is expired (status 498)
        history.push({
          pathname: `/auth/invitations/invalid`,
          state: {
            redirectTo: `/auth/login`,
            btnText: 'Login',
            description: 'You can login to your existing account here',
          },
        });
      }
    } else if (userStatus === 'Active') {
      if (status) {
        // if status active and link is active (status 200)
        history.push(`/auth/invitations/accept${currentQueryString}`);
      } else {
        // if status active and link is expired (status 498)
        history.push({
          pathname: `/auth/invitations/invalid`,
          state: {
            redirectTo: `/auth/login`,
            btnText: 'Login',
            description: 'You can login to your existing account here',
          },
        });
      }
    } else if (userStatus === 'Deactivated') {
      if (!status) {
        // if status deactivated and link is expired (status 498)
        history.push({
          pathname: `/auth/invitations/invalid`,
          state: {
            redirectTo: `/auth/login`,
            btnText: 'Login',
            description: 'You can login to your existing account here',
          },
        });
      }
    } else if (userStatus === 'Not Found') {
      // if status not found redirecting to register
      history.push({
        pathname: `/auth/invitations/invalid`,
        state: {
          redirectTo: `/auth/login`,
          btnText: 'Login',
          description: 'You can login to your existing account here',
        },
      });
    } else {
      history.push({
        pathname: `/auth/invitations/invalid`,
        state: {
          redirectTo: `/auth/login`,
          btnText: 'Login',
          description: 'You can login to your existing account here',
        },
      });
    }
  };

  useEffect(() => {
    // document.body.classList.add('bg-default');
    getUserStatusAndRedirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-content">
      <AuthNavbar />
      <AuthHeader
        title="Almost There!"
        lead="You'll be collaborating with your team in no time"
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={cs('border-0', classes.invitation)}>
              <Loading />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Invitation;

/*
Here are the status details for user which we are using above

1) If user status is Not Found (status code = 404), redirect to default sign up page

2) If user status is Active and link is active (status code = 200), redirect to join company page

3) If user status is Active and link is expired (status code = 498), redirect to login page

4) If user status is Deactivated and link is expired (status code = 498), call api to register company / create account

5) If user status is Pending (not signed up/joined any company yet) and link is active (status code = 200), redirect to accept & set up password page

6) If user status is Pending (not signed up/joined any company yet) and link is expired (status code = 498), redirect to invalid page and then default sign up page

 */
