import Types from 'store/types/dealDeskTypes';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

const initialState = {
  detailDealDesk: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  activities: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
    links: null,
    meta: null,
  },
  listDealDesks: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
    links: null,
    meta: null,
  },
  listDealDesksReferral: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
    links: null,
    meta: null,
  },
  listDealDesksRecurringDeals: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
    links: null,
    meta: null,
  },
  listStatuses: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
  },
  listUsers: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
  },
  listDealDeskApprovedByUsers: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
  },
  contractDetails: {
    loading: false,
    isError: false,
    message: '',
    status: 0,
    data: {},
  },
  listComments: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: [],
  },
  optionList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  relatedEntity: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

export default function dealDeskReducer(state = initialState, action) {
  switch (action.type) {
    // ============= LIST DEALDESKS =========================================
    case Types.DEALDESKS_LOADING:
      return {
        ...state,
        listDealDesks: {
          ...state.listDealDesks,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESKS_SUCCESS:
      return {
        ...state,
        listDealDesks: {
          ...state.listDealDesks,
          loading: false,
          status: action.status,
          data: action.data,
          meta: action.meta,
          links: action.links,
        },
      };

    case Types.DEALDESKS_ERROR:
      return {
        ...state,
        listDealDesks: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    // ============= LIST DEALDESK REFERRALS =========================================
    case Types.DEALDESKS_REFERRAL_LOADING:
      return {
        ...state,
        listDealDesksReferral: {
          ...state.listDealDesksReferral,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESKS_REFERRAL_SUCCESS:
      return {
        ...state,
        listDealDesksReferral: {
          ...state.listDealDesksReferral,
          loading: false,
          status: action.status,
          data: action.data,
          meta: action.meta,
          links: action.links,
        },
      };

    case Types.DEALDESKS_REFERRAL_ERROR:
      return {
        ...state,
        listDealDesksReferral: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.DEALDESKS_RECURRING_DEALS_LOADING:
      return {
        ...state,
        listDealDesksRecurringDeals: {
          ...state.listDealDesksRecurringDeals,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESKS_RECURRING_DEALS_SUCCESS:
      return {
        ...state,
        listDealDesksRecurringDeals: {
          ...state.listDealDesksRecurringDeals,
          loading: false,
          status: action.status,
          data: action.data,
          meta: action.meta,
          links: action.links,
        },
      };

    case Types.DEALDESKS_RECURRING_DEALS_ERROR:
      return {
        ...state,
        listDealDesksRecurringDeals: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    // ================= DEALDESK OPTIONS ========================================

    case Types.FETCH_OPTION_LISTS_INPROGRESS:
      return {
        ...state,
        optionList: {
          ...state.optionList,
          isInProgress: true,
        },
      };
    case Types.FETCH_OPTION_LISTS_SUCCESS:
      return {
        ...state,
        optionList: {
          ...state.optionList,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_OPTION_LISTS_FAILURE:
      return {
        ...state,
        optionList: {
          ...state.optionList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    // ================= DEALDESK DETAIL ========================================

    case Types.DEALDESK_DETAIL_LOADING:
      return {
        ...state,
        detailDealDesk: {
          ...state.detailDealDesk,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESK_DETAIL_SUCCESS:
      return {
        ...state,
        detailDealDesk: {
          ...state.detailDealDesk,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DEALDESK_DETAIL_ERROR:
      return {
        ...state,
        detailDealDesk: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    // ================= DEALDESK STATUSES ========================================

    case Types.DEALDESK_STATUSES_LOADING:
      return {
        ...state,
        listStatuses: {
          ...state.listStatuses,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESK_STATUSES_SUCCESS:
      return {
        ...state,
        listStatuses: {
          ...state.listStatuses,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DEALDESK_STATUSES_ERROR:
      return {
        ...state,
        listStatuses: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    // ================= DEALDESK ACTIVITIES ========================================

    case Types.DEALDESK_DETAIL_ACTIVITIES_LOADING:
      return {
        ...state,
        activities: {
          ...state.activities,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESK_DETAIL_ACTIVITIES_SUCCESS: {
      const page = get(action, 'params.page') || 1;

      if (page === 1)
        return {
          ...state,
          activities: {
            ...state.activities,
            loading: false,
            status: action.status,
            data: action.data,
            meta: action.meta,
            links: action.links,
          },
        };

      let activities = [];
      let existingData = get(state, 'activities.data') || [];

      if (existingData) {
        activities = [...existingData];
        (action.data || []).forEach(activity => {
          let index = findIndex(activity, {
            id: activity.id,
          });
          if (index > -1) {
            activities[index] = activity;
          } else {
            activities.push(activity);
          }
        });
      }

      return {
        ...state,
        activities: {
          ...state.activities,
          loading: false,
          status: action.status,
          data: activities,
          meta: action.meta,
          links: action.links,
        },
      };
    }

    case Types.DEALDESK_DETAIL_ACTIVITIES_ERROR:
      return {
        ...state,
        activities: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    // ================= DEALDESK USERS ========================================

    case Types.DEALDESK_USERS_LOADING:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESK_USERS_SUCCESS:
      return {
        ...state,
        listUsers: {
          ...state.listUsers,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DEALDESK_USERS_ERROR:
      return {
        ...state,
        listUsers: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    //================= DEAL DESK USERS BY TYPE====================================
    case Types.DEALDESK_APPROVED_BY_USERS_LOADING:
      return {
        ...state,
        listDealDeskApprovedByUsers: {
          ...state.listDealDeskApprovedByUsers,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESK_APPROVED_BY_USERS_SUCCESS:
      return {
        ...state,
        listDealDeskApprovedByUsers: {
          ...state.listDealDeskApprovedByUsers,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DEALDESK_APPROVED_BY_USERS_ERROR:
      return {
        ...state,
        listDealDeskApprovedByUsers: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };
    // ================= DEALDESK CONTRACT ========================================

    case Types.GET_CONTRACT_DETAILS_LOADING:
      return {
        ...state,
        contractDetails: {
          ...state.contractDetails,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.GET_CONTRACT_DETAILS_SUCCESS:
      return {
        ...state,
        contractDetails: {
          ...state.contractDetails,
          loading: false,
          // status: action.status,
          data: action.data,
        },
      };

    case Types.GET_CONTRACT_DETAILS_ERROR:
      return {
        ...state,
        contractDetails: {
          ...state.contractDetails,
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    // ================= DEALDESK COMMENTS ========================================

    case Types.DEALDESK_COMMENTS_LOADING:
      return {
        ...state,
        listComments: {
          ...state.listComments,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESK_COMMENTS_SUCCESS:
      return {
        ...state,
        listComments: {
          ...state.listComments,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DEALDESK_COMMENTS_ERROR:
      return {
        ...state,
        listComments: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    // ===================  ADD NEW COMMENT FROM SOCKET ============

    case Types.DEALDESK_ADD_NEW_COMMENT: {
      const comments = [...state.listComments.data];
      comments.push(action.data);

      return {
        ...state,
        listComments: {
          ...state.listComments,
          data: comments,
        },
      };
    }

    // ===================  READ ALL COMMENT ============

    case Types.DEALDESK_READ_ALL_COMMENT: {
      const detail = { ...state.detailDealDesk.data, unread_comments: 0 };
      return {
        ...state,
        detailDealDesk: {
          ...state.detailDealDesk,
          data: {
            ...detail,
          },
        },
      };
    }
    // ================= DEALDESK Related Entity ========================================

    case Types.DEALDESK_RELATED_ENTITY_LOADING:
      return {
        ...state,
        relatedEntity: {
          ...state.relatedEntity,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.DEALDESK_RELATED_ENTITY_SUCCESS:
      return {
        ...state,
        relatedEntity: {
          ...state.relatedEntity,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DEALDESK_RELATED_ENTITY_ERROR:
      return {
        ...state,
        relatedEntity: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    default:
      return state;
  }
}
