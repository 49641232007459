import Types from 'store/types/story';

const initialState = {
  isInProgress: false,
  isError: false,
  status: 0,
  message: '',
  data: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_TASK_TYPES_INPROGRESS:
      return {
        ...state,
        isInProgress: true,
      };
    case Types.FETCH_TASK_TYPES_SUCCESS:
      return {
        ...state,
        isInProgress: false,
        status: 1,
        data: action.data,
      };
    case Types.FETCH_TASK_TYPES_FAILURE:
      return {
        ...state,
        isInProgress: false,
        isError: true,
        message: action.message,
      };
    default:
      return state;
  }
};
