import React, { useState, useRef } from 'react';
import classes from '../StoryAssignments.module.scss';
import Button from 'components/Button';
import Input from 'components/FormFields/Input';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import cs from 'classnames';
import { useDispatch } from 'react-redux';

import {
  doAddDeliverables,
  doDeleteDeliverable,
} from 'store/actions/Story/assignments';
import get from 'lodash/get';
import ContentWithoutEditor from 'components/ContentWithoutEditor';
import deleteIcon from 'assets/img/icons/delete.svg';

const Deliverables = ({ task, isOwner, reload }) => {
  const dispatch = useDispatch();
  const [newItem, setNewItem] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const deliverables = get(task, 'deliverables', []) || [];

  const submitForm = async () => {
    if (!newItem || loading) return;
    setLoading(true);
    await dispatch(doAddDeliverables(task.id, { url: newItem }));
    setLoading(false);
    setNewItem('');
    reload();
    if (inputRef.current) inputRef.current.focus();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') submitForm();
  };

  if (!isOwner && deliverables.length === 0) return '';

  return (
    <div className={classes.deliverables}>
      <div className={classes.title}>Deliverables</div>
      <ul>
        {deliverables &&
          deliverables.map(item => (
            <li key={item.id}>
              <img
                src={deleteIcon}
                alt="delete"
                className={cs('mr-1', classes.deleteIcon)}
                onClick={async () => {
                  await dispatch(doDeleteDeliverable(task.id, item.id));
                  reload();
                }}
              />
              <ContentWithoutEditor>{item.url}</ContentWithoutEditor>
            </li>
          ))}
      </ul>

      {isOwner && (
        <div className="d-flex justify-content-between w-100 align-items-center">
          <Input
            innerRef={inputRef}
            placeholder="Paste URL (GitHub, Design Link, Prototype, etc.)"
            onChange={event => setNewItem(event.target.value)}
            value={newItem}
            maxLength={255}
            onKeyDown={handleKeyDown}
          />
          <div>
            <Button
              type="button"
              outline={isEmpty(trim(newItem))}
              className={cs('btn-icon btn-icon-only rounded-circle ml-3')}
              color={isEmpty(trim(newItem)) ? '' : 'primary'}
              onClick={submitForm}
              disabled={isEmpty(trim(newItem))}
            >
              <i className="fas fa-plus" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Deliverables;
