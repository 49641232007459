import createTypes from 'redux-create-action-types';
export default createTypes(
  'FETCH_NOTES_INPROGRESS',
  'FETCH_NOTES_SUCCESS',
  'FETCH_NOTES_FAILURE',
  'ADD_NOTE_INPROGRESS',
  'ADD_NOTE_SUCCESS',
  'ADD_NOTE_FAILURE',
  'EDIT_NOTE_INPROGRESS',
  'EDIT_NOTE_SUCCESS',
  'EDIT_NOTE_FAILURE',
  'PIN_NOTE_INPROGRESS',
  'PIN_NOTE_SUCCESS',
  'PIN_NOTE_FAILURE',
  'DELETE_NOTE_INPROGRESS',
  'DELETE_NOTE_SUCCESS',
  'DELETE_NOTE_FAILURE'
);
