import get from 'lodash/get';
import Types from 'store/types/story';
import { getScoreMatrixOptions } from 'api/backlogs';

export const fetchScoreMatrix = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_SCORE_MATRIX_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getScoreMatrixOptions();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_SCORE_MATRIX_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_SCORE_MATRIX_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_SCORE_MATRIX_FAILURE,
        message: error,
      });
    }
  };
};
