import { get } from 'lodash';
import { fetchNotifications, markAllRead, markRead } from 'api/notifications';
import Types from '../types/notifications';

export const fetchNotificationsList = (timestamp, limit) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_NOTIFICATIONS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchNotifications(timestamp, limit);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_NOTIFICATIONS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_NOTIFICATIONS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_NOTIFICATIONS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const onNotificationReceive = notificationData => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_NEW_NOTIFICATION,
      payload: notificationData,
    });
  };
};

export const markReadNotification = (id, limit) => {
  return async dispatch => {
    dispatch({
      type: Types.READ_NOTIFICATION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await markRead(id);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.READ_NOTIFICATION_SUCCESS,
          payload: { id },
        });
        //when marking notifications as read refetching all notifications which we already have
        await dispatch(fetchNotificationsList(null, limit));
      } else {
        dispatch({
          type: Types.READ_NOTIFICATION_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.READ_NOTIFICATION_FAILURE,
        message: error,
      });
    }
  };
};

export const markAllNotificationRead = limit => {
  return async dispatch => {
    dispatch({
      type: Types.READ_ALL_NOTIFICATION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await markAllRead();
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.READ_ALL_NOTIFICATION_SUCCESS,
        });
        //when marking notifications as read refetching all notifications which we already have
        await dispatch(fetchNotificationsList(null, limit));
      } else {
        dispatch({
          type: Types.READ_ALL_NOTIFICATION_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.READ_ALL_NOTIFICATION_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};
