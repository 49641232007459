import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { inviteMembers } from 'store/actions/users';
// nodejs library that concatenates classes
import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
// reactstrap components
import { FormGroup } from 'reactstrap';
// core components
import AuthCardHeader from 'components/Headers/AuthCardHeader';
import Input from 'components/FormFields/Input';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import Button from 'components/Button';
// import RegisterForm from './Register.Form';
import { Link } from 'react-router-dom';

const InviteUsersForm = ({ submitCallback }) => {
  const dispatch = useDispatch();
  const inviteSchema = Yup.object().shape({
    email_addresses: Yup.array()
      .of(Yup.string().email('Email is invalid'))
      .compact(val => isEmpty(toString(val)))
      .min(1),
  });
  const [focusedField, setFocusedField] = useState();
  return (
    <>
      <AuthCardHeader
        head="Who do you want to invite to your company?"
        title="Invite the team members you would like to collaborate and build your next big idea with."
      />
      <Formik
        initialValues={{
          email_addresses: [],
        }}
        validationSchema={inviteSchema}
        enableReinitialize
        validateOnMount
        onSubmit={async (values, actions, ...rest) => {
          actions.setSubmitting(true);
          dispatch(inviteMembers({ ...values, roles: ['Project_Manager'] }))
            .then(res => {
              if (submitCallback) {
                submitCallback(res);
              }
              // history.push('/admin/dashboard');
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          values,
          touched,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setSubmitting,
          ...rest
        }) => {
          const emailAddressErrors = get(errors, 'email_addresses', []);
          return (
            <>
              {map(Array(3), (element, index) => {
                const fieldName = `email_addresses.${index}`;
                return (
                  <FormGroup
                    key={index}
                    className={classnames({
                      focused: fieldName === focusedField,
                    })}
                  >
                    <Input
                      leftIcon={<i className="fas fa-user-plus" />}
                      name={fieldName}
                      placeholder="Email Address"
                      alternative={false}
                      outlined={false}
                      value={get(values, fieldName)}
                      onChange={handleChange}
                      touched={get(touched, fieldName)}
                      error={
                        get(touched, fieldName) &&
                        isArray(emailAddressErrors) &&
                        get(errors, fieldName)
                      }
                      onFocus={e => setFocusedField(fieldName)}
                      onBlur={e => {
                        handleBlur(e);
                        setFocusedField(fieldName);
                      }}
                      autoComplete="chrome-off"
                    />
                  </FormGroup>
                );
              })}
              <div className="d-flex justify-content-between align-items-center">
                <Link className="text-light text-sm" to="/admin/dashboard">
                  I'll do this later
                </Link>
                <Button
                  size="lg"
                  disabled={!isValid}
                  color="info w-25"
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                >
                  Send
                </Button>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default InviteUsersForm;
