import React, { useState, useEffect } from 'react';

import classes from './DocumentViewer.module.scss';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import Viewer from 'react-file-viewer';
import cs from 'classnames';
import { downloadDocument } from 'store/actions/documents';
import { useDispatch, useSelector } from 'react-redux';
import { previewDocument } from 'api/documents';
import Loading from 'components/Loading';
import { ocrActivity } from 'api/utility';
import { fetchDocumentDetails } from 'store/actions/utility';
import { get } from 'lodash';
import PdfViewer from './PdfViewer';

const DocumentViewer = ({ isOpen, toggle, batchId }) => {
  const dispatch = useDispatch();

  const documentDetails = useSelector(({ utility }) =>
    get(utility, 'documentDetails', null)
  );

  const batch = get(documentDetails, 'data.data', null);

  const [fileType, setFileType] = useState('csv'); //pdf|csv
  const [base64, setBase64] = useState([]);

  const [loading, setLoading] = useState(true);
  const [documenturl, setDocumenturl] = useState([]);

  useEffect(() => {
    if (batchId) {
      dispatch(fetchDocumentDetails(batchId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchId]);

  const convertToBase64 = async url => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return null;
    }
  };

  const convertPDFsToBase64 = async documenturl => {
    try {
      const base64Urls = await Promise.all(
        documenturl.map(async doc => {
          const base64 = await convertToBase64(doc.url);
          if (base64) {
            return {
              url: fileType === 'pdf' ? doc.url : base64,
              file: doc.file,
            };
          }
        })
      );
      return base64Urls;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    if (documenturl?.length) {
      convertPDFsToBase64(documenturl)
        .then(base64Urls => {
          setBase64(base64Urls);
        })
        .catch(error => {
          setLoading(false);
          setBase64([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documenturl]);

  useEffect(() => {
    if (batch) {
      setLoading(true);
      setDocumenturl([]);
      setBase64([]);
      handleFileTypeChange('csv');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batch]);

  const handleFileTypeChange = async fileType => {
    if (loading) {
      return;
    }
    setLoading(true);
    const fetch = async () => {
      const getData = async file => {
        const rs = await previewDocument(file.id);
        if (rs.status) {
          return { url: rs.url, file: file };
        }
      };
      if (fileType === 'pdf') {
        const files = batch?.ocr;
        if (files) {
          const promises = files.map(async ({ file }) => {
            if (file?.id) {
              return await getData(file);
            }
          });
          const results = await Promise.all(promises);
          setDocumenturl(results);
        }
      } else {
        const file = batch?.file;
        if (file) {
          const res = await getData(file);
          setDocumenturl([res]);
        }
      }
    };
    await fetch();

    batch?.id &&
      ocrActivity(batch.id, {
        activity: 'DocumentViewed',
        type: fileType,
      });
  };

  const handleDownload = file => {
    dispatch(downloadDocument(file));
    ocrActivity(batch.id, {
      activity: 'DocumentDownloaded',
      type: fileType,
    });
  };

  const handleToggle = () => {
    toggle();
    setDocumenturl([]);
    setBase64([]);
  };

  const modalHeader = () => (
    <div className={cs(classes.headerWrapper)}>
      <div className={classes.header}>
        <div className={classes.content}>
          <div className={cs(classes.cursor)} onClick={handleToggle}>
            {batch?.name}
          </div>
          {/*<div className={classes.tags}>*/}
          {/*  <div className={classes.processed}>23 W2’s Processed</div>*/}
          {/*  <div className={classes.failed}>1 W2 Failed</div>*/}
          {/*</div>*/}
        </div>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleToggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className={classes.fileTypesWrapper}>
        <div
          className={cs(classes.fileTypes, {
            [classes.disable]: loading || base64.length === 0,
          })}
        >
          <div
            onClick={() => {
              setFileType('csv');
              handleFileTypeChange('csv');
              setDocumenturl([]);
              setBase64([]);
            }}
            className={cs({ [classes.active]: fileType === 'csv' })}
          >
            CSV
          </div>
          <div
            onClick={() => {
              setFileType('pdf');
              handleFileTypeChange('pdf');
              setDocumenturl([]);
              setBase64([]);
            }}
            className={cs({ [classes.active]: fileType === 'pdf' })}
          >
            PDF
          </div>
        </div>
      </div>
    </div>
  );

  const Content = ({ children }) =>
    fileType === 'pdf' ? (
      <div style={{ overflow: 'auto' }}>{children}</div>
    ) : (
      <>{children}</>
    );

  return (
    <Modal
      size="xl"
      noHeader
      isOpen={isOpen}
      toggle={handleToggle}
      className={cs(classes.storyModal, {
        [classes.pdfOverflow]: fileType !== 'pdf',
      })}
      scrollable={true}
    >
      <div className={classes.modalHeader}>{modalHeader()}</div>
      {!loading && base64.length !== 0 ? (
        <Content>
          {base64.length &&
            base64?.map((data, key) => {
              return (
                <ModalBody key={key} className={classes.modalBodyWrapper}>
                  <div className={classes.hoverBar}>
                    <div className="d-flex align-items-center">
                      <i className="fas fa-file mr-2" />
                      <div className={classes.fileName}>{data?.file?.name}</div>
                    </div>

                    <div
                      className={cs(
                        'd-flex align-items-center',
                        classes.downloadButton
                      )}
                    >
                      <i
                        className={cs('fas fa-download', classes.downloadIcon)}
                        onClick={() => handleDownload(data.file)}
                      />
                    </div>
                  </div>

                  {data?.url &&
                    (fileType === 'pdf' &&
                    data?.file?.mime_type === 'application/pdf' ? (
                      <PdfViewer key={key} url={data?.url} />
                    ) : (
                      <Viewer fileType={fileType} filePath={data.url} />
                    ))}
                </ModalBody>
              );
            })}
        </Content>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};

export default DocumentViewer;
