import React from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import get from 'lodash/get';
import Activity from 'components/Activity';
import Loading from 'components/Loading';
import classes from '../Tabs.module.scss';
import NoActivityImg from 'assets/img/theme/No_Activity.png';
import orderBy from 'lodash/orderBy';

const PartnerActivity = ({ updatedOn, createdOn, activities = [] }) => {
  const NoDataIndication = () => {
    return (
      <div
        className={cs(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.imageWrapper}>
            <img
              className={cs('m-auto w-100', classes.defaultImage)}
              src={NoActivityImg}
              alt="No Recent Activity"
            />
          </div>
          <h4
            className={cs(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            No Recent Activities
          </h4>
        </div>
      </div>
    );
  };
  if (activities.length === 0) {
    return <NoDataIndication />;
  }
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <div
        className={cs(
          'd-flex flex-column justify-content-between',
          classes.customScrollBar,
          classes.activityWrapper
        )}
      >
        {orderBy(activities, ['date_created'], 'desc').map((d, i) => (
          <Activity key={i} item={d} showFullDate />
        ))}
      </div>
      <div className={cs('mt-auto border-top', classes.dateDetails)}>
        <div className="text-light mt-3">
          <h6>Updated On: {updatedOn}</h6>
          <h6>Created On: {createdOn}</h6>
        </div>
      </div>
    </div>
  );
};

export default PartnerActivity;
