import React from 'react';
import style from './ExpenseCard.module.scss';

const ExpenseCard = props => {
  const { title, value, color, icon } = props;
  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <div className={style.title} style={{ color: color }}>
          {title}
        </div>
        <div className={style.value}>{value}</div>
      </div>
      <div className={style.icon}>{icon}</div>
    </div>
  );
};

export default ExpenseCard;
