import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormGroup } from 'reactstrap';
import RSelect from 'components/FormFields/RSelect';
import Button from 'components/Button';
import AuthCardHeader from 'components/Headers/AuthCardHeader';
import cs from 'classnames';
import classes from './CompanyDetails.module.scss';
import { getCompanyDetails, updateCompanyDetails } from 'store/actions/company';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/FormFields/Input';
import get from 'lodash/get';

const CompanyDetailsForm = ({ submitCallback }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanyDetails());
  }, [dispatch]);
  const useGetDetails = (field, defaulValue) =>
    useGetFieldFromObject('company', field, defaulValue);
  const industries = useGetDetails('company_industries.data', {});
  const industryOptions = map(industries, (value, key) => ({
    id: key,
    text: value,
  }));

  const companySizes = useGetDetails('company_sizes.data', {});
  const sizeOptions = map(companySizes, (value, key) => ({
    id: key,
    text: value,
  }));
  const [loading, setLoading] = useState(false);
  const companySchema = Yup.object().shape({
    name: Yup.string()
      .required('Company Name is required')
      .min(1, 'Name is required'),
    industry_id: Yup.string().required('Company Industry is required'),
    size_id: Yup.string().required('Company Size is required'),
  });

  const CompanyForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  }) => {
    return (
      <>
        <FormGroup className="mb-3">
          <Input
            leftIcon={<i className="fas fa-building" />}
            placeholder="Company Name"
            name="name"
            alternative={false}
            outlined={false}
            error={errors.name}
            value={values.name}
            onChange={handleChange}
            touched={touched.name}
            onBlur={handleBlur}
            className={classes.input}
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <RSelect
            placeholder="Company Industry"
            isClearable={false}
            options={industryOptions}
            value={values.industry_id}
            error={errors.industry_id}
            touched={touched.industry_id}
            leftIconClass={classes.icon}
            onBlur={() => {
              setFieldTouched('industry_id', true);
            }}
            onChange={selectedOption => {
              setFieldValue('industry_id', selectedOption);
            }}
            leftIcon={
              <i className={cs('fas fa-seedling')} aria-hidden="true" />
            }
          />
        </FormGroup>
        <FormGroup className="mb-3">
          <RSelect
            placeholder="Company Size"
            isClearable={false}
            options={sizeOptions}
            error={errors.size_id}
            value={values.size_id}
            touched={touched.size_id}
            onBlur={() => {
              setFieldTouched('size_id', true);
            }}
            onChange={selectedOption => {
              setFieldValue('size_id', selectedOption);
            }}
            leftIconClass={cs(classes.icon, classes.usersIcon)}
            leftIcon={<i className={cs('fas fa-users')} aria-hidden="true" />}
          />
        </FormGroup>
        <div className="text-center">
          <Button
            className="mt-4"
            color="info"
            type="submit"
            disabled={!isEmpty(errors)}
            loading={loading}
            onClick={handleSubmit}
          >
            Next
          </Button>
        </div>
      </>
    );
  };
  return (
    <div className={classes.form}>
      <AuthCardHeader
        head="Tell us a little bit about your company"
        title="Answering these quick questions will help us customize your Lyght experince."
      />
      <Formik
        initialValues={{
          name: '',
          industry_id: undefined,
          size_id: undefined,
        }}
        validationSchema={companySchema}
        enableReinitialize
        onSubmit={async values => {
          setLoading(true);
          dispatch(
            updateCompanyDetails({
              ...values,
              industry_id: get(values, 'industry_id.id'),
              size_id: get(values, 'size_id.id'),
            })
          )
            .then(res => {
              if (submitCallback) {
                submitCallback(res);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        {CompanyForm}
      </Formik>
    </div>
  );
};

export default CompanyDetailsForm;
