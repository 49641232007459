import Types from 'store/types/assignmentTypeCustomizations';
import map from 'lodash/map';
import { sortBy, filter } from 'lodash';

const initialState = {
  assignmentTypes: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
    typeModified: 0,
  },
  createAssignmentType: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  editAssignmentType: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  deActivateAssignmentType: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  activateAssignmentType: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  deleteAssignmentType: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
};

export default function AssignmentTypeCustomizations(
  state = initialState,
  action
) {
  switch (action.type) {
    case Types.FETCH_ASSIGNMENT_TYPES_INPROGRESS:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };
    case Types.FETCH_ASSIGNMENT_TYPES_SUCCESS:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.ASSIGNMENT_TYPES_CHANGE:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          typeModified: state.assignmentTypes.typeModified + 1,
        },
      };
    case Types.FETCH_ASSIGNMENT_TYPES_FAILURE:
      return {
        ...state,
        assignmentTypes: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.CREATE_ASSIGNMENT_TYPE_INPROGRESS:
      return {
        ...state,
        createAssignmentType: {
          ...state.createAssignmentType,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };
    case Types.CREATE_ASSIGNMENT_TYPE_SUCCESS:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          typeModified: state.assignmentTypes.typeModified + 1,
        },
        createAssignmentType: {
          ...state.createAssignmentType,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.CREATE_ASSIGNMENT_TYPE_FAILURE:
      return {
        ...state,
        createAssignmentType: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.EDIT_ASSIGNMENT_TYPE_INPROGRESS:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          data: {
            ...state.assignmentTypes.data,
            data: sortBy(
              map(state.assignmentTypes.data.data, assignmentType =>
                assignmentType.id === action.data.id
                  ? action.data
                  : assignmentType
              ),
              type => type.name
            ),
          },
        },
        editAssignmentType: {
          ...state.editAssignmentType,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };
    case Types.EDIT_ASSIGNMENT_TYPE_SUCCESS:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          typeModified: state.assignmentTypes.typeModified + 1,
        },
        editAssignmentType: {
          ...state.editAssignmentType,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.EDIT_ASSIGNMENT_TYPE_FAILURE:
      return {
        ...state,
        editAssignmentType: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.DELETE_ASSIGNMENT_TYPE_INPROGRESS:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          data: {
            ...state.assignmentTypes.data,
            data: filter(
              state.assignmentTypes.data.data,
              assignmentType => assignmentType.id !== action.data
            ),
          },
        },
        deleteAssignmentType: {
          ...state.deleteAssignmentType,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };
    case Types.DELETE_ASSIGNMENT_TYPE_SUCCESS:
      return {
        ...state,
        assignmentTypes: {
          ...state.assignmentTypes,
          typeModified: state.assignmentTypes.typeModified + 1,
        },
        deleteAssignmentType: {
          ...state.deleteAssignmentType,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.DELETE_ASSIGNMENT_TYPE_FAILURE:
      return {
        ...state,
        deleteAssignmentType: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.ACTIVATE_ASSIGNMENT_TYPE_INPROGRESS:
      return {
        ...state,
        activateAssignmentType: {
          ...state.activateAssignmentType,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };
    case Types.ACTIVATE_ASSIGNMENT_TYPE_SUCCESS:
      return {
        ...state,
        activateAssignmentType: {
          ...state.activateAssignmentType,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.ACTIVATE_ASSIGNMENT_TYPE_FAILURE:
      return {
        ...state,
        activateAssignmentType: {
          ...state.activateAssignmentType,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    case Types.DEACTIVATE_ASSIGNMENT_TYPE_INPROGRESS:
      return {
        ...state,
        deActivateAssignmentType: {
          ...state.deActivateAssignmentType,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };
    case Types.DEACTIVATE_ASSIGNMENT_TYPE_SUCCESS:
      return {
        ...state,
        deActivateAssignmentType: {
          ...state.deActivateAssignmentType,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.DEACTIVATE_ASSIGNMENT_TYPE_FAILURE:
      return {
        ...state,
        deActivateAssignmentType: {
          ...state.deActivateAssignmentType,
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
