import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classes from './DeletedStudiesMain.module.scss';
import cs from 'classnames';
import get from 'lodash/get';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { getDeletedStudies } from 'store/actions/fulfillment';

import {
  callGetListStatuses,
  callGetListUsers,
  getDealDeskApprovedByUsers,
} from 'store/actions/dealDeskActions';

import Loading from 'components/Loading';
import DeletedStudies from './DeletedStudies';

const DeletedStudiesMain = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = queryString.parse(location.search);

  const tabs = {
    submitted: 'Submitted',
    deleted: 'Deleted',
  };

  const tab = params?.tab || 'submitted'; //referrals

  useEffect(() => {
    dispatch(getDeletedStudies({ type: tab }));
  }, [dispatch, tab]);

  return (
    <>
      <Container fluid className={classes.wrapper}>
        <p className={classes.title}>Deleted Studies</p>

        <div className={classes.contentWrapper}>
          <Nav className={classes.nav} tabs>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cs(classes.navLink, {
                  [classes.active]: tab === 'submitted',
                })}
                onClick={() =>
                  history.push(`${location.pathname}?tab=submitted`)
                }
              >
                Submitted
              </NavLink>
            </NavItem>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cs(classes.navLink, {
                  [classes.active]: tab === 'deleted',
                })}
                onClick={() => history.push(`${location.pathname}?tab=deleted`)}
              >
                Deleted
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tab}>
            <TabPane tabId="submitted">
              <DeletedStudies type={tab} />
            </TabPane>
            <TabPane tabId="deleted">
              <DeletedStudies type={tab} />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </>
  );
};

export default DeletedStudiesMain;
