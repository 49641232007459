import Types from '../types/clients';

const initialState = {
  clientState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  createClient: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editClientProfile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteClient: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Client(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CLIENTS_INPROGRESS:
      return {
        ...state,
        clientState: {
          ...state.clientState,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clientState: {
          ...state.clientState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        clientState: {
          ...state.clientState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_CLIENT_INPROGRESS:
      return {
        ...state,
        createClient: {
          ...state.createClient,
          isInProgress: true,
        },
      };
    case Types.CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        createClient: {
          ...state.createClient,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_CLIENT_FAILURE:
      return {
        ...state,
        createClient: {
          ...state.createClient,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_CLIENT_INPROGRESS:
      return {
        ...state,
        deleteClient: {
          ...state.deleteClient,
          isInProgress: true,
        },
      };
    case Types.DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        deleteClient: {
          ...state.deleteClient,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_CLIENT_FAILURE:
      return {
        ...state,
        deleteClient: {
          ...state.deleteClient,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
