import { get, map, toString } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchInviteRoles,
  inviteUsersInOnboarding,
} from 'store/actions/onboarding';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  // List,
} from 'reactstrap';
import classes from './Invitation.module.scss';
import cx from 'classnames';
import TeamMemberTable from './TeamMemberTable';
import InviteTeamMemberForm from './InviteTeamMemberForm';
import AllowedAbilites from './AllowedAbilites';
import BlockedAbilities from './BlockedAbilities';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';

const Invitation = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchInviteRoles());
  }, [dispatch]);
  const [activeTab, setActiveTab] = useState(0);
  const [openInviteForRole, setOpenInviteForRole] = useState(null);
  const [updateTable, setupdateTable] = useState(false);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const roles = useSelector(state => {
    const applicableRoles = state.onboarding.invitation.data;
    return map(applicableRoles, role => {
      switch (role.name) {
        case 'Client_Administrator':
          return {
            ...role,
            Icon: <i className={cx('fas fa-user')}></i>,
            styleClass: 'administrator',
            abilities: {
              allowed: [
                'View, upload, and edit any supporting and contemporaneous documents.',
                'Collaborate with other users by leaving comments.',
                'View, upload, and edit documents only in projects assigned by the Master Collaborator.',
              ],
              denied: [
                'View, upload, or edit any financial documents such as W-2s, company’s financial reports.',
                'View, upload, or edit user information.',
                'Remove user from the portal.',
              ],
            },
          };
        case 'Client_Financial_Collaborator':
          return {
            ...role,
            Icon: (
              <div className="d-flex justify-center">
                <i className={cx('fas fa-solid fa-user textLg')}></i>
                <i
                  className={cx(
                    'fas fa-solid fa-dollar-sign align-self-start textXs mt--2 ml--1'
                  )}
                ></i>
              </div>
            ),
            styleClass: 'financialCollaborator',
            abilities: {
              allowed: [
                'View, upload, and edit any supporting and contemporaneous documents.',
                'View, upload, or edit any financial documents such as W-2s, company’s financial reports.',
                'Collaborate with other users by leaving comments.',
                'View, upload, and edit documents only in projects assigned by the Master Collaborator.',
              ],
              denied: [
                'View, upload, or edit user information.',
                'Remove user from the portal.',
              ],
            },
          };
        case 'Client_Master_Collaborator':
          return {
            ...role,
            Icon: (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <i className={cx('fas fa-solid fa-star textXxs')}></i>
                <i className={cx('fas fa-solid fa-users textXl')}></i>
              </div>
            ),
            styleClass: 'masterCollaborator',
            abilities: {
              allowed: [
                'Do anything in the portal!',
                'View, upload, and edit any financial documents such as tax returns, W-2s, general ledgers, and other sensitive financial documents.',
                'View, upload, and edit any supporting and contemporaneous project documents.',
                'View or edit user information.',
                'Invite or remove user from the portal.',
              ],
              denied: [],
            },
          };
        default:
          return role;
      }
    });
  });
  const user = useGetFieldFromObjects('auth', 'user');
  const company = useGetFieldFromObjects('auth', 'user.company');

  return (
    <Card className={cx('w-100', classes.card)}>
      <CardBody className={cx(classes.cardBody, 'p-0')}>
        <h2 className="font-weight-700">User Roles</h2>
        <p className="font-weight-400">
          It’s important to understand the details of each role so you can
          ensure you assign the correct role to your teammates.
          <br />
          Click each role to learn what access your teammates can have and send
          an invite.
        </p>
        <Nav pills justified>
          {roles.map((role, idx) => (
            <NavItem
              key={idx}
              onClick={() => toggle(idx)}
              active={activeTab === idx}
            >
              <NavLink
                active={activeTab === idx}
                className={cx(
                  'd-flex align-items-center justify-content-center h-100',
                  classes.tabBackground,
                  classes[role.styleClass],
                  classes.tab,
                  {
                    [classes.inactive]: activeTab !== idx,
                    [classes.active]: activeTab === idx,
                  }
                )}
              >
                <div className={cx('mr-1', classes.icon)}>{role.Icon}</div>
                {role.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent
          activeTab={toString(activeTab)}
          className={cx(
            classes[get(roles, [activeTab, 'styleClass'])],
            classes.rolesPermissionList,
            'pt-4'
          )}
        >
          {roles.map((role, idx) => (
            <TabPane
              tabId={toString(idx)}
              key={idx}
              tag={Container}
              fluid
              className="w-100"
            >
              <Row>
                <Col md className="pl-4">
                  <AllowedAbilites role={role} />
                </Col>
                {get(role, 'abilities.denied', []).length !== 0 && (
                  <Col md={6} className="pr-4">
                    <BlockedAbilities role={role} />
                  </Col>
                )}
              </Row>
              <Row>
                <Button
                  size="lg"
                  color="primary"
                  className="m-4 ml-auto"
                  onClick={() => {
                    setOpenInviteForRole(role);
                  }}
                >
                  Invite {role.title}
                </Button>
              </Row>
            </TabPane>
          ))}
        </TabContent>
        <InviteTeamMemberForm
          {...{ user, company }}
          currentRole={openInviteForRole}
          roles={roles}
          closeModal={() => {
            setOpenInviteForRole(null);
            setupdateTable(!updateTable);
          }}
          onSubmit={async values => {
            await dispatch(inviteUsersInOnboarding(values));
          }}
        />
        <TeamMemberTable updateTable={updateTable} />
      </CardBody>
    </Card>
  );
};

Invitation.propTypes = {};

export default Invitation;
