import Types from '../types/partnerTypes';
import get from 'lodash/get';

const initialState = {
  partnerState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  editPartnerProfile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  invitePartner: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updatePartnerTimezone: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deletePartner: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  cancelInvitation: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  resendInvitation: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  calendlyDetails: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  partnerDashboard: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  inviteClient: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  activities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  clientStudies: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  earnings: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  referralComments: {
    isInProgress: true,
    isInitialCall: false,
    isError: false,
    shouldMakeCall: true,
    status: 0,
    message: '',
    data: {},
  },
  postReferralComment: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteReferralComment: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  markCommentRead: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  typingUsers: {
    users: [],
  },
  postQuestion: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Partner(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_PARTNERS_INPROGRESS:
      return {
        ...state,
        partnerState: {
          ...state.partnerState,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        partnerState: {
          ...state.partnerState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        partnerState: {
          ...state.partnerState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.INVITE_PARTNERS_INPROGRESS:
      return {
        ...state,
        invitePartner: {
          ...state.invitePartner,
          isInProgress: true,
        },
      };
    case Types.INVITE_PARTNERS_SUCCESS:
      return {
        ...state,
        invitePartner: {
          ...state.invitePartner,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.INVITE_PARTNERS_FAILURE:
      return {
        ...state,
        invitePartner: {
          ...state.invitePartner,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_PARTNERS_INPROGRESS:
      return {
        ...state,
        editPartnerProfile: {
          ...state.editPartnerProfile,
          isInProgress: true,
        },
      };
    case Types.UPDATE_PARTNERS_SUCCESS:
      return {
        ...state,
        editPartnerProfile: {
          ...state.editPartnerProfile,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_PARTNERS_FAILURE:
      return {
        ...state,
        editPartnerProfile: {
          ...state.editPartnerProfile,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_PARTNER_TIMEZONE_INPROGRESS:
      return {
        ...state,
        updatePartnerTimezone: {
          ...state.updatePartnerTimezone,
          isInProgress: true,
        },
      };
    case Types.UPDATE_PARTNER_TIMEZONE_SUCCESS:
      return {
        ...state,
        updatePartnerTimezone: {
          ...state.updatePartnerTimezone,
          data: action.data,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_PARTNER_TIMEZONE_FAILURE:
      return {
        ...state,
        updatePartnerTimezone: {
          ...state.updatePartnerTimezone,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_PARTNERS_INPROGRESS:
      return {
        ...state,
        deletePartner: {
          ...state.deletePartner,
          isInProgress: true,
        },
      };
    case Types.DELETE_PARTNERS_SUCCESS:
      return {
        ...state,
        deletePartner: {
          ...state.deletePartner,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_PARTNERS_FAILURE:
      return {
        ...state,
        deletePartner: {
          ...state.deletePartner,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CANCEL_INVITATION_INPROGRESS:
      return {
        ...state,
        cancelInvitation: {
          ...state.cancelInvitation,
          isInProgress: true,
        },
      };
    case Types.CANCEL_INVITATION_SUCCESS:
      return {
        ...state,
        cancelInvitation: {
          ...state.cancelInvitation,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CANCEL_INVITATION_FAILURE:
      return {
        ...state,
        cancelInvitation: {
          ...state.cancelInvitation,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.RESEND_INVITATION_INPROGRESS:
      return {
        ...state,
        resendInvitation: {
          ...state.resendInvitation,
          isInProgress: true,
        },
      };
    case Types.RESEND_INVITATION_SUCCESS:
      return {
        ...state,
        resendInvitation: {
          ...state.resendInvitation,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.RESEND_INVITATION_FAILURE:
      return {
        ...state,
        resendInvitation: {
          ...state.resendInvitation,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CALENDLY_DETAILS_BY_USER_ID_INPROGRESS:
      return {
        ...state,
        calendlyDetails: {
          ...state.calendlyDetails,
          isInProgress: true,
        },
      };
    case Types.FETCH_CALENDLY_DETAILS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        calendlyDetails: {
          ...state.calendlyDetails,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_CALENDLY_DETAILS_BY_USER_ID_FAILURE:
      return {
        ...state,
        calendlyDetails: {
          ...state.calendlyDetails,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNER_DASHBOARD_BY_USER_ID_INPROGRESS:
      return {
        ...state,
        partnerDashboard: {
          ...state.partnerDashboard,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNER_DASHBOARD_BY_USER_ID_SUCCESS:
      return {
        ...state,
        partnerDashboard: {
          ...state.partnerDashboard,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PARTNER_DASHBOARD_BY_USER_ID_FAILURE:
      return {
        ...state,
        partnerDashboard: {
          ...state.partnerDashboard,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PARTNER_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: true,
        },
      };
    case Types.FETCH_PARTNER_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          data: action.data,
        },
      };
    }
    case Types.FETCH_PARTNER_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.INVITE_CLIENT_INPROGRESS:
      return {
        ...state,
        inviteClient: {
          ...state.inviteClient,
          isInProgress: true,
        },
      };
    case Types.INVITE_CLIENT_SUCCESS:
      return {
        ...state,
        inviteClient: {
          ...state.inviteClient,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.INVITE_CLIENT_FAILURE:
      return {
        ...state,
        inviteClient: {
          ...state.inviteClient,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_INPROGRESS:
      return {
        ...state,
        clientStudies: {
          ...state.clientStudies,
          isInProgress: true,
        },
      };
    case Types.FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_SUCCESS:
      return {
        ...state,
        clientStudies: {
          ...state.clientStudies,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_FAILURE:
      return {
        ...state,
        clientStudies: {
          ...state.clientStudies,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_EARNINGS_INPROGRESS:
      return {
        ...state,
        earnings: {
          ...state.earnings,
          isInProgress: true,
        },
      };
    case Types.FETCH_EARNINGS_SUCCESS:
      return {
        ...state,
        earnings: {
          ...state.earnings,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_EARNINGS_FAILURE:
      return {
        ...state,
        earnings: {
          ...state.earnings,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_INTITIAL_REFERRAL_COMMENTS_INPROGRESS:
      return {
        ...state,
        referralComments: {
          ...state.referralComments,
          isInitialCall: action.data,
        },
      };
    case Types.FETCH_REFERRAL_COMMENTS_INPROGRESS:
      return {
        ...state,
        referralComments: {
          ...state.referralComments,
          isInProgress: true,
        },
      };
    case Types.SET_REFERRAL_COMMENTS:
      return {
        ...state,
        referralComments: {
          ...state.referralComments,
          isInProgress: false,
          status: 1,
          data: {
            data: action.data.updatedComments,
          },
          ...(action.data.unreadFrom
            ? {
                unreadFrom: action.data.unreadFrom,
              }
            : {}),
        },
      };
    case Types.SET_REFERRAL_COMMENT_UNREAD_FROM:
      return {
        ...state,
        referralComments: {
          ...state.referralComments,
          unreadFrom: action.data,
        },
      };
    case Types.FETCH_REFERRAL_COMMENTS_SUCCESS:
      return {
        ...state,
        referralComments: {
          ...state.referralComments,
          isInProgress: false,
          status: 1,
          data: action.data.data,
          ...(action.data.shouldMakeCall
            ? { shouldMakeCall: action.data.shouldMakeCall }
            : {}),
        },
      };
    case Types.FETCH_MORE_REFERRAL_COMMENTS_SUCCESS:
      // eslint-disable-next-line no-case-declarations

      return {
        ...state,
        referralComments: {
          ...state.referralComments,
          data: {
            ...get(state, 'referralComments.data', {}),
            data: action.data.data,
          },
          shouldMakeCall: action.data.shouldMakeCall,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.FETCH_REFERRAL_COMMENTS_FAILURE:
      return {
        ...state,
        referralComments: {
          ...state.referralComments,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CLEAR_REFERRAL_COMMENTS:
      return {
        ...state,
        referralComments: {
          isInProgress: true,
          isError: false,
          shouldMakeCall: false,
          status: 0,
          message: '',
          data: {},
        },
      };
    case Types.POST_REFERRAL_COMMENT_INPROGRESS:
      return {
        ...state,
        postReferralComment: {
          ...state.postReferralComment,
          isInProgress: true,
        },
      };
    case Types.POST_REFERRAL_COMMENT_SUCCESS:
      return {
        ...state,
        postReferralComment: {
          ...state.postReferralComment,
          isInProgress: false,
          status: 1,
        },
        referralComments: {
          ...state.referralComments,
          data: {
            ...get(state, 'referralComments.data', {}),
            data: action.data,
          },
        },
      };
    case Types.POST_REFERRAL_COMMENT_FAILURE:
      return {
        ...state,
        postReferralComment: {
          ...state.postReferralComment,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_REFERRAL_COMMENT_INPROGRESS:
      return {
        ...state,
        deleteReferralComment: {
          ...state.deleteReferralComment,
          isInProgress: true,
        },
      };
    case Types.DELETE_REFERRAL_COMMENT_SUCCESS:
      return {
        ...state,
        deleteReferralComment: {
          ...state.deleteReferralComment,
          isInProgress: false,
          status: 1,
        },
        referralComments: {
          ...state.referralComments,
          data: {
            ...get(state, 'referralComments.data', {}),
            data: action.data,
          },
        },
      };
    case Types.DELETE_REFERRAL_COMMENT_FAILURE:
      return {
        ...state,
        deleteReferralComment: {
          ...state.deleteReferralComment,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.MARK_READ_REFERRAL_COMMENTS_INPROGRESS:
      return {
        ...state,
        markCommentRead: {
          ...state.markCommentRead,
          isInProgress: true,
        },
      };
    case Types.MARK_READ_REFERRAL_COMMENTS_SUCCESS:
      return {
        ...state,
        markCommentRead: {
          ...state.markCommentRead,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.MARK_READ_REFERRAL_COMMENTS_FAILURE:
      return {
        ...state,
        markCommentRead: {
          ...state.markCommentRead,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.SET_REFERRAL_TYPING_USERS:
      return {
        ...state,
        typingUsers: {
          ...state.typingUsers,
          users: action.data,
        },
      };
    case Types.POST_QUESTION_INPROGRESS:
      return {
        ...state,
        postQuestion: {
          ...state.postQuestion,
          isInProgress: true,
        },
      };
    case Types.POST_QUESTION_SUCCESS:
      return {
        ...state,
        postQuestion: {
          ...state.postQuestion,
          isInProgress: false,
          message: action.data,
          status: 1,
        },
      };
    case Types.POST_QUESTION_FAILURE:
      return {
        ...state,
        postQuestion: {
          ...state.postQuestion,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
