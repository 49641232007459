import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import React, { useState } from 'react';
import classes from './EditOverviewModal.module.scss';
import Button from 'components/Button';
import * as Yup from 'yup';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Form, Formik } from 'formik';
import { FormikInput } from 'components/FormFields/Input';
import DatePicker from 'components/FormFields/DatePicker';
import { editStudy } from 'store/actions/fulfillment';
import { getProject } from 'store/actions/projects';
import * as moment from 'moment';

const EditOverviewModal = ({ toggle, isOpen }) => {
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const kickOffDate = get(currentStudy, 'kick_of_date');
  const estimatedDeliveryDate = get(currentStudy, 'estimated_delivery_date');
  const finalReportReleaseDate = get(currentStudy, 'final_report_release_date');
  const firstYearIncorporated = get(
    currentStudy,
    'client.first_year_incorporated'
  );
  const firstYearRevenue = get(currentStudy, 'client.first_year_of_revenues');
  const firstYearOfRAndD = get(currentStudy, 'client.first_year_of_rd');
  const creditReleaseDate = get(currentStudy, 'crl_release_date');
  const studyCalendarType = get(currentStudy, 'study_filing_type');
  const studyDeadlineType = get(currentStudy, 'study_filing_deadline_type');

  //get(currentStudy, 'study_filing_deadline_type');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    kickOffDate: Yup.date(),
    estimatedDeliveryDate: Yup.date(),
    creditReleaseLetterDate: Yup.date(),
    finalReportReleaseDate: Yup.date(),
    studyCalendarType: Yup.string(),
    studyDeadlineType: Yup.string(),
    firstYearIncorporated: Yup.number(),
    firstYearOfRevenue: Yup.number(),
    firstYearOfRAndD: Yup.number(),
  });
  const handleSave = async values => {
    await dispatch(
      editStudy(currentStudy.id, {
        final_report_release_date: values.finalReportReleaseDate,
        study_filing_type: values.studyCalendarType,
        study_filing_deadline_type: values.studyDeadlineType,
        kick_of_date: values.kickOffDate,
        crl_release_date: values.creditReleaseLetterDate,
        first_year_of_revenues: values.firstYearOfRevenue,
        first_year_incorporated: values.firstYearIncorporated,
        first_year_of_rd: values.firstYearOfRAndD,
      })
    );
    dispatch(getProject(currentStudy.id));
    setLoading(false);
    toggle();
  };
  return (
    <Modal
      size="lg"
      scrollable
      fade={false}
      title={'Study Overview'}
      toggle={toggle}
      isOpen={isOpen}
      className={classes.modalWrapper}
      headerClassName={classes.modalHeader}
    >
      <Formik
        initialValues={{
          kickOffDate: !kickOffDate || kickOffDate == 'TBD' ? '' : kickOffDate,
          estimatedDeliveryDate:
            !estimatedDeliveryDate || estimatedDeliveryDate == 'TBD'
              ? ''
              : estimatedDeliveryDate,
          creditReleaseLetterDate:
            !creditReleaseDate || creditReleaseDate == 'TBD'
              ? ''
              : creditReleaseDate,
          finalReportReleaseDate:
            !finalReportReleaseDate || finalReportReleaseDate == 'TBD'
              ? ''
              : finalReportReleaseDate,
          studyCalendarType: studyCalendarType,
          studyDeadlineType: studyDeadlineType,
          firstYearIncorporated: firstYearIncorporated ?? '',
          firstYearOfRAndD: firstYearOfRAndD ?? '',
          firstYearOfRevenue: firstYearRevenue ?? '',
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async values => {
          await handleSave(values);
        }}
      >
        {({ values, isValid, setFieldValue }) => {
          return (
            <Form>
              <ModalBody
                className={cx(
                  'd-flex flex-row flex-wrap py-1',
                  classes.modalBody
                )}
              >
                <div className={classes.formGroup}>
                  <div className="row">
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label className={classes.label} htmlFor="kickOffDate">
                          Kick Off Date
                        </label>
                        <div className="form-group">
                          <DatePicker
                            placeholder="YYYY-MM-DD"
                            name="kickOffDate"
                            value={values.kickOffDate}
                            renderInput={(props, openCalendar) => {
                              return (
                                <div className={classes.dateWrapper}>
                                  <input {...props} />
                                  <i
                                    className={cx(
                                      'fas fa-calendar',
                                      classes.icon
                                    )}
                                    onClick={openCalendar}
                                  />
                                </div>
                              );
                            }}
                            onChange={dates => {
                              const date = moment(dates).format('YYYY-MM-DD');
                              setFieldValue('kickOffDate', date);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label
                          className={classes.label}
                          htmlFor="estimatedDeliveryDate"
                        >
                          Estimated Delivery Dates
                        </label>
                        <div className="form-group">
                          <DatePicker
                            placeholder="YYYY-MM-DD"
                            name="estimatedDeliveryDate"
                            value={values.estimatedDeliveryDate}
                            renderInput={(props, openCalendar) => {
                              return (
                                <div className={classes.dateWrapper}>
                                  <input {...props} />
                                  <i
                                    className={cx(
                                      'fas fa-calendar',
                                      classes.icon
                                    )}
                                    onClick={openCalendar}
                                  />
                                </div>
                              );
                            }}
                            onChange={dates => {
                              const date = moment(dates).format('YYYY-MM-DD');
                              setFieldValue('estimatedDeliveryDate', date);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label
                          className={classes.label}
                          htmlFor="creditReleaseLetterDate"
                        >
                          Credit Release Letter Date
                        </label>
                        <div className="form-group">
                          <DatePicker
                            placeholder="YYYY-MM-DD"
                            name="creditReleaseLetterDate"
                            value={values.creditReleaseLetterDate}
                            renderInput={(props, openCalendar) => {
                              return (
                                <div className={classes.dateWrapper}>
                                  <input {...props} />
                                  <i
                                    className={cx(
                                      'fas fa-calendar',
                                      classes.icon
                                    )}
                                    onClick={openCalendar}
                                  />
                                </div>
                              );
                            }}
                            onChange={dates => {
                              const date = moment(dates).format('YYYY-MM-DD');
                              setFieldValue('creditReleaseLetterDate', date);
                            }}
                          />
                          {/* <DatePicker
                            placeholder="MM/DD/YYYY"
                            name="creditReleaseLetterDate"
                            value={values.creditReleaseLetterDate}
                            renderInput={(props, openCalendar) => {
                              return (
                                <div className={classes.dateWrapper}>
                                  <input {...props} />
                                  <i
                                    className={cx(
                                      'fas fa-calendar',
                                      classes.icon
                                    )}
                                    onClick={openCalendar}
                                  />
                                </div>
                              );
                            }}
                            onChange={dates => {
                              const date = moment(dates).format('MM/DD/YYYY');
                              setFieldValue('creditReleaseLetterDate', date);
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label
                          className={classes.label}
                          htmlFor="finalReportReleaseDate"
                        >
                          Final Report Release Date
                        </label>
                        <div className="form-group">
                          <DatePicker
                            placeholder="YYYY-MM-DD"
                            name="finalReportReleaseDate"
                            value={values.finalReportReleaseDate}
                            renderInput={(props, openCalendar) => {
                              return (
                                <div className={classes.dateWrapper}>
                                  <input {...props} />
                                  <i
                                    className={cx(
                                      'fas fa-calendar',
                                      classes.icon
                                    )}
                                    onClick={openCalendar}
                                  />
                                </div>
                              );
                            }}
                            onChange={dates => {
                              const date = moment(dates).format('YYYY-MM-DD');
                              setFieldValue('finalReportReleaseDate', date);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label className={classes.label}>
                          Will this Study be filed by Calendar Year or Fiscal
                          Year?
                        </label>
                        <div
                          role="group"
                          className="form-group"
                          aria-labelledby="studyCalendarType"
                        >
                          <Button
                            color="primary"
                            outline
                            active={values.studyCalendarType == 'calendar'}
                            onClick={() => {
                              setFieldValue('studyCalendarType', 'calendar');
                            }}
                          >
                            Calendar
                          </Button>
                          <Button
                            color="primary"
                            outline
                            active={values.studyCalendarType == 'fiscal'}
                            onClick={() => {
                              setFieldValue('studyCalendarType', 'fiscal');
                            }}
                          >
                            Fiscal
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label className={classes.label}>
                          Will this Study be filed by the Standard or Extended
                          Deadline?
                        </label>
                        <div
                          role="group"
                          className="form-group"
                          aria-labelledby="studyDeadlineType"
                        >
                          <Button
                            color="primary"
                            outline
                            active={values.studyDeadlineType == 'standard'}
                            onClick={() => {
                              setFieldValue('studyDeadlineType', 'standard');
                            }}
                          >
                            Standard
                          </Button>
                          <Button
                            color="primary"
                            outline
                            active={values.studyDeadlineType == 'extended'}
                            onClick={() => {
                              setFieldValue('studyDeadlineType', 'extended');
                            }}
                          >
                            Extended
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label
                          className={classes.label}
                          htmlFor="firstYearIncorporated"
                        >
                          First Year Incorporated
                        </label>
                        <FormikInput
                          className={classes.input}
                          type="text"
                          name="firstYearIncorporated"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className={classes.inputWrapper}>
                        <label
                          className={classes.label}
                          htmlFor="firstYearOfRAndD"
                        >
                          First Year Of R And D
                        </label>
                        <FormikInput
                          className={classes.input}
                          type="text"
                          name="firstYearOfRAndD"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.inputWrapper}>
                    <label
                      className={classes.label}
                      htmlFor="firstYearOfRevenue"
                    >
                      First Year Of Revenue
                    </label>
                    <FormikInput
                      className={classes.input}
                      type="text"
                      name="firstYearOfRevenue"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className={classes.modalFooter}>
                <Button color="link" className={classes.back} onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  //   loading={loading}
                  disabled={!isValid}
                  color="primary"
                  className={classes.submit}
                  type="submit"
                >
                  Confirm
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditOverviewModal;
