import { get } from 'lodash';
import { fetchSupportStaff } from 'api/supportStaff';
import Types from '../types/supportStaffs';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const fetchSupportStaffList = (id, page, sort, q, params) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_SUPPORT_STAFFS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchSupportStaff(id, page, sort, q, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_SUPPORT_STAFFS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_SUPPORT_STAFFS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_SUPPORT_STAFFS_FAILURE,
        message: error,
      });
    }
  };
};
