import AuthHeader from 'components/Headers/AuthHeader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendEmailResetPassword,
  resendEmailResetPassword,
  toggleResetPasswordError,
  toggleResetPasswordSuccess,
} from 'store/actions/authActions';
import AuthCardHeader from 'components/Headers/AuthCardHeader';
// nodejs library that concatenates classes
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// reactstrap components
import {
  Card,
  CardBody,
  //   CardHeader,
  Col,
  Container,
  //   Form,
  FormGroup,
  Row,
} from 'reactstrap';
import classes from './ForgotPassword.module.scss';
// core components
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';

// import history from 'helpers/history';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [focus, setFocus] = useState({});
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();

  // useEffect(() => {
  //   dispatch(toggleResetPasswordSuccess());
  //   dispatch(toggleResetPasswordError());
  // }, [dispatch]);

  if (auth.passwordReset.isError) {
    setTimeout(() => {
      dispatch(toggleResetPasswordError());
    }, 5000);
  }

  if (auth.passwordReset.status) {
    setTimeout(() => {
      dispatch(toggleResetPasswordSuccess());
    }, 5000);
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required')
      .max(255, 'The email must be less than 255 chars'),
  });

  const handleResend = async () => {
    setLoading(true);
    await dispatch(resendEmailResetPassword(email));
    setLoading(false);
  };

  const renderForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  }) => {
    return (
      <Form>
        <FormGroup
          className={classnames(
            {
              focused: focus.email,
            },
            'mb-3'
          )}
        >
          {auth.passwordReset.isError === false &&
          auth.passwordReset.status === 1 ? (
            <div className={classes.message}>
              Check your email for the reset password link. If you did not
              receive the link, you can request another below.
            </div>
          ) : (
            <Input
              name="email"
              placeholder="Email"
              type="text"
              error={errors.email}
              value={values.email}
              onChange={handleChange}
              touched={touched.email}
              onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
              onBlur={e => {
                handleBlur(e);
                setFocus({ ...focus, [e.target.name]: false });
              }}
              leftIcon={<i className="ni ni-email-83" />}
            />
          )}
        </FormGroup>
        <div className="text-center">
          {auth.passwordReset.isError === false &&
          auth.passwordReset.status === 1 ? (
            <Button
              className="mt-4"
              color="primary"
              block
              loading={loading}
              onClick={handleResend}
            >
              Resend Link
            </Button>
          ) : (
            <Button
              className="mt-4"
              color="primary"
              type="submit"
              block
              loading={loading}
            >
              Send Link
            </Button>
          )}
        </div>
      </Form>
    );
  };

  return (
    <>
      <AuthHeader title="" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={classes.card}>
              <CardBody className={classes.cardBody}>
                <AuthCardHeader
                  head="Reset Password"
                  title={
                    auth.passwordReset.status === 1
                      ? ''
                      : `Enter your email below to receive a password reset link.`
                  }
                />
                {auth.passwordReset.isError === true && (
                  <div className={classes.alert}>
                    <Alert
                      fade
                      dismiss
                      text={auth.passwordReset.message}
                      color="danger"
                      toggle={() => dispatch(toggleResetPasswordError())}
                    />
                  </div>
                )}

                {auth.passwordReset.isError === false &&
                  auth.passwordReset.status === 1 && (
                    <div className={classes.alert}>
                      <Alert
                        fade
                        dismiss
                        text={auth.passwordReset.message}
                        color="success"
                        toggle={() => dispatch(toggleResetPasswordSuccess())}
                        icon={<i className="fas fa-check-circle" />}
                      />
                    </div>
                  )}
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={schema}
                  enableReinitialize
                  onSubmit={async (values, actions) => {
                    setLoading(true);
                    setEmail(values.email);
                    await dispatch(sendEmailResetPassword(values.email));
                    actions.resetForm();
                    setLoading(false);
                  }}
                >
                  {renderForm}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
