import Types from 'store/types/story';

const initalState = {
  addAssignment: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  updateAssignment: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  addCheckList: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case Types.ADD_ASSIGNMENT_LOADING:
      return {
        ...state,
        addAssignment: {
          ...state.addAssignment,
          loading: true,
          isError: false,
          message: '',
        },
      };
    case Types.ADD_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        addAssignment: {
          ...state.addAssignment,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.ADD_ASSIGNMENT_ERROR:
      return {
        ...state,
        addAssignment: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_ASSIGNMENT_LOADING:
      return {
        ...state,
        updateAssignment: {
          ...state.updateAssignment,
          loading: true,
          isError: false,
          message: '',
        },
      };
    case Types.UPDATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        updateAssignment: {
          ...state.updateAssignment,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    case Types.UPDATE_ASSIGNMENT_ERROR:
      return {
        ...state,
        updateAssignment: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};
