import Types from 'store/types/timerTypes';
import get from 'lodash/get';
import concat from 'lodash/concat';

const initialState = {
  runningTime: null,
  runningEndTime: null,
  timeFormat: 24,
  currentProject: null,
  currentStory: null,
  currentTask: null,
  currentRunningTime: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  recentEntries: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  deleteEntry: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  getEntry: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
};

export default function TimerReducer(state = initialState, action) {
  switch (action.type) {
    case Types.START_TIMER:
      return {
        ...state,
        runningTime: action.data,
      };

    case Types.SET_END_RUNNINGTIME:
      return {
        ...state,
        runningEndTime: action.data,
      };

    case Types.STOP_TIMER:
      return {
        ...state,
        runningTime: null,
        runningEndTime: null,
        currentProject: null,
        currentStory: null,
        currentTask: null,
        currentRunningTime: {
          ...state.currentRunningTime,
          status: null,
          data: null,
        },
      };

    case Types.TOGGLE_TIME_FORMAT:
      return {
        ...state,
        timeFormat: action.data,
      };

    case Types.RECENT_ENTRIES_LOADING:
      return {
        ...state,
        recentEntries: {
          ...state.recentEntries,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.RECENT_ENTRIES_SUCCESS: {
      let newData = {};
      if (
        get(state.recentEntries.data, 'data', []).length > 0 &&
        get(action.data, 'meta.current_page') > 1
      ) {
        const oldData = { ...state.recentEntries.data };
        newData = { ...action.data };
        newData.data = concat(oldData.data, newData.data);
      } else {
        newData = { ...action.data };
      }

      return {
        ...state,
        recentEntries: {
          ...state.recentEntries,
          loading: false,
          status: action.status,
          data: newData,
        },
      };
    }

    case Types.RECENT_ENTRIES_ERROR:
      return {
        ...state,
        recentEntries: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: action.data,
      };

    case Types.CURRENT_TIME_LOADING:
      return {
        ...state,
        currentRunningTime: {
          ...state.currentRunningTime,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.CURRENT_TIME_SUCCESS:
      return {
        ...state,
        currentRunningTime: {
          ...state.currentRunningTime,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.CURRENT_TIME_ERROR:
      return {
        ...state,
        currentRunningTime: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.DELETE_ENTRY_LOADING:
      return {
        ...state,
        deleteEntry: {
          ...state.deleteEntry,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.DELETE_ENTRY_SUCCESS:
      return {
        ...state,
        deleteEntry: {
          ...state.deleteEntry,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.DELETE_ENTRY_ERROR:
      return {
        ...state,
        deleteEntry: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    case Types.GET_ENTRY_LOADING:
      return {
        ...state,
        getEntry: {
          ...state.getEntry,
          loading: true,
          isError: false,
          status: null,
          message: '',
        },
      };

    case Types.GET_ENTRY_SUCCESS:
      return {
        ...state,
        getEntry: {
          ...state.getEntry,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_ENTRY_ERROR:
      return {
        ...state,
        getEntry: {
          loading: false,
          isError: true,
          status: 0,
          message: action.message,
        },
      };

    default:
      return state;
  }
}
