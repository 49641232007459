import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_CONTRACTS_INPROGRESS',
  'FETCH_CONTRACTS_SUCCESS',
  'FETCH_CONTRACTS_FAILURE',
  'FETCH_SINGLE_CONTRACT_INPROGRESS',
  'FETCH_SINGLE_CONTRACT_SUCCESS',
  'FETCH_SINGLE_CONTRACT_FAILURE',
  'CREATE_CONTRACT_INPROGRESS',
  'CREATE_CONTRACT_SUCCESS',
  'CREATE_CONTRACT_FAILURE',
  'EDIT_CONTRACT_INPROGRESS',
  'EDIT_CONTRACT_SUCCESS',
  'EDIT_CONTRACT_FAILURE',
  'DELETE_CONTRACT_INPROGRESS',
  'DELETE_CONTRACT_SUCCESS',
  'DELETE_CONTRACT_FAILURE',
  'CLEAR_CONTRACT_DATA',
  'POST_CONTRACT_AGREED_INPROGRESS',
  'POST_CONTRACT_AGREED_SUCCESS',
  'POST_CONTRACT_AGREED_FAILURE',
  'POST_CONTRACT_DECLINED_INPROGRESS',
  'POST_CONTRACT_DECLINED_SUCCESS',
  'POST_CONTRACT_DECLINED_FAILURE',
  'UPDATE_CONTRACT_STATUS_INPROGRESS',
  'UPDATE_CONTRACT_STATUS_SUCCESS',
  'UPDATE_CONTRACT_STATUS_FAILURE'
);
