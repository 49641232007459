import React, { forwardRef, useImperativeHandle, useState } from 'react';
import TooltipContainer from './TooltipContainer';

const TooltipForChart = forwardRef((_props, ref) => {
  const [show, setShow] = useState(false);
  const [content, setContent] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (position, tooltipModel, component) => {
      setShow(true);

      const left =
        position.left + window.pageXOffset + tooltipModel.caretX + 10;
      const top = position.top + window.pageYOffset + tooltipModel.caretY;

      const align = tooltipModel.xAlign;

      setContent(
        <TooltipContainer
          left={left}
          top={top}
          align={align}
          component={component}
        />
      );
    },
    openCustom: component => {
      setShow(true);
      setContent(component);
    },
    close: () => {
      setShow(false);
      setContent(null);
    },
    toggle: () => {
      setShow(!show);
    },
  }));

  if (show) return <div>{content}</div>;

  return '';
});

export default TooltipForChart;
