import Types from '../../types/story';
import { getStoryActivity } from 'api/story';
import { get } from 'lodash';

export const fetchStoryActivity = (id, page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_STORY_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getStoryActivity(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_STORY_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_STORY_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STORY_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreStoryActivity = (id, page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MORE_STORY_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getStoryActivity(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_STORY_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_MORE_STORY_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MORE_STORY_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};
