import React from 'react';

import { Col, Row, FormGroup } from 'reactstrap';
import SelectMultiple from 'components/FormFields/SelectMultiple';
import Input from 'components/FormFields/Input';
import { useAccess, permissions } from 'helpers/permission';

const AdvancedFilters = ({ formik, sendGAEvent }) => {
  const clientIDs = formik.values.clients.map(item => item.id).join(',');

  const themeIDs = formik.values.themes.map(item => item.id).join(',');

  const teamIDs = formik.values.users.map(item => item.id).join(',');

  const initiativesIDs = formik.values.initiatives
    .map(item => item.id)
    .join(',');

  const epicsIDs = formik.values.epics.map(item => item.id).join(',');

  const storiesIDs = formik.values.stories.map(item => item.id).join(',');

  const isUserAllowedListClients = useAccess(permissions.LIST_CLIENTS);
  const isUserAllowedListAgreements = useAccess(permissions.LIST_THEMES);

  return (
    <>
      {(isUserAllowedListClients || isUserAllowedListAgreements) && (
        <Row>
          {isUserAllowedListClients && (
            <Col md={4} xl={3}>
              <FormGroup>
                <SelectMultiple
                  label="Client"
                  name="clients"
                  units="Clients"
                  value={formik.values.clients}
                  placeholder="Select Client"
                  url="/accounts/list/dropdown?q="
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  onChange={data => {
                    sendGAEvent('client');
                    formik.setValues({
                      ...formik.values,
                      clients: data,
                      themes: [],
                      initiatives: [],
                      epics: [],
                      stories: [],
                      tasks: [],
                    });
                  }}
                />
              </FormGroup>
            </Col>
          )}
          {isUserAllowedListAgreements && (
            <Col md={4} xl={3}>
              <FormGroup>
                <SelectMultiple
                  label="SOW"
                  name="themes"
                  units="SOWs"
                  value={formik.values.themes}
                  placeholder="Select SOW"
                  url={`/themes/list/dropdown?client=${clientIDs}&q=`}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  onChange={themes => {
                    sendGAEvent('sow');
                    formik.setValues({
                      ...formik.values,
                      themes: themes,
                      initiatives: [],
                      epics: [],
                      stories: [],
                      tasks: [],
                    });
                  }}
                  disabled={clientIDs ? false : true}
                  tooltip={!clientIDs ? 'Please select a Client first' : ''}
                />
              </FormGroup>
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col md={3}>
          <FormGroup>
            <SelectMultiple
              label="Study"
              name="study"
              units="Studies"
              value={formik.values.initiatives}
              placeholder="Select Study"
              url={`/initiatives/list/dropdown?client=${clientIDs}&theme=${themeIDs}&team=${teamIDs}&q=`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={initiatives => {
                sendGAEvent('project');
                formik.setValues({
                  ...formik.values,
                  initiatives: initiatives,
                  epics: [],
                  stories: [],
                  tasks: [],
                });
              }}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <SelectMultiple
              label="Epic"
              name="epic"
              units="Epics"
              value={formik.values.epics}
              placeholder="Select Epic"
              url={`/epics/list/dropdown?initiative=${initiativesIDs}&q=`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={data => {
                sendGAEvent('epic');
                formik.setValues({
                  ...formik.values,
                  epics: data,
                  stories: [],
                  tasks: [],
                });
              }}
              disabled={initiativesIDs ? false : true}
              tooltip={!initiativesIDs ? 'Please select a Study first' : ''}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <SelectMultiple
              label="Story"
              name="story"
              units="Stories"
              value={formik.values.stories}
              placeholder="Select Story"
              url={`/stories/list/dropdown?initiative=${initiativesIDs}&epic=${epicsIDs}&q=`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={data => {
                sendGAEvent('stories');
                formik.setFieldValue('stories', data);
                formik.setFieldValue('tasks', []);
              }}
              disabled={initiativesIDs ? false : true}
              tooltip={!initiativesIDs ? 'Please select a Study first' : ''}
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <SelectMultiple
              label="Assignment"
              name="task"
              units="Tasks"
              value={formik.values.tasks}
              placeholder="Select Assignment"
              url={`/tasks/list/dropdown?initiative=${initiativesIDs}&epic=${epicsIDs}&story=${storiesIDs}&q=`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              onChange={data => {
                sendGAEvent('assignment');
                formik.setFieldValue('tasks', data);
              }}
              disabled={initiativesIDs ? false : true}
              tooltip={!initiativesIDs ? 'Please select a Study first' : ''}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Input
              label="Timelog Description (contains)"
              placeholder="Timelog Description (contains)"
              value={formik.values.description}
              onChange={e => {
                sendGAEvent('description');
                formik.setFieldValue('description', e.target.value);
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default AdvancedFilters;
