import classes from '../QuickStartTour.module.scss';

export const getOnboardingWalkThroughSteps = () => [
  {
    target: '#financialYears',
    content:
      'Have documents from different years? Select each year to upload accordingly.',
    disableBeacon: true,
    hideFooter: false,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.financialYears,
  },
  {
    target: '#document',
    content:
      'Not sure what to upload in each category? Click on the Information Icon to learn more.',
    disableBeacon: true,
    hideFooter: false,
    hideCloseButton: true,
    spotlightClicks: true,
    placement: 'right',
    spotlightPadding: 10,
    extraPlacementClass: classes.document,
  },
  {
    target: '#integrateButton',
    content:
      'Want to integrate with your accounting or payroll software? You can select Integrate to start the process. ',
    disableBeacon: true,
    hideFooter: false,
    hideCloseButton: true,
    spotlightClicks: true,
    placement: 'right',
    spotlightPadding: 10,
    extraPlacementClass: classes.integrateButton,
  },
  {
    target: '#uploadFileButton',
    content:
      'Want to manually upload your documents instead? You can upload them by selecting Upload File or dragging and dropping them directly into each category.',
    disableBeacon: true,
    hideFooter: false,
    hideCloseButton: true,
    spotlightClicks: true,
    placement: 'right',
    spotlightPadding: 10,
    extraPlacementClass: classes.uploadFileButton,
  },
  {
    target: '#otherDocument',
    content:
      'Not seeing a document category you’re looking for? You can use Other Documents for any supporting documents!',
    disableBeacon: true,
    hideFooter: false,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.otherDocument,
  },
  {
    target: '#submitYearButton',
    content:
      'Ready to submit your documents for the year? Click Submit for Review and your documents will be  sent to the Strike Team. ',
    disableBeacon: true,
    hideFooter: false,
    hideCloseButton: true,
    spotlightClicks: true,
    placement: 'right',
    spotlightPadding: 10,
    extraPlacementClass: classes.submitButton,
  },
];
