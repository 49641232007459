import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseTable from 'components/Table';
import get from 'lodash/get';
import classnames from 'classnames';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import DocumentIconImg from 'assets/img/icons/document-comment-icon.svg';
import { ReactComponent as PreviewIcon } from 'assets/img/icons/eye-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/download-icon.svg';

import classes from '../AllDocumentModal.module.scss';
import { downloadDocument } from 'store/actions/documents';
import moment from 'moment';
import { getAllDocumentFolder } from 'store/actions/fulfillment';
import { withTime } from 'helpers/times';

const FolderView = ({ handleDocumentViewer, batches, filters }) => {
  const dispatch = useDispatch();

  const loading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'allDocumentFolder.isInProgress', false)
  );

  const downloadLoading = useGetFieldFromObject(
    'documents',
    'downloadDoc.isInProgress',
    false
  );
  const studyId = useSelector(({ project }) =>
    get(project, 'getProject.data.data.id', '')
  );

  const [documents, setDocuments] = useState([]);
  const [sortBy, setSortBy] = useState({
    dataField: 'doc_type',
    order: 'asc',
  });

  const updateData = (data = []) => {
    const dataClone = [...data];
    dataClone.forEach((doc, index) => {
      const docsForMatched = batches?.find(
        batch => batch?.short_name === doc?.doc_type
      );
      if (docsForMatched) {
        doc['documents'] = docsForMatched?.documents || [];
        doc.id = index;
      }
    });
    setDocuments(dataClone);
  };

  const fetchAndUpdateDocuments = async (studyId, filters) => {
    const { data = [] } = await dispatch(
      getAllDocumentFolder(studyId, filters)
    );
    if (data?.length) {
      updateData(data);
    } else {
      setDocuments([]);
    }
  };

  useEffect(() => {
    fetchAndUpdateDocuments(studyId, filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, filters]);

  const NoDataIndication = () => (
    <div className="d-flex align-items-center p-4 justify-content-center">
      No Files Uploaded
    </div>
  );

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort" />;
    else if (order === 'asc') return <i className="fas fa-sort-up" />;
    else if (order === 'desc') return <i className="fas fa-sort-down" />;
    return null;
  };

  const handleDownload = file => {
    dispatch(downloadDocument(file));
  };

  const getNonExpandableRows = () => {
    let ids = [];
    // data.forEach((data, index) => {
    //   if (data.documents && data?.documents?.length === 0) ids.push(data.id);
    // });
    return ids;
  };

  const getSize = data => Math.round(+data / 1024);

  const renderDropdown = row => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={e => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu className={classnames(classes.dropdownMenu)}>
          <DropdownItem
            className={classes.downloadText}
            onClick={() => handleDownload(row)}
            disabled={downloadLoading}
          >
            <DownloadIcon />
            <span>Download</span>
          </DropdownItem>
          <DropdownItem
            className={classes.downloadText}
            onClick={() => {
              handleDocumentViewer([row] || []);
            }}
          >
            <PreviewIcon /> <span>Preview</span>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const expandRow = {
    renderer: row => {
      return (
        <table width="100%" className={classes.expandedTable}>
          {row.documents.map((data, index) => (
            <tr key={index}>
              <td className={classes['first-expand-cell']}>
                <div className="table-data-expand">
                  <span>
                    <img src={DocumentIconImg} alt="doc" />
                  </span>
                </div>
              </td>
              <td className={classes['expand-name-cell']}>
                <div className="table-data-expand">{data?.name || '-'}</div>
              </td>
              <td className={classes['expand-size-cell']}>
                <div className="table-data-expand">
                  {data?.size ? getSize(data.size) : '-'}KB
                </div>
              </td>
              <td className={classes['expand-date-created-cell']}>
                <div className="table-data-expand">
                  {withTime(data?.created_at, { format: 'date-time' })}
                </div>
              </td>
              <td className={classes['expand-date-download-cell']}>
                <div className="table-data-expand">
                  {withTime(data?.downloaded_on, { format: 'date-time' })}
                </div>
              </td>
              <td className={classes['expand-download-cell']}>
                {renderDropdown(data)}
              </td>
            </tr>
          ))}
        </table>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: () => {
      return '';
    },
    expandColumnRenderer: row => {
      const { expanded, expandable } = row;
      if (expandable) {
        if (expanded) {
          return (
            <i
              className={classnames('fas fa-angle-up', classes.expandIcon)}
            ></i>
          );
        }
        return (
          <i
            className={classnames('fas fa-angle-down', classes.expandIcon)}
          ></i>
        );
      }
      return '';
    },
    nonExpandable: getNonExpandableRows(),
  };

  const handleTableChange = async (type, { sortOrder, sortField }) => {
    if (type === 'pagination') {
    } else if (type === 'sort') {
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
    }
    const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
    const filter = { sort, ...filters };
    await dispatch(getAllDocumentFolder(studyId, filter));
  };

  return (
    <BaseTable
      keyField="id"
      bootstrap4
      remote
      loading={loading}
      noDataIndication={NoDataIndication}
      search={false}
      bordered={false}
      paginationOptions={false}
      wrapperClasses={classes.tableHeader}
      data={documents}
      expandRow={expandRow}
      onTableChange={handleTableChange}
      columns={[
        {
          dataField: 'doc_type',
          text: 'Name',
          sort: true,
          sortCaret: renderSortCaret,
          headerStyle: () => {
            return { width: '25%' };
          },
          formatter: (cell, row, index) => (
            <div
              className={classnames(
                classes.fileName,
                'table-data  d-flex  align-items-center'
              )}
              onClick={() => {
                if (row?.mime_type === 'application/zip') {
                  handleDownload(index);
                }
              }}
            >
              <i
                style={{ marginRight: '12px' }}
                className="fas fa-folder-open"
              />
              <span id={`file-name-${row.id}`}>{cell || '-'}</span>
            </div>
          ),
        },
        {
          dataField: 'document_count',
          text: 'Size',
          sort: true,
          sortCaret: renderSortCaret,
          headerStyle: () => {
            return { width: '20%' };
          },
          formatter: (cell, row, index) => {
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-between  align-items-center'
                )}
              >
                <span className={classes.cursor}>
                  {cell ? `${cell}  ${cell > 1 ? 'Files' : 'File'}` : '-'}
                </span>
              </div>
            );
          },
        },
        {
          dataField: 'date_created',
          text: 'Date Created',
          sort: true,
          sortCaret: renderSortCaret,
          headerStyle: () => {
            return { width: '20%' };
          },
          formatter: (cell, row, index) => {
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-between  align-items-center'
                )}
              >
                <span className={classes.cursor}>
                  {cell ? moment(cell).format('MM/DD/YYYY, h:mm A') : '-'}
                </span>
              </div>
            );
          },
        },
        {
          dataField: 'date_downloaded',
          text: 'Date Downloaded',
          sort: true,
          sortCaret: renderSortCaret,
          headerStyle: () => {
            return { width: '20%' };
          },
          formatter: (cell, row, index) => {
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-between  align-items-center'
                )}
              >
                <span className={classes.cursor}>
                  {cell ? moment(cell).format('MM/DD/YYYY, h:mm A') : '-'}
                </span>
              </div>
            );
          },
        },
        {
          dataField: '',
          text: '',
          headerStyle: () => {
            return { width: '300px' };
          },
          formatter: (cell, row, index) => {
            return (
              <div className={classnames(classes.tData, 'table-data')}>
                <span> </span>
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default FolderView;
