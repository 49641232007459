import React from 'react';
import GreetingImg from 'assets/img/theme/Assignment_Type_Customization_Greeting.svg';
import classes from './AssignmentTypeCustomizations.module.scss';
import cx from 'classnames';

const Greetings = ({ isMobile, isTablet }) => {
  return (
    <div
      className={cx(
        'bg-gradient-info d-flex align-items-center justify-content-center',
        classes.greeting
      )}
    >
      <div className={cx(classes.text, isTablet ? 'm-2 my-4' : 'm-4 ml-6')}>
        <h1 className={cx('text-capitalize', isMobile && 'text-center')}>
          Customize Your Assignment Types
        </h1>
        {!isTablet && (
          <p>
            Utilize assignment types to guide the workflow your teams will use
            to complete projects. Customizing assignments will provide greater
            insight into where and how your team is spending their time.
          </p>
        )}
      </div>
      {!isTablet && (
        <img className="ml-auto" src={GreetingImg} alt="User Greeting" />
      )}
    </div>
  );
};

export default Greetings;
