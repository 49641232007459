import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import forEach from 'lodash/forEach';
import Types from '../types/backlogs';
import {
  getSections,
  getItemsForSection,
  removeStory,
  createStory,
  updateSection,
  reorderStories,
  updateItemSection,
  addBacklogSection,
  removeSection,
  massUpdateStories as massUpdateStoriesApi,
  massDeleteStories as massDeleteStoriesApi,
  reorderSections,
} from 'api/backlogs';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { updateStory as updateStoryApi } from 'api/story';

export const fetchSectionsList = (
  projectId,
  shouldFetchStories = true,
  q = ''
) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_BACKLOG_SECTIONS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getSections(projectId, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.FETCH_BACKLOG_SECTIONS_SUCCESS,
          data: data,
        });
        if (shouldFetchStories) {
          forEach(data, section => {
            dispatch(fetchItemsForSection(section.id, q));
          });
        }
      } else {
        dispatch({
          type: Types.FETCH_BACKLOG_SECTIONS_FAILURE,
          message: message,
        });
      }
      return { status, data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_BACKLOG_SECTIONS_FAILURE,
        message: error,
      });
    }
  };
};

export const addSection = (projectId, name) => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_BACKLOG_SECTION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await addBacklogSection(projectId, name);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.ADD_BACKLOG_SECTION_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.ADD_BACKLOG_SECTION_FAILURE,
          message: message,
        });
      }
      return { status, data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_BACKLOG_SECTIONS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchItemsForSection = (sectionId, q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_BACKLOG_SECTION_ITEMS_INPROGRESS,
      data: {
        sectionId,
      },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getItemsForSection(sectionId, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_BACKLOG_SECTION_ITEMS_SUCCESS,
          data: {
            sectionId,
            items: resp,
          },
        });
      } else {
        dispatch({
          type: Types.FETCH_BACKLOG_SECTION_ITEMS_FAILURE,
          data: {
            sectionId,
          },
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_BACKLOG_SECTION_ITEMS_FAILURE,
        data: {
          sectionId,
        },
        message: error,
      });
    }
  };
};

export const deleteStory = (storyId, sectionId) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_STORY_INPROGRESS,
      data: {
        sectionId,
      },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await removeStory(storyId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_STORY_SUCCESS,
          data: {
            sectionId,
          },
        });
        await dispatch(fetchItemsForSection(sectionId));
        NotificationHandler.open({
          message: message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_STORY_FAILURE,
          data: {
            sectionId,
          },
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_STORY_FAILURE,
        data: {
          sectionId,
        },
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
      });
    }
  };
};

export const deleteSection = sectionId => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_SECTION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await removeSection(sectionId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_SECTION_SUCCESS,
          data: sectionId,
        });
        NotificationHandler.open({
          message: message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_SECTION_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_SECTION_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const massDeleteStories = (projectId, stories) => {
  return async dispatch => {
    dispatch({
      type: Types.STORIES_MASS_DELETE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await massDeleteStoriesApi(projectId, { stories });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.STORIES_MASS_DELETE_SUCCESS,
        });
        await dispatch(fetchSectionsList(projectId));
      } else {
        dispatch({
          type: Types.STORIES_MASS_DELETE_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.STORIES_MASS_DELETE_FAILURE,
        message: error,
      });
    }
  };
};

export const massUpdateStories = (projectId, field, value, stories) => {
  return async dispatch => {
    dispatch({
      type: Types.STORIES_MASS_UPDATE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await massUpdateStoriesApi(projectId, {
        field,
        value,
        stories,
      });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.STORIES_MASS_UPDATE_SUCCESS,
        });
        await dispatch(fetchSectionsList(projectId));
      } else {
        dispatch({
          type: Types.STORIES_MASS_UPDATE_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.STORIES_MASS_UPDATE_FAILURE,
        message: error,
      });
    }
  };
};

export const createStoryForSection = (sectionId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_STORY_INPROGRESS,
      data: {
        sectionId,
      },
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createStory(sectionId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_STORY_SUCCESS,
          data: {
            item: record,
            sectionId,
          },
        });
      } else {
        dispatch({
          type: Types.CREATE_STORY_FAILURE,
          data: {
            sectionId,
          },
          message: message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.CREATE_STORY_FAILURE,
        data: {
          sectionId,
        },
        message: error,
      });
    }
  };
};

export const renameSection = (sectionId, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_SECTION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateSection(sectionId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.UPDATE_SECTION_SUCCESS,
        });
        const sections = get(getState(), 'backlog.sectionsState.data', []);
        const foundIndex = sections.findIndex(s => s.id === record.id);
        if (foundIndex > -1) {
          dispatch({
            type: Types.UPDATE_SECTION_DATA,
            data: [
              ...sections.slice(0, foundIndex),
              record,
              ...sections.slice(foundIndex + 1),
            ],
          });
        }
        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.UPDATE_SECTION_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_SECTION_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updateStoriesOrder = (sectionId, newOrder, customOrder) => {
  return async dispatch => {
    dispatch({
      type: Types.REORDER_STORIES_INPROGRESS,
    });
    if (newOrder && newOrder.length) {
      dispatch({
        type: Types.UPDATE_STORIES_STATE,
        data: {
          sectionId,
          items: customOrder || orderBy(newOrder, ['sort_order'], ['asc']),
        },
      });
      dispatch({
        type: Types.UPDATE_SORT_ORDER_STATE,
        data: { items: orderBy(newOrder, ['sort_order'], ['asc']), sectionId },
      });
      // eslint-disable-next-line no-undef
      try {
        const resp = await reorderStories(sectionId, newOrder);
        const status = get(resp, 'status');

        if (status) {
          dispatch({
            type: Types.REORDER_STORIES_SUCCESS,
            data: resp || {},
          });
        } else {
          dispatch({
            type: Types.REORDER_STORIES_FAILURE,
            message: 'Something went wrong',
          });
        }
      } catch (error) {
        dispatch({
          type: Types.REORDER_STORIES_FAILURE,
          message: error,
        });
      }
    }
  };
};

export const updateItemDetails = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_ITEM_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateItemSection(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data', {});
      if (status) {
        dispatch({
          type: Types.UPDATE_ITEM_SUCCESS,
        });
      } else {
        dispatch({
          type: Types.UPDATE_ITEM_FAILURE,
          message: message,
        });
      }
      return {
        status,
        data: record,
      };
    } catch (error) {
      dispatch({
        type: Types.UPDATE_ITEM_FAILURE,
        message: error,
      });
    }
  };
};

const updateStory = (sectionId, storyId, record = {}) => {
  return async (dispatch, getState) => {
    const stories = get(
      getState(),
      `backlog.itemsState.data.${sectionId}.data`,
      []
    );
    const foundIndex = stories.findIndex(s => s.id === storyId);
    if (foundIndex > -1) {
      dispatch({
        type: Types.UPDATE_STORY_DATA,
        data: {
          items: [
            ...stories.slice(0, foundIndex),
            { ...stories[foundIndex], ...record },
            ...stories.slice(foundIndex + 1),
          ],
          sectionId,
        },
      });
    }
  };
};

export const assignStoryOwner = (sectionId, storyId, userId) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_STORY_DETAILS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateStoryApi(storyId, { owner_id: userId });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');
        dispatch(updateStory(sectionId, storyId, data));
        dispatch({
          type: Types.UPDATE_STORY_DETAILS_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.UPDATE_STORY_DETAILS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_STORY_DETAILS_FAILURE,

        message: error,
      });
    }
  };
};

export const updateStoryPriorities = (
  storyId,
  data,
  sectionId,
  shouldCallApi = true
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_STORY_DETAILS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const { score, ...rest } = data;
      dispatch(updateStory(sectionId, storyId, { score }));
      // calling api only if flag is true
      if (shouldCallApi) {
        const resp = await updateStoryApi(storyId, rest);
        const status = get(resp, 'status');
        const message = get(resp, 'message');
        const record = get(resp, 'data');
        if (status) {
          dispatch({
            type: Types.UPDATE_STORY_DETAILS_SUCCESS,
          });
          dispatch(updateStory(sectionId, storyId, record));
        } else {
          dispatch({
            type: Types.UPDATE_STORY_DETAILS_FAILURE,
            message: message,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_STORY_DETAILS_FAILURE,
        data: {
          sectionId,
        },
        message: error,
      });
    }
  };
};

export const updateStoryStatus = (storyId, statusData, sectionId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_STORY_DETAILS_INPROGRESS,
    });
    dispatch(updateStory(sectionId, storyId, { status: statusData }));
    // eslint-disable-next-line no-undef
    try {
      const data = {
        status_id: statusData ? statusData.id : null,
      };
      const resp = await updateStoryApi(storyId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_STORY_DETAILS_SUCCESS,
        });
      } else {
        dispatch({
          type: Types.UPDATE_STORY_DETAILS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_STORY_DETAILS_FAILURE,
        data: {
          sectionId,
        },
        message: error,
      });
    }
  };
};

export const updateStoryEpic = (storyId, epic, sectionId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_STORY_DETAILS_INPROGRESS,
    });
    dispatch(updateStory(sectionId, storyId, { epic }));

    // eslint-disable-next-line no-undef
    try {
      const resp = await updateStoryApi(storyId, {
        epic_id: get(epic, 'id', null),
      });
      const status = get(resp, 'status');
      const data = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_STORY_DETAILS_SUCCESS,
        });
        dispatch(updateStory(sectionId, storyId, data));
      } else {
        dispatch({
          type: Types.UPDATE_STORY_DETAILS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_STORY_DETAILS_FAILURE,
        data: {
          sectionId,
        },
        message: error,
      });
    }
  };
};

export const updateSectionsOrder = (projectId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.REORDER_SECTIONS_INPROGRESS,
    });
    dispatch({
      type: Types.UPDATE_SECTIONS_STATE,
      data,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await reorderSections(projectId, data);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.REORDER_SECTIONS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.REORDER_SECTIONS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.REORDER_SECTIONS_FAILURE,
        message: error,
      });
    }
    // }
  };
};
