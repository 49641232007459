import Request from './request';

export const fetchNotifications = async (timestamp, limit) => {
  return Request.call({
    url: '/notifications',
    method: 'GET',
    params: {
      created_at: timestamp,
      limit: limit,
    },
  });
};

export const markRead = async id => {
  return Request.call({
    url: `/notifications/${id}/read`,
    method: 'POST',
  });
};

export const markAllRead = async () => {
  return Request.call({
    url: `/notifications/markAllread`,
    method: 'POST',
  });
};
