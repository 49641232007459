import { get } from 'lodash';
import { fetchList, create, remove } from 'api/clients';
import Types from '../types/clients';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const fetchClientsList = (page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchList(page, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CLIENTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const createClient = data => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_CLIENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await create(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_CLIENT_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_CLIENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.CREATE_CLIENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const deleteClient = (id, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_CLIENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await remove(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_CLIENT_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchClientsList(page, sort, q));
        NotificationHandler.open({
          message: message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_CLIENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_CLIENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};
