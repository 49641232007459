import CheckBox from 'components/FormFields/CheckBox';
import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import classes from './EditModal.module.scss';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import { useDispatch } from 'react-redux';
import { editStudyDocs } from 'store/actions/fulfillment';
import Button from 'components/Button';
import moment from 'moment';

const currentYear = moment().year();
const YEARS_DATA = Array.from({ length: 10 }, (_, index) => ({
  year: currentYear - index,
  disabled: false,
  checked: false,
})).sort((a, b) => a.year - b.year);

const EditModal = ({
  years,
  isOpen,
  toggle,
  parentId,
  getDocs = null,
  docType,
  studyName,
}) => {
  const [data, setData] = useState(YEARS_DATA);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const updatedData = data.map(item =>
      years.includes(item.year) ? { ...item, checked: true } : item
    );

    setData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = year => {
    const updatedData = data.map(item =>
      item.year === year ? { ...item, checked: !item.checked } : item
    );
    setData(updatedData);
  };

  const handleSave = async () => {
    const focusedYears = data
      .filter(item => item.checked)
      .map(item => item.year);
    setLoading(true);
    await dispatch(
      editStudyDocs(parentId, {
        years: focusedYears,
      })
    );
    if (getDocs) await getDocs();
    setLoading(false);
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={classes.modalWrapper}
      title={
        <div className={classes.modalTitle}>
          <h3 className="mb-0">{studyName}</h3>
          <span className={classes.docType}> • {docType}</span>
        </div>
      }
    >
      <ModalBody className={classes.modalBody}>
        <div className={classes.title}>
          Select Years needed for this Bucket:
        </div>
        <div className={classes.checkboxWrapper}>
          {data.map(data => (
            <CheckBox
              key={data.year}
              id={data.year}
              label={data.year}
              className={classes.checkbox}
              inputClassName={cs(
                years?.includes(data.year) && classes.activeYear
              )}
              checked={data.checked}
              onChange={() => handleChange(data.year)}
              disabled={data.disabled}
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button className={classes.cancelBtn} onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="primary"
          className={classes.saveBtn}
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditModal;
