import React from 'react';
import { Col, ListGroupItem, Row } from 'reactstrap';

const NotificationNotAvailable = props => {
  return (
    <>
      <ListGroupItem
        className={'list-group-item-action'}
        href="#"
        onClick={e => {
          e.preventDefault();
        }}
        tag="a"
      >
        <Row className="align-items-center">
          <Col className="col-auto">
            <p className="text-sm mb-0">You don't have any notification</p>
          </Col>
        </Row>
      </ListGroupItem>
    </>
  );
};

export default NotificationNotAvailable;
