import Request from './request';

export const fetchContractsList = async (page, limit, sort, query) => {
  return Request.call({
    url: '/sla-contract',
    method: 'GET',
    params: {
      page,
      limit,
      sort,
      q: query,
    },
  });
};

export const getContract = async id => {
  return Request.call({
    url: `/sla-contract/${id}`,
    method: 'GET',
  });
};

export const editContract = async (id, data) => {
  return Request.call({
    url: `/sla-contract/${id}`,
    method: 'PUT',
    data,
  });
};

export const deleteContract = async id => {
  return Request.call({
    url: `/sla-contract/${id}`,
    method: 'DELETE',
  });
};

export const createContract = async data => {
  return Request.call({
    url: '/sla-contract',
    method: 'POST',
    data,
  });
};

export const postContractAgreed = async id => {
  return Request.call({
    url: `/sla-contract/sign/${id}`,
    method: 'POST',
  });
};

export const updateContractStatus = async (id, data, path) => {
  return Request.call({
    url: `/${path}/${id}/status`,
    method: 'PUT',
    data,
  });
};

export const postContractDeclined = async (
  id,
  email,
  reason = 'no_authority'
) => {
  return Request.call({
    url: `/sla-contract/decline/${id}`,
    method: 'POST',
    data: {
      reason,
      email,
    },
  });
};
