/* eslint-disable import/no-unresolved */
import React from 'react';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

import '@react-pdf-viewer/zoom/lib/styles/index.css';

import classes from './DocumentViewer.module.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PdfViewer = ({ url, showDownload = false }) => {
  const zoomPluginInstance = zoomPlugin();
  const getFilePluginInstance = getFilePlugin();

  const { ZoomInButton, ZoomOutButton } = zoomPluginInstance;
  const { DownloadButton } = getFilePluginInstance;

  return (
    <>
      <div className="options-menu-pdf options-wrapper">
        <div className={classes.optionsList}>
          <div className={classes.zoomComponent}>
            {showDownload && <DownloadButton />}
            <ZoomOutButton />
            <ZoomInButton />
          </div>
        </div>
      </div>
      <div className={classes.pdfWrapper}>
        <Viewer
          fileUrl={url}
          plugins={[zoomPluginInstance, getFilePluginInstance]}
          defaultScale={SpecialZoomLevel.PageWidth}
        />
      </div>
    </>
  );
};

export default PdfViewer;
