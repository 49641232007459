import React from 'react';
import get from 'lodash/get';
import RSelectAsync from 'components/FormFields/RSelectAsync';

const SelectStory = props => {
  const { formik, project } = props;

  return (
    <RSelectAsync
      url={`/timelogs/stories/search?initiative=${project}`}
      getOptionLabel={option => option.name}
      placeholder="Select Story"
      onChange={(data, actions) => {
        const selected = {
          id: get(data, 'id', ''),
          name: get(data, 'name', ''),
          project: get(data, 'initiative.id', ''),
          project_name: get(data, 'initiative.name', ''),
        };

        if (actions.action === 'select-option' && !project) {
          formik.setFieldValue('project', selected.project);
          formik.setFieldValue('project_name', selected.project_name);
        }

        formik.setFieldValue('story', selected.id);
        formik.setFieldValue('story_name', selected.name);

        formik.setFieldValue('task', '');
        formik.setFieldValue('task_name', '');
      }}
      value={
        formik.values.story && formik.values.story_name
          ? { id: formik.values.story, name: formik.values.story_name }
          : null
      }
    />
  );

  // return (
  //   <SelectAsync
  //     touched={true}
  //     error={error}
  //     className="form-control"
  //     data={
  //       id && text
  //         ? [
  //             {
  //               id: id,
  //               text: JSON.stringify({
  //                 id,
  //                 name: text,
  //                 initiative: {
  //                   id: project,
  //                   name: project_name,
  //                 },
  //                 initData: true,
  //               }),
  //               content: text,
  //             },
  //           ]
  //         : []
  //     }
  //     value={id}
  //     defaultValue={id}
  //     placeholder={'Select Story'}
  //     url={`/timelogs/stories/search?initiative=${project}`}
  //     name="story"
  //     processResults={data => ({
  //       results: data.data.map(response => ({
  //         id: response.id,
  //         text: JSON.stringify(response),
  //         content: response.name,
  //       })),
  //       pagination: {
  //         more: data.links.next ? true : false,
  //       },
  //     })}
  //     templateSelection={data => {
  //       if (data.id === '') return data.text;
  //       try {
  //         const rs = JSON.parse(data.text);
  //         const initiative = JSON.stringify(rs.initiative);
  //         data.element.setAttribute('data-initiative', initiative);

  //         return rs.name;
  //       } catch (error) {
  //         return data.text;
  //       }
  //     }}
  //     templateResult={data => {
  //       return data.content;
  //     }}
  //     onSelect={event => {
  //       const selected = get(event.target, 'selectedOptions.0');

  //       if (selected) {
  //         const rs = JSON.parse(selected.text);
  //         if (rs) {
  //           onChange(
  //             selected.value,
  //             rs.name,
  //             rs.initiative.id,
  //             rs.initiative.name,
  //             get(rs, 'initData')
  //           );
  //         }
  //       }
  //     }}
  //   />
  // );
};

export default SelectStory;
