import React, { useState, useEffect } from 'react';
import { Popover } from 'reactstrap';
import BaseColorPicker from './BaseColorPicker';
import Button from 'components/Button';
import classNames from 'classnames';
import classes from './ColorPicker.module.scss';

const ColorPicker = ({
  color: receivedColor,
  onChange: onColorChange,
  onChangeComplete: colorSelectionComplete,
  targetElement,
  isOpen,
  onCancel,
  isUncontrolled = true,
  trigger = 'legacy',
  ...rest
}) => {
  const [color, setColor] = useState(receivedColor);
  useEffect(() => setColor(receivedColor), [receivedColor]);
  return (
    <Popover
      popperClassName={classNames(classes['color-picker-container'])}
      target={targetElement}
      isOpen={isOpen}
      placement="bottom-start"
      trigger={trigger}
      boundariesElement="viewport"
      toggle={isUncontrolled ? onCancel : () => {}}
      {...rest}
    >
      <div className="d-flex flex-column justify-content-center align-items-stretch h-100 w-100 p-2">
        <BaseColorPicker
          color={color}
          onChange={color => {
            onColorChange(color.hex);
            setColor(color.hex);
          }}
        />
        <div className="d-flex flex-row justify-content-between align-items-stretch">
          <Button
            outline
            color="primary"
            className="w-50"
            size="sm"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-50 m-0"
            size="sm"
            onClick={() => colorSelectionComplete(color)}
          >
            Save
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default ColorPicker;
