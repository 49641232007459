import React, { useState } from 'react';
import cs from 'classnames';
import classes from '../QuickActions.module.scss';
import InviteToProjectForm from 'views/pages/Project/Teams/InviteToProject.Form';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { addTeamMember } from 'store/actions/teamMembers';
import { useDispatch, useSelector } from 'react-redux';
import { inviteMembers } from 'store/actions/users';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import UpgradeAlert from 'components/UpgradeAlert';
import InviteUserForm from 'views/pages/Users/InviteUser.Form';
import { get, map } from 'lodash';

const InviteTeamMember = ({ projectId, isPersonalDashboard }) => {
  const dispatch = useDispatch();
  const [isModal, setModal] = useState(false);
  const [inviteUserModal, setInviteUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionChange, setSelectionChange] = useState({
    openValidModal: false,
    agreeToInvite: false,
    selectedValue: '',
    data: null,
  });
  const projectDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data', {})
  );
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.project_teams,
      ...rest,
    });
  };
  const openModal = () => {
    if (!isPersonalDashboard) {
      setModal(true);
    } else {
      setInviteUserModal(true);
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleNewUserInvite = options => () => {
    if (!options.hasValue && options.value.length === 0) {
      closeModal();
    }
    setInviteUserModal(true);
  };

  const handleCloseInviteUserForm = () => {
    setInviteUserModal(false);
  };

  const inviteUserToPlatform = async values => {
    setIsLoading(true);
    const { firstName, lastName, email, role, note, workflow } = values;
    const data = {
      roles: [role.name],
      first_name: firstName,
      last_name: lastName,
      email_addresses: [email],
      show_macro_onboarding: workflow || false,
    };
    if (note) {
      data.personal_note = note;
    }
    analyticsSendEvent({
      category: analyticsConstants.category.user_management,
      action: analyticsConstants.action.invite_users,
    });

    const { errorStatus } = await dispatch(inviteMembers(data));
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showTeamLimit();
    }
    setIsLoading(false);
    handleCloseInviteUserForm();
  };

  const submitValues = async ({ users }) => {
    analyticsSendEvent({
      action: analyticsConstants.action.add_project_team_member,
    });
    await dispatch(
      addTeamMember(projectId, { members: users.map(u => u.id) }, {}, false)
    );
    setModal(false);
  };

  return (
    <>
      <div className={classes.actionBox} onClick={openModal}>
        <div className={cs(classes.gradient, classes.inviteMember)} />
        <div className={classes.box}>
          <i className={cs('fas fa-users', classes.users)} />
          <h2>Invite Strike Staff</h2>
        </div>
      </div>
      {isModal ? (
        <InviteToProjectForm
          projectId={projectId}
          isModalOpen={isModal}
          submitValues={submitValues}
          closeModal={closeModal}
          handleNewUserInvite={handleNewUserInvite}
        />
      ) : null}
      {inviteUserModal ? (
        <InviteUserForm
          isModalOpen={inviteUserModal}
          submitValues={inviteUserToPlatform}
          closeModal={handleCloseInviteUserForm}
          editValues={{
            projects: projectId
              ? [
                  {
                    name: projectDetails.name,
                    id: projectDetails.id,
                  },
                ]
              : undefined,
          }}
          isLoading={isLoading}
          selectionChange={selectionChange}
          setSelectionChange={setSelectionChange}
          title="Invite Strike Staff"
        />
      ) : null}
    </>
  );
};

export default InviteTeamMember;
