import Types from '../types/assignmentTypeCustomizations';
import {
  getAssignmentTypes,
  createAssignmentType as createAssignmentTypeApi,
  editAssignmentType as editAssignmentTypeApi,
  deleteAssignmentType,
} from 'api/customizations';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import forEach from 'lodash/forEach';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const fetchAssigmentTypes = (searchTerm = '', page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_ASSIGNMENT_TYPES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getAssignmentTypes(searchTerm, page);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_ASSIGNMENT_TYPES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_ASSIGNMENT_TYPES_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ASSIGNMENT_TYPES_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const createAssignmentType = name => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_ASSIGNMENT_TYPE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createAssignmentTypeApi(name);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const data = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CREATE_ASSIGNMENT_TYPE_SUCCESS,
          data: data || {},
        });
        NotificationHandler.open({
          operation: 'update',
          message,
        });
        return resp;
      } else {
        dispatch({
          type: Types.CREATE_ASSIGNMENT_TYPE_FAILURE,
          message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_ASSIGNMENT_TYPE_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const changeAssignmentTypeActiveStatus = (
  data,
  showNotification = true
) => {
  return async dispatch => {
    const isChecked = get(data, 'is_active', true);
    dispatch({
      type:
        Types[`${isChecked ? '' : 'DE'}ACTIVATE_ASSIGNMENT_TYPE_INPROGRESS`],
      data,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await editAssignmentTypeApi(data.id, {
        is_active: get(data, 'is_active', true),
      });
      const status = get(resp, 'status');
      const respData = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type:
            Types[`${isChecked ? '' : 'DE'}ACTIVATE_ASSIGNMENT_TYPE_SUCCESS`],
          data: respData || {},
        });
        if (showNotification) {
          NotificationHandler.open({
            operation: 'update',
            message,
          });
        }
        return resp;
      } else {
        dispatch({
          type:
            Types[`${isChecked ? '' : 'DE'}ACTIVATE_ASSIGNMENT_TYPE_FAILURE`],
          message: 'Something went wrong',
        });
        if (showNotification) {
          NotificationHandler.open({
            operation: 'failure',
            message,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: Types[`${isChecked ? '' : 'DE'}ACTIVATE_ASSIGNMENT_TYPE_FAILURE`],
        message: 'Something went wrong',
      });
      if (showNotification) {
        NotificationHandler.open({
          operation: 'failure',
          message: error,
        });
      }
    }
  };
};

export const editAssignmentType = (data, showNotification = true) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_ASSIGNMENT_TYPE_INPROGRESS,
      data,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await editAssignmentTypeApi(data.id, {
        name: get(data, 'name', ''),
        is_active: get(data, 'is_active', true),
      });
      const status = get(resp, 'status');
      const respData = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_ASSIGNMENT_TYPE_SUCCESS,
          data: respData || {},
        });
        if (showNotification) {
          NotificationHandler.open({
            operation: 'update',
            message,
          });
        }
        return resp;
      } else {
        dispatch({
          type: Types.EDIT_ASSIGNMENT_TYPE_FAILURE,
          message: 'Something went wrong',
        });
        if (showNotification) {
          NotificationHandler.open({
            operation: 'failure',
            message,
          });
        }
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_ASSIGNMENT_TYPE_FAILURE,
        message: 'Something went wrong',
      });
      if (showNotification) {
        NotificationHandler.open({
          operation: 'failure',
          message: error,
        });
      }
    }
  };
};

export const removeAssignmentType = taskId => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_ASSIGNMENT_TYPE_INPROGRESS,
      data: taskId,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteAssignmentType(taskId);
      const status = get(resp, 'status');
      const respData = get(resp, 'data');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_ASSIGNMENT_TYPE_SUCCESS,
          data: respData || {},
        });
        NotificationHandler.open({
          operation: 'update',
          message,
        });
        return resp;
      } else {
        dispatch({
          type: Types.DELETE_ASSIGNMENT_TYPE_FAILURE,
          message: 'Something went wrong',
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_ASSIGNMENT_TYPE_FAILURE,
        message: 'Something went wrong',
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};
