import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import queryString from 'query-string';
import useFinch from 'helpers/useFinch';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFinchDetails,
  fetchCodatConnectURL,
  disconnectFinch,
  disconnectCodat,
} from 'store/actions/onboarding';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import { getCompanyDetails } from 'store/actions/company';
import { get, keys, map } from 'lodash';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { useHistory, useLocation } from 'react-router';
import Finch1 from 'assets/img/brand/FinchIntegrationIcons/Finch1.svg';
import Finch2 from 'assets/img/brand/FinchIntegrationIcons/Finch2.svg';
import Finch3 from 'assets/img/brand/FinchIntegrationIcons/Finch3.svg';
import Finch4 from 'assets/img/brand/FinchIntegrationIcons/Finch4.svg';
import Codat1 from 'assets/img/brand/CodatIntegrationIcons/Codat1.svg';
import Codat2 from 'assets/img/brand/CodatIntegrationIcons/Codat2.svg';
import Codat3 from 'assets/img/brand/CodatIntegrationIcons/Codat3.svg';
import Codat4 from 'assets/img/brand/CodatIntegrationIcons/Codat4.svg';
import styles from './integrations.module.scss';
import cx from 'classnames';
import InviteUserForm from 'views/pages/Users/InviteUser.Form';
import { fetchInviteClientRoles, inviteMembers } from 'store/actions/users';

const Integrations = ({ formikRef }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectionChange, setSelectionChange] = useState({
    openValidModal: false,
    agreeToInvite: false,
    selectedValue: '',
    data: null,
  });

  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  const companyId = useSelector(({ auth }) => get(auth, 'user.company.id'));

  const payrollProvider = useGetFieldFromObjects(
    'company',
    'details.data.payroll_provider'
  );

  const accountProvider = useGetFieldFromObjects(
    'company',
    'details.data.accounting_provider'
  );

  const codatUrl = useGetFieldFromObjects(
    'onboarding',
    'integration.data.codat.url'
  );

  const setupStatus = useGetFieldFromObjects(
    'onboarding',
    'integration.status'
  );

  useEffect(() => {
    dispatch(fetchInviteClientRoles());
  }, [dispatch]);

  const closeModal = () => {
    setIsModal(false);
  };

  const submitValues = async values => {
    setIsLoading(true);
    const { email, role, note, firstName, lastName } = values;
    const data = {
      roles: [role.name],
    };
    data.first_name = firstName;
    data.last_name = lastName;
    data.email_addresses = [email];
    data.initiatives = map(get(values, 'projects'), 'id');
    if (note) {
      data.personal_note = note;
    }
    data.company_id = companyId;

    await dispatch(inviteMembers(data));
    setIsLoading(false);
    closeModal();
  };

  const [integrations, setIntegrations] = useState({
    accounting: {
      name: 'Codat',
      title: 'Securely connect your accounting software',
      content: `Connect with these or <a href='https://www.codat.io/us/security' target='_blank'>9 other popular accounting software providers</a> to speed up the document gathering process (you can always do this later)`,
      onClickConnect: async () => {
        let queryParams = location.search;
        if (queryParams) {
          queryParams = queryParams + '&success=true';
        } else {
          queryParams = `?success=true`;
        }
        localStorage.setItem('codatRedirect', location.pathname + queryParams);

        const url = await dispatch(fetchCodatConnectURL());
        if (url) {
          window.location.href = url;
        }
      },
      disabled: false,
      isDone: false,
      imageSrc: [Codat1, Codat2, Codat3, Codat4],
      integrationObject: accountProvider,
      clickComponent: 'a',
      clickComponentProps: { href: codatUrl },
    },
    payroll: {
      name: 'Finch',
      title: 'Securely connect your HR/payroll software',
      content:
        'Connect with the popular options above or <a href="https://tryfinch.com/security" target="_blank">15 other major HR/payroll providers</a> to save your team time and effort (don’t worry, you can do this later)',
      onClickConnect: () => {
        open();
      },
      integrationObject: payrollProvider,
      imageSrc: [Finch1, Finch2, Finch3, Finch4],
      disabled: false,
      isDone: false,
    },
  });

  useEffect(() => {
    if (queryProps?.success && setupStatus === 1) {
      showSuccessAlert({
        text: `You have successfully connected with your accounting software!`,
      });
      history.push(location.pathname);
    }
  }, [history, location.pathname, queryProps, setupStatus]);

  useEffect(() => {
    setIntegrations(currentIntegrations => ({
      ...currentIntegrations,
      accounting: {
        ...currentIntegrations.accounting,
        clickComponentProps: {
          ...currentIntegrations.accounting.clickComponentProps,
          href: codatUrl,
        },
        integrationObject: accountProvider,
        imageSrc: [Codat1, Codat2, Codat3, Codat4],
        disabled: !!accountProvider,
        isDone: !!accountProvider,
      },
      payroll: {
        ...currentIntegrations.payroll,
        integrationObject: payrollProvider,
        imageSrc: [Finch1, Finch2, Finch3, Finch4],
        disabled: !!payrollProvider,
        isDone: !!payrollProvider,
      },
    }));
  }, [codatUrl, accountProvider, payrollProvider]);

  useEffect(() => {
    formikRef({ isValid: true });
  }, [formikRef]);

  const showSuccessAlert = ({
    title = 'Connection Successful!',
    confirmBtnText = 'OK, got it!',
    text,
  }) => {
    AlertPopupHandler.openCustom({
      type: 'success',
      warning: false,
      success: true,
      showCancel: false,
      onConfirm: () => {},
      title,
      confirmBtnText,
      confirmBtnBsStyle: 'success',
      text,
    });
  };

  const { open } = useFinch(
    async ({ code }) => {
      await dispatch(fetchFinchDetails(code));
      dispatch(getCompanyDetails(false));
      showSuccessAlert({
        text: `You have successfully connected with your payroll software!`,
      });
    },
    error => {
      AlertPopupHandler.openCustom({
        type: 'warning',
        warning: true,
        showCancel: true,
        onConfirm: () => {
          open();
        },
        title: 'Integration Unsuccessful',
        confirmBtnText: 'Try Again',
        confirmBtnBsStyle: 'warning pl-5 pr-5',
        text: `Looks like integration was unsuccessful. Please try connecting again.`,
      });
    }
  );

  const openDisconnect = data => {
    AlertPopupHandler.openCustom({
      type: 'warning',
      warning: true,
      success: false,
      showCancel: true,
      onConfirm: async () => {
        let status;
        if (data === 'Codat') {
          status = await dispatch(disconnectCodat());
        } else {
          status = await dispatch(disconnectFinch());
        }
        dispatch(getCompanyDetails(false));
        if (status) {
          AlertPopupHandler.open({
            type: 'success',
            warning: false,
            success: true,
            showCancel: false,
            onConfirm: () => {},
            title: 'Integration Disconnected',
            confirmBtnText: 'OK, got it!',
            confirmBtnBsStyle: 'success',
            text: `You have successfully disconnected with your ${
              data === 'Codat' ? 'accounting software' : 'payroll software'
            }!`,
          });
        }
      },
      title: 'Disconnect Integration',
      confirmBtnText: 'Yes, Disconnect',
      confirmBtnBsStyle: 'warning',
      text:
        'This will remove the integration and all data created by the integration will be removed. Are you sure you want to disconnect the integration?',
    });
  };

  return map(keys(integrations), (integrationKey, index) => {
    const integration = integrations[integrationKey];

    return (
      <>
        {isModal && (
          <InviteUserForm
            isModalOpen={isModal}
            submitValues={submitValues}
            closeModal={closeModal}
            isLoading={isLoading}
            selectionChange={selectionChange}
            setSelectionChange={setSelectionChange}
            inviteClient
          />
        )}
        <div className="d-flex flex-nowrap mx-3" key={index}>
          <p className="avatar rounded-circle bg-primary text-white mr-4 mb-0 align-self-center">
            {integration.isDone ? <i className="fas fa-check" /> : index + 1}
          </p>
          <Card className="w-75">
            <CardBody className={cx('d-flex flex-nowrap p-0', styles.cardBody)}>
              <div className="d-flex flex-column w-100">
                <div className="pt-4 pl-4 pr-4 mr-6">
                  <h1 className={styles.title}>{integration.title}</h1>
                  <div className="d-flex justify-content-between mb-2 mt-2">
                    {integration.imageSrc?.map((img, index) => (
                      <img src={img} key={index} />
                    ))}
                  </div>
                  <p
                    className={styles.bodyText}
                    dangerouslySetInnerHTML={{ __html: integration.content }}
                  ></p>
                </div>
                <div className={cx('d-flex mt-3', styles.footer)}>
                  {integration.integrationObject ? (
                    <>
                      <Button
                        className="border-blue"
                        color="link"
                        onClick={event => openDisconnect(integration.name)}
                      >
                        Disconnect
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="text-white"
                        disabled={integration.disabled || integration.isDone}
                        color="primary"
                        onClick={integration.onClickConnect}
                        {...(integration.clickComponent
                          ? {
                              tag: integration.clickComponent,
                              ...integration.clickComponentProps,
                            }
                          : {})}
                      >
                        Connect Now
                      </Button>
                      <p
                        className={cx(
                          'd-flex align-items-center m-0 ml-4',
                          styles.footerText
                        )}
                      >
                        Don’t have access?
                        <span onClick={() => setIsModal(!isModal)}>
                          {' '}
                          Invite a Team Member to Connect
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </>
    );
  });
};

export default Integrations;
