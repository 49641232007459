import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal, ModalBody } from 'reactstrap';
import classes from './SuccessModal.module.scss';
import DoneIcon from 'assets/img/icons/deal-desk/done-icon.svg';

const SuccessModal = forwardRef(({ id }, ref) => {
  const [isOpen, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        setOpen(false);
      }, 3000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpen]);

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  return (
    <Modal
      size="md"
      centered={true}
      scrollable
      fade={false}
      isOpen={isOpen}
      toggle={toggleModal}
      className={classes.modalSuccess}
    >
      <ModalBody>
        <div className={classes.successState}>
          <img src={DoneIcon} />
          <div>Done!</div>
        </div>
      </ModalBody>
    </Modal>
  );
});

export default SuccessModal;
