import Types from '../types/components';

export const setMentionsUrl = url => ({
  type: Types.ADD_MENTION_URL,
  data: url,
});

export const removeMentionsUrl = () => ({
  type: Types.REMOVE_MENTION_URL,
});
