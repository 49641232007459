import Modal from 'components/FormFields/Modal';
import React from 'react';
import classes from './ContractPreviewModal.module.scss';
import cx from 'classnames';
import parse from 'html-react-parser';
import { Card, CardBody } from 'reactstrap';

const ContractPreviewModal = ({ isOpen, toggle, previewDetails }) => {
  return (
    <Modal
      size="lg"
      noHeader
      scrollable
      fade={false}
      toggle={toggle}
      isOpen={isOpen}
      className={classes.previewModal}
    >
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <div className={classes.content}>
            {parse(previewDetails?.summary || '')}
          </div>
          <div className={cx(classes.agreement)}>
            <div>{parse(previewDetails?.content || '')}</div>
          </div>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default ContractPreviewModal;
