import React from 'react';
import classes from '../StoryAssignments.module.scss';
import SelectAsync from 'components/FormFields/SelectAsync';
import get from 'lodash/get';
import cs from 'classnames';
import Button from 'components/Button';
import { components } from 'react-select';
import { useAccess, permissions } from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';

const Type = ({
  smallView,
  formik,
  isEditAllowed,
  analyticsSendEvent,
  taskTypeChange,
}) => {
  const { values, setFieldValue, handleSubmit } = formik;
  const isCreateTaskTypeAllowed = useAccess(permissions.CREATE_TASK_TYPES);
  if (!isEditAllowed)
    return (
      <div className={cs(classes.assignmentType, classes.noPointer)}>
        {get(values, 'task_type.name', '') || 'Assignment Type'}{' '}
      </div>
    );
  const CreateTypeButton = () => (
    <Button
      tag="a"
      color="primary"
      className="w-100 text-white"
      size="sm"
      href="/admin/assignment-types/new"
      target="_blank"
      onClick={() => {
        analytics.sendEvent({
          category: analyticsConstants.category.customizations,
          action: analyticsConstants.action.manage_types_from_story,
        });
      }}
    >
      + Add Type
    </Button>
  );

  return (
    <SelectAsync
      url="/taskTypes/list/dropdown"
      id={`assignment-type-task-${values.id}`}
      onChange={data => {
        analyticsSendEvent({
          action: analyticsConstants.action.update_assignment_type,
        });
        setFieldValue('task_type', data);
        handleSubmit();
      }}
      placement={smallView ? 'bottom-end' : 'bottom-start'}
      filterOption={() => true}
      refreshOptions={taskTypeChange}
      styles={{
        menuList: provided => {
          return { ...provided, paddingBottom: 0 };
        },
      }}
      components={{
        Option: props => {
          if (get(props, 'data.type') === 'Custom Option') {
            const { innerRef, children } = props;
            return (
              <div
                ref={innerRef}
                className={classes.addTypeButton}
                style={{
                  backgroundColor: get(
                    props.getStyles('menu', props),
                    'backgroundColor'
                  ),
                }}
              >
                {children}
              </div>
            );
          } else {
            return <components.Option {...props} />;
          }
        },
      }}
      {...(isCreateTaskTypeAllowed
        ? {
            customOptions: [
              {
                type: 'Custom Option',
                name: <CreateTypeButton />,
              },
            ],
            noOptionsMessage: () =>
              isCreateTaskTypeAllowed ? (
                <>
                  <div className={cs('mb-2 mt--2', classes.noAssignmentType)}>
                    No Assignment Types
                  </div>
                  <CreateTypeButton />
                </>
              ) : (
                'No Assignment Types'
              ),
          }
        : {})}
    >
      <div className={classes.assignmentType}>
        {get(values, 'task_type.name', '') || 'Assignment Type'}{' '}
        <i className="fas fa-sort"></i>
        <span className={classes.overlay} />
      </div>
    </SelectAsync>
  );
};

export default Type;
