import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const InputHour24 = props => {
  const { name, value, onChange } = props;
  const [data, setData] = useState(0);

  useEffect(() => {
    setData(value);
  }, [value]);

  const validateTime = e => {
    const re = /^[0-9\b]+$/;

    let value = e.target.value;

    if (value === '00') {
      setData(value);
      return true;
    }

    if (value === '') {
      setData('');
      return true;
    }

    if (re.test(value)) {
      const iValue = parseInt(value);
      if (iValue > 23) value = 23;
      setData(value);
    }
  };
  const handleOnBlur = e => {
    let value = e.target.value;

    if (value === '' || value === '0') {
      value = '00';
      setData(value);
      onChange(0);
      return true;
    }

    const iValue = parseInt(value);
    let changeData = iValue;
    if (iValue < 10) changeData = `0${iValue}`;

    setData(changeData);

    if (iValue || iValue === 0) onChange(iValue);
  };

  return (
    <input
      autoComplete="off"
      type="text"
      name={name}
      value={data}
      maxLength="2"
      onChange={validateTime}
      onBlur={handleOnBlur}
    />
  );
};

InputHour24.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
InputHour24.defaultProps = {};

export default InputHour24;
