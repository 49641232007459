import React from 'react';
import { Badge } from 'reactstrap';
import addIcon from '../../../../../assets/img/icons/add-doc-icon.svg';
import cx from 'classnames';
import style from './SourceDocumnetCard.module.scss';

const SourceDocumentCard = ({ key, data, onClickDocument }) => {
  const { years, documents, status, short_name, is_optional, is_yearly } = data;
  const mapDocumentStatus = status => {
    return status?.toLowerCase()?.replace(/ /g, '_');
  };
  return (
    <div className={style.container} onClick={onClickDocument}>
      <div className={style.cardData}>
        <div className={style.dataWrapper}>
          <div className={style.leftWrapper}>
            <img src={addIcon} />
            <div className={style.documentTitle}>
              {short_name}
              {is_optional ? ', If Applicable' : ''}
            </div>
          </div>
          <div className={style.rightWrapper}>
            <div className={style.statusWrapper}>
              <div className={style.statusContainer}>
                {is_yearly && (
                  <div>
                    <div className={style.statusText}>STATUS</div>
                    <div>
                      <Badge
                        pill
                        color={'#2e3e7e'}
                        className={cx(
                          style.status,
                          style[
                            mapDocumentStatus(status?.label ?? 'Not Started')
                          ]
                        )}
                      >
                        {status?.label ?? 'Not Started'}
                      </Badge>
                    </div>
                  </div>
                )}
              </div>
              <div className={style.statusContainer}>
                <div className={style.statusText}>Number of docs</div>
                <div>
                  <span className={style.docsNo}>{documents?.length}</span>
                </div>
              </div>
              <div className={style.statusContainer}>
                {is_yearly && (
                  <div>
                    <div className={style.statusText}>year status</div>
                    <div className={style.yearWrapper}>
                      {years &&
                        years.map((year, index) => {
                          return (
                            <div key={index}>
                              <Badge
                                pill
                                className={cx(
                                  style.year,
                                  style[year?.status?.label?.toLowerCase()]
                                )}
                              >
                                {year?.year}
                              </Badge>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={style.rightArrowIcon}>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceDocumentCard;
