import React, { useState } from 'react';
import classes from './DocumentUploadPage.module.scss';
import { useDispatch } from 'react-redux';

import DocumentUpload from './DocumentUpload';
import { cloneDeep } from 'lodash';
import { uploadDocuments } from 'store/actions/utility';
import cx from 'classnames';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from 'reactstrap';
import history from 'helpers/history';
import UtilityDocOptionPreview from './UtilityDocOptionPreview';
import Button from 'components/Button';

const options = [
  { id: '1', label: '1 employee', value: '1' },
  { id: '2', label: '2 employees', value: '2' },
  { id: '3', label: '2x2 employees', value: '2x2' },
  { id: '4', label: '4 employees', value: '4' },
];

const DocumentUploadPage = () => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [values, setValues] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const documentDimensionOptions = {
    '1': null,
    '2': { x: 0, y: 2 },
    '2x2': { x: 2, y: 2 },
    '4': { x: 0, y: 4 },
  };

  const handleUpload = async values => {
    setUploadLoading(true);
    const formData = new FormData();
    formData.append(`name`, values.name);
    formData.append(`type`, values.type.id);

    switch (values.type.id) {
      case 'w2': {
        if (selectedOption !== '1') {
          const dimension = documentDimensionOptions[selectedOption];
          formData.append(`dimensions[x]`, dimension['x']);
          formData.append(`dimensions[y]`, dimension['y']);
        }
        break;
      }
      case '1999': {
      }
    }

    if (files.length > 0) {
      files.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    const { status } = await dispatch(uploadDocuments(formData));
    setUploadLoading(false);
    return status;
  };

  const onDrop = acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
  };

  const handleRemoveFile = index => {
    const fileClone = cloneDeep(files);
    fileClone.splice(index, 1);
    setFiles(fileClone);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <h3>Document Processor</h3>{' '}
        <Breadcrumb
          className={cx('d-none d-md-inline-block ml-md-4', classes.breadcrumb)}
          listClassName="breadcrumb-links"
        >
          <BreadcrumbItem>
            <a href="/admin/dashboard" onClick={e => e.preventDefault()}>
              <i className="fas fa-home" />
            </a>
          </BreadcrumbItem>
          <BreadcrumbItem>Utility Tools</BreadcrumbItem>
          <BreadcrumbItem
            onClick={() => history.push('/admin/utility/document-processor')}
          >
            <span>Document Processor</span>
          </BreadcrumbItem>
          <BreadcrumbItem aria-current="page" className="active">
            Document Uploader
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Card className={classes.card}>
        <CardHeader className={classes.cardHeader}>
          <h3>Upload Files</h3>
        </CardHeader>
        <CardBody className={classes.cardBody}>
          <div>
            <DocumentUpload
              setValues={setValues}
              setIsValid={setIsValid}
              onSubmit={handleUpload}
              files={files}
              onDrop={onDrop}
              removeFile={handleRemoveFile}
            />
          </div>
          {values?.type?.id === 'w2' && (
            <UtilityDocOptionPreview
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              options={options}
            />
          )}
        </CardBody>
        <CardFooter className={classes.cardFooter}>
          <Button
            onClick={() => history.push('/admin/utility/document-processor')}
            color="link"
            className={classes.cancel}
          >
            Cancel
          </Button>
          <Button
            form="document-processor-upload"
            type="submit"
            loading={uploadLoading}
            disabled={
              !isValid ||
              uploadLoading ||
              files.length === 0 ||
              (selectedOption === null && values?.type?.id === 'w2')
            }
            className={classes.upload}
          >
            Upload Files
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default DocumentUploadPage;
