import Request from './request';
const noteBaseURL = '/notes/client/';
export const fetchNotes = async clientId => {
  return Request.call({
    url: `${noteBaseURL}${clientId}`,
    method: 'GET',
  });
};
export const createNote = async (client_id, note) => {
  return Request.call({
    url: `${noteBaseURL}${client_id}`,
    method: 'POST',
    data: note,
  });
};

export const updateNote = async (clientId, note_id, note) => {
  return Request.call({
    url: `${noteBaseURL}${clientId}/${note_id}`,
    method: 'POST',
    data: { description: note.description, is_pinned: note.is_pinned },
  });
};

export const updatePinNote = async (client_id, note_id, is_pinned) => {
  return Request.call({
    url: `${noteBaseURL}${client_id}/pinned/${note_id}`,
    method: 'PUT',
    data: { is_pinned: is_pinned },
  });
};

export const removeNote = async (client_id, note_id) => {
  return Request.call({
    url: `${noteBaseURL}${client_id}/${note_id}`,
    method: 'DELETE',
  });
};
