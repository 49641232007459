import React, { useState, useEffect } from 'react';
import MessagesNotAvailable from './MessagesNotAvailable';

import { useDispatch, useSelector } from 'react-redux';
import classes from './Interactions.module.scss';
import get from 'lodash/get';
import { useParams, useLocation } from 'react-router';
import cx from 'classnames';
import { Card, CardBody } from 'reactstrap';
import Loading from 'components/Loading';

import InfiniteScroll from 'react-infinite-scroller';
import queryString from 'query-string';
import { clearCommentsData } from 'store/actions/Story/comments';

import InteractionFilter from './InteractionFilter';
import GeneralCommentModal from 'components/Fulfillment/Modals/GeneralCommentModal';
import DocumentCommentModal from 'components/Fulfillment/Modals/DocumentCommentModal';
import { getStudyInteractions } from 'store/actions/fulfillment';
import useBreakpoint from 'helpers/useBreakPoint';
import InteractionItem from './InteractionItem';
import { NotificationHandler } from 'components/Notifications';
import history from 'helpers/history';

const modalType = {
  DocumentCommentAdded: 'FinancialYearDocumentType',
  FinancialYearDocumentType: 'FinancialYearDocumentType',
  FileAdded: 'FinancialYearDocumentType',
  CommentAdded: 'Initiative',
};

const Interactions = ({ activeTab }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = useParams();
  const isTablet = useBreakpoint('lg', 'down');
  const initiativeId = get(params, 'id');

  const studyInteractionsMeta = useSelector(({ fulfillment }) =>
    get(fulfillment, 'studyInteractions.data.meta', {})
  );
  const studyInteractionLoading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'studyInteractions.isInProgress', false)
  );

  const current_page = studyInteractionsMeta?.current_page || 0;
  const last_page = studyInteractionsMeta?.last_page || 0;

  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [openStudyModal, setOpenStudyModal] = useState(false);
  const [openDocumentModal, setOpenDocumentModal] = useState(false);
  const [studyInteractions, setStudyInteractions] = useState([]);
  const [filters, setFilters] = useState(null);

  const [modalParams, setModalParams] = useState({
    parent_type: '',
    parent_id: '',
  });

  const [selectedMessageData, setSelectedMessageData] = useState(null);

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const user = useSelector(({ auth }) => get(auth, 'user'));

  useEffect(() => {
    const { modal_type = '', parent_id = '' } = queryProps; //document|study
    if (openStudyModal || openDocumentModal) return;

    if (modal_type === 'document') {
      const params = {
        parent_type: 'FinancialYearDocumentType',
        parent_id: parent_id,
      };
      setModalParams(params);
      setOpenDocumentModal(true);
    }
    if (modal_type === 'study') {
      const params = {
        parent_type: 'Initiative',
        parent_id: parent_id,
      };
      setModalParams(params);
      setOpenStudyModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryProps?.parent_id, queryParams?.modal_type]);

  const fetchStudyInteractions = async (
    page = 1,
    filters = {},
    fromFilter = false,
    updateInteraction = false
  ) => {
    const { data } = await dispatch(
      getStudyInteractions(initiativeId, { page, ...filters })
    );
    if (fromFilter) {
      setStudyInteractions([...data]);
    } else if (updateInteraction) {
      const updatedInteractions = studyInteractions.map(interaction => {
        const found = data.find(d => d.id === interaction.id);
        if (found) {
          return found;
        }
        return interaction;
      });
      setStudyInteractions(updatedInteractions);
    } else {
      setStudyInteractions([...studyInteractions, ...data]);
    }
  };

  const handleMessageClick = data => {
    setSelectedMessageData(data);
    switch (data.type) {
      case 'CommentAdded':
        history.push(
          `${pathname}?modal_type=study&parent_id=${data?.parent_id}&comment_id=${data?.data?.comment?.id}`
        );
        break;
      case 'DocumentCommentAdded':
      case 'FinancialYearDocumentType':
      case 'DocumentStatusChanged':
      case 'FileAdded':
        history.push(
          `${pathname}?modal_type=document&parent_id=${data?.parent_id}&comment_id=${data?.data?.comment?.id}`
        );
        break;
      default:
    }
  };

  const handleSetFilters = filters => {
    setFilters(filters);
    fetchStudyInteractions(1, filters, true);
  };

  const refreshInteractions = () => {
    fetchStudyInteractions(1, filters, true);
  };

  useEffect(() => {
    activeTab === 'activity_feed' && refreshInteractions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const handleCloseModal = message => {
    setOpenDocumentModal(false);
    if (message != '') {
      NotificationHandler.open({
        title: 'Success',
        message,
        operation: 'success',
        icon: ' ',
      });
    }
  };

  return (
    <>
      {openStudyModal ? (
        <GeneralCommentModal
          parentId={modalParams.parent_id}
          parentType={modalType[modalParams.type]}
          params={modalParams}
          data={selectedMessageData}
          userType="admin"
          isOpen={openStudyModal}
          initiativeId={initiativeId}
          closeModal={() => {
            dispatch(clearCommentsData());
            setOpenStudyModal(false);
            refreshInteractions();
            history.push(`?`);
          }}
        />
      ) : null}
      {openDocumentModal ? (
        <DocumentCommentModal
          params={modalParams}
          userType="admin"
          isOpen={openDocumentModal}
          closeModal={message => {
            handleCloseModal(message);
            dispatch(clearCommentsData());
            setOpenDocumentModal(false);
            refreshInteractions();
            history.push(`?`);
          }}
        />
      ) : null}
      <Card className={classes.card}>
        <InteractionFilter
          handleSetFilters={handleSetFilters}
          filters={filters}
        />
        <CardBody className={cx(classes.notificationList)} key="notifications">
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={async () => {
              if (studyInteractions.length && !studyInteractionLoading) {
                fetchStudyInteractions(current_page + 1, filters);
              }
            }}
            hasMore={current_page < last_page}
            loader={<Loading key="loader" size={50} />}
            useWindow={isTablet ? false : true}
          >
            {studyInteractions.length === 0 && studyInteractionLoading ? (
              <Loading />
            ) : studyInteractions.length === 0 ? (
              <MessagesNotAvailable />
            ) : (
              <div className={cx(classes.messagesWrapper)}>
                {studyInteractions.map((interaction, index) => (
                  <InteractionItem
                    key={`${interaction.id}-${index}`}
                    interaction={interaction}
                    userTimezone={userTimezone}
                    user={user}
                    handleMessageClick={data => {
                      handleMessageClick(data);
                    }}
                  />
                ))}
              </div>
            )}
          </InfiniteScroll>
        </CardBody>
      </Card>
    </>
  );
};

export default Interactions;
