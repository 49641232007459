import Types from 'store/types/timerTypes';
import moment from 'moment-timezone';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { setUserPreference } from 'store/actions/profile';

import get from 'lodash/get';
import {
  postStopTimer,
  postStartTimer,
  getRunningTimer,
  getRecentTimeEntries,
  deleteTimeEntry,
  getTimeEntryById,
  updateTimeEntry,
  postAddTimeEntry,
} from 'api/timerApi';

export const doStartTimer = (startTime = '') => {
  return async (dispatch, getState) => {
    const storeData = getState();
    const timezone = get(storeData, 'auth.user.timezone');

    if (startTime)
      dispatch({
        type: Types.START_TIMER,
        data: moment(startTime)
          .tz(timezone)
          .format(),
      });
    else
      dispatch({
        type: Types.START_TIMER,
        data: moment()
          .tz(timezone)
          .format(),
      });
  };
};

export const doStopTimer = () => {
  return async dispatch => {
    dispatch({
      type: Types.STOP_TIMER,
    });
  };
};

export const doPostStopTimer = (
  dateStart,
  dateEnd,
  initiativeId,
  storyId,
  taskId,
  description,
  discounted
) => {
  return async dispatch => {
    const resp = await postStopTimer(
      dateStart,
      dateEnd,
      initiativeId,
      storyId,
      taskId,
      description,
      discounted
    );

    if (resp.status === 1) {
      NotificationHandler.open({
        title: 'Success',
        message: resp.message,
        operation: 'success',
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostStartTimer = (
  initiativeId = null,
  taskId = null,
  storyId = null,
  description = null,
  discounted = false
) => {
  return async (dispatch, getState) => {
    const storeData = getState();
    const timezone = get(storeData, 'auth.user.timezone');

    const dateStart = moment()
      .tz(timezone)
      .format('YYYY-MM-DD HH:mm:ss');

    dispatch(doStartTimer(dateStart));

    const resp = await postStartTimer(
      dateStart,
      initiativeId,
      storyId,
      taskId,
      description,
      discounted
    );

    if (resp.status === 1) {
      dispatch({
        type: Types.CURRENT_TIME_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      dispatch(doStopTimer());
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doGetRunningTimer = () => {
  return async dispatch => {
    dispatch({
      type: Types.CURRENT_TIME_LOADING,
    });

    const resp = await getRunningTimer();

    if (resp.status === 1) {
      if (resp.data) {
        dispatch({
          type: Types.CURRENT_TIME_SUCCESS,
          status: resp.status,
          data: resp.data,
        });

        dispatch(doStartTimer(resp.data.date_start));
      } else {
        dispatch(doStopTimer());
      }
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.CURRENT_TIME_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doGetRunningTimerFromSocket = data => {
  return async dispatch => {
    if (data) {
      dispatch({
        type: Types.CURRENT_TIME_SUCCESS,
        status: 1,
        data: data,
      });

      dispatch(doStartTimer(data.date_start));
    } else {
      dispatch(doStopTimer());
    }
  };
};

export const doGetRecentTimeEntries = (page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.RECENT_ENTRIES_LOADING,
    });

    const resp = await getRecentTimeEntries(page);

    if (resp.status === 1) {
      dispatch({
        type: Types.RECENT_ENTRIES_SUCCESS,
        status: resp.status,
        data: resp,
      });
    } else {
      dispatch({
        type: Types.RECENT_ENTRIES_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doDeleteTimeEntry = id => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_ENTRY_LOADING,
    });

    const resp = await deleteTimeEntry(id);

    if (resp.status === 1) {
      NotificationHandler.open({
        title: 'Success',
        message: resp.message,
        operation: 'success',
      });

      dispatch({
        type: Types.DELETE_ENTRY_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.DELETE_ENTRY_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doGetTimeEntryById = id => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ENTRY_LOADING,
    });

    let resp = null;
    if (id) resp = await getTimeEntryById(id);
    else
      resp = {
        status: 1,
        data: null,
      };

    if (resp.status === 1) {
      dispatch({
        type: Types.GET_ENTRY_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.GET_ENTRY_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }

    return resp.status;
  };
};

export const doPostUpdateTimeEntry = (
  id,
  startDate,
  endDate,
  initiativeId = null,
  storyId = null,
  taskId = null,
  description = null,
  discounted = false
) => {
  return async dispatch => {
    const resp = await updateTimeEntry(
      id,
      startDate,
      endDate,
      initiativeId,
      storyId,
      taskId,
      description,
      discounted
    );

    if (resp.status === 1) {
      NotificationHandler.open({
        title: 'Success',
        message: resp.message,
        operation: 'success',
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostAddTimeEntry = (
  startDate,
  endDate,
  initiativeId = null,
  storyId = null,
  taskId = null,
  description = null,
  discounted = false
) => {
  return async dispatch => {
    const resp = await postAddTimeEntry(
      startDate,
      endDate,
      initiativeId,
      storyId,
      taskId,
      description,
      discounted
    );

    if (resp.status === 1) {
      NotificationHandler.open({
        title: 'Success',
        message: resp.message,
        operation: 'success',
      });
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp.status;
  };
};

export const doPostUpdateTimeFormat = () => {
  return async (dispatch, getState) => {
    const storeData = getState();
    const timeFormat = get(storeData, 'timer.timeFormat');

    const data = timeFormat === 24 ? 12 : 24;
    dispatch({
      type: Types.TOGGLE_TIME_FORMAT,
      data,
    });

    dispatch(
      setUserPreference({
        category: 'timelog',
        key: 'time_format',
        value: data,
      })
    );
  };
};
