import React, { useState } from 'react';
import cs from 'classnames';
import classes from './PartnerInfoDetails.module.scss';
import {
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Badge,
} from 'reactstrap';
import useBreakPoint from 'helpers/useBreakPoint';
import findIndex from 'lodash/findIndex';
import PartnerComments from './Tabs/Comments/PartnerComments';

import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Loading from 'components/Loading';
import PartnerActivity from './Tabs/Activity/PartnerActivity';

const PartnerInfoDetails = ({
  projectId,
  type = 'partner',
  parentType = 'Company',
  parentId,
  currentTab,
  comment,
  updatedOn,
  createdOn,
  onTabChange,
  activities = [],
  leadId,
}) => {
  const comments = useSelector(({ partner }) =>
    get(partner, 'referralComments.data.data', [])
  );

  const isInitialCall = false;
  const unreadFrom = useSelector(({ partner }) =>
    get(partner, 'referralComments.unreadFrom', null)
  );

  const getCommentsCount = () => {
    if (unreadFrom) {
      const foundIndex = comments.findIndex(c => c.id === unreadFrom);
      if (foundIndex > -1) {
        return comments.length - foundIndex;
      } else {
        return 0;
      }
    }
    return 0;
  };

  const contents = [
    {
      name: 'Comments',
      iconClassName: 'fas fa-comments',
      gradientColor: 'orange',
      pageLink: 'comments',
      showBadge: true,
      count: getCommentsCount(),
    },
  ];

  const getCurrentTab = () => {
    const tabIndex = findIndex(contents, obj => obj.pageLink === currentTab);
    return tabIndex > -1 ? contents[tabIndex].pageLink : contents[0].pageLink;
  };

  const isMobile = useBreakPoint('xs', 'down');
  const [tabs, setTabs] = useState(getCurrentTab());

  const toggleTabs = tab => {
    setTabs(tab);
    onTabChange(tab);
  };
  return (
    <Col
      className={cs(
        classes['right-column'],
        'pb-3 d-flex flex-column',
        isMobile ? 'border-top' : 'border-left',
        { 'w-100': isMobile }
      )}
    >
      <div className={classes.tabWrapper}>
        <Nav className="nav-fill flex-row" id="tabs-icons-text" pills>
          {contents.map(content => (
            <NavItem key={content.pageLink} className={classes.tabItem}>
              <NavLink
                className={
                  tabs === content.pageLink ? 'text-primary' : 'text-muted'
                }
                onClick={() => {
                  toggleTabs(content.pageLink);
                }}
                href="#"
                role="tab"
              >
                <i className={content.iconClassName} />
                {content.name}
                {content.showBadge && content.count ? (
                  <Badge
                    color="danger"
                    className={cs(
                      'badge-sm badge-circle badge-floating border-white',
                      classes.badgeCount
                    )}
                  >
                    {content.count > 99 ? '99+' : content.count}
                  </Badge>
                ) : null}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <TabContent activeTab={tabs} className="h-100">
        <TabPane tabId="comments" className="h-100">
          {isInitialCall ? (
            <Loading />
          ) : (
            <PartnerComments
              type={type}
              parentType={parentType}
              projectId={projectId}
              commentToScroll={comment}
              leadId={leadId}
              parentId={parentId}
            />
          )}
        </TabPane>
        <TabPane tabId="activity" className="h-100">
          <PartnerActivity
            updatedOn={updatedOn}
            createdOn={createdOn}
            activities={activities}
          />
        </TabPane>
      </TabContent>
    </Col>
  );
};

export default PartnerInfoDetails;
