import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Badge } from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './RecentlyViewedStudies.module.scss';
import history from 'helpers/history';
import Button from 'components/Button';
import NoStudy from 'assets/img/theme/NoStudy.png';
import {
  addStudy,
  getRecentlyViewedStudiesList,
  deleteStudy,
} from 'store/actions/fulfillment';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from 'components/Loading';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Input from 'components/FormFields/Input';
import { toNumber } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import Dropdowns from 'components/Dropdowns';
import DeleteStudyModal from 'components/DeleteStudyModal/DeleteStudyModal';
const currentYear = moment().year();

// pass the length previous years
const YEARS_DATA = Array.from({ length: 2 }, (_, index) => ({
  label: currentYear - index,
  value: currentYear - index,
  selected: false,
})).sort((a, b) => a.value - b.value);

const RecentlyViewedStudies = ({
  title = 'Recently Viewed Studies',
  cardClassName = '',
  listParams = {
    type: 'recently_viewed',
  },
  isDocumentPage = false,
  rowAction,
  fetchFirstStudy,
  showActive = false,
  isFulfilment = false,
  clientId,
  clientName,
  showNewStudy = false,
}) => {
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const { initiative_id = null } = queryString.parse(queryParams);
  const [studyId, setStudyId] = useState(initiative_id);

  const studiesMeta = useSelector(({ fulfillment }) =>
    get(fulfillment, 'recentlyViewedStudiesList.data.meta', {})
  );
  const studiesLoading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'recentlyViewedStudiesList.isInProgress', false)
  );
  const addStudyLoading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'addStudy.isInProgress', false)
  );

  const current_page = get(studiesMeta, 'current_page', 0);
  const last_page = get(studiesMeta, 'last_page', 0);
  const [studies, setStudies] = useState([]);
  const [filters, setFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDeleteStudy, setIsOpenDeleteStudy] = useState(false);
  const [years, setYears] = useState(YEARS_DATA);
  const [isConfirm, setIsConfirm] = useState(false);
  const [estimate, setEstimate] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedStudy, setSelectedStudy] = useState(null);

  const rowEvents = {
    onClick: (e, row) => {
      if (rowAction) {
        rowAction(row);
      } else {
        history.push(`/admin/studies/${row.id}/dashboard`);
      }
    },
  };
  useEffect(() => {
    initiative_id && setStudyId(initiative_id);
  }, [initiative_id]);

  useEffect(() => {
    fetchStudies(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchFirstStudy && studies.length > 0 && current_page === 1) {
      fetchFirstStudy(studies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studies, current_page]);

  const fetchStudies = async (page = 1, params = {}, fromSort = false) => {
    const { data } = await dispatch(
      getRecentlyViewedStudiesList({ page, ...params, ...listParams })
    );

    if (fromSort) {
      setStudies([...data]);
    } else {
      setStudies([...studies, ...data]);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
    setIsConfirm(false);
  };

  const handleClick = async () => {
    if (!years.some(year => year.selected)) {
      setErrors({
        ...errors,
        year: 'Please select at least one year',
      });
      return;
    }
    if (!isConfirm) {
      setIsConfirm(true);
    } else {
      const formattedEstimate = parseInt(estimate.replace(/,/g, ''));
      const formattedYears = years
        .filter(year => year.selected)
        .map(year => year.value);

      await dispatch(
        addStudy({
          client_id: clientId,
          years: formattedYears,
          credit_estimate: formattedEstimate,
        })
      );
      fetchStudies(1, {}, true);
      setErrors({});
      setEstimate(null);
      setYears(YEARS_DATA);
      setIsOpen(false);
      setIsConfirm(false);
    }
  };
  const handleStudyYear = value => {
    const updateYears = years.map(year =>
      year.value === value ? { ...year, selected: !year.selected } : year
    );
    setYears(updateYears);
  };
  const getUserActionOptions = row => {
    if (row.pending_for_deletion) return [];
    return [
      {
        text: 'Delete Study',
        onClick: event => {
          event.stopPropagation();
          handleDeleteStudy(row);
        },
      },
    ];
  };

  const handleDeleteStudy = row => {
    setSelectedStudy(row);
    setIsOpenDeleteStudy(true);
  };
  const handleDeleteStudyConfirm = async () => {
    setIsOpenDeleteStudy(false);
    await dispatch(deleteStudy(selectedStudy?.id));
    setSelectedStudy(null);
    await fetchStudies(1, {}, true);
  };
  const closeDeleteStudyModal = () => {
    setIsOpenDeleteStudy(false);
    setSelectedStudy(null);
  };
  return (
    <Card
      className={cx(classes.wrapper, {
        [classes.cardwrapper]: isFulfilment,
      })}
    >
      <CardHeader className={classes.cardHeader}>
        <h3 className="mb-0">{title}</h3>
        {studies.length > 0 && listParams?.type && (
          <>
            <span className={classes.tableSubHeader}>Viewed last 48 hours</span>
          </>
        )}
        {showNewStudy && (
          <Button
            color="primary"
            className={classes.addStudyBtn}
            onClick={() => setIsOpen(true)}
          >
            New Study
          </Button>
        )}
      </CardHeader>
      <CardBody className={cx(classes.cardBody, cardClassName)}>
        <InfiniteScroll
          useWindow={false}
          hasMore={current_page < last_page}
          initialLoad={false}
          pageStart={1}
          loadMore={() => {
            if (studies?.length && !studiesLoading) {
              fetchStudies(current_page + 1, filters);
            }
          }}
        >
          {studies?.length === 0 && studiesLoading ? (
            <Loading />
          ) : (
            <BaseTable
              keyField="id"
              defaultSorted={null}
              noDataIndication={() => (
                <div className="text-center mt-5 mb-5">
                  <img src={NoStudy} />

                  <div className="mt-1">
                    {' '}
                    <h3>
                      <b>No Recently Viewed Studies</b>
                    </h3>
                  </div>
                </div>
              )}
              search={false}
              bordered={false}
              loading={studiesLoading}
              paginationOptions={false}
              data={studies}
              classes="mb-0"
              rowClasses={classes.row}
              wrapperClasses={classes.tableWrapper}
              rowEvents={rowEvents}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  const sort =
                    sortOrder === 'desc' ? `-${sortField}` : sortField;
                  setFilters({ sort });
                  fetchStudies(1, { sort }, true);
                }
              }}
              columns={[
                {
                  dataField: 'name',
                  text: 'Study',
                  sort: false,
                  classes: (d, row) => {
                    return showActive && studyId == row.id
                      ? classes.activeName
                      : '';
                  },
                  formatter: (cell, row) => (
                    <>
                      <div
                        title={cell}
                        className={cx(
                          'd-flex align-items-center',
                          classes.name
                        )}
                        id={`project-name-${row.id}`}
                      >
                        <span>{cell || '-'}</span>
                      </div>
                    </>
                  ),
                },
                {
                  dataField: 'study_years',
                  text: 'YEARS',
                  sort: false,
                  hidden: isDocumentPage,
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div
                          className={cx(
                            'd-flex align-items-center',
                            classes['project-name']
                          )}
                          id={`project-phase-${row.id}`}
                        >
                          <span>{cell}</span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'phase',
                  text: 'Phase',
                  sort: false,
                  hidden: isDocumentPage,
                  formatter: (cell = {}, row) => {
                    return (
                      <>
                        <div className="m-0 d-flex align-items-center">
                          {cell?.name && !row?.pending_for_deletion && (
                            <i
                              style={{
                                color: `${cell?.color}`,
                                fontSize: '6px',
                              }}
                              className="fas fa-circle mr-1"
                            />
                          )}
                          {row?.pending_for_deletion && (
                            <Badge color="danger" pill>
                              Pending Deletion
                            </Badge>
                          )}
                          {!row?.pending_for_deletion && (
                            <span> {cell?.name || '-'}</span>
                          )}
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: '',
                  text: '',
                  sort: false,
                  formatter: (cell, row) => {
                    return (
                      <>
                        {!row.pending_for_deletion && (
                          <Dropdowns
                            onClick={event => event.stopPropagation()}
                            text={<i className="fas fa-ellipsis-v" />}
                            className="btn-icon-only m-0 text-light float-right"
                            options={getUserActionOptions(row)}
                            caret={false}
                            size="sm"
                            color=""
                          />
                        )}
                      </>
                    );
                  },
                },
              ]}
            />
          )}
        </InfiniteScroll>
      </CardBody>
      {isOpen && (
        <Modal
          className={classes.modalWrapper}
          isOpen={isOpen}
          toggle={closeModal}
          title="New Study"
        >
          <ModalBody>
            <div className={classes.modalBody}>
              {isConfirm ? (
                <>
                  <div className={classes.modalContent}>
                    You’ll be creating the following study:
                  </div>
                  <div className={classes.studyText}>
                    {clientName} R&D Study
                    {years.map((year, i) => {
                      return (
                        year.selected && <span key={i}>- {year?.label}</span>
                      );
                    })}
                  </div>
                  <div className={classes.modalContent}>Is this correct?</div>
                </>
              ) : (
                <>
                  <div className={classes.yearWrapper}>
                    <div className={classes.modalText}>
                      Select one or more years for the study:
                    </div>
                    <div>
                      {years.map((year, index) => (
                        <Button
                          onClick={() => handleStudyYear(year.value)}
                          key={index}
                          className={cx(classes.yearButton, {
                            [classes.selected]: year.selected,
                          })}
                        >
                          {year.label}
                        </Button>
                      ))}
                    </div>
                    {errors.year && (
                      <div className={classes.error}>{errors?.year}</div>
                    )}
                  </div>
                  <div className={classes.creditEstimate}>
                    <div className={classes.modalText}>Credit Estimate</div>
                    <Input
                      min={0}
                      value={estimate}
                      leftIcon="$"
                      placeholder="Enter estimate"
                      onChange={event => {
                        const budgetNumber = event.target.value.replace(
                          /,/g,
                          ''
                        );
                        if (
                          !isNaN(budgetNumber) &&
                          toNumber(budgetNumber) >= 0
                        ) {
                          setErrors({
                            ...errors,
                            estimate: '',
                          });
                          setEstimate(budgetNumber);
                        }
                      }}
                      onBlur={event => {
                        if (!estimate) {
                          setErrors({
                            ...errors,
                            estimate: 'Credit estimate is required.',
                          });
                          return;
                        } else {
                          setErrors({
                            ...errors,
                            estimate: '',
                          });
                        }
                        if (toNumber(event.target.value) > 0) {
                          setEstimate(
                            numeral(event.target.value).format('0,0')
                          );
                        }
                      }}
                    />
                    {errors.estimate && (
                      <div className={classes.error}>{errors?.estimate}</div>
                    )}
                  </div>
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter className={classes.modalFooter}>
            <Button
              className={classes.cancelBtn}
              onClick={() => {
                if (isConfirm) {
                  setIsConfirm(false);
                } else {
                  closeModal();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.addBtn}
              onClick={handleClick}
              disabled={(!isConfirm && !estimate) || addStudyLoading}
              loading={addStudyLoading}
            >
              {isConfirm ? 'Confirm' : 'Add Study'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {isOpenDeleteStudy && (
        <DeleteStudyModal
          isOpen={isOpenDeleteStudy}
          closeModal={closeDeleteStudyModal}
          handleConfirm={handleDeleteStudyConfirm}
        ></DeleteStudyModal>
      )}
    </Card>
  );
};

export default RecentlyViewedStudies;
