import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_BAR_CHART_LOADING',
  'GET_BAR_CHART_SUCCESS',
  'GET_BAR_CHART_ERROR',
  'GET_DOUGHNUT_CHART_LOADING',
  'GET_DOUGHNUT_CHART_SUCCESS',
  'GET_DOUGHNUT_CHART_ERROR',
  'SUMMARY_GROUP_LOADING',
  'SUMMARY_GROUP_SUCCESS',
  'SUMMARY_GROUP_ERROR',
  'SUMMARY_SUBGROUP_LOADING',
  'SUMMARY_SUBGROUP_SUCCESS',
  'SUMMARY_SUBGROUP_ERROR',
  'TIMELOG_REPORT_DETAILS_LOADING',
  'TIMELOG_REPORT_DETAILS_SUCCESS',
  'TIMELOG_REPORT_DETAILS_ERROR',
  'TIMELOG_REPORT_ACTION_LOADING',
  'TIMELOG_REPORT_ACTION_SUCCESS',
  'TIMELOG_REPORT_ACTION_ERROR'
);
