import React, { useRef, useState } from 'react';
import classes from './TabContract.module.scss';
import { Col, Row } from 'reactstrap';
import cx from 'classnames';

import PreviewIcon from 'assets/img/icons/deal-desk/preview-icon.svg';
import EyesIcon from 'assets/img/icons/deal-desk/eyes-icon.svg';
import InIcon from 'assets/img/icons/deal-desk/in-icon.svg';
import LinkIcon from 'assets/img/icons/deal-desk/link-icon.svg';
import EditIcon from 'assets/img/icons/deal-desk/edit-icon.svg';

import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import CommentFeature from 'views/pages/DealDesk/DealDeskModal/CommentFeature';
import ContractPreviewModal from '../ContractPreviewModal/ContractPreviewModal';
import { callGetContractDeatails } from 'store/actions/dealDeskActions';
import Button from 'components/Button';
import { clearDocumentUrl, downloadDocument } from 'store/actions/documents';
import FileViewer from 'components/FileViewer';
import { previewDocument } from 'api/documents';
import RelatedEntityModal from '../RelatedEntityModal/RelatedEntityModal';
import map from 'lodash/map';
import EditContractModal from '../EditContractModal/EditContractModal';

export const TabContract = ({ id, type = 'deals' }) => {
  const dispatch = useDispatch();
  const editRef = useRef(null);
  const [printLoading, setPrintLoading] = useState(false);
  const [editedRelatedEntity, setEditedRelatedEntity] = useState(null);
  const [previewModal, setpreviewModal] = useState({
    isOpen: false,
    data: null,
  });

  const [fileViewer, setFileViewer] = useState({
    isOpen: false,
    data: null,
  });

  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const previewLoading = useSelector(({ dealDesk }) =>
    get(dealDesk, 'contractDetails.loading', false)
  );
  const data = dealDesk?.data;

  const isContractSigned = get(data, 'status.status', '') === 'Signed';

  const linkedinUrl = get(data, 'client.linkedin_url');
  const closeUrl = get(data, 'client.close_url');
  const secretary_state_url = get(data, 'client.secretary_of_state_url');
  const relatedEntities = get(data, 'client.related_entities');
  //get(data, 'client.related_entities');
  const status = data?.status?.sub_status;
  const relatedEntityRef = useRef(null);
  const cc_list = get(data, 'signatory.cc_list', [{ name: '', email: '' }]) ?? [
    { name: '', email: '' },
  ];

  const entity = {
    title: 'Entity',
    name: 'entity',
    fields: [
      {
        label: 'Entity Name',
        name: 'name',
        value: get(data, 'client.name'),
        required: true,
      },
      {
        label: 'Entity Address',
        name: 'address',
        value: get(data, 'address.street'),
        required: true,
      },
      {
        label: 'Entity Address Line 2',
        name: 'address_2',
        value: get(data, 'address.address_2'),
      },
      {
        label: 'Entity City',
        name: 'city',
        value: get(data, 'address.city'),
        required: true,
      },
      {
        label: 'Entity State',
        name: 'state',
        value: get(data, 'address.state'),
        required: true,
      },
      {
        label: 'Entity Zip',
        name: 'zip',
        value: get(data, 'address.zip'),
        required: true,
      },
    ],
  };

  const signatory = {
    title: 'Signatory',
    name: 'signatory',
    fields: [
      {
        label: 'Signatory Name',
        name: 'name',
        value: get(data, 'signatory.name'),
        required: true,
      },
      {
        label: 'Signatory Email Address',
        name: 'email',
        value: get(data, 'signatory.email'),
        required: true,
      },
      {
        label: '',
        type: 'field_array',
        name: 'cc_list',
        value: [...cc_list],
      },
    ],
  };

  let plan = {};

  const dealsPlan = {
    title: 'Plan Options',
    name: 'options',
    fields: Object.keys(get(data, 'plan_options', {})).map(p => {
      return {
        label: p,
        name: p,
        value: get(data, `plan_options.${p}`),
        type: 'select',
      };
    }),
  };
  const referralPlan = {
    title: 'Referral Fee',
    name: 'referral_option',
    fields: [
      {
        label: 'Referral %',
        name: 'referral_fee',
        value: get(data, `referral_fee`),
        type: 'select',
      },
    ],
  };
  if (type === 'deals') {
    plan = dealsPlan;
  } else {
    plan = referralPlan;
  }

  const applicable_years = {
    title: 'Applicable Years',
    name: 'applicable_years',
    fields: Object.keys(get(data, 'applicable_years', {})).map(p => {
      return {
        label: p,
        name: p,
        value: get(data, `applicable_years.${p}`),
      };
    }),
  };

  const handlePreviewModalOpen = async () => {
    const { status, data = null } = await dispatch(
      callGetContractDeatails(
        id,
        type === 'deals' ? 'deal-desk' : 'partner-deal-desk'
      )
    );
    if (status === 1) {
      setpreviewModal(prev => ({ ...prev, isOpen: true, data: data.contract }));
    }
  };

  const handleFileViewer = () => {
    setFileViewer(prev => ({ ...prev, isOpen: false, data: null }));
    dispatch(clearDocumentUrl());
  };

  const handleViewSignedContract = () => {
    const [document] = get(data, 'contract.documents', [null]);
    if (document) {
      setFileViewer(prev => ({ ...prev, isOpen: true, data: document }));
    }
  };

  const handlePrintContract = async () => {
    setPrintLoading(true);
    const [document] = get(data, 'contract.documents', [null]);
    if (document.id) {
      const { url = '' } = await previewDocument(document.id);
      if (url) window.open(url, '_blank');
    }
    setPrintLoading(false);
  };
  return (
    <>
      <RelatedEntityModal
        ref={relatedEntityRef}
        id={id}
        relatedEntity={editedRelatedEntity}
      />
      {previewModal.isOpen ? (
        <ContractPreviewModal
          isOpen={previewModal.isOpen}
          toggle={() => setpreviewModal(prev => ({ ...prev, isOpen: false }))}
          previewDetails={previewModal.data}
        />
      ) : null}
      {fileViewer.isOpen ? (
        <FileViewer
          isOpen={fileViewer.isOpen}
          toggle={handleFileViewer}
          fileData={fileViewer.data}
          title={data?.contract?.title || ''}
        />
      ) : null}
      <div className={classes.tabContract}>
        <Row className={classes.topContract}>
          <Col md={7} className="text-left">
            <span className={classes.title}>
              Contract Template Type: {data?.type}
            </span>
            {isContractSigned ? (
              <>
                <Button
                  loading={previewLoading}
                  className={classes.btPreview}
                  onClick={handleViewSignedContract}
                  leftIcon={<img src={PreviewIcon} />}
                >
                  View Contract
                </Button>
                <Button
                  loading={printLoading}
                  className={classes.btPreviewOutline}
                  onClick={handlePrintContract}
                  leftIcon={<i className="fa fa-print" aria-hidden="true"></i>}
                >
                  Print Contract
                </Button>
              </>
            ) : (
              <Button
                loading={previewLoading}
                className={classes.btPreview}
                onClick={handlePreviewModalOpen}
                leftIcon={<img src={PreviewIcon} />}
              >
                Preview Contract
              </Button>
            )}
          </Col>
          <Col md={5} className="text-right">
            {closeUrl && (
              <button
                className={classes.link}
                onClick={() => window.open(closeUrl, '_blank')}
              >
                <img src={EyesIcon} />
              </button>
            )}

            {linkedinUrl && (
              <button
                className={classes.link}
                onClick={() => window.open(linkedinUrl, '_blank')}
              >
                <img src={InIcon} />
              </button>
            )}

            {secretary_state_url && (
              <button
                className={classes.link}
                onClick={() => window.open(secretary_state_url, '_blank')}
              >
                <img src={LinkIcon} /> State Legislature
              </button>
            )}
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <div className={classes.box}>
              <div className={classes.heading}>
                <span>Entity</span>

                {status !== 'Signed' && (
                  <button onClick={() => editRef.current.open(entity)}>
                    <img src={EditIcon} /> Edit
                  </button>
                )}
              </div>

              <Row>
                <Col lg={12}>
                  <div className={classes.label}>
                    Entity Name{' '}
                    <CommentFeature
                      field="client.name"
                      label="Entity Name"
                      value={entity.fields.find(e => e.name === 'name').value}
                    />
                  </div>
                  <div className={classes.value}>
                    {entity.fields.find(e => e.name === 'name').value}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className={classes.label}>
                    Entity Address 1{' '}
                    <CommentFeature
                      field="client.address.street"
                      label="Entity Address 1"
                      value={
                        entity.fields.find(e => e.name === 'address').value
                      }
                    />
                  </div>
                  <div className={classes.value}>
                    {entity.fields.find(e => e.name === 'address').value}
                  </div>
                </Col>

                <Col lg={12}>
                  <div className={classes.label}>
                    Entity Address 2{' '}
                    <CommentFeature
                      field="client.address.address_2"
                      label="Entity Address 2"
                      value={
                        entity.fields.find(e => e.name === 'address_2').value
                      }
                    />
                  </div>
                  <div className={classes.value}>
                    {entity.fields.find(e => e.name === 'address_2').value}
                  </div>
                </Col>

                <Col lg={4}>
                  <div className={classes.label}>
                    Entity City{' '}
                    <CommentFeature
                      field="client.address.city"
                      label="Entity City"
                      value={entity.fields.find(e => e.name === 'city').value}
                    />
                  </div>
                  <div className={classes.value}>
                    {entity.fields.find(e => e.name === 'city').value}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={classes.label}>
                    Entity State{' '}
                    <CommentFeature
                      field="client.address.state"
                      label="Entity State"
                      value={entity.fields.find(e => e.name === 'state').value}
                    />
                  </div>
                  <div className={classes.value}>
                    {entity.fields.find(e => e.name === 'state').value}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className={classes.label}>
                    Entity Zip{' '}
                    <CommentFeature
                      field="client.address.zip"
                      label="Entity Zip"
                      value={entity.fields.find(e => e.name === 'zip').value}
                    />
                  </div>
                  <div className={classes.value}>
                    {entity.fields.find(e => e.name === 'zip').value}
                  </div>
                </Col>
              </Row>
              {map(relatedEntities, (relatedEntity, i) => (
                <div key={i}>
                  <div className={classes.heading}>
                    <span>Related Entity</span>

                    {status !== 'Signed' && (
                      <button
                        onClick={() => {
                          setEditedRelatedEntity(relatedEntity);
                          relatedEntityRef.current.open();
                        }}
                      >
                        <img src={EditIcon} /> Edit
                      </button>
                    )}
                  </div>
                  <Row>
                    <Col lg={12}>
                      <div className={classes.label}>Entity Name </div>
                      <div className={classes.value}>
                        {relatedEntity.entity_name}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className={classes.label}>Entity Address </div>
                      <div className={classes.value}>
                        {relatedEntity.address1}
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className={classes.label}>Entity City </div>
                      <div className={classes.value}>{relatedEntity.city}</div>
                    </Col>
                    <Col lg={4}>
                      <div className={classes.label}>Entity State </div>
                      <div className={classes.value}>{relatedEntity.state}</div>
                    </Col>
                    <Col lg={4}>
                      <div className={classes.label}>Entity Zip </div>
                      <div className={classes.value}>{relatedEntity.zip}</div>
                    </Col>
                  </Row>
                </div>
              ))}
              {(data?.type === 'ERTC' || data?.type === 'R&D') && (
                <Row>
                  <button
                    type="button"
                    className={classes.addEntity}
                    onClick={() => {
                      setEditedRelatedEntity(null);
                      relatedEntityRef.current.open();
                    }}
                  >
                    + Add Related Entity
                  </button>
                </Row>
              )}
            </div>
            <div className={classes.box}>
              <div className={classes.heading}>
                <span>Signatory</span>
                {status !== 'Signed' && (
                  <button onClick={() => editRef.current.open(signatory)}>
                    <img src={EditIcon} /> Edit
                  </button>
                )}
              </div>
              <Row>
                <Col lg={6}>
                  <div className={classes.label}>
                    Signatory Name{' '}
                    <CommentFeature
                      field="client.signatory.name"
                      label="Signatory Name"
                      value={
                        signatory.fields.find(e => e.name === 'name').value
                      }
                    />
                  </div>
                  <div className={classes.value}>
                    {signatory.fields.find(e => e?.name === 'name')?.value}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className={classes.label}>
                    Signatory Email Address{' '}
                    <CommentFeature
                      field="client.signatory.email"
                      label="First Email Address"
                      value={
                        signatory.fields.find(e => e.name === 'email').value
                      }
                    />
                  </div>
                  <div className={classes.value}>
                    {signatory.fields.find(e => e.name === 'email').value}
                  </div>
                </Col>

                {cc_list.map(({ name = '', email = '' }) => {
                  return (
                    <>
                      <Col lg={6}>
                        <div className={classes.label}>
                          CC Name{' '}
                          <CommentFeature
                            field="client.signatory.cc_name"
                            label="CC Name"
                            value={name}
                          />
                        </div>
                        <div className={classes.value}>{name || '-'}</div>
                      </Col>

                      <Col lg={6}>
                        <div className={classes.label}>
                          CC Email Address{' '}
                          <CommentFeature
                            field="client.signatory.cc_email"
                            label="CC Email Address"
                            value={email}
                          />
                        </div>
                        <div
                          className={cx(classes.value, classes.signatoryEmail)}
                        >
                          {email || '-'}
                        </div>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </div>
          </Col>
          <Col md={6}>
            <div className={classes.box}>
              <div className={classes.heading}>
                {type === 'deals' ? (
                  <span>Plan Options</span>
                ) : (
                  <span>Referral Fee</span>
                )}
                {status !== 'Signed' && (
                  <button onClick={() => editRef.current.open(plan)}>
                    <img src={EditIcon} /> Edit
                  </button>
                )}
              </div>
              <Row>
                {plan.fields.map((p, index) => (
                  <Col lg={6} key={index}>
                    <div className={classes.label}>
                      {p.label}{' '}
                      <CommentFeature
                        field={`client.plan_options.${p.label}`}
                        label={p.label}
                        value={p.value}
                      />
                    </div>
                    <div className={classes.value}>{p.value || '-'}</div>
                  </Col>
                ))}
              </Row>
            </div>

            {applicable_years.fields.length > 0 && (
              <div className={classes.box}>
                <div className={classes.heading}>
                  <span>Applicable Years</span>
                  {status !== 'Signed' && (
                    <button
                      onClick={() => editRef.current.open(applicable_years)}
                    >
                      <img src={EditIcon} /> Edit
                    </button>
                  )}
                </div>
                <Row>
                  {applicable_years.fields.map((p, index) => (
                    <Col lg={6} key={index}>
                      <div className={classes.label}>
                        {p.label}
                        <CommentFeature
                          field={`client.applicable_years.${p.label}`}
                          label={p.label}
                          value={p.value}
                        />
                      </div>
                      <div className={classes.value}>{p.value || '-'}</div>
                    </Col>
                  ))}
                </Row>
              </div>
            )}

            {type === 'deals' && (
              <div className={classes.box}>
                <div className={classes.heading}>
                  <span>Service Order</span>
                </div>
                <Row>
                  <Col lg={6}>
                    <div className={classes.label}>
                      Entity Type{' '}
                      <CommentFeature
                        field={`client.type`}
                        label={'Entity Type'}
                        value={data?.type}
                      />
                    </div>
                    <div className={classes.value}>{data?.type}</div>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>

        <EditContractModal ref={editRef} id={id} type={type} />
      </div>
    </>
  );
};
