import React from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import get from 'lodash/get';
import Activity from 'components/Activity';
import Loading from 'components/Loading';
import classes from '../Tabs.module.scss';
import orderBy from 'lodash/orderBy';

const LeadActivity = ({ updatedOn, createdOn }) => {
  const activities = useSelector(({ partner }) =>
    get(partner, 'activities.data.activities', [])
  );

  const isInProgress = useSelector(({ partner }) =>
    get(partner, 'activity.activities.isInProgress', false)
  );

  if (isInProgress) {
    return (
      <div className="h-100 w-100 d-flex align-items-center justify-content-start">
        <Loading key="loader" size={100} />
      </div>
    );
  }
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <div
        className={cs(
          'd-flex flex-column justify-content-between',
          classes.customScrollBar,
          classes.activityWrapper
        )}
      >
        {orderBy(activities, ['date_created'], 'desc').map((d, i) => (
          <Activity key={i} item={d} />
        ))}
      </div>
      <div className={cs('mt-auto border-top', classes.dateDetails)}>
        <div className="text-light mt-3">
          <h6>Updated On: {updatedOn}</h6>
          <h6>Created On: {createdOn}</h6>
        </div>
      </div>
    </div>
  );
};

export default LeadActivity;
