import React from 'react';
import classes from './MacroSideBar.module.scss';
import MacroSideBarContent from './MacroSideBarContent';
import { useHistory } from 'react-router-dom';

const MacroSideBar = ({ content }) => {
  const history = useHistory();

  const handleClick = (index, block) => {
    const { completed = false, active } = block;
    if (!completed || !active) return;
    return history.push({ pathname: `/admin/onboarding/${active}` });
  };

  return (
    <div className={classes.sideBar}>
      {content && content.length
        ? content.map((c, i) => {
            return (
              <MacroSideBarContent
                key={i}
                onClick={() => {
                  handleClick(i, c);
                }}
                icon={c.icon}
                description={c.description}
                label={c.label}
                active={c.isCurrent || c.completed}
                isLast={c.isLast}
                completed={c.completed}
              />
            );
          })
        : null}
    </div>
  );
};

export default MacroSideBar;
