import React from 'react';
import classnames from 'classnames';
import classes from './AdminNavbar.module.scss';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const BreadCrumbs = ({ props }) => {
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );
  const unreadComments = useSelector(({ story }) =>
    get(story, 'comments.unreadCount.count', null)
  );

  const studyName = get(currentStudy, 'name', '');
  const path = get(props, 'location.pathname');

  const getMessage = () => {
    return (
      <div className="d-flex">
        messages
        {unreadComments > 0 && (
          <span className={classes.headerBadge}>{unreadComments}</span>
        )}
      </div>
    );
  };

  const getBreadcrumbs = () => {
    switch (path) {
      case '/admin/dashboard':
        return ['Dashboard'];
      case `/admin/studies/${path.split('/').slice(-1)}`:
        return ['Studies', 'Active Studies', studyName];
      case `/admin/studies`:
        return ['Studies'];
      case `/admin/client/messages`:
        return [getMessage()];

      default:
        return [];
    }
  };

  return (
    <div className={classes.clientBreadCrumbs}>
      <Breadcrumb className={classes.breadcrumbs}>
        {getBreadcrumbs().map((path, i) => {
          return (
            <BreadcrumbItem
              className={classnames(classes.breadcrumb, {
                [classes.main]: i === 0,
              })}
              key={path}
              active
            >
              {path}
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumbs;
