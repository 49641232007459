import React, { useEffect, useState, useCallback } from 'react';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import StoryName from './Fields/StoryName';
import Epic from './Fields/Epic';
import Description from './Fields/Description';
import AttachFile from './Fields/FileAttachment';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import classes from './StoryModal.module.scss';
import classnames from 'classnames';
import {
  updateStory,
  assignEpicToStory,
  assignStoryOwner as assignStoryOwnerAction,
  updateSelectedStoryStatus,
  updateStoryPriority,
  fetchStory as fetchStoryAction,
} from 'store/actions/Story/details';
import { clearCommentsData } from 'store/actions/Story/comments';
import moment from 'moment';
// import ScrollBar from 'react-perfect-scrollbar';
import isEmpty from 'lodash/isEmpty';
import useBreakPoint from 'helpers/useBreakPoint';
import trim from 'lodash/trim';
import isEqual from 'lodash/isEqual';
import { permissions, useAccess } from 'helpers/permission';
import find from 'lodash/find';
import StoryDetails from './StoryDetails';
import PropTypes from 'prop-types';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import socket from 'helpers/socket';
import { cleanUpMentions } from 'helpers/mentions';
import StoryAssignments from './StoryAssignments';
import replace from 'lodash/replace';
import toNumber from 'lodash/toNumber';
import analytics, { analyticsConstants } from 'helpers/analytics';
import {
  didAllOnboardingFlowCompleted,
  setUserPreference,
  updateActiveTourStatus,
} from 'store/actions/profile';
import Loading from 'components/Loading';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { deleteStory } from 'store/actions/backlogs';

const StoryModal = ({
  isOpen,
  closeModal,
  projectId: recievedProjectId,
  storyId: recievedStoryId,
  onChange,
  currentTab: parentCurrentTab = 'overview',
  onTabChange,
  comment,
  openDuplicateModal,
}) => {
  const dispatch = useDispatch();

  const isMobile = useBreakPoint('xs', 'down');
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };

  const sectionStoriesData = useSelector(({ backlog }) =>
    get(backlog, `itemsState.data`, [])
  );

  useEffect(() => {
    if (recievedStoryId) {
      dispatch(fetchStoryAction(recievedStoryId));
      socket.joinAndListenComments('Story', recievedStoryId);
      return () => {
        socket.leaveComments();
        dispatch(clearCommentsData());
      };
    }
    // used when notification is sent to user
    const leftColumn = document.getElementById('storyModal-leftColumn');
    if (leftColumn) leftColumn.scrollTop = 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recievedProjectId, recievedStoryId]);

  const isStoryFetchInProgress = useSelector(({ story }) =>
    get(story, 'details.isInProgress', false)
  );

  const projectMemberDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data.team_members', [])
  );
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));

  const isUserMemberOfTeam = find(
    projectMemberDetails,
    member => member.id === userId
  );

  const isUserAllowedEdit = useAccess([
    permissions.EDIT_ALL_STORIES,
    { permission: permissions.EDIT_PROJECT_STORIES, value: isUserMemberOfTeam },
  ]);

  const isStoryDeleteAllowed = useAccess([
    permissions.DELETE_ALL_STORIES,
    {
      permission: permissions.DELETE_MY_STORIES,
      value: isUserMemberOfTeam,
    },
  ]);

  const isStoryDuplicateAllowed = useAccess([
    permissions.DUPLICATE_ALL_STORIES,
    { permission: permissions.DUPLICATE_MY_STORIES, value: isUserMemberOfTeam },
  ]);

  const [editField, setEditField] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);

  const isStoryFetchError = useGetFieldFromObject(
    'story',
    'details.isError',
    false
  );
  const storyFetchErrorMessage = useGetFieldFromObject(
    'story',
    'details.message',
    'Something went wrong. Please try again'
  );
  const errorStoryId = useGetFieldFromObject('story', 'details.id');

  const isStoryError =
    isStoryFetchError && toNumber(errorStoryId) === recievedStoryId;

  const storyName = useGetFieldValue('name');
  const epic = useGetFieldValue('epic');
  const storyNumber = useGetFieldValue('number', '0');
  const storyId = useGetFieldValue('id');
  const description = useGetFieldValue('description');
  const owner = {
    name: useGetFieldValue('owner.name'),
    avatar: useGetFieldValue('owner.avatar'),
  };
  const projectId = useGetFieldValue('initiative.id');
  const projectName = useGetFieldValue('initiative.name');
  const score = useGetFieldValue('score');
  const status = {
    color: useGetFieldValue('status.color'),
    status: useGetFieldValue('status.status'),
  };
  const createdBy = {
    name: useGetFieldValue('created_by.name'),
    avatar: useGetFieldValue('created_by.avatar'),
  };
  const attachments = useGetFieldValue('attachments', []);

  const priority = useGetFieldValue('priority', null);
  const complexity = useGetFieldValue('complexity', null);
  const priorities = useGetFieldFromObject('story', 'priorityOptions.data', []);
  const complexities = useGetFieldFromObject(
    'story',
    'complexityOptions.data',
    []
  );
  const scoreMatrix = useGetFieldFromObject('story', 'scoreMatrix.data', []);
  const useGetTime = field =>
    moment(useGetFieldValue(field, moment()))
      .tz(userTimeZone)
      .format('MMMM DD, YYYY');
  const createdOn = useGetTime('created_at');
  const updatedOn = useGetTime('updated_at');
  if (
    !isStoryFetchInProgress &&
    projectId !== recievedProjectId &&
    recievedStoryId === storyId
  )
    closeModal();

  const updateStoryTitle = async ({ target: { value } }) => {
    if (!isEmpty(trim(value)) && !isEqual(trim(value), storyName)) {
      analyticsSendEvent({
        action: analyticsConstants.action.update_story_name,
      });

      await dispatch(updateStory(storyId, { name: value }));
    }
    setEditField();
    onChange();
  };

  const updateStoryDescription = async value => {
    await dispatch(
      updateStory(storyId, {
        description: isEqual(value, '<p><br></p>')
          ? null
          : replace(cleanUpMentions(value), /\s{2,}/g, ' '),
      })
    );
    setEditField();
    onChange();
  };
  const assignStoryOwner = async ({ id }) => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_story_owner,
      updated_from: 'Story Modal',
    });
    await dispatch(assignStoryOwnerAction(storyId, id));
    setEditField();
    onChange();
  };

  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };

  const changeStatus = async item => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_story_status,
      updated_from: 'Story Modal',
    });
    await dispatch(updateSelectedStoryStatus(storyId, item));
    onChange();
  };

  const ConditionallyRenderScrollBar = useCallback(
    ({ children }) => {
      return isMobile ? (
        children
      ) : (
        <div className={classes.scrollBar} id="storyModal-leftColumn">
          {children}
        </div>
      );
    },
    [isMobile]
  );

  const changePriority = async data => {
    analyticsSendEvent({
      action: analyticsConstants.action.update_story_priority,
      updated_from: 'Story Modal',
    });
    await dispatch(updateStoryPriority(storyId, data));
    onChange();
  };

  useEffect(() => {
    if (isStoryError) {
      NotificationHandler.open({
        message: storyFetchErrorMessage,
        operation: 'failure',
      });
      closeModal();
    }
  }, [closeModal, isStoryError, storyFetchErrorMessage]);

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour = preferences.find(
    p => p.category === 'onboarding_tour'
  );

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const handleModalClose = async () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'story_creation'
    ) {
      const isWithCreateProject = quickStartTour.withCreateProject;
      await dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
          withCreateProject: false,
        })
      );
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action:
          analyticsConstants.action.complete_create_a_story_onboarding_flow,
      });
      if (isWithCreateProject) {
        analytics.sendEvent({
          category: analyticsConstants.category.onboarding,
          action:
            analyticsConstants.action.complete_create_a_project_onboarding_flow,
        });
      }
      dispatch(
        setUserPreference({
          ...onBoardingTour,
          value: {
            ...get(onBoardingTour, 'value', {}),
            status: 'Active',
            steps: {
              ...get(onBoardingTour, 'value.steps', {}),
              story_creation: {
                status: 'Completed',
              },
              ...(isWithCreateProject
                ? {
                    project_creation: {
                      status: 'Completed',
                    },
                  }
                : {}),
            },
          },
        })
      );
      didAllOnboardingFlowCompleted();
    }
    closeModal();
  };

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleDuplicateClick = () => {
    closeModal();
    openDuplicateModal({ id: storyId, name: storyName });
  };

  const confirmDelete = () => {
    analyticsSendEventStory({
      action: analyticsConstants.action.delete_story,
    });
    let foundSectionId = null;
    const currentSectionIds = Object.keys(sectionStoriesData);
    for (let i = 0; i < currentSectionIds.length; i++) {
      const currentSection = sectionStoriesData[currentSectionIds[i]];
      if (currentSection && currentSection.data && currentSection.data.length) {
        const found = currentSection.data.findIndex(s => s.id === storyId);
        if (found > -1) {
          foundSectionId = currentSectionIds[i];
        }
      }
      if (foundSectionId) {
        break;
      }
    }
    dispatch(deleteStory(storyId, foundSectionId));
  };

  const handleDeleteClick = () => {
    closeModal();
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Story',
      text: `You are about to delete this story. Do you want to continue?`,
      data: {},
    });
  };

  const dropdownOptions = [
    ...(isStoryDuplicateAllowed
      ? [
          {
            text: 'Duplicate Story',
            onClick: handleDuplicateClick,
          },
        ]
      : []),
    ...(isStoryDeleteAllowed
      ? [
          {
            text: 'Delete Story',
            onClick: handleDeleteClick,
          },
        ]
      : []),
  ];

  return (
    <Modal
      size="lg"
      scrollable
      fade={false}
      title={
        isStoryFetchInProgress ? (
          ''
        ) : (
          <div className="d-flex align-items-center">
            <span>Task ID-{storyNumber}</span>
            {isStoryDuplicateAllowed || isStoryDeleteAllowed ? (
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
                className={classes.storyDropdown}
              >
                <DropdownToggle
                  caret={false}
                  size="sm"
                  className="ml-2"
                  color=""
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                  {dropdownOptions.map((item, index) => {
                    return (
                      <DropdownItem
                        key={index}
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          item.onClick();
                        }}
                      >
                        {item.text}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            ) : null}
          </div>
        )
      }
      toggle={handleModalClose}
      isOpen={isOpen && !isStoryError}
    >
      <ModalBody
        className={classnames(
          'p-0 d-flex flex-row flex-wrap',
          classes['story-modal'],
          { [classes['story-modal-mobile']]: isMobile }
        )}
        id="storyModal-body"
      >
        {isStoryFetchInProgress ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <>
            <ConditionallyRenderScrollBar>
              <Col className={classnames(classes['left-column'], 'py-3')}>
                <StoryName
                  editField={editField}
                  storyName={storyName}
                  updateStoryTitle={updateStoryTitle}
                  isEditAllowed={isUserAllowedEdit}
                  onClick={() => {
                    setEditField('story name');
                  }}
                />
                <Epic
                  isEditAllowed={isUserAllowedEdit}
                  epic={epic}
                  onChange={async epic => {
                    setEditField();
                    analyticsSendEvent({
                      action: analyticsConstants.action.update_epic,
                      updated_from: 'Story Modal',
                    });
                    await dispatch(
                      assignEpicToStory(storyId, get(epic, 'id', null))
                    );
                    onChange();
                  }}
                  projectId={projectId}
                />
                <Description
                  editField={editField}
                  isEditAllowed={isUserAllowedEdit}
                  description={description}
                  changeDescription={updateStoryDescription}
                  onCancel={() => {
                    setEditField();
                  }}
                  isMobile={isMobile}
                  projectId={projectId}
                  onClick={() => {
                    if (editField !== 'description') {
                      setEditField('description');
                    }
                  }}
                />
                <AttachFile
                  storyId={storyId}
                  attachments={attachments}
                  isEditAllowed={isUserAllowedEdit}
                />
                <StoryAssignments
                  isEditAllowed={isUserAllowedEdit}
                  projectId={projectId}
                  storyId={storyId}
                  onChange={onChange}
                />
              </Col>
            </ConditionallyRenderScrollBar>
            <StoryDetails
              projectId={projectId}
              storyId={storyId}
              currentTab={parentCurrentTab}
              comment={comment}
              assignStoryOwner={assignStoryOwner}
              isUserAllowedEdit={isUserAllowedEdit}
              owner={owner}
              projectName={projectName}
              priority={priority}
              complexity={complexity}
              complexities={complexities}
              scoreMatrix={scoreMatrix}
              score={score}
              priorities={priorities}
              createdBy={createdBy}
              updatedOn={updatedOn}
              createdOn={createdOn}
              status={status}
              changeStatus={changeStatus}
              changePriority={changePriority}
              onTabChange={onTabChange}
              analyticsSendEvent={analyticsSendEvent}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

StoryModal.propTypes = {
  projectId: PropTypes.number.isRequired,
  storyId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
  currentTab: PropTypes.oneOf(['comments', 'activity', 'info']),
  comment: PropTypes.number,
};

StoryModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
  onTabChange: () => {},
  currentTab: 'comments',
};
export default StoryModal;
