import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_CLIENTS_INPROGRESS',
  'FETCH_CLIENTS_SUCCESS',
  'FETCH_CLIENTS_FAILURE',
  'CREATE_CLIENT_INPROGRESS',
  'CREATE_CLIENT_SUCCESS',
  'CREATE_CLIENT_FAILURE',
  'DELETE_CLIENT_INPROGRESS',
  'DELETE_CLIENT_SUCCESS',
  'DELETE_CLIENT_FAILURE'
);
