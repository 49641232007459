import React from 'react';
import classes from './Tabs.module.scss';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import cs from 'classnames';

const Tabs = ({
  tabs = [],
  activeTab = '',
  navClassName = '',
  NavItemClassName = '',
  NavLinkClassName = '',
}) => {
  return (
    <Nav className={cs(classes.nav, navClassName)} tabs>
      {tabs?.map(({ id = '', onClick = () => {}, label = '' }, index) => {
        return (
          <NavItem
            key={index}
            className={cs(classes.navItem, NavItemClassName)}
          >
            <NavLink
              className={cs(classes.navLink, NavLinkClassName, {
                [classes.active]: activeTab === id,
                active: activeTab === id,
              })}
              onClick={onClick}
            >
              {label}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
  activeTab: PropTypes.string,
  navClassName: PropTypes.string,
  NavItemClassName: PropTypes.string,
  NavLinkClassName: PropTypes.string,
};

export default Tabs;
