import Request from './request';
import { store } from 'store/index';
import get from 'lodash/get';
import axios from 'axios';

export const getOcrDocumentTypes = async () => {
  return Request.call({
    url: `/ocr/resources/get-ocr-document-types`,
    method: 'GET',
    params: {
      page: 1,
    },
  });
};
