import Request from './request';

export const postStopTimer = async (
  date_start,
  date_end,
  initiative_id,
  story_id,
  task_id,
  description,
  discounted
) => {
  return Request.call({
    url: '/timelogs/timer/stop',
    method: 'POST',
    data: {
      date_start,
      date_end,
      initiative_id,
      story_id,
      task_id,
      description,
      discounted,
    },
  });
};

export const postStartTimer = async (
  date_start,
  initiative_id,
  story_id,
  task_id,
  description,
  discounted
) => {
  return Request.call({
    url: '/timelogs/timer/start',
    method: 'POST',
    data: {
      date_start,
      initiative_id,
      story_id,
      task_id,
      description,
      discounted,
    },
  });
};

export const getRunningTimer = async () => {
  return Request.call({
    url: '/timelogs/timer/running',
    method: 'GET',
  });
};

export const getRecentTimeEntries = async (page = 1) => {
  return Request.call({
    url: '/timelogs',
    method: 'GET',
    params: { page },
  });
};

export const deleteTimeEntry = async id => {
  return Request.call({
    url: `/timelogs/${id}`,
    method: 'DELETE',
  });
};

export const updateTimeEntry = async (
  id,
  date_start,
  date_end,
  initiative_id,
  story_id,
  task_id,
  description,
  discounted
) => {
  return Request.call({
    url: `/timelogs/${id}`,
    method: 'PUT',
    data: {
      date_start,
      date_end,
      initiative_id,
      story_id,
      task_id,
      description,
      discounted,
    },
  });
};

export const getTimeEntryById = async id => {
  return Request.call({
    url: `/timelogs/${id}`,
    method: 'GET',
  });
};

export const postAddTimeEntry = async (
  date_start,
  date_end,
  initiative_id,
  story_id,
  task_id,
  description,
  discounted
) => {
  return Request.call({
    url: `/timelogs`,
    method: 'POST',
    data: {
      date_start,
      date_end,
      initiative_id,
      story_id,
      task_id,
      description,
      discounted,
    },
  });
};
