import React, { useState } from 'react';
import classes from './UtilityDocOptionPreview.module.scss';
import InfoCircle from 'assets/img/icons/info-circle-dark-icon.svg';
import { FormGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import cx from 'classnames';
import W2Previewer from './W2Previewer';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const UtilityDocOptionPreview = ({
  selectedOption = null,
  setSelectedOption,
  options,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  const handleOpenPreview = () => {
    setShowPreview(true);
  };
  const toggle = () => {
    setShowPreview(!showPreview);
  };

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const viewPdf = {
    '1': <Viewer fileUrl={require('assets/pdfs/w2-1.pdf')} />,
    '2': <Viewer fileUrl={require('assets/pdfs/w2-2.pdf')} />,
    '2x2': <Viewer fileUrl={require('assets/pdfs/w2-2X2.pdf')} />,
    '4': <Viewer fileUrl={require('assets/pdfs/w2-4.pdf')} />,
  };

  const getOption = value => {
    if (!selectedOption) {
      return options[0];
    }
    return options.find(options => options.value === value);
  };

  return (
    <>
      {showPreview ? (
        <W2Previewer
          viewPdf={viewPdf}
          options={options}
          selectedOption={getOption(selectedOption)}
          isOpen={showPreview}
          toggle={toggle}
        />
      ) : null}
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <p>
            Number of Records Per Page
            <span className={classes.required}>*</span>
            <span id="w2-info" className={classes.tooltip}>
              <img src={InfoCircle} alt="info" />
            </span>
          </p>
          <UncontrolledTooltip
            target={`w2-info`}
            innerClassName={classes.innerTooltip}
            className={classes.tooltipCustom}
            // modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
          >
            <span>
              Please preview the files before upload. Selecting the right
              document format is crucial for accuracy of results.
            </span>
          </UncontrolledTooltip>
        </div>
        <FormGroup className={classes.inputs}>
          {options.map(({ label, value }, i) => {
            return (
              <FormGroup className={classes.inputGroup} key={i} check>
                <Label check>
                  <Input
                    className={classes.input}
                    checked={selectedOption === value}
                    value={value}
                    type="radio"
                    name={value}
                    onChange={handleOptionChange}
                  />
                  {label}
                </Label>
              </FormGroup>
            );
          })}
        </FormGroup>
        <div
          className={cx(classes.preview, {
            [classes.notSelected]: !viewPdf[selectedOption],
          })}
        >
          <i
            onClick={handleOpenPreview}
            className="fas fa-arrows-alt"
            aria-hidden="true"
          ></i>
          {viewPdf[selectedOption] ?? <h3>Select an Option Above</h3>}
        </div>
      </div>
    </>
  );
};

export default UtilityDocOptionPreview;
