import Types from '../types/teamMembers';
import { slice, findIndex, get } from 'lodash';

const initialState = {
  teamMembersState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  addMember: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteMember: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function TeamMembers(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_TEAM_MEMBERS_INPROGRESS:
      return {
        ...state,
        teamMembersState: {
          ...state.teamMembersState,
          isInProgress: true,
        },
      };
    case Types.FETCH_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembersState: {
          ...state.teamMembersState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        teamMembersState: {
          ...state.teamMembersState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_MEMBER_DETAILS: {
      const teamMembers = get(state, 'teamMembersState.data.data', []);
      const userIndex = findIndex(teamMembers, ['id', action.id]);
      return {
        ...state,
        teamMembersState: {
          ...state.teamMembersState,
          isInProgress: false,
          isError: true,
          data: {
            ...state.teamMembersState.data,
            data: [
              ...slice(teamMembers, 0, userIndex),
              {
                ...teamMembers[userIndex],
                ...action.data,
              },
              ...slice(teamMembers, userIndex + 1),
            ],
          },
        },
      };
    }
    case Types.ADD_TEAM_MEMBER_INPROGRESS:
      return {
        ...state,
        addMember: {
          ...state.addMember,
          isInProgress: true,
        },
      };
    case Types.ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        addMember: {
          ...state.addMember,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.ADD_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        addMember: {
          ...state.addMember,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_TEAM_MEMBERS_INPROGRESS:
      return {
        ...state,
        deleteMember: {
          ...state.deleteMember,
          isInProgress: true,
        },
      };
    case Types.DELETE_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        deleteMember: {
          ...state.deleteMember,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        deleteMember: {
          ...state.deleteMember,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
