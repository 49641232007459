export const getOptions = (data, label = 'label', value = 'value') => {
  return data && Object.keys(data).length
    ? Object.keys(data).map(k => ({
        [label]: data[k],
        [value]: k,
      }))
    : [];
};

export const getDisplayValue = (value, id = 0) => {
  return {
    text: value,
    id: id,
  };
};
