import React, { useState } from 'react';

import Modal, { ModalBody } from 'components/FormFields/Modal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import classes from './AllDocumentModal.module.scss';
import classnames from 'classnames';

import { ReactComponent as PreviewIcon } from 'assets/img/icons/eye-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/download-icon.svg';
import useBreakPoint from 'helpers/useBreakPoint';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';

import { deleteAttachedFile } from 'store/actions/clientProfile';
import { clearDocumentUrl } from 'store/actions/documents';

import Button from 'components/Button';
import DocumentViewer from './DocumentViewer';
import DocumentFilter from './DocumentFilter';
import ListView from './Table/ListView';
import FolderView from './Table/FolderView';
import { downloadAllDocuments } from 'store/actions/fulfillment';

const AllDocumentModal = ({ isOpen, closeModal, batches }) => {
  const dispatch = useDispatch();

  const [view, setView] = useState('list'); //list|folder

  const allDocuments = batches.flatMap(data => data.documents);
  const allDocumentsIds = batches.flatMap(data => data.id);
  const [isFileViewerOpen, setFileViewerOpen] = useState(false);
  const [fileViewerData, setFileViewerData] = useState([]);
  const [filters, setFilters] = useState({});

  const isMobile = useBreakPoint('xs', 'down');
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );

  const isStoryFetchInProgress = useSelector(({ story }) =>
    get(story, 'details.isInProgress', false)
  );

  const handleModalClose = async () => {
    closeModal();
  };

  const toggleDocumentViewer = () => {
    setFileViewerOpen(false);
    setFileViewerData(null);

    dispatch(clearDocumentUrl());
  };

  const handleDocumentViewer = files => {
    setFileViewerData(files);
    setFileViewerOpen(true);
  };

  const handlePreviewAll = () => {
    if (allDocuments.length > 0) {
      setFileViewerOpen(true);
      setFileViewerData(allDocuments);
    }
  };

  const handleDownloadAll = () => {
    dispatch(
      downloadAllDocuments(currentStudy?.id, { doc_type_ids: allDocumentsIds })
    );
  };

  const handleSetFilters = filters => {
    setFilters(filters);
  };

  return (
    <>
      {isFileViewerOpen ? (
        <DocumentViewer
          isOpen={isFileViewerOpen}
          toggle={toggleDocumentViewer}
          title={currentStudy?.name || ''}
          files={fileViewerData}
          handleDownloadAll={handleDownloadAll}
        />
      ) : null}
      <Modal
        size="xl"
        scrollable
        fade={false}
        title={currentStudy?.name || ''}
        toggle={handleModalClose}
        isOpen={isOpen}
        className={classes.documentModalWrapper}
      >
        <DocumentFilter
          handleSetFilters={handleSetFilters}
          numberOfDocs={allDocuments?.length}
        />
        <ModalBody
          className={classnames(
            'p-0 d-flex flex-row flex-wrap',
            classes['document-modal'],
            { [classes['document-modal-mobile']]: isMobile }
          )}
          id="storyModal-body"
        >
          {isStoryFetchInProgress ? (
            <Loading wrapperClass={classes.loading} />
          ) : (
            <>
              <div className={classnames('w-100 d-flex', classes.modalWrapper)}>
                <div className={classes.fileTable}>
                  <div className={classes.modalHeader}>
                    <div className={classes.folderTitle}>
                      <div className="d-flex align-items-center">
                        {/* <i className="fas fa-folder-open" /> */}
                        <div>All Documents</div>
                      </div>
                    </div>
                    <div className={classes.actions}>
                      <div className={classes.adminAction}>
                        <Button
                          outline
                          rightIcon={<PreviewIcon />}
                          color="primary"
                          className={classes.previewButton}
                          onClick={handlePreviewAll}
                        >
                          Preview All
                        </Button>
                        <Button
                          outline
                          rightIcon={<DownloadIcon />}
                          color="primary"
                          className={classes.downloadAllButton}
                          onClick={handleDownloadAll}
                        >
                          Download All
                        </Button>
                      </div>
                      <div className={classes.viewButton}>
                        <Button
                          outline
                          leftIcon={
                            view === 'list' ? (
                              <i className="fas fa-folder-open" />
                            ) : (
                              <i
                                className="fas fa-list-ul"
                                aria-hidden="true"
                              ></i>
                            )
                          }
                          color="primary"
                          onClick={() => {
                            if (view === 'list') {
                              setView('folder');
                            } else {
                              setView('list');
                            }
                          }}
                        >
                          {view === 'list' ? 'Folder View' : 'List View'}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className={classes.table}>
                    {view === 'list' ? (
                      <ListView
                        filters={filters}
                        limit={allDocuments?.length}
                        handleDocumentViewer={handleDocumentViewer}
                      />
                    ) : (
                      <FolderView
                        filters={filters}
                        handleDocumentViewer={handleDocumentViewer}
                        batches={batches}
                      />
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

AllDocumentModal.propTypes = {
  projectId: PropTypes.number,
  storyId: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
  currentTab: PropTypes.oneOf(['comments']),
  userType: PropTypes.oneOf(['client', 'admin']),
  comment: PropTypes.number,
};

AllDocumentModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
  currentTab: 'comments',
};
export default AllDocumentModal;
