import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import React from 'react';
import classes from './KickoffModal.module.scss';
import Button from 'components/Button';
import * as Yup from 'yup';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Field, Form, Formik } from 'formik';
import { FormikInput } from 'components/FormFields/Input';

const KickoffModal = ({ toggle, isOpen }) => {
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );

  const studyName = get(currentStudy, 'name', '');

  const validationSchema = Yup.object().shape({
    gatheredDocuments: Yup.string().required('required'),
    confirmKickOffMeeting: Yup.string().when('gatheredDocuments', {
      is: 'no',
      then: Yup.string().required('required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    provideUpdatedCreditEstimate: Yup.string().when(
      ['confirmKickOffMeeting', 'gatheredDocuments'],
      {
        is: (confirmKickOffMeeting, gatheredDocuments) => {
          return confirmKickOffMeeting === 'yes' && gatheredDocuments === 'no';
        },
        then: Yup.string().required('required'),
        otherwise: () => Yup.string().notRequired(),
      }
    ),
    updatedCreditEstimate: Yup.string().when(['provideUpdatedCreditEstimate'], {
      is: provideUpdatedCreditEstimate => {
        return provideUpdatedCreditEstimate === 'yes';
      },
      then: Yup.string().required('This field is required'),
      otherwise: () => Yup.string().notRequired(),
    }),
    notes: Yup.string(),
  });

  return (
    <Modal
      size="lg"
      backdrop={['static']}
      scrollable
      fade={false}
      title={studyName}
      toggle={toggle}
      isOpen={isOpen}
      className={classes.modalWrapper}
      headerClassName={classes.modalHeader}
    >
      <div className={classes.title}>
        <p>Ready for Kickoff Call</p>
      </div>

      <Formik
        initialValues={{
          gatheredDocuments: '',
          confirmKickOffMeeting: '',
          provideUpdatedCreditEstimate: '',
          updatedCreditEstimate: '',
          notes: '',
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async values => {}}
      >
        {({ values, isValid, setFieldValue }) => {
          return (
            <Form>
              <ModalBody
                className={cx('d-flex flex-row flex-wrap', classes.modalBody)}
              >
                <div className={classes.formGroup}>
                  <div className={classes.inputWrapper}>
                    <label className={classes.label}>
                      Did you gather all documents needed for the study?
                    </label>
                    <div role="group" aria-labelledby="gatheredDocuments">
                      <label className={classes.option}>
                        <Field
                          type="radio"
                          name="gatheredDocuments"
                          value="yes"
                          onClick={() => {
                            setFieldValue('confirmKickOffMeeting', '');
                            setFieldValue('provideUpdatedCreditEstimate', '');
                            setFieldValue('updatedCreditEstimate', '');
                          }}
                        />{' '}
                        Yes
                      </label>
                      <label className={classes.option}>
                        <Field
                          type="radio"
                          name="gatheredDocuments"
                          value="no"
                        />{' '}
                        No
                      </label>
                    </div>
                  </div>
                  {values.gatheredDocuments === 'no' && (
                    <div className={classes.inputWrapper}>
                      <label className={classes.label}>
                        Can you confirm that you have enough documents to
                        warrant a kick-off meeting?
                      </label>
                      <div role="group" aria-labelledby="confirmKickOffMeeting">
                        <label className={classes.option}>
                          <Field
                            type="radio"
                            name="confirmKickOffMeeting"
                            value="yes"
                          />{' '}
                          Yes
                        </label>
                        <label className={classes.option}>
                          <Field
                            type="radio"
                            name="confirmKickOffMeeting"
                            value="no"
                            onClick={() => {
                              setFieldValue('updatedCreditEstimate', '');
                              setFieldValue('notes', '');
                            }}
                          />{' '}
                          No
                        </label>
                      </div>
                    </div>
                  )}
                  {!!values.gatheredDocuments && (
                    <div className={classes.inputWrapper}>
                      <label className={classes.label}>
                        Can you provide an updated Credit Estimate?
                      </label>
                      <div
                        role="group"
                        aria-labelledby="provideUpdatedCreditEstimate"
                      >
                        <label className={classes.option}>
                          <Field
                            type="radio"
                            name="provideUpdatedCreditEstimate"
                            value="yes"
                          />{' '}
                          Yes
                        </label>
                        <label className={classes.option}>
                          <Field
                            type="radio"
                            name="provideUpdatedCreditEstimate"
                            value="no"
                            onClick={() => {
                              setFieldValue(
                                'provideUpdatedCreditEstimate',
                                'no'
                              );
                              setFieldValue('updatedCreditEstimate', '');
                            }}
                          />{' '}
                          No
                        </label>
                      </div>
                    </div>
                  )}
                  {values.provideUpdatedCreditEstimate === 'yes' && (
                    <div className={classes.inputWrapper}>
                      <label
                        className={classes.label}
                        htmlFor="updatedCreditEstimate"
                      >
                        Updated Credit Estimate
                      </label>
                      <FormikInput
                        className={classes.input}
                        type="text"
                        name="updatedCreditEstimate"
                      />
                    </div>
                  )}
                  {!!values.gatheredDocuments && (
                    <div className={classes.inputWrapper}>
                      {values?.confirmKickOffMeeting === 'no' ? (
                        <p className={classes.text}>
                          Before scheduling a kick-off meeting for the tax
                          credit study, please make sure to gather the minimum
                          threshold of documentation to warrant a kick off
                          meeting.
                        </p>
                      ) : (
                        <p className={classes.text}>
                          Before scheduling a kick-off meeting for the tax
                          credit study, please input context and notes detailing
                          the readiness rationale. While gathering all documents
                          from the client's company is ideal, it's crucial to at
                          least secure the most pertinent ones that fit the
                          kick-off criteria.
                        </p>
                      )}
                      <FormikInput
                        rows="5"
                        name="notes"
                        label="Notes"
                        type="textarea"
                        labelClassName={classes.textareaLabel}
                        // groupClassName={className(
                        //   'm-0',
                        //   classes.textInputLeftMargin
                        // )}
                      />
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter className={classes.modalFooter}>
                <Button color="link" className={classes.back} onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  //   loading={loading}
                  disabled={
                    !isValid ||
                    values?.confirmKickOffMeeting === 'no' ||
                    !values?.gatheredDocuments
                  }
                  color="primary"
                  className={classes.submit}
                  //   onClick={async () => {
                  //     setLoading(true);
                  //     await handleSubmitReview();
                  //     setLoading(false);
                  //   }}
                >
                  Save
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default KickoffModal;
