import React from 'react';
import classes from './UserInfo.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Avatar from 'components/Avatar';
import get from 'lodash/get';
import { UncontrolledTooltip } from 'reactstrap';

const UserInfo = ({
  info,
  altText,
  showName = true,
  showToolTip = false,
  avatarClassName = '',
}) => {
  const name = get(info, 'name');
  const avatar = get(info, 'avatar');
  const id = get(info, 'id', 0);
  return (
    <>
      <div className="d-flex flex-row flex-nowrap align-items-center overflow-hidden">
        {!isEmpty(avatar) ? (
          <div id={`user-info-${id}`}>
            <Avatar
              url={avatar}
              className={classnames('avatar-xs mr-2', avatarClassName)}
            />
          </div>
        ) : (
          <div
            id={`user-info-${id}`}
            className={classnames(
              'avatar-xs avatar mr-2 d-flex align-items-center justify-content-center',
              classes['empty-avatar'],
              'rounded-circle'
            )}
          >
            <span className="fa fa-user text-white" />
          </div>
        )}
        {showName && (
          <p
            className={classnames(
              !name ? 'text-muted' : classes.username,
              classes['ellipsis']
            )}
          >
            {name || altText}
          </p>
        )}
      </div>
      {showToolTip && (
        <UncontrolledTooltip
          delay={0}
          target={`user-info-${id}`}
          placement="bottom"
          innerClassName={classes['tooltip']}
          boundariesElement="viewport"
        >
          {name}
        </UncontrolledTooltip>
      )}
    </>
  );
};

UserInfo.propTypes = {
  info: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  altText: PropTypes.string.isRequired,
};

UserInfo.defaultProps = {
  altText: '',
  info: {},
};

export default UserInfo;
