import React from 'react';
import FulfillmentStudyDashboard from 'views/pages/FulfillmentStudyDashboard';

const StudyDashboard = () => {
  return (
    <>
      <FulfillmentStudyDashboard />
    </>
  );
};

export default StudyDashboard;
