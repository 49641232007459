import React from 'react';
import PropTypes from 'prop-types';
import UserInfo from 'components/UserInfo';
import classes from '../StoryModal.module.scss';
import classnames from 'classnames';
import PeopleSelector from 'components/PeopleSelector';

const StoryOwner = ({ onChange, isEditAllowed, info }) => {
  const handleClick = selectedManager => {
    onChange(selectedManager);
  };
  return (
    <>
      <h4>Story Owner</h4>
      <PeopleSelector
        id="story-owner-target"
        disabled={!isEditAllowed}
        url="/users/list/dropdown?status=Active&role=manager"
        onChange={handleClick}
        placeholder="Assign a Story Owner"
      >
        <div className={classnames(isEditAllowed && classes['hover-hand'])}>
          <UserInfo info={info} altText="Assign a Story Owner" />
        </div>
      </PeopleSelector>
    </>
  );
};

StoryOwner.propTypes = {
  info: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  isEditAllowed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

StoryOwner.defaultProps = {
  isEditAllowed: false,
};

export default StoryOwner;
