import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_NOTIFICATIONS_INPROGRESS',
  'FETCH_NOTIFICATIONS_SUCCESS',
  'FETCH_NOTIFICATIONS_FAILURE',

  'ADD_NEW_NOTIFICATION',

  'READ_NOTIFICATION_INPROGRESS',
  'READ_NOTIFICATION_SUCCESS',
  'READ_NOTIFICATION_FAILURE',
  'READ_ALL_NOTIFICATION_INPROGRESS',
  'READ_ALL_NOTIFICATION_SUCCESS',
  'READ_ALL_NOTIFICATION_FAILURE'
);
