import React, { useEffect } from 'react';
import { FormGroup, Form, ModalBody } from 'reactstrap';
import { Formik } from 'formik';
import Input from 'components/FormFields/Input';
import cx from 'classnames';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Editor from 'components/FormFields/Editor/Editor';
import classes from './ClientRequestModal.module.scss';
import Modal, { ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import { NotificationHandler } from 'components/Notifications';
import history from 'helpers/history';

const ClientRequestModal = ({
  handleClose,
  isOpen,
  onSubmit = () => {},
  bodyClassName,
  footerClassName,
  heading = 'Ask a Question',
  submitProps = {},
  cancelProps = {},
  editorProps = {},
  required = {
    name: true,
    description: true,
  },
}) => {
  const requiredState = { name: true, description: true, ...required };

  const defaultSubmitProps = { buttonText: 'Send', color: 'primary' };
  const defaultCancelProps = {
    buttonText: 'Cancel',
    color: 'default',
    outline: true,
    onClick: () => {
      handleClose();
    },
  };
  const defaultEditorProps = {
    placeholder: 'Message',
  };

  const submit = { ...defaultSubmitProps, ...submitProps };
  const cancel = { ...defaultCancelProps, ...cancelProps };
  const editor = { ...defaultEditorProps, ...editorProps };

  let initialValues = {
    name: '',
    description: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().when([], {
      is: () => requiredState.name === true,
      then: Yup.string()
        .trim()
        .required('Required'),
    }),
    description: Yup.string().when([], {
      is: () => requiredState.description === true,
      then: Yup.string()
        .ensure()
        .test(
          'is-empty',
          'Description can not be empty',
          value =>
            !isEmpty(
              value.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/gi, '$1').trim()
            )
        )
        .required('Required'),
    }),
  });

  const getToastMessage = () => {
    return 'Your message was sent successfully.';
  };

  const RenderForm = ({
    handleChange,
    setFieldValue,
    isValid,
    handleReset,
    handleSubmit,
    values,
  }) => {
    return (
      <Form>
        <ModalBody className={cx('pb-0', bodyClassName)}>
          {requiredState.name && (
            <FormGroup>
              <Input
                value={values.name}
                placeholder="Subject*"
                required
                name="name"
                onChange={handleChange}
              />
            </FormGroup>
          )}
          {requiredState.description && (
            <FormGroup>
              <Editor
                value={values.description}
                onChange={description =>
                  setFieldValue('description', description)
                }
                className="mb-4"
                placeholder={editor.placeholder}
              />
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter className={cx(classes.modalFooter, footerClassName)}>
          <Button
            onClick={cancel?.onClick}
            color={cancel?.color}
            outline={cancel.outline}
            className={cx(classes.cancelButtonClass, cancel.className)}
          >
            {cancel.buttonText}
          </Button>
          <Button
            className={cx(classes.submitButtonWrapper, submit.className)}
            id={submit?.id}
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid || submit?.loading}
            color={submit?.color}
            loading={submit?.loading}
          >
            {submit.buttonText}
          </Button>
        </ModalFooter>
      </Form>
    );
  };

  if (!isOpen) return null;

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        setSubmitting(false);
        const resp = await onSubmit(values);
        if (resp?.status === 1) {
          resetForm();
          handleClose();
          NotificationHandler.open({
            operation: 'update',
            message: getToastMessage(),
            icon: ' ',
            title: ' ',
          });
        } else {
          NotificationHandler.open({
            operation: 'failure',
            message: '',
          });
        }
      }}
    >
      {props => (
        <Modal
          toggle={handleClose}
          backdrop="static"
          centered
          isOpen={isOpen}
          title={heading}
          size="lg"
          className={classes.modal}
        >
          <RenderForm {...props} />
        </Modal>
      )}
    </Formik>
  );
};

export default ClientRequestModal;
