import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_YEARS_INPROGRESS',
  'FETCH_YEARS_SUCCESS',
  'FETCH_YEARS_FAILURE',
  'FETCH_DOCUMENT_FOR_YEAR_INPROGRESS',
  'FETCH_DOCUMENT_FOR_YEAR_SUCCESS',
  'FETCH_DOCUMENT_FOR_YEAR_FAILURE',
  'DOCUMENT_UPLOAD_INPROGRESS',
  'DOCUMENT_UPLOAD_SUCCESS',
  'DOCUMENT_UPLOAD_FAILURE',
  'DOWNLOAD_DOCUMENT_INPROGRESS',
  'DOWNLOAD_DOCUMENT_SUCCESS',
  'DOWNLOAD_DOCUMENT_FAILURE',
  'PREVIEW_DOCUMENT_INPROGRESS',
  'PREVIEW_DOCUMENT_SUCCESS',
  'PREVIEW_DOCUMENT_FAILURE',
  'SUBMIT_YEAR_INPROGRESS',
  'SUBMIT_YEAR_SUCCESS',
  'SUBMIT_YEAR_FAILURE',
  'VERIFY_YEAR_INPROGRESS',
  'VERIFY_YEAR_SUCCESS',
  'VERIFY_YEAR_FAILURE',
  'UNDO_VERIFY_YEAR_INPROGRESS',
  'UNDO_VERIFY_YEAR_SUCCESS',
  'UNDO_VERIFY_YEAR_FAILURE',
  'SYNC_DOCUMENT_INPROGRESS',
  'SYNC_DOCUMENT_SUCCESS',
  'SYNC_DOCUMENT_FAILURE',
  'SYNC_DOCUMENT_COMPLETED',
  'CLEAR_PREVIEW_DOCUMENT',
  'FETCH_DOCUMENT_ACTIVITIES_INPROGRESS',
  'FETCH_DOCUMENT_ACTIVITIES_SUCCESS',
  'FETCH_DOCUMENT_ACTIVITIES_FAILURE',
  'FETCH_MORE_DOCUMENT_ACTIVITIES_INPROGRESS',
  'FETCH_MORE_DOCUMENT_ACTIVITIES_SUCCESS',
  'FETCH_MORE_DOCUMENT_ACTIVITIES_FAILURE',
  'FETCH_OCR_DOCUMENT_TYPES_SUCCESS',
  'SUBMIT_MISSING_YEAR_INFO_DOCUMENT_INPROGRESS',
  'SUBMIT_MISSING_YEAR_INFO_DOCUMENT_SUCCESS',
  'SUBMIT_MISSING_YEAR_INFO_DOCUMENT_FAILURE',
  'FETCH_DOCUMENT_STATUS_LIST_INPROGRESS',
  'FETCH_DOCUMENT_STATUS_LIST_SUCCESS',
  'FETCH_DOCUMENT_STATUS_LIST_FAILURE',
  'FETCH_DOCUMENT_STUDY_TYPES_INPROGRESS',
  'FETCH_DOCUMENT_STUDY_TYPES_SUCCESS',
  'FETCH_DOCUMENT_STUDY_TYPES_FAILURE',
  'STUDY_DOCUMENT_UPLOAD_INPROGRESS',
  'STUDY_DOCUMENT_UPLOAD_SUCCESS',
  'STUDY_DOCUMENT_UPLOAD_FAILURE',
  'MOVE_DOCUMENT_INPROGRESS',
  'MOVE_DOCUMENT_SUCCESS',
  'MOVE_DOCUMENT_FAILURE'
);
