import React from 'react';
import { Form, FormGroup, Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import FormModal from 'components/FormFields/FormModal';
import cls from './partners.module.scss';

const InvitePartnerForm = ({
  closeModal,
  submitValues,
  isModalOpen,
  loading,
  editValues,
}) => {
  const initialValues = {
    first_name: editValues?.first_name || '',
    last_name: editValues?.last_name || '',
    email: editValues?.email || '',
  };

  const PartnerSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Please enter valid email'),
  });

  const handleCloseModal = () => {
    closeModal();
  };

  const RenderForm = ({ handleSubmit, isValid }) => {
    return (
      <FormModal
        toggle={handleCloseModal}
        isOpen={isModalOpen}
        size="md"
        id="invitePartnerModal"
        heading={'Invite a New Partner'}
        submit={{
          id: 'invitePartnerBtn',
          onClick: handleSubmit,
          loading: loading,
          isValid: isValid,
          buttonText: `Submit`,
          buttonVariant: 'primary',
        }}
        cancel={{
          onClick: handleCloseModal,
          buttonText: 'Cancel',
        }}
      >
        <Form role="form" onSubmit={handleSubmit}>
          <Row className={cls.row}>
            <Col className="px-0">
              <FormGroup className="mb-3">
                <label className={cls.formLabel}>
                  First Name <span className={cls.required}>*</span>
                </label>
                <FormikInput
                  inputId="PartnerFirstNameInput"
                  groupClassName="mb-3"
                  name="first_name"
                  placeholder="Enter first name"
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col className="px-0">
              <FormGroup className="mb-3">
                <label className={cls.formLabel}>
                  Last Name <span className={cls.required}>*</span>
                </label>
                <FormikInput
                  inputId="PartnerLastNameInput"
                  groupClassName="mb-3"
                  name="last_name"
                  placeholder="Enter last name"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className={cls.row}>
            <Col className="px-0">
              <FormGroup className="mb-3 ">
                <label className={cls.formLabel}>
                  Email <span className={cls.required}>*</span>
                </label>
                <FormikInput
                  inputId="PartnerEmailInput"
                  groupClassName="mb-3"
                  name="email"
                  placeholder="Enter email address"
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={PartnerSchema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        submitValues(values);
        resetForm();
      }}
    >
      {RenderForm}
    </Formik>
  );
};

export default InvitePartnerForm;
