import React, { useState } from 'react';
import classes from './YearsVerification.module.scss';
import Button from 'components/Button';
import cx from 'classnames';
import * as Yup from 'yup';
import { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import { ButtonGroup } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { FormikInput } from 'components/FormFields/Input';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const YearsVerification = ({ prev, handleSubmitReview, years }) => {
  const [loading, setLoading] = useState(false);
  const [submitButtonType, setSubmitButtonType] = useState('save');

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );

  const getYears = () => {
    const years = currentStudy?.engagement_years?.split('-');
    const startYear = parseInt(years[0]);
    const endYear = parseInt(years[1]);
    const collectYears = [];

    for (let year = startYear; year <= endYear; year++) {
      collectYears.push({ id: year, value: `${year}` });
    }
    if (collectYears.length > 0)
      collectYears.push({
        id: 9999,
        value: 'Not Sure',
      });
    return collectYears;
  };

  const schema = Yup.object().shape({
    selectedYears: Yup.array().required('required'),
    additionalComment: Yup.string(),
  });

  const YEARS_DATA = [...getYears()];
  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    await handleSubmitReview({
      missing_years: values.selectedYears,
      comments: values.additionalComment,
    });
    setLoading(false);
    setSubmitting(false);
  };

  const handleButtonClick = type => {
    setSubmitButtonType(type);
  };

  return (
    <div className={classes.wrapper}>
      <Formik
        validationSchema={schema}
        initialValues={{ selectedYears: [] }}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <ModalBody
              className={cx('d-flex flex-row flex-wrap', classes.modalBody)}
              id="storyModal-body"
            >
              <div className={classes.header}>
                <h3>Which years are missing or have incomplete data?</h3>
                <p>
                  You may select one or multiple years. If you don’t have that
                  information, select Not Sure.
                </p>
              </div>
              <div className={classes.yearOption}>
                <ButtonGroup className={classes.buttonGroup}>
                  {YEARS_DATA.map(({ id, value }) => {
                    return (
                      <Field
                        key={id}
                        name="selectedYears"
                        type="checkbox"
                        value={id}
                      >
                        {({ field }) => (
                          <Button
                            color="default"
                            className={classes.selectButton}
                            outline
                            onClick={() => {
                              if (id === 9999) {
                                setFieldValue('selectedYears', [id]);
                              } else {
                                let newSelectedYears;
                                if (values.selectedYears.includes(id)) {
                                  newSelectedYears = values.selectedYears.filter(
                                    year => year !== id
                                  );
                                } else {
                                  newSelectedYears = [
                                    ...values.selectedYears,
                                    id,
                                  ];
                                  if (values.selectedYears.includes(9999)) {
                                    newSelectedYears = newSelectedYears.filter(
                                      year => year !== 9999
                                    );
                                  }
                                }
                                setFieldValue(
                                  'selectedYears',
                                  newSelectedYears
                                );
                              }
                            }}
                            active={values.selectedYears.includes(id)}
                          >
                            {value}
                          </Button>
                        )}
                      </Field>
                    );
                  })}
                </ButtonGroup>
              </div>
              <div className={classes.additionalComments}>
                <FormikInput
                  labelClassName={classes.customLabel}
                  placeholder="Type here..."
                  name="additionalComment"
                  label="If you’re not sure or have any additional comments, you can leave them here. (*optional)"
                  type="textarea"
                  groupClassName={cx('m-0', classes.textInputLeftMargin)}
                />
              </div>
            </ModalBody>
            <ModalFooter className={classes.modalFooter}>
              <Button
                className={classes.back}
                onClick={prev}
                leftIcon={
                  <i className="fa fa-arrow-left" aria-hidden="true"></i>
                }
              >
                Back
              </Button>
              <div className={classes.submitActions}>
                <Button
                  type="submit"
                  color="primary"
                  className={classes.submit}
                  disabled={
                    values.selectedYears.length === 0 ||
                    (loading && submitButtonType === 'submit')
                  }
                  onClick={() => handleButtonClick('submit')}
                  loading={loading && submitButtonType === 'submit'}
                >
                  Submit for Review
                </Button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default YearsVerification;
