import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import { Form, FormGroup, Row, Col } from 'reactstrap';

import FormModal from 'components/FormFields/FormModal';

import classes from './TimerModal.module.scss';
import get from 'lodash/get';

import clockIcon from 'assets/img/icons/clock-blue-timer.svg';
import stopTimerIcon from 'assets/img/icons/stop-timer.svg';
import arrowTimerIcon from 'assets/img/icons/arrow-timer.svg';
import checkedIcon from 'assets/img/icons/discount-timer-checked.svg';
import unCheckedIcon from 'assets/img/icons/discount-timer-unchecked.svg';
import { useSelector, useDispatch } from 'react-redux';
import InputTime from './InputTime';
import ToggleCheckBox from 'components/FormFields/ToggleCheckBox';

import moment from 'moment-timezone';
import { secondsToHHMMSS } from 'helpers/times';
import {
  doPostStopTimer,
  doGetTimeEntryById,
  doPostUpdateTimeEntry,
  doPostAddTimeEntry,
  doPostUpdateTimeFormat,
  doDeleteTimeEntry,
  doStopTimer,
} from 'store/actions/timerActions';
import IconCheckBox from 'components/FormFields/IconCheckBox';
import SelectProject from './SelectProject';
import SelectStory from './SelectStory';
import SelectTask from './SelectTask';
import cs from 'classnames';
import { useAccess, permissions } from 'helpers/permission';
import { updateActiveTourStatus } from 'store/actions/profile';
import analytics, { analyticsConstants } from 'helpers/analytics';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';

const TimerModal = props => {
  const dispatch = useDispatch();

  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const runningTime = useSelector(({ timer }) => get(timer, 'runningTime'));
  const runningEndTime = useSelector(({ timer }) =>
    get(timer, 'runningEndTime')
  );
  const timeFormat = useSelector(({ timer }) => get(timer, 'timeFormat'));

  const currentProject = useSelector(({ timer }) =>
    get(timer, 'currentProject')
  );

  const analyticsSendEventTimeLog = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.time_log_reporting,
      ...rest,
    });
  };
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.time_entries,
      ...rest,
    });
  };

  const getEntry = useSelector(({ timer }) => get(timer, 'getEntry.data'));

  useEffect(() => {
    dispatch(doGetTimeEntryById(props.entryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entryId]);

  let project = {};

  let date_start = '';
  let startDate = '';
  let date_end = '';
  if (props.mode === 'add') {
    project = {
      id: get(currentProject, 'id', ''),
      name: get(currentProject, 'text', ''),
    };

    date_start = moment(runningTime).format();
    date_end = moment(runningEndTime).format();
  } else if (props.mode === 'add-manual') {
    date_start = moment()
      .tz(timezone)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .format();
    date_end = moment()
      .tz(timezone)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .format();

    project = {
      id: get(getEntry, 'project.id', ''),
      name: get(getEntry, 'project.name', ''),
    };
  } else {
    project = {
      id: get(getEntry, 'project.id', ''),
      name: get(getEntry, 'project.name', ''),
    };

    date_start =
      get(getEntry, 'date_start', '') ||
      moment()
        .tz(timezone)
        .format();

    date_end =
      get(getEntry, 'date_end', '') ||
      moment()
        .tz(timezone)
        .format();
  }

  startDate = moment(date_start).tz(timezone);
  const endDate = moment(date_end).tz(timezone);

  const discounted = get(getEntry, 'billable', true);
  const description = get(getEntry, 'description', '') || '';

  const story = {
    id: get(getEntry, 'story.id', '') || '',
    name: get(getEntry, 'story.name', '') || '',
  };

  const task = {
    id: get(getEntry, 'task.id', '') || '',
    name: get(getEntry, 'task.name', '') || '',
  };

  let initialValues = {
    id: get(getEntry, 'id'),
    project: project.id || '',
    story: story.id || '',
    task: task.id || '',
    project_name: project.name,
    story_name: story.name,
    task_name: task.name,
    start_date: startDate.format('YYYY-MM-DD HH:mm:ss'),
    end_date: endDate.format('YYYY-MM-DD HH:mm:ss'),
    start_time: startDate.format('X'),
    end_time: endDate.format('X'),
    discounted: !discounted,
    description,
  };

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    // start_date: Yup.string().required('Start date is required'),
    // end_date: Yup.string().required('End date is required'),
  });

  const icon = props.mode === 'add' ? stopTimerIcon : clockIcon;

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const handleClose = () => {
    props.toggle();
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'time_logging'
    ) {
      dispatch(
        updateActiveTourStatus({
          activeTour: 'time_logging',
          step: 1,
        })
      );
    }
  };

  const handleDelete = () => {
    AlertPopupHandler.open({
      onConfirm: async () => {
        await dispatch(doDeleteTimeEntry(get(getEntry, 'id')));
        dispatch(doStopTimer());
        analyticsSendEvent({
          action: analyticsConstants.action.delete_time_entry,
          updated_from: 'Timelog Report',
        });
      },
      confirmBtnText: 'Delete Time Entry',
      text: `You are about to delete this Time Entry. Do you want to continue?`,
      data: {},
    });
  };

  const RenderForm = formik => {
    return (
      <FormModal
        size="md"
        showDelete
        toggle={handleClose}
        isOpen={props.isOpen}
        heading={heading}
        id="timeEntryModal"
        submit={{
          onClick: formik.handleSubmit,
          isValid:
            formik.values.end_time > formik.values.start_time && formik.isValid,
          buttonText: `Save Time Entry`,
          loading,
        }}
        cancel={{
          onClick: handleClose,
          buttonText: 'Cancel',
        }}
        deleteBtn={{
          onClick: handleDelete,
          buttonText: 'Delete',
        }}
      >
        <Form
          role="form"
          onSubmit={formik.handleSubmit}
          className={classes.root}
        >
          <Row className="d-flex align-items-center">
            <Col>
              <FormGroup className={classes.runningTime}>
                <img
                  className={classes['timer-btn']}
                  alt="Timer Button"
                  src={icon}
                />
                <span
                  className={cs({
                    [classes.error]:
                      formik.values.end_time - formik.values.start_time < 0,
                  })}
                >
                  {secondsToHHMMSS(
                    formik.values.end_time - formik.values.start_time
                  )}
                </span>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className={classes.toggleTime}>
                12 HR
                <ToggleCheckBox
                  checked={timeFormat === 24}
                  onChange={() => {
                    dispatch(doPostUpdateTimeFormat());
                  }}
                />
                24 HR
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12} className={classes.customTime}>
              <InputTime
                id="startTimerInput"
                label="Start time*"
                position="left"
                time={formik.values.start_date}
                before={formik.values.end_date}
                onChange={startDateTime => {
                  formik.setFieldValue('start_time', startDateTime.format('X'));
                  formik.setFieldValue(
                    'start_date',
                    startDateTime.format('YYYY-MM-DD HH:mm:ss')
                  );
                }}
              />
              <img src={arrowTimerIcon} alt="to" className="arrow-to" />
              <InputTime
                label="End time*"
                position="right"
                time={formik.values.end_date}
                after={formik.values.start_date}
                onChange={endDateTime => {
                  formik.setFieldValue('end_time', endDateTime.format('X'));
                  formik.setFieldValue(
                    'end_date',
                    endDateTime.format('YYYY-MM-DD HH:mm:ss')
                  );
                }}
                error={formik.values.end_time - formik.values.start_time < 0}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <SelectProject formik={formik} />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <SelectStory formik={formik} project={formik.values.project} />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <SelectTask
                  formik={formik}
                  project={formik.values.project}
                  story={formik.values.story}
                />
              </FormGroup>
            </Col>
            {useAccess([permissions.DISCOUNT_TIMELOGS]) && (
              <Col md={12}>
                <FormGroup>
                  <IconCheckBox
                    label="Discount Time Entry"
                    error={formik.errors.discounted}
                    checked={formik.values.discounted}
                    onChange={() => {
                      formik.setFieldValue(
                        'discounted',
                        !formik.values.discounted
                      );
                    }}
                    touched={formik.touched.discounted}
                    iconChecked={checkedIcon}
                    iconUncheck={unCheckedIcon}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md={12}>
              <FormikInput
                type="textarea"
                name="description"
                placeholder="Add a Description"
                rows="5"
              />
            </Col>
          </Row>
        </Form>
      </FormModal>
    );
  };

  const handleOnSubmit = async (values, actions) => {
    setLoading(true);

    let rs = null;

    if (props.mode === 'add') {
      analyticsSendEvent({ action: analyticsConstants.action.stop_timer });

      rs = await dispatch(
        doPostStopTimer(
          values.start_date,
          values.end_date,
          values.project,
          values.story,
          values.task,
          values.description,
          values.discounted
        )
      );
    } else if (props.mode === 'edit') {
      analyticsSendEvent({
        action: analyticsConstants.action.edit_time_entry,
        updated_from: get(props, 'source'),
      });

      values.discounted &&
        analyticsSendEventTimeLog({
          action: analyticsConstants.action.discount_time_entry,
          updated_from: 'Time Entry Modal',
        });

      rs = await dispatch(
        doPostUpdateTimeEntry(
          values.id,
          values.start_date,
          values.end_date,
          values.project,
          values.story,
          values.task,
          values.description,
          values.discounted
        )
      );
    } else if (props.mode === 'add-manual') {
      analyticsSendEvent({
        action: analyticsConstants.action.add_manual_time_entry,
      });
      rs = await dispatch(
        doPostAddTimeEntry(
          values.start_date,
          values.end_date,
          values.project,
          values.story,
          values.task,
          values.description,
          values.discounted
        )
      );
    }

    if (rs === 1) {
      setLoading(false);
      props.success();
    } else {
      setLoading(false);
    }
  };

  let heading = '';

  switch (props.mode) {
    case 'add':
      heading = 'Time Entry Details';
      break;

    case 'edit':
      heading = 'Edit Time Entry';
      break;

    case 'add-manual':
      heading = 'Manual Time Entry';
      break;

    default:
      break;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
    >
      {RenderForm}
    </Formik>
  );
};

TimerModal.propTypes = {
  toggle: PropTypes.func,
  success: PropTypes.func,
};

export default TimerModal;
