import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import PersonalDashboardQuickActions from 'views/pages/QuickActions/PersonalDashboardQuickActions';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import classes from './AccountOverview.module.scss';
import cx from 'classnames';
import ClientTeams from 'views/pages/ClientProfile/ClientTeams';
import Helmet from 'components/Helmet';
import RecentlyViewedStudies from 'views/pages/dashboards/FulfillmentDashboard/RecentlyViewedStudies';
import NotesContainer from './NotesContainer';
import AddEditNoteModal from './AddEditNoteModal';
// import CompanyInformation from './CompanyInformation';

const AccountOverview = ({
  clientId,
  handleClientTeamAction,
  clientName,
  isFulfilment,
  companyId,
}) => {
  const dispatch = useDispatch();
  const isMobile = useBreakPoint('sm', 'down');

  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('clientProfile', field, defaultValue);
  const [tab, setTab] = useState('users');
  const [addEditNoteModal, setAddEditNoteModal] = useState({
    isOpen: false,
    note: null,
  });
  const { isOpen } = addEditNoteModal;
  return (
    <Container fluid>
      {isFulfilment && <div className={classes.title}>Overview</div>}
      <Helmet title="Strike Portal - Clients - Account Overview" />

      <Row
        className={cx(classes.accountOverviewWrapper, {
          [classes.wrapper]: isFulfilment,
        })}
      >
        <Col md={isFulfilment ? 6 : 4}>
          <RecentlyViewedStudies
            listParams={{ type: '', limit: 10, client: clientId }}
            title="Studies"
            cardClassName={classes.card}
            isFulfilment={isFulfilment}
            clientId={clientId}
            clientName={clientName}
            showNewStudy={true}
          />
        </Col>
        <Col md={isFulfilment ? 6 : 8}>
          {isFulfilment ? (
            <>
              <NotesContainer
                clientId={clientId}
                setAddEditNoteModal={setAddEditNoteModal}
              />
              {isOpen && (
                <AddEditNoteModal
                  clientId={clientId}
                  addEditNoteModal={addEditNoteModal}
                  closeModal={() => {
                    setAddEditNoteModal({ isOpen: false, note: null });
                  }}
                  setAddEditNoteModal={setAddEditNoteModal}
                />
              )}
            </>
          ) : (
            <>
              <PersonalDashboardQuickActions
                clientId={clientId}
                clientName={clientName}
                companyId={companyId}
              />
              <ClientTeams
                companyId={companyId}
                clientId={clientId}
                isMobile={isMobile}
                getDetails={useGetDetails}
                handleClientTeamAction={handleClientTeamAction}
              />
            </>
          )}
        </Col>
      </Row>
      {isFulfilment && (
        <div className={classes.contentWrapper}>
          <Nav className={classes.nav} tabs>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cx(classes.navLink, {
                  [classes.active]: tab === 'companyInfo',
                })}
                onClick={() => setTab('companyInfo')}
              >
                Company Information
              </NavLink>
            </NavItem>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cx(classes.navLink, {
                  [classes.active]: tab === 'users',
                })}
                onClick={() => setTab('users')}
              >
                Users
              </NavLink>
            </NavItem>
            <NavItem className={classes.navItem}>
              <NavLink
                className={cx(classes.navLink, {
                  [classes.active]: tab === 'history',
                })}
                onClick={() => setTab('history')}
              >
                History
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={tab}>
            {/*<TabPane tabId="companyInfo">*/}
            {/*  <CompanyInformation />*/}
            {/*</TabPane>*/}
            <TabPane tabId="users">
              <ClientTeams
                companyId={companyId}
                clientId={clientId}
                isMobile={isMobile}
                getDetails={useGetDetails}
                handleClientTeamAction={handleClientTeamAction}
                isFulfilment={isFulfilment}
                cardHeader="Client Users"
              />
            </TabPane>
          </TabContent>
        </div>
      )}
    </Container>
  );
};

export default AccountOverview;
