import React, { useState, useEffect } from 'react';
import Table from 'components/Table';
import useDebounce from 'react-use/lib/useDebounce';
import {
  fetchMembers,
  deleteUser,
  reInviteUser,
} from 'store/actions/onboarding';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Avatar from 'components/Avatar';
import classes from './Invitation.module.scss';
import Dropdowns from 'components/Dropdowns';
import { AlertPopupHandler } from 'components/AlertPopup';
import { map } from 'lodash';

const TeamMemberTable = ({ updateTable }) => {
  const dispatch = useDispatch();
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const [page, setPage] = useState(1);
  const [updateUsers, setupdateUsers] = useState(false);

  const teamMembers = useSelector(state =>
    get(state, 'onboarding.teamMembers.data', {})
  );
  const deleteTeamMember = useSelector(state =>
    get(state, 'onboarding.deleteTeamMember.data.id', null)
  );
  const isTeamMembersLoading = useSelector(state =>
    get(state, 'onboarding.teamMembers.isInProgress', false)
  );
  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };
  useDebounce(
    () => {
      dispatch(
        fetchMembers(
          page,
          `${sortBy.order === 'asc' ? '' : '-'}${sortBy.dataField}`
        )
      );
    },
    500,
    [dispatch, page, sortBy, deleteTeamMember, updateUsers]
  );

  useEffect(() => {
    setupdateUsers(updateTable);
  }, [updateTable]);

  return (
    <>
      <h2 className="mt-4 my-3 mt-4">My Team</h2>
      <Table
        bootstrap4
        remote
        search={false}
        bordered={false}
        keyField="id"
        defaultSorted={[sortBy]}
        paginationOptions={{
          page,
          totalSize: get(teamMembers, 'meta.total', 0),
          sizePerPage: parseInt(get(teamMembers, 'meta.per_page', 0)),
        }}
        wrapperClasses={classes.teamMemberTable}
        bodyClasses={classes.tableBody}
        loading={isTeamMembersLoading}
        data={teamMembers.data}
        noDataIndication={() => <div>No Team Members invited!!</div>}
        headerClasses="bg-secondary"
        onTableChange={(type, { page: newPage, sortOrder, sortField }) => {
          if (type === 'pagination') {
            setPage(newPage);
          } else if (type === 'sort') {
            setPage(1);
            setSortBy({
              dataField: sortField,
              order: sortOrder,
            });
          }
        }}
        columns={[
          {
            dataField: 'avatar_thumbnail',
            text: '',
            sort: false,
            sortCaret: null,
            formatter: cell => {
              return <Avatar url={cell} className="avatar-xs" />;
            },
          },
          {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: renderSortCaret,
          },
          {
            dataField: 'email',
            text: 'Email Address',
            sort: true,
            sortCaret: renderSortCaret,
          },
          {
            dataField: 'roles[0].title',
            text: 'Role',
            sort: true,
            sortCaret: renderSortCaret,
          },
          {
            dataField: 'status.status',
            text: 'Status',
            sort: true,
            sortCaret: renderSortCaret,
          },
          {
            dataField: 'id',
            text: '',
            sort: false,
            sortCaret: null,
            formatter: (cell, row) => {
              return (
                <Dropdowns
                  dropdownClasses="float-right"
                  text={<i className="fas fa-ellipsis-v" />}
                  className="btn-icon-only m-0 text-light"
                  options={
                    row.status.id !== 133
                      ? [
                          {
                            text: 'Edit User',
                            onClick: () => {},
                          },

                          {
                            text: 'Remove User',
                            onClick: () => {
                              AlertPopupHandler.open({
                                onConfirm: data => {
                                  dispatch(deleteUser(data.id));
                                },
                                confirmBtnText: 'Delete',
                                title: 'Do you want to remove this user?',
                                text: `This user will be removed and will no longer have access to the portal.`,
                                data: { id: cell },
                              });
                            },
                          },
                        ]
                      : [
                          {
                            text: 'Re-Invite User',
                            onClick: () => {
                              dispatch(
                                reInviteUser(cell, {
                                  roles: map(row.roles, 'name'),
                                })
                              );
                            },
                          },
                        ]
                  }
                  caret={false}
                  size="sm"
                  color=""
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default TeamMemberTable;
