import React from 'react';
// import PropTypes from 'prop-types';
import classes from '../StoryAssignments.module.scss';
import noAvatarIcon from 'assets/img/icons/no-avatar.svg';
import SelectAsync from 'components/FormFields/SelectAsync';
import cs from 'classnames';
import { analyticsConstants } from 'helpers/analytics';
const UserItem = ({ user, label, pointer }) => {
  if (!user)
    return (
      <span
        className={cs(
          'd-flex align-items-center',
          classes.avatar,
          classes.empty,
          { [classes.noPointer]: pointer === false }
        )}
      >
        <img src={noAvatarIcon} alt="No Avatar" className="mr-2" /> + Assign
        Team
      </span>
    );

  return (
    <span
      className={cs('d-flex align-items-center', classes.avatar, {
        [classes.noPointer]: pointer === false,
      })}
    >
      <img
        src={user.avatar}
        alt={user.name}
        className={cs('mr-2', {
          [classes.noPointer]: pointer === false,
        })}
      />
      <div
        className={cs(classes.ellipsis, {
          [classes.label]: label,
          [classes.noPointer]: pointer === false,
        })}
      >
        {user.name}
      </div>
    </span>
  );
};

const Owner = ({
  formik,
  projectId,
  smallView,
  isEditAllowed,
  analyticsSendEvent,
}) => {
  const { values, setFieldValue, handleSubmit } = formik;

  return (
    <div className={classes.assignmentOwner}>
      <div className={classes.title}>Assigned To</div>

      {isEditAllowed ? (
        <SelectAsync
          url={`/initiatives/${projectId}/team/dropdown`}
          id={`assignment-owner-task-${values.id}`}
          onChange={data => {
            analyticsSendEvent({
              action: analyticsConstants.action.update_assignment_owner,
            });
            setFieldValue('user', data);
            handleSubmit();
          }}
          getOptionLabel={option => <UserItem user={option} label={false} />}
          placement={smallView ? 'bottom-end' : 'bottom-start'}
        >
          <UserItem user={values.user} label={true} pointer={true} />
        </SelectAsync>
      ) : (
        <UserItem user={values.user} label={true} pointer={false} />
      )}
    </div>
  );
};

Owner.propTypes = {};
Owner.defaultProps = {};

export default Owner;
