import React from 'react';
import { Row } from 'reactstrap';
import classes from './Address.module.scss';
import className from 'classnames';
import FormikInput from 'components/FormFields/Input/FormikInput';

const Address = ({ title, name, values }) => (
  <>
    <Row
      className={className('mb-3 font-weight-bold', classes.row)}
    >{`${title} Address`}</Row>
    <Row className={classes.row}>
      <FormikInput
        name={`${name}_street`}
        label="Address"
        type="text"
        groupClassName={classes.textInputAddress}
      />
      <FormikInput
        name={`${name}_city`}
        label="City"
        type="text"
        groupClassName={classes.textInputLeftMargin}
      />
    </Row>
    <Row className={classes.row}>
      <FormikInput
        name={`${name}_state`}
        label="State"
        groupClassName={classes.textInputRightMargin}
      />

      <FormikInput
        name={`${name}_country`}
        label="Country"
        type="text"
        groupClassName={classes.textInputMiddleMargin}
      />
      <FormikInput
        name={`${name}_zip`}
        label="Zip Code"
        type="text"
        groupClassName={classes.textInputLeftMargin}
      />
    </Row>
  </>
);

export default Address;
