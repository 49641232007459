import React from 'react';
import classes from './InitialInfo.module.scss';
import Button from 'components/Button';
import cx from 'classnames';
import { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

const docInfo = {
  'General Ledger': {
    links: [
      {
        label: 'Read more about General Ledgers',
        url:
          'https://quickbooks.intuit.com/learn-support/en-au/help-article/financial-reports/find-general-ledger-report/L2n5SbHls_AU_en_AU#:~:text=A%20General%20Ledger%20report%20in,Receivable%2C%20Equity%20and%20Undeposited%20Funds',
      },
    ],
  },
  'Profit and Loss Statement': {
    links: [
      {
        label: 'Read more about Profit and Loss Statement',
        url:
          'https://quickbooks.intuit.com/r/bookkeeping/what-is-a-profit-and-loss-statement/',
      },
    ],
  },
  'Federal & State Tax Returns': {
    links: [
      {
        label: 'Read more about what Tax Return Documents are needed.',
        url:
          'https://www.irs.gov/businesses/small-businesses-self-employed/business-structures',
      },
    ],
  },
  'Tax Returns': {
    links: [
      {
        label: 'Read more about what Tax Return Documents are needed.',
        url:
          'https://www.irs.gov/businesses/small-businesses-self-employed/business-structures',
      },
    ],
  },

  'Employee Payroll Reports - W2s': {
    links: [
      {
        label: 'Example Document',
        url: 'https://www.irs.gov/pub/irs-pdf/fw2.pdf',
      },
      {
        label: 'Read more about Employee Payroll Reports - W2s',
        url: 'https://www.irs.gov/forms-pubs/about-form-w-2',
      },
    ],
  },
  '1099 Forms': {
    links: [
      {
        label: 'Example Document',
        url: 'https://www.irs.gov/pub/irs-pdf/f1099msc.pdf',
      },
      {
        label: 'Read more about 1099 Forms',
        url: 'https://www.irs.gov/forms-pubs/about-form-1099-misc',
      },
    ],
  },
  'Supporting Documents': {
    links: [
      {
        label: 'Template Spreadsheet',
        url: '',
      },
    ],
  },
  'Employee Roster': {
    links: [
      {
        label: 'Template Spreadsheet',
        url: '/employee-roster-example.png',
      },
    ],
  },
};

const docText = {
  'General Ledger': 'general ledgers',
  'Profit and Loss Statement': 'profit and loss statement',
  'Federal & State Tax Returns': 'tax returns',
  'Employee Payroll Reports - W2s': 'employee payroll reports - W2s',
  '1099 Forms': '1099 forms',
  'Employee Roster': 'employee rosters',
  'Other Supporting Documents': 'supporting documents',
};

const InitialInfo = ({ data, next, prev, isSupportingDoc = false }) => {
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );
  const [startYear, endYear = null] = currentStudy?.engagement_years
    ?.split('-')
    .map(year => Number(year));

  const info = docInfo[data?.name] ?? null;

  const getYears = () => {
    if (!endYear) {
      return startYear;
    }
    const years = Array.from(
      { length: endYear - startYear + 1 },
      (_, index) => startYear + index
    );
    const result = years.join(', ').replace(/,([^,]*)$/, ' and$1');

    return result;
  };

  return (
    <div className={classes.modalContentWrapper}>
      <ModalBody className={cx('d-flex flex-row flex-wrap', classes.modalBody)}>
        <div className={classes.description}>
          <h3>Description</h3>
          <p>
            Please upload any {docText[data.name]} you have{' '}
            {!isSupportingDoc ? 'as filed' : ''} for {getYears()}.{' '}
            {data.id === 8
              ? ''
              : 'You may upload one at a time or all at once, we’ll ask if there are any years missing later.'}
          </p>
        </div>
        {!isSupportingDoc && (
          <div className={classes.examples}>
            <h3>Examples</h3>
            <p>
              {data.name === 'Other Supporting Documents'
                ? "Aren't sure what we are asking for? Please work with your Strike team to determine if your Study requires any Supporting Documents."
                : 'Aren’t sure what we are asking for? Here are links to help with example images and more information:'}
            </p>
            {info && info.links && (
              <ul>
                {info.links.map(({ label, url }, i) => {
                  return (
                    <li key={i}>
                      <a
                        href={url}
                        {...(url
                          ? { target: '_blank', rel: 'noreferrer' }
                          : { onClick: e => e.preventDefault() })}
                      >
                        {label}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button
          className={classes.back}
          onClick={prev}
          leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
        >
          Back
        </Button>
        <Button
          color="primary"
          // className={('btn-outline-default', classes.next)}
          onClick={next}
        >
          Start Uploading
        </Button>
      </ModalFooter>
    </div>
  );
};

export default InitialInfo;
