import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import classes from './SourceDocuments.module.scss';
import { useParams } from 'react-router';

import { Card } from 'reactstrap';
import SourceDocumentCard from './SourceDocumentCard';
import NewInteractionCard from './NewInteractionCard';
import docsIcon from '../../../../assets/vendor/@fortawesome/fontawesome-free/svgs/regular/docs.svg';
import DocumentCommentModal from 'components/Fulfillment/Modals/DocumentCommentModal';
import AllDocumentModal from 'components/Fulfillment/Modals/AllDocumentModal';
import { fetchDocumentStudyTypes } from 'store/actions/documents';
import {
  getDocumentBucketStatusList,
  getFinancialYearStatusList,
} from 'store/actions/fulfillment';
import { NotificationHandler } from 'components/Notifications';
import { get } from 'lodash';
import Button from 'components/Button';
import history from 'helpers/history';

const SourceDocuments = ({ handleDocAndYearStatusUpdate }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const id = get(params, 'id');

  const documents = useSelector(({ documents }) =>
    get(documents, `documentStudyTypes.data.data`, [])
  );

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [viewAllDocument, setViewAllDocument] = useState(false);
  const [documentData, setDocumentData] = useState(null);
  const [sourceDocuments, setSourceDocuments] = useState([]);
  const currentStudyYear = get(currentStudy, 'current_study');

  const toggle = () => setViewAllDocument(!viewAllDocument);

  const handleCloseModal = message => {
    setIsDocumentModalOpen(false);
    if (message != '') {
      NotificationHandler.open({
        title: 'Success',
        message,
        operation: 'success',
        icon: ' ',
      });
    }
  };

  const getDocs = async () => {
    const { data = [] } = await dispatch(
      fetchDocumentStudyTypes(currentStudy?.id)
    );
    setSourceDocuments(data);
  };

  useEffect(() => {
    getDocs();
    dispatch(getDocumentBucketStatusList());
    dispatch(getFinancialYearStatusList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStudy, dispatch]);

  const handleOpenPreviewModal = data => {
    history.push(
      `/admin/studies/${id}/document-type/${data.financial_year_document_id}`
    );
    // setIsDocumentModalOpen(true);
    setDocumentData(data);
    setModalData(data);
  };

  return (
    <>
      {isDocumentModalOpen && (
        <DocumentCommentModal
          params={{
            parent_id: modalData?.financial_year_document_id,
            parent_type: 'FinancialYearDocumentType',
          }}
          documentTypeId={documentData?.id}
          activeYear={currentStudyYear}
          title={
            <div className="d-flex">
              <i className="fas fa-folder-open" />
              <div className="mr-2 ml-2">{2021}</div>
              <div>{modalData?.short_name}</div>
            </div>
          }
          userType="admin"
          isOpen={isDocumentModalOpen}
          closeModal={message => {
            handleCloseModal(message);
            getDocs();
          }}
          initiativeId={currentStudy?.id}
          handleDocAndYearStatusUpdate={handleDocAndYearStatusUpdate}
        />
      )}
      {viewAllDocument ? (
        <AllDocumentModal
          isOpen={viewAllDocument}
          closeModal={toggle}
          batches={documents}
        />
      ) : null}
      <Card className={classes.card}>
        <div className={classes.container}>
          <Button
            onClick={() => {
              setViewAllDocument(true);
            }}
            className={classes.expandDocTag}
            color="secondary"
          >
            View All Documents
          </Button>
        </div>
        {/* <CardBody className={cx(classes.notificationList)} key="notifications">
          <p>
            When the client begins to share the documents as requested for this
            study, you will begin to see them here. Invite them or send a
            message to have them get started.
          </p>
        </CardBody> */}
        <div className={classes.documentsWrapper}>
          {sourceDocuments?.map((data, index) => {
            return (
              <div key={index} className={classes.documentCard}>
                <SourceDocumentCard
                  data={data}
                  onClickDocument={() => handleOpenPreviewModal(data)}
                />
                {(data?.comment_activities_count > 0 ||
                  data?.document_activities_count > 0) && (
                  <NewInteractionCard data={data} />
                )}
              </div>
            );
          })}
        </div>
      </Card>
    </>
  );
};

export default SourceDocuments;
