import Request from './request';

export const fetchTimezones = async () => {
  return Request.call({
    url: 'meta/dropdowns?list=timezones',
    method: 'GET',
  });
};

export const getDropdown = param => {
  return Request.call({
    url: `/meta/dropdowns`,
    method: 'GET',
    params: {
      list: param,
    },
  });
};

export const getIndustries = () => {
  return Request.call({
    url: `/meta/dropdowns?list=client_industries`,
    method: 'GET',
  });
};

export const getAccountTypes = () => {
  return Request.call({
    url: `/meta/dropdowns?list=account_types`,
    method: 'GET',
  });
};

export const getCompanySizes = () => {
  return Request.call({
    url: `/meta/dropdowns?list=client_sizes`,
    method: 'GET',
  });
};

export const getLeadSources = () => {
  return Request.call({
    url: `/meta/dropdowns?list=lead_sources`,
    method: 'GET',
  });
};

export const getYears = () => {
  return Request.call({
    url: `/meta/dropdowns?list=financial_years`,
    method: 'GET',
  });
};

export const getContactTerms = () => {
  return Request.call({
    url: `/meta/dropdowns?list=contract_terms_options`,
    method: 'GET',
  });
};

export const getQuarters = () => {
  return Request.call({
    url: `/meta/dropdowns?list=quarters`,
    method: 'GET',
  });
};

export const getControllerGroupFillingTypes = () => {
  return Request.call({
    url: `/meta/dropdowns?list=controller_group_filling_types`,
    method: 'GET',
  });
};

export const getEntityTypes = () => {
  return Request.call({
    url: `/meta/dropdowns?list=entity_types`,
    method: 'GET',
  });
};

export const getAccountLevel = async () => {
  return Request.call({
    url: `/meta/dropdowns?list=account_levels`,
    method: 'GET',
  });
};

export const getStudyPhases = async () => {
  return Request.call({
    url: `/meta/dropdowns?list=project_phases`,
    method: 'GET',
  });
};
