import Types from '../types/quickActions';

const initialState = {
  createStoryAction: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  createClientTaskAction: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function QuickActions(state = initialState, action) {
  switch (action.type) {
    case Types.CREATE_STORY_FROM_QUICK_ACTIONS_INPROGRESS:
      return {
        ...state,
        createStoryAction: {
          ...state.createStoryAction,
          isInProgress: true,
        },
      };
    case Types.CREATE_STORY_FROM_QUICK_ACTIONS_SUCCESS:
      return {
        ...state,
        getProject: {
          ...state.getProject,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_STORY_FROM_QUICK_ACTIONS_FAILURE:
      return {
        ...state,
        getProject: {
          ...state.getProject,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_CLIENT_TASK_FROM_QUICK_ACTION_INPROGRESS:
      return {
        ...state,
        createClientTaskAction: {
          ...state.createStoryAction,
          isInProgress: true,
        },
      };
    case Types.CREATE_CLIENT_TASK_FROM_QUICK_ACTION_SUCCESS:
      return {
        ...state,
        createClientTaskAction: {
          ...state.getProject,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_CLIENT_TASK_FROM_QUICK_ACTION_FAILURE:
      return {
        ...state,
        createClientTaskAction: {
          ...state.createStoryAction,
          isInProgress: true,
        },
      };
    default:
      return state;
  }
}
