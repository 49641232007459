import React, { useRef } from 'react';
import classes from './TimeLogDoughnutChart.module.scss';
import { Doughnut } from 'react-chartjs-2';

import TooltipForChartHandler from 'components/TooltipForChart/TooltipForChartHandler';
import get from 'lodash/get';
import { secondsToHHMMSS } from 'helpers/times';
import clockIcon from 'assets/img/icons/clock-white.svg';
import { useSelector } from 'react-redux';

const TimeLogDoughnutChart = () => {
  const refChart = useRef(null);

  const doughnutChart = useSelector(({ timelogReport }) =>
    get(timelogReport, 'doughnutChart')
  );

  const projects = get(doughnutChart, 'data.initiatives', []) || [];
  let labels = [],
    data = [],
    backgroundColor = [];

  projects.map(item => {
    labels.push(item.name ? item.name : 'Unassigned');
    data.push(item.duration_hrs);
    backgroundColor.push(`#${item.color}`);
    return true;
  });

  var DoughnutChartData = {
    labels: labels,
    datasets: [
      {
        data,
        backgroundColor,
        hoverBackgroundColor: backgroundColor,
      },
    ],
  };

  const TooltipContent = ({ data }) => {
    const time = secondsToHHMMSS(data.value * 3600);
    const label = data.label;
    return (
      <div className={classes.tooltip}>
        <b>{label}</b> <br />
        <img src={clockIcon} alt="clock" /> {time}
      </div>
    );
  };

  return (
    <Doughnut
      ref={refChart}
      width={200}
      height={200}
      data={DoughnutChartData}
      options={{
        animation: false,
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
          custom: tooltipModel => {
            if (tooltipModel.opacity === 0) {
              TooltipForChartHandler.close();
              return;
            }

            let point = get(tooltipModel, 'body.0.lines.0', null);

            if (!point) return;
            const position = refChart.current.chartInstance.canvas.getBoundingClientRect();

            point = point.split(': ');
            TooltipForChartHandler.open(
              position,
              tooltipModel,
              <TooltipContent data={{ label: point[0], value: point[1] }} />
            );
          },
        },
      }}
      datasetKeyProvider={() => Math.random()}
      className="chart-canvas"
      id="chart-hour-logged"
    />
  );
};

TimeLogDoughnutChart.propTypes = {};
TimeLogDoughnutChart.defaultProps = {};

export default TimeLogDoughnutChart;
