import orderBy from 'lodash/orderBy'; // to reassign the sort_order to the list
export const reassignOrder = (list, orderField = 'sort_order') =>
  list.map((item, index) => ({
    ...item,
    [orderField]: index + 1,
  }));

// to reorder items within the list
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// to move items between two lists
export const move = (source, destination, sourceIndex, destinationIndex) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(sourceIndex, 1);

  destClone.splice(
    destinationIndex < 0 ? destination.length : destinationIndex,
    0,
    removed
  );
  return {
    sourceClone,
    destClone,
  };
};

export const getModifiedOrder = ({
  destination,
  source,
  data,
  sortField = 'sort_order',
}) => {
  if (destination === null) {
    return;
  }
  const { index: destinationIndex } = destination;
  const { index: sourceIndex } = source;
  if (destination && destinationIndex !== sourceIndex) {
    let modifiedOrder = [];
    for (
      let index = Math.min(sourceIndex, destinationIndex);
      index <= Math.max(sourceIndex, destinationIndex);
      index++
    ) {
      if (index === sourceIndex) {
        modifiedOrder.push({
          ...data[index],
          [sortField]: data[destinationIndex][sortField],
        });
      } else {
        const elementIndexForSortOrder =
          sourceIndex < destinationIndex ? -1 : 1;
        modifiedOrder.push({
          ...data[index],
          [sortField]: data[index + elementIndexForSortOrder][sortField],
        });
      }
    }
    const modifiedData = [...data];
    modifiedData.splice(
      Math.min(sourceIndex, destinationIndex),
      modifiedOrder.length,
      ...modifiedOrder
    );
    return {
      modifiedOrder,
      modifiedData: orderBy(modifiedData, [sortField], ['asc']),
    };
  }
};
