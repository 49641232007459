import React, { useState } from 'react';
import { Row } from 'reactstrap';
import classes from './GeneralDetails.module.scss';
import FormikInput from 'components/FormFields/Input/FormikInput';
import RSelect from 'components/FormFields/RSelect';
import Input from 'components/FormFields/Input/Input';
import className from 'classnames';
import { getOptions } from 'helpers/clientProfile';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Radio from 'components/FormFields/Radio';
import Button from 'components/Button';

const checkBoxValues = [
  {
    label: 'Strike Tax',
    value: 'Strike Tax',
  },
  {
    label: 'Hemp Tax Credits',
    value: 'Hemp Tax Credits',
  },
];

const GeneralDetails = ({ values, setFieldValue, accountLevels }) => {
  const accountTypes = useSelector(({ metadata }) =>
    get(metadata, 'accountTypes.data', {})
  );

  const handleRadioChange = index => () => {
    const newPrimaryEmail = values.email_addresses.map((val, i) => ({
      ...val,
      is_primary: index === i ? 1 : 0,
    }));
    setFieldValue('email_addresses', newPrimaryEmail);
  };

  const addNewAlternateEmail = () => {
    let newAlternateEmail = [...values.email_addresses];
    newAlternateEmail.push({ email: '', is_primary: 0 });
    setFieldValue('email_addresses', newAlternateEmail);
  };

  const deleteAlternateEmail = index => () => {
    let emails = [...values.email_addresses];
    let newEmails = [...emails.slice(0, index), ...emails.slice(index + 1)];
    setFieldValue('email_addresses', newEmails);
  };

  const handleCheckboxChange = value => () => {
    const newValues =
      values.associated_entity.findIndex(c => c === value) > -1
        ? values.associated_entity.filter(f => f !== value)
        : [...values.associated_entity, value];
    setFieldValue('associated_entity', newValues);
  };

  return (
    <>
      <Row className={className(classes.row)}>
        <div className={classes.textInputRightMargin}>
          <label className="form-control-label">Associated Entity</label>
          <Row className={className('flex-column mb-4', classes.row)}>
            {checkBoxValues.map((option, index) => (
              <Input
                key={index}
                label={option.label}
                name={option.value}
                type="checkbox"
                id={option.value}
                checked={
                  values.associated_entity.findIndex(c => c === option.value) >
                  -1
                }
                onChange={handleCheckboxChange(option.value)}
                className={classes.checkBox}
              />
            ))}
          </Row>
        </div>
        <div className={classes.textInputLeftMargin}>
          <RSelect
            name="account_level"
            label="Account Level"
            options={getOptions(accountLevels)}
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            value={values.account_level}
            onChange={value => {
              setFieldValue('account_level', value ? value : null);
            }}
          />
        </div>
      </Row>
      <Row className={classes.row}>
        <FormikInput
          name="name"
          label="Company Legal Name"
          type="text"
          groupClassName={classes.textInputRightMargin}
          placeholder="Best Company LLC dba Best Company"
        />
        <FormikInput
          name="website"
          label="Website"
          type="text"
          groupClassName={classes.textInputLeftMargin}
          placeholder="https://"
        />
      </Row>
      <Row className={classes.row}>
        <div className={classes.textInputRightMargin}>
          <RSelect
            name="account_type"
            label="Account Type"
            options={getOptions(accountTypes)}
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            value={values.account_type}
            onChange={value => {
              setFieldValue('account_type', value ? value : null);
            }}
          />
        </div>
        <FormikInput
          name="payroll_provider"
          label="Payroll Provider"
          type="text"
          groupClassName={classes.textInputLeftMargin}
        />
      </Row>
      <Row className={classes.row}>
        <FormikInput
          name="entity_hash_1"
          label="Entity #1"
          type="text"
          groupClassName={classes.textInputRightMargin}
        />
        <FormikInput
          name="entity_hash_1_ein"
          label="Entity #1 EIN"
          type="text"
          groupClassName={classes.textInputLeftMargin}
        />
      </Row>
      <Row className={classes.row}>
        <FormikInput
          name="phone"
          label="Phone"
          type="text"
          groupClassName={classes.textInputRightMargin}
        />
        <FormikInput
          name="phone_alt"
          label="Alternate Phone"
          type="text"
          groupClassName={classes.textInputLeftMargin}
        />
      </Row>
      <Row className={classes.row}>
        <div
          className={className(
            'd-flex flex-column',
            classes.textInputRightMargin
          )}
        >
          <Row
            className={className(
              'flex-row justify-content-between mb-2',
              classes.row
            )}
          >
            <div className={classes.emailAddress}>Email Address</div>
            <div className={classes.emailAddress}>Primary</div>
          </Row>
          <Row className={className('flex-column', classes.row, classes.radio)}>
            {values.email_addresses.map((val, index) => (
              <div
                key={index}
                className={className(
                  'flex-row justify-content-between',
                  classes.row,
                  classes.email
                )}
              >
                <div className="position-relative d-flex w-100 mr-3">
                  <FormikInput
                    name={`email_addresses[${index}].email`}
                    type="email"
                    groupClassName="w-100"
                  />
                  {!val.is_primary ? (
                    <i
                      className={className('fas fa-trash-alt', classes.icon)}
                      onClick={deleteAlternateEmail(index)}
                    />
                  ) : null}
                </div>
                <Radio
                  id={`email-${index}`}
                  label=" "
                  onChange={handleRadioChange(index)}
                  checked={val.is_primary}
                />
              </div>
            ))}
          </Row>
          <Row className={classes.row}>
            <Button
              outline
              color="primary"
              size="sm"
              className={classes.button}
              onClick={addNewAlternateEmail}
            >
              + Add Email Address
            </Button>
          </Row>
        </div>
        <FormikInput
          name="fax"
          label="Fax"
          type="text"
          groupClassName={classes.textInputLeftMargin}
        />
      </Row>
    </>
  );
};

export default GeneralDetails;
