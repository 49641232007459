import React from 'react';
import PropTypes from 'prop-types';

import InputHour24 from './InputHour24';
import InputHour12 from './InputHour12';

const InputHour = props => {
  const { timeFormat, name, value, onChange, AMPM } = props;

  if (timeFormat === 24)
    return <InputHour24 name={name} value={value} onChange={onChange} />;

  if (timeFormat === 12)
    return (
      <InputHour12 name={name} value={value} onChange={onChange} AMPM={AMPM} />
    );
};

InputHour.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  timeFormat: PropTypes.number,
  AMPM: PropTypes.string,
};
InputHour.defaultProps = {};

export default InputHour;
