import React, { useEffect } from 'react';
import { Card, CardHeader, CardBody, Row } from 'reactstrap';
import classes from './KickOffCall.module.scss';
import FormikInput from 'components/FormFields/Input/FormikInput';
import RSelect from 'components/FormFields/RSelect';
import { Formik, Form } from 'formik';
import className from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import * as Yup from 'yup';
import { getOptions } from 'helpers/clientProfile';
import { fetchControllerGroupFillingTypes } from 'store/actions/metadata';
import { getValueFromOptions, yesOrNoOptions } from 'helpers/dropdownHelpers';
import YesNoDropdown from 'views/pages/ClientProfile/KickOffCall/YesNoDropdown';
import Button from 'components/Button';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import Helmet from 'components/Helmet';

const schema = Yup.object().shape({
  entity_name: Yup.string().required('Entity Type is required'),
  entity_name_abreviation: Yup.string().required(
    'Entity Name Abbreviation is required'
  ),
  has_ever_filed_tax_credit: Yup.string().required(
    'Has the company ever filed an R&D credit previously?	is required'
  ),
  no_of_owners: Yup.string().required('How many owners are there?	is required'),
  owner_int_companies: Yup.string().required(
    'Do the owners of the company have any ownership interests in any other companies? is required'
  ),
  company_int_other_comp: Yup.string().required(
    'Does the company itself have any ownership interests in any other companies? is required'
  ),
  using_contract_workers: Yup.string().required(
    'Does the Company use an subcontractors or 1099 workers for design or development work? is required'
  ),
  using_time_tracking: Yup.string().required(
    'Does the Company use a time tracking or project accounting system?	is required'
  ),
  aquired_or_disposed: Yup.string().required(
    'Has the company acquired or disposed of a business or the major portion of a trade or business? is required'
  ),
  states_calc_credit: Yup.string().required(
    'What state(s) are we also calculating a credit? is required'
  ),
  eligible_small_business: Yup.string().required(
    'Eligible Small Business (AMT Offset)? is required'
  ),
  qualified_small_business: Yup.string().required(
    'Qualified Small Business (Payroll Offset)? is required'
  ),
  req_se_income_calc: Yup.string().required(
    'Requires SE Income Calculation? is required'
  ),
  cntr_group_filing_type: Yup.string().required(
    'Controlled Group Filing Type is required'
  ),
  kick_off_call_notes: Yup.string().required('Notes is required'),
});

const KickOffCall = ({ submitForm }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchControllerGroupFillingTypes());
  }, [dispatch]);
  const clientProfileData = useSelector(({ clientProfile }) =>
    get(clientProfile, 'clientProfile.data')
  );

  const controllerGroupFillingTypes = useSelector(({ metadata }) =>
    get(metadata, 'controllerGroupFillingTypes.data', {})
  );

  const renderKickOffCall = ({
    values,
    setFieldValue,
    isValid,
    handleSubmit,
  }) => {
    const handleYesNoChange = key => value => {
      setFieldValue(key, value);
    };

    return (
      <Form onSubmit={handleSubmit}>
        <Row className={classes.row}>
          <FormikInput
            name="entity_name"
            label="Entity Name"
            type="text"
            groupClassName={classes.textInputRightMargin}
          />
          <FormikInput
            name="entity_name_abreviation"
            label="Entity Name Abbreviation"
            type="text"
            groupClassName={classes.textInputLeftMargin}
          />
        </Row>
        <Row className={classes.row}>
          <YesNoDropdown
            name="has_ever_filed_tax_credit"
            label="Has the company ever filed an R&D credit previously?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputRightMargin}
          />

          <div className={classes.textInputLeftMargin}>
            <FormikInput
              name="no_of_owners"
              label="How many owners are there?"
            />
          </div>
        </Row>
        <Row className={classes.row}>
          <YesNoDropdown
            name="owner_int_companies"
            label="Do the owners of the company have any ownership interests in any other companies?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputRightMargin}
          />
          <YesNoDropdown
            name="company_int_other_comp"
            label="Does the company itself have any ownership interests in any other companies?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputLeftMargin}
          />
        </Row>
        <Row className={className('align-items-end', classes.row)}>
          <YesNoDropdown
            name="using_contract_workers"
            label="Does the Company use an subcontractors or 1099 workers for design or development work?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputRightMargin}
          />
          <YesNoDropdown
            name="using_time_tracking"
            label="Does the Company use a time tracking or project accounting system?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputLeftMargin}
          />
        </Row>
        <Row className={className('align-items-end', classes.row)}>
          <YesNoDropdown
            name="aquired_or_disposed"
            label="Has the company acquired or disposed of a business or the major portion of a trade or business?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputRightMargin}
          />
          <div className={classes.textInputLeftMargin}>
            <FormikInput
              name="states_calc_credit"
              label="What state(s) are we also calculating a credit?"
            />
          </div>
        </Row>
        <Row className={classes.row}>
          <YesNoDropdown
            name="eligible_small_business"
            label="Eligible Small Business (AMT Offset)?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputRightMargin}
          />

          <YesNoDropdown
            name="qualified_small_business"
            label="Qualified Small Business (Payroll Offset)?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputLeftMargin}
          />
        </Row>
        <Row className={classes.row}>
          <YesNoDropdown
            name="req_se_income_calc"
            label="Requires SE Income Calculation?"
            values={values}
            onChange={handleYesNoChange}
            wrapperClass={classes.textInputRightMargin}
          />
          <div className={classes.textInputLeftMargin}>
            <RSelect
              name="cntr_group_filing_type"
              label="Controlled Group Filing Type"
              options={getOptions(controllerGroupFillingTypes)}
              value={values.cntr_group_filing_type}
              getOptionLabel={opt => opt.label}
              getOptionValue={opt => opt.value}
              onChange={value => {
                setFieldValue('cntr_group_filing_type', value);
              }}
            />
          </div>
        </Row>
        <Row className={classes.row}>
          <FormikInput
            name="kick_off_call_notes"
            label="Notes"
            type="textarea"
            groupClassName={className('m-0', classes.textInputLeftMargin)}
          />
        </Row>
        <div className="w-100 d-flex justify-content-end">
          <Button disabled={!isValid} color="primary" type="submit">
            Save
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <div className="ml-4 mr-4">
      <Helmet title="Strike Portal - Clients - Kickoff Call Questions" />

      <Card className={classes.card}>
        <CardHeader className={className('h3', classes.cardHeader)}>
          Kickoff Call Questions
        </CardHeader>
        <CardBody>
          <Formik
            initialValues={{
              ...clientProfileData,
              associated_entity:
                clientProfileData.associated_entity &&
                clientProfileData.associated_entity.length
                  ? clientProfileData.associated_entity?.split(',')
                  : [],
              entity_name: clientProfileData.entity_name
                ? clientProfileData.entity_name
                : '',
              entity_name_abreviation: clientProfileData.entity_name_abreviation
                ? clientProfileData.entity_name_abreviation
                : '',
              has_ever_filed_tax_credit: clientProfileData.has_ever_filed_tax_credit
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.has_ever_filed_tax_credit
                  )
                : null,
              no_of_owners: clientProfileData.no_of_owners
                ? clientProfileData.no_of_owners
                : '',
              owner_int_companies: clientProfileData.owner_int_companies
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.owner_int_companies
                  )
                : null,
              company_int_other_comp: clientProfileData.company_int_other_comp
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.company_int_other_comp
                  )
                : null,
              using_contract_workers: clientProfileData.using_contract_workers
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.using_contract_workers
                  )
                : null,
              using_time_tracking: clientProfileData.using_time_tracking
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.using_time_tracking
                  )
                : null,
              aquired_or_disposed: clientProfileData.aquired_or_disposed
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.aquired_or_disposed
                  )
                : null,
              states_calc_credit: clientProfileData.states_calc_credit
                ? clientProfileData.states_calc_credit
                : '',
              eligible_small_business: clientProfileData.eligible_small_business
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.eligible_small_business
                  )
                : null,
              qualified_small_business: clientProfileData.qualified_small_business
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.qualified_small_business
                  )
                : null,
              req_se_income_calc: clientProfileData.req_se_income_calc
                ? getValueFromOptions(
                    yesOrNoOptions,
                    clientProfileData.req_se_income_calc
                  )
                : null,
              cntr_group_filing_type: clientProfileData.cntr_group_filing_type
                ? getValueFromOptions(
                    controllerGroupFillingTypes,
                    clientProfileData.cntr_group_filing_type
                  )
                : null,
              kick_off_call_notes: clientProfileData.kick_off_call_notes
                ? clientProfileData.kick_off_call_notes
                : '',
            }}
            enableReinitialize
            // validationSchema={schema}
            onSubmit={submitForm}
          >
            {renderKickOffCall}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default KickOffCall;
