import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_STORY_INPROGRESS',
  'FETCH_STORY_SUCCESS',
  'FETCH_STORY_FAILURE',
  'UPDATE_CLIENT_TASK_INPROGRESS',
  'UPDATE_CLIENT_TASK_SUCCESS',
  'UPDATE_CLIENT_TASK_FAILURE',
  'MARK_COMPLETE_CLIENT_TASK_INPROGRESS',
  'MARK_COMPLETE_CLIENT_TASK_SUCCESS',
  'MARK_COMPLETE_CLIENT_TASK_FAILURE',
  'UPDATE_STORY_INPROGRESS',
  'UPDATE_STORY_SUCCESS',
  'UPDATE_STORY_FAILURE',
  'FILE_UPLOAD_INPROGRESS',
  'FILE_UPLOAD_SUCCESS',
  'FILE_UPLOAD_FAILURE',
  'FILE_DELETE_INPROGRESS',
  'FILE_DELETE_SUCCESS',
  'FILE_DELETE_FAILURE',
  'ADD_ASSIGNMENT_LOADING',
  'ADD_ASSIGNMENT_SUCCESS',
  'ADD_ASSIGNMENT_ERROR',
  'UPDATE_ASSIGNMENT_LOADING',
  'UPDATE_ASSIGNMENT_SUCCESS',
  'UPDATE_ASSIGNMENT_ERROR',
  'ADD_CHECKLIST_LOADING',
  'ADD_CHECKLIST_SUCCESS',
  'ADD_CHECKLIST_ERROR',
  'FETCH_INTITIAL_COMMENTS_INPROGRESS',
  'FETCH_STORY_COMMENTS_INPROGRESS',
  'SET_STORY_COMMENTS',
  'FETCH_STORY_COMMENTS_SUCCESS',
  'FETCH_STORY_COMMENTS_FAILURE',
  'FETCH_MORE_STORY_COMMENTS_SUCCESS',
  'CLEAR_STORY_COMMENTS',
  'POST_STORY_COMMENT_INPROGRESS',
  'POST_STORY_COMMENT_SUCCESS',
  'POST_STORY_COMMENT_FAILURE',
  'DELETE_STORY_COMMENT_INPROGRESS',
  'DELETE_STORY_COMMENT_SUCCESS',
  'DELETE_STORY_COMMENT_FAILURE',
  'MARK_READ_COMMENTS_INPROGRESS',
  'MARK_READ_COMMENTS_SUCCESS',
  'MARK_READ_COMMENTS_FAILURE',
  'SET_STORY_COMMENT_UNREAD_FROM',
  'FETCH_STORY_STATUSES_INPROGRESS',
  'FETCH_STORY_STATUSES_SUCCESS',
  'FETCH_STORY_STATUSES_FAILURE',
  'FETCH_STORY_PRIORITIES_INPROGRESS',
  'FETCH_STORY_PRIORITIES_SUCCESS',
  'FETCH_STORY_PRIORITIES_FAILURE',
  'FETCH_COMPLEXITIES_INPROGRESS',
  'FETCH_COMPLEXITIES_SUCCESS',
  'FETCH_COMPLEXITIES_FAILURE',
  'FETCH_SCORE_MATRIX_INPROGRESS',
  'FETCH_SCORE_MATRIX_SUCCESS',
  'FETCH_SCORE_MATRIX_FAILURE',
  'SET_TYPING_USERS',
  'FETCH_STORY_ACTIVITIES_INPROGRESS',
  'FETCH_STORY_ACTIVITIES_SUCCESS',
  'FETCH_STORY_ACTIVITIES_FAILURE',
  'FETCH_MORE_STORY_ACTIVITIES_INPROGRESS',
  'FETCH_MORE_STORY_ACTIVITIES_SUCCESS',
  'FETCH_MORE_STORY_ACTIVITIES_FAILURE',
  'FETCH_TASK_TYPES_INPROGRESS',
  'FETCH_TASK_TYPES_SUCCESS',
  'FETCH_TASK_TYPES_FAILURE',
  'FETCH_UNREAD_COUNT_INPROGRESS',
  'FETCH_UNREAD_COUNT_SUCCESS',
  'FETCH_UNREAD_COUNT_FAILURE'
);
