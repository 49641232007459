import Types from 'store/types/timelogReportTypes';
import {
  getDataBarChart,
  getDataDoughnutChart,
  postDataSummary,
  postDataDetail,
  patchBillableById,
  patchApproveById,
} from 'api/timelogReportApi';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const doGetBarChart = (data = null) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_BAR_CHART_LOADING,
    });

    const resp = await getDataBarChart(data);

    if (resp.status === 1) {
      dispatch({
        type: Types.GET_BAR_CHART_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      dispatch({
        type: Types.GET_BAR_CHART_ERROR,
        status: resp.status,
        message: resp.message,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }
  };
};

export const doGetDoughnutChart = (data = null) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_DOUGHNUT_CHART_LOADING,
    });

    const resp = await getDataDoughnutChart(data);

    if (resp.status === 1) {
      dispatch({
        type: Types.GET_DOUGHNUT_CHART_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      dispatch({
        type: Types.GET_DOUGHNUT_CHART_ERROR,
        status: resp.status,
        message: resp.message,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }
  };
};

export const doPostDataSummary = (data = null, sort = '-duration') => {
  return async dispatch => {
    dispatch({
      type: Types.SUMMARY_GROUP_LOADING,
    });

    const resp = await postDataSummary(data, sort);

    if (resp.status === 1) {
      dispatch({
        type: Types.SUMMARY_GROUP_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      dispatch({
        type: Types.SUMMARY_GROUP_ERROR,
        status: resp.status,
        message: resp.message,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp;
  };
};

export const doPostDataSummarySubGroup = (data = null, sort = '-duration') => {
  return async dispatch => {
    dispatch({
      type: Types.SUMMARY_SUBGROUP_LOADING,
    });

    const resp = await postDataSummary(data, sort, true);

    if (resp.status === 1) {
      dispatch({
        type: Types.SUMMARY_SUBGROUP_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      dispatch({
        type: Types.SUMMARY_SUBGROUP_ERROR,
        status: resp.status,
        message: resp.message,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp;
  };
};

export const doPostDataDetails = (data = null, sort = '-date_start', page) => {
  return async dispatch => {
    dispatch({
      type: Types.TIMELOG_REPORT_DETAILS_LOADING,
    });

    const resp = await postDataDetail(data, sort, page);

    if (resp.status === 1) {
      dispatch({
        type: Types.TIMELOG_REPORT_DETAILS_SUCCESS,
        status: resp.status,
        data: resp,
      });
    } else {
      dispatch({
        type: Types.TIMELOG_REPORT_DETAILS_ERROR,
        status: resp.status,
        message: resp.message,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp;
  };
};

export const doPatchAction = (id, data, action) => {
  return async dispatch => {
    dispatch({
      type: Types.TIMELOG_REPORT_ACTION_LOADING,
    });

    let resp = null;

    switch (action) {
      case 'is_approved':
        resp = await patchApproveById(id, data);
        break;

      case 'is_billable':
      default:
        resp = await patchBillableById(id, data);
        break;
    }

    if (resp.status === 1) {
      dispatch({
        type: Types.TIMELOG_REPORT_ACTION_SUCCESS,
        status: resp.status,
        id: id,
        data: {
          key: action,
          value: data,
        },
      });
    } else {
      dispatch({
        type: Types.TIMELOG_REPORT_ACTION_ERROR,
        status: resp.status,
        message: resp.message,
      });

      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
    }

    return resp;
  };
};
