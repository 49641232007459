import React from 'react';
import classes from './ClientStudyDocuments.module.scss';
import { useLocation } from 'react-router';
import SourceDocuments from 'views/pages/FulfillmentStudyDashboard/SourceDocuments';
import RecentlyViewedStudies from 'views/pages/dashboards/FulfillmentDashboard/RecentlyViewedStudies';
import history from 'helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { getProject as getProjectAction } from 'store/actions/projects';
import { get } from 'lodash';
import Loading from 'components/Loading';

const ClientStudyDocuments = ({ clientId }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentStudyLoading = useSelector(({ project }) =>
    get(project, 'getProject.isInProgress', false)
  );

  const handleStudyChange = studyId => {
    if (studyId) {
      history.push(`${pathname}?initiative_id=${studyId}`);
      dispatch(getProjectAction(studyId));
    }
  };

  return (
    <div className={classes.wrapper} fluid>
      <div className={classes.studyWrapper}>
        <RecentlyViewedStudies
          rowAction={row => {
            handleStudyChange(row?.id);
          }}
          showActive
          cardClassName={classes.card}
          title="Studies"
          listParams={{ type: '', limit: 10, client: clientId }}
          isDocumentPage
          fetchFirstStudy={studies => {
            const firstStudyId = studies?.[0]?.id;
            if (firstStudyId) {
              handleStudyChange(firstStudyId);
            }
          }}
        />
      </div>

      <div className={classes.allDocumentsWrapper}>
        {currentStudyLoading ? (
          <Loading />
        ) : (
          <SourceDocuments handleDocAndYearStatusUpdate={() => {}} />
        )}
      </div>
    </div>
  );
};

export default ClientStudyDocuments;
