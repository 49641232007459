import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import TypingIndicator from 'components/TypingIndicator';

const UsersTyping = () => {
  const users = useSelector(({ story }) =>
    get(story, 'comments.typingUsers.users', [])
  );

  if (!users.length) return null;
  else if (users.length === 1) {
    return (
      <div className="d-flex py-1">
        <small className="mr-2">{users[0].name} is typing</small>
        <TypingIndicator />
      </div>
    );
  }
  return (
    <div className="d-flex py-1">
      <small className="mr-2">{users.length} people are typing</small>
      <TypingIndicator />
    </div>
  );
};

export default UsersTyping;
