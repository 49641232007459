import AuthHeader from 'components/Headers/AuthHeader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import get from 'lodash/get';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import classes from './Accept.module.scss';
import qs from 'query-string';
import history from 'helpers/history';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import AuthNavbar from 'components/Navbars/AuthNavbar';
import { acceptInvite } from 'store/actions/authActions';

const Accept = props => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   document.body.classList.add('bg-default');
  // }, []);

  const queryString = qs.parse(get(props, 'location.search', ''));
  const companyName = get(queryString, 'companyName', '');
  const avatar = get(queryString, 'invited_by_avatar', '');
  const userEmail = get(queryString, 'email', '');
  const userId = get(queryString, 'user_id', null);
  const companyId = get(queryString, 'companyId', '');
  const invitedBy = get(queryString, 'invited_by_name', '');
  const token = get(queryString, 'token', '');

  const handleAccept = async () => {
    setLoading(true);
    const status = await dispatch(
      acceptInvite(companyId, { token, email: userEmail })
    );
    if (status !== 1) setLoading(false);
  };

  if (userId) {
    return (
      <div className="main-content">
        <AuthNavbar />
        <AuthHeader
          title={`Joining ${companyName}`}
          lead="Accept the invite below to join this organization-"
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className={cs('border-0', classes.accept)}>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className={classes.content}>
                    <div className="text-center text-muted mb-4">
                      <Avatar
                        url={decodeURIComponent(avatar)}
                        className={classes.avatar}
                      />
                    </div>
                    <h4 className="text-center w-75">
                      {invitedBy} invited you to collaborate with {companyName}
                    </h4>
                    <Button
                      className="mt-4"
                      color="info"
                      onClick={handleAccept}
                      loading={loading}
                    >
                      Accept Invitation
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    const pathname = get(props, 'location.pathname', '');
    const search = get(props, 'location.search', '');

    sessionStorage.setItem('redirect_URL', `${pathname}${search}`);
    history.push('/auth/login');
    return null;
  }
};

export default Accept;
