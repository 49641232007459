import Types from '../types/assignedProjects';

const initialState = {
  assignedProjectState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

const AssignedProjects = (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_ASSIGNED_PROJECTS_INPROGRESS:
      return {
        ...state,
        assignedProjectState: {
          ...state.assignedProjectState,
          isInProgress: true,
        },
      };
    case Types.FETCH_ASSIGNED_PROJECTS_SUCCESS:
      return {
        ...state,
        assignedProjectState: {
          ...state.assignedProjectState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_ASSIGNED_PROJECTS_FAILURE:
      return {
        ...state,
        assignedProjectState: {
          ...state.assignedProjectState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};

export default AssignedProjects;
