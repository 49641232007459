import classes from '../QuickStartTour.module.scss';

export const getStoryCreationSteps = projects => [
  {
    target: '#sidebarItem-projects',
    title: 'Create a Story',
    content: projects.length
      ? 'This is where you will go to view projects and the stories within. A project is where you will find your Stories, Kanban Board and Epics'
      : 'Create Projects to guide your team’s vision. Manage stories from your Project Backlog and Kanban.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.sidebarIcon,
  },
  {
    target: '#projectsTable',
    title: 'Create a Story',
    content: 'Select a project from the Project List.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'top',
    extraPlacementClass: classes.projectsTable,
  },
  {
    target: '#addProjectBtn',
    title: 'Create a Project',
    content: 'Click here to add your Project!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    placement: 'left',
    extraPlacementClass: classes.addBtn,
  },
  {
    target: '#projectNameInput',
    title: 'Create a Project',
    content:
      'Create a project with as little as a name. If you have more details, you can add those now or later!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.addProjectInput,
  },
  {
    target: '#createProjectBtn',
    title: 'Create a Project',
    content: 'Click here to create your Project!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'right',
    extraPlacementClass: classes.createProjectBtn,
  },
  {
    target: '#backlogTab',
    title: 'Create a Story',
    content:
      'Click here to access your Project Backlog. This is where work will be created by your team.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 3,
    placement: 'bottom',
    extraPlacementClass: classes.storyNavigationBacklog,
  },
  {
    target: '#sectionStoryInput',
    title: 'Create a Story',
    content:
      'Type here to give your story a name. Press enter to bring your story to life.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'bottom',
    extraPlacementClass: classes.sectionInputTitle,
  },
  {
    target: '#lastSectionStory',
    title: 'Explore your Story',
    content:
      'Awesome! Open your story to collaborate and communicate with your team.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'bottom',
    extraPlacementClass: classes.sectionLastStory,
  },
  {
    target: '#storyModalStoryName',
    title: 'Explore your Story',
    content:
      'Add details, attachments, and teammates to create a living story for your project.',
    shouldShowDismiss: true,
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.storyModalStoryName,
  },
];
