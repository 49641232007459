import React, { useState, useEffect } from 'react';
import classes from './DocumentViewer.module.scss';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import Viewer from 'react-file-viewer';
import cs from 'classnames';
import { downloadDocument } from 'store/actions/documents';
import { useDispatch } from 'react-redux';
import { previewDocument } from 'api/documents';

import { ReactComponent as DownloadIcon } from 'assets/img/icons/download-icon.svg';
import Loading from 'components/Loading';
import Pagination from 'components/Pagination';
import Button from 'components/Button';

const getFileType = name => {
  const extension = name.split('.').pop();
  const type = extension.toLowerCase();
  return type;
};

const DocumentViewer = ({
  isOpen,
  toggle,
  title,
  files = [],
  handleDownloadAll,
}) => {
  const dispatch = useDispatch();
  const [docs, setDocs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleDownload = fileData => {
    if (fileData) {
      dispatch(downloadDocument(fileData));
    }
  };

  const convertToBase64 = async url => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return null;
    }
  };

  const convertFileToBase64 = async documenturl => {
    try {
      const base64Urls = await Promise.all(
        documenturl.map(async doc => {
          const base64 = await convertToBase64(doc.url);
          if (base64) {
            return {
              url: base64,
              file: doc.file,
            };
          }
        })
      );
      return base64Urls;
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    setLoading(true);
    if (files.length > 0) {
      const fetch = async () => {
        const getData = async file => {
          const rs = await previewDocument(file.id);
          if (rs.status) {
            return { url: rs.url, file: file };
          }
        };

        const promises = files.slice(0, page).map(async file => {
          return await getData(file);
        });

        const results = await Promise.all(promises);
        if (results) {
          const res = await convertFileToBase64(results);
          setDocs(res);
        }
        setLoading(false);
      };
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, page]);

  return (
    <Modal
      size="xl"
      closeButtonVariant="version_2"
      isOpen={isOpen}
      toggle={toggle}
      headerClassName={classes.headerClassName}
      title={
        <div className="d-flex">
          <Button
            className={classes.back}
            onClick={toggle}
            leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
          >
            Back
          </Button>
        </div>
      }
      className={cs(classes.storyModal)}
      scrollable={true}
    >
      <ModalBody className={classes.modalBodyWrapper}>
        <div className={classes.hoverBar}>
          <div className="d-flex align-items-center">
            <div className={classes.fileName}>
              {title}
              <span className={classes.dot}>
                <i className="fa fa-circle" aria-hidden="true"></i>
              </span>
              <span>{files[page - 1]?.doc_type}</span>
              <span className={classes.dot}>
                <i className="fa fa-circle" aria-hidden="true"></i>
              </span>
              <span style={{ fontWeight: 600 }}>{files[page - 1]?.name}</span>
              <span style={{ fontWeight: 600 }}>Document Preview</span>
            </div>
          </div>
          <div
            onClick={() => {
              files.length > 1 && handleDownloadAll();
            }}
            className={classes.download}
          >
            {files.length > 1 && <span>Download All</span>}
            <DownloadIcon
              color="#fff"
              onClick={e => {
                e.stopPropagation();
                handleDownload(docs[page - 1].file);
              }}
            />
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          docs.length > 0 &&
          docs[page - 1] && (
            <>
              <div className={classes.title}>{docs[page - 1].file.name}</div>
              <Viewer
                fileType={getFileType(docs[page - 1].file.name)}
                filePath={docs[page - 1].url}
              />
            </>
          )
        )}
      </ModalBody>
      {files.length > 1 && (
        <div className={classes.paginationWrapper}>
          <Pagination
            totalPage={files.length}
            currentPage={page}
            onClick={page => {
              setPage(page);
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default DocumentViewer;
