import React, { useState, useEffect } from 'react';
import classes from './FileViewer.module.scss';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import Viewer from 'react-file-viewer';
import cs from 'classnames';
import { downloadDocument } from 'store/actions/documents';
import { useDispatch } from 'react-redux';
import { previewDocument } from 'api/documents';
// import get from 'lodash/get';
import Loading from 'components/Loading';

const FileViewer = ({ isOpen, toggle, fileData, title }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(null);
  const [base64, setBase64] = useState(null);
  const [loading, setLoading] = useState(false);
  const [documenturl, setDocumenturl] = useState(null);
  // const documenturl = useSelector(({ documents }) =>
  //   get(documents, 'previewDoc.url', '')
  // );

  useEffect(() => {
    if (fileData) {
      const fileName = fileData?.name;
      const extension = fileName.split('.').pop();
      setType(extension.toLowerCase());
    } else {
      setType(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (documenturl) {
      setLoading(true);
      let previewRequest = new XMLHttpRequest();
      previewRequest.open('GET', documenturl, true);
      previewRequest.responseType = 'blob';
      previewRequest.onload = function(e) {
        let dataStreamReader = new FileReader();
        dataStreamReader.onload = function(event) {
          let resp = event.target.result;
          setBase64(resp);
          setLoading(false);
        };
        let file = previewRequest.response;
        dataStreamReader.readAsDataURL(file);
      };
      previewRequest.onerror = function() {
        setBase64(null);
      };
      previewRequest.send();
    } else {
      setBase64(null);
    }
  }, [documenturl]);

  useEffect(() => {
    const getData = async documentId => {
      const rs = await previewDocument(documentId);
      setDocumenturl(rs.url);
    };

    if (fileData?.id) getData(fileData?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData]);

  const handleDownload = () => {
    dispatch(downloadDocument(fileData));
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={toggle}
      title={
        <div className="d-flex">
          <div className={cs('text-light', classes.cursor)} onClick={toggle}>
            {title}
          </div>
          <div className="mr-1 ml-1">/</div>
          <div>{fileData?.name}</div>
        </div>
      }
      className={cs(classes.storyModal, {
        [classes.pdfOverflow]: type !== 'pdf',
      })}
      scrollable={true}
    >
      <ModalBody className={classes.modalBodyWrapper}>
        <div className={classes.hoverBar}>
          <div className="d-flex align-items-center">
            <i className="fas fa-file mr-2" />
            <div className={classes.fileName}>{fileData?.name}</div>
          </div>
          <div>
            <i
              className={cs('fas fa-download', classes.downloadIcon)}
              onClick={handleDownload}
            />
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          base64 && <Viewer fileType={type} filePath={base64} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default FileViewer;
