import AuthHeader from 'components/Headers/AuthHeader';
import React, { useEffect, useRef } from 'react';
// nodejs library that concatenates classes

// reactstrap components
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import AuthNavbar from 'components/Navbars/AuthNavbar.js';
import AuthFooter from 'components/Footers/AuthFooter.js';
import clsx from 'classnames';
import classes from './Privacy.module.scss';
import Helmet from 'components/Helmet';

const Privacy = () => {
  const ref = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    ref.current.scrollTop = 0;

    return () => {};
  });

  const baseUrl = window.location.origin;

  return (
    <>
      <Helmet title="Strike Portal - Privacy Policy"></Helmet>
      <div className="main-content" ref={ref}>
        <AuthNavbar />

        <AuthHeader />
        <Container className={classes.mainContainer}>
          <Row className="justify-content-center">
            <Col>
              <Card className="bg-secondary border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className={clsx(classes.documentTitle, 'mt-3')}>
                    Privacy Policy of Strike R&D Tax Advisory
                  </div>
                  <div className={clsx(classes.bodyText)}>
                    Strike R&D Tax Advisory operates {baseUrl}, which provides
                    the Project Management Service ('Service')
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    This page is used to inform website visitors regarding our
                    policies with the collection, use, and disclosure of
                    Personal Information if anyone decided to use our Service,{' '}
                    {baseUrl}.
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    If you choose to use our Service, then you agree to the
                    collection and use of information in relation with this
                    policy. The Personal Information that we collect are used
                    for providing and improving the Service. We will not use or
                    share your information with anyone except as described in
                    this Privacy Policy.
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    The terms used in this Privacy Policy have the same meanings
                    as in our Terms and Conditions, which is accessible at
                    Website URL, unless otherwise defined in this Privacy
                    Policy.
                  </div>
                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    {' '}
                    Information Collection and Use
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    For a better experience while using our Service, we may
                    require you to provide us with certain personally
                    identifiable information, including but not limited to your
                    name, phone number, and postal address. The information that
                    we collect will be used to contact or identify you.
                  </div>
                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    {' '}
                    Log Data
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    We want to inform you that whenever you visit our Service,
                    we collect information that your browser sends to us that is
                    called Log Data. This Log Data may include information such
                    as your computer's Internet Protocol (“IP”) address, browser
                    version, pages of our Service that you visit, the time and
                    date of your visit, the time spent on those pages, and other
                    statistics.
                  </div>

                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    Cookies
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    Cookies are files with small amount of data that is commonly
                    used an anonymous unique identifier. These are sent to your
                    browser from the website that you visit and are stored on
                    your computer's hard drive.
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    Our website uses these “cookies” to collection information
                    and to improve our Service. You have the option to either
                    accept or refuse these cookies, and know when a cookie is
                    being sent to your computer. If you choose to refuse our
                    cookies, you may not be able to use some portions of our
                    Service.
                  </div>

                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    Service Providers
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    We may employ third-party companies and individuals due to
                    the following reasons:
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    To facilitate our Service;
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-1')}>
                    {' '}
                    To provide the Service on our behalf;
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-1')}>
                    {' '}
                    To perform Service-related services; or
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-1')}>
                    To assist us in analyzing how our Service is used.
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-1')}>
                    We want to inform our Service users that these third parties
                    have access to your Personal Information. The reason is to
                    perform the tasks assigned to them on our behalf. However,
                    they are obligated not to disclose or use the information
                    for any other purpose.
                  </div>

                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    Security
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    We value your trust in providing us your Personal
                    Information, thus we are striving to use commercially
                    acceptable means of protecting it. But remember that no
                    method of transmission over the internet, or method of
                    electronic storage is 100% secure and reliable, and we
                    cannot guarantee its absolute security.
                  </div>

                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    Links to Other Sites
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    Our Service may contain links to other sites. If you click
                    on a third-party link, you will be directed to that site.
                    Note that these external sites are not operated by us.
                    Therefore, we strongly advise you to review the Privacy
                    Policy of these websites. We have no control over, and
                    assume no responsibility for the content, privacy policies,
                    or practices of any third-party sites or services.
                  </div>

                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    {' '}
                    Children's Privacy
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    Our Services do not address anyone under the age of 13. We
                    do not knowingly collect personal identifiable information
                    from children under 13. In the case we discover that a child
                    under 13 has provided us with personal information, we
                    immediately delete this from our servers. If you are a
                    parent or guardian and you are aware that your child has
                    provided us with personal information, please contact us so
                    that we will be able to do necessary actions.
                  </div>

                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    {' '}
                    Changes to This Privacy Policy
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    We may update our Privacy Policy from time to time. Thus, we
                    advise you to review this page periodically for any changes.
                    We will notify you of any changes by posting the new Privacy
                    Policy on this page. These changes are effective
                    immediately, after they are posted on this page.
                  </div>

                  <div className={clsx(classes.sectionTitle, 'mt-3')}>
                    Contact Us
                  </div>
                  <div className={clsx(classes.bodyText, 'mt-3')}>
                    If you have any questions or suggestions about our Privacy
                    Policy, do not hesitate to contact us.
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Privacy;
