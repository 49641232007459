import React, { useState } from 'react';

import classes from './W2Previewer.module.scss';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import {
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

import cs from 'classnames';

const W2Previewer = ({
  isOpen,
  toggle,
  selectedOption = null,
  options,
  viewPdf,
}) => {
  const [activeOption, setActiveOption] = useState(selectedOption);

  const handlePaginationClick = option => {
    setActiveOption(option);
  };

  const modalHeader = () => (
    <div className={cs(classes.headerWrapper)}>
      <div className={classes.header}>
        <div className={classes.content}>
          <div className={cs(classes.cursor)}>Record Examples</div>
        </div>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
    </div>
  );

  return (
    <Modal
      size="xl"
      noHeader
      isOpen={isOpen}
      toggle={toggle}
      className={cs(classes.storyModal, {})}
      scrollable={true}
    >
      <div className={classes.modalHeader}>{modalHeader()}</div>
      <div>
        <ModalBody className={classes.modalBodyWrapper}>
          <div className={classes.hoverBar}>
            <div className="d-flex align-items-center">
              <div className={classes.fileName}>
                {activeOption?.label} - Example
              </div>
            </div>

            <div
              className={cs(
                'd-flex align-items-center',
                classes.downloadButton
              )}
            ></div>
          </div>
          <div className={classes.pdfPreview}>
            {viewPdf[activeOption?.value] ?? '-'}
          </div>
        </ModalBody>
      </div>
      <ModalFooter className={classes.modalFooter}>
        <Pagination className="pagination justify-content-center">
          <PaginationItem>
            <PaginationLink
              href="#"
              onClick={e => {
                e.preventDefault();
                const findIndex = options.findIndex(
                  d => d.id === activeOption?.id
                );
                if (options[findIndex - 1] && findIndex !== -1) {
                  setActiveOption(options[findIndex - 1]);
                }
              }}
              tabIndex="-1"
            >
              <i className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {options.map((option, i) => {
            return (
              <PaginationItem
                className={cs({
                  [classes.active]: activeOption?.id === option?.id,
                })}
                key={i}
              >
                <PaginationLink
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    handlePaginationClick(option);
                  }}
                >
                  {option?.label?.split(' ')[0]}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          <PaginationItem>
            <PaginationLink
              href="#"
              onClick={e => {
                e.preventDefault();
                const findIndex = options.findIndex(
                  d => d.id === activeOption?.id
                );
                if (options[findIndex + 1] && findIndex !== -1) {
                  setActiveOption(options[findIndex + 1]);
                }
              }}
            >
              <i className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </ModalFooter>
    </Modal>
  );
};

export default W2Previewer;
