import React, { useRef, memo } from 'react';
import cx from 'classnames';
import BaseTable from 'components/Table';
import NoAssignmentTypes from 'assets/img/theme/No_Assignment_Types.svg';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import classes from './AssignmentTypeCustomizations.module.scss';
import ToggleCheckBox from 'components/FormFields/ToggleCheckBox';
import { permissions, useAccess } from 'helpers/permission';
import {
  fetchAssigmentTypes,
  changeAssignmentTypeActiveStatus,
  removeAssignmentType,
} from 'store/actions/assignmentTypesCustomization';
import numeral from 'numeral';
import get from 'lodash/get';
import Dropdowns from 'components/Dropdowns';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import pluralize from 'pluralize';
import { analyticsConstants } from 'helpers/analytics';

const AssignmentTypeTable = ({
  editValues,
  viewMode,
  dispatch,
  setModal,
  analyticsSendEvent,
}) => {
  const tableSearchText = useRef('');
  const tablePageNumber = useRef(1);
  const data = useGetFieldFromObjects(
    'assignmentTypeCustomizations',
    'assignmentTypes.data.data',
    []
  );
  const deleteTaskType = async row => {
    analyticsSendEvent({
      action: analyticsConstants.action.delete_assignment_type,
    });
    await dispatch(removeAssignmentType(row.id));
    dispatch(
      fetchAssigmentTypes(tableSearchText.current, tablePageNumber.current)
    );
  };
  const loading = useGetFieldFromObjects(
    'assignmentTypeCustomizations',
    'assignmentTypes.loading'
  );
  const metaData = useGetFieldFromObjects(
    'assignmentTypeCustomizations',
    'assignmentTypes.data.meta'
  );
  const isEditTaskTypeAllowed = useAccess(permissions.EDIT_TASK_TYPES);
  const isDeleteTaskTypeAllowed = useAccess(permissions.DELETE_TASK_TYPES);

  const NoDataIndication = () => (
    <div
      className={cx(
        'd-flex justify-contnet-center align-items-center p-4',
        classes.noDataWrapper
      )}
    >
      <img
        className={cx('mx-auto mw-100', classes.image)}
        src={NoAssignmentTypes}
        alt="No Assignment Types"
      />
    </div>
  );

  return (
    <BaseTable
      keyField="id"
      noDataIndication={NoDataIndication}
      bootstrap4
      remote
      bordered={false}
      loading={loading}
      paginationOptions={{
        page: get(metaData, 'current_page', 0),
        totalSize: get(metaData, 'total', 1),
        sizePerPage: numeral(get(metaData, 'per_page', 50)).value(),
      }}
      data={data}
      columns={[
        {
          dataField: 'name',
          text: 'Study',
          sort: false,
          classes: 'align-middle font-weight-bold',
          formatter: cell => cell,
        },
        {
          dataField: 'initiatives_count',
          text: '# OF PROJECTS USING TYPE',
          classes: 'align-middle text-muted text-sm font-weight-light',
          sort: false,
          formatter: cell => cell,
        },
        {
          dataField: 'tasks_count',
          text: '# OF ASSIGNMENTS',
          sort: false,
          classes: 'align-middle text-muted text-sm font-weight-light',
          formatter: cell => cell,
        },
        {
          dataField: 'completed_tasks_count',
          text: '# OF ASSIGNMENTS COMPLETED',
          sort: false,
          classes: 'align-middle text-muted text-sm font-weight-light',
          formatter: cell => cell,
        },
        {
          dataField: 'is_active',
          text: 'ACTIVE',
          sort: false,
          classes:
            'align-middle d-flex align-items-center justify-content-between',
          formatter: (cell, row) => (
            <>
              <ToggleCheckBox
                defaultChecked={cell}
                checkedLabel="Yes"
                unCheckedLabel="No"
                onChange={event => {
                  if (isEditTaskTypeAllowed) {
                    analyticsSendEvent({
                      action:
                        analyticsConstants.action[
                          event.target.checked
                            ? 'activate_assignment_type'
                            : 'deactivate_assignment_type'
                        ],
                    });
                    dispatch(
                      changeAssignmentTypeActiveStatus(
                        {
                          ...row,
                          is_active: event.target.checked,
                        },
                        false
                      )
                    );
                  }
                }}
              />
              <Dropdowns
                color=""
                dropdownClasses="ml-auto"
                className="btn-icon-only m-0 text-light float-right"
                size="sm"
                caret={false}
                options={[
                  ...(isEditTaskTypeAllowed
                    ? [
                        {
                          text: 'Rename Type',
                          onClick: () => {
                            editValues.current = row;
                            viewMode.current = 'edit';
                            setModal(true);
                          },
                        },
                      ]
                    : []),
                  ...(isDeleteTaskTypeAllowed
                    ? [
                        {
                          text: 'Delete Type',
                          onClick: () => {
                            AlertPopupHandler.open({
                              onConfirm: deleteTaskType,
                              confirmBtnText: 'Delete Type',
                              text: `You are about to delete “${
                                row.name
                              }”. This type is being used on ${pluralize(
                                'Assignment',
                                row.tasks_count,
                                true
                              )}. Do you want to continue?`,
                              data: row,
                            });
                          },
                        },
                      ]
                    : []),
                ]}
                text={<i className="fas fa-ellipsis-v text-light" />}
              />
            </>
          ),
        },
      ]}
      onTableChange={(type, { page, searchText, ...rest }) => {
        tableSearchText.current = searchText;
        tablePageNumber.current = page;
        if (type === 'search') {
          tablePageNumber.current = 1;
          dispatch(fetchAssigmentTypes(searchText, 1));
        } else {
          dispatch(fetchAssigmentTypes(searchText, page));
        }
      }}
    />
  );
};

export default memo(AssignmentTypeTable);
