const eventEmitter = require('event-emitter');

const EmitterClass = function() {};
eventEmitter(EmitterClass.prototype); // All instances of EmitterClass will expose event-emitter interface

export const EMIT_EVENTS = {
  SUBSCRIPTION_CHANGE: 'subsciption-change',
};

export default new EmitterClass();
