import React, { useState, useEffect } from 'react';
import moment from 'moment';
import NotificationItem from './NotificationItem';
import NotificationNotAvailable from './NotificationNotAvailable';
import NotificationCount from './NotificationCount';
import Loading from 'components/Loading';
import InfiniteScroll from 'react-infinite-scroller';
import {
  fetchNotificationsList,
  markReadNotification,
  markAllNotificationRead,
} from 'store/actions/notifications';
import { useDispatch, useSelector } from 'react-redux';
import classes from './Notification.module.scss';
import get from 'lodash/get';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { analyticsConstants } from 'helpers/analytics';

const NotificationList = props => {
  const {
    notifications,
    count,
    isNotificationLoading,
    toggleNotification,
    shouldMakeCall,
    analyticsSendEvent,
  } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);

  useEffect(() => {
    // update internal loading when it updates in redux from api
    setIsLoading(isNotificationLoading);
  }, [isNotificationLoading]);

  useEffect(() => {
    if (queryProps.notification) {
      dispatch(markReadNotification(queryProps.notification));
    }
  }, [dispatch, queryProps.notification]);

  const handleMarkAllReadClick = e => {
    e.stopPropagation();
    analyticsSendEvent({
      action: analyticsConstants.action.mark_all_notifications_read,
    });
    dispatch(markAllNotificationRead(notifications.length));
  };

  return (
    <>
      <NotificationCount
        count={count}
        handleMarkAllReadClick={handleMarkAllReadClick}
      />
      <div className={classes.notificationList} key="notifications">
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={() => {
            if (!isLoading && shouldMakeCall) {
              // set internal loading true, so we can stop hasMore flag till api loading
              setIsLoading(true);
              // when fetching next set of notifications we are sending the last notifications created_at
              dispatch(
                fetchNotificationsList(
                  notifications.length
                    ? moment(notifications[notifications.length - 1].created_at)
                        .tz(userTimezone)
                        .format('YYYY/MM/DD hh:mm:ss')
                    : null
                )
              );
            }
          }}
          hasMore={shouldMakeCall}
          loader={isNotificationLoading && <Loading key="loader" size={50} />}
          useWindow={false}
        >
          {notifications.length > 0 ? (
            <>
              {notifications.map((notification, index) => (
                <NotificationItem
                  key={`${notification.id}-${index}`}
                  notification={notification}
                  markRead={markReadNotification}
                  toggle={toggleNotification}
                  userTimezone={userTimezone}
                  limit={notifications.length}
                  analyticsSendEvent={analyticsSendEvent}
                />
              ))}
            </>
          ) : (
            <NotificationNotAvailable />
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default NotificationList;
