import CheckBox from 'components/FormFields/CheckBox';
import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import classes from './EditStudyModal.module.scss';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import { useDispatch } from 'react-redux';
import { editStudy } from 'store/actions/fulfillment';
import Button from 'components/Button';
import moment from 'moment';
import Input from 'components/FormFields/Input';
import { getProject as getProjectAction } from 'store/actions/projects';

const currentYear = moment().year();
const YEARS_DATA = Array.from({ length: 10 }, (_, index) => ({
  year: currentYear - index,
  disabled: false,
  checked: false,
})).sort((a, b) => a.year - b.year);

const EditStudyModal = ({ years, isOpen, toggle, parentId, studyName }) => {
  const [data, setData] = useState({
    name: studyName,
    years: YEARS_DATA,
  });
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(studyName);
  const dispatch = useDispatch();

  useEffect(() => {
    const updatedData = data.years.map(item =>
      years.includes(item.year) ? { ...item, checked: true } : item
    );

    setData({ ...data, years: updatedData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = year => {
    const updatedData = data.years.map(item =>
      item.year === year ? { ...item, checked: !item.checked } : item
    );
    setData({ ...data, years: updatedData });
  };

  const handleSave = async () => {
    const focusedYears = data.years
      .filter(item => item.checked)
      .map(item => item.year);
    setLoading(true);
    await dispatch(
      editStudy(parentId, {
        name: data.name,
        years: focusedYears,
      })
    );
    dispatch(getProjectAction(parentId));
    setLoading(false);
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={classes.modalWrapper}
      title={
        <div className={classes.modalTitle}>
          <h3 className="mb-0">{name}</h3>
        </div>
      }
    >
      <ModalBody className={classes.modalBody}>
        <div className={classes.title}>Study Name</div>
        <div className={classes.inputWrapper}>
          <Input
            className="mb-3 w-100"
            size="lg"
            placeholder="Study Name"
            name="studyName"
            type="text"
            value={name}
            onChange={event => {
              data.name = event.target.value;
              setName(event.target.value);
            }}
          />
        </div>
        <div className={classes.title}>
          Select Years needed for this Bucket:
        </div>
        <div className={classes.checkboxWrapper}>
          {data.years?.map(data => (
            <CheckBox
              key={data.year}
              id={data.year}
              label={data.year}
              className={classes.checkbox}
              inputClassName={cs(
                years?.includes(data.year) && classes.activeYear
              )}
              checked={data.checked}
              onChange={() => handleChange(data.year)}
              disabled={data.disabled}
            />
          ))}
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button className={classes.cancelBtn} onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="primary"
          className={classes.saveBtn}
          onClick={handleSave}
          loading={loading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditStudyModal;
