import React, { useEffect, useState } from 'react';
import { get, isEqual } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { Prompt } from 'react-router';
import * as Yup from 'yup';
import { Card, CardBody, CardHeader, Container, Col, Row } from 'reactstrap';
import CardsHeader from 'components/Headers/CardsHeader.js';
import Loading from 'components/Loading';
import {
  fetchDetailsByUserId,
  updateDetailsByUserId,
} from 'store/actions/profile';
import { showProfileUser } from 'store/actions/authActions';
import ProfileForm from './Profile.Form';
import ProfileDetails from './ProfileDetails';
import history from 'helpers/history';
import { fetchTimezones } from 'store/actions/metadata';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
} from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';

const EditProfile = props => {
  const userId = get(props, 'match.params.id');
  const dispatch = useDispatch();
  const [isDirtyConfirmed, setIsDirtyConfirmed] = useState(false);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_profile,
      ...rest,
    });
  };
  // using JSON.parse(JSON.stringify()) to copy the object as it is more efficient and doesn't leave any references
  const details = useSelector(({ profile }) =>
    JSON.parse(JSON.stringify(get(profile, 'details.data', {})))
  );
  const isLoading = useSelector(({ profile }) =>
    get(profile, 'details.isInProgress', false)
  );

  const auth = useSelector(({ auth }) => auth);

  const currentUserId = get(auth, 'user.id', '');

  const isUserAllowedEdit = useAccess([
    permissions.EDIT_ALL_USER_PROFILES,
    {
      permission: permissions.EDIT_MY_PROFILE,
      value: isEqual(parseInt(currentUserId), parseInt(userId)),
    },
  ]);

  if (!isUserAllowedEdit) {
    showUnAuhtorizedError();
  }

  useEffect(() => {
    dispatch(fetchTimezones());
    dispatch(fetchDetailsByUserId(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, isUserAllowedEdit]);

  let initialValues = {
    name: get(details, 'name', ''),
    first_name: get(details, 'first_name', ''),
    last_name: get(details, 'last_name', ''),
    title: get(details, 'title', '') || '',
    weekly_availability: get(details, 'weekly_availability', '') || '',
    bio: get(details, 'bio', '') || '',
    timezone: get(details, 'timezone', '') || '',
    timezone_offset: get(details, 'timezone_offset', '') || '',
    skills: get(details, 'skills', '') || [],
    city: get(details, 'city', '') || '',
    country: get(details, 'country', '') || '',
    email: get(details, 'email', ''),
    phone: get(details, 'phone', '') || '',
    skype_id: get(details, 'skype_id', '') || '',
    github_id: get(details, 'github_id', '') || '',
    calendly_link: get(details, 'calendly_link', '') || '',
  };
  const ProfileSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Please enter a valid email'),
    bio: Yup.string().max(500, 'Bio should be less than 500 characters'),
    skills: Yup.array().max(10, 'Skill should be less than 10'),
    country: Yup.string().matches(
      '^[a-zA-Z ]+$',
      'Country should contain only alphabets'
    ),
    city: Yup.string().matches(
      '^[a-zA-Z ]+$',
      'City should contain only alphabets'
    ),
  });

  const submitValues = async values => {
    delete values.timezone_offset;
    analyticsSendEvent({ action: analyticsConstants.action.edit_user_details });
    await dispatch(updateDetailsByUserId(userId, values));
    dispatch(showProfileUser());
  };

  return (
    <>
      <div className="header pb-6 d-flex align-items-center">
        <CardsHeader
          currentPage="Edit Profile"
          parentName="People"
          childLink={`/admin/users/${userId}`}
          childName="User Profile"
          name="Edit Profile"
        />
      </div>
      {isLoading ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      ) : (
        <Container className="mt--6" fluid>
          <Formik
            initialValues={initialValues}
            validationSchema={ProfileSchema}
            enableReinitialize
            validateOnMount
            onSubmit={async values => {
              await submitValues(values);
              history.push({
                pathname: `/admin/users/${userId}`,
              });
            }}
          >
            {({ handleSubmit, values, ...rest }) => (
              <Form role="form" onSubmit={handleSubmit}>
                <Row>
                  <Col className="order-xl-1" xl="4">
                    <ProfileDetails
                      mode="edit"
                      userId={userId}
                      values={values}
                      loggedInUser={currentUserId}
                      analyticsSendEvent={analyticsSendEvent}
                    />
                  </Col>
                  <Col className="order-xl-2" xl="8">
                    <Card>
                      <CardHeader>
                        <Row className="align-items-center">
                          <Col xs="8">
                            <h3 className="mb-0">User Information</h3>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <ProfileForm
                          submitValues={submitValues}
                          formik={{ values, ...rest }}
                          loggedInUser={currentUserId}
                          userId={currentUserId}
                          onDirtyConfirm={() => setIsDirtyConfirmed(true)}
                        />
                        <Prompt
                          when={rest.dirty && !isDirtyConfirmed}
                          message="If you leave all unsaved changes will be lost."
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </>
  );
};

export default EditProfile;
