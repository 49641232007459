import {
  getClientCreationSteps,
  getProjectsTeamInviteSteps,
  getProjectCreationSteps,
  getStoryCreationSteps,
  getTimeTrackingSteps,
  getUserInvitationSteps,
  getViewProjectsSteps,
  getPersonalKanbanSteps,
  getOnboardingWalkThroughSteps,
} from './steps';

export const getSteps = (tourKey, extra) => {
  switch (tourKey) {
    case 'time_logging': {
      return getTimeTrackingSteps();
    }
    case 'client_creation': {
      return getClientCreationSteps();
    }
    case 'project_creation': {
      return getProjectCreationSteps(extra.role);
    }
    case 'user_invitation': {
      const docBody = document.getElementsByTagName('body')[0];
      const isSidebarShow = docBody.classList.contains('g-sidenav-show');
      const isSidebarPinned = docBody.classList.contains('g-sidenav-pinned');
      return getUserInvitationSteps(isSidebarShow, isSidebarPinned);
    }
    case 'story_creation': {
      return getStoryCreationSteps(extra.assignedProjects);
    }
    case 'invite_team': {
      return getProjectsTeamInviteSteps(extra.assignedProjects);
    }
    case 'view_projects': {
      return getViewProjectsSteps();
    }
    case 'view_personal_kanban': {
      return getPersonalKanbanSteps();
    }
    case 'general_ledger_payroll': {
      return getOnboardingWalkThroughSteps();
    }
    case 'tax_returns': {
      return getOnboardingWalkThroughSteps();
    }
    default: {
      return [];
    }
  }
};

const userInvitationTour = {
  tourKey: 'user_invitation',
  title: 'Invite Your Team',
  content:
    'Invite the team you want to build and collaborate with. Bringing your team to Lyght is the first step to fluid collaboration.',
  iconClass: 'fas fa-users text-purple',
};

const createProjectTour = {
  tourKey: 'project_creation',
  title: 'Create a Project',
  content:
    'Track, organize, and assign work related to your projects. Innovate and create the future you envision for your projects.',
  iconClass: 'fas fa-cubes text-success',
};

const createClientTour = {
  tourKey: 'client_creation',
  title: 'Create a Client',
  content:
    'Clients unify projects, time and teams together. Create transparency and trust through organization with your client.',
  iconClass: 'fas fa-handshake text-primary',
};

const timeLogTour = {
  tourKey: 'time_logging',
  title: 'Make a Time Entry',
  content:
    'Track your time on projects, stories, and assignments. Understanding the past helps you to better plan for the future.',
  iconClass: 'fas fa-clock text-yellow',
};

const createStoryTour = {
  tourKey: 'story_creation',
  title: 'Create a Story',
  content:
    'Stories are the ideas and initiatives that can be assigned to your team. Every story is a step towards a successful project.',
  iconClass: 'fas fa-book-open text-primary',
};

const inviteTeamTour = {
  tourKey: 'invite_team',
  title: 'Invite Your Team',
  content: `Gather your team to collaborate and build your project. Your team is the foundation to your project's success`,
  iconClass: 'fas fa-users text-purple',
};

const viewProjectsTour = {
  tourKey: 'view_projects',
  title: 'View Projects',
  content:
    'Stay up to date on the work that you have been assigned and view all projects that you have been invited to collaborate on!',
  iconClass: 'fas fa-cubes text-success',
};

const viewPersonalKanbanTour = {
  tourKey: 'view_personal_kanban',
  title: 'View Personal Kanban',
  content:
    'Organize your stories in real time! Here, you can view each team member’s progress and status for every collaborative story!',
  iconClass: 'ni ni-chart-bar-32 text-primary',
};

export const getAvailableTours = role => {
  switch (role) {
    case 'admin': {
      return [
        userInvitationTour,
        createProjectTour,
        createClientTour,
        timeLogTour,
      ];
    }
    case 'manager': {
      return [createProjectTour, createStoryTour, inviteTeamTour, timeLogTour];
    }
    case 'team_member': {
      return [viewProjectsTour, viewPersonalKanbanTour, timeLogTour];
    }
    default:
      return [];
  }
};

export const getOnboardingWalkThroughCurrentSteps = () => {
  const financialYearsNavBar = document.getElementById('financialYears');
  const documents = document.getElementById('document');
  const uploadFileButton = document.getElementById('uploadFileButton');
  if (financialYearsNavBar) {
    return 0;
  } else if (financialYearsNavBar && documents) {
    return 1;
  } else if (financialYearsNavBar && documents && uploadFileButton) {
    return 3;
  } else {
    return 0;
  }
};

export const getTimeEntryCurrentStep = runningTime => {
  const timerModal = document.getElementById('timeEntryModal');
  if (timerModal && runningTime) {
    return 2;
  } else if (runningTime) {
    return 1;
  }
  return 0;
};

export const getCreateProjectCurrentStep = () => {
  const projectModal = document.getElementById('createProjectModal');
  const projectNameInput = document.getElementById('projectNameInput');
  const createProjectBtn = document.getElementById('addProjectBtn');
  const projectNav = document.getElementById('projectNavigationButtons');

  if (projectNav) {
    return 4;
  } else if (projectModal && projectNameInput && projectNameInput.value) {
    return 3;
  } else if (projectModal && projectNameInput && !projectNameInput.value) {
    return 2;
  } else if (createProjectBtn) {
    return 1;
  } else {
    return 0;
  }
};

export const getCreateClientCurrentStep = () => {
  const clientModal = document.getElementById('createClientModal');
  const clientNameInput = document.getElementById('clientNameInput');
  const addClientBtn = document.getElementById('addClientBtn');

  if (clientModal && clientNameInput && clientNameInput.value) {
    return 3;
  } else if (clientModal && clientNameInput && !clientNameInput.value) {
    return 2;
  } else if (addClientBtn) {
    return 1;
  } else {
    return 0;
  }
};

export const getInviteUserCurrentStep = () => {
  const docBody = document.getElementsByTagName('body')[0];
  const isSidebarShow = docBody.classList.contains('g-sidenav-show');
  const isSidebarPinned = docBody.classList.contains('g-sidenav-pinned');
  const inviteUserModal = document.getElementById('inviteUserModal');
  const usersEmailInput = document.getElementById('usersEmailInput');
  const inviteUserBtn = document.getElementById('inviteUserBtn');
  const sidebarItemAdmin = document.getElementById('sidebarItem-Admin');
  const sidebarItemUsers = document.getElementById('sidebarItem-Users');

  if (inviteUserModal && usersEmailInput && usersEmailInput.value) {
    return 4;
  } else if (inviteUserModal && usersEmailInput && !usersEmailInput.value) {
    return 3;
  } else if (inviteUserBtn) {
    return 2;
  } else if (
    sidebarItemUsers &&
    sidebarItemAdmin &&
    sidebarItemAdmin.getAttribute('aria-expanded') === 'true' &&
    (isSidebarShow || isSidebarPinned)
  ) {
    return 1;
  } else {
    return 0;
  }
};

export const getCreateStoryCurrentStep = assignedProjects => {
  const projectModal = document.getElementById('createProjectModal');
  const projectNameInput = document.getElementById('projectNameInput');
  const createProjectBtn = document.getElementById('addProjectBtn');
  const backlogTab = document.getElementById('backlogTab');
  const projectsTable = document.getElementById('projectsTable');
  const sectionStoryInput = document.getElementById('sectionStoryInput');
  const storyModalStoryName = document.getElementById('storyModalStoryName');

  if (storyModalStoryName) {
    return 8;
  } else if (sectionStoryInput) {
    return 6;
  } else if (backlogTab) {
    return 5;
  } else if (projectModal && projectNameInput && projectNameInput.value) {
    return 4;
  } else if (projectModal && projectNameInput && !projectNameInput.value) {
    return 3;
  } else if (createProjectBtn && !assignedProjects.length) {
    return 2;
  } else if (projectsTable && assignedProjects.length) {
    return 1;
  } else {
    return 0;
  }
};

export const getInviteTeamCurrentStep = assignedProjects => {
  const projectModal = document.getElementById('createProjectModal');
  const projectNameInput = document.getElementById('projectNameInput');
  const createProjectBtn = document.getElementById('addProjectBtn');
  const teamsTab = document.getElementById('teamsTab');
  const projectsTable = document.getElementById('projectsTable');
  const addTeamMemberBtn = document.getElementById('addTeamMemberBtn');
  const inviteToTeamSelect = document.getElementById('inviteToTeamSelect');

  if (inviteToTeamSelect) {
    return 7;
  } else if (addTeamMemberBtn) {
    return 6;
  } else if (teamsTab) {
    return 5;
  } else if (projectModal && projectNameInput && projectNameInput.value) {
    return 4;
  } else if (projectModal && projectNameInput && !projectNameInput.value) {
    return 3;
  } else if (createProjectBtn && !assignedProjects.length) {
    return 2;
  } else if (projectsTable && assignedProjects.length) {
    return 1;
  } else {
    return 0;
  }
};

export const getViewProjectsCurrentStep = () => {
  const projectsList = document.getElementById('projectsList');
  if (projectsList) {
    return 1;
  } else {
    return 0;
  }
};

export const getViewPersonalKanbanCurrentStep = () => {
  const myKanban = document.getElementById('myKanban');
  if (myKanban) {
    return 1;
  } else {
    return 0;
  }
};

export const getCurrentStepForTour = (tourKey, extra) => {
  const { assignedProjects, runningTime } = extra;
  switch (tourKey) {
    case 'time_logging':
      return getTimeEntryCurrentStep(runningTime);
    case 'project_creation':
      return getCreateProjectCurrentStep();
    case 'client_creation':
      return getCreateClientCurrentStep();
    case 'user_invitation':
      return getInviteUserCurrentStep();
    case 'story_creation':
      return getCreateStoryCurrentStep(assignedProjects);
    case 'invite_team':
      return getInviteTeamCurrentStep(assignedProjects);
    case 'view_projects':
      return getViewProjectsCurrentStep();
    case 'view_personal_kanban':
      return getViewPersonalKanbanCurrentStep();
    case 'general_ledger_payroll':
      return getOnboardingWalkThroughCurrentSteps();
    case 'tax_returns':
      return getOnboardingWalkThroughCurrentSteps();
    default:
      return 0;
  }
};
