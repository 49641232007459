import React from 'react';

const AuthCardHeader = ({ head, title }) => {
  return (
    <div className="text-center text-dark">
      {head && <h1 className="h1">{head}</h1>}
      {title && <p className="font-weight-400 mb-5">{title}</p>}
    </div>
  );
};

export default AuthCardHeader;
