import React from 'react';
import classes from './Interactions.module.scss';
import { ReactComponent as MessageIcon } from 'assets/img/icons/study-message.svg';

const NotificationNotAvailable = ({ showFilter }) => {
  return (
    <div className={classes.noMessages}>
      <div className={classes.content}>
        <MessageIcon width={88} height={88} />
        <p>There are currently no interactions to show.</p>
      </div>
    </div>
  );
};

export default NotificationNotAvailable;
