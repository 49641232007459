import get from 'lodash/get';
import CompanyTypes from 'store/types/company';
import Types from '../types/onboarding';
import {
  getDocumentTypes,
  getInviteRoles,
  getFinchDetails,
  getCodatConnectUrl,
  getCodatDetails,
  getDisconnectFinch,
  getDisconnectCodat,
} from 'api/onboarding';
import { fetchList, deactivate, sendInvitations, reInvite } from 'api/users';
import {
  updateCompanyDetails as updateCompanyDetailsApi,
  updateCompanySettings,
} from 'api/company';
import { NotificationHandler } from 'components/Notifications';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';

export const fetchFinchDetails = code => {
  return async dispatch => {
    dispatch({
      type: Types.FINCH_SETUP_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getFinchDetails({
        code,
        redirectUri: 'https://tryfinch.com',
      });
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FINCH_SETUP_SUCCESS,
          data: get(resp, 'data', []),
        });
        dispatch({
          type: CompanyTypes.FETCH_COMPANY_SUCCESS,
          data: get(resp, 'data', []),
        });
      } else {
        NotificationHandler.open({
          operation: 'failure',
          message: 'Something went wrong, please try again later',
        });
        dispatch({
          type: Types.FINCH_SETUP_FAILURE,
          message: 'Something went wrong',
        });
      }
      return status;
    } catch (error) {
      NotificationHandler.open({
        operation: 'failure',
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.FINCH_SETUP_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};
export const fetchDocumentTypes = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_ONBOARING_DOCUMENT_TYPES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDocumentTypes();
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_ONBOARING_DOCUMENT_TYPES_SUCCESS,
          data: get(resp, 'data', []),
        });
      } else {
        dispatch({
          type: Types.FETCH_ONBOARING_DOCUMENT_TYPES_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ONBOARING_DOCUMENT_TYPES_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchInviteRoles = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_ONBOARING_INVITE_ROLES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getInviteRoles();
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_ONBOARING_INVITE_ROLES_SUCCESS,
          data: get(resp, 'data', []),
        });
      } else {
        dispatch({
          type: Types.FETCH_ONBOARING_INVITE_ROLES_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ONBOARING_INVITE_ROLES_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchMembers = (page, sort) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_ONBOARING_TEAM_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchList(page, sort, undefined, undefined);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_ONBOARING_TEAM_MEMBERS_SUCCESS,
          data: resp,
        });
      } else {
        dispatch({
          type: Types.FETCH_ONBOARING_TEAM_MEMBERS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ONBOARING_TEAM_MEMBERS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const deleteUser = userId => {
  return async dispatch => {
    dispatch({
      type: Types.ONBOARDING_DELETE_TEAM_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deactivate(userId);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.ONBOARDING_DELETE_TEAM_MEMBERS_SUCCESS,
          data: resp,
        });
      } else {
        dispatch({
          type: Types.ONBOARDING_DELETE_TEAM_MEMBERS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ONBOARDING_DELETE_TEAM_MEMBERS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const reInviteUser = (userId, userData) => {
  return async dispatch => {
    dispatch({
      type: Types.ONBOARDING_REINVITE_TEAM_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await reInvite(userId, userData);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.ONBOARDING_REINVITE_TEAM_MEMBERS_SUCCESS,
          data: resp,
        });
      } else {
        dispatch({
          type: Types.ONBOARDING_REINVITE_TEAM_MEMBERS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ONBOARDING_REINVITE_TEAM_MEMBERS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const inviteUsersInOnboarding = ({
  firstName,
  lastName,
  role,
  note,
  email,
}) => {
  return async dispatch => {
    dispatch({
      type: Types.ONBOARDING_INVITE_TEAM_MEMBER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const payload = {
        email_addresses: [email],
        first_name: firstName,
        last_name: lastName,
        roles: [role.name],
        personal_note: note,
      };
      const resp = await sendInvitations(payload);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.ONBOARDING_INVITE_TEAM_MEMBER_SUCCESS,
          data: resp,
        });
      } else {
        dispatch({
          type: Types.ONBOARDING_INVITE_TEAM_MEMBER_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ONBOARDING_INVITE_TEAM_MEMBER_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const completeOnboarding = companyId => {
  return async dispatch => {
    dispatch({
      type: Types.ONBOARDING_SET_COMPLETE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateCompanySettings(companyId, {
        key: 'onboarding_profile_setup',
        value: 'completed',
      });
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.ONBOARDING_SET_COMPLETE_SUCCESS,
          data: resp,
        });
      } else {
        dispatch({
          type: Types.ONBOARDING_SET_COMPLETE_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ONBOARDING_SET_COMPLETE_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const addClientOnboardingSetup = (companyId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.CLIENT_SETUP_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateCompanyDetailsApi(companyId, data);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.CLIENT_SETUP_SUCCESS,
          data: get(resp, 'data', {}),
        });
      } else {
        dispatch({
          type: Types.CLIENT_SETUP_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CLIENT_SETUP_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchCodatConnectURL = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CODAT_CONNECT_URL_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getCodatConnectUrl();
      const status = get(resp, 'status');
      const url = get(resp, 'url');
      if (status) {
        dispatch({
          type: Types.FETCH_CODAT_CONNECT_URL_SUCCESS,
          data: url,
        });
      } else {
        dispatch({
          type: Types.FETCH_CODAT_CONNECT_URL_FAILURE,
          message: 'Something went wrong',
        });
      }
      return url;
    } catch (error) {
      NotificationHandler.open({
        operation: 'failure',
        message: 'Something went wrong, please try again later',
      });
      dispatch({
        type: Types.FETCH_CODAT_CONNECT_URL_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchCodatDetails = companyId => {
  return async dispatch => {
    dispatch({
      type: Types.CODAT_SETUP_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getCodatDetails({
        companyId,
      });
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.CODAT_SETUP_SUCCESS,
          data: get(resp, 'data', {}),
        });
        dispatch({
          type: CompanyTypes.FETCH_COMPANY_SUCCESS,
          data: get(resp, 'data', {}),
        });
        AlertPopupHandler.openCustom({
          type: 'success',
          warning: false,
          success: true,
          showCancel: false,
          onConfirm: () => {},
          confirmBtnBsStyle: 'success',
          text: `You have successfully connected with your accounting software!`,
          title: 'Connection Successful!',
          confirmBtnText: 'OK, got it!',
        });
      } else {
        dispatch({
          type: Types.CODAT_SETUP_FAILURE,
          message: 'Something went wrong',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.CODAT_SETUP_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const disconnectFinch = () => {
  return async dispatch => {
    dispatch({
      type: Types.FINCH_DISCONNECT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDisconnectFinch();
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FINCH_DISCONNECT_SUCCESS,
        });
      } else {
        dispatch({
          type: Types.FINCH_DISCONNECT_FAILURE,
          message: 'Something went wrong',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.FINCH_DISCONNECT_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const disconnectCodat = () => {
  return async dispatch => {
    dispatch({
      type: Types.CODAT_DISCONNECT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDisconnectCodat();
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.CODAT_DISCONNECT_SUCCESS,
        });
      } else {
        dispatch({
          type: Types.CODAT_DISCONNECT_FAILURE,
          message: 'Something went wrong',
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.CODAT_DISCONNECT_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};
