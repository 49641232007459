import Types from '../types/contracts';

const initialState = {
  contractState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  getContract: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  createContract: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editContract: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteContract: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  contractAgreed: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  contractDeclined: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  statusUpdate: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Contract(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_CONTRACTS_INPROGRESS:
      return {
        ...state,
        contractState: {
          ...state.contractState,
          isInProgress: true,
        },
      };
    case Types.FETCH_CONTRACTS_SUCCESS:
      return {
        ...state,
        contractState: {
          ...state.contractState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_CONTRACTS_FAILURE:
      return {
        ...state,
        contractState: {
          ...state.contractState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_SINGLE_CONTRACT_INPROGRESS:
      return {
        ...state,
        getContract: {
          ...state.getContract,
          isInProgress: true,
        },
      };
    case Types.FETCH_SINGLE_CONTRACT_SUCCESS:
      return {
        ...state,
        getContract: {
          ...state.getContract,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_SINGLE_CONTRACT_FAILURE:
      return {
        ...state,
        getContract: {
          ...state.getContract,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_CONTRACT_AGREED_INPROGRESS:
      return {
        ...state,
        contractAgreed: {
          ...state.contractAgreed,
          isInProgress: true,
        },
      };
    case Types.POST_CONTRACT_AGREED_SUCCESS:
      return {
        ...state,
        contractAgreed: {
          ...state.contractAgreed,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.POST_CONTRACT_AGREED_FAILURE:
      return {
        ...state,
        contractAgreed: {
          ...state.contractAgreed,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.POST_CONTRACT_DECLINED_INPROGRESS:
      return {
        ...state,
        contractDeclined: {
          ...state.contractDeclined,
          isInProgress: true,
        },
      };
    case Types.POST_CONTRACT_DECLINED_SUCCESS:
      return {
        ...state,
        contractDeclined: {
          ...state.contractDeclined,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.POST_CONTRACT_DECLINED_FAILURE:
      return {
        ...state,
        contractDeclined: {
          ...state.contractDeclined,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CLEAR_CONTRACT_DATA:
      return {
        ...state,
        getContract: {
          ...state.getContract,
          data: action.data,
        },
      };
    case Types.CREATE_CONTRACT_INPROGRESS:
      return {
        ...state,
        createContract: {
          ...state.createContract,
          isInProgress: true,
        },
      };
    case Types.CREATE_CONTRACT_SUCCESS:
      return {
        ...state,
        createContract: {
          ...state.createContract,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_CONTRACT_FAILURE:
      return {
        ...state,
        createContract: {
          ...state.createContract,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.EDIT_CONTRACT_INPROGRESS:
      return {
        ...state,
        editContract: {
          ...state.editContract,
          isInProgress: true,
        },
      };
    case Types.EDIT_CONTRACT_SUCCESS:
      return {
        ...state,
        editContract: {
          ...state.editContract,
          isInProgress: false,
          status: 1,
        },
        contractState: {
          ...state.contractState,
          data: action.data,
        },
      };
    case Types.EDIT_CONTRACT_FAILURE:
      return {
        ...state,
        editContract: {
          ...state.editContract,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_CONTRACT_INPROGRESS:
      return {
        ...state,
        deleteContract: {
          ...state.deleteContract,
          isInProgress: true,
        },
      };
    case Types.DELETE_CONTRACT_SUCCESS:
      return {
        ...state,
        deleteContract: {
          ...state.deleteContract,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_CONTRACT_FAILURE:
      return {
        ...state,
        deleteContract: {
          ...state.deleteContract,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_CONTRACT_STATUS_INPROGRESS:
      return {
        ...state,
        statusUpdate: {
          ...state.statusUpdate,
          isInProgress: true,
        },
      };
    case Types.UPDATE_CONTRACT_STATUS_SUCCESS:
      return {
        ...state,
        statusUpdate: {
          ...state.statusUpdate,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_CONTRACT_STATUS_FAILURE:
      return {
        ...state,
        statusUpdate: {
          ...state.statusUpdate,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
