import React, { useState, useEffect } from 'react';
import Dropdowns from 'components/Dropdowns';
import cs from 'classnames';
const PeriodDropdown = ({ onChange }) => {
  const [period, setPeriod] = useState({
    id: 'last_month',
    text: 'Last 30 Days',
  });

  useEffect(() => {
    onChange(period);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  const periodOptions = [
    {
      id: 'last_week',
      text: 'Last 7 Days',
      onClick: async () => {
        setPeriod({
          id: 'last_week',
          text: 'Last 7 Days',
        });
      },
    },
    {
      id: 'last_month',
      text: 'Last 30 Days',
      onClick: async () => {
        setPeriod({
          id: 'last_month',
          text: 'Last 30 Days',
        });
      },
    },
    {
      id: 'last_six_months',
      text: 'Last Six Months',
      onClick: async () => {
        setPeriod({
          id: 'last_six_months',
          text: 'Last Six Months',
        });
      },
    },
    {
      id: 'last_year',
      text: 'This Year',
      onClick: async () => {
        setPeriod({
          id: 'last_year',
          text: 'This Year',
        });
      },
    },
    {
      id: '',
      text: 'All Assignments',
      onClick: async () => {
        setPeriod({
          id: '',
          text: 'All Assignments',
        });
      },
    },
  ];
  return (
    <div>
      <Dropdowns
        dropdownClasses={cs('ml-auto')}
        className="m-0 p-1 ml-1"
        text={
          <>
            {period.text}
            <i className="fas fa-angle-down ml-2"></i>
          </>
        }
        size="sm"
        caret={false}
        role="button"
        color=""
        options={periodOptions}
      />
    </div>
  );
};

export default PeriodDropdown;
