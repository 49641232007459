import { get } from 'lodash';

import Types from '../types/app';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { submitSupportRequest as submit } from 'api/app';

export const showSupportForm = isOpen => {
  return {
    type: Types.SHOW_SUPPORT_REQUEST_FORM,
    payload: isOpen,
  };
};

export const submitSupportRequest = data => {
  return async dispatch => {
    try {
      const resp = await submit(data);

      const status = get(resp, 'status');
      const message = get(resp, 'message');

      if (status) {
        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        NotificationHandler.open({
          message,
          operation: 'failure',
        });
      }
      return { status };
    } catch (error) {
      NotificationHandler.open({
        operation: 'failure',
      });
    }
  };
};
