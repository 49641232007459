import classes from '../QuickStartTour.module.scss';

export const getPersonalKanbanSteps = () => [
  {
    target: '#viewKanban',
    title: 'View your Kanban',
    content: 'Click here to open your personal Kanban board.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'bottom',
    extraPlacementClass: classes.viewKanban,
  },
  {
    target: '#myKanban',
    title: 'View Your Kanban',
    content:
      'Your kanban will allow you to organized the stories you are assigned to and what their status is.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    shouldShowDismiss: true,
    disableOverlay: true,
    spotlightPadding: 0,
    placement: 'top',
    extraPlacementClass: classes.projectsTable,
  },
];
