import Button from 'components/Button';
import history from 'helpers/history';
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import Messages from '../dashboards/Fields/Messages';
import classes from './ClientMessages.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardMessages } from 'store/actions/userDashboard';
import { get } from 'lodash';

const ClientMessages = () => {
  const dispatch = useDispatch();

  const messages = useSelector(({ userDashboard }) =>
    get(userDashboard, 'messages.data', [])
  );
  const isMessagesLoading = useSelector(({ userDashboard }) =>
    get(userDashboard, 'messages.isInProgress', false)
  );
  const shouldMakeCall = useSelector(({ userDashboard }) =>
    get(userDashboard, 'messages.shouldMakeCall', true)
  );

  useEffect(() => {
    dispatch(getDashboardMessages('list'));
  }, [dispatch]);

  return (
    <Container fluid className={classes.container}>
      <Button
        color="link"
        className={classes.backToDashboard}
        // onClick={prev}
        leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
        onClick={() => {
          history.push('/admin/dashboard');
        }}
      >
        Back to Dashboard
      </Button>
      <Messages
        shouldMakeCall={shouldMakeCall}
        notifications={messages}
        showFilter
        isMessagesLoading={isMessagesLoading}
      />
    </Container>
  );
};

export default ClientMessages;
