import React, { useRef, useState } from 'react';
import classes from './DocumentUpload.module.scss';
import Button from 'components/Button';
import cx from 'classnames';
import { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import { useDropzone } from 'react-dropzone';
import millify from 'millify';

const DocumentUpload = ({
  prev,
  next,
  files,
  onDrop,
  removeFile,
  userType,
}) => {
  const uploadFile = useRef();
  const [uploadLoading, setUploadLoading] = useState(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleInputRef = event => {
    event.stopPropagation();
    uploadFile.current.click();
  };

  return (
    <div className={classes.uploadSection}>
      <ModalBody
        className={cx('d-flex flex-row flex-wrap', classes.modalBody)}
        id="storyModal-body"
      >
        <div className={classes.description}>
          <p>
            Add your documents by dropping them in the area below or selecting
            Browse Files.
          </p>
        </div>
        <div
          className={cx(classes.fileInputWrapper, {
            [classes.hoverDrop]: isDragActive,
          })}
          {...getRootProps()}
        >
          <input {...getInputProps()} ref={uploadFile} />
          {isDragActive ? (
            <div className={classes.onDropText}>
              <p>Drop them Here</p>
              <div className={classes.dropIcon}>
                <i className="fa fa-arrow-down" aria-hidden="true"></i>
              </div>
            </div>
          ) : (
            <div className={classes.uploadActions}>
              <div className={classes.text}>
                <p>
                  Drop file here to upload
                  <br />
                  <br /> or
                </p>
              </div>
              <Button
                color="secondary"
                onClick={handleInputRef}
                className={classes.uploadButton}
              >
                Browse Files
              </Button>
            </div>
          )}
        </div>
        <div className={classes.fileList}>
          {files.map(({ name, size }, i) => {
            return (
              <div key={i} className={classes.file}>
                <div className={classes.fileDetails}>
                  <img src={require('assets/img/extensions/Icn-file.svg')} />
                  <div className={classes.title}>
                    <h3>{name}</h3>
                    <p>
                      {millify(size, {
                        precision: 0,
                        units: ['B', 'KB', 'MB', 'GB', 'TB'],
                        space: true,
                      })}
                    </p>
                  </div>
                </div>
                <div
                  onClick={() => removeFile(i)}
                  className={classes.removeFile}
                >
                  <i className="fas fa-trash" aria-hidden="true"></i>
                </div>
              </div>
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button
          className={classes.back}
          onClick={prev}
          leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
        >
          Back
        </Button>
        <Button
          loading={uploadLoading}
          disabled={uploadLoading || files.length === 0}
          color="primary"
          // className={('btn-outline-default', classes.next)}
          onClick={async () => {
            setUploadLoading(true);
            await next();
            setUploadLoading(false);
          }}
        >
          {userType === 'admin' ? 'Submit' : 'Next'}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default DocumentUpload;
