import React from 'react';

import classes from './DealDesk.module.scss';
import DealDeskCounter from './DealDeskCounter';
import { isLightHexColor } from 'helpers/color';
import { Col, Row } from 'reactstrap';

const DealDeskItem = ({ item, type, openModal }) => {
  return (
    <>
      <div className={classes.deals} onClick={() => openModal(item.id)}>
        <Row className={classes.dealDeskItem}>
          <Col lg="2" md="6">
            <p>Entitiy Name</p>
            <h3>{item.client}</h3>
          </Col>
          <Col lg="2" md="6">
            <p>Signatory Name</p>
            <p>{item.signatory_name}</p>
          </Col>
          <Col lg="3" md="6" className={classes.SignatoryEmail}>
            <p>Signatory Email</p>
            <p>{item.signatory_email}</p>
          </Col>
          <Col lg="1" md="6">
            <p>Type</p>
            <p>{item.type}</p>
          </Col>
          <Col lg="1" md="6">
            <p>Submitted by</p>
            <p>{item.submitted_by}</p>
          </Col>
          <Col lg="1" md="6">
            {type === 'deals' ? (
              <>
                <p>Deal Size </p>
                <p>{item.deal_size}</p>
              </>
            ) : (
              <>
                <p>Referral % </p>
                <p>{item.referral_fee}</p>
              </>
            )}
          </Col>
          <Col lg="1" md="6">
            <p>Days Pending</p>
            <p>{item.days_pending}</p>
          </Col>
          <Col lg="1" md="6">
            <div className={classes.status}>
              <p
                className={classes.review}
                style={{
                  background: item?.status?.color,
                  color: isLightHexColor(item?.status?.color)
                    ? '#000000'
                    : '#FFFFFF',
                }}
              >
                {item?.status?.status}
              </p>

              {item?.status?.status === 'Needs Review' && (
                <DealDeskCounter item={item} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DealDeskItem;
