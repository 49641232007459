import React from 'react';
import { getOptions } from 'helpers/clientProfile';
import { yesOrNoOptions } from 'helpers/dropdownHelpers';
import RSelect from 'components/FormFields/RSelect';

const YesNoDropdown = ({ name, label, values, onChange, wrapperClass }) => {
  return (
    <div className={wrapperClass}>
      <RSelect
        name={name}
        label={label}
        options={getOptions(yesOrNoOptions)}
        value={values[name]}
        getOptionLabel={opt => opt.label}
        getOptionValue={opt => opt.value}
        onChange={onChange(name)}
      />
    </div>
  );
};

export default YesNoDropdown;
