import React from 'react';
import { FormGroup, Form, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Formik } from 'formik';
import numeral from 'numeral';
import Input from 'components/FormFields/Input';
import DatePicker from 'components/FormFields/DatePicker';
import classNames from 'classnames';
import classes from './projects.module.scss';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import * as moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import FormModal from 'components/FormFields/FormModal';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import { updateActiveTourStatus } from 'store/actions/profile';

const ProjectForm = ({
  closeModal,
  viewMode,
  submitValues,
  isModalOpen,
  isFromSow = false,
  quickStartTour = {},
  sowInitialValues = {},
  loading,
}) => {
  const dispatch = useDispatch();
  const getProject = useSelector(
    ({ project }) => get(project, 'getProject', {}),
    isEqual
  );
  const editLoading = get(getProject, 'isInProgress', false);
  const { data: editData } = get(getProject, 'data', {});

  const getInitialValue = field => {
    if (viewMode !== 'edit') return '';
    const value = get(editData, field);
    return isNumber(value) || !isEmpty(value) ? value : '';
  };

  let initialValues = {
    projectId: getInitialValue('id'),
    projectName: getInitialValue('name'),
    projectClient: getInitialValue('client'),
    projectContract: getInitialValue('contract'),
    projectDescription: getInitialValue('description'),
    startDate: getInitialValue('date_start').replace(/-/g, '/'),
    endDate: getInitialValue('date_end').replace(/-/g, '/'),
    monthlyBudget: getInitialValue('total_budget'),
    monthlyBudgetDisplay: getInitialValue('total_budget')
      ? numeral(getInitialValue('total_budget')).format('0,0.00')
      : '',
    monthlyHours: getInitialValue('budget_hours'),
  };

  // When coming from creating an SOW Agreement we will use this to initialize the values
  if (isFromSow) {
    initialValues = {
      ...initialValues,
      projectClient: sowInitialValues.client,
      projectContract: sowInitialValues.contract,
    };
  }
  const validationSchema = Yup.object().shape({
    projectName: Yup.string().required('Required'),
  });

  const handleCloseModal = () => {
    closeModal();
    if (
      (quickStartTour &&
        quickStartTour.activeTour &&
        quickStartTour.activeTour === 'project_creation' &&
        (quickStartTour.step === 2 || quickStartTour.step === 3)) ||
      ((quickStartTour.activeTour === 'invite_team' ||
        quickStartTour.activeTour === 'story_creation') &&
        (quickStartTour.step === 3 || quickStartTour.step === 4))
    ) {
      dispatch(
        updateActiveTourStatus({
          step:
            quickStartTour.activeTour === 'invite_team' ||
            quickStartTour.activeTour === 'story_creation'
              ? 2
              : 1,
        })
      );
    }
  };

  const RenderForm = ({
    handleChange,
    setFieldValue,
    isValid,
    handleReset,
    handleSubmit,
    values,
    setValues,
  }) => {
    return (
      <FormModal
        toggle={handleCloseModal}
        isOpen={isModalOpen}
        heading={viewMode === 'edit' ? 'Edit Project' : 'Create a Study'}
        id="createProjectModal"
        submit={{
          id: 'createProjectBtn',
          onClick: handleSubmit,
          isValid: isValid,
          buttonText: viewMode === 'edit' ? 'Save Changes' : 'Create Study',
          loading: loading,
        }}
        cancel={{
          onClick: () => {
            handleReset();
            handleCloseModal();
          },
          buttonText: 'Cancel',
        }}
      >
        <Form className={classNames('mb-0', classes['add-project-form'])}>
          <FormGroup>
            <Input
              inputId="projectNameInput"
              value={values.projectName}
              placeholder="Study Name*"
              required
              name="projectName"
              onChange={handleChange}
              onBlur={() => {
                if (isValid) {
                  if (
                    (quickStartTour &&
                      quickStartTour.activeTour &&
                      quickStartTour.activeTour === 'project_creation' &&
                      quickStartTour.step === 2) ||
                    ((quickStartTour.activeTour === 'invite_team' ||
                      quickStartTour.activeTour === 'story_creation') &&
                      quickStartTour.step === 3)
                  ) {
                    dispatch(
                      updateActiveTourStatus({
                        step:
                          quickStartTour.activeTour === 'invite_team' ||
                          quickStartTour.activeTour === 'story_creation'
                            ? 4
                            : 3,
                      })
                    );
                  }
                }
              }}
            />
          </FormGroup>

          <Row form>
            <Col md={6}>
              <FormGroup>
                <RSelectAsync
                  isClearable={true}
                  value={values.projectClient}
                  // Disabled input when coming from creating sow agreement as we have client details
                  isDisabled={isFromSow}
                  getOptionLabel={option => option.name}
                  placeholder="Client"
                  url="/accounts/list/dropdown"
                  name="projectClient"
                  onChange={data => {
                    setFieldValue('projectClient', data);
                    setFieldValue('projectContract', null);
                  }}
                  id="clientList"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup id="select-sow">
                <RSelectAsync
                  value={values.projectContract}
                  getOptionLabel={option => option.name}
                  // Disabled input when coming from creating sow agreement as we have contract details
                  isDisabled={
                    (isEmpty(values.projectClient) &&
                      !isNumber(values.projectClient)) ||
                    isFromSow
                  }
                  placeholder="SOW"
                  url={`/accounts/${get(values, 'projectClient.id')}/themes`}
                  name="projectContract"
                  onChange={data => {
                    setFieldValue('projectContract', data);
                  }}
                />
                <UncontrolledTooltip
                  placement="bottom"
                  disabled={
                    !(
                      isEmpty(values.projectClient) &&
                      !isNumber(values.projectClient) &&
                      !isFromSow
                    )
                  }
                  delay={0}
                  target="select-sow"
                >
                  Please select a Client first
                </UncontrolledTooltip>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <DatePicker
                  placeholder="Start Date"
                  isValidDate={current =>
                    current.isAfter(moment().subtract(1, 'day')) &&
                    (values.endDate
                      ? current.isBefore(moment(values.endDate))
                      : true)
                  }
                  value={values.startDate}
                  name="startDate"
                  onChange={date => {
                    moment(date).isValid()
                      ? setFieldValue(
                          'startDate',
                          moment(date).format('YYYY/MM/DD')
                        )
                      : setFieldValue('startDate', '');
                    // handleChange
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <DatePicker
                  isValidDate={current => {
                    const date = values.startDate
                      ? moment(values.startDate, 'YYYY/MM/DD')
                      : moment();
                    return current.isAfter(date);
                  }}
                  placeholder="End Date"
                  value={values.endDate}
                  name="endDate"
                  onChange={date => {
                    moment(date).isValid()
                      ? setFieldValue(
                          'endDate',
                          moment(date).format('YYYY/MM/DD')
                        )
                      : setFieldValue('endDate', '');
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Input
              min={0}
              value={values.monthlyBudgetDisplay}
              leftIcon="$"
              rightIcon="per month"
              placeholder="Budget Amount"
              onChange={event => {
                const budgetNumber = event.target.value.replace(/,/g, '');
                if (!isNaN(budgetNumber) && toNumber(budgetNumber) >= 0) {
                  setValues({
                    ...values,
                    monthlyBudget: budgetNumber,
                    monthlyBudgetDisplay: budgetNumber,
                  });
                }
              }}
              onBlur={event => {
                if (toNumber(event.target.value) > 0)
                  setFieldValue(
                    'monthlyBudgetDisplay',
                    numeral(event.target.value).format('0,0.00')
                  );
              }}
              name="monthlyBudget"
            />
          </FormGroup>
          <FormGroup>
            <Input
              min={0}
              value={values.monthlyHours}
              className={classes['input-monthly-hours']}
              type="number"
              rightIcon="hours per month"
              placeholder="Total Budget Hours"
              onChange={handleChange}
              name="monthlyHours"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="textarea"
              value={values.projectDescription}
              className={classNames(classes['fixed-textarea'])}
              placeholder="Study Description"
              onChange={handleChange}
              name="projectDescription"
            />
          </FormGroup>
        </Form>
      </FormModal>
    );
  };
  if (editLoading) return null;
  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { resetForm, setSubmitting }) => {
        setSubmitting(false);
        submitValues(values);
        // closeModal();
      }}
    >
      {RenderForm}
    </Formik>
  );
};

export default ProjectForm;
