const colors = ['#F07766', '#FCA805', '#F2C94C', '#24A46D'];
const defaultPurpleColor = '#7889E8';

export const getInitials = name => {
  if (name) {
    const firstSpaceIndex = name?.indexOf(' ');
    const fInitial = name?.slice(0, 1);
    const lInitial = name?.substring(firstSpaceIndex + 1, firstSpaceIndex + 2);
    return `${fInitial}${lInitial}`;
  }
  return 'NA';
};
export const getDefaultPhotoThumbnail = (userType, name, size = 40) => {
  const initials = getInitials(name);
  const color =
    userType == 'client'
      ? colors[Math.floor(Math.random() * 4)]
      : defaultPurpleColor;

  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');

  ctx.beginPath();
  ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
  ctx.fillStyle = color;
  ctx.fill();

  ctx.font = '11px Arial';
  ctx.fillStyle = 'black';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(initials.toUpperCase(), size / 2, size / 2);

  return canvas.toDataURL();
};
