import Types from '../types/app';

const initialState = {
  isSupportFormOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.SHOW_SUPPORT_REQUEST_FORM:
      return {
        ...state,
        isSupportFormOpen: action.payload,
      };
    default:
      return state;
  }
};
