import classes from '../QuickStartTour.module.scss';

export const getProjectCreationSteps = role => [
  {
    target: '#sidebarItem-projects',
    title: 'Create a Project',
    content:
      role === 'manager'
        ? 'This is where you will go to view and create Projects. A project is where you will find your Stories, Kanban Board, and Epics'
        : 'Create Projects to guide your team’s vision. Manage stories from your Project Backlog and Kanban.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.sidebarIcon,
  },
  {
    target: '#addProjectBtn',
    title: 'Create a Project',
    content: 'Click here to create your Project!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    placement: 'left',
    extraPlacementClass: classes.addBtn,
  },
  {
    target: '#projectNameInput',
    title: 'Create a Project',
    content:
      'Create a project with as little as a name. If you have more details, you can add those now or later!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.addProjectInput,
  },
  {
    target: '#createProjectBtn',
    title: 'Create a Project',
    content: 'Click here to create your Project!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'right',
    extraPlacementClass: classes.createProjectBtn,
  },
  {
    target: '#projectNavigationButtons',
    title: 'Explore your project',
    content:
      role === 'manager'
        ? 'Use this navigation to view other areas of your project. Create epics, stories, assignments, and more!'
        : 'Click here to view other areas of your project. Create epics, stories, assignments, and more!',
    shouldShowDismiss: true,
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    placement: 'left',
    extraPlacementClass: classes.storyNavigationDropdown,
  },
];
