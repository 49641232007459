import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import classes from './SelectMultiple.module.scss';
import get from 'lodash/get';
import Input from 'components/FormFields/Input';
import useClickAway from 'react-use/lib/useClickAway';
import Request from 'api/request';
import { useDebounce } from 'react-use';
import { UncontrolledTooltip } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';

const SelectMultiple = ({
  name,
  value,
  placeholder,
  getOptionLabel,
  getOptionValue,
  url,
  onChange,
  disabled,
  tooltip,
  label,
  units,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const ref = useRef(null);
  const inputRef = useRef(null);

  useClickAway(ref, _e => {
    setOpen(false);
    setKeyword('');
    setOptions([]);
  });

  const displayValue = values => {
    if (values.length === 1) {
      const item = get(values, '0', null);
      return getOptionLabel(item);
    } else {
      return `${values.length} ${units}`;
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      setPage(1);
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useDebounce(
    () => {
      if (isOpen === true) {
        loadMoreOptions(keyword, page);
      }
    },
    500,
    [keyword, page, isOpen]
  );

  const loadMoreOptions = async (keyword, page = 1) => {
    setLoading(true);
    const resp = await Request.call({
      url: `${url}${keyword}`,
      params: { page },
    });
    if (resp.status === 1) {
      const rs = get(resp, 'data', []) || [];
      if (page === 1) setOptions(rs);
      else {
        const arr = [...options, ...rs];

        const isNext = get(resp, 'links.next');
        if (!isNext) setHasMore(false);
        else setHasMore(true);

        setOptions(arr);
      }
    } else {
      setOptions([]);
    }
    setLoading(false);
  };

  const selectOption = item => {
    let data = value ? [...value] : [];
    const isExist = data.find(
      option => getOptionValue(option) === getOptionValue(item)
    );

    if (!isExist) {
      data = [...data, item];
      onChange(data);
    }
  };

  const unselectOption = item => {
    const index = value.findIndex(
      option => getOptionValue(option) === getOptionValue(item)
    );

    if (index > -1) {
      let data = value.filter((item, i) => (i !== index ? item : false));
      onChange(data);
    }
  };

  const onCheckItem = (item, isChecked) => {
    if (isChecked === true) selectOption(item);
    else unselectOption(item);
  };

  const selectAll = () => {
    let data = value ? [...value] : [];
    options.map(item => {
      const isExist = data.find(
        option => getOptionValue(option) === getOptionValue(item)
      );

      if (!isExist) data = [...data, item];
      return true;
    });

    onChange(data);
  };

  const id = `tooltip_select_${name}`;
  return (
    <>
      {label && <label className="form-control-label">{label}</label>}
      <div
        id={id}
        className={cs(classes.root, { [classes.disabled]: disabled === true })}
        ref={ref}
        onClick={() => {
          if (disabled === true) return false;
          setOpen(true);
        }}
      >
        <div
          className={cs(classes.control, { [classes.open]: isOpen === true })}
        >
          {value && value.length > 0 ? (
            <div className={classes.value}>{displayValue(value)}</div>
          ) : (
            <div className={classes.placeholder}>{placeholder}</div>
          )}
          <i className="ml-auto mr-2 fas fa-sort" />
        </div>
        <div className={cs(classes.menu, { [classes.open]: isOpen === true })}>
          <div className={classes.menuSearch}>
            <Input
              innerRef={inputRef}
              type="text"
              value={keyword}
              onChange={e => {
                setPage(1);
                setHasMore(true);
                setKeyword(e.target.value);
              }}
            />
            <button className="btn btn-link" onClick={selectAll}>
              Select All
            </button>
            <button className="btn btn-link" onClick={() => onChange([])}>
              Select None
            </button>
          </div>
          <div className={classes.menuList}>
            <InfiniteScroll
              pageStart={0}
              initialLoad={false}
              loadMore={() => {
                if (!loading && hasMore) {
                  setLoading(true);
                  setPage(page + 1);
                }
              }}
              hasMore={hasMore}
              useWindow={false}
            >
              {options.length > 0
                ? options.map(item => {
                    const id = getOptionValue(item);
                    const label = getOptionLabel(item);
                    return (
                      <div
                        key={`${name}-checkbox-${id}`}
                        className={classes.option}
                      >
                        <Input
                          id={`${name}_option_${id}`}
                          name={`${name}_option_${id}`}
                          type="checkbox"
                          value="yes"
                          label={label}
                          checked={
                            !!value.find(
                              option => getOptionValue(option) === id
                            )
                          }
                          onChange={e => {
                            const isChecked = e.target.checked;
                            onCheckItem(item, isChecked);
                          }}
                        />
                      </div>
                    );
                  })
                : !loading && <div className="text-muted">No Data</div>}
              {loading && <div className="text-muted">Loading...</div>}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {tooltip && (
        <UncontrolledTooltip delay={0} placement="bottom" target={id}>
          {tooltip}
        </UncontrolledTooltip>
      )}
    </>
  );
};

SelectMultiple.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  units: PropTypes.string,
  url: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tooltip: PropTypes.string,
};

SelectMultiple.defaultProps = {
  units: 'Options',
  disabled: false,
  placeholder: '',
  value: null,
  getOptionLabel: option => option.text,
  getOptionValue: option => option.id,
};

export default SelectMultiple;
