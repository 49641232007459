import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { Row } from 'reactstrap';
import classes from 'views/pages/ClientProfile/CreditBank/CreditBank.module.scss';
import RSelect from 'components/FormFields/RSelect';
import { getOptions } from 'helpers/clientProfile';
import FormikInput from 'components/FormFields/Input/FormikInput';
import className from 'classnames';
import Button from 'components/Button';

const estimatedPaymentObj = {
  year: '',
  quarter: '',
  amount: '',
};

const EstimatedPayments = ({ values, setFieldValue }) => {
  const [isRemovePaymentLoading, setRemovePaymentLoading] = useState(false);

  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );

  const editProfileLoading = useSelector(({ clientProfile }) =>
    get(clientProfile, 'editClientProfile.isInProgress')
  );

  const quartersOptions = useSelector(({ metadata }) =>
    get(metadata, 'quarters.data', {})
  );

  const addEstimatedPayment = () => {
    const valueArray = values.estimated_payments.length
      ? values.estimated_payments
      : [estimatedPaymentObj];
    const newEstimatedPayment = [...valueArray];
    newEstimatedPayment.push(estimatedPaymentObj);
    setFieldValue('estimated_payments', newEstimatedPayment);
  };

  const removeEstimatedPayment = index => () => {
    if (values.estimated_payments.length > 1) {
      setRemovePaymentLoading(true);
      let estimatedPayment = [...values.estimated_payments];
      let newEstimatedPayment = [
        ...estimatedPayment.slice(0, index),
        ...estimatedPayment.slice(index + 1),
      ];
      setFieldValue('estimated_payments', newEstimatedPayment);
    }
  };

  useEffect(() => {
    if (!isRemovePaymentLoading || !editProfileLoading) {
      setRemovePaymentLoading(false);
    }
  }, [editProfileLoading, isRemovePaymentLoading]);

  const renderPayments = () => {
    const payments =
      values.estimated_payments && values.estimated_payments.length
        ? values.estimated_payments
        : [estimatedPaymentObj];

    return payments.map((val, index) => (
      <Row className={classes.row} key={index}>
        <div className={classes.textInputRightMargin}>
          <RSelect
            name={`estimated_payments[${index}].year`}
            options={getOptions(yearsList)}
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            placeholder="Year"
            value={val.year}
            onChange={value => {
              setFieldValue(
                `estimated_payments[${index}].year`,
                value ? value : null
              );
            }}
          />
        </div>
        <div className={classes.textInputRightMargin}>
          <RSelect
            name={`estimated_payments[${index}].quarter`}
            options={getOptions(quartersOptions)}
            placeholder="Quarter"
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            value={val.quarter}
            onChange={value => {
              setFieldValue(
                `estimated_payments[${index}].quarter`,
                value ? value : null
              );
            }}
          />
        </div>
        <FormikInput
          name={`estimated_payments[${index}].amount`}
          type="text"
          placeholder="Amount"
          groupClassName={className('ml-0 mr-2', classes.textInputLeftMargin)}
        />
        <div
          className={className(classes.removeIcon, {
            [classes.disabled]:
              !(values.estimated_payments.length > 1) || isRemovePaymentLoading,
          })}
        >
          <span onClick={removeEstimatedPayment(index)}>
            <i className="fas fa-minus-circle p-0 mb-2" />
          </span>
        </div>
      </Row>
    ));
  };

  return (
    <div className={classes.textInputLeftMargin}>
      <Row className={className('mb-0', classes.row)}>
        <div
          className={className('flex-column align-items-initial', classes.row)}
        >
          <Row className={className('mb-0', classes.row)}>
            <label className="form-control-label">Estimated Payments</label>
          </Row>
          {renderPayments()}
        </div>
      </Row>
      <Row className={className('flex-row mb-0', classes.row)}>
        <div className={classes.textInputRightMargin}>
          <Button
            outline
            color="primary"
            onClick={addEstimatedPayment}
            className={className('p-1 w-50', classes.button)}
          >
            + Add Estimated Payments
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default EstimatedPayments;
