import React from 'react';
import TagsInput from 'react-tagsinput';

import { Container } from 'reactstrap';
import get from 'lodash/get';
import { useAccess, permissions } from 'helpers/permission';

const TagsFilter = ({ formik }) => {
  let tags = [];
  const isUserAllowedViewAllTimeLogs = useAccess([
    permissions.VIEW_ALL_TIMELOGS,
  ]);
  if (formik.values.users.length > 0) {
    let text = '';
    if (formik.values.users.length > 1)
      text = `${formik.values.users.length} members`;
    else {
      text = `${get(formik.values.users, '0.name')}`;
    }

    tags.push({
      text: text,
      disabled: !isUserAllowedViewAllTimeLogs,
      onRemove: () => {
        formik.setFieldValue('users', []);
      },
    });
  }

  if (formik.values.range_date)
    tags.push({
      text: formik.values.range_date,
      onRemove: () => {
        formik.setFieldValue('range_date', '');
        formik.setFieldValue('startDate', '');
        formik.setFieldValue('endDate', '');
      },
    });

  if (formik.values.billable) {
    tags.push({
      text:
        formik.values.billable.text === 'All'
          ? 'Billable - Non-Billable'
          : formik.values.billable.text,
      onRemove: () => {
        formik.setFieldValue('billable', '');
      },
    });
  }

  if (formik.values.clients.length > 0) {
    let text = '';
    if (formik.values.clients.length > 1)
      text = `${formik.values.clients.length} clients`;
    else {
      text = `${get(formik.values.clients, '0.name')}`;
    }

    tags.push({
      text: text,
      onRemove: () => {
        formik.setFieldValue('clients', []);
      },
    });
  }

  if (formik.values.themes.length > 0) {
    let text = '';
    if (formik.values.themes.length > 1)
      text = `${formik.values.themes.length} SOWs`;
    else {
      text = `${get(formik.values.themes, '0.name')}`;
    }

    tags.push({
      text: text,
      onRemove: () => {
        formik.setFieldValue('themes', []);
      },
    });
  }

  if (formik.values.initiatives.length > 0) {
    let text = '';
    if (formik.values.initiatives.length > 1)
      text = `${formik.values.initiatives.length} Study`;
    else {
      text = `${get(formik.values.initiatives, '0.name')}`;
    }

    tags.push({
      text: text,
      onRemove: () => {
        formik.setFieldValue('initiatives', []);
      },
    });
  }

  if (formik.values.epics.length > 0) {
    let text = '';
    if (formik.values.epics.length > 1)
      text = `${formik.values.epics.length} epics`;
    else {
      text = `${get(formik.values.epics, '0.name')}`;
    }

    tags.push({
      text: text,
      onRemove: () => {
        formik.setFieldValue('epics', []);
      },
    });
  }

  if (formik.values.stories.length > 0) {
    let text = '';
    if (formik.values.stories.length > 1)
      text = `${formik.values.stories.length} stories`;
    else {
      text = `${get(formik.values.stories, '0.name')}`;
    }

    tags.push({
      text: text,
      onRemove: () => {
        formik.setFieldValue('stories', []);
      },
    });
  }

  if (formik.values.tasks.length > 0) {
    let text = '';
    if (formik.values.tasks.length > 1)
      text = `${formik.values.tasks.length} tasks`;
    else {
      text = `${get(formik.values.tasks, '0.name')}`;
    }

    tags.push({
      text: text,
      onRemove: () => {
        formik.setFieldValue('tasks', []);
      },
    });
  }

  if (formik.values.description) {
    tags.push({
      text: formik.values.description,
      onRemove: () => {
        formik.setFieldValue('description', '');
      },
    });
  }

  const defaultRenderTag = props => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;

    return (
      <span key={key} {...other}>
        {getTagDisplayValue(tag.text)}
        {!disabled && !tag.disabled && (
          <a
            className={classNameRemove}
            onClick={e => {
              e.preventDefault();
              onRemove(key);
              tag.onRemove();
            }}
            href="/"
          >
            {' '}
          </a>
        )}
      </span>
    );
  };

  if (tags.length === 0) return <></>;

  return (
    <Container fluid className="mb-4">
      <TagsInput
        className="bootstrap-tagsinput"
        onChange={() => {}}
        tagProps={{ className: 'tag badge mr-1' }}
        value={tags}
        inputProps={{
          className: '',
          placeholder: '',
        }}
        preventSubmit={true}
        renderTag={defaultRenderTag}
      />
    </Container>
  );
};

export default TagsFilter;
