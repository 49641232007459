import createTypes from 'redux-create-action-types';

export default createTypes(
  'CATEGORIES_LOADING',
  'CATEGORIES_SUCCESS',
  'CATEGORIES_ERROR',
  'GET_ALL_CATEGORIES_LOADING',
  'GET_ALL_CATEGORIES_SUCCESS',
  'GET_ALL_CATEGORIES_ERROR',
  'ARTICLES_LOADING',
  'ARTICLES_SUCCESS',
  'ARTICLES_ERROR',
  'ARTICLE_LOADING',
  'ARTICLE_SUCCESS',
  'ARTICLE_ERROR',
  'ARTICLES_MOST_LOADING',
  'ARTICLES_MOST_SUCCESS',
  'ARTICLES_MOST_ERROR',
  'CREATE_CATEGORY_INPROGRESS',
  'CREATE_CATEGORY_SUCCESS',
  'RESET_CREATE_CATEGORY',
  'CREATE_CATEGORY_FAILURE',
  'UPDATE_CATEGORY_INPROGRESS',
  'UPDATE_CATEGORY_SUCCESS',
  'UPDATE_CATEGORY_FAILURE',
  'REORDER_CATEGORY_INPROGRESS',
  'REORDER_CATEGORY_SUCCESS',
  'REORDER_CATEGORY_FAILURE',
  'UPDATE_CATEGORY_STATE'
);
