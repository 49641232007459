import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button, ListGroupItem, ListGroup, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import {
  byteToReadbleSize,
  fileExtensionList,
  imageFileExtensionList,
} from 'helpers/constants';
import { LineLoader } from 'components/ContentLoader';
import classes from './FileDropZone.module.scss';
import includes from 'lodash/includes';
import { downloadDocument } from 'store/actions/documents';
const FileDropZone = props => {
  const {
    handleFileChange,
    attachment,
    deleteIndex = [],
    inputProps = {},
    rootProps = {},
    shouldAllowDownload = false,
    downloadAttachment,
    showContentLoader,
    shouldAppendFiles = false,
    isUpdateAllowed = true,
    showFileDeletingLoader = false,
    onRemove,
    placeholder = 'Drop the files here to upload',
  } = props;

  const [files, setFiles] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const getFiles = () => {
      // if multiple files are allowed then attachment should be array
      if (inputProps.multiple) {
        return attachment;
      } else {
        // if single file is allowed then attachment will be single file only so creating array of it
        if (attachment && Object.keys(attachment).length) {
          return [attachment];
        } else {
          return [];
        }
      }
    };

    setFiles(getFiles());
  }, [attachment, inputProps.multiple]);

  const onDrop = acceptedFiles => {
    if (inputProps.multiple) {
      // accept all files if multiple files are allowed
      handleFileChange &&
        handleFileChange([...files, ...acceptedFiles], acceptedFiles);
    } else {
      // accept first file only if only single file is allowed
      handleFileChange && handleFileChange([acceptedFiles[0]]);
    }
  };

  const handleDownloadClick = file => {
    dispatch(downloadDocument(file));
  };

  const getIcon = doc => {
    const docType = doc.name
      .toLowerCase()
      .split('.')
      .pop();

    if (!fileExtensionList.includes(docType))
      return (
        <img alt="file" src={require(`assets/img/extensions/Icn-file.svg`)} />
      );

    if (imageFileExtensionList.includes(docType) && doc.thumbnail) {
      return <img alt="docType" src={doc.thumbnail} />;
    }

    return (
      <img
        alt="docType"
        src={require(`assets/img/extensions/Icn-${docType}.svg`)}
      />
    );
  };

  const removeFile = index => () => {
    onRemove(
      [...files.slice(0, index), ...files.slice(index + 1)],
      files[index],
      index
    );
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const showFileList = () => {
    return (
      <ListGroup className="list-group-lg" flush>
        {files.map((file, index) => {
          return (
            <ListGroupItem key={index} className="px-0">
              {includes(deleteIndex, index) && showFileDeletingLoader ? (
                <LineLoader
                  number={1}
                  height="6rem"
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                <Row
                  className={classnames(
                    'align-items-center',
                    classes['file-row']
                  )}
                >
                  <Col className="col-auto">
                    <div className={classnames(classes.icon, 'avatar')}>
                      {getIcon(file)}
                    </div>
                  </Col>
                  <div
                    className={classnames(
                      'col ml--3 flex-nowrap',
                      classes['file-title']
                    )}
                  >
                    <h4 className=" mb-1">{file.name}</h4>
                    <p className=" small text-muted mb-0">
                      {byteToReadbleSize(file.size)}
                    </p>
                  </div>
                  <Col className="col-auto">
                    {file.id && shouldAllowDownload ? (
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => handleDownloadClick(file)}
                      >
                        <i className="fas fa-download" />
                      </Button>
                    ) : null}
                    {isUpdateAllowed ? (
                      <Button
                        size="sm"
                        color="danger"
                        onClick={removeFile(index)}
                      >
                        <i className="fas fa-trash" />
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              )}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  };

  if (files && files.length && !shouldAppendFiles) {
    return showFileList();
  }

  return (
    <>
      {isUpdateAllowed ? (
        showContentLoader ? (
          <LineLoader number={1} height="5.625rem" />
        ) : (
          <div
            className={classes.fileInputWrapper}
            {...getRootProps({ ...rootProps })}
          >
            <input {...getInputProps({ ...inputProps })} />
            <p>{placeholder}</p>
          </div>
        )
      ) : null}
      {files && !!files.length && shouldAppendFiles && showFileList()}
    </>
  );
};

FileDropZone.prototype = {
  showContentLoader: PropTypes.bool,
  handleFileChange: PropTypes.func,
  attachment: PropTypes.any,
  inputProps: PropTypes.any,
  rootProps: PropTypes.any,
  shouldAppendFiles: PropTypes.bool,
  shouldAllowDownload: PropTypes.bool,
  downloadAttachment: PropTypes.func,
  isUpdateAllowed: PropTypes.bool,
  onRemove: PropTypes.func,
  showFileDeletingLoader: PropTypes.bool,
  deleteIndex: PropTypes.arrayOf(PropTypes.number), // height of each line
};

export default FileDropZone;
