import React from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardBody, Row } from 'reactstrap';
import { updateDetailsByUserId } from 'store/actions/profile';
import { showProfileUser } from 'store/actions/authActions';
import classes from './profile.module.scss';
import cs from 'classnames';

const ManageEmailPreferences = () => {
  const dispatch = useDispatch();

  const details = useSelector(({ profile }) =>
    JSON.parse(JSON.stringify(get(profile, 'details.data', {})))
  );

  const listActivities = [
    {
      label: 'Referral Activity Monthly Digest',
      value: 'enable_referral_activity_monthly_digest',
    },
    {
      label: 'Referral Activity Status Changes',
      value: 'enable_referral_activity_status_changes',
    },
    {
      label: 'Referral Submissions',
      value: 'enable_referral_submissions',
    },
    {
      label: 'All Email Notifications',
      value: 'enable_all_email_notifications',
    },
  ];

  const handleChanges = async e => {
    let notify = { ...details.notification_preference };

    const value = e.target.checked ? 1 : 0;

    if (e.target.name !== 'enable_all_email_notifications') {
      notify[e.target.name] = value;
      notify.enable_all_email_notifications = 0;
    } else {
      notify = {
        enable_referral_activity_monthly_digest: value,
        enable_referral_activity_status_changes: value,
        enable_referral_submissions: value,
        enable_all_email_notifications: value,
      };
    }

    await dispatch(
      updateDetailsByUserId(details.id, {
        notification_preference: notify,
      })
    );
    dispatch(showProfileUser());
  };

  return (
    <>
      <Card className="h-100 mb-0">
        <CardBody className={classes.manangeEmailPreferences}>
          <Row>
            <div className="col">
              <h5 className="h3">Notification Preferences</h5>
              <p className={cs('h4', 'font-weight-normal')}>
                Please indicate which notifications you would like to receive:
              </p>

              <ul>
                {listActivities.map((active, key) => {
                  return (
                    <li key={key}>
                      <div>{active.label}</div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          name={active.value}
                          checked={
                            get(
                              details,
                              `notification_preference.${active.value}`
                            ) === 1
                              ? true
                              : false
                          }
                          onClick={handleChanges}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default ManageEmailPreferences;
