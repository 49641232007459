import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Popover, PopoverBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import classes from './PartnerManager.module.scss';
import { detectMobile, formatPhoneNumber } from 'helpers/constants';

const PartnerManager = () => {
  const partnerManager = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.partner_manager', {})
  );
  const calendlyLink = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.calendly_link', '')
  );
  const partner_manager_phone = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.partner_manager.phone', '')
  );

  const phoneNumber = formatPhoneNumber(partner_manager_phone);

  const isMobile = detectMobile();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => {
    setPopoverOpen(!popoverOpen);
  };

  useEffect(() => {
    if (popoverOpen) {
      let popoverTimeout = setTimeout(() => {
        setPopoverOpen(false);
      }, 5000);
      return () => {
        clearTimeout(popoverTimeout);
      };
    }
  }, [popoverOpen]);

  return (
    <>
      <div className={classes.profile}>
        <div className={classes.image}>
          <img src={partnerManager?.avatar} alt="img" />
        </div>
        <div className={classes.details}>
          <h3>
            {partnerManager?.first_name} {partnerManager?.last_name}
          </h3>
          <a href={`mailto:${partnerManager?.email}`}>
            <img
              src={require('assets/img/icons/envelope-icon.svg')}
              alt="envelope"
            />
            Send Email
          </a>
          <a
            {...(isMobile
              ? { href: `tel:${phoneNumber}`, onClick: () => {} }
              : { onClick: toggle })}
            id="partner-manager-phone-tooltip"
          >
            <img src={require('assets/img/icons/phone-icon.svg')} alt="phone" />
            Make a Call
          </a>
          {popoverOpen && (
            <Popover
              placement="top"
              trigger="legacy"
              isOpen={popoverOpen}
              target="partner-manager-phone-tooltip"
              className={cx('custom-tooltip', classes.tooltipCustom)}
              toggle={toggle}
            >
              <PopoverBody>
                <span className="font-weight-bolder progress-heading">
                  {phoneNumber || '-'}
                </span>
              </PopoverBody>
            </Popover>
          )}
          <a href={calendlyLink} target="_blank" rel="noreferrer">
            <img
              src={require('assets/img/icons/calender-icon.svg')}
              alt="calender"
            />
            Book a Meeting
          </a>
        </div>
      </div>
    </>
  );
};

export default PartnerManager;
