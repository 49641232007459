/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";
import analytics,{analyticsConstants} from 'helpers/analytics'

class Calendar extends React.Component {
  render() {
    const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({category: analyticsConstants.category.footer_links,...rest,});};
    return (
      <>
        <Container fluid id="admin-footer">
          <footer className="footer py-3">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="6">
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://www.striketax.com/"
                    target="_blank"
                  >
                    Strike Tax Advisory
                  </a>
                </div>
              </Col>
              <Col lg="6">
                <Nav className="nav-footer justify-content-center justify-content-lg-end">

                  {/* <NavItem>
                    <NavLink
                      href="https://www.getventive.com/about-us"
                      target="_blank"

                      onClick={()=>{
                          analyticsSendEvent({action: analyticsConstants.action.view_about_us,});
                        }}>
                      About Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.getventive.com/blog"
                      target="_blank"

                      onClick={()=>{
                          analyticsSendEvent({action: analyticsConstants.action.view_blog,});
                        }}>
                      Blog
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      href="/terms"
                      target="_blank"
                      onClick={()=>{
                          analyticsSendEvent({action: analyticsConstants.action.view_terms,});
                        }}
                    >
                      Terms & Conditions
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="/privacy"
                        target="_blank"
                        onClick={()=>{
                          analyticsSendEvent({action: analyticsConstants.action.view_privacy_policy,});
                        }}
                    >
                      Privacy Policy
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Calendar;
