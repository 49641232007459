import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {
  Pagination as BasePagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

const ranges = [1, 2];
const rangesReverse = [...ranges].reverse();

const Pagination = props => {
  const { totalPage, onClick } = props;
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  return (
    <nav>
      <BasePagination
        className="pagination justify-content-center"
        listClassName="justify-content-center"
      >
        <PaginationItem className={cs({ disabled: currentPage <= 1 })}>
          <PaginationLink
            href="#"
            onClick={e => {
              e.preventDefault();
              if (currentPage - 1 >= 1) onClick(currentPage - 1);
            }}
            tabIndex="-1"
          >
            <i className="fa fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>

        {rangesReverse.map(item => {
          const prevPage = currentPage - item;

          if (prevPage < 1) return '';
          return (
            <PaginationItem key={`previous-${prevPage}`}>
              <PaginationLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  onClick(prevPage);
                }}
              >
                {prevPage}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        <PaginationItem className="active" key={`current-${currentPage}`}>
          <PaginationLink
            href="#"
            onClick={e => {
              e.preventDefault();
            }}
          >
            {currentPage}
          </PaginationLink>
        </PaginationItem>

        {ranges.map(item => {
          const nextPage = currentPage + item;
          if (nextPage > totalPage) return '';
          return (
            <PaginationItem key={`next-${nextPage}`}>
              <PaginationLink
                href="#"
                onClick={e => {
                  e.preventDefault();
                  onClick(nextPage);
                }}
              >
                {nextPage}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        <PaginationItem className={cs({ disabled: currentPage >= totalPage })}>
          <PaginationLink
            href="#"
            onClick={e => {
              e.preventDefault();
              if (currentPage + 1 <= totalPage) onClick(currentPage + 1);
            }}
          >
            <i className="fa fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </BasePagination>
    </nav>
  );
};

Pagination.propTypes = {
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  onClick: PropTypes.func,
};
export default Pagination;
