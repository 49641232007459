import React from 'react';
import classNames from 'classnames';
import LoadingImg from 'assets/img/brand/loading.gif';
import classes from './Loading.module.scss';

const Loading = ({ wrapperClass, size = 150 }) => {
  return (
    <div className={classNames(wrapperClass, classes.loader)}>
      <img
        src={LoadingImg}
        alt="Loading..."
        style={{ height: size, width: size }}
      />
    </div>
  );
};

export default Loading;
