import React from 'react';
import get from 'lodash/get';
import RSelectAsync from 'components/FormFields/RSelectAsync';

const SelectTask = props => {
  const { formik, project, story } = props;

  return (
    <RSelectAsync
      url={`/timelogs/tasks/search?initiative=${project}&story=${story}`}
      getOptionLabel={option => option.name}
      placeholder="Select Assignment"
      onChange={data => {
        const selected = {
          id: get(data, 'id', ''),
          name: get(data, 'name', ''),
        };

        formik.setFieldValue('task', selected.id);
        formik.setFieldValue('task_name', selected.name);
      }}
      value={
        formik.values.task && formik.values.task_name
          ? { id: formik.values.task, name: formik.values.task_name }
          : null
      }
    />
  );
};

export default SelectTask;
