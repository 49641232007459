import { get } from 'lodash';
import {
  fetchNotes,
  createNote,
  removeNote,
  updateNote,
  updatePinNote,
} from 'api/accountOverview';
import Types from '../types/accountOverview';

// =================FETCH_NOTES=================
export const fetchNotesList = clientId => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_NOTES_INPROGRESS,
    });
    try {
      const resp = await fetchNotes(clientId);

      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_NOTES_SUCCESS,
          data: resp.data || [],
        });
      } else {
        dispatch({
          type: Types.FETCH_NOTES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_NOTES_FAILURE,
        message: error.message,
      });
    }
  };
};

// =================ADD_NOTE=================
export const addNote = (clientId, newNote) => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_NOTE_INPROGRESS,
    });
    try {
      const resp = await createNote(clientId, newNote);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const newNoteObj = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.ADD_NOTE_SUCCESS,
          data: newNoteObj || {},
        });
      } else {
        dispatch({
          type: Types.ADD_NOTE_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.ADD_NOTE_FAILURE,
        message: error.message,
      });
    }
  };
};

// =================EDIT_NOTE=================
export const editNote = (clientId, note) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_NOTE_INPROGRESS,
    });
    try {
      const resp = await updateNote(clientId, note.id, note);

      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_NOTE_SUCCESS,
          data: resp.data,
        });
      } else {
        dispatch({
          type: Types.EDIT_NOTE_FAILURE,
          message: message,
        });
      }
      return { status };
    } catch (error) {
      dispatch({
        type: Types.EDIT_NOTE_FAILURE,
        message: error.message,
      });
    }
  };
};
// =================PIN/UNPIN_NOTE=================
export const pinNote = (client_id, updatedNote) => {
  return async dispatch => {
    dispatch({
      type: Types.PIN_NOTE_INPROGRESS,
    });
    try {
      const resp = await updatePinNote(
        client_id,
        updatedNote.id,
        updatedNote.pinned_by //?NOTE:pinned_by is same as is_pinned
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.PIN_NOTE_SUCCESS,
          data: updatedNote,
        });
      } else {
        dispatch({
          type: Types.PIN_NOTE_FAILURE,
          message: message,
        });
      }
      return { status };
    } catch (error) {
      dispatch({
        type: Types.PIN_NOTE_FAILURE,
        message: error.message,
      });
    }
  };
};
// =================DELETE_NOTE=================
export const deleteNote = (client_id, note_id) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_NOTE_INPROGRESS,
    });
    try {
      const resp = await removeNote(client_id, note_id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');

      if (status) {
        dispatch({
          type: Types.DELETE_NOTE_SUCCESS,
          data: { note_id, ...resp },
        });
      } else {
        dispatch({
          type: Types.DELETE_NOTE_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_NOTE_FAILURE,
        message: error.message,
      });
    }
  };
};
