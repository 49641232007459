import React from 'react';
import { FormGroup, Form } from 'reactstrap';
import { Formik } from 'formik';
import Input from 'components/FormFields/Input';
import classNames from 'classnames';
import classes from './supportRequestModal.module.scss';
import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import FormModal from 'components/FormFields/FormModal';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import Editor from 'components/FormFields/Editor/Editor';
import { submitSupportRequest } from 'store/actions/app';
import { postQuestion } from 'store/actions/partners';

const SupportRequestModal = ({ closeModal, isModalOpen }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const { is_partner = false } = currentUser;

  let initialValues = {
    name: '',
    epic_id: null,
    description: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Required'),
    description: Yup.string()
      .ensure()
      .required('Required')
      .test(
        'is-empty',
        'Description can not be empty',
        value =>
          !isEmpty(value.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/gi, '$1').trim())
      ),
  });

  const RenderForm = ({
    closeModal,
    handleChange,
    setFieldValue,
    isValid,
    handleReset,
    handleSubmit,
    values,
    isModalOpen,
  }) => {
    return (
      <FormModal
        toggle={e => {
          handleReset();
          closeModal();
        }}
        isOpen={isModalOpen}
        className={classes['support-req-modal']}
        heading="Ask a Question"
        submit={{
          onClick: handleSubmit,
          isValid: isValid,
          buttonText: 'Submit Request',
        }}
        cancel={{
          onClick: () => {
            handleReset();
            closeModal();
          },
          buttonText: 'Cancel',
        }}
      >
        <Form className={classNames('mb-0', classes['add-project-form'])}>
          <FormGroup>
            <Input
              value={values.name}
              placeholder="Subject*"
              required
              name="name"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Editor
              value={values.description}
              onChange={description =>
                setFieldValue('description', description)
              }
              className="mb-4"
              placeholder="Description*"
            />
          </FormGroup>
        </Form>
      </FormModal>
    );
  };
  if (!isModalOpen) return null;
  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { resetForm, setSubmitting }) => {
        setSubmitting(false);
        if (is_partner) {
          const partnerQuery = {
            subject: values.name,
            question: values.description,
          };
          dispatch(postQuestion(partnerQuery));
        } else {
          dispatch(submitSupportRequest(values));
        }
        resetForm();
        closeModal();
      }}
    >
      {props => (
        <RenderForm
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          {...props}
        />
      )}
    </Formik>
  );
};

export default SupportRequestModal;
