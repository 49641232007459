import React, { useEffect, useState, useCallback } from 'react';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { Col } from 'reactstrap';
import Info from './Fields/Info';
import Description from './Fields/Description';
import AttachFile from './Fields/FileAttachment';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import classes from './TaskModal.module.scss';
import classnames from 'classnames';
import {
  fetchStory as fetchStoryAction,
  updateClientTask,
  markCompleteClientTask,
} from 'store/actions/Story/details';
import { fetchStoryStatuses } from 'store/actions/Story/storyStatuses';
import { clearCommentsData } from 'store/actions/Story/comments';
import moment from 'moment';
import useBreakPoint from 'helpers/useBreakPoint';
import { permissions, useAccess } from 'helpers/permission';
import find from 'lodash/find';
import StoryDetails from './StoryDetails';
import PropTypes from 'prop-types';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import socket from 'helpers/socket';
import toNumber from 'lodash/toNumber';
import analytics, { analyticsConstants } from 'helpers/analytics';
import Loading from 'components/Loading';
import Button from 'components/Button';
import attachments from 'store/reducers/Story/attachments';
import each from 'lodash/each';

const TaskModal = ({
  isOpen,
  closeModal,
  storyId: recievedStoryId,
  currentTab: parentCurrentTab = 'overview',
  onTabChange,
  comment,
}) => {
  const dispatch = useDispatch();

  const isMobile = useBreakPoint('xs', 'down');
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };

  useEffect(() => {
    dispatch(fetchStoryStatuses());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recievedStoryId) {
      dispatch(fetchStoryAction(recievedStoryId));
      socket.joinAndListenComments('Story', recievedStoryId);
      return () => {
        socket.leaveComments();
        dispatch(clearCommentsData());
      };
    }
    // used when notification is sent to user
    const leftColumn = document.getElementById('storyModal-leftColumn');
    if (leftColumn) leftColumn.scrollTop = 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recievedStoryId]);

  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const isStoryFetchInProgress = useSelector(({ story }) =>
    get(story, 'details.isInProgress', false)
  );

  const projectMemberDetails = useSelector(({ project }) =>
    get(project, 'getProject.data.data.team_members', [])
  );

  const statuses = useSelector(({ story }) =>
    get(story, 'statusOptions.data', [])
  );

  const userId = useSelector(({ auth }) => get(auth, 'user.id'));

  const isUserMemberOfTeam = find(
    projectMemberDetails,
    member => member.id === userId
  );

  const isUserAllowedEdit = useAccess([
    permissions.EDIT_ALL_STORIES,
    { permission: permissions.EDIT_PROJECT_STORIES, value: isUserMemberOfTeam },
  ]);

  const isUserAllowedReply = useAccess(
    [permissions.EDIT_ALL_STORIES, permissions.EDIT_ASSIGNED_STORIES],
    true
  );

  const [editField, setEditField] = useState();
  const [strikeAttachments, setStrikeAttachments] = useState([]);
  const [userAttachments, setUserAttachments] = useState([]);
  const [filesToRemove, setFileToRemove] = useState([]);
  const [reply, setReply] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);
  const isClient = useSelector(({ auth }) => auth.user.is_client);
  const useGetFieldValue = (fieldName, emptyValue = null) =>
    useGetFieldFromObject('story', `details.data.${fieldName}`, emptyValue);

  const isStoryFetchError = useGetFieldFromObject(
    'story',
    'details.isError',
    false
  );
  const storyFetchErrorMessage = useGetFieldFromObject(
    'story',
    'details.message',
    'Something went wrong. Please try again'
  );
  const errorStoryId = useGetFieldFromObject('story', 'details.id');

  const isStoryError =
    isStoryFetchError && toNumber(errorStoryId) === recievedStoryId;

  const storyName = useGetFieldValue('name');
  const storyNumber = useGetFieldValue('number', '0');
  const storyId = useGetFieldValue('id');
  const description = useGetFieldValue('description');
  const owner = {
    id: useGetFieldValue('owner.id'),
    name: useGetFieldValue('owner.name'),
    avatar: useGetFieldValue('owner.avatar'),
  };
  const projectId = useGetFieldValue('initiative.id');
  const projectName = useGetFieldValue('initiative.name');
  const score = useGetFieldValue('score');
  const status = {
    color: useGetFieldValue('status.color'),
    status: useGetFieldValue('status.label'),
  };
  const createdBy = {
    name: useGetFieldValue('created_by.name'),
    avatar: useGetFieldValue('created_by.avatar'),
  };

  const storyReply = useGetFieldValue('reply', null);
  const storyAttachments = useGetFieldValue('attachments', []);
  const priority = useGetFieldValue('priority', null);
  const complexity = useGetFieldValue('complexity', null);
  const priorities = useGetFieldFromObject('story', 'priorityOptions.data', []);
  const complexities = useGetFieldFromObject(
    'story',
    'complexityOptions.data',
    []
  );
  const scoreMatrix = useGetFieldFromObject('story', 'scoreMatrix.data', []);
  const useGetTime = field =>
    moment(useGetFieldValue(field, moment()))
      .tz(userTimeZone)
      .format('MMMM DD, YYYY');
  const createdOn = useGetTime('created_at');
  const updatedOn = useGetTime('updated_at');

  useEffect(() => {
    if (storyAttachments && storyAttachments.length) {
      const strikeFiles = storyAttachments.filter(
        attachment => attachment.is_strike_document
      );
      const userFiles = storyAttachments.filter(
        attachment => !attachment.is_strike_document
      );
      setUserAttachments(userFiles);
      setStrikeAttachments(strikeFiles);
    } else {
      setUserAttachments([]);
      setStrikeAttachments([]);
    }
  }, [storyAttachments]);

  useEffect(() => {
    if (storyReply) {
      setReply(storyReply);
    }
  }, [storyReply]);

  const assignStoryOwner = async ({ id }) => {
    // analyticsSendEvent({
    //   action: analyticsConstants.action.update_story_owner,
    //   updated_from: 'Story Modal',
    // });
    // await dispatch(assignStoryOwnerAction(storyId, id));
    // setEditField();
    // onChange();
  };

  const changeStatus = async item => {
    // analyticsSendEvent({
    //   action: analyticsConstants.action.update_story_status,
    //   updated_from: 'Story Modal',
    // });
    // await dispatch(updateSelectedStoryStatus(storyId, item));
    // onChange();
  };

  const ConditionallyRenderScrollBar = useCallback(
    ({ children }) => {
      return isMobile ? (
        children
      ) : (
        <div className={classes.scrollBar} id="storyModal-leftColumn">
          {children}
        </div>
      );
    },
    [isMobile]
  );

  const changePriority = async data => {
    // analyticsSendEvent({
    //   action: analyticsConstants.action.update_story_priority,
    //   updated_from: 'Story Modal',
    // });
    // await dispatch(updateStoryPriority(storyId, data));
    // onChange();
  };

  useEffect(() => {
    if (isStoryError) {
      NotificationHandler.open({
        message: storyFetchErrorMessage,
        operation: 'failure',
      });
      closeModal();
    }
  }, [closeModal, isStoryError, storyFetchErrorMessage]);

  const handleModalClose = () => {
    closeModal();
  };

  const handleFileChange = files => {
    setUserAttachments(files);
  };

  const handleFileRemove = (files, removedFile) => {
    setFileToRemove([...filesToRemove, removedFile.id]);
    setUserAttachments(files);
  };

  const removeTaskAttachment = async (
    isStrikeAttachments,
    files,
    removeFile
  ) => {
    if (isStrikeAttachments) {
      setStrikeAttachments(files);
    } else {
      setUserAttachments(files);
    }
    const formData = new FormData();
    formData.append(`deleted_attachments[0]`, removeFile.id);
    formData.append('_method', 'PUT');
    await dispatch(updateClientTask(recievedStoryId, formData));
  };

  const updateStoryDescription = async value => {
    setReply(value);
    setEditField();
  };

  const handleReply = async () => {
    setIsSubmitting(true);
    const formData = new FormData();
    if (reply) {
      formData.append('reply', reply);
    }
    if (filesToRemove.length > 0) {
      each(filesToRemove, (fileId, index) => {
        formData.append(`deleted_attachments[${index}]`, fileId);
      });
    }
    if (userAttachments.length) {
      each(userAttachments, (attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    formData.append('_method', 'PUT');
    const success = await dispatch(updateClientTask(recievedStoryId, formData));
    if (!success) {
      setIsSubmitting(false);
      return false;
    }
    closeModal();
  };

  const handleMarkComplete = async () => {
    setIsSubmitting(true);
    const completedStatus = statuses?.find(
      status => status.label === 'Completed'
    );
    await dispatch(
      markCompleteClientTask(recievedStoryId, {
        status_id: completedStatus?.id,
      })
    );
    closeModal();
  };

  const isValid = !isSubmitting && reply;
  const isOwner = owner && currentUser && owner.id === currentUser.id;

  return (
    <Modal
      size="lg"
      scrollable
      fade={false}
      title={
        isStoryFetchInProgress ? (
          ''
        ) : (
          <div className="d-flex align-items-center">
            <span>Task ID-{storyNumber}</span>
          </div>
        )
      }
      toggle={handleModalClose}
      isOpen={isOpen && !isStoryError}
    >
      <ModalBody
        className={classnames(
          'p-0 d-flex flex-row flex-wrap',
          classes['story-modal'],
          { [classes['story-modal-mobile']]: isMobile }
        )}
        id="storyModal-body"
      >
        {isStoryFetchInProgress ? (
          <Loading wrapperClass={classes.loading} />
        ) : (
          <>
            <ConditionallyRenderScrollBar>
              <Col className={classnames(classes['left-column'], 'py-3')}>
                <Info title="Task Name" value={storyName} />
                {description && (
                  <Info title="Description" value={description} type="html" />
                )}
                {!!storyReply && (
                  <Info title="Reply" value={storyReply} type="html" />
                )}
                {isUserAllowedReply && !storyReply && isClient && (
                  <Description
                    title="Reply"
                    placeholder="Add a reply to this task"
                    editField={editField}
                    isEditAllowed={isUserAllowedReply}
                    description={reply}
                    changeDescription={updateStoryDescription}
                    onCancel={() => {
                      setEditField();
                    }}
                    isMobile={isMobile}
                    projectId={projectId}
                    onClick={() => {
                      if (editField !== 'description') {
                        setEditField('description');
                      }
                    }}
                  />
                )}
                {strikeAttachments.length > 0 && (
                  <AttachFile
                    title="Attachment from Strike team"
                    storyId={storyId}
                    attachments={strikeAttachments}
                    isEditAllowed={!isClient}
                    shouldAppendFiles={false}
                    onRemove={(...params) =>
                      removeTaskAttachment(true, ...params)
                    }
                  />
                )}
                <AttachFile
                  storyId={storyId}
                  attachments={userAttachments}
                  isEditAllowed={isClient && isUserAllowedEdit}
                  inputProps={{
                    multiple: true,
                  }}
                  shouldAppendFiles={isUserAllowedReply && isClient}
                  onRemove={handleFileRemove}
                  onChange={handleFileChange}
                />
                {!isOwner && !isClient && status?.status !== 'Completed' && (
                  <div className={classes['action-item-wrapper']}>
                    <Button
                      color="primary"
                      onClick={handleMarkComplete}
                      loading={isSubmitting}
                    >
                      Complete Task
                    </Button>
                  </div>
                )}
                {isUserAllowedReply && isClient && (
                  <div className={classes['action-item-wrapper']}>
                    <Button
                      color={!isSubmitting ? 'primary' : 'secondary'}
                      disabled={isSubmitting}
                      onClick={handleReply}
                      loading={isSubmitting}
                    >
                      Reply
                    </Button>
                  </div>
                )}
              </Col>
            </ConditionallyRenderScrollBar>
            <StoryDetails
              projectId={projectId}
              storyId={storyId}
              currentTab={parentCurrentTab}
              comment={comment}
              assignStoryOwner={assignStoryOwner}
              owner={owner}
              projectName={projectName}
              priority={priority}
              complexity={complexity}
              complexities={complexities}
              scoreMatrix={scoreMatrix}
              score={score}
              priorities={priorities}
              createdBy={createdBy}
              updatedOn={updatedOn}
              createdOn={createdOn}
              status={status}
              changeStatus={changeStatus}
              changePriority={changePriority}
              onTabChange={onTabChange}
              analyticsSendEvent={analyticsSendEvent}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

TaskModal.propTypes = {
  storyId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func,
  onChange: PropTypes.func,
  onTabChange: PropTypes.func,
  currentTab: PropTypes.oneOf(['comments', 'activity', 'info']),
  comment: PropTypes.number,
};

TaskModal.defaultProps = {
  isOpen: false,
  onChange: () => {},
  onTabChange: () => {},
  currentTab: 'comments',
};
export default TaskModal;
