import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import classes from './Radio.module.scss';
import { generateRandomNumber } from 'helpers/constants';

const Radio = props => {
  const {
    className,
    disabled,
    touched,
    error,
    label,
    id,
    checked,
    name,
    value,
    onChange,
    ...rest
  } = props;

  return (
    <>
      <div className={cx('custom-control custom-radio', className)}>
        <input
          className="custom-control-input"
          id={id}
          type="radio"
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
          {...rest}
        />

        {label && (
          <label className="custom-control-label" htmlFor={id}>
            {label}
          </label>
        )}
      </div>

      {touched && error && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </>
  );
};

Radio.prototype = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  touched: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  // reactstrap props,
};

Radio.defaultProps = {
  label: null,
  id: `Radio-${generateRandomNumber()}`,
  touched: false,
  className: '',
};

export default Radio;
