import React, { useRef } from 'react';
import classes from './TimeLogBarChart.module.scss';
import { Bar } from 'react-chartjs-2';

import TooltipForChartHandler from 'components/TooltipForChart/TooltipForChartHandler';
import get from 'lodash/get';
import { secondsToHHMMSS } from 'helpers/times';
import clockIcon from 'assets/img/icons/clock-white.svg';
import { useSelector } from 'react-redux';

const TimeLogBarChart = () => {
  const refChart = useRef(null);

  const barChart = useSelector(({ timelogReport }) =>
    get(timelogReport, 'barChart')
  );

  const data = {
    labels: get(barChart, 'data.label'),
    datasets: [
      {
        label: '',
        data: get(barChart, 'data.graph_data.0.data', []),
        backgroundColor: '#FB6340',
        barThickness: 10,
      },
    ],
  };

  const TooltipContent = ({ data }) => {
    const time = secondsToHHMMSS(data.value * 3600);
    const label = data.label;
    return (
      <div className={classes.tooltip}>
        <b>{label}</b> <br />
        <img src={clockIcon} alt="clock" /> {time}
      </div>
    );
  };

  return (
    <Bar
      ref={refChart}
      data={data}
      options={{
        maintainAspectRatio: false,
        animation: false,
        layout: {
          padding: 0,
        },
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            usePointStyle: true,
            padding: 16,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0.4,
            borderWidth: 1,
            borderCapStyle: 'rounded',
          },
        },

        scales: {
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                beginAtZero: true,
                padding: 20,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                drawBorder: false,
                drawTicks: false,
                lineWidth: 1,
                zeroLineWidth: 1,
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
              stacked: true,
              ticks: {
                beginAtZero: true,
                precision: 0,
                callback: number => {
                  return number;
                },
              },
            },
          ],
        },
        tooltips: {
          enabled: false,
          custom: tooltipModel => {
            if (tooltipModel.opacity === 0) {
              TooltipForChartHandler.close();
              return;
            }

            const point = get(tooltipModel, 'dataPoints.0', null);

            if (!point) return;
            const position = refChart.current.chartInstance.canvas.getBoundingClientRect();

            TooltipForChartHandler.open(
              position,
              tooltipModel,
              <TooltipContent data={point} />
            );
          },
        },
      }}
      datasetKeyProvider={() => Math.random()}
      className="chart-canvas"
      id="chart-hour-logged"
    />
  );
};

TimeLogBarChart.propTypes = {};
TimeLogBarChart.defaultProps = {};

export default TimeLogBarChart;
