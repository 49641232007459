import React from 'react';
import Linkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
  <a
    href={href}
    key={key}
    target="_blank"
    rel="noopener noreferrer"
    onClick={e => e.stopPropagation()}
  >
    {text}
  </a>
);

const ContentWithoutEditor = ({ children }) => {
  return <Linkify componentDecorator={componentDecorator}>{children}</Linkify>;
};

export default ContentWithoutEditor;
