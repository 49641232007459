import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Worker } from '@react-pdf-viewer/core';
// react library for routing
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector, Provider, useDispatch } from 'react-redux';
import get from 'lodash/get';
// bootstrap rtl for rtl support page
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss';
// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0'; // eslint-disable-line
import Accept from './views/pages/Auth/Accept';
import Invitation from './views/pages/Auth/Invitation';
import Deactivated from './views/pages/Auth/Deactivated';
import Invalid from './views/pages/Auth/Invalid';
import { PersistGate } from 'redux-persist/integration/react';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import { persistor, store } from 'store';
import history from 'helpers/history';
import socket from 'helpers/socket';
import AuthenticateRoute from 'components/AuthenticateRoute';
import Notifications from 'components/Notifications';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import AlertPopup from 'components/AlertPopup';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Terms from 'views/pages/Terms';
import Privacy from 'views/pages/Privacy';
import TooltipForChart from 'components/TooltipForChart';
import TooltipForChartHandler from 'components/TooltipForChart/TooltipForChartHandler';
// import WindowFocusHandler from './components/WindowFocusHandler';
import useInactivity from './helpers/useInactivity';
import { showProfileUser } from 'store/actions/authActions';
import Loading from 'components/Loading';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import MacroOnBoarding from 'views/pages/MacroOnBoarding';
import ViewContract from 'views/pages/DealDesk/ViewContract';

const App = () => {
  const dispatch = useDispatch();

  useInactivity();
  const authToken = useSelector(({ auth }) =>
    get(auth, 'user.auth_token', null)
  );
  const [isMeLoading, setMeLoading] = useState(false);
  const loggedInUser = useSelector(({ auth }) => get(auth, 'user', null));
  const isLoggedIn = useSelector(({ auth }) => get(auth, 'isLoggedIn', true));
  const isActiveSession = useSelector(({ auth }) =>
    get(auth, 'isActiveSession', true)
  );

  const isRegistrationFlow = useGetFieldFromObjects(
    'auth',
    'isRegistrationFlow',
    false
  );
  useEffect(() => {
    // if auth token changes, then call socket start or stop method based on login or logout
    if (authToken && get(loggedInUser, 'id') !== get(socket, 'user.id')) {
      // only start socket if we have authToken and we haven't started socket for that user
      // if user changes then start socket again, start socket method automatically stop past socket
      socket.start(loggedInUser);
    }
    if (!authToken) {
      // if there is no auth token means user is logged out then stop listening to socket
      socket.stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, loggedInUser]);

  useEffect(() => {
    if (isLoggedIn && !isRegistrationFlow && isActiveSession) {
      setMeLoading(true);
      dispatch(showProfileUser()).finally(() => {
        setMeLoading(false);
      });
    }
  }, [dispatch, isActiveSession, isLoggedIn, isRegistrationFlow]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <>
        {isMeLoading ? (
          <div className="main-root-loading">
            <Loading />
          </div>
        ) : (
          <Router history={history}>
            <Switch>
              <Route
                path="/auth/invitations/invite"
                render={props => <Invitation {...props} />}
              />
              <Route
                exact
                path="/auth/invitations/deactivated"
                render={props => <Deactivated {...props} />}
              />
              <Route
                exact
                path="/auth/invitations/accept"
                render={props => <Accept {...props} />}
              />
              <Route
                exact
                path="/auth/invitations/invalid"
                render={props => <Invalid {...props} />}
              />
              <Route
                exact
                path="/admin/onboarding/:currentStep"
                render={props => <MacroOnBoarding {...props} />}
              />
              <AuthenticateRoute path="/admin" component={AdminLayout} />
              <Route path="/terms" render={props => <Terms {...props} />} />
              <Route path="/privacy" render={props => <Privacy {...props} />} />
              <Route
                path="/view-contract"
                render={props => <ViewContract {...props} />}
              />
              <Route path="/" render={props => <AuthLayout {...props} />} />
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
        )}{' '}
      </>
    </PersistGate>
  );
};

ReactDOM.render(
  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
    <Provider store={store}>
      <Notifications ref={NotificationHandler.setRef} />
      <AlertPopup ref={AlertPopupHandler.setRef} />
      <TooltipForChart ref={TooltipForChartHandler.setRef} />
      {/*<WindowFocusHandler>*/}
      <App />
      {/*</WindowFocusHandler>*/}
      <div id="portal-drag-drop" />
    </Provider>
  </Worker>,
  document.getElementById('root')
);
