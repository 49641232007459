import AuthHeader from 'components/Headers/AuthHeader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  doResetPassword,
  toggleEmailVerifyError,
} from 'store/actions/authActions';
// nodejs library that concatenates classes
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
// reactstrap components
import { Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';
import classes from './ResetPassword.module.scss';
// core components
import { FormikInput } from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { validatePassword } from 'helpers/constants';
import history from 'helpers/history';
import AuthCardHeader from 'components/Headers/AuthCardHeader';

const ResetPassword = props => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   dispatch(toggleEmailVerifyError());
  // }, [dispatch]);

  const registerSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Your password should contain at least 8 characters.')
      .test(
        'validate-password',
        'Your password should contain a combination of numbers, upper and lower case letters, and special characters.',
        validatePassword
      )
      .required('Your password should contain at least 8 characters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'The passwords you entered don’t match. ')
      .required('Confirm Password is required'),
  });

  const renderForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  }) => {
    return (
      <Form>
        <FormikInput
          name="password"
          placeholder="Create Password"
          type="password"
          leftIcon={<i className="fas fa-lock"></i>}
        />

        <FormikInput
          name="password_confirmation"
          placeholder="Confirm Password"
          type="password"
          leftIcon={<i className="fas fa-lock"></i>}
        />

        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="submit"
            block
            loading={loading}
          >
            Reset Password
          </Button>
        </div>
      </Form>
    );
  };

  const params = queryString.parse(props.location.search);

  if (auth.emailVerify.isError) {
    setTimeout(() => {
      dispatch(toggleEmailVerifyError());
    }, 5000);
  }

  return (
    <>
      <AuthHeader title="" lead="" />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={classes.card}>
              <CardBody className="px-lg-5 py-lg-5">
                {auth.emailVerify.isError === true && (
                  <Alert
                    text={auth.emailVerify.message}
                    dismiss={true}
                    fade={true}
                    color="danger"
                    className={classes.alert}
                    toggle={() => dispatch(toggleEmailVerifyError())}
                  />
                )}
                <AuthCardHeader
                  head="Reset Password"
                  title="Create a new password"
                />
                <Formik
                  initialValues={{
                    email: params.email,
                    token: params.token,
                    password: '',
                    password_confirmation: '',
                  }}
                  validationSchema={registerSchema}
                  enableReinitialize
                  onSubmit={async values => {
                    const data = {
                      email: values.email,
                      token: values.token,
                      password: values.password,
                      password_confirmation: values.password_confirmation,
                    };

                    setLoading(true);
                    await dispatch(doResetPassword(data));
                    history.push('/auth/login');
                    setLoading(false);
                  }}
                >
                  {renderForm}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
