import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_COMPANY_INPROGRESS',
  'FETCH_COMPANY_SUCCESS',
  'FETCH_COMPANY_FAILURE',
  'UPDATE_COMPANY_INPROGRESS',
  'UPDATE_COMPANY_SUCCESS',
  'UPDATE_COMPANY_FAILURE',
  'FETCH_COMPANY_METADATA_INPROGRESS',
  'FETCH_COMPANY_METADATA_SUCCESS',
  'FETCH_COMPANY_METADATA_FAILURE'
);
