import React from 'react';
import Input from 'components/FormFields/Input';
import classes from '../StoryModal.module.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
const StoryName = ({
  editField,
  storyName,
  updateStoryTitle,
  onClick,
  isEditAllowed,
}) => {
  return (
    <>
      <h4 id="storyModalStoryName" className={classnames('mt-0')}>
        Task Name
      </h4>
      {editField === 'story name' ? (
        <Input
          autoFocus
          defaultValue={storyName}
          onBlur={updateStoryTitle}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              updateStoryTitle(event);
            }
          }}
        />
      ) : (
        <p
          className={classnames(isEditAllowed && classes['hover-hand'])}
          onClick={() => isEditAllowed && onClick()}
        >
          {storyName}
        </p>
      )}
    </>
  );
};

StoryName.propTypes = {
  editField: PropTypes.string,
  storyName: PropTypes.string,
  updateStoryTitle: PropTypes.func,
  onClick: PropTypes.func,
};

StoryName.defaultProps = {
  storyName: '',
};

export default StoryName;
