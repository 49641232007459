import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import classes from './CustomEditor.module.scss';
import cs from 'classnames';
import 'quill-mention';
import { getSourceList } from 'helpers/mentions';
import CustomToolbar from './CustomToolbar';

const mention = {
  source: getSourceList,
  minChars: 0,
  offsetLeft: -11,
  offsetTop: 7,
  // this is to allow spaces in mentions, if you remove this it will not support spaces.
  allowedChars: /^(?:[a-zA-Z0-9_]+\s?)*$/,
  showDenotationChar: false,
  dataAttributes: ['id', 'value', 'denotationChar', 'link', 'target', 'userId'],
  linkTarget: '',
  mentionListClass: cs(classes['mention-list'], 'mention-list'),
  listItemClass: cs(classes['mention-list-item'], 'mention-list-item'),
  mentionContainerClass: cs(
    classes['mention-list-container'],
    'mention-list-container'
  ),
  renderItem: item => {
    return `<div class="d-flex flex-row flex-nowrap align-items-center overflow-hidden" id="${item.id}">
    <img class="avatar rounded-circle avatar-xs mr-2" src="${item.avatar}" >
    <span>${item.name}</span>
    </div>`;
  },
  onSelect: (item, insert) => {
    insert(item);
  },
};

const Link = Quill.import('formats/link');
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = [
  'http',
  'https',
  'mailto',
  'tel',
  'radar',
  'rdar',
  'smb',
  'sms',
];

class CustomLinkSanitizer extends Link {
  static sanitize(url) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') return sanitizedUrl;

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST.some(function(
      protocol
    ) {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `http://${sanitizedUrl}`;
  }
}

Quill.register(CustomLinkSanitizer, true);

const CustomEditor = props => {
  const {
    value,
    variables = [],
    onChange,
    className,
    error,
    assignRef,
    mentionProps,
    restModules,
    shouldAllowMention = false,
    ToolBarSection = null,
    ...rest
  } = props;

  const [toolbarNode, setToolbarNode] = useState();

  const modules = React.useMemo(() => {
    const config = {
      toolbar: {
        container: toolbarNode,
        handlers: {
          insertCustomTags: value => {
            const quill = assignRef?.current?.getEditor();
            if (quill) {
              const cursorPosition = quill?.getSelection()?.index;
              quill.insertText(cursorPosition, value);
              quill.setSelection(cursorPosition + value.length);
            }
          },
        },
      },
    };
    return config;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolbarNode, assignRef]);

  return (
    <div
      className={cs(className, {
        [classes['is-invalid']]: error,
      })}
    >
      <CustomToolbar
        toolbarRef={setToolbarNode}
        variables={variables}
        assignRef={assignRef}
        ToolBarSection={ToolBarSection}
      />
      <ReactQuill
        value={value}
        onChange={onChange}
        theme="snow"
        modules={{
          toolbar: {
            container: [
              [
                'bold',
                'italic',
                'link',
                'blockquote',
                'code',
                'image',
                {
                  list: 'ordered',
                },
                {
                  list: 'bullet',
                },
              ],
            ],
          },
          ...modules,
          mention: shouldAllowMention
            ? { ...mention, ...mentionProps }
            : undefined,
          ...(restModules ? { ...restModules } : {}),
        }}
        ref={assignRef}
        {...rest}
      />
      {error && <div className={classes['invalid-feedback']}>{error}</div>}
    </div>
  );
};

export default CustomEditor;
