import { get } from 'lodash';
import {
  fetchPartnerList as fetchPartnerListApi,
  removePartner,
  updatePartnerProfile,
  cancelInvitations,
  fetchPartnerDashboard as fetchPartnerDashboardApi,
  invitePartner as invitePartnerApi,
  resendInvitations as resendInvitationsApi,
  fetchCalendlyDetails as fetchCalendlyDetailsApi,
  inviteClient as inviteClientApi,
  fetchPartnersActivity as fetchPartnersActivityApi,
  fetchClientStudiesForGivenPartner as fetchClientStudiesForGivenPartnerApi,
  fetchEarnings as fetchEarningsApi,
  fetchReferralComments as fetchReferralCommentsApi,
  postReferralComment as postReferralCommentApi,
  updateReferralComment as updateReferralCommentApi,
  deleteReferralComment as deleteReferralCommentApi,
  updatePartnerTimeZone as updatePartnerTimeZoneApi,
  postQuestion as postQuestionApi,
} from 'api/partners';
import Types from '../types/partnerTypes';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import { getUniqDataInOrder } from 'helpers/constants';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { readComments } from 'api/story';
import findKey from 'lodash/findKey';
import BacklogTypes from 'store/types/backlogs';

export const fetchPartnerList = (page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchPartnerListApi(page, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTNERS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTNERS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNERS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchPartnerDashboard = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNER_DASHBOARD_BY_USER_ID_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchPartnerDashboardApi();

      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTNER_DASHBOARD_BY_USER_ID_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTNER_DASHBOARD_BY_USER_ID_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNER_DASHBOARD_BY_USER_ID_FAILURE,
        message: error,
      });
    }
  };
};

export const invitePartner = partnerId => {
  return async dispatch => {
    dispatch({
      type: Types.INVITE_PARTNERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await invitePartnerApi(partnerId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.INVITE_PARTNERS_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.INVITE_PARTNERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.INVITE_PARTNERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const postQuestion = data => {
  return async dispatch => {
    dispatch({
      type: Types.POST_QUESTION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await postQuestionApi(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.POST_QUESTION_SUCCESS,
          data: message,
        });

        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.POST_QUESTION_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.POST_QUESTION_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const inviteClient = data => {
  return async dispatch => {
    dispatch({
      type: Types.INVITE_CLIENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await inviteClientApi(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.INVITE_CLIENT_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.INVITE_CLIENT_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.INVITE_CLIENT_FAILURE,
        message: error,
      });
    }
  };
};

export const updatePartnerClient = (data, id) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_PARTNERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updatePartnerProfile(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.UPDATE_PARTNERS_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.UPDATE_PARTNERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.UPDATE_PARTNERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const deletePartner = id => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_PARTNERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await removePartner(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_PARTNERS_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.open({
          message: message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_PARTNERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_PARTNERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updatePartnerTimeZone = timezone => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_PARTNER_TIMEZONE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updatePartnerTimeZoneApi(timezone);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_PARTNER_TIMEZONE_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.UPDATE_PARTNER_TIMEZONE_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_PARTNER_TIMEZONE_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchCalendlyDetails = id => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CALENDLY_DETAILS_BY_USER_ID_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchCalendlyDetailsApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CALENDLY_DETAILS_BY_USER_ID_SUCCESS,
          data: resp || {},
        });
      } else {
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
        dispatch({
          type: Types.FETCH_CALENDLY_DETAILS_BY_USER_ID_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_CALENDLY_DETAILS_BY_USER_ID_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchPartnerActivities = leadId => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PARTNER_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchPartnersActivityApi(leadId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PARTNER_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PARTNER_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PARTNER_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const cancelInvitation = (id, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.CANCEL_INVITATION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await cancelInvitations(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.CANCEL_INVITATION_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;
        dispatch(fetchPartnerList(page, sort, q));
        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CANCEL_INVITATION_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.CANCEL_INVITATION_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const resendInvitation = id => {
  return async dispatch => {
    dispatch({
      type: Types.RESEND_INVITATION_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await resendInvitationsApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.RESEND_INVITATION_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.RESEND_INVITATION_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.RESEND_INVITATION_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const fetchClientStudiesForGivenPartner = filter => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchClientStudiesForGivenPartnerApi(filter);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const clients = get(resp, 'clients');
      if (status) {
        dispatch({
          type: Types.FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_SUCCESS,
          data: clients || [],
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchEarnings = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_EARNINGS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchEarningsApi();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_EARNINGS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_EARNINGS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_EARNINGS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchReferralComments = (
  parentId,
  messageId,
  parentType = 'Company',
  type,
  lead_id,
  toMessageId,
  isInitialCall = false
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.FETCH_REFERRAL_COMMENTS_INPROGRESS,
    });
    if (isInitialCall) {
      dispatch({
        type: Types.FETCH_INTITIAL_REFERRAL_COMMENTS_INPROGRESS,
        data: true,
      });
    }

    try {
      const resp = await fetchReferralCommentsApi(
        parentId,
        messageId,
        type,
        lead_id,
        toMessageId,
        parentType
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const records = get(resp, 'data', []);

      if (status) {
        let existingComments = [
          ...get(getState(), 'partner.referralComments.data.data', []),
        ];
        dispatch({
          type: Types.FETCH_REFERRAL_COMMENTS_SUCCESS,
          data: {
            data: {
              ...resp,
              data: uniqBy(
                [...orderBy([...records, ...existingComments], 'id', 'asc')],
                'id'
              ),
            },
            shouldMakeCall: !!records.length,
          },
        });
        if (isInitialCall) {
          dispatch({
            type: Types.FETCH_INTITIAL_REFERRAL_COMMENTS_INPROGRESS,
            data: false,
          });
        }
      } else {
        dispatch({
          type: Types.FETCH_REFERRAL_COMMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_REFERRAL_COMMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const clearReferralCommentsUnreadFrom = () => ({
  type: Types.SET_REFERRAL_COMMENT_UNREAD_FROM,
  data: null,
});

export const fetchMoreReferralComments = (
  parentId,
  messageId,
  type,
  lead_id,
  toMessageId,
  parentType
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.FETCH_REFERRAL_COMMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchReferralCommentsApi(
        parentId,
        messageId,
        type,
        lead_id,
        toMessageId,
        parentType
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const records = get(resp, 'data', []);
      // using orderBy to store the comments in order of the id so that we have old to new order
      let existingComments = [
        ...get(getState(), 'partner.referralComments.data.data', []),
      ];
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_REFERRAL_COMMENTS_SUCCESS,
          data: {
            data: getUniqDataInOrder([...records, ...existingComments]),
            shouldMakeCall: !!records.length,
          },
        });
      } else {
        dispatch({
          type: Types.FETCH_REFERRAL_COMMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_REFERRAL_COMMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const postCommentForReferral = (
  parentId,
  comment,
  parentType,
  type,
  lead_id
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.POST_REFERRAL_COMMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await postReferralCommentApi(
        parentId,
        comment,
        parentType,
        type,
        lead_id
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data', {});
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        const existingComments = get(
          getState(),
          'partner.referralComments.data.data',
          []
        );
        dispatch({
          type: Types.POST_REFERRAL_COMMENT_SUCCESS,
          data: getUniqDataInOrder([...existingComments, record]),
        });
      } else {
        dispatch({
          type: Types.POST_REFERRAL_COMMENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.POST_REFERRAL_COMMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const updateCommentForReferral = (commentId, comment) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.POST_REFERRAL_COMMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateReferralCommentApi(commentId, comment);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data', {});
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        const existingComments = get(
          getState(),
          'partner.referralComments.data.data',
          []
        );
        dispatch({
          type: Types.POST_REFERRAL_COMMENT_SUCCESS,
          data: getUniqDataInOrder([
            ...existingComments.map(c => (c.id === commentId ? record : c)),
          ]),
        });
      } else {
        dispatch({
          type: Types.POST_REFERRAL_COMMENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.POST_REFERRAL_COMMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteReferralComment = commentId => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.DELETE_REFERRAL_COMMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteReferralCommentApi(commentId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        const existingComments = get(
          getState(),
          'partner.referralComments.data.data',
          []
        );
        dispatch({
          type: Types.DELETE_REFERRAL_COMMENT_SUCCESS,
          data: getUniqDataInOrder([
            ...existingComments.filter(c => c.id !== commentId),
          ]),
        });
        NotificationHandler.open({
          title: 'Comment Deleted Successfully',
          message: message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.DELETE_REFERRAL_COMMENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_REFERRAL_COMMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const updateCommentsFromSocketForCompany = (eventType, comment) => {
  return (dispatch, getState) => {
    const comments = get(getState(), 'partner.referralComments.data.data', []);
    const existingUnreadFrom = get(
      getState(),
      'partner.referralComments.unreadFrom',
      null
    );
    let updatedComments = [];
    let unreadFrom = null;
    switch (eventType) {
      case 'posted': {
        updatedComments = [...comments, comment];
        if (!existingUnreadFrom) {
          unreadFrom = comment.id;
        }
        break;
      }
      case 'edited': {
        updatedComments = [
          ...comments.map(c => (c.id === comment.id ? comment : c)),
        ];
        break;
      }
      case 'deleted': {
        updatedComments = [...comments.filter(c => c.id !== comment.id)];
        break;
      }
      default:
        break;
    }
    dispatch({
      type: Types.SET_REFERRAL_COMMENTS,
      data: {
        updatedComments,
        ...(unreadFrom ? { unreadFrom } : {}),
      },
    });
  };
};

export const markReferralCommentsAsRead = (comments, parentId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.MARK_READ_REFERRAL_COMMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await readComments(comments);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.MARK_READ_REFERRAL_COMMENTS_SUCCESS,
        });
        const sectionData = get(getState(), 'backlog.itemsState.data', {});

        const sectionId = findKey(sectionData, section => {
          const stories = get(section, 'data', []);
          return stories.findIndex(s => s.id === parentId);
        });
        if (sectionId) {
          const stories = get(
            getState(),
            `backlog.itemsState.data.${sectionId}.data`,
            []
          );
          const foundIndex = stories.findIndex(s => s.id === parentId);
          dispatch({
            type: BacklogTypes.UPDATE_STORY_DATA,
            data: {
              items: [
                ...stories.splice(foundIndex, 0, {
                  ...stories[foundIndex],
                  unread_comments:
                    stories[foundIndex].unread_comments - comments.length > 0
                      ? stories[foundIndex].unread_comments - comments.length
                      : 0,
                }),
              ],
              sectionId,
            },
          });
        }
      } else {
        dispatch({
          type: Types.MARK_READ_REFERRAL_COMMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.MARK_READ_REFERRAL_COMMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const clearReferralCommentsData = () => ({
  type: Types.CLEAR_REFERRAL_COMMENTS,
});
