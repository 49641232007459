import { cloneDeepWith, get } from 'lodash';
import Types from '../types/utility';

const initialState = {
  documents: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  uploadDocuments: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  documentDetails: {
    isInProgress: false,
    isError: false,
    status: 0,
    data: {},
    message: '',
  },
  deleteDocument: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  retryUpload: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Utility(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_DOCUMENTS_INPROGRESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          isInProgress: true,
        },
      };
    case Types.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: {
          ...state.documents,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        documents: {
          ...state.documents,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_DOCUMENT_DATA: {
      const existingData = get(state, 'documents.data.data', []);
      const meta = get(state, 'documents.data.meta', []);
      const updatedBatch = action?.data;
      const batchToUpdateIndex = existingData.findIndex(
        b => b.id === updatedBatch.id
      );
      if (batchToUpdateIndex !== -1) {
        existingData[batchToUpdateIndex] = updatedBatch;
      }

      return {
        ...state,
        documents: {
          ...state.documents,
          isInProgress: false,
          status: 1,
          data: { data: [...existingData], meta },
        },
      };
    }
    case Types.UPLOAD_DOCUMENTS_INPROGRESS:
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          isInProgress: true,
        },
      };
    case Types.UPLOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPLOAD_DOCUMENTS_FAILURE:
      return {
        ...state,
        uploadDocuments: {
          ...state.uploadDocuments,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_DOCUMENT_BY_ID_INPROGRESS:
      return {
        ...state,
        documentDetails: {
          ...state.documentDetails,
          isInProgress: true,
        },
      };
    case Types.FETCH_DOCUMENT_BY_ID_SUCCESS:
      return {
        ...state,
        documentDetails: {
          ...state.documentDetails,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DOCUMENT_BY_ID_FAILURE:
      return {
        ...state,
        documentDetails: {
          ...state.documentDetails,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_DOCUMENT_INPROGRESS:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          isInProgress: true,
        },
      };
    case Types.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.RETRY_DOCUMENT_BATCH_UPLOAD_INPROGRESS:
      return {
        ...state,
        retryUpload: {
          ...state.retryUpload,
          isInProgress: true,
        },
      };
    case Types.RETRY_DOCUMENT_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        retryUpload: {
          ...state.retryUpload,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.RETRY_DOCUMENT_BATCH_UPLOAD_FAILURE:
      return {
        ...state,
        retryUpload: {
          ...state.retryUpload,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
