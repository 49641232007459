import { get } from 'lodash';
import {
  addMember,
  fetchMembersList,
  removeMember,
  updateRate,
} from 'api/teams';
import Types from '../types/teamMembers';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const fetchTeamMembersList = (id, page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_TEAM_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchMembersList(id, page, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_TEAM_MEMBERS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_TEAM_MEMBERS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TEAM_MEMBERS_FAILURE,
        message: error,
      });
    }
  };
};

export const addTeamMember = (id, data, filters, shouldFetchList = true) => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_TEAM_MEMBER_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await addMember(id, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.ADD_TEAM_MEMBER_SUCCESS,
          data: resp || {},
        });
        if (shouldFetchList) {
          const { page, sort, q } = filters;
          await dispatch(fetchTeamMembersList(id, page, sort, q));
        }
        NotificationHandler.open({
          title: 'New team member(s) added',
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.ADD_TEAM_MEMBER_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.ADD_TEAM_MEMBER_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const deleteTeamMember = (projectId, userId, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_TEAM_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await removeMember(projectId, userId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_TEAM_MEMBERS_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchTeamMembersList(projectId, page, sort, q));
        NotificationHandler.open({
          title: 'Team member successfully removed',
          message: message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_TEAM_MEMBERS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_TEAM_MEMBERS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updateMemberRate = (id, userId, rate) => {
  return async dispatch => {
    // dispatch({
    //   type: Types.FETCH_PROJECT_METADATA_INPROGRESS,
    //   payload: { type },
    // });
    try {
      const resp = await updateRate(id, userId, { rate });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const data = get(resp, 'data');

        dispatch({
          type: Types.UPDATE_MEMBER_DETAILS,
          id: userId,
          data: { project_rate: rate },
        });
      } else {
        // dispatch({
        //   type: Types.FETCH_PROJECT_METADATA_FAILURE,
        //   payload: { type, message },
        // });
      }
    } catch (e) {
      // dispatch({
      //   type: Types.FETCH_PROJECT_METADATA_FAILURE,
      //   payload: { type },
      // });
    }
  };
};
