import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_ASSIGNMENT_TYPES_INPROGRESS',
  'FETCH_ASSIGNMENT_TYPES_SUCCESS',
  'FETCH_ASSIGNMENT_TYPES_FAILURE',
  'ASSIGNMENT_TYPES_CHANGE',
  'EDIT_ASSIGNMENT_TYPE_INPROGRESS',
  'EDIT_ASSIGNMENT_TYPE_SUCCESS',
  'EDIT_ASSIGNMENT_TYPE_FAILURE',
  'DEACTIVATE_ASSIGNMENT_TYPE_INPROGRESS',
  'DEACTIVATE_ASSIGNMENT_TYPE_SUCCESS',
  'DEACTIVATE_ASSIGNMENT_TYPE_FAILURE',
  'ACTIVATE_ASSIGNMENT_TYPE_INPROGRESS',
  'ACTIVATE_ASSIGNMENT_TYPE_SUCCESS',
  'ACTIVATE_ASSIGNMENT_TYPE_FAILURE',
  'CREATE_ASSIGNMENT_TYPE_INPROGRESS',
  'CREATE_ASSIGNMENT_TYPE_SUCCESS',
  'CREATE_ASSIGNMENT_TYPE_FAILURE',
  'DELETE_ASSIGNMENT_TYPE_INPROGRESS',
  'DELETE_ASSIGNMENT_TYPE_SUCCESS',
  'DELETE_ASSIGNMENT_TYPE_FAILURE'
);
