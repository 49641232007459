import Request from './request';
export const fetchSupportStaff = async (id, page, sort, query, params = {}) => {
  return Request.call({
    url: `/support-staff/${id}`,
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      ...params,
    },
  });
};
