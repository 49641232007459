import Types from '../types/timeSpent';

const initialState = {
  timeSpentState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

const TimeSpent = (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_TIME_SPENT_INPROGRESS:
      return {
        ...state,
        timeSpentState: {
          ...state.timeSpentState,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIME_SPENT_SUCCESS:
      return {
        ...state,
        timeSpentState: {
          ...state.timeSpentState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_TIME_SPENT_FAILURE:
      return {
        ...state,
        timeSpentState: {
          ...state.timeSpentState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};

export default TimeSpent;
