import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import classes from './SupportTeam.module.scss';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import ClientRequestModal from 'views/pages/dashboards/Fields/ClientRequestModal';
import { postCommentForStory } from 'store/actions/Story/comments';
import { get } from 'lodash';

const SupportTeam = ({
  handelMessageCall = () => {},
  showSnapshot = false,
}) => {
  const [openClientRequestModal, setOpenClientRequestModal] = useState(false);
  const companyId = useSelector(({ auth }) => get(auth, 'user.company.id'));
  const isPosting = useSelector(({ story }) =>
    get(story, 'comments.postStoryComment.isInProgress', false)
  );

  const dispatch = useDispatch();
  const strikeTeam = useGetFieldFromObjects(
    'supportStaffs',
    'supportStaffsState.data.data.support_staff',
    []
  );

  const collaborators = strikeTeam.map(member => {
    return {
      name: member?.name,
      role: member?.role,
      avatar: member?.avatar,
    };
  });

  const handlePostGeneralMessage = async values => {
    const { name = '', description = '' } = values;
    const resp = await dispatch(
      postCommentForStory(companyId, description, 'Company', {
        type: 'general',
        subject: name,
      })
    );
    handelMessageCall();
    return resp;
  };

  if (collaborators.length === 0) {
    return <div></div>;
  }

  return (
    <>
      <ClientRequestModal
        submitProps={{ loading: isPosting }}
        onSubmit={handlePostGeneralMessage}
        handleClose={() => setOpenClientRequestModal(false)}
        isOpen={openClientRequestModal}
      />
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <h3>Your Strike Team is here to help!</h3>
          {!showSnapshot && (
            <p>For general questions, please select Message the Team below.</p>
          )}

          {collaborators.map(({ avatar, name, role }, index) => {
            return (
              <div className={classes.teamMember} key={index}>
                <div className={classes.info}>
                  <img src={avatar} />
                  <p>{name}</p>
                </div>
                <div className={classes.position}>
                  <p>{role}</p>
                </div>
              </div>
            );
          })}
          {!showSnapshot && (
            <Button
              outline
              leftIcon={<i className="fa fa-envelope" aria-hidden="true"></i>}
              color="primary"
              className={classes.button}
              onClick={() => setOpenClientRequestModal(true)}
            >
              Message the Team
            </Button>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default SupportTeam;
