import React from 'react';
import classes from './Greeting.module.scss';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import split from 'lodash/split';
import moment from 'moment';
import cx from 'classnames';
import useBreakpoint from 'helpers/useBreakPoint';

const Greeting = () => {
  const isMobile = useBreakpoint('sm', 'down');
  const isTablet = useBreakpoint('md', 'down');
  const userDetails = useSelector(state => get(state, 'auth.user'));
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const name =
    get(userDetails, 'first_name') || split(get(userDetails, 'name'), ' ')[0];

  const greeting = () => {
    const time = moment()
      .tz(userTimezone)
      .hour();
    if (0 <= time && time < 12) return 'Morning';
    else if (12 <= time && time < 18) return 'afternoon';
    else return 'evening';
  };
  return (
    <div className={cx('d-flex align-items-center', classes.greeting)}>
      <div className={cx(classes.text, { [classes.mobileMargin]: isMobile })}>
        <h1 className={cx('text-capitalize', isMobile && 'text-center')}>
          good {greeting()}, {name}!
        </h1>
        {!isTablet && (
          <p>
            Welcome to your personal dashboard! Check out open tasks and recent
            activity below to jump in and collaborate with your team.
          </p>
        )}
      </div>
    </div>
  );
};

Greeting.propTypes = {};
export default Greeting;
