import Button from 'components/Button';
import React from 'react';
import { Card, CardBody, Badge } from 'reactstrap';
import cx from 'classnames';
import classes from './DocumentCard.module.scss';

const DocumentCard = ({
  data,
  onAddDocument,
  handleOpenPreviewModal,
  canUpload,
}) => {
  const { status, id, short_name = '', documents = [] } = data;
  const mapDocumentStatus = status => {
    return status?.toLowerCase()?.replace(/ /g, '_');
  };
  return (
    <Card className={classes.card}>
      <CardBody
        onClick={() => {
          if (!canUpload) return;
          handleOpenPreviewModal();
        }}
        className={classes.cardBody}
      >
        <img src={require('assets/img/icons/add-doc-icon.svg')} />
        <div className={classes.infoWrapper}>
          <div className={classes.info}>
            <div className={classes.title}>{short_name}</div>
            <div
              className={cx(classes.wrapper, {
                [classes.hideButton]: !canUpload,
              })}
            >
              <div className={classes.statusWrapper}>
                <p className={classes.label}>status</p>
                <p
                  className={cx(
                    classes.status,
                    classes[mapDocumentStatus(status?.label ?? 'Not started')]
                  )}
                >
                  {status?.label || 'Not Started'}
                </p>
              </div>
              {canUpload && (
                <>
                  <div className={classes.action}>
                    {status !== 'Complete' && (
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          onAddDocument(id);
                        }}
                        rightIcon={
                          <i className="fa fa-upload" aria-hidden="true"></i>
                        }
                        color="primary"
                        className={classes.addButton}
                      >
                        Add Docs
                      </Button>
                    )}
                  </div>
                  <div className={classes.icon}>
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DocumentCard;
