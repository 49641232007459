import React from 'react';
import { Card } from 'reactstrap';
import classes from './DeclineExitModal.module.scss';
import cx from 'classnames';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import { doLogout } from 'store/actions/authActions';

const DeclineExitModal = ({ isOpen = false, email = '', onClose }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={cx(classes.declineExitModal, {
        [classes.openDeclineExitModal]: isOpen,
      })}
    >
      <Card className={classes.card}>
        <div className={classes.image}>
          <img
            width={88}
            height={88}
            src={require('assets/img/icons/success-alert-icon.svg')}
            alt="check-icon"
          />
        </div>

        <div className={classes.alertText}>
          <h3>Success!</h3>
          <p>
            This agreement has been forwarded to
            <b> {email}.</b>
          </p>
          <p className="mb-4">
            We’ll send you an email regarding next steps once this agreement has
            been signed. If you have any questions before then, feel free to
            contact your assigned Strike Expert.
          </p>
          <div>
            <Button
              onClick={() => {
                onClose();
                dispatch(doLogout(false, true, true));
                window.location.href = 'https://www.striketax.com/';
              }}
              color="success"
            >
              Exit this page
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DeclineExitModal;
