import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import toNumber from 'lodash/toNumber';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
// reactstrap components
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ProfileHeader from 'components/Headers/ProfileHeader.js';
import ProfileDetails from './ProfileDetails';
// import AvailabilityStats from './AvailabilityStats';
// import TasksStats from './TasksStats';
// core components
import { fetchStory } from 'store/actions/Story/details';
// import AssignedProjectsWidget from 'views/widgets/AssignedProjects';
// import TimeSpent from 'views/widgets/TimeSpent';
// import ProfileActivity from './ProfileActivity';
import StoryModal from '../Backlogs/Stories/StoryModal';
import analytics, { analyticsConstants } from 'helpers/analytics';
import Loading from 'components/Loading';
import classes from './profile.module.scss';
import ManageEmailPreferences from './ManageEmailPreferences';
import Helmet from 'components/Helmet';

const Profile = props => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_profile,
      ...rest,
    });
  };

  const auth = useSelector(({ auth }) => auth);
  const currentUserId = get(auth, 'user.id', '');

  const pathname = get(props, 'location.pathname');
  let profileId = get(props, 'match.params.id');
  if (!profileId) profileId = currentUserId;

  const details = useSelector(({ profile }) =>
    JSON.parse(JSON.stringify(get(profile, 'details.data', {})))
  );

  const roles = get(details, 'roles', []);
  const isPartner = get(auth, 'user.is_partner', false);
  const isClient = get(auth, 'user.is_client', false);

  const isPartnerAdmin = !!roles.find(r => r.name === 'Partner_Administrator');

  const loading = useSelector(({ profile }) =>
    get(profile, 'details.isInProgress', false)
  );
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const history = useHistory();
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const [storyId, setStoryId] = useState('');
  const [projectId, setProjectId] = useState('');
  const [count, setCount] = useState(0);
  const [isStoryModalOpen, setIsStoryModalOpen] = useState(false);

  const getStoryDetail = async story => {
    const { status, data } = await dispatch(fetchStory(story));

    if (status === 1) {
      setStoryId(story);
      setProjectId(get(data, 'initiative.id', ''));
      analyticsSendEventStory({
        action: analyticsConstants.action.open_story,
        opened_from: 'User Profile Recent Activity',
      });
      setIsStoryModalOpen(true);
    }
  };

  useEffect(() => {
    analyticsSendEvent({ action: analyticsConstants.action.view_user_profile });
  }, []);

  const reload = () => setCount(count + 1);
  const getTitle = () => {
    if (isPartner) return 'Partner Portal - Profile';
    if (isClient) return 'Client Portal - Profile';
    return 'Strike Portal - Profile';
  };
  return (
    <>
      <Helmet title={getTitle()} />
      {loading && <Loading wrapperClass={classes.loading} />}
      <ProfileHeader userId={profileId} name={get(details, 'name')} />
      <Container className="mt--6" fluid>
        <Row className="align-items-start">
          <Col className="order-xl-1 flex-fill mb-3" xl="4">
            <ProfileDetails
              userId={profileId}
              mode="view"
              analyticsSendEvent={analyticsSendEvent}
            />
            {isPartnerAdmin && details.show_earning && (
              <Card className={classes.referralDetails}>
                <CardBody className={classes.cardBody}>
                  <h3>My Referral Fees</h3>
                  <div className="mb-3">
                    <p>Direct Client Referral: </p>
                    <h4>{details.referral_fee}</h4>
                  </div>
                  <div>
                    <p>Override Referral: </p>
                    <h3>{details.override_fee}</h3>
                  </div>
                </CardBody>
              </Card>
            )}
            {/* <AssignedProjectsWidget userId={userId} /> */}
          </Col>
          {pathname?.includes('/admin/profile') && isPartnerAdmin && (
            <Col className="order-xl-2 flex-fill mb-3" xl="6">
              <ManageEmailPreferences />
            </Col>
          )}

          {/* <Col className="order-xl-2 d-flex flex-column" xl="8">
           <Row>
              <Col lg="6">
                <AvailabilityStats userId={userId} />
              </Col>
              <Col lg="6">
                <TasksStats userId={userId} />
              </Col>
            </Row>
           <Row>
              <Col lg="12">
                <TimeSpent userId={userId} />
              </Col>
            </Row>
           <ProfileActivity
              userId={userId}
              onStoryClick={url => {
                getStoryDetail(get(queryString.parseUrl(url), 'query.story'));
              }}
            /> 
           </Col> */}
        </Row>
        {isStoryModalOpen && (
          <StoryModal
            storyId={toNumber(storyId)}
            projectId={toNumber(projectId)}
            isOpen={isStoryModalOpen}
            closeModal={() => {
              setIsStoryModalOpen(false);
              history.push(`?`);
            }}
            currentTab={get(queryProps, 'tab', 'comments')}
            onChange={() => reload()}
          />
        )}
      </Container>
    </>
  );
};

export default Profile;
