import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classes from './InputTime.module.scss';
import caretDownIcon from 'assets/img/icons/caret-down.svg';
import ReactDatetime from 'react-datetime';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import useBreakPoint from 'helpers/useBreakPoint';
import cs from 'classnames';
import useClickAway from 'react-use/lib/useClickAway';
import InputHour from '../InputHour';
import InputMinuteSecond from '../InputMinuteSecond';
import get from 'lodash/get';
import { setUserPreference } from 'store/actions/profile';

const InputTime = props => {
  const dispatch = useDispatch();

  const DateRef = useRef(null);
  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));
  const timeFormat = useSelector(({ timer }) => get(timer, 'timeFormat'));
  const isMobile = useBreakPoint('xs', 'down');

  const formatDate = 'MM/DD/YYYY';

  const [showDatePicker, setShowDatePicker] = useState(false);

  const today = moment().tz(timezone);

  const currentDateTime = moment.tz(props.time, timezone);

  const times = {
    hours: currentDateTime.format('HH'),
    minutes: currentDateTime.format('mm'),
    seconds: currentDateTime.format('ss'),
    AMPM: currentDateTime.format('A'),
  };

  useClickAway(DateRef, () => {
    setShowDatePicker(!showDatePicker);
  });

  const onChange = (field, value) => {
    switch (field) {
      case 'hours':
        currentDateTime.set('hour', value);
        break;

      case 'minutes':
        currentDateTime.set('minute', value);

        break;

      case 'seconds':
        currentDateTime.set('second', value);
        break;

      default:
        break;
    }

    props.onChange(currentDateTime);
  };

  const handleChangeAMPM = () => {
    dispatch(
      setUserPreference({
        category: 'timelog',
        key: 'time_format',
        value: '12-hour',
      })
    );

    if (timeFormat === 24) return true;

    const hour = times.hours * 1;

    if (hour === 0) {
      onChange('hours', 12);
    } else if (hour === 12) {
      onChange('hours', 0);
    } else if (times.AMPM === 'AM') {
      const hours = hour < 12 ? hour + 12 : hour;
      onChange('hours', hours);
    } else {
      const hours = hour > 12 ? hour - 12 : hour;
      onChange('hours', hours);
    }
  };

  return (
    <div
      className={cs(classes.root, { [classes.error]: props.error })}
      id={props.id}
    >
      <div className={classes.label}>
        <span>{props.label}</span>
        <button
          onClick={e => {
            e.preventDefault();
            setShowDatePicker(!showDatePicker);
          }}
        >
          {today.format(formatDate) === currentDateTime.format(formatDate)
            ? 'Today'
            : currentDateTime.format(formatDate)}{' '}
          <img src={caretDownIcon} alt="open datepicker" />
        </button>

        {showDatePicker && (
          <div
            ref={DateRef}
            className={cs(classes.dropdownCalendar, {
              [classes.left]: !isMobile && props.position === 'left',
              [classes.right]: isMobile || props.position === 'right',
            })}
          >
            <ReactDatetime
              dateFormat={formatDate}
              timeFormat={false}
              value={currentDateTime}
              input={false}
              onChange={date => {
                props.onChange(date);
              }}
              className={cs(classes.calendar)}
              id={props.id}
              isValidDate={current => {
                if (props.before) {
                  const before = moment.tz(props.before, timezone);
                  return current.isBefore(before) && current.isBefore(today);
                } else if (props.after) {
                  const after = moment
                    .tz(props.after, timezone)
                    .subtract(1, 'day');
                  return current.isAfter(after) && current.isBefore(today);
                }
              }}
            />
          </div>
        )}
      </div>
      <div className={classes.timeContent}>
        <InputHour
          name="hours"
          value={times.hours}
          timeFormat={timeFormat}
          onChange={data => {
            onChange('hours', data);
          }}
          AMPM={times.AMPM}
        />
        :
        <InputMinuteSecond
          key="minutes"
          name="minutes"
          value={times.minutes}
          onChange={data => {
            onChange('minutes', data);
          }}
        />
        :
        <InputMinuteSecond
          key="seconds"
          name="seconds"
          value={times.seconds}
          onChange={data => {
            onChange('seconds', data);
          }}
        />
        <button onClick={handleChangeAMPM} type="button">
          {timeFormat === 24 ? '24 HR' : times.AMPM}
        </button>
      </div>
    </div>
  );
};

InputTime.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  position: PropTypes.string,
  time: PropTypes.string,
  before: PropTypes.string,
  after: PropTypes.string,
};

InputTime.defaultProps = {
  before: null,
  after: null,
  time: '',
  onChange: () => {},
  position: 'left',
};

export default InputTime;
