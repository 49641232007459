import Types from 'store/types/projectDashboard';
import get from 'lodash/get';

const initialState = {
  budgetSpent: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  timeLoggedThisWeek: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  lastWeekHours: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  storiesCompleted: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  timeLogGraph: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  clientTasks: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  tasksScreenClientTasks: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  deliverables: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_PROJECT_SPENT_INPROGRESS:
      return {
        ...state,
        budgetSpent: {
          ...state.budgetSpent,
          isInProgress: true,
        },
      };
    case Types.FETCH_PROJECT_SPENT_SUCCESS:
      return {
        ...state,
        budgetSpent: {
          ...state.budgetSpent,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_PROJECT_SPENT_FAILURE:
      return {
        ...state,
        budgetSpent: {
          ...state.budgetSpent,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_TIME_LOG_CURRENT_WEEK_INPROGRESS:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIME_LOG_CURRENT_WEEK_SUCCESS:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_TIME_LOG_CURRENT_WEEK_FAILURE:
      return {
        ...state,
        timeLoggedThisWeek: {
          ...state.timeLoggedThisWeek,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_LAST_WEEK_HOURS_INPROGRESS:
      return {
        ...state,
        lastWeekHours: {
          ...state.lastWeekHours,
          isInProgress: true,
        },
      };
    case Types.FETCH_LAST_WEEK_HOURS_SUCCESS:
      return {
        ...state,
        lastWeekHours: {
          ...state.lastWeekHours,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_LAST_WEEK_HOURS_FAILURE:
      return {
        ...state,
        lastWeekHours: {
          ...state.lastWeekHours,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_COMPLETED_STORIES_INPROGRESS:
      return {
        ...state,
        storiesCompleted: {
          ...state.storiesCompleted,
          isInProgress: true,
        },
      };
    case Types.FETCH_COMPLETED_STORIES_SUCCESS:
      return {
        ...state,
        storiesCompleted: {
          ...state.storiesCompleted,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_COMPLETED_STORIES_FAILURE:
      return {
        ...state,
        storiesCompleted: {
          ...state.storiesCompleted,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_TIME_LOG_GRAPH_INPROGRESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIME_LOG_GRAPH_SUCCESS:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_TIME_LOG_GRAPH_FAILURE:
      return {
        ...state,
        timeLogGraph: {
          ...state.timeLogGraph,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_STUDY_TASKS_INPROGRESS:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: true,
        },
      };
    case Types.FETCH_STUDY_TASKS_SUCCESS:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'clientTasks.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_TASKS_SCREEN_STUDY_TASKS_INPROGRESS:
      return {
        ...state,
        tasksScreenClientTasks: {
          ...state.tasksScreenClientTasks,
          isInProgress: true,
        },
      };
    case Types.FETCH_TASKS_SCREEN_STUDY_TASKS_SUCCESS:
      return {
        ...state,
        tasksScreenClientTasks: {
          ...state.tasksScreenClientTasks,
          isInProgress: false,
          // ...state.tasksScreenClientTasks.data,
          [action.key]: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(
                    state,
                    ['tasksScreenClientTasks', action.key, 'data'],
                    []
                  )),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_TASKS_SCREEN_STUDY_TASKS_FAILURE:
      return {
        ...state,
        tasksScreenClientTasks: {
          ...state.tasksScreenClientTasks,
          isInProgress: false,
          isError: true,
        },
      };
    case Types.FETCH_STUDY_TASKS_FAILURE:
      return {
        ...state,
        clientTasks: {
          ...state.clientTasks,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_STUDY_DELIVERABLES_INPROGRESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: true,
        },
      };
    case Types.FETCH_STUDY_DELIVERABLES_SUCCESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          data: action.data,
        },
      };
    case Types.FETCH_ALL_STUDY_DELIVERABLES_SUCCESS:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          data: {
            ...action.data,
            data: [
              ...(get(action, 'data.meta.current_page', 1) === 1
                ? []
                : get(state, 'deliverables.data.data', [])),
              ...get(action, 'data.data', []),
            ],
          },
        },
      };
    case Types.FETCH_STUDY_DELIVERABLES_FAILURE:
      return {
        ...state,
        deliverables: {
          ...state.deliverables,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
};
