import React from 'react';

const UnpinIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5252_11250)">
        <path
          d="M11.1801 16.9404L8.53826 20.0581"
          stroke="#76769D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.81528 13.242L15.5448 20.639L16.4747 19.5416C16.6712 19.3095 16.799 19.0272 16.8438 18.7264C16.8886 18.4256 16.8487 18.1183 16.7284 17.839L16.094 16.3373C15.9737 16.058 15.9337 15.7507 15.9785 15.4499C16.0233 15.1491 16.1512 14.8668 16.3476 14.6347L18.8626 11.6667L19.4862 12.195C19.8169 12.4753 20.2454 12.6127 20.6775 12.577C21.1095 12.5413 21.5097 12.3354 21.7899 12.0047C22.0702 11.6739 22.2076 11.2454 22.1719 10.8133C22.1362 10.3813 21.9303 9.98112 21.5996 9.70086L16.6113 5.47399C16.2805 5.19373 15.852 5.05634 15.42 5.09204C14.9879 5.12774 14.5877 5.33361 14.3075 5.66435C14.0272 5.9951 13.8898 6.42363 13.9255 6.85567C13.9612 7.28772 14.1671 7.68789 14.4978 7.96815L15.1214 8.49651L12.6064 11.4645C12.4097 11.6965 12.1522 11.8689 11.8628 11.9625C11.5735 12.056 11.2638 12.067 10.9685 11.9942L9.38308 11.6149C9.08783 11.5421 8.77811 11.5531 8.48877 11.6467C8.19942 11.7402 7.94191 11.9127 7.74519 12.1446L6.81528 13.242Z"
          stroke="#76769D"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5252_11250">
          <rect
            width="19.615"
            height="19.615"
            fill="white"
            transform="translate(12.6797) rotate(40.2764)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UnpinIcon;
