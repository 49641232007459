import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';
import classes from 'views/pages/ClientProfile/CreditBank/CreditBank.module.scss';
import RSelect from 'components/FormFields/RSelect';
import { getOptions } from 'helpers/clientProfile';
import FormikInput from 'components/FormFields/Input/FormikInput';
import className from 'classnames';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const taxCreditObject = {
  year: '',
  federal_tax_credits: '',
  state_tax_credits: '',
  total_tax_credits: '',
};

const TaxCredits = ({ values, setFieldValue }) => {
  const [isRemoveCreditLoading, setRemoveCreditLoading] = useState(false);
  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );

  const editProfileLoading = useSelector(({ clientProfile }) =>
    get(clientProfile, 'editClientProfile.isInProgress')
  );

  const addTaxCredit = () => {
    const valueArray = values.credit_amounts.length
      ? values.credit_amounts
      : [taxCreditObject];
    const newTaxCredit = [...valueArray];
    newTaxCredit.push(taxCreditObject);
    setFieldValue('credit_amounts', newTaxCredit);
  };

  const removeTaxCredits = index => () => {
    if (values.credit_amounts.length > 1) {
      setRemoveCreditLoading(true);
      let taxCredit = [...values.credit_amounts];
      let newTaxCredit = [
        ...taxCredit.slice(0, index),
        ...taxCredit.slice(index + 1),
      ];
      setFieldValue('credit_amounts', newTaxCredit);
    }
  };

  useEffect(() => {
    if (!isRemoveCreditLoading || !editProfileLoading) {
      setRemoveCreditLoading(false);
    }
  }, [editProfileLoading, isRemoveCreditLoading]);

  const renderCredits = () => {
    const credits =
      values.credit_amounts && values.credit_amounts.length
        ? values.credit_amounts
        : [taxCreditObject];
    return credits.map((val, index) => {
      return (
        <Row className={classes.row} key={index}>
          <div className={classes.textInputRightMargin}>
            <RSelect
              name={`credit_amounts[${index}].year`}
              options={getOptions(yearsList)}
              getOptionLabel={opt => opt.label}
              getOptionValue={opt => opt.value}
              placeholder="Year"
              value={val.year}
              onChange={value => {
                setFieldValue(
                  `credit_amounts[${index}].year`,
                  value ? value : null
                );
              }}
            />
          </div>
          <FormikInput
            name={`credit_amounts[${index}].federal_tax_credits`}
            type="text"
            placeholder="Federal Credits"
            groupClassName={classes.textInputRightMargin}
            onBlur={() => {
              setFieldValue(
                `credit_amounts[${index}].total_tax_credits`,
                val.state_tax_credits
                  ? +val.federal_tax_credits + +val.state_tax_credits
                  : +val.federal_tax_credits
              );
            }}
          />
          <FormikInput
            name={`credit_amounts[${index}].state_tax_credits`}
            type="text"
            placeholder="State Credits"
            groupClassName={classes.textInputRightMargin}
            onBlur={() => {
              setFieldValue(
                `credit_amounts[${index}].total_tax_credits`,
                +val.federal_tax_credits
                  ? +val.federal_tax_credits + +val.state_tax_credits
                  : +val.state_tax_credits
              );
            }}
          />
          <FormikInput
            name={`credit_amounts[${index}].total_tax_credits`}
            type="text"
            disabled
            placeholder="Total Credits"
            groupClassName={classes.textInputRightMargin}
          />
          <div
            className={className(classes.removeIcon, {
              [classes.disabled]:
                !(values.credit_amounts.length > 1) || isRemoveCreditLoading,
            })}
          >
            <span onClick={removeTaxCredits(index)}>
              <i className="fas fa-minus-circle p-0 mb-2" />
            </span>
          </div>
        </Row>
      );
    });
  };

  return (
    <div className={className('mb-0', classes.textInputRightMargin)}>
      <Row className={className('mb-0', classes.row)}>
        <div
          className={className('flex-column align-items-initial', classes.row)}
        >
          <Row className={className('mb-0', classes.row)}>
            <label className="form-control-label">R&D Tax Credits</label>
          </Row>
          {renderCredits()}
        </div>
      </Row>
      <Row className={className('flex-row mb-0', classes.row)}>
        <div className={className('mb-0', classes.textInputRightMargin)}>
          <Button
            outline
            color="primary"
            className={className('p-1 w-50', classes.button)}
            onClick={addTaxCredit}
          >
            + Add R&D Tax Credits
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default TaxCredits;
