import React, { useEffect, useState } from 'react';

const DealDeskCounter = ({ item }) => {
  const [time, setTime] = useState('');

  const convertTimeToDisplay = millisec => {
    var seconds = (millisec / 1000).toFixed(0);
    var minutes = Math.floor(seconds / 60);
    var hours = '';
    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = hours >= 10 ? hours : '0' + hours;
      minutes = minutes - hours * 60;
      minutes = minutes >= 10 ? minutes : '0' + minutes;
    }

    seconds = Math.floor(seconds % 60);
    seconds = seconds >= 10 ? seconds : '0' + seconds;
    if (hours != '') {
      return hours + ':' + minutes + ':' + seconds;
    }
    return minutes + ':' + seconds;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timer = () => {
    const created_at = new Date(item?.created_at);
    const today = new Date();
    const diff = today.valueOf() - created_at.valueOf();
    setTime(convertTimeToDisplay(diff));
  };

  useEffect(() => {
    const id = setInterval(timer, 1000);
    return () => clearInterval(id);
  }, [timer]);

  return (
    <>
      <p className="text-center">{time}</p>
    </>
  );
};

export default DealDeskCounter;
