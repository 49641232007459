import React from 'react';
import classes from '../StoryAssignments.module.scss';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import cs from 'classnames';
import { analyticsConstants } from 'helpers/analytics';
const hours = [1, 2, 3, 5, 8, 13, 21, 34, 55];
const BudgetHours = ({ formik, isEditAllowed, analyticsSendEvent }) => {
  const { values, setFieldValue, handleSubmit } = formik;
  const hour = values.budget_hours;
  return (
    <div className={classes.budgetHours}>
      <div className={classes.title}>Budget Hours</div>
      <nav>
        <Pagination
          className="pagination pagination-md"
          listClassName="pagination-md"
        >
          {hours.map(item => (
            <PaginationItem
              key={item}
              className={cs({
                active: hour === item,
                disabled: hour !== item && isEditAllowed === false,
              })}
            >
              <PaginationLink
                className={cs({
                  [classes.noPointer]: isEditAllowed === false,
                })}
                href="#"
                onClick={e => {
                  if (!isEditAllowed) return;
                  analyticsSendEvent({
                    action:
                      analyticsConstants.action.update_assignment_budget_hours,
                  });
                  e.preventDefault();
                  setFieldValue('budget_hours', parseInt(item));
                  handleSubmit();
                }}
                tabIndex="-1"
              >
                {item}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </nav>
    </div>
  );
};

export default BudgetHours;
