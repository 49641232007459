import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment-timezone';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import cs from 'classnames';
import {
  fetchMoreStoryComments,
  markCommentsAsRead,
  deleteStoryComment,
  clearCommentsUnreadFrom,
  fetchStoryComments,
} from 'store/actions/Story/comments';
import { setMentionsUrl } from 'store/actions/components';
import Button from 'components/Button';
import Comment from 'components/Comment';
import Loading from 'components/Loading';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { getFormattedDate } from 'helpers/times';
import { permissions, useAccess } from 'helpers/permission';
import CommentsFooter from './CommentsFooter';
import UsersTyping from './UsersTyping';
import classes from '../Tabs.module.scss';
import { addInMentions } from 'helpers/mentions';
import socket from 'helpers/socket';
import { useDebounce } from 'react-use';
import analytics, { analyticsConstants } from 'helpers/analytics';

const StoryComments = ({ storyId, commentToScroll, projectId }) => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.story_comments,
      ...rest,
    });
  };
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const containerRef = useRef(null);
  const commentRef = useRef(null);
  const [isInitialCallComplete, setInitialCallComplete] = useState(false);
  const [hideBottom, setHideBottom] = useState(false);
  const [editComment, setEditComment] = useState(null);
  const [viewMode, setViewMode] = useState('');
  const [markingAsRead, setMarkingAsRead] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [visibleComments, setVisibleComments] = useState([]);
  const [newUnreadFrom, setNewUnreadFrom] = useState(null);

  const loggedInUser = useSelector(({ auth }) => get(auth, 'user', null));
  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const comments = useSelector(({ story }) =>
    get(story, 'comments.storyComments.data.data', [])
  );
  const shouldMakeCall = useSelector(({ story }) =>
    get(story, 'comments.storyComments.shouldMakeCall', true)
  );
  const unreadFrom = useSelector(({ story }) =>
    get(story, 'comments.storyComments.unreadFrom', null)
  );
  const isLoading = useSelector(({ story }) =>
    get(story, 'comments.storyComments.isInProgress', true)
  );
  const users = useSelector(({ story }) =>
    get(story, 'comments.typingUsers.users', [])
  );
  useEffect(() => {
    socket.listenTypingWhisper();

    return () => {
      socket.stopListenTypingWhisper();
    };
  }, []);

  useEffect(() => {
    if (storyId) {
      dispatch(fetchStoryComments(storyId, null, true, 'Story'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);

  useEffect(() => {
    if (!unreadFrom) {
      if (containerRef.current && comments.length && !isInitialCallComplete) {
        if (commentRef.current && commentToScroll) {
          scrollToComment();
        } else {
          // Adding timeout so that content from comments get rendered (For image it takes little time so timeout is required)
          setTimeout(() => handleScrollToBottom(), 300);
        }
        setInitialCallComplete(true);
      }
    } else {
      scrollToComment();
      setInitialCallComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, commentRef]);

  useEffect(() => {
    if (containerRef.current) {
      if (
        containerRef.current.scrollHeight <= containerRef.current.clientHeight
      ) {
        handleCommentsRead();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  const updateVisibleComments = async () => {
    // making sure we call only if the visible comments array has ids
    if (visibleComments && visibleComments.length) {
      setMarkingAsRead(true);
      if (newUnreadFrom || unreadFrom || unreadFrom === 0) {
        await dispatch(markCommentsAsRead(visibleComments, storyId));
        setNewUnreadFrom();
        setVisibleComments([]);
      }
      setMarkingAsRead(false);
    }
  };

  // This call will be made after each 2 second interval once user scrolls
  useDebounce(updateVisibleComments, 2000, [
    newUnreadFrom,
    storyId,
    visibleComments,
  ]);

  // Scrolls to specific comment specified in the Param (used for notifications)
  const scrollToComment = () => {
    if (
      containerRef &&
      containerRef.current &&
      commentRef &&
      commentRef.current
    ) {
      containerRef.current.scrollTo({
        top: commentRef.current.offsetTop - 90,
        block: 'end',
      });
    }
  };

  const confirmDelete = comment => {
    analyticsSendEvent({
      action: analyticsConstants.action.delete_comment_on_story,
    });
    dispatch(deleteStoryComment(comment.id));
  };

  const onDeleteClick = comment => () => {
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Comment',
      text: `You are about to delete a comment. Do you want to continue?`,
      data: comment,
    });
  };

  const onEditClick = comment => () => {
    setViewMode('edit');
    setEditComment({ ...comment, message: addInMentions(comment.message) });
    dispatch(
      setMentionsUrl(
        `/users/list/dropdown?status=Active&initiative=${projectId}`
      )
    );
  };

  const resetData = () => {
    setViewMode('');
    setEditComment(null);
  };

  const isUserAllowedDeleteAllComments = useAccess(
    permissions.DELETE_ALL_COMMENTS
  );
  const isUserAllowedDeleteOwnComments = useAccess(
    permissions.DELETE_MY_COMMENTS
  );
  const isUserAllowedUpdateAllComments = useAccess(
    permissions.UPDATE_ALL_COMMENTS
  );
  const isUserAllowedUpdateOwnComments = useAccess(
    permissions.UPDATE_MY_COMMENTS
  );

  const renderNoComments = () => {
    return (
      <div className="d-flex align-items-center justify-content-center h-100 flex-column">
        <div className={classes.noCommentsWrapper}>
          <i className="fas fa-comments" />
          <span>Start a conversation with your team</span>
        </div>
      </div>
    );
  };

  const hasEditPermission = user =>
    isUserAllowedUpdateAllComments ||
    (isUserAllowedUpdateOwnComments && loggedInUser.id === user.id);

  const hasDeletePermission = user =>
    isUserAllowedDeleteAllComments ||
    (isUserAllowedDeleteOwnComments && loggedInUser.id === user.id);

  const handleCommentsRead = async () => {
    setMarkingAsRead(true);
    if (unreadFrom || unreadFrom === 0) {
      await dispatch(
        markCommentsAsRead(
          [
            ...[
              ...comments.filter(c =>
                newUnreadFrom ? c.id >= newUnreadFrom : c.id >= unreadFrom
              ),
            ].map(c => c.id),
          ],
          storyId
        )
      );
    }
    dispatch(clearCommentsUnreadFrom());
    setMarkingAsRead(false);
  };

  const onScrollHandler = () => {
    if (containerRef && containerRef.current && unreadFrom) {
      const containerRects = containerRef.current.getBoundingClientRect();
      const childNodes = containerRef.current.childNodes[0].childNodes;

      const newVisibleComments = [];
      childNodes.forEach(child => {
        const childRect = child.getBoundingClientRect();
        const isInView =
          containerRects.top < childRect.top &&
          containerRects.top + containerRects.height >
            childRect.top + childRect.height;

        /*
            Explanation of following logic:
            - We are checking if id is present for element or not (we have date elements as well which will not have id)
            - Then checking if the comment is visible or not
            - When we do NOT have newUnreadFrom set then checking that current comment id is greater then or equal (>=) with unreadFrom
            - When we have newUnreadFrom set we are checking that is greater then (>) comment ID
            (NOT equal to here because the id in newUnreadFrom is already been set as read in previous call
            - If all this condition matches updating visible comment array so that it will make a debounced call
           */
        if (
          child.id &&
          !visibleComments.includes(parseInt(child.id)) &&
          isInView &&
          ((!newUnreadFrom && parseInt(child.id) >= parseInt(unreadFrom)) ||
            (newUnreadFrom && parseInt(child.id) > parseInt(newUnreadFrom)))
        ) {
          newVisibleComments.push(parseInt(child.id));
        }
      });
      setVisibleComments([...visibleComments, ...newVisibleComments]);
    }
    const hideBottom =
      containerRef && containerRef.current
        ? containerRef.current.scrollHeight - containerRef.current.scrollTop >
          600
        : false;
    setHideBottom(hideBottom);
    if (
      containerRef.current.scrollHeight <=
        containerRef.current.offsetHeight +
          containerRef.current.scrollTop +
          100 &&
      unreadFrom &&
      !markingAsRead
    ) {
      handleCommentsRead();
    }
  };

  /*
    This function loops the comments data in following order
    - grouping comments by formatted date.
    - adding the date in to array with the desired format.
    - sorting the comments in order of oldest to newest
    - looping each comment item and adding in array to show to user
     */
  const renderComments = () => {
    const dateWiseComments = groupBy(
      comments.map(t => ({
        ...t,
        formatted_date: moment(t.created_at)
          .tz(userTimezone)
          .format('dddd, MMMM D, YYYY'),
      })),
      'formatted_date'
    );
    const commentData = [];
    if (isLoading) {
      commentData.push(<Loading key="loader" size={50} />);
    }
    Object.keys(dateWiseComments)
      .sort((a, b) => moment(a) - moment(b))
      .forEach(date => {
        orderBy(dateWiseComments[date], 'id', 'asc').forEach((data, index) => {
          if (unreadFrom === data.id) {
            commentData.push(
              <h5
                className={cs(
                  'border-bottom border-primary',
                  classes.commentDate
                )}
                key="unread"
              >
                <span className="text-primary">Unread Comments</span>
              </h5>
            );
          }
          if (index === 0) {
            commentData.push(
              <h5
                className={cs('border-bottom', classes.commentDate)}
                key={date}
              >
                <span>{getFormattedDate(data.created_at, userTimezone)}</span>
              </h5>
            );
          }
          commentData.push(
            <Comment
              commentRef={commentRef}
              commentToScroll={commentToScroll || unreadFrom}
              key={data.id}
              commentId={data.id}
              user={data.created_by}
              createdAt={data.created_at}
              updatedAt={data.updated_at}
              content={data.message}
              isEdited={data.is_edited}
              dropdownOptions={[
                ...(hasEditPermission(data.created_by)
                  ? [
                      {
                        text: 'Edit Comment',
                        onClick: onEditClick(data),
                      },
                    ]
                  : []),
                ...(hasDeletePermission(data.created_by)
                  ? [
                      {
                        text: 'Delete Comment',
                        onClick: onDeleteClick(data),
                      },
                    ]
                  : []),
              ]}
            />
          );
        });
      });
    return (
      <div
        className={cs(classes.commentsWrapper, {
          [classes.editorActive]: showEditor,
          [classes.typingUsers]: users.length,
        })}
        ref={containerRef}
        onScroll={onScrollHandler}
      >
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          threshold={1}
          isReverse={true}
          loadMore={async () => {
            if ((!shouldMakeCall || isLoading) && isInitialCallComplete) {
              return;
            }
            const containerRects = containerRef.current.getBoundingClientRect();
            const childNodes = containerRef.current.childNodes[0].childNodes;

            let scrollToCommentAfterFetch = null;
            for (let i = 0; i < childNodes.length; i++) {
              const childRect = childNodes[i].getBoundingClientRect();
              const isInView =
                containerRects.top < childRect.top &&
                containerRects.top + containerRects.height >
                  childRect.top + childRect.height;
              if (childNodes[i].id && isInView) {
                scrollToCommentAfterFetch = childNodes[i].id;
                break;
              }
            }
            await dispatch(
              fetchMoreStoryComments(storyId, comments[0].id, 'Story')
            );
            // This is to maintain the scroll position in comments while we fetch new comments
            containerRef.current.scrollTo({
              top:
                document.getElementById(`${scrollToCommentAfterFetch}`)
                  .offsetTop - 20,
              block: 'end',
            });
          }}
          hasMore={shouldMakeCall}
          useWindow={false}
        >
          {commentData}
        </InfiniteScroll>
      </div>
    );
  };

  // This function handles the initial scroll to bottom of comments and click of bottom button
  const handleScrollToBottom = () => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
      });
    }
    handleCommentsRead();
  };

  return (
    <div className={cs('position-relative h-100', classes.commentsTab)}>
      <div className={cs(classes.commentsContainer, classes.customScrollBar)}>
        {comments.length ? renderComments() : renderNoComments()}
        <Button
          size="sm"
          onClick={handleScrollToBottom}
          className={cs(
            'btn-icon btn-fab btn-icon-only bg-white rounded-circle text-light',
            classes.scrollToBottom,
            {
              [classes.hide]: !hideBottom,
            }
          )}
        >
          <i className={cs('fas fa-chevron-down')} />
        </Button>
      </div>
      <UsersTyping />
      <CommentsFooter
        projectId={projectId}
        storyId={storyId}
        handleScrollToBottom={handleScrollToBottom}
        editComment={editComment}
        viewMode={viewMode}
        resetData={resetData}
        showEditor={showEditor}
        setShowEditor={setShowEditor}
        analyticsSendEvent={analyticsSendEvent}
      />
    </div>
  );
};

export default StoryComments;
