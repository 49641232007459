// /taskTypes/?q=
import Request from './request';

export const getAssignmentTypes = (q = '', page = 1) => {
  return Request.call({
    url: '/taskTypes',
    method: 'GET',
    params: {
      q,
      page,
    },
  });
};

export const createAssignmentType = data => {
  return Request.call({
    url: '/taskTypes',
    method: 'POST',
    data,
  });
};

export const editAssignmentType = (assignmentId, data) => {
  return Request.call({
    url: `/taskTypes/${assignmentId}`,
    method: 'PATCH',
    data,
  });
};

export const deleteAssignmentType = assignmentId => {
  return Request.call({
    url: `/taskTypes/${assignmentId}`,
    method: 'DELETE',
  });
};
