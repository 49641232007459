import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_PROJECT_SPENT_INPROGRESS',
  'FETCH_PROJECT_SPENT_SUCCESS',
  'FETCH_PROJECT_SPENT_FAILURE',
  'FETCH_TIME_LOG_CURRENT_WEEK_INPROGRESS',
  'FETCH_TIME_LOG_CURRENT_WEEK_SUCCESS',
  'FETCH_TIME_LOG_CURRENT_WEEK_FAILURE',
  'FETCH_LAST_WEEK_HOURS_INPROGRESS',
  'FETCH_LAST_WEEK_HOURS_SUCCESS',
  'FETCH_LAST_WEEK_HOURS_FAILURE',
  'FETCH_COMPLETED_STORIES_INPROGRESS',
  'FETCH_COMPLETED_STORIES_SUCCESS',
  'FETCH_COMPLETED_STORIES_FAILURE',
  'FETCH_TIME_LOG_GRAPH_INPROGRESS',
  'FETCH_TIME_LOG_GRAPH_SUCCESS',
  'FETCH_TIME_LOG_GRAPH_FAILURE',
  'FETCH_STUDY_TASKS_INPROGRESS',
  'FETCH_STUDY_TASKS_SUCCESS',
  'FETCH_STUDY_TASKS_FAILURE',
  'FETCH_STUDY_DELIVERABLES_INPROGRESS',
  'FETCH_STUDY_DELIVERABLES_SUCCESS',
  'FETCH_ALL_STUDY_DELIVERABLES_SUCCESS',
  'FETCH_STUDY_DELIVERABLES_FAILURE',
  'FETCH_TASKS_SCREEN_STUDY_TASKS_INPROGRESS',
  'FETCH_TASKS_SCREEN_STUDY_TASKS_SUCCESS',
  'FETCH_TASKS_SCREEN_STUDY_TASKS_FAILURE',
  'ADD_STUDY_DELIVERABLES_INPROGRESS',
  'ADD_STUDY_DELIVERABLES_SUCCESS',
  'ADD_STUDY_DELIVERABLES_FAILURE'
);
