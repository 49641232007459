import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from './DocumentMoveModal.module.scss';
import Button from 'components/Button';
import cx from 'classnames';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import { fetchProjectsList as fetchProjects } from 'store/actions/projects';
import { moveStudyDocument } from 'store/actions/documents';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { get } from 'lodash';

const DocumentMoveModal = ({ toggle, isOpen, document, onMoveSuccess }) => {
  const dispatch = useDispatch();
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );
  const [loading, setLoading] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState(currentStudy.id);
  const [selectedBucket, setSelectedBucket] = useState(null);
  const [selectedTab, setSelectedTab] = useState('study');

  //documentStudyTypes
  useEffect(() => {
    dispatch(fetchProjects(1, null, null, currentStudy.client.id));
  }, [dispatch, currentStudy]);
  const studies = useGetFieldFromObjects(
    'project',
    'projectState.data.data',
    []
  );
  const documents = useGetFieldFromObjects(
    'documents',
    'documentStudyTypes.data.data',
    []
  );

  const handleMove = async () => {
    setLoading(true);
    await dispatch(
      moveStudyDocument({
        document_id: document.id,
        new_study_id: selectedStudy,
        new_document_type_id: selectedBucket,
      })
    );
    onMoveSuccess();
    toggle();
  };

  return (
    <Modal
      size="lg"
      // backdrop={['static']}
      scrollable
      fade={false}
      title={'Studies'}
      toggle={toggle}
      isOpen={isOpen}
      className={classes.modalWrapper}
      headerClassName={classes.modalHeader}
    >
      <ModalBody className={cx('d-flex flex-row flex-wrap', classes.modalBody)}>
        {selectedTab === 'study' && (
          <div className={classes.formGroup}>
            <div className={classes.inputWrapper}>
              <label className={classes.label}>
                Select the project to move the document [{document.name}] to:
              </label>
              <Form>
                {studies.map((study, index) => {
                  return (
                    <FormGroup
                      check
                      inline
                      key={index}
                      className={classes.studyCard}
                      onClick={() => {
                        setSelectedStudy(study.id);
                      }}
                    >
                      <Label check className={classes.studyName}>
                        {study.name}
                      </Label>
                      <Input
                        type="radio"
                        name="studyRadio"
                        value={study.id}
                        checked={study.id === selectedStudy}
                      />
                    </FormGroup>
                  );
                })}
              </Form>
            </div>
          </div>
        )}
        {selectedTab !== 'study' && (
          <div className={classes.formGroup}>
            <div className={classes.inputWrapper}>
              <label className={classes.label}>
                Select the bucket to move the document [{document.name}] to:
              </label>
              <Form>
                {documents.map((document, index) => {
                  return (
                    <FormGroup
                      check
                      inline
                      key={index}
                      className={classes.studyCard}
                      onClick={() => {
                        setSelectedBucket(document.id);
                      }}
                    >
                      <Label check className={classes.studyName}>
                        {document.short_name}
                      </Label>
                      <Input
                        type="radio"
                        name={'bucketRadio'}
                        value={document.id}
                        checked={document.id === selectedBucket}
                      />
                    </FormGroup>
                  );
                })}
              </Form>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button color="link" className={classes.back} onClick={toggle}>
          Cancel
        </Button>
        {selectedTab === 'study' && (
          <Button
            disabled={selectedStudy == null}
            color={selectedStudy == null ? 'secondary' : 'primary'}
            onClick={() => setSelectedTab('bucket')}
          >
            Next
          </Button>
        )}
        {selectedTab !== 'study' && (
          <Button
            loading={loading}
            disabled={selectedBucket == null}
            color={selectedBucket == null ? 'secondary' : 'primary'}
            onClick={handleMove}
          >
            Move Document
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default DocumentMoveModal;
