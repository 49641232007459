import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverBody, Badge } from 'reactstrap';
import classes from './EpicSelect.module.scss';
import cs from 'classnames';
import RSelectAsync from 'components/FormFields/RSelectAsync';

const EpicSelect = ({
  onChange,
  disabled,
  id,
  placement,
  epic,
  projectId,
  selectorClassName,
  className,
  popperClassName,
  selectorPlaceholder,
  getOptionValue,
  getOptionLabel,
  noEpicPlaceHolder: NoEpicPlaceHolder,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = data => {
    onChange(data);
    setDropdownOpen(false);
  };

  const EpicOption = ({ innerProps, data }) => {
    return (
      <div
        className={cs('d-flex w-100 align-items-center p-1', classes.pointer)}
        onClick={innerProps.onClick}
      >
        <Badge className="badge-dot m-0" color="">
          <i
            style={{
              backgroundColor: `#${data.color}`,
            }}
          />
        </Badge>
        <h5 className={cs('font-weight-normal', 'mb-0')}>{data.name}</h5>
      </div>
    );
  };

  return (
    <div
      className={cs(classes.root, 'd-inline-block mw-100', className)}
      id={id}
    >
      {epic ? (
        <div className={cs('d-flex w-100 align-items-center', classes.pointer)}>
          <Badge className="badge-dot m-0" color="">
            <i
              style={{
                backgroundColor: `#${epic.color}`,
              }}
            />
          </Badge>
          <h5 className={cs('font-weight-normal', 'mb-0')}>{epic?.name}</h5>
        </div>
      ) : (
        <div className={cs({ [classes.hoverHand]: !disabled })}>
          {NoEpicPlaceHolder}
        </div>
      )}
      <Popover
        isOpen={isDropdownOpen}
        toggle={e => {
          if (e.target.dataset.name !== 'epic-remove')
            setDropdownOpen(currentState => !currentState);
        }}
        placement={placement}
        trigger="legacy"
        popperClassName={cs(classes.popover, popperClassName)}
        target={id}
        container={'#' + id}
        disabled={disabled}
        hideArrow={true}
        className={classes.popover}
      >
        <PopoverBody className={classes.popoverBody}>
          <RSelectAsync
            className={cs(classes.popoverSelector, selectorClassName)}
            isClearable={false}
            menuIsOpen={true}
            placeholder={selectorPlaceholder}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            url={`/initiatives/${projectId}/epics/dropdown`}
            hideSelectedOptions={false}
            closeMenuOnSelect
            onChange={handleClick}
            components={{
              Option: EpicOption,
            }}
          />
        </PopoverBody>
      </Popover>
    </div>
  );
};

EpicSelect.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  placement: PropTypes.string,
  disabled: PropTypes.bool,
  selectorPlaceholder: PropTypes.string,
  className: PropTypes.string,
  selectorClassName: PropTypes.string,
  popperClassName: PropTypes.string,
  epic: PropTypes.object,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  noEpicPlaceHolder: PropTypes.node,
};

EpicSelect.defaultProps = {
  placement: 'bottom-start',
  disabled: false,
  selectorPlaceholder: 'Search Phases',
  className: '',
  selectorClassName: '',
  popperClassName: '',
  epic: null,
  getOptionLabel: option => option.name,
  getOptionValue: option => option.name,
  noEpicPlaceHolder: (
    <h5 className="font-weight-normal text-muted mb-0">+ Assign</h5>
  ),
};

export default EpicSelect;
