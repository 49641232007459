import Request from './request';

export const fetchMembersList = async (id, page, sort, query) => {
  return Request.call({
    url: `/initiatives/${id}/team`,
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
    },
  });
};

export const addMember = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}/team/add`,
    method: 'POST',
    data,
  });
};

export const removeMember = async (projectId, userId) => {
  return Request.call({
    url: `/initiatives/${projectId}/team/remove/${userId}`,
    method: 'DELETE',
  });
};

export const updateRate = async (projectId, userId, data) => {
  return Request.call({
    url: `/initiatives/${projectId}/team/update-rate/${userId}`,
    method: 'PATCH',
    data,
  });
};
