import React from 'react';
import classes from './TabDiscoveryCall.module.scss';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import CommentFeature from 'views/pages/DealDesk/DealDeskModal/CommentFeature';
import moment from 'moment';

export const TabDiscoveryCall = () => {
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const data = dealDesk?.data?.discovery_call;

  const showContent = (data, key) => {
    const dateFields = ['Estimated Close (Date)'];
    if (dateFields.includes(key)) {
      return moment(data[key]).format('MM/DD/YYYY');
    }
    return data[key];
  };

  return (
    <div className={classes.tabDiscovery}>
      {/* <div className={classes.heading}>Industry: CBD</div> */}
      <Row>
        {Object.keys(data)
          .filter(key => (['Notes'].includes(key) ? false : true))
          .map((key, index) => (
            <Col md={6} key={index}>
              <div className={classes.label}>
                {key}{' '}
                <CommentFeature
                  field={`discovery_call.${key}`}
                  label={key}
                  value={showContent(data, key)}
                />
              </div>
              <div className={classes.value}>{showContent(data, key)}</div>
            </Col>
          ))}
      </Row>
      <Row>
        {get(data, 'Notes') && (
          <Col md={12}>
            <div className={classes.label}>
              Notes{' '}
              <CommentFeature
                field={`discovery_call.Notes`}
                label="Notes"
                value={data['Notes']}
              />
            </div>
            <div
              className={classes.value}
              dangerouslySetInnerHTML={{ __html: data['Notes'] }}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};
