import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_TEAM_MEMBERS_INPROGRESS',
  'FETCH_TEAM_MEMBERS_SUCCESS',
  'FETCH_TEAM_MEMBERS_FAILURE',
  'DELETE_TEAM_MEMBERS_INPROGRESS',
  'DELETE_TEAM_MEMBERS_SUCCESS',
  'DELETE_TEAM_MEMBERS_FAILURE',
  'ADD_TEAM_MEMBER_INPROGRESS',
  'ADD_TEAM_MEMBER_SUCCESS',
  'ADD_TEAM_MEMBER_FAILURE',
  'UPDATE_MEMBER_DETAILS'
);
