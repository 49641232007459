import Request from './request';

export const getCompanyDetails = id => {
  return Request.call({
    url: `/companies/${id}`,
    method: 'GET',
  });
};

export const updateCompanyDetails = (id, data) => {
  return Request.call({
    url: `/companies/${id}`,
    method: 'PUT',
    data,
  });
};

export const updateCompanySettings = (id, data) => {
  return Request.call({
    url: `/companies/${id}/settings`,
    method: 'POST',
    data,
  });
};

export const getCompanySettings = id => {
  return Request.call({
    url: `/companies/${id}/settings`,
    method: 'GET',
  });
};
