import Request from './request';

export const fetchClientDeliverables = async (id, page = 1) => {
  return Request.call({
    url: `/clients/${id}/deliverables`,
    method: 'GET',
    params: {
      page,
    },
  });
};

export const postAddDeliverable = async (taskId, data) => {
  return Request.call({
    url: `/tasks/${taskId}/deliverables`,
    method: 'POST',
    data,
  });
};

export const deleteDeliverable = async (taskId, id) => {
  return Request.call({
    url: `/tasks/${taskId}/deliverables/${id}`,
    method: 'DELETE',
  });
};

export const getStudyDeliverables = async (id, params) => {
  return Request.call({
    url: `/initiatives/${id}/deliverables`,
    method: 'GET',
    params,
  });
};

export const addStudyDeliverable = async data => {
  return Request.call({
    url: `/deliverables`,
    method: 'POST',
    data,
  });
};

export const deleteClientDeliverable = async deliverableId => {
  return Request.call({
    url: `/deliverables/${deliverableId}`,
    method: 'DELETE',
  });
};

export const updateDeliverables = async (deliverable, deliverableId) => {
  return Request.call({
    url: `/deliverables/${deliverableId}`,
    method: 'POST',
    data: deliverable,
  });
};

export const getDeliverableById = async id => {
  return Request.call({
    url: `/deliverables/${id}`,
    method: 'GET',
  });
};

// /deliverables
