import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import classes from './Checkbox.module.scss';

const CheckBox = props => {
  const {
    className,
    inputClassName,
    disabled,
    touched,
    error,
    label,
    id,
    checked,
    defaultChecked,
    indeterminate,
    ...rest
  } = props;

  return (
    <>
      <div className={classnames('custom-control custom-checkbox', className)}>
        <input
          className={classnames('custom-control-input', {
            'custom-checkbox-indeterminate': indeterminate,
          })}
          id={id}
          type="checkbox"
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          {...rest}
        />

        <label
          className={classnames('custom-control-label', inputClassName)}
          htmlFor={id}
        >
          {label}
        </label>
      </div>

      {touched && error && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </>
  );
};

CheckBox.prototype = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  touched: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  uncontrolled: PropTypes.bool,
  // reactstrap props,
};

CheckBox.defaultProps = {
  label: null,
  id: null,
  touched: false,
  className: '',
  inputClassName: '',
};

export default CheckBox;
