import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import Activity from 'components/Activity';
import Loading from 'components/Loading';
import {
  fetchMoreStoryActivity,
  fetchStoryActivity,
} from 'store/actions/Story/activity';
import classes from '../Tabs.module.scss';
import { useHistory } from 'react-router';
import analytics, { analyticsConstants } from 'helpers/analytics';
const StoryActivity = ({ storyId, updatedOn, createdOn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const activities = useSelector(({ story }) =>
    get(story, 'activity.activities.data.data', [])
  );
  const activitiesMeta = useSelector(({ story }) =>
    get(story, 'activity.activities.data.meta', {})
  );
  const isInProgress = useSelector(({ story }) =>
    get(story, 'activity.activities.isInProgress', false)
  );

  useEffect(() => {
    if (storyId) {
      dispatch(fetchStoryActivity(storyId, 1));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyId]);
  const analyticsSendEventStory = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const hasMore =
    activitiesMeta.current_page &&
    activitiesMeta.last_page &&
    activitiesMeta.current_page < activitiesMeta.last_page;

  const loadMoreActivities = async () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      await dispatch(
        fetchMoreStoryActivity(
          storyId,
          parseInt(activitiesMeta.current_page) + 1
        )
      );
      setIsLoading(false);
    }
  };

  if (isInProgress) {
    return (
      <div className="h-100 w-100 d-flex align-items-center justify-content-start">
        <Loading key="loader" size={100} />
      </div>
    );
  }
  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <div
        className={cs(
          'd-flex flex-column justify-content-between',
          classes.customScrollBar,
          classes.activityWrapper
        )}
      >
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={loadMoreActivities}
          hasMore={hasMore}
          loader={<Loading key="loader" size={50} />}
          useWindow={false}
        >
          {orderBy(activities, ['created_at'], 'desc').map((d, i) => (
            <Activity key={i} item={d} />
          ))}
        </InfiniteScroll>
      </div>
      <div className={cs('mt-auto border-top', classes.dateDetails)}>
        <div className="text-light mt-3">
          <h6>Updated On: {updatedOn}</h6>
          <h6>Created On: {createdOn}</h6>
        </div>
      </div>
    </div>
  );
};

export default StoryActivity;
