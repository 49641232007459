import React, { useState } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './DeletedStudies.module.scss';
import Button from 'components/Button';
import NoStudy from 'assets/img/theme/NoStudy.png';
import { formatDate } from 'helpers/times';
import {
  getDeletedStudies,
  ApproveDeleteStudy,
  RestoreStudy,
} from 'store/actions/fulfillment';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import DeleteStudyModal from 'components/DeleteStudyModal/DeleteStudyModal';
import numeral from 'numeral';
import { isAdmin } from 'helpers/permission';

const DeletedStudies = ({ type }) => {
  const dispatch = useDispatch();

  const studiesMeta = useSelector(({ fulfillment }) =>
    get(fulfillment, 'deletedStudies.data.meta', {})
  );
  const studiesLoading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'deletedStudies.isInProgress', false)
  );
  const studies = useSelector(({ fulfillment }) =>
    get(fulfillment, 'deletedStudies.data.data', [])
  );
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'desc',
  });
  const [search, setSearch] = useState('');
  const [isOpenDeleteStudy, setIsOpenDeleteStudy] = useState(false);
  const [selectedStudy, setSelectedStudy] = useState(null);

  const handleDeleteStudy = row => {
    setSelectedStudy(row);
    setIsOpenDeleteStudy(true);
  };
  const handleDeleteStudyConfirm = async () => {
    setIsOpenDeleteStudy(false);
    await dispatch(
      ApproveDeleteStudy(
        selectedStudy.id,
        selectedStudy?.deletion_requests?.approvers &&
          selectedStudy?.deletion_requests?.approvers?.length > 0
      )
    );
    setSelectedStudy(null);
    await fetchStudies();
  };
  const closeDeleteStudyModal = () => {
    setIsOpenDeleteStudy(false);
    setSelectedStudy(null);
  };
  const handleRestoreStudy = async row => {
    await dispatch(RestoreStudy(row.id));
    await fetchStudies();
  };
  const fetchStudies = async () => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    await dispatch(getDeletedStudies({ page, sort, q: search, type: type }));
  };
  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort" />;
    else if (order === 'asc') return <i className="fas fa-sort-up" />;
    else if (order === 'desc') return <i className="fas fa-sort-down" />;
    return null;
  };
  const NoDataIndication = () => (
    <div className="text-center mt-5 mb-5">
      <img src={NoStudy} />

      <div className="mt-1">
        {' '}
        <h3>
          <b>No Deleted Studies</b>
        </h3>
      </div>
    </div>
  );
  return (
    <Card className={cx(classes.wrapper, classes.projects)}>
      <CardBody className={cx(classes.cardBody)}>
        <BaseTable
          id="deletedStudies"
          keyField="id"
          defaultSorted={[sortBy]}
          noDataIndication={NoDataIndication}
          bootstrap4
          remote
          bordered={false}
          loading={studiesLoading}
          search={true}
          paginationOptions={{
            page,
            totalSize: studiesMeta.total,
            sizePerPage: numeral(get(studiesMeta, 'per_page', 50)).value(),
          }}
          data={studies}
          columns={[
            {
              dataField: 'name',
              text: 'Study',
              sort: true,
              classes: classes['project-name-column'],
              sortCaret: renderSortCaret,
              formatter: (cell, row, index) => (
                <div
                  className={cx(
                    'd-flex justify-content-between w-100 align-items-center',
                    classes['project-name']
                  )}
                  title={cell}
                >
                  {cell}
                </div>
              ),
            },
            {
              dataField: 'study_years',
              text: 'YEARS',
              sort: false,
              sortCaret: renderSortCaret,
              formatter: (cell, row) => {
                return (
                  <>
                    <div
                      className={cx(
                        'd-flex align-items-center',
                        classes['project-name']
                      )}
                    >
                      {cell}
                    </div>
                  </>
                );
              },
            },
            {
              dataField: 'phase',
              text: 'Submitted By',
              sort: false,
              sortCaret: renderSortCaret,
              formatter: (cell = {}, row) => {
                return (
                  <>
                    <div className="m-0 d-flex align-items-center">
                      {row?.deletion_requests?.requested_by?.name || '-'}
                    </div>
                  </>
                );
              },
            },
            type == 'submitted'
              ? {
                  dataField: 'color',
                  text: 'Date Submitted',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell = {}, row) => {
                    return (
                      <>
                        <div className="m-0 d-flex align-items-center">
                          {row?.deletion_requests?.created_at
                            ? formatDate(
                                row?.deletion_requests?.created_at,
                                'MM/DD/YYYY'
                              )
                            : '-'}
                        </div>
                      </>
                    );
                  },
                }
              : {
                  dataField: 'deleted_at',
                  text: 'Date Deleted',
                  sort: true,
                  sortCaret: renderSortCaret,
                  formatter: (cell = {}, row) => {
                    return (
                      <>
                        <div className="m-0 d-flex align-items-center">
                          {formatDate(cell, 'MM/DD/YYYY') || '-'}
                        </div>
                      </>
                    );
                  },
                },
            {
              dataField: 'deletion_requests',
              text: 'Reviewed By',
              sort: false,
              sortCaret: renderSortCaret,
              formatter: (cell = {}, row) => {
                return (
                  <>
                    {cell?.approvers?.map(approver => (
                      <div
                        key={approver.id}
                        className="m-0 d-flex align-items-center"
                      >
                        <span>{approver.name || '-'}</span>&nbsp;&nbsp;
                        <i
                          className={cx(
                            'fa fa-check-circle',
                            classes['confirmedCheck']
                          )}
                        ></i>
                      </div>
                    ))}
                  </>
                );
              },
            },
            {
              dataField: '',
              text: '',
              sort: false,
              formatter: (cell, row) => {
                return (
                  <div>
                    <Button
                      color="primary"
                      outline="true"
                      onClick={() => handleRestoreStudy(row)}
                    >
                      Restore Study
                    </Button>
                    {!row?.deleted_at && (
                      <Button
                        color="primary"
                        onClick={() => handleDeleteStudy(row)}
                      >
                        Confirm Delete
                      </Button>
                    )}
                  </div>
                );
              },
            },
          ]}
          onTableChange={(
            type,
            { page, sortOrder, sortField, searchText, ...rest }
          ) => {
            // debugger
            if (type === 'pagination') {
              setPage(page);
            } else if (type === 'sort') {
              setSortBy({
                dataField: sortField,
                order: sortOrder,
              });
            } else if (type === 'search') {
              setSearch(searchText);
            }
            const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
            dispatch(getDeletedStudies({ page, sort, q: searchText }));
          }}
        />
      </CardBody>
      {isOpenDeleteStudy && (
        <DeleteStudyModal
          isOpen={isOpenDeleteStudy}
          closeModal={closeDeleteStudyModal}
          handleConfirm={handleDeleteStudyConfirm}
          isAdmin={true}
          confirmedBy={selectedStudy?.deletion_requests?.approvers}
        ></DeleteStudyModal>
      )}
    </Card>
  );
};

export default DeletedStudies;
