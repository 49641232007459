import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const InputMinuteSecond = props => {
  const { name, value, onChange } = props;

  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value]);

  const validateTime = e => {
    const re = /^[0-9\b]+$/;

    let value = e.target.value;
    if (value === '00') {
      setData('00');
    } else if (value !== '' && re.test(value)) {
      const iValue = parseInt(value) || 0;

      if (iValue > 59) value = 59;

      setData(value);
    } else if (value === '') {
      setData('');
    }
  };

  const handleOnBlur = e => {
    let value = e.target.value;

    if (value === '') {
      value = '00';
      setData(value);
      onChange(0);
      return true;
    }

    const iValue = parseInt(value);
    let changeData = iValue;
    if (iValue < 10) changeData = `0${iValue}`;

    setData(changeData);

    if (iValue || iValue === 0) onChange(iValue);
  };

  return (
    <input
      autoComplete="off"
      type="text"
      name={name}
      value={data}
      maxLength="2"
      onChange={validateTime}
      onBlur={handleOnBlur}
    />
  );
};

InputMinuteSecond.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
InputMinuteSecond.defaultProps = {};

export default InputMinuteSecond;
