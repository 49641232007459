export const shortName = fullname => {
  const arr = fullname.split(' ');
  if (arr.length > 1) {
    return `${arr[0]} ${arr[1][0]}.`;
  }

  return fullname;
};

export const getStudyName = studyName => {
  return studyName.split(' -')[0];
};
