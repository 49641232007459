import React, { useEffect } from 'react';
import classes from './GeneralCommentModal.module.scss';
import MessageDetails from './MessageDetails';
import Modal, { ModalBody } from 'components/FormFields/Modal';
import cx from 'classnames';
import socket from 'helpers/socket';
import useBreakpoint from 'helpers/useBreakPoint';
import { useDispatch, useSelector } from 'react-redux';
import { clearCommentsData } from 'store/actions/Story/comments';
import Button from 'components/Button';
import { get } from 'lodash';
import history from 'helpers/history';
import { useParams } from 'react-router';

const GeneralCommentModal = ({
  isOpen,
  closeModal,
  comment = '',
  data,
  params,
  userType = 'client', //client|admin
}) => {
  const dispatch = useDispatch();
  const parameters = useParams();
  const initiativeId = get(parameters, 'id');
  const isMobile = useBreakpoint('xs', 'down');
  const parentId = get(params, 'parent_id');
  const user = useSelector(({ auth }) => get(auth, 'user'));
  const assigned_initiatives = get(user, 'assigned_initiatives', []);
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );

  const isStudyAssigned = assigned_initiatives.includes(currentStudy?.id);

  useEffect(() => {
    if (parentId) {
      socket.joinAndListenComments('Initiative', parentId, params);
      return () => {
        socket.leaveComments();
        dispatch(clearCommentsData());
      };
    }
    // used when notification is sent to user
    const leftColumn = document.getElementById('storyModal-leftColumn');
    if (leftColumn) leftColumn.scrollTop = 0;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  return (
    <Modal
      size="lg"
      scrollable
      closeButtonVariant="version_2"
      headerClassName="align-items-center"
      actionButton={
        userType === 'admin' ? (
          <Button
            outline
            leftIcon={<i className="fa fa-envelope" aria-hidden="true"></i>}
            color="primary"
            className={classes.redirectButton}
            onClick={() => {
              history.push(
                `/admin/accounts/${data?.data?.client?.id}/${initiativeId}/overview`
              );
            }}
          >
            Go to Client
          </Button>
        ) : (
          ''
        )
      }
      fade={false}
      title={
        <div className="d-flex align-items-center">
          {<span>{data?.subject || currentStudy?.name || ''}</span>}
        </div>
      }
      toggle={closeModal}
      isOpen={isOpen}
      className={classes.modalWrapper}
    >
      <ModalBody
        className={cx('p-0 d-flex flex-row flex-wrap', classes['story-modal'], {
          [classes['story-modal-mobile']]: isMobile,
        })}
        id="storyModal-body"
      >
        <MessageDetails
          params={params}
          comment={comment}
          parentId={parentId}
          initiativeId={initiativeId}
          userType={userType}
          isStudyAssigned={isStudyAssigned}
        />
      </ModalBody>
    </Modal>
  );
};

export default GeneralCommentModal;
