import React from 'react';
import { CustomPicker } from 'react-color';
import classNames from 'classnames';
import { Hue, Saturation } from 'react-color/lib/components/common';
import classes from './ColorPicker.module.scss';
const SaturationCircle = () => {
  return <div className={classes['saturation-picker-pointer']} />;
};

const HuePointer = () => {
  return <div className={classes['hue-picker-pointer']} />;
};
const CustomColorPicker = props => {
  return (
    <div className={classNames(classes['color-picker'], 'mb-2')}>
      <div className={classes['saturation-picker']}>
        <Saturation {...props} pointer={SaturationCircle} />
      </div>
      <div className={classNames(classes['hue-picker-container'], 'ml-1')}>
        <Hue
          className={classNames(classes['hue-picker'], 'h-100')}
          {...props}
          pointer={HuePointer}
          direction="vertical"
        />
      </div>
    </div>
  );
};

export default CustomPicker(CustomColorPicker);
