import {
  fetchClientProfile,
  fetchAgreements,
  download,
  create,
  remove,
  update,
  deleteFile,
  getAgreement,
  getAgreementTypes,
  getTimeSpentForClient,
  getRecentlyViewedProjects,
  getRecentTimeEntries as getRecentTimeEntriesApi,
  fetchClientTasks as fetchClientTasksApi,
  fetchClientActivities as fetchClientActivitiesApi,
  fetchClientDocuments as fetchClientDocumentsApi,
  uploadClientDocument as uploadClientDocumentApi,
  fetchTeamMembers as fetchTeamMembersApi,
} from 'api/clientProfile';
import {
  fetchClientDeliverables,
  deleteClientDeliverable,
  updateDeliverables,
  getDeliverableById as getDeliverableApi,
} from 'api/deliverables';
import { forEach, get, pickBy } from 'lodash';
import Types from '../types/clientProfile';
import { fileDownload } from 'helpers/constants';
import { NotificationHandler } from 'components/Notifications';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
import { updateClientProfile } from 'api/clients';
import { deactivate, resendInvitation, cancel, editUser } from 'api/users';

export const getClientProfile = (id, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_PROFILE_INPROGRESS,
      id,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchClientProfile(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        if (cb) {
          cb(resp);
        }
        dispatch({
          type: Types.FETCH_CLIENT_PROFILE_SUCCESS,
          data: resp.data || {},
          id,
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_PROFILE_FAILURE,
          message: message,
          id,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_PROFILE_FAILURE,
        message: error,
        id,
      });
    }
  };
};

export const fetchAgreementsList = (id, page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_AGREEMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchAgreements(id, page, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_AGREEMENTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_AGREEMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_AGREEMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchClientProfileTasks = (id, params) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_PROFILE_TASKS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchClientTasksApi(id, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CLIENT_PROFILE_TASKS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_PROFILE_TASKS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_PROFILE_TASKS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchDeliverablesList = (id, page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_DELIVERABLES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchClientDeliverables(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CLIENT_DELIVERABLES_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_DELIVERABLES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_DELIVERABLES_FAILURE,
        message: error,
      });
    }
  };
};

export const getDeliverable = id => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DELIVERABLE_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getDeliverableApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DELIVERABLE_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DELIVERABLE_FAILURE,
          message: message,
        });
      }
      return resp.data;
    } catch (error) {
      dispatch({
        type: Types.FETCH_DELIVERABLE_FAILURE,
        message: error,
      });
    }
  };
};

export const doResetActiveDeliverables = () => {
  return dispatch => {
    dispatch({
      type: Types.RESET_ACTIVE_DELIVERABLES,
    });
  };
};

export const doDeleteClientDeliverable = deliverableId => {
  return async (dispatch, getStore) => {
    dispatch({
      type: Types.DELETE_CLIENT_DELIVERABLES_INPROGRESS,
    });
    try {
      const resp = await deleteClientDeliverable(deliverableId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        const store = getStore();
        const deliverables = get(store, 'clientProfile.deliverables.data', []);
        const index = deliverables.findIndex(
          deliverable => deliverable.id === deliverableId
        );
        let updatedDeliverable = [];
        if (index > -1) {
          updatedDeliverable = [
            ...deliverables.slice(0, index),
            ...deliverables.slice(index + 1),
          ];
        }
        dispatch({
          type: Types.DELETE_CLIENT_DELIVERABLES_SUCCESS,
          updatedDeliverable,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'success',
          icon: ' ',
          title: ' ',
        });
        return true;
      } else {
        dispatch({
          type: Types.DELETE_CLIENT_DELIVERABLES_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
          icon: ' ',
          title: ' ',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_CLIENT_DELIVERABLES_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
        icon: ' ',
        title: ' ',
      });
    }
  };
};

export const doUpdateDeliverable = (deliverable, deliverableId) => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_CLIENT_DELIVERABLES_INPROGRESS,
    });
    try {
      const resp = await updateDeliverables(deliverable, deliverableId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_CLIENT_DELIVERABLES_SUCCESS,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'success',
          icon: ' ',
          title: ' ',
        });
      } else {
        dispatch({
          type: Types.EDIT_CLIENT_DELIVERABLES_FAILURE,
          message,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
          icon: ' ',
          title: ' ',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_CLIENT_DELIVERABLES_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
        icon: ' ',
        title: ' ',
      });
    }
  };
};

export const createAgreement = (data, filters, clientId) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await create(data, clientId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.CREATE_AGREEMENT_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchAgreementsList(clientId, page, sort, q));

        NotificationHandler.open({
          message,
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.CREATE_AGREEMENT_FAILURE,
          message: message,
        });
        if (errorStatus !== SHOW_UPGRADE_ALERT_CODE) {
          NotificationHandler.open({
            operation: 'failure',
            message,
          });
        }
      }
      // returning status and data as we need this to open the create project modal
      return { status, data: record, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.CREATE_AGREEMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updateAgreement = (id, data, filters, clientId) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await update(data, id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      const errorStatus = get(resp, 'errorStatus');
      if (status) {
        dispatch({
          type: Types.UPDATE_AGREEMENT_SUCCESS,
          data: {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchAgreementsList(clientId, page, sort, q));

        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.UPDATE_AGREEMENT_FAILURE,
          message: message,
        });
        if (errorStatus !== SHOW_UPGRADE_ALERT_CODE) {
          NotificationHandler.open({
            operation: 'failure',
            message: message,
          });
        }
      }
      // returning status and data as we need this to open the create project modal
      return { status, data: record, errorStatus };
    } catch (error) {
      dispatch({
        type: Types.UPDATE_AGREEMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const deleteAgreement = (id, filters, clientId) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await remove(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_AGREEMENT_SUCCESS,
          data: resp || {},
        });
        const { page, sort, q } = filters;
        await dispatch(fetchAgreementsList(clientId, page, sort, q));
        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.DELETE_AGREEMENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_AGREEMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const getAgreementData = id => {
  return async dispatch => {
    dispatch({
      type: Types.EDIT_AGREEMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getAgreement(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.EDIT_AGREEMENT_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.EDIT_AGREEMENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_AGREEMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchAgreementTypes = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_AGREEMENT_TYPES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getAgreementTypes();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_AGREEMENT_TYPES_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_AGREEMENT_TYPES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_AGREEMENT_TYPES_FAILURE,
        message: error,
      });
    }
  };
};

export const clearEditAgreement = () => {
  return async dispatch => {
    dispatch({
      type: Types.CLEAR_EDIT_AGREEMENT,
    });
  };
};

export const deleteAttachedFile = async id => {
  try {
    const resp = await deleteFile(id);
    const message = get(resp, 'message', '');
    if (resp.status) {
      NotificationHandler.open({
        operation: 'success',
        title: message,
      });
    } else {
      NotificationHandler.open({
        operation: 'failure',
        title: message,
      });
    }
  } catch (error) {
    NotificationHandler.open({
      operation: 'failure',
      title: error,
    });
  }
};

export const downloadFile = doc => {
  return async dispatch => {
    dispatch({
      type: Types.DOWNLOAD_FILE_INPROGRESS,
    });
    try {
      const response = await download(doc.id);
      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, doc.name);
      dispatch({
        type: Types.DOWNLOAD_FILE_SUCCESS,
      });
      NotificationHandler.open({
        title: 'Success',
        operation: 'success',
        message: 'File Downloaded Successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.DOWNLOAD_FILE_FAILURE,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const getTimeSpent = (
  clientId,
  group = 'week',
  startDate = '',
  endDate = ''
) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_INPROGRESS });
    try {
      const resp = await getTimeSpentForClient(clientId, {
        group,
        startDate,
        endDate,
      });
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_TIME_LOG_GRAPH_FAILURE,
        message: error,
      });
    }
  };
};

export const getViewedProjects = clientId => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_INPROGRESS });
    try {
      const resp = await getRecentlyViewedProjects(clientId);
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        // Mock passing study name to table on board
        forEach(data, i => {
          i.study_name = 'R&D 2018-2020';
        });
        dispatch({
          type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_RECENTLY_VIEWED_PROJECTS_FAILURE,
        message: error,
      });
    }
  };
};

export const getRecentTimeEntries = (clientId, page = 1) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_INPROGRESS });
    try {
      const resp = await getRecentTimeEntriesApi(clientId, page);
      const status = get(resp, 'status');
      if (status) {
        const data = pickBy(resp, (value, key) => key !== 'status');
        dispatch({
          type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_RECENT_TIME_ENTRIES_FAILURE,
        message: error,
      });
    }
  };
};

const getYearsAsString = years => {
  if (typeof years === 'string' && years !== '') {
    return years.split(',');
  }
  return years && years.length ? years.map(val => val.label) : [];
};

export const updateClient = (id, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_CLIENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const {
        // id: notUsedId,
        associated_entity,
        email_addresses,
        account_type,
        industry,
        engagement_years,
        // initial_study_years,
        assigned_user,
        business_development_rep,
        size,
        lead_source,
        entity_type,
        year_engaged,
        quarter_engaged,
        scope_state_start_year,
        scope_state_end_year,
        scope_federal_start_year,
        scope_federal_end_year,
        contract_terms_option1,
        contract_terms_option2,
        has_ever_filed_tax_credit,
        owner_int_companies,
        company_int_other_comp,
        using_contract_workers,
        using_time_tracking,
        aquired_or_disposed,
        eligible_small_business,
        qualified_small_business,
        req_se_income_calc,
        cntr_group_filing_type,
        created_at,
        updated_at,
        credit_amounts,
        estimated_payments,
        account_level,
        referred_by,
        business_development_manager,
        ...rest
      } = data;
      const resp = await updateClientProfile(id, {
        ...rest,
        referred_by: referred_by ? referred_by : null,
        business_dev_manager_id: business_development_manager
          ? business_development_manager.id
          : null,
        account_type: account_type ? account_type.label : null,
        account_level: account_level ? account_level.label : null,
        industry: industry ? industry.label : null,
        size: size ? size.label : null,
        lead_source: lead_source ? lead_source.label : null,
        engagement_years: getYearsAsString(engagement_years),
        // initial_study_years: getYearsAsString(initial_study_years),
        assigned_user_id: assigned_user ? assigned_user.id : null,
        entity_type: entity_type ? entity_type.label : null,
        business_dev_rep_id: business_development_rep
          ? business_development_rep.id
          : null,
        year_engaged: year_engaged ? year_engaged.label : null,
        quarter_engaged: quarter_engaged ? quarter_engaged.label : null,
        scope_state_start_year: scope_state_start_year
          ? scope_state_start_year.label
          : null,
        scope_state_end_year: scope_state_end_year
          ? scope_state_end_year.label
          : null,
        scope_federal_start_year: scope_federal_start_year
          ? scope_federal_start_year.label
          : null,
        scope_federal_end_year: scope_federal_end_year
          ? scope_federal_end_year.label
          : null,
        contract_terms_option1: contract_terms_option1
          ? contract_terms_option1.label
          : null,
        contract_terms_option2: contract_terms_option2
          ? contract_terms_option2.label
          : null,
        has_ever_filed_tax_credit: has_ever_filed_tax_credit
          ? has_ever_filed_tax_credit.label
          : null,
        owner_int_companies: owner_int_companies
          ? owner_int_companies.label
          : null,
        company_int_other_comp: company_int_other_comp
          ? company_int_other_comp.label
          : null,
        using_contract_workers: using_contract_workers
          ? using_contract_workers.label
          : null,
        using_time_tracking: using_time_tracking
          ? using_time_tracking.label
          : null,
        aquired_or_disposed: aquired_or_disposed
          ? aquired_or_disposed.label
          : null,
        eligible_small_business: eligible_small_business
          ? eligible_small_business.label
          : null,
        qualified_small_business: qualified_small_business
          ? qualified_small_business.label
          : null,
        req_se_income_calc: req_se_income_calc
          ? req_se_income_calc.label
          : null,
        cntr_group_filing_type: cntr_group_filing_type
          ? cntr_group_filing_type.label
          : null,
        email_addresses: email_addresses
          .map(e => ({
            email: e.email,
            is_primary: e.is_primary,
          }))
          .filter(e => e.email),
        associated_entity: associated_entity,
        credit_amounts: credit_amounts.map(c => ({
          ...c,
          year: c.year
            ? typeof c.year === 'string'
              ? c.year
              : c.year.label
            : '',
        })),
        estimated_payments: estimated_payments.map(c => ({
          ...c,
          year: c.year
            ? typeof c.year === 'string'
              ? c.year
              : c.year.label
            : '',
          quarter: c.quarter
            ? typeof c.quarter === 'string'
              ? c.quarter
              : c.quarter.label
            : '',
        })),
      });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const updatedData = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.UPDATE_CLIENT_SUCCESS,
          data: updatedData || {},
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.UPDATE_CLIENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          message: resp.message,
          operation: 'failure',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_CLIENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        message: error,
        operation: 'failure',
      });
    }
  };
};

export const fetchClientActivities = (id, page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchClientActivitiesApi(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CLIENT_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchClientDocuments = (clientId, params) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_DOCUMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchClientDocumentsApi(clientId, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CLIENT_DOCUMENTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_DOCUMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_DOCUMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const uploadClientDocument = (documentType, params) => {
  return async dispatch => {
    dispatch({
      type: Types.UPLOAD_CLIENT_DOCUMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await uploadClientDocumentApi(documentType, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPLOAD_CLIENT_DOCUMENT_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.UPLOAD_CLIENT_DOCUMENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return status;
    } catch (error) {
      dispatch({
        type: Types.UPLOAD_CLIENT_DOCUMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchTeamMembers = (clientId, params) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CLIENT_TEAM_MEMBERS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchTeamMembersApi(clientId, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CLIENT_TEAM_MEMBERS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CLIENT_TEAM_MEMBERS_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_CLIENT_TEAM_MEMBERS_FAILURE,
        message: error,
      });
    }
  };
};
