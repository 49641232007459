import React, { useState, useEffect } from 'react';
import classes from './KnowledgeBaseArticle.module.scss';
import CardsHeader from 'components/Headers/CardsHeader.js';

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  ListGroupItem,
  ListGroup,
} from 'reactstrap';
import LikeAndDislike from 'components/LikeAndDislike';
import Dropdowns from 'components/Dropdowns';

import KnowledgeBaseForm from 'views/pages/KnowledgeBase/KnowledgeBaseForm';
import { useDispatch, useSelector } from 'react-redux';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';

import {
  doGetArticleById,
  doGetArticlesMostRated,
  doDeleteArticleById,
  doPostLikeDisLikeArticleById,
} from 'store/actions/knowledgeBaseActions';
import { get, isEqual } from 'lodash';
import history from 'helpers/history';
import ContentEditor from 'components/ContentEditor';
import Loading from 'components/Loading';
import { useAccess, permissions } from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { withTime } from 'helpers/times';

const KnowledgeBaseArticle = props => {
  const dispatch = useDispatch();
  const knowledgeBase = useSelector(({ knowledgeBase }) => knowledgeBase);
  const auth = useSelector(({ auth }) => auth);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.knowledge_base,
      ...rest,
    });
  };
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await dispatch(doGetArticleById(props.match.params.id));
      await dispatch(doGetArticlesMostRated());
      setLoading(false);
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  useEffect(() => {
    analyticsSendEvent({ action: analyticsConstants.action.view_kb_article });
  }, []);

  const article = get(knowledgeBase, 'article.data');
  const authUserId = get(auth, 'user.id');
  const isUserAllowedEditAllArticles = useAccess(permissions.EDIT_ALL_ARTICLES);
  const isUserAllowedEditOwnArticles = useAccess(permissions.EDIT_MY_ARTICLES);
  const isUserAllowedDeleteAllArticles = useAccess(
    permissions.DELETE_ALL_ARTICLES
  );
  const isUserAllowedDeleteOwnArticles = useAccess(
    permissions.DELETE_MY_ARTICLES
  );
  const canEditArticle = createdBy =>
    isUserAllowedEditAllArticles ||
    (isUserAllowedEditOwnArticles &&
      isEqual(parseInt(createdBy), parseInt(authUserId)));

  const canDeleteArticle = createdBy =>
    isUserAllowedDeleteAllArticles ||
    (isUserAllowedDeleteOwnArticles &&
      isEqual(parseInt(createdBy), parseInt(authUserId)));

  if (!article) return '';

  const articleGuid = get(article, 'category.guid');
  const companyGuid = get(auth, 'user.company.guid');
  return (
    <div className={classes.root}>
      {loading && <Loading wrapperClass={classes.loading} />}
      <CardsHeader
        currentPage="Article"
        name={article.name}
        parentName="Knowledge Base"
        parentLink="/admin/knowledge-base"
      />

      <div className={classes.container}>
        <Row>
          <Col md={8} className={classes.content}>
            <Card>
              <CardBody>
                <CardTitle className="h2 mb-0 ">
                  <div className={classes.actions}>
                    {((articleGuid === '1' && companyGuid === articleGuid) ||
                      articleGuid !== '1') && (
                      <LikeAndDislike
                        isLiked={article.isLiked}
                        isDisLiked={article.isDisLiked}
                        like={article.likes}
                        dislike={article.dislikes}
                        onClick={type => {
                          dispatch(
                            doPostLikeDisLikeArticleById(article.id, type)
                          );
                        }}
                      />
                    )}
                    {(canEditArticle(article.created_by.id) ||
                      canDeleteArticle(article.created_by.id)) &&
                      ((articleGuid === '1' && companyGuid === articleGuid) ||
                        articleGuid !== '1') && (
                        <Dropdowns
                          className=" text-light"
                          text={<i className="fas fa-ellipsis-v" />}
                          size="sm"
                          caret={false}
                          role="button"
                          color=""
                          options={[
                            ...(canEditArticle(article.created_by.id)
                              ? [
                                  {
                                    text: 'Edit Article',
                                    onClick: () => {
                                      toggle();
                                    },
                                  },
                                ]
                              : []),
                            ...(canDeleteArticle(article.created_by.id)
                              ? [
                                  {
                                    text: 'Delete Article',
                                    onClick: () => {
                                      AlertPopupHandler.open({
                                        onConfirm: async () => {
                                          setLoading(true);
                                          analyticsSendEvent({
                                            action:
                                              analyticsConstants.action
                                                .delete_kb_article,
                                          });

                                          dispatch(
                                            doDeleteArticleById(
                                              article.id,
                                              () => {
                                                history.push(
                                                  '/admin/knowledge-base'
                                                );
                                              },
                                              () => {
                                                setLoading(false);
                                              }
                                            )
                                          );
                                        },
                                        confirmBtnText: 'Delete Article',
                                        text: `You are about to delete “${article.name}”. Do you want to continue?`,
                                        data: {},
                                      });
                                    },
                                  },
                                ]
                              : []),
                          ]}
                        />
                      )}
                  </div>
                  {article.name}
                </CardTitle>
                <small className="text-muted">
                  by {get(article, 'created_by.name', '')}
                  {withTime(article.created_at, {
                    customFormat: ' on MMM Do [at] h:mm A',
                  })}
                </small>

                <ContentEditor className="mt-4" content={article.content} />
              </CardBody>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <ListGroup flush className={classes.mostArticle}>
                <ListGroupItem className="h3">Top Rated Content</ListGroupItem>
                {get(knowledgeBase, 'mostRated.data', []).map(item => {
                  return (
                    <ListGroupItem
                      key={item.id}
                      onClick={() =>
                        history.push(`/admin/knowledge-base/${item.id}`)
                      }
                    >
                      {item.name}
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </div>

      {isOpen && (
        <KnowledgeBaseForm
          isOpen={isOpen}
          toggle={toggle}
          mode="edit"
          data={article}
        />
      )}
    </div>
  );
};

export default KnowledgeBaseArticle;
