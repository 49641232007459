import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentTypes } from 'store/actions/onboarding';
import styles from './Documentation.module.scss';
import cx from 'classnames';
import map from 'lodash/map';
import OnboardingDocumentationImg from 'assets/img/theme/MacroOnboarding_Documentation_Img.svg';

const Documentation = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDocumentTypes());
  }, [dispatch]);
  const documentation = useSelector(
    state => state.onboarding.documentation.data
  );

  return (
    <Card className={cx('ml-5', styles.card)}>
      <CardBody className={cx(styles.cardBody, 'p-0')}>
        <p className="font-weight-400">
          <div className="mb-4">You’ve made a great progress!</div>
          Let’s review the{' '}
          <span className="font-weight-700">
            4 different types of documents
          </span>{' '}
          you’ll need to submit: 
        </p>
        <Container className="text-sm p-0" fluid>
          <Row>
            {map(documentation, (document, i) => (
              <Col sm={6} key={i} className="d-flex">
                <Card className={cx('rounded-lg', styles.documentCard)}>
                  <CardHeader className={cx(styles.cardTitle)}>
                    {document.name}
                  </CardHeader>
                  <CardBody className="text-xs">
                    {document.description}
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col>
              <div className="font-weight-700">
                Ready to upload these documents?
              </div>
              <br />
              <div>
                Click{' '}
                <span className="font-weight-700">Continue to Invite</span> and
                onboard team members to begin collaborating together to get your
                R&D Study started!
              </div>
              <br />
              Not ready just yet?
              <br />
              Don’t worry! Your information has been saved and you can resume
              this later.
            </Col>
            <Col sm="auto">
              <img src={OnboardingDocumentationImg} />
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

Documentation.propTypes = {};

export default Documentation;
