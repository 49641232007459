import React from 'react';
import cs from 'classnames';
import classes from './MessageDetails.module.scss';
import {
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Badge,
} from 'reactstrap';
import useBreakPoint from 'helpers/useBreakPoint';

import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Loading from 'components/Loading';
import CommentSection from 'components/Fulfillment/CommentSection';

const MessageDetails = ({
  parentId,
  params,
  initiativeId,
  userType,
  isStudyAssigned,
}) => {
  const comments = useSelector(({ story }) =>
    get(story, 'comments.storyComments.data.data', [])
  );

  const isInitialCall = false;
  const unreadFrom = useSelector(({ partner }) =>
    get(partner, 'comments.storyComments.unreadFrom', null)
  );

  const getCommentsCount = () => {
    if (unreadFrom) {
      const foundIndex = comments.findIndex(c => c.id === unreadFrom);
      if (foundIndex > -1) {
        return comments.length - foundIndex;
      } else {
        return 0;
      }
    }
    return 0;
  };

  const contents = [
    {
      name: 'Messages',
      iconClassName: 'fas fa-comments',
      gradientColor: 'orange',
      pageLink: 'comments',
      showBadge: true,
      count: getCommentsCount(),
    },
  ];

  const isMobile = useBreakPoint('xs', 'down');

  return (
    <Col
      className={cs(
        classes['right-column'],
        'pb-3 d-flex flex-column',
        isMobile ? 'border-top' : 'border-left',
        { 'w-100': isMobile }
      )}
    >
      <div className={classes.tabWrapper}>
        <Nav className="nav-fill flex-row" id="tabs-icons-text" pills>
          {contents.map(content => (
            <NavItem key={content.pageLink} className={classes.tabItem}>
              <NavLink className={'text-primary'} href="#" role="tab">
                <i className={content.iconClassName} />
                {content.name}
                {content.showBadge && content.count ? (
                  <Badge
                    color="danger"
                    className={cs(
                      'badge-sm badge-circle badge-floating border-white',
                      classes.badgeCount
                    )}
                  >
                    {content.count > 99 ? '99+' : content.count}
                  </Badge>
                ) : null}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <TabContent activeTab={'comments'} className="h-100">
        <TabPane tabId="comments" className="h-100">
          {isInitialCall ? (
            <Loading />
          ) : (
            <CommentSection
              params={params}
              parentId={parentId}
              initiativeId={initiativeId}
              userType={userType}
              isStudyAssigned={isStudyAssigned}
            />
          )}
        </TabPane>
      </TabContent>
    </Col>
  );
};

export default MessageDetails;
