import { store } from 'store';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { each, isObject, map, some, every } from 'lodash';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import history from 'helpers/history';
import useGetFieldFromObjects from './useGetFieldFromObject';

export const roles = {
  COMPANY_ADMIN: [
    'Company_Administrator',
    'Sales_Staff',
    'Sales_Administrator',
  ],
  BASIC_TEAM_MEMBER: 'Basic_Team_Member',
};

export const permissions = {
  VIEW_ALL_PROJECTS_TEAM: 'view all projects team',
  VIEW_MY_PROJECTS_TEAM: 'view my projects team',
  VIEW_TEAM_MEMBER_RATE: 'view team member rate',
  REMOVE_TEAM_MEMBER: 'remove team members',
  ADD_TEAM_MEMBER: 'add team members',
  VIEW_ALL_STORIES: 'view all stories',
  VIEW_PROJECT_STORIES: 'view project stories',
  DELETE_ALL_STORIES: 'delete all stories',
  DELETE_MY_STORIES: 'delete my stories',
  DELETE_PROJECT_STORIES: 'delete project stories',
  EDIT_ALL_STORIES: 'edit all stories',
  EDIT_ASSIGNED_STORIES: 'edit assigned stories',
  EDIT_MY_PROJECTS: 'edit my projects',
  EDIT_ALL_PROJECTS: 'edit all projects',
  EDIT_PROJECT_STORIES: 'edit project stories',
  VIEW_ALL_PROJECTS_KANBAN: 'view all projects kanban',
  VIEW_MY_PROJECTS_KANBAN: 'view my projects kanban',
  VIEW_COMMENTS: 'view comments',
  POST_COMMENTS: 'post comments',
  UPDATE_ALL_COMMENTS: 'update all comments',
  UPDATE_MY_COMMENTS: 'update my comments',
  DELETE_ALL_COMMENTS: 'delete all comments',
  DELETE_MY_COMMENTS: 'delete my comments',
  VIEW_ALL_PROJECTS_DASHBOARD: 'view all projects dashboard',
  VIEW_MY_PROJECTS_DASHBOARD: 'view my projects dashboard',
  VIEW_COMPANY_SETTINGS: 'view company settings',
  UPDATE_COMPANY_SETTINGS: 'update company settings',
  DELETE_ALL_BACKLOG_SECTIONS: 'delete all backlog sections',
  DELETE_PROJECT_BACKLOG_SECTIONS: 'delete project backlog sections',
  UPDATE_TEAM_MEMBER_RATE: 'update team member rate',
  ADD_EPICS_TO_ALL_PROJECTS: 'add epics to all projects',
  ADD_EPICS_TO_MY_PROJECTS: 'add epics to my projects',
  LIST_EPICS: 'list epics',
  EDIT_ALL_EPICS: 'edit all epics',
  EDIT_PROJECT_EPICS: 'edit project epics',
  DELETE_ALL_EPICS: 'delete all epics',
  DELETE_PROJECT_EPICS: 'delete project epics',
  EDIT_ALL_BACKLOG_SECTIONS: 'edit all backlog sections',
  EDIT_PROJECT_BACKLOG_SECTIONS: 'edit project backlog sections',
  ADD_STORIES_TO_ALL_BACKLOG_SECTIONS: 'add stories to all backlog sections',
  ADD_STORIES_TO_PROJECT_BACKLOG_SECTIONS:
    'add stories to project backlog sections',
  PRIORITIZE_ALL_PROJECTS_BACKLOG: 'prioritize all projects backlog',
  PRIORITIZE_MY_PROJECTS_BACKLOG: 'prioritize my projects backlog',
  ADD_SECTIONS_TO_ALL_PROJECTS: 'add sections to all projects',
  ADD_SECTIONS_TO_MY_PROJECTS: 'add sections to my projects',
  MASS_UPDATE_STORIES_OF_ALL_SECTIONS: 'mass update stories of all sections',
  MASS_UPDATE_STORIES_OF_MY_SECTIONS:
    'mass update stories of my projects sections',
  MASS_DELETE_STORIES_OF_ALL_SECTIONS: 'mass delete stories of all sections',
  MASS_DELETE_STORIES_OF_MY_SECTIONS:
    'mass delete stories of my projects sections',
  REORDER_ALL_BACKLOG_SECTIONS: 'reorder all backlog sections',
  REORDER_MY_PROJECTS_BACKLOG_SECTIONS: 'reorder my projects backlog sections',
  LIST_THEMES: 'list themes',
  EDIT_ALL_USER_PROFILES: 'edit all user profiles',
  EDIT_MY_PROFILE: 'edit my profile',
  EDIT_USER_ROLES: 'edit user roles',
  VIEW_ALL_TIMELOGS: 'view all timelogs',
  VIEW_MY_TIMELOGS: 'view my timelogs',
  DISCOUNT_TIMELOGS: 'discount timelogs',
  APPROVE_TIMELOGS: 'approve timelogs',
  EDIT_ALL_TIMELOGS: 'edit all timelogs',
  EDIT_MY_TIMELOGS: 'edit my timelogs',
  DELETE_ALL_TIMELOGS: 'delete all timelogs',
  DELETE_MY_TIMELOGS: 'delete my timelogs',
  CREATE_PROJECTS: 'create projects',
  VIEW_ALL_PROJECTS: 'view all projects',
  VIEW_MY_PROJECTS: 'view my projects',
  DELETE_ALL_PROJECTS: 'delete all projects',
  DELETE_MY_PROJECTS: 'delete my projects',
  DEACTIVATE_USERS: 'deactivate users',
  MANGAGE_KB_CATEGORIES: 'manage kb categories',
  LIST_ARTICLES: 'list articles',
  CREATE_ARTICLES: 'create articles',
  VIEW_ALL_ARTICLES: 'view all articles',
  EDIT_ALL_ARTICLES: 'edit all articles',
  DELETE_ALL_ARTICLES: 'edit all articles',
  VIEW_MY_ARTICLES: 'view my articles',
  EDIT_MY_ARTICLES: 'edit my articles',
  DELETE_MY_ARTICLES: 'edit my articles',
  CREATE_TIMELOGS: 'create timelogs',
  LIST_TIMELOGS: 'list timelogs',
  LIST_PROJECTS: 'list projects',
  LIST_CLIENTS: 'list clients',
  CREATE_CLIENTS: 'create clients',
  ACCESS_PERSONAL_KANBAN: 'access personal kanban',
  ACCESS_GLOBAL_KANBAN: 'access global kanban',
  VIEW_EVERYONES_KANBAN: 'view everyones kanban',
  CREATE_TASK_TYPES: 'create task types',
  EDIT_TASK_TYPES: 'edit task types',
  DELETE_TASK_TYPES: 'delete task types',
  CUSTOMIZE_ALL_PROJECTS_KANBAN: 'customize all projects kanban',
  CUSTOMIZE_MY_PROJECTS_KANBAN: 'customize my projects kanban',
  INVITE_USERS: 'invite users',
  VIEW_ALL_PROJECTS_QUICK_ACTION_WIDGET:
    'view all projects quick action widget',
  VIEW_MY_PROJECTS_QUICK_ACTION_WIDGET: 'view my projects quick action widget',
  VIEW_ALL_PROJECTS_BACKLOG: 'view all projects backlog',
  VIEW_MY_PROJECTS_BACKLOG: 'view my projects backlog',
  CREATE_DELIVERABLES: 'create deliverables',
  VIEW_DELIVERABLES: 'list deliverables',
  DELETE_DELIVERABLES: 'delete deliverables',
  EDIT_DELIVERABLES: 'update deliverables',
  ENABLE_PAYROLL_REPORT_INTEGRATION: 'enable payroll reports integration',
  ENABLE_GENERAL_LEDGER_INTEGRATION: 'enable general ledger integration',
  SUBMIT_DOCUMENTS: 'submit documents',
  LIST_PARTNERS: 'list partners',
  VIEW_PARTNER: 'view partner',
  INVITE_PARTNER: 'invite partner',
  DELETE_PARTNER: 'delete partner',
  EDIT_PARTNER: 'edit partner',
};

export const isAdmin = () => {
  const storeData = store.getState();
  const currentRoles = get(storeData, 'auth.user.roles', []) || [];

  const rs = currentRoles.some(role => {
    return roles.COMPANY_ADMIN.indexOf(role) >= 0 ? true : false;
  });

  return rs ? true : false;
};

export const useAccess = (permissions, eitherOr = true) => {
  const currentPermissions =
    useGetFieldFromObjects('auth', 'user.permissions', []) || [];

  const isPermissionAllowed = permission => {
    return currentPermissions.includes(permission);
  };

  const finalPermissions = [].concat(permissions);

  const allPermission = map(finalPermissions, perm => {
    const isAllowed = isPermissionAllowed(
      isObject(perm) ? get(perm, 'permission') : perm
    );
    const operation = get(perm, 'operation', 'and');
    const otherBoolean = get(perm, 'value', true);
    switch (operation) {
      case 'and':
        return isAllowed && otherBoolean;
      default:
        return isAllowed || otherBoolean;
    }
  });
  if (eitherOr) {
    // if eitherOr = true that means at least one permission should be satisfied
    return allPermission.some(perm => !!perm);
  } else {
    // if eitherOr = false that means all permission should be satisfied
    return allPermission.every(perm => !!perm);
  }
};

export const showUnAuhtorizedError = (message, redirect = true) => {
  NotificationHandler.open({
    message: message || 'You do not have access to that page',
    operation: 'failure',
  });

  if (redirect) {
    history.push('/admin/dashboard');
  }
};

export const useGetAvailablePermission = (perm1, perm2, allVals) => {
  const isAllowedAccessPerm1 = useAccess(perm1);
  const isAllowedAccessPerm2 = useAccess(perm2);
  return map(
    allVals,
    val => isAllowedAccessPerm1 || (isAllowedAccessPerm2 && val)
  );
};
