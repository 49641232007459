import Request from './request';

export const getDocumentTypes = async () => {
  return Request.call({
    url: '/document-types',
    method: 'GET',
  });
};

export const getInviteRoles = async () => {
  return Request.call({
    url: '/roles/list/dropdown?level_name=Client',
    method: 'GET',
  });
};

export const getFinchDetails = async data => {
  return Request.call({
    url: '/finch/callback',
    method: 'POST',
    data,
  });
};

export const getCodatConnectUrl = async () => {
  return Request.call({
    url: '/codat/connect',
    method: 'POST',
  });
};

export const getCodatDetails = async params => {
  return Request.call({
    url: '/codat/callback',
    method: 'POST',
    params,
  });
};

export const getDisconnectFinch = async () => {
  return Request.call({
    url: '/finch/disconnect',
    method: 'POST',
  });
};

export const getDisconnectCodat = async () => {
  return Request.call({
    url: '/codat/disconnect',
    method: 'POST',
  });
};
