import React from 'react';
import PropTypes from 'prop-types';
import classes from './ContentEditor.module.scss';
import cs from 'classnames';

import dompurify from 'dompurify';
const ContentEditor = ({ className, content, ...rest }) => {
  const sanitizer = dompurify.sanitize;
  return (
    <div
      className={cs(classes.root, className)}
      dangerouslySetInnerHTML={{
        __html: sanitizer(content, {
          ALLOWED_TAGS: [
            'b',
            'p',
            'strong',
            'em',
            'blockquote',
            'code',
            'br',
            'ul',
            'li',
            'ol',
            'i',
            'u',
            'a',
            'img',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
          ],
          ALLOWED_ATTR: ['style', 'href', 'src', 'rel', 'target', 'class'],
        }),
      }}
      {...rest}
    />
  );
};

ContentEditor.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
};

export default ContentEditor;
