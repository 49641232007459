/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Form, Col, UncontrolledTooltip } from 'reactstrap';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import Types from 'store/types/knowledgeBaseTypes';
import { getModifiedOrder } from 'helpers/dragDropHelpers';
import {
  addCategory,
  doDeleteCategoryById,
  editCategory as updateCategory,
  reorderCategories as reorganizeCategories,
} from 'store/actions/knowledgeBaseActions';
import Input from 'components/FormFields/Input';
import Dropdowns from 'components/Dropdowns';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import toNumber from 'lodash/toNumber';
import classNames from 'classnames';
import classes from './categoriesModal.module.scss';
import pluralize from 'pluralize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import ScrollBar from 'react-perfect-scrollbar';
import { useDebounce } from 'react-use';
import analytics, { analyticsConstants } from 'helpers/analytics';

const CategoriesModal = ({ closeModal, isOpen }) => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.knowledge_base,
      ...rest,
    });
  };
  const addCategoryRef = useRef(null);
  const retRef = useRef(null);
  const [isReorder, setIsReorder] = useState(0);
  const assignRef = ref => (retRef.current = ref);
  const data = useSelector(({ knowledgeBase }) =>
    get(knowledgeBase, 'allCategories.data', [])
  );
  const createdCategory = useSelector(({ knowledgeBase }) =>
    get(knowledgeBase, 'createCategory.id', null)
  );

  useDebounce(async () => dispatch(reorganizeCategories(data)), 500, [
    isReorder,
  ]);

  const elementToFocus = useRef(null);
  useEffect(() => {
    if (elementToFocus.current) {
      const offsetTop = elementToFocus.current.offsetTop;
      retRef.current.scrollTop = offsetTop;
      dispatch({
        type: Types.RESET_CREATE_CATEGORY,
      });
    }
  });
  const [editCategoryId, setEditCategoryId] = useState();
  const [newCategoryName, setNewCategoryName] = useState();
  const newCategory = name => {
    if (!isEmpty(trim(name))) {
      analyticsSendEvent({
        action: analyticsConstants.action.rename_kb_category,
      });
      dispatch(updateCategory(editCategoryId, trim(name)));
    }
    setEditCategoryId();
  };

  const deleteCategory = data => {
    analyticsSendEvent({
      action: analyticsConstants.action.delete_kb_category,
    });
    dispatch(doDeleteCategoryById(data.id, data, data.id));
  };
  const reorderCategories = ({ source, destination }) => {
    const getReorderedData = async () =>
      getModifiedOrder({
        source,
        destination,
        data,
      });
    getReorderedData().then(reorderedData => {
      if (reorderedData) {
        const { modifiedData } = reorderedData;
        setIsReorder(prevState => prevState + 1);
        dispatch({
          type: Types.UPDATE_CATEGORY_STATE,
          data: modifiedData,
        });
      }
    });
  };
  const deleteCategoryConfirm = row => {
    const text =
      row.articles === 0
        ? `You are about to delete "${row.name}". Do you want to continue?`
        : `There ${pluralize('is', toNumber(row.articles))} ${
            row.articles
          } ${pluralize('article', toNumber(row.articles))} assigned to "${
            row.name
          }" that will also be deleted. Do you want to continue?`;
    AlertPopupHandler.open({
      onConfirm: deleteCategory,
      confirmBtnText: 'Delete Category',
      text,
      data: row,
    });
  };
  const portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');
  document.body.appendChild(portal);
  const conditionallyCreatePortal = (isDragging, element) => {
    return isDragging ? createPortal(element, portal) : element;
  };

  const createCategory = () => {
    analyticsSendEvent({
      action: analyticsConstants.action.create_kb_category,
    });
    dispatch(addCategory(newCategoryName, data));
    setNewCategoryName('');
    addCategoryRef.current.focus();
  };
  return (
    <Modal
      centered
      title="Categories"
      toggle={closeModal}
      scrollable
      isOpen={isOpen}
    >
      <div
        className={classNames(
          classes['categories-title'],
          'd-flex border-bottom border-top'
        )}
      >
        <Col
          className={classNames(classes['category-heading-column'], 'pl-0')}
          xs={8}
        >
          <h6 className={classNames('text-gray text-weight-bold mb-0')}>
            Category
          </h6>
        </Col>
        <Col className="pr-0" xs={4}>
          <h6 className={classNames('text-gray text-weight-bold mb-0')}>
            Articles
          </h6>
        </Col>
      </div>
      <ModalBody className={classNames(classes['content-wrapper'], 'p-0 m-0')}>
        <DragDropContext
          onDragEnd={(...args) => {
            analyticsSendEvent({
              action: analyticsConstants.action.drag_kb_category,
            });
            reorderCategories(...args);
          }}
        >
          <ScrollBar
            className={classes['content-wrapper-scroller']}
            containerRef={assignRef}
          >
            <Droppable droppableId="all-categories">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {data.map((row, index) => {
                    return (
                      <Draggable
                        draggableId={row.id.toString()}
                        index={index}
                        key={row.id}
                      >
                        {(provided, snapshot) => {
                          return conditionallyCreatePortal(
                            snapshot.isDragging,
                            <div
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              className={classNames(
                                'd-flex align-items-center flex-row py-4 bg-white border-bottom',
                                snapshot.isDragging && 'border-top',
                                data.length - 1 > index && 'border-bottom'
                              )}
                            >
                              {row.id === createdCategory && (
                                <div ref={elementToFocus} />
                              )}
                              <Col
                                className={classNames(
                                  'd-flex justify-content-start w-100 align-items-center'
                                )}
                                xs={editCategoryId === row.id ? 10 : 8}
                              >
                                <i
                                  {...provided.dragHandleProps}
                                  className="fas fa-grip-vertical text-light mr-2"
                                />
                                {editCategoryId === row.id ? (
                                  <Input
                                    defaultValue={row.name}
                                    onBlur={event =>
                                      newCategory(event.target.value)
                                    }
                                    maxLength={255}
                                    autoFocus
                                    onKeyDown={event => {
                                      if (event.keyCode === 13) {
                                        newCategory(event.target.value);
                                      }
                                    }}
                                  />
                                ) : (
                                  <>
                                    <div
                                      className={classes['category-name']}
                                      id={`category-name-${row.id}`}
                                    >
                                      <h5 title={null} className="m-0">
                                        {row.name}
                                      </h5>
                                    </div>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target={`category-name-${row.id}`}
                                    >
                                      {row.name}
                                    </UncontrolledTooltip>
                                  </>
                                )}
                              </Col>
                              <Col
                                className={classNames(
                                  `d-flex justify-content-${
                                    editCategoryId === row.id
                                      ? 'end'
                                      : 'between'
                                  } align-items-center pr-0`,
                                  editCategoryId === row.id && 'pl-0'
                                )}
                                xs={editCategoryId === row.id ? 2 : 4}
                              >
                                {editCategoryId !== row.id && (
                                  <div
                                    className={classNames(
                                      'd-flex justify-content-center align-items-center',
                                      classes['articles']
                                    )}
                                  >
                                    {row.articles}
                                  </div>
                                )}
                                {row.guid !== '1' && (
                                  <span className="d-flex align-items-center h-100  mr-3">
                                    <Dropdowns
                                      text={<i className="fas fa-ellipsis-v" />}
                                      className="btn-icon-only m-0 text-light float-right"
                                      options={[
                                        {
                                          text: 'Rename Category',
                                          onClick: () => {
                                            setEditCategoryId(row.id);
                                          },
                                        },
                                        {
                                          text: 'Delete Category',
                                          onClick: () =>
                                            deleteCategoryConfirm(row),
                                        },
                                      ]}
                                      caret={false}
                                      size="sm"
                                      color=""
                                    />
                                  </span>
                                )}
                              </Col>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </ScrollBar>
        </DragDropContext>
      </ModalBody>
      <ModalFooter className="p-2 border-top">
        <Form className="d-flex justify-content-between w-100 align-items-center">
          <Input
            innerRef={addCategoryRef}
            placeholder="Create a new category"
            onChange={event => setNewCategoryName(event.target.value)}
            value={newCategoryName}
            maxLength={255}
          />
          <div>
            <Button
              type="submit"
              outline={isEmpty(trim(newCategoryName))}
              className={classNames(
                'btn-icon btn-icon-only rounded-circle ml-3',
                classes['create-category-btn']
              )}
              color={isEmpty(trim(newCategoryName)) ? '' : 'primary'}
              onClick={event => createCategory(event.target.value)}
              disabled={isEmpty(trim(newCategoryName))}
            >
              <i className="fas fa-plus" />
            </Button>
          </div>
        </Form>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(CategoriesModal);
