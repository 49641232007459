import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BaseTable from 'components/Table';
import get from 'lodash/get';
import classnames from 'classnames';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import DocumentIconImg from 'assets/img/icons/document-comment-icon.svg';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import { ReactComponent as PreviewIcon } from 'assets/img/icons/eye-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/download-icon.svg';

import classes from '../AllDocumentModal.module.scss';
import { downloadDocument } from 'store/actions/documents';
import { getAllDocumentList } from 'store/actions/fulfillment';
import { withTime } from 'helpers/times';

const ListView = ({ handleDocumentViewer, limit = 20, filters }) => {
  const dispatch = useDispatch();
  const loading = useSelector(({ fulfillment }) =>
    get(fulfillment, 'allDocumentList.isInProgress', false)
  );

  const studyId = useSelector(({ project }) =>
    get(project, 'getProject.data.data.id', '')
  );
  const downloadLoading = useGetFieldFromObject(
    'documents',
    'downloadDoc.isInProgress',
    false
  );

  const data = useSelector(({ fulfillment }) =>
    get(fulfillment, 'allDocumentList.data.data', [])
  );

  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });

  useEffect(() => {
    dispatch(getAllDocumentList(studyId, { limit, ...filters }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyId, filters]);

  const NoDataIndication = () => (
    <div className="d-flex align-items-center p-4 justify-content-center">
      No Files Uploaded
    </div>
  );

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort" />;
    else if (order === 'asc') return <i className="fas fa-sort-up" />;
    else if (order === 'desc') return <i className="fas fa-sort-down" />;
    return null;
  };

  const handleDownload = index => {
    dispatch(downloadDocument(data[index]));
  };

  const handleTableChange = async (type, { sortOrder, sortField }) => {
    if (type === 'pagination') {
    } else if (type === 'sort') {
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
    }
    const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
    const filter = { sort, limit, ...filters };
    await dispatch(getAllDocumentList(studyId, filter));
  };

  return (
    <BaseTable
      keyField="id"
      bootstrap4
      remote
      loading={loading}
      noDataIndication={NoDataIndication}
      search={false}
      bordered={false}
      paginationOptions={false}
      wrapperClasses={classes.tableHeader}
      data={data}
      onTableChange={handleTableChange}
      columns={[
        {
          dataField: 'name',
          text: 'Name',
          sort: true,
          sortCaret: renderSortCaret,
          formatter: (cell, row, index) => (
            <div
              className={classnames(
                classes.fileName,
                'table-data  d-flex w-100 align-items-center'
              )}
              onClick={() => {
                if (row?.mime_type === 'application/zip') {
                  handleDownload(index);
                } else {
                  handleDocumentViewer([row]);
                }
              }}
            >
              <span>
                <img src={DocumentIconImg} alt="doc" />
              </span>
              <span id={`file-name-${row.id}`}>{cell}</span>
            </div>
          ),
        },
        {
          dataField: 'doc_type',
          text: 'Bucket',
          sort: true,
          sortCaret: renderSortCaret,
          formatter: (cell, row, index) => {
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-between w-100 align-items-center'
                )}
              >
                <span className={classes.cursor}>{cell}</span>
              </div>
            );
          },
        },
        {
          dataField: 'size',
          text: 'Size',
          sort: true,
          sortCaret: renderSortCaret,
          formatter: (cell, row, index) => {
            const size = Math.round(+cell / 1024);
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-between w-100 align-items-center'
                )}
              >
                <span
                  onClick={() => {
                    handleDocumentViewer([row]);
                  }}
                  className={classes.cursor}
                >
                  {size} KB
                </span>
              </div>
            );
          },
        },
        {
          dataField: 'created_at',
          text: 'Date Created',
          sort: true,
          sortCaret: renderSortCaret,
          headerStyle: () => {
            return { width: '218px' };
          },
          formatter: (cell, row, index) => {
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-between w-100 align-items-center'
                )}
              >
                <span className={classes.cursor}>
                  {withTime(cell, { format: 'date-time' })}
                </span>
              </div>
            );
          },
        },
        {
          dataField: 'downloaded_on',
          text: 'Date Downloaded',
          sort: true,
          sortCaret: renderSortCaret,
          headerStyle: () => {
            return { width: '218px' };
          },
          formatter: (cell, row, index) => {
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-between w-100 align-items-center'
                )}
              >
                <span className={classes.cursor}>
                  {cell ? withTime(cell, { format: 'date-time' }) : '-'}
                </span>
              </div>
            );
          },
        },
        {
          dataField: '',
          text: '',
          headerStyle: () => {
            return { width: '218px' };
          },
          formatter: (cell, row, index) => {
            return (
              <div
                className={classnames(
                  classes.tData,
                  'table-data d-flex justify-content-end w-100 align-items-center'
                )}
              >
                <span>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className={classnames(classes.dropdownMenu)}>
                      <DropdownItem
                        className={classes.downloadText}
                        onClick={() => handleDownload(index)}
                        disabled={downloadLoading}
                      >
                        <DownloadIcon />
                        <span>Download</span>
                      </DropdownItem>
                      <DropdownItem
                        className={classes.downloadText}
                        onClick={() => {
                          handleDocumentViewer([row]);
                        }}
                      >
                        <PreviewIcon /> <span>Preview</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </span>
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default ListView;
