class TooltipForChartHandler {
  ref = null;

  open = (position, tooltipModel, content) => {
    this.ref.open(position, tooltipModel, content);
  };

  close = () => {
    this.ref.close();
  };

  setRef = ref => {
    this.ref = ref;
  };
}

export default new TooltipForChartHandler();
