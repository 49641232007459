import Types from '../types/supportStaffs';
import { slice, findIndex, get } from 'lodash';

const initialState = {
  supportStaffsState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default function SupportStaffsReducers(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_SUPPORT_STAFFS_INPROGRESS:
      return {
        ...state,
        supportStaffsState: {
          ...state.supportStaffsState,
          isInProgress: true,
        },
      };
    case Types.FETCH_SUPPORT_STAFFS_SUCCESS:
      return {
        ...state,
        supportStaffsState: {
          ...state.supportStaffsState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_SUPPORT_STAFFS_FAILURE:
      return {
        ...state,
        supportStaffsState: {
          ...state.supportStaffsState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
