import { get } from 'lodash';
import {
  fetchTimezones as fetchMetaTimezones,
  getIndustries,
  getAccountTypes,
  getCompanySizes,
  getLeadSources,
  getYears,
  getContactTerms,
  getQuarters,
  getControllerGroupFillingTypes,
  getEntityTypes,
  getAccountLevel,
  getStudyPhases,
} from 'api/metadata';
import Types from '../types/metadata';

export const fetchTimezones = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_TIMEZONES_INPROGRESS,
    });

    try {
      const resp = await fetchMetaTimezones();

      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_TIMEZONES_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_TIMEZONES_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TIMEZONES_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchIndustries = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_INDUSTRIES_INPROGRESS });
    try {
      const resp = await getIndustries();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_INDUSTRIES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_INDUSTRIES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_INDUSTRIES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchAccountTypes = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_ACCOUNT_TYPES_INPROGRESS });
    try {
      const resp = await getAccountTypes();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_ACCOUNT_TYPES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_ACCOUNT_TYPES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ACCOUNT_TYPES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchCompanySizes = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_COMPANY_SIZE_INPROGRESS });
    try {
      const resp = await getCompanySizes();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_COMPANY_SIZE_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_COMPANY_SIZE_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_COMPANY_SIZE_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchLeadSources = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_LEAD_SOURCES_INPROGRESS });
    try {
      const resp = await getLeadSources();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_LEAD_SOURCES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_LEAD_SOURCES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_LEAD_SOURCES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchYears = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_YEARS_LIST_INPROGRESS });
    try {
      const resp = await getYears();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_YEARS_LIST_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_YEARS_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_YEARS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchContactTerms = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CONTACT_TERMS_INPROGRESS });
    try {
      const resp = await getContactTerms();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_CONTACT_TERMS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_CONTACT_TERMS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CONTACT_TERMS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchQuarters = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_QUARTERS_INPROGRESS });
    try {
      const resp = await getQuarters();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_QUARTERS_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_QUARTERS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_QUARTERS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchControllerGroupFillingTypes = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_CONTROLLER_GROUP_FILLING_TYPES_INPROGRESS });
    try {
      const resp = await getControllerGroupFillingTypes();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_CONTROLLER_GROUP_FILLING_TYPES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_CONTROLLER_GROUP_FILLING_TYPES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CONTROLLER_GROUP_FILLING_TYPES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchEntityTypes = () => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_ENITIY_TYPES_INPROGRESS });
    try {
      const resp = await getEntityTypes();
      const status = get(resp, 'status');
      if (status) {
        const data = get(resp, 'data');
        dispatch({
          type: Types.FETCH_ENTITY_TYPES_SUCCESS,
          data,
        });
      } else {
        const message = get(resp, 'message');
        dispatch({
          type: Types.FETCH_ENTITY_TYPES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ENTITY_TYPES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchAccountLevels = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_ACCOUNT_LEVELS_INPROGRESS,
    });
    try {
      const resp = await getAccountLevel();
      const { status, data, message } = resp;
      if (status) {
        dispatch({
          type: Types.FETCH_ACCOUNT_LEVELS_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.FETCH_ACCOUNT_LEVELS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_ACCOUNT_LEVELS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchStudyPhases = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_STUDY_PHASES_INPROGRESS,
    });
    try {
      const resp = await getStudyPhases();
      const { status, data, message } = resp;
      if (status) {
        dispatch({
          type: Types.FETCH_STUDY_PHASES_SUCCESS,
          data,
        });
      } else {
        dispatch({
          type: Types.FETCH_STUDY_PHASES_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STUDY_PHASES_FAILURE,
        message: error,
      });
    }
  };
};
