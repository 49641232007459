import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import PartnerManager from '../PartnerManager';
import classes from './Support.module.scss';

const Support = () => {
  const clientOpportunityType = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.contract.type', 'ertc')
  );
  const partnerManager = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.partner_manager', null)
  );
  return (
    <div className={classes.support}>
      <div className={classes.title}>
        <p>Your Dedicated Strike Team Member is on standby to help!</p>
      </div>
      {partnerManager && (
        <div className={classes.partnerManger}>
          <PartnerManager />
        </div>
      )}
      <div className={classes.promises}>
        <h3>The Strike Promise</h3>
        <div className={classes.point}>
          <img
            src={require('assets/img/icons/dollar-outlined-icon.svg')}
            alt="icon"
          />
          <div>
            <h4>No Out-of-Pocket Costs.</h4>
            <p>
              There are no retainers, monthly billings, or fix-fees upfront.
            </p>
          </div>
        </div>
        <div className={classes.point}>
          <img
            src={require('assets/img/icons/check-outlined-icon.svg')}
            alt="icon"
          />
          <div>
            <h4>Success-Based Fee Structure</h4>
            <p>
              Strike doesn’t get paid until you receive or utilize your credits.
            </p>
          </div>
        </div>
        <div className={classes.point}>
          <img
            src={require('assets/img/icons/thunder-outlined-icon.svg')}
            alt="icon"
          />
          <div>
            <h4>Strike Shield™ </h4>
            <p>Unlimited audit protection and support to defend credits.</p>
          </div>
        </div>
        <div className={classes.point}>
          <img
            src={require('assets/img/icons/smile-outlined-icon.svg')}
            alt="icon"
          />
          <div>
            <h4>100% Fee-Back Guarantee</h4>
            <p>If the IRS takes back credits, our fee goes too.</p>
          </div>
        </div>
      </div>
      <div className={classes.bottomCard}>
        <h4>Did you know...</h4>
        <h3>
          {clientOpportunityType === 'ertc'
            ? 'Time to claim the ERTC is running out!'
            : 'The R&D tax credit has a lookback period of three years.'}
        </h3>
        <p>
          {clientOpportunityType === 'ertc'
            ? 'While the extension to retroactively claim the credit has increased from 3 years to 5 years, time goes quickly. Don’t miss your window to claim your cash refund!'
            : 'You are able to look back and file an amended return up to three years from the original filing date. On the utilization side, you can roll credits forward for up to 20 years!'}
        </p>
      </div>
    </div>
  );
};

export default Support;
