import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchAssigmentTypes,
  createAssignmentType,
  editAssignmentType,
} from 'store/actions/assignmentTypesCustomization';
import classes from './AssignmentTypeCustomizations.module.scss';
import useBreakPoint from 'helpers/useBreakPoint';
import cx from 'classnames';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Button from 'components/Button';
import AssignmentCustomizationForm from './AssignmentTypeCustomization.Form';
import { permissions, useAccess } from 'helpers/permission';
import Greetings from './Greetings';
import AssignmentTypeTable from './AssignmentTypeTable';
import { useParams, useHistory } from 'react-router';
import get from 'lodash/get';
import analytics, { analyticsConstants } from 'helpers/analytics';

const AssignmentTypeCustomizations = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setModal] = useState(false);
  const editValues = useRef(null);
  const viewMode = useRef(null);
  const params = useParams();
  const history = useHistory();
  const modalOpen = get(params, 'modalOpen');
  useEffect(() => {
    if (modalOpen) {
      viewMode.current = 'create';
      setModal(true);
      history.replace('/admin/assignment-types');
    }
  }, [history, modalOpen]);
  const analyticsSendEvent = useCallback(({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.customizations,
      ...rest,
    });
  }, []);

  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_assignment_types,
    });
  }, [analyticsSendEvent]);
  const isMobile = useBreakPoint('sm', 'down');
  const isTablet = useBreakPoint('md', 'down');

  const isCreateTaskTypeAllowed = useAccess(permissions.CREATE_TASK_TYPES);

  const tableSearchText = useRef('');
  const tablePageNumber = useRef(1);

  const closeModal = () => {
    setModal(false);
    viewMode.current = '';
    editValues.current = null;
  };

  const submitValues = async values => {
    setModal(false);
    if (viewMode.current !== 'edit') {
      await dispatch(createAssignmentType(values));
      analyticsSendEvent({
        action: analyticsConstants.action.create_assignment_type,
      });
    } else {
      await dispatch(
        editAssignmentType({
          ...editValues.current,
          ...values,
        })
      );
      analyticsSendEvent({
        action: analyticsConstants.action.edit_assignment_type,
      });
    }
    dispatch(
      fetchAssigmentTypes(tableSearchText.current, tablePageNumber.current)
    );
  };

  const customProps = {
    isTablet,
    isMobile,
    editValues,
    viewMode,
    dispatch,
    setModal,
    analyticsSendEvent,
  };

  return (
    <>
      {isModalOpen && (
        <AssignmentCustomizationForm
          {...{
            isModalOpen,
            submitValues,
            closeModal,
            viewMode: viewMode.current,
            editValues: editValues.current,
          }}
        />
      )}
      <Greetings {...customProps} />
      <Card className={cx('mt--4', isTablet ? 'mx-3' : classes.card)}>
        <CardHeader className="d-flex align-items-center justify-content-between">
          <h3 className="m-0">Assignment Types</h3>
          {isCreateTaskTypeAllowed && (
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={() => {
                viewMode.current = 'create';
                setModal(true);
              }}
            >
              Add Type
            </Button>
          )}
        </CardHeader>
        <CardBody className="p-0">
          <AssignmentTypeTable {...customProps} />
        </CardBody>
      </Card>
    </>
  );
};

export default AssignmentTypeCustomizations;
