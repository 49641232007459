import React from 'react';
import PropTypes from 'prop-types';
import classes from './ToggleCheckBox.module.scss';
import cs from 'classnames';
const ToggleCheckBox = ({
  className,
  checkedLabel,
  unCheckedLabel,
  ...rest
}) => {
  return (
    <div className={cs(classes.root, className)}>
      <label className="custom-toggle">
        <input {...rest} type="checkbox" />
        <span
          data-label-on={checkedLabel}
          data-label-off={unCheckedLabel}
          className="custom-toggle-slider rounded-circle"
        />
      </label>
    </div>
  );
};

ToggleCheckBox.propTypes = {
  className: PropTypes.string,
};

ToggleCheckBox.defaultProps = {
  className: '',
  checkedLabel: '',
  unCheckedLabel: '',
};

export default ToggleCheckBox;
