import React, { useCallback } from 'react';
import classes from './Notes.module.scss';
import infoIcon from 'assets/img/icons/info-popup-icon.svg';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import NoteItem from '../NoteItem';
import { debounce, get, partition } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNote, pinNote } from 'store/actions/accountOverview';

const Notes = ({ setAddEditNoteModal, clientId }) => {
  const notes = useSelector(({ notes }) => get(notes, 'getNotes.data', []));
  const dispatch = useDispatch();

  const [pinnedNotes, otherNotes] = partition(notes, note => note.pinned_by);

  const handleEditNote = note => {
    setAddEditNoteModal({ isOpen: true, note: note });
  };

  const handleDelete = async id => {
    await dispatch(deleteNote(clientId, id));
  };

  const handleDeleteNote = id => {
    AlertPopupHandler.open({
      type: 'custom',
      warning: true,
      success: false,
      showCancel: true,
      customIcon: infoIcon,
      onConfirm: async () => {
        await handleDelete(id);
      },
      confirmBtnText: `Delete`,
      confirmBtnCssClass: classes.confirmButton,
      cancelBtnCssClass: classes.cancelButton,
      customClass: classes.customPopupClass,
      focusConfirmBtn: false,
      text: (
        <div className={classes.deleteinfoText}>
          Deleting this note can not be undone.
        </div>
      ),
    });
  };

  const pinToggle = useCallback(
    debounce(async note => {
      await dispatch(
        pinNote(clientId, { ...note, pinned_by: !note?.pinned_by })
      );
    }, 300),
    [dispatch, clientId]
  );
  return (
    <div className={classes.notesContent}>
      <div className={classes.noteType}>Pinned Notes</div>
      {pinnedNotes?.length > 0 ? (
        pinnedNotes?.map((note, index) => {
          return (
            <NoteItem
              key={index}
              note={note}
              index={index}
              handleDeleteNote={handleDeleteNote}
              handleEditNote={handleEditNote}
              pinToggle={pinToggle}
            />
          );
        })
      ) : (
        <div className="d-flex justify-content-center">
          There are no pinned notes.
        </div>
      )}
      <div className={classes.noteType}>Other Notes</div>
      {otherNotes?.length > 0 ? (
        otherNotes?.map((note, index) => {
          return (
            <NoteItem
              key={index}
              note={note}
              index={index}
              handleDeleteNote={handleDeleteNote}
              handleEditNote={handleEditNote}
              pinToggle={pinToggle}
            />
          );
        })
      ) : (
        <div className="d-flex justify-content-center">There are no notes.</div>
      )}
    </div>
  );
};

export default Notes;
