import get from 'lodash/get';
import Types from '../types/users';

const initialState = {
  userState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  roleOptions: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  clientRoleOptions: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  updateUserRole: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  inviteUsersState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  impersonateState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
    partnerId: null,
    guestPartner: {},
  },
};

export default function User(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_USERS_INPROGRESS:
      return {
        ...state,
        userState: {
          ...state.userState,
          isInProgress: true,
        },
      };
    case Types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        userState: {
          ...state.userState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_USERS_DATA:
      return {
        ...state,
        userState: {
          ...state.userState,
          isInProgress: false,
          status: 1,
          data: {
            ...get(state, 'userState.data', []),
            data: action.data,
          },
        },
      };
    case Types.FETCH_USERS_FAILURE:
      return {
        ...state,
        userState: {
          ...state.userState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_USER_ROLES_INPROGRESS:
      return {
        ...state,
        roleOptions: {
          ...state.roleOptions,
          isInProgress: true,
        },
      };
    case Types.FETCH_USER_ROLES_SUCCESS:
      return {
        ...state,
        roleOptions: {
          ...state.roleOptions,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_USER_ROLES_FAILURE:
      return {
        ...state,
        roleOptions: {
          ...state.roleOptions,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_INVITE_CLIENT_ROLES_INPROGRESS:
      return {
        ...state,
        clientRoleOptions: {
          ...state.clientRoleOptions,
          isInProgress: true,
        },
      };
    case Types.FETCH_INVITE_CLIENT_ROLES_SUCCESS:
      return {
        ...state,
        clientRoleOptions: {
          ...state.clientRoleOptions,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_INVITE_CLIENT_ROLES_FAILURE:
      return {
        ...state,
        clientRoleOptions: {
          ...state.clientRoleOptions,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_USER_ROLE_INPROGRESS:
      return {
        ...state,
        updateUserRole: {
          ...state.updateUserRole,
          isInProgress: true,
        },
      };
    case Types.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        updateUserRole: {
          ...state.updateUserRole,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_USER_ROLE_FAILURE:
      return {
        ...state,
        updateUserRole: {
          ...state.updateUserRole,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.INVITE_MEMBERS_INPROGRESS:
      return {
        ...state,
        inviteUsersState: {
          ...state.inviteUsersState,
          isInProgress: true,
        },
      };
    case Types.INVITE_MEMBERS_SUCCESS:
      return {
        ...state,
        inviteUsersState: {
          ...state.inviteUsersState,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.INVITE_MEMBERS_FAILURE:
      return {
        ...state,
        inviteUsersState: {
          ...state.inviteUsersState,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.IMPERSONATE_USER_INPROGRESS:
      return {
        ...state,
        impersonateState: {
          ...state.impersonateState,
          isInProgress: true,
        },
      };
    case Types.IMPERSONATE_USER_SUCCESS:
      return {
        ...state,
        impersonateState: {
          ...state.impersonateState,
          isInProgress: false,
          status: 1,
          data: action.data,
          partnerId: action.data,
        },
      };
    case Types.IMPERSONATE_USER_FAILURE:
      return {
        ...state,
        impersonateState: {
          ...state.impersonateState,
          isInProgress: false,
          isError: true,
          message: action.message,
          partnerId: null,
        },
      };
    case Types.IMPERSONATE_INACTIVE_USER_SUCCESS:
      return {
        ...state,
        impersonateState: {
          ...state.impersonateState,
          isInProgress: false,
          status: 1,
          guestPartner: action.data,
        },
      };
    case Types.EXIT_IMPERSONATE_USER:
      return {
        ...state,
        impersonateState: {
          ...state.impersonateState,
          isInProgress: false,
          isError: false,
          message: '',
          status: 0,
          data: {},
          guestPartner: {},
        },
      };
    default:
      return state;
  }
}
