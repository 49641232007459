import React, { useEffect } from 'react';
import classes from './ProgressBarPhase.module.scss';
import { Progress } from 'reactstrap';
import cx from 'classnames';

const ProgressBarPhase = ({ phase, currentPhase, isFulfillment }) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--progress-bar-color',
      phase.color
    );
  }, [phase.color]);

  return (
    <div
      className={cx(classes.barWrapper, {
        [classes.isFulfillmentWrapper]: isFulfillment,
      })}
    >
      <div
        className={cx(classes.titleContainer, {
          [classes.fulfillmentTitleContainer]: isFulfillment,
        })}
      >
        <p
          className={cx(classes.title, {
            [classes.active]:
              phase.name === currentPhase.name ||
              (phase.progress !== 0 && !phase?.completed_at),
          })}
        >
          {phase.name}
        </p>
        {isFulfillment && (
          <i
            className="far fa-file-alt"
            style={{ color: phase.progress === 100 ? '#08A5EF' : '#DEE2E6' }}
          ></i>
        )}
      </div>
      <div
        className={cx(classes.bar, {
          [classes.active]: phase.name === currentPhase || phase.progress,
          [classes.isFulfillmentBar]: isFulfillment,
        })}
      >
        <Progress
          style={{ '--progress-bar-color': phase.color }}
          className="mb-0"
          value={phase.progress}
        ></Progress>
      </div>
    </div>
  );
};

export default ProgressBarPhase;
