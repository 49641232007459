import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import FileDropZone from 'components/FormFields/FileDropZone';
import { downloadAttachment } from 'store/actions/Story/fileAttachments';
export const FileAttachment = ({
  title = 'Attachment',
  attachments,
  isEditAllowed,
  onChange,
  onRemove,
  shouldAppendFiles = true,
}) => {
  return (
    <>
      <h4>{title}</h4>
      {isEmpty(attachments) && !isEditAllowed ? (
        <p className="text-muted">No Attachments</p>
      ) : (
        <FileDropZone
          onRemove={onRemove}
          showFileDeletingLoader={true}
          handleFileChange={onChange}
          shouldAllowDownload={true}
          downloadAttachment={(...args) => {
            downloadAttachment(...args);
          }}
          inputProps={{
            multiple: true,
          }}
          isUpdateAllowed={isEditAllowed}
          attachment={attachments}
          shouldAppendFiles={shouldAppendFiles}
        />
      )}
    </>
  );
};

FileAttachment.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object),
  storyId: PropTypes.number,
  isEditAllowed: PropTypes.bool,
};

FileAttachment.defaultProps = {
  isEditAllowed: false,
};

export default FileAttachment;
