import get from 'lodash/get';
import filter from 'lodash/filter';
import Types from 'store/types/story';

const initalState = {
  isInProgress: false,
  isError: false,
  status: 0,
  message: '',
  data: {},
  id: undefined,
  updateStory: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case Types.FETCH_STORY_INPROGRESS:
      return {
        ...state,
        isInProgress: true,
        isError: false,
        message: '',
        id: undefined,
      };
    case Types.FETCH_STORY_SUCCESS:
      return {
        ...state,
        isInProgress: false,
        data: action.data,
        status: 1,
        isError: false,
        message: '',
        id: undefined,
        updateStory: {
          ...state.updateStory,
          isInProgress: false,
          isError: false,
          message: '',
        },
      };
    case Types.FETCH_STORY_FAILURE:
      return {
        ...state,
        isInProgress: false,
        isError: true,
        message: action.message,
        id: action.id,
      };
    case Types.UPDATE_STORY_INPROGRESS:
      return {
        ...state,
        updateStory: {
          ...state.updateStory,
          isInProgress: true,
        },
      };
    case Types.UPDATE_STORY_SUCCESS:
      return {
        ...state,
        data: action.data,
        isError: false,
        message: '',
        updateStory: {
          ...state.updateStory,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_STORY_FAILURE:
      return {
        ...state,
        updateStory: {
          ...state.updateStory,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case Types.FILE_DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          attachments: filter(
            get(state.data, 'attachments', []),
            media => media.id !== action.data
          ),
        },
      };
    case Types.ADD_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          tasks: [...get(state, 'data.tasks', []), action.data],
        },
      };
    default:
      return state;
  }
};
