import React from 'react';
import cs from 'classnames';

const StoryCell = ({ row, cell }) => {
  return (
    <div className="d-flex flex-column justify-content-between align-items-left">
      <div className="pb-2">
        <b>{row.story.id ? row.story.name : <i>{row.story.name}</i>}</b>
      </div>

      <div className={cs('text-muted', 'pb-2')}>
        {row.epic.id ? row.epic.name : <i>{row.epic.name}</i>}
        {' | '}
        {row.task_type.id ? row.task_type.name : <i>{row.task_type.name}</i>}
      </div>

      {row.description !== 'No Description' ? (
        row.description
      ) : (
        <i>{'No Description'}</i>
      )}
    </div>
  );
};

export default StoryCell;
