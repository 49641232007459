import Types from 'store/types/knowledgeBaseTypes';
import {
  getCategories,
  getArticles,
  postCreateArticle,
  putUpdateArticle,
  getArticleById,
  getArticlesMostRated,
  createCategory,
  delArticleById,
  postLikeDisLikeArticleById,
  delCategoryById,
  updateCategory,
  organizeCategories,
} from 'api/knowledgeBaseApi';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const reorderCategories = newOrder => {
  return async dispatch => {
    dispatch({
      type: Types.REORDER_CATEGORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      organizeCategories(newOrder).then(resp => {
        const status = get(resp, 'status');

        if (status) {
          dispatch({
            type: Types.REORDER_CATEGORY_SUCCESS,
            data: resp || {},
          });
          dispatch(
            doGetCategories({
              status: 'published',
              myArticles: '',
            })
          );
        } else {
          dispatch({
            type: Types.REORDER_CATEGORY_FAILURE,
            message: 'Something went wrong',
          });
        }
      });
    } catch (error) {
      dispatch({
        type: Types.REORDER_CATEGORY_FAILURE,
        message: error,
      });
    }
  };
};

export const addCategory = (name, categoryData) => {
  return async dispatch => {
    dispatch({
      type: Types.CREATE_CATEGORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createCategory(name);
      const status = get(resp, 'status');
      const categoryId = get(resp, 'data.id');
      const elementToEnterBefore = findIndex(
        categoryData,
        elem => elem.sort_order > resp.data.sort_order
      );
      const newCategoryData =
        elementToEnterBefore === -1
          ? [...categoryData, resp.data]
          : categoryData.splice(elementToEnterBefore - 1, 0, resp.data);
      if (status) {
        dispatch({
          type: Types.CREATE_CATEGORY_SUCCESS,
          categoryId,
          data: newCategoryData,
        });
      } else {
        dispatch({
          type: Types.CREATE_CATEGORY_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CREATE_CATEGORY_FAILURE,
        message: error,
      });
    }
  };
};

export const doGetCategories = (filter = null) => {
  return async dispatch => {
    dispatch({
      type: Types.CATEGORIES_LOADING,
    });

    const resp = await getCategories(filter);
    if (resp.status === 1) {
      dispatch({
        type: Types.CATEGORIES_SUCCESS,
        status: resp.status,
        data: resp.data,
      });

      const categories = resp.data;

      categories.map(cate => {
        dispatch(
          doGetArticles(
            cate.id,
            get(filter, 'status'),
            get(filter, 'myArticles')
          )
        );
        return true;
      });
    } else {
      dispatch({
        type: Types.CATEGORIES_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const doGetAllCategories = (filter = { all: true }) => {
  return async dispatch => {
    dispatch({
      type: Types.GET_ALL_CATEGORIES_LOADING,
    });

    try {
      const resp = await getCategories(filter);
      if (resp.status === 1) {
        dispatch({
          type: Types.GET_ALL_CATEGORIES_SUCCESS,
          status: resp.status,
          data: resp.data,
        });
      } else {
        dispatch({
          type: Types.GET_ALL_CATEGORIES_ERROR,
          status: resp.status,
          message: resp.message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.GET_ALL_CATEGORIES_ERROR,
        message: 'Something went wrong',
      });
    }
  };
};

export const doGetArticles = (
  categoryId,
  status = null,
  myArticles = null,
  page = 1
) => {
  return async dispatch => {
    dispatch({
      type: Types.ARTICLES_LOADING,
      category: categoryId,
    });

    const resp = await getArticles(categoryId, status, myArticles, page);

    if (resp.status === 1) {
      dispatch({
        type: Types.ARTICLES_SUCCESS,
        status: resp.status,
        data: resp.data,
        meta: resp.meta,
        category: categoryId,
      });
    } else {
      dispatch({
        type: Types.ARTICLES_ERROR,
        status: resp.status,
        message: resp.message,
        category: categoryId,
      });
    }
  };
};

export const doPostCreateArticle = (
  title,
  categoryId,
  content,
  status,
  success = null,
  error = null
) => {
  return async dispatch => {
    const resp = await postCreateArticle(title, categoryId, content, status);

    if (resp.status === 1) {
      NotificationHandler.open({
        message: resp.message,
        operation: 'success',
      });

      if (success) success(resp.data);
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
      if (error) error();
    }
  };
};

export const doPutUpdateArticle = (
  id,
  title,
  categoryId,
  content,
  status,
  success = null,
  error = null
) => {
  return async dispatch => {
    const resp = await putUpdateArticle(id, title, categoryId, content, status);

    dispatch({
      type: Types.ARTICLE_LOADING,
    });

    if (resp.status === 1) {
      NotificationHandler.open({
        message: resp.message,
        operation: 'success',
      });

      dispatch({
        type: Types.ARTICLE_SUCCESS,
        status: resp.status,
        data: resp.data,
      });

      if (success) success();
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });

      dispatch({
        type: Types.ARTICLE_ERROR,
        status: resp.status,
        message: resp.message,
      });

      if (error) error();
    }
  };
};

export const doGetArticleById = (id, success = null, error = null) => {
  return async dispatch => {
    dispatch({
      type: Types.ARTICLE_LOADING,
    });

    const resp = await getArticleById(id);

    if (resp.status === 1) {
      dispatch({
        type: Types.ARTICLE_SUCCESS,
        status: resp.status,
        data: resp.data,
      });

      if (success) success(resp.data);
    } else {
      dispatch({
        type: Types.ARTICLE_ERROR,
        status: resp.status,
        message: resp.message,
      });

      if (error) error();
    }
  };
};

export const doGetArticlesMostRated = () => {
  return async dispatch => {
    dispatch({
      type: Types.ARTICLES_MOST_LOADING,
    });

    const resp = await getArticlesMostRated();

    if (resp.status === 1) {
      dispatch({
        type: Types.ARTICLES_MOST_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      dispatch({
        type: Types.ARTICLES_MOST_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const doDeleteArticleById = (id, success = null, error = null) => {
  return async dispatch => {
    const resp = await delArticleById(id);

    if (resp.status === 1) {
      NotificationHandler.open({
        title: 'Success',
        message: resp.message,
        operation: 'success',
      });

      if (success) success(resp.data);
    } else {
      NotificationHandler.open({
        message: resp.message,
        operation: 'failure',
      });
      if (error) error();
    }
  };
};

export const doDeleteCategoryById = id => {
  return async dispatch => {
    try {
      const resp = await delCategoryById(id);
      const message = get(resp, 'message');
      if (resp.status === 1) {
        NotificationHandler.open({
          title: 'Category Deleted Successfully',
          message,
          operation: 'success',
        });
      } else {
        NotificationHandler.open({
          message,
          operation: 'failure',
          title: 'Something went wrong',
        });
      }
    } catch (error) {
      NotificationHandler.open({
        message: error,
        operation: 'failure',
        title: 'Something went wrong',
      });
    }
    dispatch(
      doGetCategories({
        status: 'published',
        myArticles: '',
      })
    );
    dispatch(doGetAllCategories());
  };
};

export const doPostLikeDisLikeArticleById = (id, type) => {
  return async dispatch => {
    const resp = await postLikeDisLikeArticleById(id, type);

    dispatch({
      type: Types.ARTICLE_LOADING,
    });

    if (resp.status === 1) {
      dispatch({
        type: Types.ARTICLE_SUCCESS,
        status: resp.status,
        data: resp.data,
      });
    } else {
      dispatch({
        type: Types.ARTICLE_ERROR,
        status: resp.status,
        message: resp.message,
      });
    }
  };
};

export const editCategory = (categoryId, name) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_CATEGORY_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateCategory(categoryId, name);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.UPDATE_CATEGORY_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.UPDATE_CATEGORY_FAILURE,
          message: 'Something went wrong',
        });
      }
      dispatch(
        doGetCategories({
          status: 'published',
          myArticles: '',
        })
      );
      dispatch(doGetAllCategories());
    } catch (error) {
      dispatch({
        type: Types.UPDATE_CATEGORY_FAILURE,
        message: error,
      });
    }
  };
};
