import React, { useEffect, useState } from 'react';
import classes from './Interactions.module.scss';
import Input from 'components/FormFields/Input';
import RSelect from 'components/FormFields/RSelect';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import { components } from 'react-select';
import DateRangePicker from 'components/FormFields/DateRangePicker';
import { ReactComponent as FilterIcon } from 'assets/img/icons/filter-icon.svg';
import { useSelector } from 'react-redux';
import { get, debounce } from 'lodash';
import Button from 'components/Button';
import cx from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';

const FilterCountIcon = ({ value = 1, className = '' }) => {
  return (
    <svg
      className={className}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.5"
        cy="7.5"
        r="6.75"
        fill="white"
        stroke="#3C4D69"
        strokeWidth="0.5"
      ></circle>
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        fontFamily="Open Sans"
        fontSize="10px"
        fontWeight="600"
        fill="#3C4D69"
        dy=".3em"
      >
        {value}
      </text>
    </svg>
  );
};

const filterMenuOptions = [
  {
    label: 'Status',
    options: [
      { value: 'resolved', label: 'Resolved', type: 'status' },
      { value: 'unresolved', label: 'Unresolved', type: 'status' },
    ],
  },
  {
    label: 'Interaction Type',
    options: [
      { value: 'message', label: 'Messages', type: 'activity_type' },
      { value: 'document_upload', label: 'Doc Uploads', type: 'activity_type' },
    ],
  },
  {
    label: 'Date Sort',
    options: [
      {
        value: 'desc',
        label: 'Descending (default)',
        type: 'sort_order',
      },
      { value: 'asc', label: 'Ascending', type: 'sort_order' },
    ],
  },
];

const InteractionFilter = ({ handleSetFilters }) => {
  const documents = useSelector(({ documents }) =>
    get(documents, `documentStudyTypes.data.data`, [])
  );

  const clientId = useSelector(({ project }) =>
    get(project, 'getProject.data.data.client.id', null)
  );

  const docBucketOptions = [
    { value: 'all', label: 'All' },
    ...documents.map(data => ({
      label: data?.short_name,
      value: data?.short_name,
    })),
  ];

  const [searchText, setSearchText] = useState('');
  const [clientUser, setClientUser] = useState([]);
  const [docBucket, setDocBucket] = useState([]);

  const initialFilterState = [
    {
      value: 'desc',
      label: 'Descending (default)',
      type: 'sort_order',
    },
  ];

  const [filter, setFilter] = useState(initialFilterState);
  const [onApplyFilterValue, setOnApplyFilterValue] = useState();
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateRange, setDateRange] = useState();

  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <Input
            label={props.label}
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
        </components.Option>
      </div>
    );
  };

  const MenuWithHeader = ({ children, clearValue = () => {}, ...props }) => {
    return (
      <components.Menu {...props}>
        {children}
        <div className={classes.menuHeader}>
          <Button onClick={clearValue} color="link">
            Clear
          </Button>
        </div>
      </components.Menu>
    );
  };

  const FilterMenuWithHeader = ({
    children,
    clearValue = () => {},
    ...props
  }) => {
    return (
      <components.Menu {...props}>
        {children}
        <div className={classes.actions}>
          <Button
            className={classes.clear}
            onClick={() => {
              clearValue();
              setOpenFilterMenu(false);
            }}
            color="link"
          >
            Clear
          </Button>
          <Button
            onClick={() => {
              const convertedOptions = filter.reduce((acc, option) => {
                const type = option.type;
                if (!acc[type]) {
                  acc[type] = [];
                }
                acc[type].push(option.value);
                return acc;
              }, {});
              setOnApplyFilterValue({
                ...convertedOptions,
                sort_order: convertedOptions?.sort_order?.[0],
              });
              setOpenFilterMenu(false);
            }}
            color="primary"
            className={classes.apply}
          >
            Apply
          </Button>
        </div>
      </components.Menu>
    );
  };

  useEffect(() => {
    const filter = {
      user_ids: clientUser
        ?.filter(data => data.value !== 'all')
        ?.map(d => d.id),
      buckets: docBucket
        ?.filter(data => data.value !== 'all')
        ?.map(d => d.value),
      start_date: startDate ? startDate.format('YYYY-MM-DD') : null,
      end_date: endDate ? endDate.format('YYYY-MM-DD') : null,
      keyword: searchText,
      ...onApplyFilterValue,
    };
    handleSetFilters(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    docBucket,
    searchText,
    startDate,
    endDate,
    onApplyFilterValue,
    clientUser,
  ]);

  const MoreSelectedBadge = ({ items }) => {
    const style = {
      marginLeft: 'auto',
      borderRadius: '4px',
      fontFamily: 'Open Sans',
      color: '#525F7F',
      fontSize: '11px',
      order: 99,
    };

    const title = items.join(', ');
    const length = items.length;
    const label = `+ ${length} more`;

    return (
      <div style={style} title={title}>
        {label}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }) => {
    const maxToShow = 1;
    const overflow = getValue()
      .slice(maxToShow)
      .map(x => x.label);
    return index < maxToShow ? (
      <components.MultiValue
        className={cx('multi-value_label_default', {
          'multi-value_label': overflow?.length >= 1,
        })}
        {...props}
      />
    ) : index === maxToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null;
  };

  const rSelectStyles = {
    menu: ({ width, ...css }) => ({
      ...css,
      width: 'max-content',
      minWidth: '100%',
    }),
  };

  const searchInputChanged = e => {
    setSearchText(e.target.value);
  };

  const handleChange = e => {
    e.persist();

    delayedSearch(e);
  };

  const delayedSearch = debounce(searchInputChanged, 500);

  return (
    <div className={classes.wrapper}>
      <div className={classes.filterWrapper}>
        <div className={classes.selectWrapper}>
          <div className={classes.search}>
            <Input
              className={classes.input}
              label="Search"
              placeholder="Start typing..."
              onChange={handleChange}
              rightIcon={<i className="fa fa-search" aria-hidden="true"></i>}
            />
          </div>
        </div>
        <div className={classes.filters}>
          <div className={classes.select}>
            <RSelectAsync
              isMulti
              isClearable={false}
              styles={rSelectStyles}
              hideSelectedOptions={false}
              name="client_user"
              closeMenuOnSelect={false}
              label="Client User:"
              url={clientId ? `/clients/${clientId}/users` : ''}
              getOptionLabel={opt => opt.name}
              getOptionValue={opt => opt.id}
              value={clientUser}
              onChange={values => {
                setClientUser(values || []);
              }}
              components={{
                Option: Option,
                MultiValue: MultiValue,
                Menu: MenuWithHeader,
              }}
            />
          </div>
          <div className={classes.select}>
            <RSelect
              isMulti
              isClearable={false}
              name="doc_bucket"
              label="Doc Bucket:"
              closeMenuOnSelect={false}
              styles={rSelectStyles}
              hideSelectedOptions={false}
              options={docBucketOptions}
              getOptionLabel={opt => opt.label}
              getOptionValue={opt => opt.value}
              value={docBucket}
              onChange={(values, option) => {
                const isAllSelected = values?.findIndex(v => v.value === 'all');
                if (isAllSelected !== -1 && option.action !== 'remove-value') {
                  setDocBucket(docBucketOptions);
                } else {
                  setDocBucket(values || []);
                }
              }}
              components={{
                Option: Option,
                MultiValue: MultiValue,
                Menu: MenuWithHeader,
              }}
            />
          </div>
          <div className={classes.select}>
            <DateRangePicker
              label="Date:"
              placeholder="Date"
              showIcon={false}
              startDate={startDate}
              datePickerClass={classes.datePicker}
              endDate={endDate}
              value={dateRange}
              onChange={dates => {
                if (dates.startDate && dates.endDate) {
                  setStartDate(dates.startDate);
                  setEndDate(dates.endDate);
                  const rangeDate =
                    dates.startDate.format('YYYY-MM-DD') +
                    ' - ' +
                    dates.endDate.format('YYYY-MM-DD');
                  setDateRange(rangeDate);
                } else {
                  setDateRange(dateRange);
                }
              }}
            />
          </div>

          <div className={classes.filterIcon}>
            <OutsideClickHandler
              onOutsideClick={() => {
                setOpenFilterMenu(false);
              }}
            >
              <span
                className={classes.filterIconWrapper}
                onClick={() => setOpenFilterMenu(!openFilterMenu)}
              >
                {filter.length !== 0 && (
                  <FilterCountIcon
                    className={classes.countIcon}
                    value={filter.length}
                  />
                )}
                <FilterIcon />
              </span>
              {openFilterMenu && (
                <div className={classes.select}>
                  <RSelect
                    isMulti
                    isClearable={false}
                    menuIsOpen={true}
                    name="filter"
                    label=""
                    closeMenuOnSelect={false}
                    styles={rSelectStyles}
                    hideSelectedOptions={false}
                    options={filterMenuOptions}
                    getOptionLabel={opt => opt.label}
                    getOptionValue={opt => opt.value}
                    value={filter}
                    onChange={(values, option) => {
                      const type = option?.option?.type;
                      const value = option?.option?.value;
                      if (type === 'sort_order') {
                        if (value === 'desc') {
                          const filteredValues = values.filter(
                            data => data.value !== 'asc'
                          );

                          setFilter(filteredValues);
                        } else if (value === 'asc') {
                          const filteredValues = values.filter(
                            data => data.value !== 'desc'
                          );
                          setFilter(filteredValues);
                        }
                      } else {
                        setFilter(values || []);
                      }
                    }}
                    components={{
                      Option: Option,
                      MultiValue: MultiValue,
                      Menu: props => (
                        <FilterMenuWithHeader
                          {...props}
                          clearValue={() => {
                            setFilter(initialFilterState);
                            const convertedOptions = initialFilterState.reduce(
                              (acc, option) => {
                                const type = option.type;
                                if (!acc[type]) {
                                  acc[type] = [];
                                }
                                acc[type].push(option.value);
                                return acc;
                              },
                              {}
                            );
                            setOnApplyFilterValue({
                              ...convertedOptions,
                              sort_order: convertedOptions?.sort_order?.[0],
                            });
                          }}
                        />
                      ),
                    }}
                  />
                </div>
              )}
            </OutsideClickHandler>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InteractionFilter;
