import classes from '../QuickStartTour.module.scss';
import cs from 'classnames';

export const getUserInvitationSteps = (isSidebarShow, isSidebarPinned) => [
  {
    target: '#sidebarItem-Admin',
    title: 'Invite Team Members',
    content: 'Invite the team members you want to build and collaborate with.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.sidebarIcon,
  },
  {
    target: '#sidebarItem-Users',
    title: 'Invite Team Members',
    content: 'Invite the team members you want to build and collaborate with.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: cs(classes.sidebarIconInner, {
      [classes.translateTooltip]: isSidebarShow && isSidebarPinned,
    }),
  },
  {
    target: '#inviteUserBtn',
    title: 'Invite Team Members',
    content:
      'Click here to start inviting your team members into various projects.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    placement: 'left',
    extraPlacementClass: classes.addBtn,
  },
  {
    target: '#usersEmailInput',
    title: 'Invite Team Members',
    content:
      'Add as many emails as you would like. Separate each email with a comma.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.userEmailInput,
  },
  {
    target: '#inviteUsersBtn',
    title: 'Invite Team Members',
    content: 'Click here to send your email invitations to the team.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'right',
    extraPlacementClass: classes.inviteBtn,
  },
];
