import AuthHeader from 'components/Headers/AuthHeader';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptAndSetupInvite,
  toggleRegisterError,
} from 'store/actions/authActions';
import InfoCircle from 'assets/img/icons/info-circle-dark-icon.svg';
import cs from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import classes from './AcceptAndSetup.module.scss';
import { FormikInput } from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { validatePassword } from 'helpers/constants';
import qs from 'query-string';
import { PRIVACY, TERMS_AND_CONDITIONS } from '../Register/RegisterConstants';
import AuthCardHeader from 'components/Headers/AuthCardHeader';

const AcceptAndSetup = props => {
  const dispatch = useDispatch();
  const auth = useSelector(({ auth }) => auth);
  const [loading, setLoading] = useState(false);

  const queryString = qs.parse(get(props, 'location.search', ''));
  const companyId = get(queryString, 'companyId', '');
  const userEmail = get(queryString, 'email', '');
  const token = get(queryString, 'token', '');
  const isError = get(auth, 'register.isError', false);

  useEffect(() => {
    document.getElementById('footer-main').classList.add(classes.footerMain);
    document.getElementById('root').classList.add(classes.rootStyles);
    document
      .getElementsByClassName('main-content')[0]
      .classList.add(classes.mainContent);

    return () => {
      document
        .getElementById('footer-main')
        .classList.remove(classes.footerMain);
      document.getElementById('root').classList.remove(classes.rootStyles);
      document
        .getElementsByClassName('main-content')[0]
        .classList.remove(classes.mainContent);
    };
  }, []);
  const acceptAndSetupSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Your password should contain at least 8 characters.')
      .test(
        'validate-password',
        'Your password should contain a combination of numbers, upper and lower case letters, and special characters.',
        validatePassword
      )
      .required('Your password should contain at least 8 characters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'The passwords you entered don’t match. ')
      .required('The passwords you entered don’t match. '),
  });

  if (isError) {
    setTimeout(() => {
      dispatch(toggleRegisterError());
    }, 5000);
  }

  const renderAcceptAndSetupForm = ({ handleSubmit, values, isValid }) => {
    return (
      <Form>
        {!!userEmail && (
          <div className={classes.email}>
            <label>Email</label>
            <p>{userEmail}</p>
          </div>
        )}
        <UncontrolledTooltip
          boundariesElement="viewport"
          autohide={false}
          className={cs('custom-tooltip', classes.tooltipCustom)}
          placement="top"
          target="password-tooltip"
          arrowClassName={classes.arrow}
        >
          <div className={classes.content}>
            <p>Password requirements:</p>
            <ul>
              <li>8+ characters </li>
              <li>One Upper Letter</li>
              <li>One Lower Letter</li>
              <li>One Number</li>
              <li>One Special Character</li>
            </ul>
          </div>
        </UncontrolledTooltip>
        <FormikInput
          label={
            <span>
              Password
              <img
                style={{ marginTop: '-4px' }}
                id="password-tooltip"
                src={InfoCircle}
                className="ml-1"
                alt="InfoCircle"
              />
            </span>
          }
          groupClassName={cs('mb-3', classes.inputGroup)}
          name="password"
          placeholder="Create Password"
          type="password"
          leftIcon={<i className="fas fa-lock"></i>}
        />
        <FormikInput
          label="Confirm Password"
          groupClassName={cs('mb-3', classes.inputGroup)}
          name="password_confirmation"
          placeholder="Confirm Password"
          type="password"
          leftIcon={<i className="fas fa-lock"></i>}
        />
        <div className={classes.terms}>
          <p>
            By signing in, you agree to the Strike Tax{' '}
            <a href="/privacy" target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="/terms" target="_blank">
              Terms and Conditions
            </a>{' '}
          </p>
        </div>

        <div className="text-center">
          <Button
            className="mt-4"
            color="primary"
            type="submit"
            block
            loading={loading}
            onClick={handleSubmit}
            disabled={!isValid}
          >
            Create Portal Account
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <AuthHeader
      // title={`Joining ${companyName}`}
      // lead="Fill out the details below to finalize your account setup"
      />
      <Container className={cs('pb-5', classes.container)}>
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className={cs('border-0', classes.acceptAndSetup)}>
              <CardBody className="px-lg-5 py-lg-5">
                {isError === true && (
                  <Alert
                    text={auth.register.message}
                    dismiss={true}
                    fade={true}
                    color="danger"
                  />
                )}
                <AuthCardHeader
                  head="Get Started "
                  title="We’ve already created an account for you! 
                  Create a password to access the portal.
                  "
                />

                <Formik
                  initialValues={{
                    password: '',
                    password_confirmation: '',
                  }}
                  validationSchema={acceptAndSetupSchema}
                  enableReinitialize
                  validateOnMount
                  onSubmit={async values => {
                    const user = {
                      token,
                      name: values.name,
                      email: userEmail,
                      password: values.password,
                      password_confirmation: values.password_confirmation,
                      accepted_tc: true,
                      terms_and_conditions: TERMS_AND_CONDITIONS,
                      privacy_policy: PRIVACY,
                    };
                    setLoading(true);
                    const status = await dispatch(
                      acceptAndSetupInvite(companyId, user)
                    );
                    if (status !== 1) setLoading(false);
                  }}
                >
                  {renderAcceptAndSetupForm}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AcceptAndSetup;
