import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import classes from '../StoryAssignments.module.scss';
import { createPortal } from 'react-dom';
import Button from 'components/Button';
import Input from 'components/FormFields/Input';
// import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import cs from 'classnames';
import CheckListItem from './CheckListItem';
import {
  doAddCheckList,
  doPostReOrderCheckList,
} from 'store/actions/Story/assignments';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getModifiedOrder } from 'helpers/dragDropHelpers';
import get from 'lodash/get';
import { analyticsConstants } from 'helpers/analytics';

const CheckList = props => {
  const dispatch = useDispatch();
  const {
    task,
    reload,
    isEditAllowed,
    isOwner,
    isDependency,
    analyticsSendEvent,
  } = props;
  const completedAt = get(task, 'completed_at');
  const [newItem, setNewItem] = useState('');
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  const submitForm = async () => {
    if (!newItem || loading) return;
    setLoading(true);
    analyticsSendEvent({
      action: analyticsConstants.action.add_assignment_checklist_item,
    });
    await dispatch(doAddCheckList(task.id, { description: newItem }));
    setLoading(false);
    setNewItem('');
    reload();
    if (inputRef.current) inputRef.current.focus();
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') submitForm();
  };

  const checklists = task.checklists || [];

  const reorderCheckLists = async ({ source, destination }) => {
    // dropped outside the list
    if (!destination) {
      return;
    }
    analyticsSendEvent({
      action: analyticsConstants.action.drag_assignment_checklist_item,
    });
    const reorderedData = getModifiedOrder({
      source,
      destination,
      data: checklists,
    });

    const modifiedData = get(reorderedData, 'modifiedData');

    if (modifiedData)
      dispatch(doPostReOrderCheckList(task.id, modifiedData, () => reload()));
  };

  const portal = window.document.getElementById('portal-drag-drop');
  const conditionallyCreatePortal = (isDragging, element) => {
    return isDragging ? createPortal(element, portal) : element;
  };

  return (
    <div className={classes.checkList}>
      <DragDropContext onDragEnd={reorderCheckLists}>
        <Droppable
          droppableId={`checklists-task-${task.id}`}
          type={`checklist-task-${task.id}`}
        >
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {checklists.map((data, index) => {
                return (
                  <Draggable
                    draggableId={`checklist-${data.id.toString()}`}
                    index={index}
                    key={`checklist-${data.id}`}
                    isDragDisabled={isEditAllowed === false}
                  >
                    {(provided, snapshot) => {
                      return conditionallyCreatePortal(
                        snapshot.isDragging,
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={cs(
                            snapshot.isDragging && classes.CheckListItemDragging
                          )}
                          key={data.id}
                        >
                          <CheckListItem
                            completedAt={completedAt}
                            isDependency={isDependency}
                            isOwner={isOwner}
                            isEditAllowed={isEditAllowed}
                            provided={provided}
                            data={data}
                            taskId={task.id}
                            callback={() => reload()}
                            analyticsSendEvent={analyticsSendEvent}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {!completedAt && isEditAllowed && (
        <div className="d-flex justify-content-between w-100 align-items-center">
          <Input
            // autoFocus
            innerRef={inputRef}
            placeholder="Add Checklist Item"
            onChange={event => {
              setNewItem(event.target.value);
            }}
            value={newItem}
            maxLength={255}
            onKeyDown={handleKeyDown}
          />
          <div>
            <Button
              type="button"
              outline={isEmpty(trim(newItem))}
              className={cs('btn-icon btn-icon-only rounded-circle ml-3')}
              color={isEmpty(trim(newItem)) ? '' : 'primary'}
              onClick={submitForm}
              disabled={isEmpty(trim(newItem))}
            >
              <i className="fas fa-plus" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

CheckList.propTypes = {};
CheckList.defaultProps = {};

export default CheckList;
