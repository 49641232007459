import React, { useState, useRef } from 'react';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'reactstrap';
import classes from './DataArea.module.scss';
import cs from 'classnames';
import useClickAway from 'react-use/lib/useClickAway';
import Radio from 'components/FormFields/Radio';

const SortedByDropdown = props => {
  const ref = useRef(null);
  const { sortedBy, sortedType, onChange } = props;
  const [sortedOpen, setSortedOpen] = useState(false);

  useClickAway(ref, _e => {
    setSortedOpen(false);
  });

  return (
    <div
      className={cs(classes.filterDropdown, classes.sortedDropdown)}
      ref={ref}
    >
      <span className={classes.label}>
        SORTED BY:{' '}
        <Dropdown
          className={classes.dropdownMenu}
          isOpen={sortedOpen}
          toggle={() => setSortedOpen(true)}
        >
          <DropdownToggle caret={true}>{sortedBy.text}</DropdownToggle>

          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem>
              Sort by
              <Radio
                id="sort_title"
                label="Title"
                name="stored_at"
                checked={'title' === sortedBy.value}
                value="title"
                onChange={() => {
                  onChange({ text: 'Title', value: 'title' }, sortedType);
                }}
              />
              <Radio
                id="sort_duration"
                label="Hours Logged"
                name="stored_at"
                checked={'duration' === sortedBy.value}
                value="duration"
                onChange={() => {
                  onChange(
                    { text: 'Hours Logged', value: 'duration' },
                    sortedType
                  );
                }}
              />
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem>
              Order
              <Radio
                id="sort_asc"
                label="Ascending"
                name="stored_type"
                checked={'' === sortedType}
                value=""
                onChange={() => {
                  onChange(sortedBy, '');
                }}
              />
              <Radio
                id="sort_desc"
                label="Descending"
                name="stored_type"
                checked={'-' === sortedType}
                value="-"
                onChange={() => {
                  onChange(sortedBy, '-');
                }}
              />
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </span>
    </div>
  );
};

export default SortedByDropdown;
