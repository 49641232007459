import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import { Form, FormGroup, Row, Col } from 'reactstrap';

import classes from './KnowledgeBaseForm.module.scss';
import Editor from 'components/FormFields/Editor/Editor';
import FormModal from 'components/FormFields/FormModal';
import RSelectAsync from 'components/FormFields/RSelectAsync';

import {
  doPostCreateArticle,
  doPutUpdateArticle,
} from 'store/actions/knowledgeBaseActions';
import AutoSaveFormik from 'components/FormFields/AutoSaveFormik';
import { get, isEmpty } from 'lodash';
import analytics, { analyticsConstants } from 'helpers/analytics';

const KnowledgeBaseForm = props => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.knowledge_base,
      ...rest,
    });
  };
  const [loading, setLoading] = useState(false);

  let initialValues = {
    id: get(props.data, 'id', ''),
    title: get(props.data, 'name', ''),
    content: get(props.data, 'content', ''),
    publish: get(props.data, 'status', false),
    category: get(props.data, 'category.id', ''),
    category_name: get(props.data, 'category.name', ''),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required('Title is required'),
    category: Yup.string().required('Category is required'),
    content: Yup.string()
      .ensure()
      .required('Required')
      .test(
        'is-empty',
        'Content can not be empty',
        value =>
          !isEmpty(value.replace(/(<\/?(?:img)[^>]*>)|<[^>]+>/gi, '$1').trim())
      ),
  });

  const renderForm = formik => {
    const heading = props.mode === 'add' ? 'Create Article' : 'Edit Article';

    return (
      <FormModal
        toggle={props.toggle}
        isOpen={props.isOpen}
        heading={heading}
        submit={{
          onClick: formik.handleSubmit,
          isValid: formik.isValid,
          buttonText: `Save`,
          loading,
        }}
        cancel={{
          onClick: () => {
            formik.handleReset();
            props.toggle();
          },
          buttonText: 'Cancel',
        }}
      >
        <Form
          role="form"
          onSubmit={formik.handleSubmit}
          className={classes.root}
        >
          <Row>
            <Col md={8}>
              <FormGroup>
                <RSelectAsync
                  touched={formik.touched.category}
                  error={formik.errors.category}
                  value={
                    formik.values.category && formik.values.category_name
                      ? {
                          id: formik.values.category,
                          name: formik.values.category_name,
                        }
                      : null
                  }
                  placeholder="Select Category*"
                  url="/kb/categories/list/dropdown"
                  getOptionLabel={option => option.name}
                  name="category"
                  onChange={data => {
                    formik.setFieldValue('category', get(data, 'id', ''));
                    formik.setFieldValue(
                      'category_name',
                      get(data, 'name', '')
                    );
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className={classes.publish}>
                <small className="mr-1">Publish</small>
                <label className="custom-toggle custom-toggle-success mr-1">
                  <input
                    defaultChecked={formik.values.publish}
                    onChange={() => {
                      formik.setFieldValue('publish', !formik.values.publish);
                    }}
                    type="checkbox"
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormikInput name="title" placeholder="Title*" type="text" />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Editor
                value={formik.values.content}
                onChange={content => formik.setFieldValue('content', content)}
                className="mb-4"
              />
            </Col>
          </Row>
          <AutoSaveFormik
            onSave={() => {
              if (formik.isValid) {
                handleOnSubmit(formik.values, formik, false);
              }
            }}
          />
        </Form>
      </FormModal>
    );
  };

  const handleOnSubmit = (values, actions = null, closeModal = true) => {
    const title = values.title;
    const content = values.content;
    const categoryId = values.category;
    const status = values.publish ? 1 : 0;
    const id = values.id;
    const originalStatus = get(props, 'data.status', 0);

    if (Boolean(originalStatus) !== Boolean(status)) {
      analyticsSendEvent({
        action:
          analyticsConstants.action[
            originalStatus ? 'unpublish_kb_article' : 'publish_kb_article'
          ],
      });
    }
    let mode = props.mode;
    if (id) mode = 'edit';

    setLoading(true);
    switch (mode) {
      case 'edit':
        analyticsSendEvent({
          action: analyticsConstants.action.edit_kb_article,
        });
        dispatch(
          doPutUpdateArticle(
            id,
            title,
            categoryId,
            content,
            status,
            () => {
              setLoading(false);

              if (closeModal) {
                actions.resetForm();
                props.success();
                props.toggle();
              }
            },
            () => {
              setLoading(false);
            }
          )
        );
        break;

      case 'add':
      default:
        analyticsSendEvent({
          action: analyticsConstants.action.create_kb_article,
        });
        dispatch(
          doPostCreateArticle(
            title,
            categoryId,
            content,
            status,
            data => {
              setLoading(false);
              actions.setFieldValue('id', data.id);

              if (closeModal) {
                actions.resetForm();
                props.success();
                props.toggle();
              }
            },
            () => {
              setLoading(false);
            }
          )
        );
        break;
    }
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, actions) => {
        handleOnSubmit(values, actions);
      }}
    >
      {renderForm}
    </Formik>
  );
};

KnowledgeBaseForm.propTypes = {
  mode: PropTypes.oneOf(['add', 'edit']),
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  success: PropTypes.func,
};

KnowledgeBaseForm.defaultProps = {
  success: () => {},
};

export default KnowledgeBaseForm;
