import React from 'react';
import PropTypes from 'prop-types';
import classes from './RSelect.module.scss';
import cs from 'classnames';
import Select, { components } from 'react-select';
import DropdownIndicator from 'components/DropDownIndicator';
import Input from 'components/FormFields/Input';
import Button from 'components/Button';

export const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <Input
          label={props.label}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
      </components.Option>
    </div>
  );
};

export const MenuWithHeader = ({
  children,
  clearValue = () => {},
  ...props
}) => {
  return (
    <components.Menu {...props}>
      {children}
      <div className={classes.menuHeader}>
        <Button onClick={clearValue} color="link">
          Clear
        </Button>
      </div>
    </components.Menu>
  );
};

export const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: 'auto',
    borderRadius: '4px',
    fontFamily: 'Open Sans',
    color: '#525F7F',
    fontSize: '11px',
    order: 99,
  };
};

export const rSelectStyles = {
  menu: ({ width, ...css }) => ({
    ...css,
    width: 'max-content',
    minWidth: '100%',
  }),
};

export const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 2;
  const overflow = getValue()
    .slice(maxToShow)
    .map(x => x.label);
  return index < maxToShow ? (
    <components.MultiValue
      className={cs('multi-value_label_default', {
        'multi-value_label': overflow?.length >= 1,
      })}
      {...props}
    />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const RSelect = props => {
  const {
    getOptionLabel,
    getOptionValue,
    placeholder,
    onChange,
    isClearable,
    options,
    touched,
    error,
    label,
    components,
    leftIcon,
    leftIconClass,
    ...rest
  } = props;

  return (
    <>
      {label && <label className="form-control-label">{label}</label>}
      <div
        className={cs(
          {
            [classes['is-invalid']]: touched && error,
            [classes.hasLeftIcon]: leftIcon,
          },
          classes.root
        )}
      >
        {leftIcon && (
          <span className={cs(classes.leftIcon, leftIconClass)}>
            {leftIcon}
          </span>
        )}
        <Select
          classNamePrefix="rselect"
          isClearable={isClearable}
          placeholder={placeholder}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          onChange={onChange}
          options={options}
          components={{
            DropdownIndicator,
            ...components,
          }}
          {...rest}
        />

        {error && touched && (
          <div className={classes['invalid-feedback']}>{error}</div>
        )}
      </div>
    </>
  );
};

RSelect.propTypes = {
  label: PropTypes.string,
};

RSelect.defaultProps = {
  isClearable: true,
  placeholder: '',
  getOptionLabel: option => option.text,
  getOptionValue: option => option.id,
  onChange: () => {},
  options: [],
  components: {},
};

export default RSelect;

// all Props : https://react-select.com/props
