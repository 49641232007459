import React from 'react';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Button from 'components/Button';
import InfoCircle from 'assets/img/icons/info-circle.png';
import UserGroupsIcon from 'assets/img/theme/user_groups.svg';
import ProjectsIcon from 'assets/img/theme/projects.svg';
import StorageIcon from 'assets/img/theme/storage.svg';
import { isAdmin } from 'helpers/permission';
import classes from './upgradeAlert.module.scss';
import eventEmitter, { EMIT_EVENTS } from 'helpers/eventEmitter';

const noop = () => {};

class UpgradeAlert {
  constructor() {
    eventEmitter.on(EMIT_EVENTS.SUBSCRIPTION_CHANGE, this.onSubscriptionChange);
  }

  open = ({ title, message, icon }) => {
    AlertPopupHandler.openCustom({
      onConfirm: () => {},
      onCancel: () => {},
      confirmBtnText: '',
      showCancel: false,
      showConfirm: false,
      warning: false,
      custom: true,
      title,
      text: message,
      confirmBtnBsStyle: 'info px-4',
      customClass: classes.upgradeModalRoot,
      ChildTag: 'div',
      customIcon: icon,
    });
  };

  close() {
    AlertPopupHandler.close();
  }

  onSubscriptionChange = sub => {
    if (sub.plan_name === 'Pro') {
      // close alert
      this.close();
      // if user subscribed to pro, then call onAfterUpgrade function
      this.onAfterUpgrade();
      // reset onAfterUpgrade to noop
      this.onAfterUpgrade = noop;
    }
  };

  goToSripe = async () => {
    const windowRef = window.open('about:blank', '_blank');
    windowRef.location = '/admin/billing?subscribeToPro=true';
  };

  // this is function which will be called after user successfully upgrade the plan
  // we will set this.onAfterUpgrade on every function where we need callback facility
  // check showTeamLimit, showStorageLimit, showProjectsLimit function
  onAfterUpgrade = noop;

  renderFooterButton = () => {
    if (isAdmin()) {
      return (
        <>
          <Button className={classes.upgradeButton} onClick={this.goToSripe}>
            Upgrade to Pro
          </Button>
          <div className={classes.noThanksText} onClick={this.close}>
            No thanks, I would rather stay small
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.contactText}>
            <img src={InfoCircle} /> Talk to your admin about upgrading
          </div>
        </>
      );
    }
  };

  showTeamLimit = (successCb = noop) => {
    this.onAfterUpgrade = successCb;
    this.open({
      icon: UserGroupsIcon,
      title: "You're Growing! Keep Going!",
      message: (
        <div className={classes.messageBody}>
          <span className={classes.text}>
            You're currently limited to <b>5 teammates</b>.
            <br />
            <b>Invite as many as you like!</b>
          </span>
          {this.renderFooterButton()}
        </div>
      ),
    });
  };

  showStorageLimit = (successCb = noop) => {
    this.onAfterUpgrade = successCb;
    this.open({
      icon: StorageIcon,
      title: "You're Growing! Keep Going!",
      message: (
        <div className={classes.messageBody}>
          <span className={classes.text}>
            Your file storage is limited to <b>1GB</b>.
            <br />
            <b>Upgrade to Unlimited Storage!</b>
          </span>
          {this.renderFooterButton()}
        </div>
      ),
    });
  };

  showProjectsLimit = (successCb = noop) => {
    this.onAfterUpgrade = successCb;
    this.open({
      icon: ProjectsIcon,
      title: "You're Growing! Keep Going!",
      message: (
        <div className={classes.messageBody}>
          <span className={classes.text}>
            You’re currently limited to <b>3 projects</b>.
            <br />
            <b>Open up Unlimited Projects!</b>
          </span>
          {this.renderFooterButton()}
        </div>
      ),
    });
  };
}

export default new UpgradeAlert();
