import React, { useState, useEffect, createRef } from 'react';
import classes from './StoryAssignments.module.scss';
import Button from 'components/Button';
import AssignmentItem from './AssignmentItem';
import {
  doPostAddAssignment,
  doPostReOrderAssignments,
} from 'store/actions/Story/assignments';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getModifiedOrder } from 'helpers/dragDropHelpers';
import { createPortal } from 'react-dom';
import cs from 'classnames';
import maxBy from 'lodash/maxBy';
import analytics, { analyticsConstants } from 'helpers/analytics';

const StoryAssignments = ({ projectId, storyId, isEditAllowed, onChange }) => {
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.assignments,
      ...rest,
    });
  };
  const [loading, setLoading] = useState(false);
  const tasksStore =
    useSelector(({ story }) => get(story, 'details.data.tasks', [])) || [];

  const [tasks, setTasks] = useState(tasksStore);
  const [count, setCount] = useState(0);
  const [scrollTo, setScroll] = useState(0);
  const [refs, setRefs] = useState(
    tasksStore.reduce((acc, task) => {
      acc[task.id] = createRef();
      return acc;
    }, {})
  );

  useEffect(() => {
    setTasks(tasksStore);
    setRefs(
      tasksStore.reduce((acc, task) => {
        acc[task.id] = createRef();
        return acc;
      }, {})
    );
  }, [tasksStore, count]);

  useEffect(() => {
    const assigment = maxBy(tasks, 'id');

    if (scrollTo) {
      refs[assigment.id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo]);

  const reload = (scrollToNew = false) => {
    setCount(count + 1);
    if (scrollToNew) setScroll(scrollTo + 1);
    onChange();
  };

  const portal = window.document.getElementById('portal-drag-drop');
  const conditionallyCreatePortal = (isDragging, element) => {
    return isDragging ? createPortal(element, portal) : element;
  };

  const reorderAssigments = ({ source, destination }) => {
    // dropped outside the list
    if (!destination) {
      return;
    }

    const reorderedData = getModifiedOrder({
      source,
      destination,
      data: tasks,
    });

    const modifiedData = get(reorderedData, 'modifiedData');

    if (modifiedData) {
      analyticsSendEvent({ action: analyticsConstants.action.drag_assignment });
      dispatch(doPostReOrderAssignments(storyId, modifiedData, () => reload()));
    }
  };

  return (
    <div className={classes.root}>
      <h4>Assignments</h4>
      {isEmpty(tasks) && !isEditAllowed ? (
        <p className="text-muted">No Assignments</p>
      ) : (
        <>
          <DragDropContext onDragEnd={reorderAssigments}>
            <Droppable droppableId={`assignments`} type="assignments">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {tasks.map((task, index) => {
                    return (
                      <div key={task.id} ref={refs[task.id]}>
                        <Draggable
                          draggableId={task.id.toString()}
                          index={index}
                          isDragDisabled={isEditAllowed === false}
                        >
                          {(provided, snapshot) => {
                            return conditionallyCreatePortal(
                              snapshot.isDragging,
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={cs(
                                  snapshot.isDragging &&
                                    classes.AssignmentItemDragging
                                )}
                                key={task.id}
                              >
                                <AssignmentItem
                                  isEditAllowed={isEditAllowed}
                                  provided={provided}
                                  reload={reload}
                                  task={task}
                                  projectId={projectId}
                                  storyId={storyId}
                                  analyticsSendEvent={analyticsSendEvent}
                                />
                              </div>
                            );
                          }}
                        </Draggable>
                      </div>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {isEditAllowed && (
            <Button
              color="primary"
              outline
              block
              loading={loading}
              leftIcon={<i className="fas fa-plus"></i>}
              onClick={async () => {
                setLoading(true);
                analyticsSendEvent({
                  action: analyticsConstants.action.add_assignment,
                });
                await dispatch(doPostAddAssignment(storyId));
                setLoading(false);
              }}
            >
              Add Assignment
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default StoryAssignments;
