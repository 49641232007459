import without from 'lodash/without';
import Types from 'store/types/story';

const initalState = {
  uploadFile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteFile: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case Types.FETCH_STORY_SUCCESS:
    case Types.FETCH_STORY_FAILURE:
      return {
        deleteFile: {
          ...state.deleteFile,
          isInProgress: false,
        },
        uploadFile: {
          ...state.uploadFile,
          isInProgress: false,
        },
      };
    case Types.FILE_UPLOAD_INPROGRESS:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          isInProgress: true,
        },
      };
    case Types.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.FILE_UPLOAD_FAILURE:
      return {
        ...state,
        uploadFile: {
          ...state.uploadFile,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FILE_DELETE_INPROGRESS:
      return {
        ...state,
        deleteFile: {
          ...state.deleteFile,
          isInProgress: true,
          data: [...(state.deleteFile.data || []), action.data],
        },
      };
    case Types.FILE_DELETE_SUCCESS:
      return {
        ...state,
        deleteFile: {
          ...state.deleteFile,
          isInProgress: false,
          data: without(state.deleteFile.data || [], action.data),
          status: 1,
        },
      };
    case Types.FILE_DELETE_FAILURE:
      return {
        ...state,
        deleteFile: {
          ...state.deleteFile,
          isInProgress: false,
          isError: true,
          message: action.message,
          data: without(state.deleteFile.data || [], action.data),
        },
      };
    default:
      return state;
  }
};
