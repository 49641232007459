import { getOptions } from 'helpers/clientProfile';

export const getValueFromOptions = (opts, value) => {
  const options = getOptions(opts);
  const selectedIndex = options.findIndex(opt => `${opt.label}` === `${value}`);
  return selectedIndex > -1 ? options[selectedIndex] : null;
};

export const getMultiSelectValueFromOptions = (opts, value) => {
  return value.map(v => {
    return getValueFromOptions(opts, v);
  });
};

export const yesOrNoOptions = {
  Yes: 'Yes',
  No: 'No',
};
