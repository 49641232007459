import React from 'react';
import { Badge } from 'reactstrap';
import PropTypes from 'prop-types';
import classes from './Notification.module.scss';

const NotificationBadge = props => {
  if (props.count > 0) {
    let count = props.count > 99 ? '99+' : props.count;
    return (
      <Badge
        color="danger"
        className={`badge-sm badge-circle badge-floating border-white ${classes.notificationBadge}`}
      >
        {count}
      </Badge>
    );
  } else {
    return null;
  }
};

NotificationBadge.prototype = {
  count: PropTypes.number,
};

export default NotificationBadge;
