import React from 'react';
import Button from 'components/Button';
import PinIcon from '../../views/pages/ClientProfile/AccountOverview/icons/PinIcon';
import UnpinIcon from '../../views/pages/ClientProfile/AccountOverview/icons/UnpinIcon';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

const acceptableRoles = ['Company_Administrator', 'Fulfilment_Director'];

const Pin = ({ pin, pinToggle, className }) => {
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const roles = get(currentUser, 'roles', []);
  const isGlobalRole = acceptableRoles.some(role => roles?.includes(role));

  return (
    <div>
      <Button
        onClick={pinToggle}
        style={{ ...(isGlobalRole ? {} : { cursor: 'not-allowed' }) }}
        disabled={!isGlobalRole}
        className={className}
        rightIcon={pin ? <PinIcon /> : <UnpinIcon />}
      />
    </div>
  );
};

export default Pin;
