import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import FileDropZone from 'components/FormFields/FileDropZone';
import { useSelector, useDispatch } from 'react-redux';
import {
  uploadStoryAttachment,
  deleteStoryAttachment,
  downloadAttachment,
} from 'store/actions/Story/fileAttachments';
import get from 'lodash/get';
import each from 'lodash/each';
import findIndex from 'lodash/findIndex';
import analytics, { analyticsConstants } from 'helpers/analytics';
import UpgradeAlert from 'components/UpgradeAlert';
import { SHOW_UPGRADE_ALERT_CODE } from 'api/request';
export const FileAttachment = ({ attachments, storyId, isEditAllowed }) => {
  const dispatch = useDispatch();
  const removedFileIds = useSelector(({ story }) =>
    get(story, 'attachments.deleteFile.data', [])
  );
  const fileUpload = useSelector(({ story }) =>
    get(story, 'attachments.uploadFile', {})
  );
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const isFileUploadInProgress = get(fileUpload, 'isInProgress', false);

  const onFilesChanges = async attachments => {
    const form = new FormData();
    each(attachments, (attachment, index) => {
      form.append(`attachments[${index}]`, attachment);
    });
    analyticsSendEvent({
      action: analyticsConstants.action.add_attachment,
    });
    const { errorStatus } = await dispatch(
      uploadStoryAttachment(storyId, form)
    );
    if (errorStatus === SHOW_UPGRADE_ALERT_CODE) {
      UpgradeAlert.showStorageLimit();
      return;
    }
  };

  return (
    <>
      <h4>Attachment</h4>
      {isEmpty(attachments) && !isEditAllowed ? (
        <p className="text-muted">No Attachments</p>
      ) : (
        <FileDropZone
          onRemove={(files, removedFile) => {
            if (removedFile.id) {
              analyticsSendEvent({
                action: analyticsConstants.action.delete_attachment,
              });
              dispatch(deleteStoryAttachment(removedFile.id, storyId));
            }
          }}
          deleteIndex={removedFileIds.map(removedFileId =>
            findIndex(
              attachments,
              attachment => attachment.id === removedFileId
            )
          )}
          showFileDeletingLoader={true}
          handleFileChange={onFilesChanges}
          shouldAllowDownload={true}
          downloadAttachment={(...args) => {
            analyticsSendEvent({
              action: analyticsConstants.action.download_attachment,
            });
            downloadAttachment(...args);
          }}
          inputProps={{
            multiple: true,
          }}
          isUpdateAllowed={isEditAllowed}
          attachment={attachments}
          shouldAppendFiles={true}
          showContentLoader={isFileUploadInProgress || !isEmpty(removedFileIds)}
        />
      )}
    </>
  );
};

FileAttachment.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object),
  storyId: PropTypes.number,
  isEditAllowed: PropTypes.bool,
};

FileAttachment.defaultProps = {
  isEditAllowed: false,
};

export default FileAttachment;
