import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import classes from './DocumentSubmit.module.scss';
import { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';

const DocumentSubmit = ({ prev, handleSubmitReview }) => {
  const [loading, setLoading] = useState(false);
  const [collaborators, setCollaborators] = useState([]);

  const strikeTeam = useGetFieldFromObjects(
    'supportStaffs',
    'supportStaffsState.data.data',
    []
  );

  useEffect(() => {
    let users = [];
    if (strikeTeam.team_members) {
      strikeTeam.team_members.forEach(member => {
        users.push({
          name: member.name,
        });
      });
    }
    if (strikeTeam.support_staff) {
      strikeTeam.support_staff.forEach(member => {
        users.push({
          name: member.name,
          role: member.role,
        });
      });
    }
    setCollaborators(users);
  }, [strikeTeam]);

  return (
    <div className={classes.modalContentWrapper}>
      <ModalBody className={cx('d-flex flex-row flex-wrap', classes.modalBody)}>
        <div className={classes.content}>
          <h3 className={classes.title}>
            Great! Let’s get your docs submitted.
          </h3>
          <p className={classes.description}>
            Just a reminder, the following collaborators will be able to view
            your docs once submitted:
          </p>
          <ul className={classes.collaborators}>
            {collaborators.map(({ name, role }, index) => {
              return (
                <li key={index}>
                  {name}
                  {role ? `, ${role}` : ''}
                </li>
              );
            })}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button
          className={classes.back}
          onClick={prev}
          leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
        >
          Back
        </Button>
        <Button
          loading={loading}
          color="primary"
          className={classes.submit}
          onClick={async () => {
            setLoading(true);
            await handleSubmitReview();
            setLoading(false);
          }}
        >
          Submit for Review
        </Button>
      </ModalFooter>
    </div>
  );
};

export default DocumentSubmit;
