import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Input from 'components/FormFields/Input';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import ColorPicker from 'components/ColorPicker';
import replace from 'lodash/replace';
import isEmpty from 'lodash/isEmpty';
import { analyticsConstants } from 'helpers/analytics';

const EssentialInformation = ({
  onChange,
  isAllowedEdit,
  analyticsSendEvent,
}) => {
  const [isColorPickerOpen, setColorPickerOpen] = useState(false);
  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('company', `details.data.${field}`, defaultValue);
  const inputRef = useRef(null);
  const defaultColor = '35B0F0';
  const favoriteAnimal = useGetDetails('favorite_animal');
  const [newFavoriteAnimal, setNewFavoriteAnimal] = useState(favoriteAnimal);
  const color = '#' + (useGetDetails('color') || defaultColor);
  const [selectedColor, setSelectedColor] = useState(color);
  const [selectingColor, setSelectingColor] = useState(false);
  useEffect(() => {
    setSelectedColor(color);
  }, [color]);

  const changeFavoriteAnimal = () => {
    if (isEmpty(newFavoriteAnimal)) {
      setNewFavoriteAnimal(favoriteAnimal);
    } else {
      analyticsSendEvent({
        action: analyticsConstants.action.update_company_animal,
      });
      onChange('favorite_animal', newFavoriteAnimal);
    }
  };
  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Essential Information</h3>
      </CardHeader>
      <CardBody className="px-lg-5">
        <Row>
          <Col md={6}>
            <Input
              alternative={false}
              maxLength={7}
              disabled={!isAllowedEdit}
              innerRef={inputRef}
              inputId="company-color"
              rightIcon={
                <>
                  <i
                    className="fas fa-circle text-xl"
                    style={{
                      color: '#' + replace(selectedColor, '#', ''),
                    }}
                    onClick={() => {
                      if (isAllowedEdit) {
                        setColorPickerOpen(true);
                        inputRef.current.focus();
                      }
                    }}
                  />
                  <i className="ml-2 fas fa-sort" />
                </>
              }
              value={selectedColor}
              onChange={e => {
                const colorWithoutHash = replace(e.target.value, '#', '');
                setSelectedColor(
                  isEmpty(colorWithoutHash)
                    ? e.target.value
                    : '#' + colorWithoutHash
                );
              }}
              label="Company Color"
              placeholder={`#${defaultColor}`}
              onFocus={() => {
                setColorPickerOpen(true);
              }}
              focused={document.activeElement.id === 'company-color'}
              onClick={() => {
                setColorPickerOpen(true);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  if (
                    /^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/.test(selectedColor)
                  ) {
                    analyticsSendEvent({
                      action: analyticsConstants.action.update_company_color,
                    });

                    onChange(
                      'color',
                      replace(selectedColor || defaultColor, '#', '')
                    );
                  } else {
                    setSelectedColor(color);
                  }
                  inputRef.current.blur();
                }
              }}
              onBlur={() => {
                setTimeout(() => {
                  if (!selectingColor) {
                    setColorPickerOpen(false);
                    setSelectedColor(color);
                  }
                }, 200);
              }}
            />
            <ColorPicker
              color={selectedColor}
              targetElement="company-color"
              isOpen={isColorPickerOpen}
              onChange={hex => {
                setSelectedColor(hex);
              }}
              onChangeComplete={() => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_color,
                });

                onChange(
                  'color',
                  replace(selectedColor || defaultColor, '#', '')
                );
                setColorPickerOpen(false);
              }}
              onCancel={() => {
                setSelectedColor(color);
                setColorPickerOpen(false);
              }}
              isUncontrolled={false}
              onMouseUp={() => {
                inputRef.current.focus();
                setSelectingColor(false);
              }}
              onMouseDown={() => {
                setSelectingColor(true);
              }}
              disabled={!isAllowedEdit}
            />
          </Col>
          <Col md={6}>
            <Input
              disabled={!isAllowedEdit}
              label="Favorite Animal"
              defaultValue={newFavoriteAnimal}
              onChange={e => {
                setNewFavoriteAnimal(e.target.value);
              }}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  changeFavoriteAnimal();
                }
              }}
              onBlur={changeFavoriteAnimal}
              placeholder="Favorite Animal"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default EssentialInformation;
