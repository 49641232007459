import React from 'react';
import PropTypes from 'prop-types';
import classes from './LikeAndDislike.module.scss';
import LikeIcon from 'assets/img/icons/like.svg';
import DisLikeIcon from 'assets/img/icons/dislike.svg';
import cs from 'classnames';
import analytics, { analyticsConstants } from 'helpers/analytics';
const LikeAndDislike = props => {
  const { like, dislike, isLiked, isDisLiked } = props;
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.knowledge_base,
      ...rest,
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.button}>
        <button
          onClick={() => {
            if (!isLiked) {
              analyticsSendEvent({
                action: analyticsConstants.action.like_kb_article,
              });
            }
            props.onClick('like');
          }}
          className={cs({ [classes.active]: isLiked === true })}
        >
          <img src={LikeIcon} alt="like button" />
          <span className={classes.count}>{like}</span>
        </button>
      </div>
      <div className={classes.button}>
        <button
          onClick={() => {
            if (!isDisLiked) {
              analyticsSendEvent({
                action: analyticsConstants.action.dislike_kb_article,
              });
            }
            props.onClick('dislike');
          }}
          className={cs({ [classes.active]: isDisLiked === true })}
        >
          <img src={DisLikeIcon} alt="dislike button" />
          <span className={classes.count}>{dislike}</span>
        </button>
      </div>
    </div>
  );
};

LikeAndDislike.propTypes = {
  isLiked: PropTypes.bool,
  isDisLiked: PropTypes.bool,
  like: PropTypes.number.isRequired,
  dislike: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

export default LikeAndDislike;
