import { get } from 'lodash';
import findIndex from 'lodash/findIndex';
import Types from '../../types/story';

const initialState = {
  activities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  moreActivities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function StoryActivities(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_STORY_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: true,
        },
      };
    case Types.FETCH_STORY_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_STORY_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_STORY_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: true,
        },
      };
    case Types.FETCH_MORE_STORY_ACTIVITIES_SUCCESS: {
      let { data } = action;
      let activities = [];
      let existingData = get(state, 'activities.data.data');
      const { data: newActivities, ...rest } = data;
      if (existingData) {
        activities = [...existingData];
        (data.data || []).forEach(activity => {
          let index = findIndex(activity, {
            id: activity.id,
          });
          if (index > -1) {
            activities[index] = activity;
          } else {
            activities.push(activity);
          }
        });
      }
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          status: 1,
        },
        activities: {
          ...state.activities,
          data: {
            ...get(state, 'activities.data', {}),
            data: [...activities],
            ...rest,
          },
        },
      };
    }
    case Types.FETCH_MORE_STORY_ACTIVITIES_FAILURE:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
