import React from 'react';
import PropTypes from 'prop-types';
import UserInfo from 'components/UserInfo';

const CreatedBy = ({ info }) => {
  return (
    <>
      <h4>Created By</h4>
      <UserInfo info={info} />
    </>
  );
};

CreatedBy.propTypes = {
  info: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

CreatedBy.defaultProps = {};

export default CreatedBy;
