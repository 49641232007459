import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import useBreakPoint from 'helpers/useBreakPoint';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import PartnerDashboard from './PartnerDashboard';
import { getClientTasks } from 'store/actions/userDashboard';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { isEmpty } from 'lodash';
import FulfillmentDashboard from './FulfillmentDashboard';
import ClientDashboard from './ClientDashboard';

const Dashboard = () => {
  const dispatch = useDispatch();

  const handleActionToPerform = (params = {}) => {
    dispatch(getClientTasks(params));
  };

  const analyticsSendEvent = (action, label, value) => {
    analytics.sendEvent({
      category: analyticsConstants.category.user_dashboard,
      action,
      label,
      value,
    });
  };

  const isMobile = useBreakPoint('sm', 'down');
  const isTablet = useBreakPoint('md', 'down');
  const useGetDetails = (field, defaultValue) =>
    useGetFieldFromObject('userDashboard', field, defaultValue);
  const commonProps = {
    isMobile,
    getDetails: useGetDetails,
    isTablet,
    analyticsConstants,
    actionToPerform: handleActionToPerform,
  };

  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const impersonateState = useSelector(({ user }) =>
    get(user, 'impersonateState')
  );

  useEffect(() => {
    analyticsSendEvent(analyticsConstants.action.view_personal_dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDashboard = () => {
    if (currentUser?.is_partner || !isEmpty(impersonateState.guestPartner)) {
      return <PartnerDashboard />;
    } else if (currentUser?.is_client) {
      return <ClientDashboard />;
    } else {
      return <FulfillmentDashboard commonProps={commonProps} />;
    }
  };

  return <>{currentUser && getDashboard()}</>;
};

export default Dashboard;
