import React from 'react';
import { Card, CardBody } from 'reactstrap';
import classes from './SupportTeam.module.scss';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';

const SupportTeam = () => {
  const strikeTeam = useGetFieldFromObjects(
    'supportStaffs',
    'supportStaffsState.data.data.support_staff',
    []
  );

  const STRIKE_TEAM = strikeTeam?.map(teamMember => {
    return {
      avatar: teamMember.avatar,
      name: teamMember.name,
      position: teamMember.role,
    };
  });

  return (
    <>
      <Card className={classes.card}>
        <CardBody className={classes.cardBody}>
          <h3>Your Strike Support Team is here to help!</h3>
          {STRIKE_TEAM.map(({ avatar, name, position }, index) => {
            return (
              <div className={classes.teamMember} key={index}>
                <div className={classes.info}>
                  <img src={avatar} />
                  <p>{name}</p>
                </div>
                <div className={classes.position}>
                  <p>{position}</p>
                </div>
              </div>
            );
          })}
        </CardBody>
      </Card>
    </>
  );
};

export default SupportTeam;
