import get from 'lodash/get';
import Types from 'store/types/story';
import { getPriorityOptions } from 'api/backlogs';
export const fetchStoryPriorities = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_STORY_PRIORITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getPriorityOptions();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_STORY_PRIORITIES_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_STORY_PRIORITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STORY_PRIORITIES_FAILURE,
        message: error,
      });
    }
  };
};
