import Types from '../types/onboarding';
import get from 'lodash/get';

const initialState = {
  documentation: {
    isInProgress: false,
    isError: false,
    message: '',
  },
  invitation: {
    isInProgress: false,
    isError: false,
    message: '',
  },
  teamMembers: {
    isInProgress: false,
    isError: false,
    message: '',
  },
  integration: {
    isInProgress: false,
    isError: false,
    message: '',
    data: {},
  },
  deleteTeamMember: {
    isInProgress: false,
    isError: false,
    message: '',
  },
  onboardingSetup: {
    isInProgress: false,
    isError: false,
    status: 0,
    data: {},
    message: '',
  },
};

export default function onboardingReducer(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_ONBOARING_DOCUMENT_TYPES_INPROGRESS:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          isInProgress: true,
          isError: false,
        },
      };
    case Types.FETCH_ONBOARING_DOCUMENT_TYPES_SUCCESS:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          isInProgress: false,
          isError: false,
          data: action.data,
        },
      };
    case Types.FETCH_ONBOARING_DOCUMENT_TYPES_FAILURE:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_ONBOARING_INVITE_ROLES_INPROGRESS:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          isInProgress: true,
          isError: false,
        },
      };
    case Types.FETCH_ONBOARING_INVITE_ROLES_SUCCESS:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          isInProgress: false,
          isError: false,
          data: action.data,
        },
      };
    case Types.FETCH_ONBOARING_INVITE_ROLES_FAILURE:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_ONBOARING_TEAM_MEMBERS_INPROGRESS:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          isInProgress: true,
          isError: false,
        },
      };
    case Types.FETCH_ONBOARING_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          isInProgress: false,
          isError: false,
          data: action.data,
        },
      };
    case Types.FETCH_ONBOARING_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        teamMembers: {
          ...state.teamMembers,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ONBOARDING_DELETE_TEAM_MEMBERS_INPROGRESS:
      return {
        ...state,
        deleteTeamMember: {
          ...state.deleteTeamMember,
          isInProgress: true,
          isError: false,
        },
      };
    case Types.ONBOARDING_DELETE_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        deleteTeamMember: {
          ...state.deleteTeamMember,
          isInProgress: false,
          isError: false,
          data: action.data,
        },
      };
    case Types.ONBOARDING_DELETE_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        deleteTeamMember: {
          ...state.deleteTeamMember,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.CLIENT_SETUP_INPROGRESS:
      return {
        ...state,
        onboardingSetup: {
          ...state.onboardingSetup,
          isInProgress: true,
        },
      };

    case Types.CLIENT_SETUP_SUCCESS:
      return {
        ...state,
        onboardingSetup: {
          ...state.onboardingSetup,
          status: 1,
          data: action.data,
          isInProgress: false,
        },
      };

    case Types.CLIENT_SETUP_FAILURE:
      return {
        ...state,
        onboardingSetup: {
          ...state.onboardingSetup,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FINCH_SETUP_INPROGRESS:
      return {
        ...state,
        integration: {
          ...state.integration,
          isInProgress: true,
        },
      };

    case Types.FINCH_SETUP_SUCCESS:
      return {
        ...state,
        integration: {
          ...state.integration,
          status: 1,
          data: { ...state.integration.data, finch: action.data },
          isInProgress: false,
        },
      };

    case Types.FINCH_SETUP_FAILURE:
      return {
        ...state,
        integration: {
          ...state.integration,
          isInProgress: false,
          isError: true,
          message: action.message,
          status: 0,
        },
      };
    case Types.FETCH_CODAT_CONNECT_URL_INPROGRESS:
      return {
        ...state,
        integration: {
          ...state.integration,
          isInProgress: true,
        },
      };

    case Types.FETCH_CODAT_CONNECT_URL_SUCCESS:
      return {
        ...state,
        integration: {
          ...state.integration,
          status: 1,
          data: { ...state.integration.data, codat: { url: action.data } },
          isInProgress: false,
        },
      };

    case Types.FETCH_CODAT_CONNECT_URL_FAILURE:
      return {
        ...state,
        integration: {
          ...state.integration,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CODAT_SETUP_INPROGRESS:
      return {
        ...state,
        integration: {
          ...state.integration,
          isInProgress: true,
        },
      };
    case Types.CODAT_SETUP_SUCCESS:
      // eslint-disable-next-line no-console
      return {
        ...state,
        integration: {
          ...state.integration,
          status: 1,
          data: {
            ...state.integration.data,
            codat: {
              ...get(state.integration.data, 'codat', {}),
              ...action.data,
            },
          },
          isInProgress: false,
        },
      };
    case Types.CODAT_SETUP_FAILURE:
      return {
        ...state,
        integration: {
          ...state.integration,
          isInProgress: false,
          isError: true,
          message: action.message,
          status: 0,
        },
      };
    default:
      return state;
  }
}
