import * as React from 'react';
import PropTypes from 'prop-types';
import { Alert as BaseAlert, UncontrolledAlert } from 'reactstrap';
import forIn from 'lodash/forIn';
const Alert = props => {
  const { dismiss, icon, text, children, ...rest } = props;

  const renderIcon = icon => {
    if (!icon) return '';
    return <span className="alert-inner--icon">{icon}</span>;
  };

  const renderText = text => {
    if (!text) return '';

    if (typeof text === 'string')
      return <span className="alert-inner--text">{text}</span>;
    else {
      let messages = [];
      forIn(text, values => {
        values.map(value => {
          messages.push(value);
          return true;
        });
      });

      return (
        <>
          {messages.map((content, index) => (
            <div key={index}>
              <span className="alert-inner--text">{content}</span>
              <br />
            </div>
          ))}
        </>
      );
    }
  };

  if (!children && !text) return '';

  if (dismiss === true) {
    return (
      <UncontrolledAlert {...rest}>
        {renderIcon(icon)}
        {renderText(text)}

        {children}
      </UncontrolledAlert>
    );
  } else {
    return (
      <BaseAlert {...rest}>
        {renderIcon(icon)}
        {renderText(text)}

        {children}
      </BaseAlert>
    );
  }
};

Alert.prototype = {
  icon: PropTypes.node,
  text: PropTypes.node | PropTypes.string | PropTypes.array,
  color: PropTypes.string,
  className: PropTypes.string,
  dismiss: PropTypes.bool,
  // reactstrap button props,
};

Alert.defaultProps = {
  dismiss: false,
  icon: null,
  text: null,
  children: null,
  color: '',
  className: '',
};

// Document : https://demos.creative-tim.com/argon-dashboard-pro-react/#/documentation/alert

export default Alert;
