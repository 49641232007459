import TimeSpentTypes from '../types/timeSpent';
import timerTypes from 'store/types/timerTypes';
import { get } from 'lodash';
import {
  fetchAvailability,
  fetchTasks,
  fetchDetails,
  updateDetails,
  updateAvatar,
  getAssignedProjects as getAssignedProjectsApi,
  getTimeSpent,
  getPreferences,
  setPreferences,
  getProfileActivities,
} from 'api/profile';
import { NotificationHandler } from 'components/Notifications';
import Types from '../types/profile';
import AssignedProjectTypes from '../types/assignedProjects';
import { store } from '../index';
import { getAvailableTours } from 'components/QuickStartTour/TourHelpers';
import analytics, { analyticsConstants } from 'helpers/analytics';

export const setUserPreference = data => {
  return async dispatch => {
    dispatch({
      type: Types.SET_PREFERENCE_INPROGRESS,
    });
    try {
      const resp = await setPreferences(data);
      const status = get(resp, 'status');
      const responseData = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.SET_PREFERENCE_SUCCESS,
          data: responseData,
        });
      } else {
        dispatch({
          type: Types.SET_PREFERENCE_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.SET_PREFERENCE_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const getUserPreference = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PREFERENCE_INPROGRESS,
    });
    try {
      const preferenceData = await getPreferences();
      const status = get(preferenceData, 'status');
      const data = get(preferenceData, 'data');
      if (status === 1) {
        dispatch({
          type: Types.FETCH_PREFERENCE_SUCCESS,
          data,
        });

        const timeFormat = data.find(
          item => item.category === 'timelog' && item.key === 'time_format'
        );

        const onBoardingTour = data.find(
          p => p.category === 'onboarding_tour' && p.key === 'admin'
        );
        if (
          onBoardingTour &&
          onBoardingTour.value &&
          onBoardingTour.value.status === 'Active'
        ) {
          const foundActiveTour = Object.keys(onBoardingTour.value.steps).find(
            k => onBoardingTour.value.steps[k].status === 'Active'
          );
          if (foundActiveTour) {
            dispatch(
              updateActiveTourStatus({
                activeTour: foundActiveTour ? foundActiveTour : null,
                step: foundActiveTour ? 0 : null,
              })
            );
          }
        }

        if (timeFormat) {
          dispatch({
            type: timerTypes.TOGGLE_TIME_FORMAT,
            data: parseInt(timeFormat.value),
          });
        }
      } else {
        dispatch({
          type: Types.FETCH_PREFERENCE_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch {
      dispatch({
        type: Types.FETCH_PREFERENCE_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchTimeSpent = (userId, group, startDate = '', endDate = '') => {
  return async dispatch => {
    dispatch({
      type: TimeSpentTypes.FETCH_TIME_SPENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getTimeSpent(userId, { group, startDate, endDate });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: TimeSpentTypes.FETCH_TIME_SPENT_SUCCESS,
          data: { ...resp, group } || {},
        });
      } else {
        dispatch({
          type: TimeSpentTypes.FETCH_TIME_SPENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: TimeSpentTypes.FETCH_TIME_SPENT_FAILURE,
        message: error,
      });
    }
  };
};

export const getAssignedProjects = (userId, page = 1, sort = 'name') => {
  return async dispatch => {
    dispatch({
      type: AssignedProjectTypes.FETCH_ASSIGNED_PROJECTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getAssignedProjectsApi(userId, { page, sort });
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: AssignedProjectTypes.FETCH_ASSIGNED_PROJECTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: AssignedProjectTypes.FETCH_ASSIGNED_PROJECTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: AssignedProjectTypes.FETCH_ASSIGNED_PROJECTS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchDetailsByUserId = userId => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DETAILS_INPROGRESS,
    });

    try {
      const resp = await fetchDetails(userId);

      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_DETAILS_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DETAILS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DETAILS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const updateDetailsByUserId = (userId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_DETAILS_INPROGRESS,
    });

    try {
      const resp = await updateDetails(userId, data);

      const status = get(resp, 'status');
      const message = get(resp, 'message');

      if (status) {
        dispatch({
          type: Types.UPDATE_DETAILS_SUCCESS,
          data: resp.data || {},
        });

        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.UPDATE_DETAILS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_DETAILS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const updateAvatarByUserId = (userId, data) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_DETAILS_INPROGRESS,
    });

    try {
      const resp = await updateAvatar(userId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');

      if (status) {
        dispatch({
          type: Types.UPDATE_DETAILS_SUCCESS,
          data: resp.data || {},
        });

        NotificationHandler.open({
          message,
          operation: 'update',
        });
      } else {
        dispatch({
          type: Types.UPDATE_DETAILS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_DETAILS_FAILURE,
        message: 'Something went wrong',
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const fetchAvailabilityByUserId = userId => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_AVAILABILITY_STATS_INPROGRESS,
    });

    try {
      const resp = await fetchAvailability(userId);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_AVAILABILITY_STATS_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_AVAILABILITY_STATS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_AVAILABILITY_STATS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchTasksByUserId = userId => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_TASKS_STATS_INPROGRESS,
    });

    try {
      const resp = await fetchTasks(userId);
      const status = get(resp, 'status');
      if (status) {
        dispatch({
          type: Types.FETCH_TASKS_STATS_SUCCESS,
          data: resp.data || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_TASKS_STATS_FAILURE,
          message: 'Something went wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_TASKS_STATS_FAILURE,
        message: 'Something went wrong',
      });
    }
  };
};

export const fetchProfileActivities = (id, page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_PROFILE_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getProfileActivities(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_PROFILE_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_PROFILE_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_PROFILE_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreProfileActivities = (id, page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_MORE_PROFILE_ACTIVITIES_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getProfileActivities(id, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_PROFILE_ACTIVITIES_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_MORE_PROFILE_ACTIVITIES_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_MORE_PROFILE_ACTIVITIES_FAILURE,
        message: error,
      });
    }
  };
};

export const updateActiveTourStatus = data => ({
  type: Types.UPDATE_ACTIVE_TOUR_STATE,
  data,
});

export const didAllOnboardingFlowCompleted = () => {
  setTimeout(() => {
    const state = store.getState();
    const preferences = get(state, 'profile.preference.data', []);
    const onBoardingTour = preferences.find(
      p => p.category === 'onboarding_tour'
    );
    const allTours = getAvailableTours(onBoardingTour.key);
    const isToursCompleted = allTours.every(
      ({ tourKey }) =>
        get(onBoardingTour, `value.steps.${tourKey}.status`, '') === 'Completed'
    );
    if (isToursCompleted) {
      analytics.sendEvent({
        category: analyticsConstants.category.onboarding,
        action: analyticsConstants.action.complete_all_onboarding_flows,
      });
    }
  }, 10);
};
