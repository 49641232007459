import Modal from 'components/FormFields/Modal';
import React, { useMemo, useRef, useState } from 'react';
import classes from './AddEditNoteModal.module.scss';
import CustomEditor from 'components/FormFields/CustomEditor';
import Pin from 'components/Pin';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { addNote, editNote } from 'store/actions/accountOverview';
import { get } from 'lodash';

const AddEditNoteModal = ({ addEditNoteModal, closeModal, clientId }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => get(auth, 'user'));
  const isPinned = addEditNoteModal?.note?.pinned_by;
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState(isPinned || false);
  const isEdit = !!addEditNoteModal?.note;
  const contentRef = useRef();

  const [content, setContent] = useState(
    addEditNoteModal?.note?.description || ''
  );
  const { isOpen } = addEditNoteModal;

  const pinToggle = () => {
    setPin(!pin);
  };

  const ToolBarSection = (
    <div className={classes.toolbarSection}>
      <span className="ql-formats">
        <select className="ql-header" defaultValue="3">
          <option value="1">Heading</option>
          <option value="2">Subheading</option>
          <option value="3">Normal</option>
        </select>
      </span>
      <span className="ql-formats pl-0 pr--4w">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
      </span>
      <span className="ql-formats border-0">
        <button className="ql-list" value="bullet" />
        <button className="ql-list" value="ordered" />
      </span>
      <span className={classes.pin}>
        <Pin pin={pin} pinToggle={pinToggle} className={classes.pinButton} />
      </span>
    </div>
  );

  const isEditorEmpty = useMemo(() => {
    const text = content.replace(/(<([^>]+)>)/gi, '');
    return text.trim().length === 0;
  }, [content]);

  const handleAddNote = async () => {
    if (loading) return false;
    setLoading(true);
    const newNote = {
      description: content,
      is_pinned: pin,
      created_by: user?.id,
    };
    await dispatch(addNote(clientId, newNote));
    setLoading(false);
    closeModal();
  };

  const handleEditNote = async () => {
    if (loading) return false;
    setLoading(true);
    const editedNote = {
      ...addEditNoteModal.note,
      description: content,
      is_pinned: pin,
    };
    await dispatch(editNote(clientId, editedNote));
    setLoading(false);
    closeModal();
  };

  return (
    <Modal
      className={classes.modalWrapper}
      isOpen={isOpen}
      toggle={closeModal}
      title={isEdit ? 'Edit Note' : 'Create New Note'}
    >
      <CustomEditor
        variables={[]}
        assignRef={contentRef}
        ToolBarSection={ToolBarSection}
        value={content}
        onChange={content => setContent(content)}
        className={classes.editor}
        placeholder="Enter your note here..."
      />
      <div className={classes.footer}>
        <Button className={classes.cancelBtn} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          className={classes.postNoteBtn}
          disabled={isEditorEmpty}
          onClick={isEdit ? handleEditNote : handleAddNote}
        >
          Post
        </Button>
      </div>
    </Modal>
  );
};

export default AddEditNoteModal;
