import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_DETAILS_INPROGRESS',
  'FETCH_DETAILS_SUCCESS',
  'FETCH_DETAILS_FAILURE',

  'UPDATE_DETAILS_INPROGRESS',
  'UPDATE_DETAILS_SUCCESS',
  'UPDATE_DETAILS_FAILURE',

  'FETCH_AVAILABILITY_STATS_INPROGRESS',
  'FETCH_AVAILABILITY_STATS_SUCCESS',
  'FETCH_AVAILABILITY_STATS_FAILURE',

  'FETCH_TASKS_STATS_INPROGRESS',
  'FETCH_TASKS_STATS_SUCCESS',
  'FETCH_TASKS_STATS_FAILURE',

  'UPDATE_PREFERENCES_INPROGRESS',
  'UPDATE_PREFERENCES_SUCCESS',
  'UPDATE_PREFERENCES_FAILURE',

  'FETCH_PREFERENCE_INPROGRESS',
  'FETCH_PREFERENCE_SUCCESS',
  'FETCH_PREFERENCE_FAILURE',
  'FETCH_PREFERENCE_RESET',

  'SET_PREFERENCE_INPROGRESS',
  'SET_PREFERENCE_SUCCESS',
  'SET_PREFERENCE_FAILURE',
  'SET_PREFERENCE_RESET',

  'FETCH_PROFILE_ACTIVITIES_INPROGRESS',
  'FETCH_PROFILE_ACTIVITIES_SUCCESS',
  'FETCH_PROFILE_ACTIVITIES_FAILURE',
  'FETCH_MORE_PROFILE_ACTIVITIES_INPROGRESS',
  'FETCH_MORE_PROFILE_ACTIVITIES_SUCCESS',
  'FETCH_MORE_PROFILE_ACTIVITIES_FAILURE',

  'UPDATE_ACTIVE_TOUR_STATE'
);
