import React from 'react';
import { Row } from 'reactstrap';
import classes from './Notes.module.scss';
import FormikInput from 'components/FormFields/Input/FormikInput';
import RSelect from 'components/FormFields/RSelect';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import PeopleSelector from 'components/PeopleSelector';
import UserInfo from 'components/UserInfo';

const Notes = ({ options, setFieldValue, values }) => {
  const getDisplay = (value, label) => {
    if (!isEmpty(value)) {
      return value;
    } else {
      return label;
    }
  };
  return (
    <>
      <Row className={classes.row}>
        <div className={classes.textInputRightMargin}>
          <label className="form-control-label">Assigned To</label>
          <Row className={classNames(classes.row, classes.assignments)}>
            <PeopleSelector
              id="project-assigned_user-target"
              onChange={selectedOwner => {
                setFieldValue('assigned_user', selectedOwner);
              }}
              url="/users/list/dropdown"
              placeholder="Assign an User"
            >
              <div className={classes.hoverHand}>
                <UserInfo info={values.assigned_user} altText="Assign" />
              </div>
            </PeopleSelector>
          </Row>
        </div>

        <div className={classes.textInputLeftMargin}>
          <label className="form-control-label">Business Development Rep</label>
          <Row className={classNames(classes.row, classes.assignments)}>
            <PeopleSelector
              id="project-business_development_rep-target"
              onChange={selectedOwner => {
                setFieldValue('business_development_rep', selectedOwner);
              }}
              url="/users/list/dropdown"
              placeholder="Assign"
            >
              <div className={classes.hoverHand}>
                <UserInfo
                  info={values.business_development_rep}
                  altText="Assign a Business Development Rep"
                />
              </div>
            </PeopleSelector>
          </Row>
        </div>
      </Row>
      <Row className={classes.row}>
        <div className={classes.textInputRightMargin}>
          <RSelect
            name="lead_source"
            label="Lead Source"
            options={options}
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            value={values.lead_source}
            onChange={value => {
              setFieldValue('lead_source', value);
            }}
          />
        </div>
        <div className={classes.textInputLeftMargin}>
          <label className="form-control-label">
            Business Development Manager
          </label>
          <Row className={classNames(classes.row, classes.assignments)}>
            <PeopleSelector
              id="project-business_development_manager-target"
              onChange={selectedOwner => {
                setFieldValue('business_development_manager', selectedOwner);
              }}
              url="/users/list/dropdown"
              placeholder="Assign"
            >
              <div className={classes.hoverHand}>
                <UserInfo
                  info={values.business_development_manager}
                  altText="Assign a Business Development Manager"
                />
              </div>
            </PeopleSelector>
          </Row>
        </div>
      </Row>
      <Row className={classes.row}>
        <div className={classes.textInputRightMargin}>
          <label className="form-control-label">Referred By</label>
          <Row className={classNames(classes.row, classes.assignments)}>
            <div className={classes.referred_by}>
              {getDisplay(values.referred_by, '-')}
            </div>
          </Row>
        </div>
      </Row>
      <Row className={classes.row}>
        <FormikInput
          name="description"
          label="Description"
          type="textarea"
          groupClassName={classes.textInputRightMargin}
        />
        <FormikInput
          name="notes"
          label="Notes"
          type="textarea"
          groupClassName={classes.textInputLeftMargin}
        />
      </Row>
    </>
  );
};

export default Notes;
