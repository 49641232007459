import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { doRegister } from 'store/actions/authActions';
// nodejs library that concatenates classes
import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
// reactstrap components
import { FormGroup } from 'reactstrap';

// core components
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import analytics, { analyticsConstants } from 'helpers/analytics';

const RegisterForm = ({ setStep, setSentEmail, initialRegister }) => {
  const dispatch = useDispatch();
  const registerSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(1, 'Name is required'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required')
      .max(255, 'The email must be less than 255 chars'),
    /*accepted_tc: Yup.string().required(
      'The terms and conditions must be accepted.'
    ),*/
  });

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.authentication,
      ...rest,
    });
  };

  const [focus, setFocus] = useState({});
  const [loading, setLoading] = useState(false);
  const renderFormikRegister = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  }) => {
    return (
      <>
        <FormGroup
          className={classnames(
            {
              focused: focus.name,
            },
            'mb-3'
          )}
        >
          <Input
            name="name"
            placeholder="Name"
            alternative={false}
            outlined={false}
            error={errors.name}
            value={values.name}
            onChange={handleChange}
            touched={touched.name}
            onFocus={e => {
              setFocus({ ...focus, [e.target.name]: true });
            }}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
            leftIcon={<i className="fa fa-user" />}
            autoComplete="chrome-off"
          />
        </FormGroup>
        <FormGroup
          className={classnames(
            {
              focused: focus.email,
            },
            'mb-3'
          )}
        >
          <Input
            placeholder="Email"
            error={errors.email}
            alternative={false}
            outlined={false}
            value={values.email}
            touched={touched.email}
            onChange={e => {
              setFieldValue('email', e.target.value);
            }}
            onFocus={e => {
              setFocus({ ...focus, email: true });
            }}
            onBlur={e => {
              setFieldTouched('email', true);
              handleBlur(e);
              setFocus({ ...focus, email: false });
            }}
            leftIcon={<i className="ni ni-email-83" />}
            autoComplete={Math.random()}
          />
        </FormGroup>
        {/*<Row className="my-4">
          <Col xs="12">
            <Input
              id="accepted_tc"
              name="accepted_tc"
              type="checkbox"
              value="yes"
              error={errors.accepted_tc}
              checked={get(values.accepted_tc, '0') === 'yes' ? true : false}
              onChange={handleChange}
              touched={touched.accepted_tc}
              label={
                <span className="text-muted">
                  I agree with the{' '}
                  <a href="/privacy" target="_blank">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="/terms" target="_blank">
                    Terms and Conditions
                  </a>
                </span>
              }
            />
          </Col>
        </Row>*/}
        <div className="text-center">
          <Button
            block
            className="mt-4"
            color="primary"
            type="submit"
            loading={loading}
            onClick={() => {
              analyticsSendEvent({
                action:
                  analyticsConstants.action.user_registration_user_sign_up,
              });
              handleSubmit();
            }}
          >
            Create Account
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      {/*<CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 ">
              <small>Sign up with</small>
            </div>

            <div className="btn-wrapper text-center">
              <CardBody>
                <ButtonSocialLogin isSignUp={true} history={props.history}>
                  <span className="btn-inner--text">Google</span>
                </ButtonSocialLogin>
              </CardBody>
            </div>
          </CardHeader>*/}
      <Formik
        initialValues={{
          name: '',
          company_name: '',
          email: '',
          //accepted_tc: '',
        }}
        validationSchema={registerSchema}
        enableReinitialize
        onSubmit={async values => {
          const user = {
            name: values.name,
            company_name: values.company_name,
            email: values.email,
            /*accepted_tc: values.accepted_tc ? true : false,
                  terms_and_conditions: TERMS_AND_CONDITIONS,
                  privacy_policy: PRIVACY,*/
          };

          setLoading(true);
          setSentEmail(user.email);
          const rs = await dispatch(doRegister(user));
          if (rs === 1) setStep(2);
          setLoading(false);
        }}
      >
        {renderFormikRegister}
      </Formik>
    </>
  );
};

RegisterForm.defaultProps = {
  initialRegister: false,
};

export default RegisterForm;
