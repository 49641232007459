import React, { useState } from 'react';
import classes from './ClientOnboarding.module.scss';
import { useDispatch } from 'react-redux';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';

import Button from 'components/Button';
import SupportTeam from 'views/pages/Project/Dashboard/Fields/SupportTeam';
import history from 'helpers/history';

const ClientOnboarding = ({ open = false }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(open);
  const [step, setStep] = useState(1);

  const onClose = () => setIsOpen(false);
  const next = () => setStep(prev => prev + 1);
  const prev = () => {
    setStep(prev => prev - 1);
  };

  const getUploadingModules = () => {
    switch (step) {
      case 2:
        return (
          <div className={classes.teamInfo}>
            <h3>Meet your dedicated Strike Team</h3>
            <div className={classes.text}>
              <p>
                You can easily get in touch with your dedicated Strike
                Specialists through the Strike Portal. Have questions, need
                guidance, or seeking updates? Connecting has never been easier.
              </p>
            </div>
            <div className={classes.content}>
              <SupportTeam showSnapshot />
            </div>
          </div>
        );
      case 3:
        return (
          <div className={classes.documentInfo}>
            <h3>Effortless Document Upload</h3>
            <div className={classes.text}>
              <p>
                Easily upload and access all tax study documents in one place.
                Our streamlined process ensures your important files are
                securely stored and easily accessible whenever you need them.
              </p>
              <p>
                Have a question about a specific document? Leave a comment
                directly on the document for your Strike team to review.
              </p>
            </div>
            <div className={classes.content}>
              <img src={require('assets/img/theme/doc-upload-info.svg')} />
            </div>
          </div>
        );
      case 4:
        return (
          <div className={classes.progressInfo}>
            <h3>Progress Tracking</h3>
            <div className={classes.text}>
              <p>
                Stay in the loop with the progress of your tax study. Our
                real-time tracking feature lets you monitor every step of the
                journey, from document submission to calculation and delivery.
                No more guessing – you'll know exactly where you stand.
              </p>
            </div>
            <div className={classes.content}>
              <div className={classes.content}>
                <img
                  src={require('assets/img/theme/progress-track-info.svg')}
                />
              </div>
            </div>
          </div>
        );
      case 1:
      default:
        return (
          <div className={classes.welcome}>
            <h3>Welcome to your Strike Portal!</h3>
            <div className={classes.text}>
              <p>
                We are excited to kick off your study and discover the tax
                savings you are entitled to!
              </p>
              <p>Here’s what you can do from your Strike Portal:</p>
            </div>
            <div className={classes.content}>
              <ul>
                <li>Easily upload documents</li>
                <li>Stay connected with your Strike team</li>
                <li>Track the progress of all your current engagements</li>
              </ul>
            </div>
          </div>
        );
    }
  };
  return (
    <Modal
      size="lg"
      backdrop={['static']}
      scrollable
      noHeader
      fade={false}
      toggle={onClose}
      isOpen={isOpen}
      className={classes.modalWrapper}
    >
      <ModalBody className={classes.modalBody} id="storyModal-body">
        {getUploadingModules()}
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        {step === 1 ? (
          <div></div>
        ) : (
          <Button
            className={classes.back}
            onClick={prev}
            leftIcon={<i className="fa fa-arrow-left" aria-hidden="true"></i>}
          >
            Back
          </Button>
        )}
        <div className={classes.nextWrapper}>
          <span className={classes.stepCount}>{step} of 4 </span>
          <Button
            color="primary"
            className={('btn-outline-default', classes.next)}
            onClick={() => {
              if (step !== 4) {
                next();
              } else {
                onClose();
                history.push('?');
              }
            }}
          >
            {step === 4 ? 'Finish' : 'Next'}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ClientOnboarding;
