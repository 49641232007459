import React from 'react';
import { CardBody, Card } from 'reactstrap';
import { useLocation } from 'react-router';
import InviteTeamMember from './InviteTeamMember';
import classes from 'views/pages/QuickActions/QuickActions.module.scss';
import cs from 'classnames';
import history from 'helpers/history';
import queryString from 'query-string';
import ViewDocuments from 'assets/img/icons/common/ViewDocuments.svg';
import ViewKanban from 'views/pages/QuickActions/ViewKanban';

import { get } from 'lodash';

const PersonalDashboardQuickActions = ({ clientId }) => {
  const { search: queryParams } = useLocation();
  const queryProps = queryString.parse(queryParams);
  const initiativeId = get(queryProps, 'initiative_id', 1);

  return (
    <Card className="d-flex">
      <CardBody className="d-flex flex-column position-relative pt-3">
        <h5 className="text-uppercase text-muted mb-0 card-title">
          Quick Actions
        </h5>
        <div className="d-flex w-100 mt-2 justify-content-between flex-wrap">
          {!clientId && <ViewKanban />}
          {!clientId && <InviteTeamMember isPersonalDashboard />}
          {clientId && (
            <div
              className={classes.actionBox}
              onClick={() =>
                history.push(
                  `/admin/accounts/${clientId}/documents?initiative_id=${initiativeId}`
                )
              }
            >
              <div className={cs(classes.gradient, classes.createStory)} />
              <div className={classes.box}>
                <img src={ViewDocuments} alt="" />
                <h2>View All Documents</h2>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

PersonalDashboardQuickActions.propTypes = {};

export default PersonalDashboardQuickActions;
