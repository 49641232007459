import React, { useMemo } from 'react';
import cs from 'classnames';
import classes from './AssignmentCard.module.scss';
import Avatar from 'components/Avatar';
import { formatDate } from 'helpers/times';
import history from 'helpers/history';

const getLastInteractionFormatted = last_interaction => {
  let interaction = '';
  if (last_interaction != null) {
    if (
      last_interaction?.type == 'CommentAdded' ||
      last_interaction?.type == 'DocumentCommentAdded'
    ) {
      const roles = last_interaction?.user?.roles;
      const isClient = roles?.some(r => r.level_name == 'client');
      interaction = `${last_interaction?.user?.name} ${
        isClient ? '(Client)' : ''
      }`;
      if (last_interaction?.type == 'CommentAdded')
        interaction = `${interaction} commented on `;
      else if (last_interaction?.type == 'DocumentCommentAdded')
        interaction = `${interaction} commented in document on `;
    } else if (last_interaction?.type == 'CloseEmail') {
      const email = last_interaction?.data?.email;
      const isClient = email?.sender_role == 'Client';
      interaction = `${email?.sender_name} ${isClient ? '(Client)' : ''}`;
      interaction += ' emailed on ';
    }
    return interaction;
  }
};
const getYearsFormatted = studyYears => {
  let years = studyYears ? studyYears.split(',') : [];
  let syears = '';
  if (years.length == 0) syears = '-';
  else if (years.length == 1) syears = years[0];
  else syears = `${years[0]} - ${years[years.length - 1]}`;
  return syears;
};
const AssignmentCard = ({
  studyId,
  companyName,
  deadline,
  studyPhase,
  studyYears,
  director,
  kick_of_date,
  last_interaction,
}) => {
  const syears = useMemo(() => getYearsFormatted(studyYears), [studyYears]);
  const interaction = useMemo(
    () => getLastInteractionFormatted(last_interaction),
    [last_interaction]
  );
  const onInteractionClick = event => {
    event.preventDefault();
    event.stopPropagation();
    if (last_interaction?.type == 'CommentAdded') {
      history.push(`/admin/studies/${studyId}/dashboard?tab=internal_chat`);
    } else if (last_interaction?.type == 'DocumentCommentAdded') {
      history.push(
        `/admin/studies/${studyId}/document-type/${last_interaction?.parent_id}`
      );
    } else {
      history.push(`/admin/studies/${studyId}/dashboard`);
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={cs(
          classes.progressBar,
          classes[studyPhase.toLowerCase().replaceAll(' ', '_')]
        )}
      >
        <a href={`/admin/studies/${studyId}/dashboard`}>
          <span className={classes.companyName} title={companyName}>
            {companyName}
          </span>
        </a>
        <div
          className={cs(
            classes.wrapper,
            classes[studyPhase.toLowerCase().replaceAll(' ', '_')]
          )}
        >
          <div className="row">
            <div className="col">
              <div className={classes.dataContainer}>
                <span className={classes.header}>FLMT DIRECTOR</span>
                <div className={classes.item}>
                  <Avatar
                    url={director?.avatar_thumbnail_url}
                    className={classes.avatar}
                  />
                  <div className={classes.description} title={director?.name}>
                    {director?.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className={classes.dataContainer}>
                <span className={classes.header}>STUDY YEARS</span>
                <div className={classes.item}>
                  <div className={classes.description}>{syears}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className={classes.dataContainer}>
                <span className={classes.header}>STUDY START DATE</span>
                <div className={classes.item}>
                  <div className={classes.description}>
                    {formatDate(kick_of_date, 'MMM DD, YYYY') ?? '-'}
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className={classes.dataContainer}>
                <span className={classes.header}>FILLING DEADLINE</span>
                <div className={classes.item}>
                  <div className={classes.description}>
                    {formatDate(deadline, 'MMM DD, YYYY') ?? '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className={classes.dataContainer}>
                <span className={classes.header}>LAST INTERACTION</span>
                <div className={classes.item}>
                  <div
                    className={classes.interaction}
                    onClick={onInteractionClick}
                  >
                    <span>{interaction}</span> &nbsp;
                    <span className={classes.date}>
                      {formatDate(last_interaction?.created_at, 'MM-DD-YY')}
                    </span>
                  </div>
                  {/* <div className={classes.description}>{last_interaction}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentCard;
