import React from 'react';
import classes from './Messages.module.scss';

const NotificationNotAvailable = ({ showFilter }) => {
  return (
    <div className={classes.noMessages}>
      <div className={classes.content}>
        <h3>No messages to show</h3>
        <p>
          Any communications between you and the Strike Team will appear here.
          {showFilter
            ? ''
            : 'Go to “View all messages” to see all sent messages.'}
        </p>
      </div>
    </div>
  );
};

export default NotificationNotAvailable;
