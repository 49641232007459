import React from 'react';
import CardsHeader from 'components/Headers/CardsHeader.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ProfileHeader = props => {
  const isClient = useSelector(({ auth }) => auth.user.is_client);
  const isPartner = useSelector(({ auth }) => auth.user.is_partner);
  const roles = useSelector(({ auth }) => auth.user.roles, []);
  const canAccessMembers =
    (!isClient && !isPartner) || (isClient && !roles.includes('Technical_SME'));
  return (
    <>
      <div className="header pb-6 d-flex align-items-center">
        <CardsHeader
          currentPage="User Profile"
          parentName={canAccessMembers ? 'People' : null}
          parentLink={canAccessMembers ? `/admin/users` : null}
          name={props.name}
        />
      </div>
    </>
  );
};

ProfileHeader.prototype = {
  name: PropTypes.string,
};

export default ProfileHeader;
