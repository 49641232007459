import React, { useState } from 'react';
import { Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import cs from 'classnames';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import Button from 'components/Button';
import RSelectAsync from 'components/FormFields/RSelectAsync';
import Avatar from 'components/Avatar';
import classes from './teams.module.scss';
import { components } from 'react-select';
import { updateActiveTourStatus } from 'store/actions/profile';
import { permissions, useAccess } from 'helpers/permission';

const InviteToProjectForm = ({
  viewMode,
  closeModal,
  submitValues,
  isModalOpen,
  editValues,
  projectId,
  quickStartTour,
  handleNewUserInvite,
}) => {
  const dispatch = useDispatch();
  const isInviting = useSelector(({ teamMembers }) =>
    get(teamMembers, 'addMember.isInProgress', false)
  );
  const [agrreModalOpen, setAgrreModalOpen] = useState(false);
  const InviteUserSchema = Yup.object().shape({
    users: Yup.array().min(1, ' You must select one user'),
  });

  let initialValues = {
    users: [],
  };

  if (viewMode === 're-invite') {
    initialValues = {
      ...initialValues,
      email: editValues.email,
    };
  }

  const CustomOption = ({ innerProps, data }) => (
    <div
      {...innerProps}
      className={cs('d-flex align-items-center p-2', classes.userOption)}
    >
      <Avatar className="mr-2" url={data.avatar} alt={data.name} />
      <div>
        {data.name} {data.status === 'Pending' ? '(pending invite)' : ''}
      </div>
    </div>
  );

  const MultiValueLabel = props => {
    return (
      <div className="d-flex align-items-center">
        <Avatar
          url={props.data.avatar}
          alt={props.data.name}
          className={classes.optionAvatar}
        />
        <components.MultiValueLabel {...props} />
      </div>
    );
  };

  const MultiValueContainer = props => {
    return (
      <div className={classes.selectedOption}>
        <components.MultiValueContainer {...props} />
      </div>
    );
  };

  const InviteNewUserOption = props => {
    if (useAccess([permissions.INVITE_USERS])) {
      return (
        <div
          className={cs(
            'd-flex w-100 align-items-center px-3 py-2',
            classes.customNoData
          )}
          onClick={handleNewUserInvite(props)}
        >
          <i
            className={cs('fas fa-user-plus mr-3 text-primary', classes.icon)}
          />
          <div className="d-flex flex-column">
            <span className="text-dark">
              Don’t see who you are looking for?
            </span>
            <span className="text-primary">Invite Member.</span>
          </div>
        </div>
      );
    }
    return null;
  };

  const RenderForm = ({
    handleSubmit,
    closeModal,
    isValid,
    handleReset,
    values,
    touched,
    errors,
    setFieldValue,
    agrreModalOpen,
    setAgrreModalOpen,
  }) => {
    const getUrlToRefetch = () => {
      if (values.users && !values.users.length) {
        return `/users/list/dropdown?q=&status=Active,Pending&initiative=${projectId}&excludeMembers=true`;
      } else {
        const excludeMemberIds = values.users.map(v => v.id);
        return `/users/list/dropdown?q=&status=Active,Pending&initiative=${projectId}&excludeMembers=true&excludeIds=${excludeMemberIds.join(
          ','
        )}`;
      }
    };

    return (
      <>
        <ModalBody className="pb-0">
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-3">
              <h5>Invite users by name</h5>
              <RSelectAsync
                id="inviteToTeamSelect"
                isMulti
                url={getUrlToRefetch()}
                getOptionLabel={option => option.name}
                placeholder="Enter one or more names"
                onChange={value => {
                  setFieldValue('users', value ? value : []);
                  if (value && value.length) {
                    if (
                      quickStartTour &&
                      quickStartTour.activeTour &&
                      quickStartTour.activeTour === 'invite_team' &&
                      quickStartTour.step === 7
                    ) {
                      dispatch(
                        updateActiveTourStatus({
                          step: 8,
                        })
                      );
                    }
                  }
                }}
                value={values.users}
                components={{
                  Option: CustomOption,
                  MultiValueLabel,
                  MultiValueContainer,
                }}
                shouldShowExtraComponentInList
                extraMenuListProps={{
                  component: InviteNewUserOption,
                }}
                touched={touched.users}
                error={errors.users}
                styles={{
                  multiValue: base => ({
                    ...base,
                    background: '#F4F5F7',
                    boxShadow: '0px 1px 2px rgba(68, 68, 68, 0.25)',
                    borderRadius: '4px',
                    padding: '4px 5px',
                  }),
                  multiValueLabel: base => ({
                    ...base,
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '12px',
                    color: '#32325d',
                  }),
                }}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between pt-0">
          <Button
            onClick={() => {
              handleReset();
              closeModal();
            }}
            color="link"
            className={'float-right'}
          >
            Cancel
          </Button>
          <Button
            id="inviteToTeamBtn"
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid || isInviting}
            color={!isValid || isInviting ? 'secondary' : 'primary'}
            className={'float-left'}
            loading={isInviting}
          >
            Add
          </Button>
        </ModalFooter>
      </>
    );
  };

  return (
    <Modal
      toggle={closeModal}
      centered
      isOpen={isModalOpen}
      title="Invite Members"
      size="md"
    >
      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={InviteUserSchema}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          await submitValues(values);
          resetForm();
        }}
      >
        {props => (
          <RenderForm
            viewMode={viewMode}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            {...props}
          />
        )}
      </Formik>
    </Modal>
  );
};

export default InviteToProjectForm;
