import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Input from 'components/FormFields/Input';
import RSelect from 'components/FormFields/RSelect';
import get from 'lodash/get';
import useGetFieldFromObject from 'helpers/useGetFieldFromObject';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { analyticsConstants } from 'helpers/analytics';

const CompanyInfo = ({ onChange, isAllowedEdit, analyticsSendEvent }) => {
  const useGetDetails = field =>
    useGetFieldFromObject('company', `details.data.${field}`);
  const name = useGetDetails('name');
  const [companyName, setCompanyName] = useState(name);
  useEffect(() => {
    setCompanyName(name);
  }, [name]);
  const industries = useGetFieldFromObject(
    'company',
    'company_industries.data',
    {}
  );
  const industryOptions = map(industries, (value, key) => ({
    id: key,
    text: value,
  }));
  const selectedIndustry = useGetDetails('industry');

  const companySizes = useGetFieldFromObject(
    'company',
    'company_sizes.data',
    {}
  );
  const sizeOptions = map(companySizes, (value, key) => ({
    id: key,
    text: value,
  }));
  const selectedSize = useGetDetails('size');

  const companyTypes = useGetFieldFromObject(
    'company',
    'company_types.data',
    {}
  );
  const typeOptions = map(companyTypes, (value, key) => ({
    id: key,
    text: value,
  }));

  const changeCompanyName = () => {
    if (isEmpty(companyName)) {
      setCompanyName(name);
    } else {
      analyticsSendEvent({
        action: analyticsConstants.action.update_company_name,
      });
      onChange('name', companyName);
    }
  };
  const selectedType = useGetDetails('type');
  return (
    <Card>
      <CardHeader>
        <h3 className="mb-0">Company Info</h3>
      </CardHeader>
      <CardBody className="px-lg-5">
        <Input
          disabled={!isAllowedEdit}
          label="Company Name*"
          className="mb-3"
          onChange={e => {
            setCompanyName(e.target.value);
          }}
          onKeyDown={e => {
            if (e.keyCode === 13) {
              changeCompanyName();
            }
          }}
          onBlur={changeCompanyName}
          placeholder="Company Name*"
          value={companyName}
        />
        <div className="mb-3">
          <RSelect
            label="Industry"
            isClearable={false}
            isDisabled={!isAllowedEdit}
            options={industryOptions}
            onChange={selectedOption => {
              analyticsSendEvent({
                action: analyticsConstants.action.update_company_industry,
              });
              onChange('industry_id', get(selectedOption, 'id', null));
            }}
            value={
              selectedIndustry && {
                ...selectedIndustry,
                text: selectedIndustry.label,
              }
            }
            placeholder="Select Industry"
          />
        </div>
        <Row>
          <Col className="mb-3" md={6}>
            <RSelect
              label="Company Size"
              isClearable={false}
              isDisabled={!isAllowedEdit}
              options={sizeOptions}
              onChange={selectedOption => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_size,
                });
                onChange('size_id', get(selectedOption, 'id', null));
              }}
              value={
                selectedSize && {
                  ...selectedSize,
                  text: selectedSize.label,
                }
              }
              placeholder="Select Company size"
            />
          </Col>
          <Col className="mb-3" md={6}>
            <RSelect
              label="Company Type"
              isClearable={false}
              isDisabled={!isAllowedEdit}
              placeholder="Select Company type"
              options={typeOptions}
              onChange={selectedOption => {
                analyticsSendEvent({
                  action: analyticsConstants.action.update_company_type,
                });
                onChange('type_id', get(selectedOption, 'id', null));
              }}
              value={
                selectedType && {
                  ...selectedType,
                  text: selectedType.label,
                }
              }
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CompanyInfo;
