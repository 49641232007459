import Types from '../types/accountOverview';

const initialState = {
  getNotes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  addNote: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editNote: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  pinUnpin: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  deleteNote: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function Notes(state = initialState, action) {
  switch (action.type) {
    // =================FETCH_NOTES=================
    case Types.FETCH_NOTES_INPROGRESS:
      return {
        ...state,
        getNotes: {
          ...state.getNotes,
          isInProgress: true,
        },
      };
    case Types.FETCH_NOTES_SUCCESS: {
      return {
        ...state,
        getNotes: {
          ...state.getNotes,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    }
    case Types.FETCH_NOTES_FAILURE:
      return {
        ...state,
        getNotes: {
          ...state.getNotes,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    // =================ADD_NOTE=================
    case Types.ADD_NOTE_INPROGRESS:
      return {
        ...state,
        addNote: {
          ...state.addNote,
          isInProgress: true,
        },
      };
    case Types.ADD_NOTE_SUCCESS:
      return {
        ...state,
        addNote: {
          ...state.addNote,
          isInProgress: false,
          status: 1,
        },
        getNotes: {
          ...state.getNotes,
          data: [...state.getNotes.data, action.data],
        },
      };
    case Types.ADD_NOTE_FAILURE:
      return {
        ...state,
        addNote: {
          ...state.addNote,
          isError: true,
          message: action.message,
        },
      };
    // =================EDIT_NOTE=================
    case Types.EDIT_NOTE_INPROGRESS:
      return {
        ...state,
        editNote: {
          ...state.editNote,
          isInProgress: true,
          isError: false,
        },
      };
    case Types.EDIT_NOTE_SUCCESS: {
      const noteId = action?.data?.id;
      const index = state?.getNotes?.data?.findIndex(
        note => note.id === noteId
      );

      const updatedNotes = state.getNotes.data.toSpliced(index, 1, action.data);
      return {
        ...state,
        editNote: {
          ...state.editNote,
          isInProgress: false,
          status: 1,
          isError: false,
        },
        getNotes: {
          ...state.getNotes,
          data: updatedNotes,
        },
      };
    }
    case Types.EDIT_NOTE_FAILURE:
      return {
        ...state,
        editNote: {
          ...state.editNote,
          isError: true,
          message: action.message,
        },
      };
    // =================PIN/UNPIN_NOTE=================
    case Types.PIN_NOTE_INPROGRESS:
      return {
        ...state,
        pinUnpin: {
          ...state.pinUnpin,
          isInProgress: true,
          isError: false,
        },
      };
    case Types.PIN_NOTE_SUCCESS: {
      const noteId = action.data.id;
      const index = state.getNotes.data.findIndex(note => note.id === noteId);
      const updatedNotes = state.getNotes.data.toSpliced(index, 1, action.data);
      return {
        ...state,
        getNotes: {
          ...state.getNotes,
          data: updatedNotes,
        },
      };
    }
    case Types.PIN_NOTE_FAILURE:
      return {
        ...state,
        pinUnpin: {
          ...state.pinUnpin,
          isError: true,
          message: action.message,
        },
      };
    // =================DELETE_NOTE=================
    case Types.DELETE_NOTE_INPROGRESS:
      return {
        ...state,
        deleteNote: {
          ...state.deleteNote,
          isInProgress: true,
          isError: false,
        },
      };
    case Types.DELETE_NOTE_SUCCESS: {
      const updatedNotes = state.getNotes.data.filter(
        note => note?.id !== action?.data.note_id
      );
      return {
        ...state,
        deleteNote: {
          ...state.deleteNote,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
        getNotes: {
          ...state.getNotes,
          data: updatedNotes,
        },
      };
    }
    case Types.DELETE_NOTE_FAILURE:
      return {
        ...state,
        deleteNote: {
          ...state.deleteNote,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
