import Types from '../types/epics';

const initialState = {
  reOrderEpic: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  epicState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  projectId: null,
  deleteEpic: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editEpic: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  createEpic: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    id: '',
  },
  getEpic: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
};

export default function EpicReducers(state = initialState, action) {
  switch (action.type) {
    case Types.REORDER_EPIC_INPROGRESS:
      return {
        ...state,
        reOrderEpic: {
          ...state.reOrderEpic,
          isInProgress: true,
        },
      };
    case Types.REORDER_EPIC_SUCCESS:
      return {
        ...state,
        reOrderEpic: {
          ...state.reOrderEpic,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.REORDER_EPIC_FAILURE:
      return {
        ...state,
        reOrderEpic: {
          ...state.reOrderEpic,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_EPIC_INPROGRESS:
      return {
        ...state,
        editEpic: {
          ...state.editEpic,
          isInProgress: true,
        },
      };
    case Types.UPDATE_EPIC_SUCCESS:
      return {
        ...state,
        editEpic: {
          ...state.editEpic,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_EPIC_FAILURE:
      return {
        ...state,
        editEpic: {
          ...state.editEpic,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_EPIC_INPROGRESS:
      return {
        ...state,
        deleteEpic: {
          ...state.deleteEpic,
          isInProgress: true,
        },
      };
    case Types.DELETE_EPIC_SUCCESS:
      return {
        ...state,
        deleteEpic: {
          ...state.deleteEpic,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.DELETE_EPIC_FAILURE:
      return {
        ...state,
        deleteEpic: {
          ...state.deleteEpic,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_EPICS_INPROGRESS:
      return {
        ...state,
        epicState: {
          ...state.epicState,
          isInProgress: true,
          data: initialState.epicState.data,
        },
      };
    case Types.FETCH_EPICS_SUCCESS:
      return {
        ...state,
        epicState: {
          ...state.epicState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.UPDATE_EPIC_STATE:
      return {
        ...state,
        epicState: {
          ...state.epicState,
          data: {
            ...state.epicState.data,
            data: action.data,
          },
        },
      };
    case Types.RESET_CREATE_EPIC:
      return {
        ...state,
        createEpic: initialState.createEpic,
      };
    case Types.FETCH_EPICS_FAILURE:
      return {
        ...state,
        epicState: {
          ...state.epicState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.CREATE_EPIC_INPROGRESS:
      return {
        ...state,
        createEpic: {
          isInProgress: true,
        },
      };
    case Types.CREATE_EPIC_SUCCESS:
      return {
        ...state,
        epicState: {
          ...state.epicState,
          data: action.data,
        },
        createEpic: {
          isInProgress: false,
          isError: false,
          status: 0,
          message: '',
          id: action.epicId,
        },
      };
    case Types.CREATE_EPIC_FAILURE:
      return {
        ...state,
        createEpic: {
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
