import React, { useEffect } from 'react';
import { Container, UncontrolledTooltip } from 'reactstrap';

import classes from './earnings.module.scss';
import EstimatedChart from './EstimatedChart';
import cx from 'classnames';
import EarningTable from './EarningTable';
import { withCurrency } from 'helpers/constants';
import { fetchEarnings } from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import Loading from 'components/Loading';

const Earnings = () => {
  const dispatch = useDispatch();

  const earningsInProgress = useSelector(({ partner }) =>
    get(partner, 'earnings.InProgress', false)
  );
  const estimatedEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.estimated_earnings', {})
  );

  const estimatedBreakdown = useSelector(({ partner }) =>
    get(partner, 'earnings.data.earnings_breakdown', {})
  );

  const payments = useSelector(({ partner }) =>
    get(partner, 'earnings.data.payments', {})
  );
  const receivedPayouts = useSelector(({ partner }) =>
    get(partner, 'earnings.data.received_payouts', [])
  );
  const pendingPayouts = useSelector(({ partner }) =>
    get(partner, 'earnings.data.pending_payouts', [])
  );
  const referralEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.referral_earnings', [])
  );
  const partnerEarnings = useSelector(({ partner }) =>
    get(partner, 'earnings.data.partner_earnings', [])
  );

  useEffect(() => {
    dispatch(fetchEarnings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (earningsInProgress) {
    return <Loading />;
  }

  return (
    <Container fluid className={classes.earnings}>
      <p className={classes.title}>Earnings</p>
      <div className="row">
        <div className="col-md-8">
          <div className={classes.infoWrapper}>
            <div className="col-md-6">
              <div className={classes.boxInfo}>
                <p>
                  Most Recent
                  <br />
                  Payout
                </p>
                <p>
                  {payments.last_payment
                    ? withCurrency(payments.last_payment, {
                        isShorted: true,
                      })
                    : '$0'}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className={classes.boxInfo}>
                <p>
                  Total Pending <br /> Payouts{' '}
                </p>
                <p>
                  {payments.next_payment
                    ? withCurrency(payments.next_payment, {
                        isShorted: true,
                      })
                    : '$0'}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className={classes.boxInfo}>
                <p>
                  Paid <br /> (year to date)
                </p>
                <p>
                  {payments?.year_to_date_payments
                    ? withCurrency(payments?.year_to_date_payments, {
                        isShorted: true,
                      })
                    : '$0'}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className={classes.boxInfo}>
                <p>
                  Paid <br />
                  (all time)
                </p>
                <p>
                  {payments?.total_payments
                    ? withCurrency(payments?.total_payments, {
                        isShorted: true,
                      })
                    : '$0'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <EstimatedChart
            futureData={estimatedEarnings}
            comparedData={estimatedEarnings}
            estimatedBreakdown={estimatedBreakdown}
          />
        </div>
      </div>

      <EarningTable
        header={'Your Earnings by Client'}
        type="client_earnings"
        data={referralEarnings}
      />
      <EarningTable
        header={'Your Earnings by Partner'}
        type="partner_earnings"
        data={partnerEarnings}
      />
      <div className="col-6">
        <EarningTable
          header={'Payouts Disbursed'}
          type="payouts_disbursed"
          data={receivedPayouts}
        />
      </div>
    </Container>
  );
};

export default Earnings;
