import axios from 'axios';
import Request from './request';
import { store } from '../store';
import get from 'lodash/get';

export const fetchClientProfile = async id => {
  return Request.call({
    url: `/clients/${id}`,
    method: 'GET',
  });
};

export const getTimeSpentForClient = async (
  clientID,
  { group, startDate, endDate }
) => {
  return Request.call({
    url: `/clients/${clientID}/project-hours?&group=${group}&date_from=${startDate}&date_to=${endDate}`,
    method: 'GET',
  });
};

export const fetchAgreements = async (id, page, sort, query) => {
  return Request.call({
    url: `/clients/${id}/themes/list`,
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
    },
  });
};

export const fetchClientTasks = async (id, params) => {
  return Request.call({
    url: `/clients/${id}/tasks?clientTasks=true`,
    method: 'GET',
    params,
  });
};

export const fetchClientActivities = async (id, page = 1) => {
  return Request.call({
    url: `/clients/${id}/activities?page=${page}`,
    method: 'GET',
  });
};

export const remove = async id => {
  return Request.call({
    url: `/themes/${id}`,
    method: 'DELETE',
  });
};

export const create = async (data, clientId) => {
  return Request.call({
    url: `/clients/${clientId}/themes`,
    method: 'POST',
    data,
  });
};

export const update = async (data, id) => {
  return Request.call({
    url: `/themes/${id}`,
    method: 'POST',
    data,
  });
};

export const getAgreement = async id => {
  return Request.call({
    url: `/themes/${id}`,
    method: 'GET',
  });
};

export const deleteFile = async id => {
  return Request.call({
    url: `/media/${id}`,
    method: 'DELETE',
  });
};

export const getAgreementTypes = async () => {
  return Request.call({
    url: `/meta/dropdowns?list=agreement_types`,
    method: 'GET',
  });
};

export const download = async id => {
  const storeData = store.getState();
  const token = get(storeData, 'auth.user.auth_token', null);

  // Using axios here, as using Request.call allows to download the file but content is broken/corrupted
  // If we use Request.call content of file gets undefined in text file, images are broken
  return axios({
    url: `${process.env.REACT_APP_API_URL}/media/${id}/download`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRecentTimeEntries = (clientId, page = 1) => {
  return Request.call({
    url: `/clients/${clientId}/timelogs?page=${page}`,
    method: 'GET',
  });
};

export const getRecentlyViewedProjects = clientId => {
  return Request.call({
    url: `/clients/${clientId}/projects`,
    method: 'GET',
  });
};

export const fetchClientDocuments = (clientId, params) => {
  return Request.call({
    url: `/clients/${clientId}/documents`,
    method: 'GET',
    params,
  });
};

export const uploadClientDocument = (documentType, data) => {
  return Request.call({
    url: `/document-types/${documentType}/upload`,
    method: 'POST',
    data,
  });
};

export const fetchTeamMembers = (clientId, params) => {
  return Request.call({
    url: `/clients/${clientId}/users`,
    method: 'GET',
    params,
  });
};
