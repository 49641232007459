import Types from '../types/metadata';

const initialState = {
  timezones: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  industries: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  accountTypes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  companySize: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  leadSource: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  yearList: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  contactTerms: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  quarters: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  controllerGroupFillingTypes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  entityTypes: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  accountLevels: {
    isInProgress: false,
    isError: false,
    status: 0,
    data: [],
    message: '',
  },
  studyPhases: {
    isInProgress: false,
    isError: false,
    status: 0,
    data: [],
    message: '',
  },
};

export default function Metadata(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_TIMEZONES_INPROGRESS:
      return {
        ...state,
        timezones: {
          ...state.timezones,
          isInProgress: true,
        },
      };
    case Types.FETCH_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: {
          ...state.timezones,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_TIMEZONES_FAILURE:
      return {
        ...state,
        timezones: {
          ...state.timezones,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_INDUSTRIES_INPROGRESS:
      return {
        ...state,
        industries: {
          ...state.industries,
          isInProgress: true,
        },
      };

    case Types.FETCH_INDUSTRIES_SUCCESS:
      return {
        ...state,
        industries: {
          ...state.industries,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_INDUSTRIES_FAILURE:
      return {
        ...state,
        industries: {
          ...state.industries,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_ACCOUNT_TYPES_INPROGRESS:
      return {
        ...state,
        accountTypes: {
          ...state.accountTypes,
          isInProgress: true,
        },
      };

    case Types.FETCH_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        accountTypes: {
          ...state.accountTypes,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_ACCOUNT_TYPES_FAILURE:
      return {
        ...state,
        accountTypes: {
          ...state.accountTypes,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_COMPANY_SIZE_INPROGRESS:
      return {
        ...state,
        companySize: {
          ...state.companySize,
          isInProgress: true,
        },
      };

    case Types.FETCH_COMPANY_SIZE_SUCCESS:
      return {
        ...state,
        companySize: {
          ...state.companySize,
          status: 1,
          isInProgress: false,
          data: action.data,
        },
      };

    case Types.FETCH_COMPANY_SIZE_FAILURE:
      return {
        ...state,
        companySize: {
          ...state.aboutCompany,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_LEAD_SOURCES_INPROGRESS:
      return {
        ...state,
        leadSource: {
          ...state.aboutCompany,
          isInprogress: true,
        },
      };

    case Types.FETCH_LEAD_SOURCES_SUCCESS:
      return {
        ...state,
        leadSource: {
          ...state.leadSource,
          isInprogress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_LEAD_SOURCES_FAILURE:
      return {
        ...state,
        leadSource: {
          ...state.leadSource,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_YEARS_LIST_INPROGRESS:
      return {
        ...state,
        yearList: {
          ...state.yearList,
          isInProgress: true,
        },
      };

    case Types.FETCH_YEARS_LIST_SUCCESS:
      return {
        ...state,
        yearList: {
          ...state.yearList,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_YEARS_LIST_FAILURE:
      return {
        ...state,
        yearList: {
          ...state.yearList,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_CONTACT_TERMS_INPROGRESS:
      return {
        ...state,
        contactTerms: {
          ...state.contactTerms,
          isInProgress: true,
        },
      };

    case Types.FETCH_CONTACT_TERMS_SUCCESS:
      return {
        ...state,
        contactTerms: {
          ...state.contactTerms,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_CONTACT_TERMS_FAILURE:
      return {
        ...state,
        contactTerms: {
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_QUARTERS_INPROGRESS:
      return {
        ...state,
        quarters: {
          ...state.quarters,
          isInProgress: true,
        },
      };

    case Types.FETCH_QUARTERS_SUCCESS:
      return {
        ...state,
        quarters: {
          ...state.quarters,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_QUARTERS_FAILURE:
      return {
        ...state,
        quarters: {
          ...state.quarters,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_CONTROLLER_GROUP_FILLING_TYPES_INPROGRESS:
      return {
        ...state,
        controllerGroupFillingTypes: {
          ...state.controllerGroupFillingTypes,
          isInProgress: true,
        },
      };

    case Types.FETCH_CONTROLLER_GROUP_FILLING_TYPES_SUCCESS:
      return {
        ...state,
        controllerGroupFillingTypes: {
          ...state.controllerGroupFillingTypes,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_CONTROLLER_GROUP_FILLING_TYPES_FAILURE:
      return {
        ...state,
        controllerGroupFillingTypes: {
          ...state.controllerGroupFillingTypes,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_ENITIY_TYPES_INPROGRESS:
      return {
        ...state,
        entityTypes: {
          ...state.entityTypes,
          isInProgress: true,
        },
      };

    case Types.FETCH_ENTITY_TYPES_SUCCESS:
      return {
        ...state,
        entityTypes: {
          ...state.entityTypes,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };

    case Types.FETCH_ENTITY_TYPES_FAILURE:
      return {
        ...state,
        entityTypes: {
          ...state.entityTypes,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_ACCOUNT_LEVELS_INPROGRESS:
      return {
        ...state,
        accountLevels: {
          ...state.accountLevels,
          isInProgress: true,
        },
      };

    case Types.FETCH_ACCOUNT_LEVELS_SUCCESS:
      return {
        ...state,
        accountLevels: {
          ...state.accountLevels,
          isInprogress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_ACCOUNT_LEVELS_FAILURE:
      return {
        ...state,
        accountLevels: {
          ...state.accountLevels,
          isInprogress: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_STUDY_PHASES_INPROGRESS:
      return {
        ...state,
        studyPhases: {
          ...state.studyPhases,
          isInProgress: true,
        },
      };

    case Types.FETCH_STUDY_PHASES_SUCCESS:
      return {
        ...state,
        studyPhases: {
          ...state.studyPhases,
          isInprogress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_STUDY_PHASES_FAILURE:
      return {
        ...state,
        studyPhases: {
          ...state.studyPhases,
          isInprogress: false,
          isError: true,
          message: action.message,
        },
      };
    default:
      return state;
  }
}
