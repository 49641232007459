import { get } from 'lodash';
import React from 'react';
import {
  fetchDocuments as fetchDocumentsApi,
  uploadDocuments as uploadDocumentsApi,
  fetchDocumentDetails as fetchDocumentDetailsApi,
  deleteDocument as deleteDocumentApi,
  retryUpload as retryUploadApi,
} from 'api/utility';
import Types from '../types/utility';
import NotificationHandler from 'components/Notifications/NotificationHandler';
import history from 'helpers/history';

export const fetchDocuments = (page, sort, q) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DOCUMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchDocumentsApi(page, sort, q);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DOCUMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const uploadDocuments = data => {
  return async dispatch => {
    dispatch({
      type: Types.UPLOAD_DOCUMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await uploadDocumentsApi(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data');
      if (status) {
        dispatch({
          type: Types.UPLOAD_DOCUMENTS_SUCCESS,
          data: resp || {},
        });

        NotificationHandler.open({
          message,
          title: ' ',
          icon: ' ',
          operation: 'success',
        });
      } else {
        dispatch({
          type: Types.UPLOAD_DOCUMENTS_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
      return { status, data: record };
    } catch (error) {
      dispatch({
        type: Types.UPLOAD_DOCUMENTS_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const fetchDocumentDetails = id => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_DOCUMENT_BY_ID_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchDocumentDetailsApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENT_BY_ID_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_DOCUMENT_BY_ID_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENT_BY_ID_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteDocument = (id, filters) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_DOCUMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteDocumentApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_DOCUMENT_SUCCESS,
          data: resp || {},
        });
        const { page, limit, sort, q } = filters;
        await dispatch(fetchDocuments(page, limit, sort, q));
        NotificationHandler.open({
          message: message,
          operation: 'update',
          icon: ' ',
          title: ' ',
        });
      } else {
        dispatch({
          type: Types.DELETE_DOCUMENT_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_DOCUMENT_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const retryUpload = id => {
  return async dispatch => {
    dispatch({
      type: Types.RETRY_DOCUMENT_BATCH_UPLOAD_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await retryUploadApi(id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.RETRY_DOCUMENT_BATCH_UPLOAD_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.open({
          message: message,
          operation: 'update',
          icon: ' ',
          title: ' ',
        });
      } else {
        dispatch({
          type: Types.RETRY_DOCUMENT_BATCH_UPLOAD_FAILURE,
          message: message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.RETRY_DOCUMENT_BATCH_UPLOAD_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: error,
      });
    }
  };
};

export const updateDocumentData = data => {
  return async dispatch => {
    const {
      body: [batch, action, viewMore],
      batch_id,
    } = data;
    const resp = await fetchDocumentDetailsApi(batch_id);
    if (resp.status === 1 && resp.data) {
      dispatch({
        type: Types.UPDATE_DOCUMENT_DATA,
        data: resp.data,
      });
    }

    NotificationHandler.open({
      message: (
        <div>
          {batch.text} {action.text}
          <span
            onClick={() => {
              history.push(
                `/admin/utility/document-processor?batchId=${data?.batch_id}`
              );
            }}
            style={{
              cursor: 'pointer',
              fontWeight: 700,
              textDecoration: 'underline',
            }}
          >
            {viewMore.text}
          </span>
        </div>
      ),
      operation: 'update',
      icon: ' ',
      title: ' ',
    });
  };
};
