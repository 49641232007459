import Request from './request';

export const fetchProjects = async (page, sort, query, client = null) => {
  return Request.call({
    url: '/initiatives',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
      client: client,
    },
  });
};

export const addProject = async data => {
  return Request.call({
    url: '/initiatives',
    method: 'POST',
    data,
  });
};

export const duplicateProject = async id => {
  return Request.call({
    url: `/initiatives/duplicate/${id}`,
    method: 'POST',
  });
};

export const updateProject = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}`,
    method: 'PUT',
    data,
  });
};

export const addProjectMember = async (id, data, type) => {
  return Request.call({
    url: `/initiatives/${id}/team/add`,
    method: 'POST',
    data,
  });
};

export const deleteProjectMember = async (id, userId, type) => {
  return Request.call({
    url: `/initiatives/${id}/team/remove/${userId}/${type}`,
    method: 'DELETE',
  });
};

export const fetchProject = async (id, data) => {
  return Request.call({
    url: `/initiatives/${id}`,
    method: 'GET',
    data,
  });
};

export const removeProject = async id => {
  return Request.call({
    url: `/initiatives/${id}/request`,
    method: 'DELETE',
    data: {
      status: false,
    },
  });
};

export const getBudgetSpent = async projectId => {
  return Request.call({
    url: `/initiatives/${projectId}/budget-spent`,
    method: 'GET',
  });
};

export const getTimeLoggedThisWeek = async projectId => {
  return Request.call({
    url: `/initiatives/${projectId}/hours-logged/this-week`,
    method: 'GET',
  });
};

export const getSevenDaysHours = async projectId => {
  return Request.call({
    url: `/initiatives/${projectId}/hours-logged/seven-days`,
    method: 'GET',
  });
};

export const getStoriesCompleted = async projectId => {
  return Request.call({
    url: `/initiatives/${projectId}/stories-count`,
    method: 'GET',
  });
};

export const getTimeSpentOnProject = async (
  projectId,
  { group, startDate, endDate }
) => {
  return Request.call({
    url: `/initiatives/${projectId}/timelog-barchart?group=${group}&date_from=${startDate}&date_to=${endDate}`,
    method: 'GET',
  });
};

export const getProjectActivities = async (id, page) => {
  return Request.call({
    url: `/initiatives/${id}/activities`,
    method: 'GET',
    params: {
      page,
    },
  });
};

export const getStudyTasks = async (id, clientTasks) => {
  const params = { clientTasks: true, ...clientTasks };
  return Request.call({
    url: `/initiatives/${id}/tasks`,
    method: 'GET',
    params,
  });
};
