import classes from '../QuickStartTour.module.scss';

export const getProjectsTeamInviteSteps = () => [
  {
    target: '#sidebarItem-projects',
    title: 'Invite Team Members',
    content:
      'From projects you will be able to manage teams within individual projects',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.sidebarIcon,
  },
  {
    target: '#projectsTable',
    title: 'Invite Team Members',
    content: 'Select a project from the Project List',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'top',
    extraPlacementClass: classes.projectsTable,
  },
  {
    target: '#addProjectBtn',
    title: 'Create a Project',
    content: 'Click here to add your Project!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    placement: 'left',
    extraPlacementClass: classes.addBtn,
  },
  {
    target: '#projectNameInput',
    title: 'Create a Project',
    content:
      'You can add as little as just the project name to create it. If you have more details, you can add those now or later!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.addProjectInput,
  },
  {
    target: '#createProjectBtn',
    title: 'Create a Project',
    content: 'Click here to create your Project!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'right',
    extraPlacementClass: classes.createProjectBtn,
  },
  {
    target: '#teamsTab',
    title: 'Invite Team Members',
    content:
      'Click here to access your Project Team. This is where you can invite and manage the team on the project.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 3,
    placement: 'bottom',
    extraPlacementClass: classes.storyNavigationTeam,
  },
  {
    target: '#addTeamMemberBtn',
    title: 'Invite Team Members',
    content: 'Click here to start inviting team members into your project.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    placement: 'left',
    extraPlacementClass: classes.inviteTeamBtn,
  },
  {
    target: '#inviteToTeamSelect',
    title: 'Invite Team Members',
    content:
      'You can add as many members as you would like. Search for the member using their name.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.addUserToProjectInput,
  },
  {
    target: '#inviteToTeamBtn',
    title: 'Invite Team Members',
    content: 'Click here to send your invitations',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'right',
    extraPlacementClass: classes.inviteToTeamBtn,
  },
];
