import {
  getInteractions as getInteractionsApi,
  getUpcomingStudyList as getUpcomingStudyListApi,
  getRecentlyViewedStudiesList as getRecentlyViewedStudiesListApi,
  getDocumentBucketStatusList as getDocumentBucketStatusListApi,
  getFinancialYearStatusList as getFinancialYearStatusListApi,
  updateFinancialYearStatus as updateFinancialYearStatusApi,
  updateDocumentBucketStatus as updateDocumentBucketStatusApi,
  getStudyInteractions as getStudyInteractionsApi,
  postInteractionUnresolved as postInteractionUnresolvedApi,
  downloadAllDocuments as downloadAllDocumentsApi,
  editStudy as editStudyApi,
  addStudy as addStudyApi,
  getAllDocumentList as getAllDocumentListApi,
  getAllDocumentFolder as getAllDocumentFolderApi,
  editStudyDocs as editStudyDocsApi,
  getDeletedStudies as getDeletedStudiesApi,
  deleteStudy as deleteStudyApi,
  approveDeleteStudy as approveDeleteStudyApi,
  restoreStudy as restoreStudyApi,
} from 'api/fulfillment';
import Types from 'store/types/fulfillment';
import get from 'lodash/get';
import { NotificationHandler } from 'components/Notifications';
import { fileDownload } from 'helpers/constants';

export const getInteractions = (params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_INTERACTIONS_LIST_INPROGRESS });
    try {
      const resp = await getInteractionsApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const total = get(resp, 'total', 0);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_INTERACTIONS_LIST_SUCCESS,
          data: { data, meta, total },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_INTERACTIONS_LIST_FAILURE,
          message,
        });
      }
      return { data, total, meta };
    } catch (error) {
      dispatch({
        type: Types.FETCH_INTERACTIONS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getStudyInteractions = (id, params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_STUDY_INTERACTIONS_LIST_INPROGRESS });
    try {
      const resp = await getStudyInteractionsApi(id, params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const total = get(resp, 'total', 0);
      const unresolved_count = get(resp, 'unresolved_count', 0);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_STUDY_INTERACTIONS_LIST_SUCCESS,
          data: { data, meta, total, unresolved_count },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_STUDY_INTERACTIONS_LIST_FAILURE,
          message,
        });
      }
      return { data, total, unresolved_count };
    } catch (error) {
      dispatch({
        type: Types.FETCH_STUDY_INTERACTIONS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getUpcomingStudyList = params => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_STUDY_LIST_INPROGRESS });
    try {
      const resp = await getUpcomingStudyListApi(params);

      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_STUDY_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_STUDY_LIST_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_INTERACTIONS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getRecentlyViewedStudiesList = (params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_INPROGRESS });
    try {
      const resp = await getRecentlyViewedStudiesListApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_RECENTLY_VIEWED_STUDY_LIST_FAILURE,
        message: error,
      });
    }
  };
};
export const getDocumentBucketStatusList = params => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_INPROGRESS });
    try {
      const resp = await getDocumentBucketStatusListApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOCUMENT_BUCKET_STATUS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const getFinancialYearStatusList = params => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_INPROGRESS });
    try {
      const resp = await getFinancialYearStatusListApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_FINANCIAL_YEAR_STATUS_LIST_FAILURE,
        message: error,
      });
    }
  };
};

export const postInteractionUnresolved = data => {
  return async dispatch => {
    dispatch({ type: Types.POST_INTERACTION_AS_UNRESOLVED_INPROGRESS });
    try {
      const resp = await postInteractionUnresolvedApi(data);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.POST_INTERACTION_AS_UNRESOLVED_SUCCESS,
          data: resp.data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.POST_INTERACTION_AS_UNRESOLVED_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.POST_INTERACTION_AS_UNRESOLVED_FAILURE,
        message: error,
      });
    }
  };
};
export const updateDocumentBucketStatus = (params, documentId) => {
  return async dispatch => {
    dispatch({ type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_INPROGRESS });
    try {
      const resp = await updateDocumentBucketStatusApi(params, documentId);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_DOCUMENT_BUCKET_STATUS_FAILURE,
        message: error,
      });
    }
  };
};

export const downloadAllDocuments = (id, params) => {
  return async dispatch => {
    NotificationHandler.open({
      title: ' ',
      icon: ' ',
      operation: 'create',
      message: 'File Downloading Started',
    });
    dispatch({
      type: Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_INPROGRESS,
    });
    try {
      const response = await downloadAllDocumentsApi(id, params);
      // using await so that we show the notification once file has been downloaded
      await fileDownload(response, 'docs', { type: 'application/zip' });
      dispatch({
        type: Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_SUCCESS,
      });
      NotificationHandler.open({
        title: 'Success',
        operation: 'create',
        message: 'File Downloaded Successfully',
      });
    } catch (error) {
      dispatch({
        type: Types.FETCH_DOWNLOAD_ALL_DOCUMENTS_FAILURE,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: 'Something Went Wrong',
      });
    }
  };
};

export const getAllDocumentList = (id, params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_ALL_DOCUMENTS_LIST_INPROGRESS });
    try {
      const resp = await getAllDocumentListApi(id, params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const current_page = get(resp, 'current_page', 0);
      const last_page = get(resp, 'last_page', 0);
      const meta = { current_page, last_page };

      if (status) {
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_LIST_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_LIST_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_ALL_DOCUMENTS_LIST_FAILURE,
        message: error,
      });
    }
  };
};
export const updateFinancialYearStatus = (params, yearId) => {
  return async dispatch => {
    dispatch({ type: Types.UPDATE_FINANCIAL_YEAR_STATUS_INPROGRESS });
    try {
      const resp = await updateFinancialYearStatusApi(params, yearId);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.UPDATE_FINANCIAL_YEAR_STATUS_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.UPDATE_FINANCIAL_YEAR_STATUS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_FINANCIAL_YEAR_STATUS_FAILURE,
        message: error,
      });
    }
  };
};

export const getAllDocumentFolder = (id, params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_ALL_DOCUMENTS_FOLDER_INPROGRESS });
    try {
      const resp = await getAllDocumentFolderApi(id, params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const current_page = get(resp, 'current_page', 0);
      const last_page = get(resp, 'last_page', 0);
      const meta = { current_page, last_page };

      if (status) {
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_FOLDER_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_ALL_DOCUMENTS_FOLDER_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_ALL_DOCUMENTS_FOLDER_FAILURE,
        message: error,
      });
    }
  };
};

export const editStudyDocs = (id, params) => {
  return async dispatch => {
    dispatch({ type: Types.EDIT_STUDY_DOCS_INPROGRESS });
    try {
      const resp = await editStudyDocsApi(id, params);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.EDIT_STUDY_DOCS_SUCCESS,
          data: resp.data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.EDIT_STUDY_DOCS_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_STUDY_DOCS_FAILURE,
        message: error,
      });
    }
  };
};
export const editStudy = (id, params) => {
  return async dispatch => {
    dispatch({ type: Types.EDIT_STUDY_INPROGRESS });
    try {
      const resp = await editStudyApi(id, params);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.EDIT_STUDY_SUCCESS,
          data: resp.data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.EDIT_STUDY_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.EDIT_STUDY_FAILURE,
        message: error,
      });
    }
  };
};
export const addStudy = params => {
  return async dispatch => {
    dispatch({ type: Types.ADD_STUDY_INPROGRESS });
    try {
      const resp = await addStudyApi(params);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.ADD_STUDY_SUCCESS,
          data: resp.data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.ADD_STUDY_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.ADD_STUDY_FAILURE,
        message: error,
      });
    }
  };
};

export const getDeletedStudies = (params = {}) => {
  return async dispatch => {
    dispatch({ type: Types.FETCH_DELETED_STUDY_INPROGRESS });
    try {
      const resp = await getDeletedStudiesApi(params);
      const status = get(resp, 'status');
      const data = get(resp, 'data', []);
      const meta = get(resp, 'meta', {});

      if (status) {
        dispatch({
          type: Types.FETCH_DELETED_STUDY_SUCCESS,
          data: { data, meta },
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.FETCH_DELETED_STUDY_FAILURE,
          message,
        });
      }
      return { data };
    } catch (error) {
      dispatch({
        type: Types.FETCH_DELETED_STUDY_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteStudy = id => {
  return async dispatch => {
    dispatch({ type: Types.DELETE_STUDY_INPROGRESS });
    try {
      const resp = await deleteStudyApi(id);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.DELETE_STUDY_SUCCESS,
          data: resp.data,
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.DELETE_STUDY_FAILURE,
          message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_STUDY_FAILURE,
        message: error,
      });
    }
  };
};
export const ApproveDeleteStudy = (id, isLastApproval) => {
  return async dispatch => {
    dispatch({ type: Types.APPROVE_DELETE_STUDY_INPROGRESS });
    try {
      const resp = await approveDeleteStudyApi(id);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.APPROVE_DELETE_STUDY_SUCCESS,
          data: resp.data,
        });
        NotificationHandler.open({
          title: 'Success',
          operation: 'create',
          message: !isLastApproval
            ? 'Study successfully reviewed and confirmed for deletion'
            : 'Study deleted successfully',
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.APPROVE_DELETE_STUDY_FAILURE,
          message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message: 'Study confirm deletion: Something Went Wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.APPROVE_DELETE_STUDY_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: 'Study confirm deletion: Something Went Wrong',
      });
    }
  };
};
export const RestoreStudy = id => {
  return async dispatch => {
    dispatch({ type: Types.RESTORE_DELETE_STUDY_INPROGRESS });
    try {
      const resp = await restoreStudyApi(id);
      const status = get(resp, 'status');

      if (status) {
        dispatch({
          type: Types.RESTORE_DELETE_STUDY_SUCCESS,
          data: resp.data,
        });
        NotificationHandler.open({
          title: 'Success',
          operation: 'create',
          message: 'Study successfully restored',
        });
      } else {
        const message = get(resp, 'message', '');
        dispatch({
          type: Types.RESTORE_DELETE_STUDY_FAILURE,
          message,
        });
        NotificationHandler.open({
          operation: 'failure',
          message: 'Restore Study: Something Went Wrong',
        });
      }
    } catch (error) {
      dispatch({
        type: Types.RESTORE_DELETE_STUDY_FAILURE,
        message: error,
      });
      NotificationHandler.open({
        operation: 'failure',
        message: 'Restore Study: Something Went Wrong',
      });
    }
  };
};
