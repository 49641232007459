import React, { useState } from 'react';
import { Container } from 'reactstrap';
import classes from './FunnelAgreement.module.scss';
import Agreement from './Agreement';
import Support from './Support';
import SignatureModal from './SignatureModal';
import DeclineModal from './DeclineModal';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useLocation } from 'react-router';
import DeclineExitModal from './DeclineExitModal';

const FunnelAgreement = () => {
  const { pathname } = useLocation();

  const estimatedTaxRefund = useSelector(({ auth }) =>
    get(auth, 'clientContract.data.estimated_tax_refund', null)
  );

  const isSlaFlow = pathname === '/admin/client/sla-agreement';
  const [isOpen, setIsOpen] = useState(true);
  const [showBottomFooter, setShowBottomFooter] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [persistBackdrop, setPersistBackdrop] = useState(true);
  const [email, setEmail] = useState('');
  const [openCustomDeclineExitModal, setOpenCustomDeclineExitModal] = useState(
    false
  );
  const [isDeclineAfterAgreeToModal, setIsDeclineAfterAgreeToModal] = useState(
    false
  );
  const [signModalHeight, setSignModalHeight] = useState(null);

  const handleHeightChange = height => [setSignModalHeight(height)];

  const handleGetEmail = email => setEmail(email);

  const handleCloseDeclineExitModal = () =>
    setOpenCustomDeclineExitModal(false);

  const handleOpenDeclineExitModal = () => setOpenCustomDeclineExitModal(true);

  const handleRemoveBackdrop = () => setPersistBackdrop(false);

  const handleOpenDeclineModal = () => {
    setIsDeclineModalOpen(true);
    setIsOpen(false);
  };

  const handleDeclineAgreement = () => {
    setShowBottomFooter(true);
    setIsDeclineModalOpen(true);
    setIsDeclineAfterAgreeToModal(true);
  };
  return (
    <>
      {isSlaFlow && (
        <></>
        // <Container className={classes.estimateWrapper}>
        //   <div className={classes.clientEstimate}>
        //     <span className={classes.norm}>Your current refund estimate*</span>
        //     <span className={classes.bold}>{estimatedTaxRefund || '-'}</span>
        //   </div>
        //   <div></div>
        // </Container>
      )}
      {isDeclineModalOpen && (
        <DeclineModal
          handleGetEmail={handleGetEmail}
          handleOpenDeclineExitModal={handleOpenDeclineExitModal}
          showBottomFooter={showBottomFooter}
          bottomFooterClose={() => setShowBottomFooter(false)}
          isOpen={isDeclineModalOpen}
          closeModal={() => {
            setIsDeclineModalOpen(false);
            if (!isDeclineAfterAgreeToModal) {
              setIsOpen(true);
            }
          }}
        />
      )}
      <Container fluid className={classes.wrapper}>
        <div className={classes.summery}>
          {openCustomDeclineExitModal && (
            <DeclineExitModal
              onClose={handleCloseDeclineExitModal}
              isOpen={openCustomDeclineExitModal}
              email={email}
            />
          )}
          <SignatureModal
            handleGetEmail={handleGetEmail}
            handleHeightChange={handleHeightChange}
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
            handleOpenDeclineModal={handleOpenDeclineModal}
            persistBackdrop={persistBackdrop}
            handleRemoveBackdrop={handleRemoveBackdrop}
            handleOpenDeclineExitModal={handleOpenDeclineExitModal}
          />
          <Agreement
            signModalHeight={signModalHeight}
            isBackDropRemoved={!persistBackdrop}
            handleDeclineAgreement={handleDeclineAgreement}
          />
        </div>
        <Support />
      </Container>
    </>
  );
};

export default FunnelAgreement;
