import createTypes from 'redux-create-action-types';

export default createTypes(
  'LOGIN_INPROGRESS',
  'LOGIN_SUCCESS',
  'LOGIN_FAILURE',
  'LOGIN_DEACTIVATED',
  'LOGOUT_SUCCESS',
  'REGISTER_INPROGRESS',
  'REGISTER_SUCCESS',
  'REGISTER_FAILURE',
  'RESEND_VERIFY_EMAIL_INPROGRESS',
  'RESEND_VERIFY_EMAIL_SUCCESS',
  'RESEND_VERIFY_EMAIL_FAILURE',
  'VERIFICATION_INPROGRESS',
  'VERIFICATION_SUCCESS',
  'VERIFICATION_FAILURE',
  'PASSWORD_RESET_INPROGRESS',
  'RESEND_PASSWORD_LINK_INPROGRESS',
  'PASSWORD_RESET_COMPLETE',
  'PASSWORD_RESET_FAILURE',
  'DEACTIVATE_USER',
  'GET_PROFILE_INPROGRESS',
  'GET_PROFILE_SUCCESS',
  'GET_PROFILE_FAILURE',
  'UPDATE_TOKEN',
  'ACCEPT_INVITE_INPROGRESS',
  'ACCEPT_INVITE_SUCCESS',
  'ACCEPT_INVITE_FAILURE',
  'CHECK_INVITE_STATUS_INPROGRESS',
  'CHECK_INVITE_STATUS_SUCCESS',
  'CHECK_INVITE_STATUS_FAILURE',
  'UPDATE_USER_COMPANY',
  'VERIFICATION_VALIDITY_INPROGRESS',
  'VERIFICATION_VALIDITY_SUCCESS',
  'VERIFICATION_VALIDITY_FAILURE',
  'SET_REGISTRATION_FLOW',
  'SOCIAL_LOGIN_INPROGESS',
  'SOCIAL_LOGIN_SUCCESS',
  'SOCIAL_LOGIN_FAILURE',
  'TOGGLE_AUTH_ERROR',
  'TOGGLE_RESET_PASSWORD_ERROR',
  'TOGGLE_RESET_PASSWORD_SUCCESS',
  'TOGGLE_EMAIL_VERIFY_ERROR',
  'TOGGLE_REGISTER_ERROR',
  'GET_SLA_CONTRACT_INPROGRESS',
  'GET_SLA_CONTRACT_SUCCESS',
  'GET_SLA_CONTRACT_FAILURE',
  'GET_ACCOUNT_SETUP_DETAILS_INPROGRESS',
  'GET_ACCOUNT_SETUP_DETAILS_SUCCESS',
  'GET_ACCOUNT_SETUP_DETAILS_FAILURE'
);
