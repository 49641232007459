import React, { useState, useEffect } from 'react';
import classes from './TimelogReport.module.scss';
import AdvancedFilters from './AdvancedFilters';
import cs from 'classnames';
import Button from 'components/Button';
import RSelect from 'components/FormFields/RSelect';

import SelectMultiple from 'components/FormFields/SelectMultiple';
import DateRangePicker from 'components/FormFields/DateRangePicker';
import AutoSaveFormik from 'components/FormFields/AutoSaveFormik';
import useBreakPoint from 'helpers/useBreakPoint';

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Collapse,
  Container,
} from 'reactstrap';
import TagsFilter from './TagsFilter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useAccess, permissions } from 'helpers/permission';
import { analyticsConstants } from 'helpers/analytics';
const billingTypes = [
  {
    id: 0,
    text: 'All',
  },
  {
    id: 1,
    text: 'Billable',
  },
  {
    id: 2,
    text: 'Non-Billable',
  },
];

const Filters = ({ props, formik, handleOnSubmit, analyticsSendEvent }) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  const isMobile = useBreakPoint('xs', 'down');

  let usingFilters = 0;

  const hideFilters = [
    'clients',
    'themes',
    'initiatives',
    'epics',
    'stories',
    'tasks',
    'description',
  ];

  const sendGAEvent = filtered_by =>
    analyticsSendEvent({
      action: analyticsConstants.action.filter_timelog_report,
      filtered_by,
    });

  hideFilters.map(item => {
    if (item === 'description') {
      if (formik.values.description) usingFilters++;
    } else {
      const itemValues = get(formik, `values.${item}`, []) || [];
      if (itemValues.length > 0) usingFilters++;
    }
    return true;
  });

  return (
    <>
      <Container fluid>
        <Card>
          <AutoSaveFormik
            miliSeconds={500}
            onSave={() => {
              if (formik.isValid) {
                handleOnSubmit(formik.values, formik);

                let queryString = {};

                [
                  'users',
                  'clients',
                  'themes',
                  'initiatives',
                  'epics',
                  'stories',
                  'tasks',
                ].map(item => {
                  const json = get(formik.values, `${item}`, []).map(data => {
                    return {
                      id: data.id,
                      name: data.name,
                    };
                  });

                  if (json && json.length > 0)
                    queryString = {
                      ...queryString,
                      [item]: json,
                    };

                  return true;
                });

                [
                  'range_date',
                  'startDate',
                  'endDate',
                  'billable',
                  'description',
                ].map(item => {
                  const data = get(formik.values, `${item}`);
                  if (data)
                    queryString = {
                      ...queryString,
                      [item]: data,
                    };
                  return true;
                });

                if (isEmpty(queryString)) {
                  props.history.push(`/admin/timelog`);
                } else {
                  props.history.push(
                    `/admin/timelog?values=${JSON.stringify(queryString)}`
                  );
                }
              }
            }}
          />
          <CardBody>
            <CardTitle className="h3">Timelog Filters</CardTitle>
            <Row>
              <Col md={4} xl={3}>
                <FormGroup>
                  <SelectMultiple
                    label="Team"
                    name="team"
                    units="Members"
                    value={formik.values.users}
                    placeholder="Select Team Member"
                    url="/users/list/dropdown?q="
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    onChange={users => {
                      sendGAEvent('team');
                      formik.setFieldValue('users', users);
                    }}
                    disabled={!useAccess([permissions.VIEW_ALL_TIMELOGS])}
                  />
                </FormGroup>
              </Col>
              <Col md={4} xl={3}>
                <FormGroup>
                  <DateRangePicker
                    label="Date range"
                    placeholder="This Week"
                    startDate={formik.values.startDate}
                    endDate={formik.values.endDate}
                    value={formik.values.range_date}
                    onChange={dates => {
                      formik.setFieldValue('startDate', dates.startDate);
                      formik.setFieldValue('endDate', dates.endDate);

                      if (dates.startDate && dates.startDate) {
                        const rangeDate =
                          dates.startDate.format('MMM DD, YYYY') +
                          ' - ' +
                          dates.endDate.format('MMM DD, YYYY');
                        formik.setFieldValue('range_date', rangeDate);
                        sendGAEvent('date_from');
                        sendGAEvent('date_to');
                      } else {
                        formik.setFieldValue('range_date', '');
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} xl={3}>
                <FormGroup>
                  <RSelect
                    label="Billing Type"
                    options={billingTypes}
                    placeholder="Select Billing Type"
                    value={formik.values.billable}
                    onChange={data => {
                      sendGAEvent('billable');
                      formik.setFieldValue('billable', data);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Collapse isOpen={openCollapse}>
              <AdvancedFilters formik={formik} sendGAEvent={sendGAEvent} />
            </Collapse>
            <Row className="mt-2">
              <Col xs={12} className="d-flex justify-content-between">
                <Button
                  color="link"
                  className={cs(
                    'pl-0',
                    classes.toggleFilter,
                    'text-left',
                    'pr-1'
                  )}
                  onClick={() => setOpenCollapse(!openCollapse)}
                >
                  {openCollapse ? (
                    <>
                      {isMobile ? (
                        <>
                          Hide <br />
                          Advanced Filters
                        </>
                      ) : (
                        'Hide Advanced Filters'
                      )}{' '}
                      <i className="fas fa-caret-up" />
                    </>
                  ) : (
                    <>
                      Advanced Filters{' '}
                      {usingFilters > 0 ? `(${usingFilters})` : ''}{' '}
                      <i className="fas fa-caret-down" />
                    </>
                  )}
                </Button>
                {/* <Button
                  color="primary"
                  onClick={() => {
                    formik.setValues({
                      range_date: '',
                      startDate: '',
                      endDate: '',
                      users: [],
                      clients: [],
                      themes: [],
                      initiatives: [],
                      epics: [],
                      stories: [],
                      tasks: [],
                      billable: '',
                      description: '',
                    });
                  }}
                >
                  Clear All
                </Button> */}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={12}></Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <TagsFilter formik={formik} />
    </>
  );
};

export default Filters;
