import Types from 'store/types/timelogReportTypes';

const initialState = {
  barChart: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  doughnutChart: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  summaryGroup: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  summarySubGroup: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  details: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
  action: {
    loading: false,
    isError: false,
    message: '',
    status: null,
    data: null,
  },
};

export default function timelogReportReducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_BAR_CHART_LOADING:
      return {
        ...state,
        barChart: {
          ...state.barChart,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.GET_BAR_CHART_SUCCESS:
      return {
        ...state,
        barChart: {
          ...state.barChart,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_BAR_CHART_ERROR:
      return {
        ...state,
        barChart: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.GET_DOUGHNUT_CHART_LOADING:
      return {
        ...state,
        doughnutChart: {
          ...state.doughnutChart,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.GET_DOUGHNUT_CHART_SUCCESS:
      return {
        ...state,
        doughnutChart: {
          ...state.doughnutChart,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.GET_DOUGHNUT_CHART_ERROR:
      return {
        ...state,
        doughnutChart: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.SUMMARY_GROUP_LOADING:
      return {
        ...state,
        summaryGroup: {
          ...state.summaryGroup,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.SUMMARY_GROUP_SUCCESS:
      return {
        ...state,
        summaryGroup: {
          ...state.summaryGroup,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.SUMMARY_GROUP_ERROR:
      return {
        ...state,
        summaryGroup: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.SUMMARY_SUBGROUP_LOADING:
      return {
        ...state,
        summarySubGroup: {
          ...state.summarySubGroup,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.SUMMARY_SUBGROUP_SUCCESS:
      return {
        ...state,
        summarySubGroup: {
          ...state.summarySubGroup,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.SUMMARY_SUBGROUP_ERROR:
      return {
        ...state,
        summarySubGroup: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.TIMELOG_REPORT_DETAILS_LOADING:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.TIMELOG_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };

    case Types.TIMELOG_REPORT_DETAILS_ERROR:
      return {
        ...state,
        details: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.TIMELOG_REPORT_ACTION_LOADING:
      return {
        ...state,
        action: {
          ...state.action,
          loading: true,
          isError: false,
          message: '',
        },
      };

    case Types.TIMELOG_REPORT_ACTION_SUCCESS: {
      return {
        ...state,
        action: {
          ...state.action,
          loading: false,
          status: action.status,
          data: action.data,
        },
      };
    }

    case Types.TIMELOG_REPORT_ACTION_ERROR:
      return {
        ...state,
        action: {
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    default:
      return state;
  }
}
