import Types from '../types/projects';
import { get } from 'lodash';
import findIndex from 'lodash/findIndex';

const initialState = {
  projectState: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: [],
  },
  clientDropDown: [],
  deleteProject: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  editProject: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  createProject: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  getProject: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
    id: undefined,
  },
  activities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  moreActivities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
};

export default function ProjectReducers(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_PROJECTS_INPROGRESS:
      return {
        ...state,
        projectState: {
          ...state.projectState,
          isInProgress: true,
        },
      };
    case Types.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projectState: {
          ...state.projectState,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        projectState: {
          ...state.projectState,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.GET_PROJECT_INPROGRESS:
      return {
        ...state,
        getProject: {
          ...state.getProject,
          // data: {},
          isError: false,
          message: initialState.getProject.message,
          isInProgress: true,
          id: undefined,
        },
      };
    case Types.GET_PROJECT_SUCCESS:
      return {
        ...state,
        getProject: {
          ...state.getProject,
          isInProgress: false,
          status: 1,
          data: action.data,
          id: undefined,
        },
      };
    case Types.GET_PROJECT_RESET:
      return {
        ...state,
        getProject: initialState.getProject,
      };
    case Types.GET_PROJECT_FAILURE:
      return {
        ...state,
        getProject: {
          ...state.getProject,
          isInProgress: false,
          isError: true,
          message: action.message,
          data: initialState.getProject.data,
          id: action.id,
        },
      };
    case Types.CREATE_PROJECT_INPROGRESS:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          isInProgress: true,
        },
      };
    case Types.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.CREATE_PROJECT_FAILURE:
      return {
        ...state,
        createProject: {
          ...state.createProject,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.DELETE_PROJECT_INPROGRESS:
      return {
        ...state,
        deleteProject: {
          ...state.deleteProject,
          isInProgress: true,
        },
      };
    case Types.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        deleteProject: {
          ...state.deleteProject,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.DELETE_PROJECT_FAILURE:
      return {
        ...state,
        deleteProject: {
          ...state.deleteProject,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_PROJECT_INPROGRESS:
      return {
        ...state,
        editProject: {
          ...state.editProject,
          isInProgress: true,
        },
      };
    case Types.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        getProject: {
          ...state.getProject,
          data: {
            data: {
              ...action.data.data,
              staff_members: [...state.getProject.data.data.staff_members],
            },
            status: 1,
          },
        },
        editProject: {
          ...state.editProject,
          isInProgress: false,
          status: 1,
        },
      };
    case Types.UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        editProject: {
          ...state.editProject,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_PROJECT_METADATA_INPROGRESS: {
      const { type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          isInProgress: true,
        },
      };
    }
    case Types.FETCH_PROJECT_METADATA_SUCCESS: {
      const { type, data } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          isInProgress: false,
          status: 1,
          data,
        },
      };
    }
    case Types.FETCH_PROJECT_METADATA_FAILURE: {
      const { type, message } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          isInProgress: false,
          isError: true,
          message,
        },
      };
    }
    case Types.FETCH_PROJECT_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: true,
        },
      };
    case Types.FETCH_PROJECT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PROJECT_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_PROJECT_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: true,
        },
      };
    case Types.FETCH_MORE_PROJECT_ACTIVITIES_SUCCESS: {
      let { data } = action;
      let activities = [];
      let existingData = get(state, 'activities.data.data');
      const { data: newActivities, ...rest } = data;
      if (existingData) {
        activities = [...existingData];
        (data.data || []).forEach(activity => {
          let index = findIndex(activity, {
            id: activity.id,
          });
          if (index > -1) {
            activities[index] = activity;
          } else {
            activities.push(activity);
          }
        });
      }
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          status: 1,
        },
        activities: {
          ...state.activities,
          data: {
            ...get(state, 'activities.data', {}),
            data: [...activities],
            ...rest,
          },
        },
      };
    }
    case Types.FETCH_MORE_PROJECT_ACTIVITIES_FAILURE:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.ADD_PROJECT_MEMBER_INPROGRESS:
    case Types.DELETE_PROJECT_MEMBER_INPROGRESS:
      return {
        ...state,
        editProject: {
          ...state.editProject,
          isInProgress: true,
        },
      };
    case Types.ADD_PROJECT_MEMBER_SUCCESS: {
      let newArray;
      const {
        data: { staff_members },
      } = state.getProject.data;
      const {
        data: { lead_members },
      } = state.getProject.data;
      if (action.field === 'staff_members') {
        newArray = [...staff_members, action.data];
        return {
          ...state,
          editProject: {
            ...state.editProject,
            isInProgress: false,
          },
          getProject: {
            ...state.getProject,
            data: {
              ...state.getProject.data,
              data: {
                ...state.getProject.data.data,
                staff_members: newArray,
              },
            },
          },
        };
      } else {
        newArray = [...lead_members, action.data];
        return {
          ...state,
          editProject: {
            ...state.editProject,
            isInProgress: false,
          },
          getProject: {
            ...state.getProject,
            data: {
              ...state.getProject.data,
              data: {
                ...state.getProject.data.data,
                lead_members: newArray,
              },
            },
          },
        };
      }
    }
    case Types.DELETE_PROJECT_MEMBER_SUCCESS: {
      const {
        data: { staff_members },
      } = state.getProject.data;
      const {
        data: { lead_members },
      } = state.getProject.data;
      if (action.field === 'staff_members') {
        const newArray = staff_members.filter(
          member => member.id !== action.id
        );
        return {
          ...state,
          editProject: {
            ...state.editProject,
            isInProgress: false,
          },
          getProject: {
            ...state.getProject,
            data: {
              ...state.getProject.data,
              data: {
                ...state.getProject.data.data,
                staff_members: newArray,
              },
            },
          },
        };
      } else {
        const newArray = lead_members.filter(member => member.id !== action.id);
        return {
          ...state,
          editProject: {
            ...state.editProject,
            isInProgress: false,
          },
          getProject: {
            ...state.getProject,
            data: {
              ...state.getProject.data,
              data: {
                ...state.getProject.data.data,
                lead_members: newArray,
              },
            },
          },
        };
      }
    }
    case Types.ADD_PROJECT_MEMBER_FAILURE:
    case Types.DELETE_PROJECT_MEMBER_FAILURE:
      return {
        ...state,
        editProject: {
          ...state.editProject,
          isInProgress: false,
          isError: action.message,
        },
      };
    default:
      return state;
  }
}
