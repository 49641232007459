import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button as BaseButton } from 'reactstrap';
import classes from './Button.module.scss';
const Button = props => {
  const { rightIcon, leftIcon, children, className, loading, ...rest } = props;

  const hasIcons = !!rightIcon || !!leftIcon || !!loading;

  const renderLeftIcon = () => {
    if (leftIcon) {
      return <span className="btn-inner--icon">{leftIcon}</span>;
    }
    return null;
  };

  const renderRightIcon = () => {
    if (loading) {
      return (
        <span className="btn-inner--icon">
          <i className="fas fa-circle-notch fa-spin" />
        </span>
      );
    }
    if (rightIcon) {
      return <span className="btn-inner--icon">{rightIcon}</span>;
    }
    return null;
  };

  const renderChildren = () => {
    if (children) {
      return (
        <span className={cx({ 'btn-inner--text': hasIcons })}>{children}</span>
      );
    }
  };

  return (
    <BaseButton
      className={cx(className, { 'btn-icon': hasIcons }, classes.root)}
      disabled={props.disabled || loading}
      {...rest}
    >
      {renderLeftIcon()}
      {renderChildren()}
      {renderRightIcon()}
    </BaseButton>
  );
};

Button.prototype = {
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  loading: PropTypes.bool,

  // reactstrap button props,
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
