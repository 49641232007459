import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPassword, toggleEmailVerifyError } from 'store/actions/authActions';

// nodejs library that concatenates classes
import classnames from 'classnames';
import { Formik } from 'formik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { PRIVACY, TERMS_AND_CONDITIONS } from '../Register/RegisterConstants';
import get from 'lodash/get';
// reactstrap components
import { Col, FormGroup, Row } from 'reactstrap';
import AuthCardHeader from 'components/Headers/AuthCardHeader';

// core components
import Input from 'components/FormFields/Input';
import Button from 'components/Button';
import Alert from 'components/Alert';
import { validatePassword } from 'helpers/constants';
import { useLocation } from 'react-router';

const VerifyEmailForm = ({ submitCallback }) => {
  const dispatch = useDispatch();
  const emailVerifyError = useSelector(({ auth }) =>
    get(auth, 'emailVerify.isError')
  );
  const emailVerifyMessage = useSelector(({ auth }) =>
    get(auth, 'emailVerify.message')
  );
  const location = useLocation();
  const [focus, setFocus] = useState({});
  const params = queryString.parse(location.search);
  const isSetPasswordLoading = useSelector(({ auth }) =>
    get(auth, 'emailVerify.loading', false)
  );
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const registerSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Your password should contain at least 8 characters.')
      .test(
        'validate-password',
        'Your password should contain a combination of numbers, upper and lower case letters, and special characters.',
        validatePassword
      )
      .required('Your password should contain at least 8 characters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password')], 'The passwords you entered don’t match. ')
      .required('The passwords you entered don’t match. '),
    accepted_tc: Yup.string().required(
      'The terms and conditions must be accepted.'
    ),
  });

  if (emailVerifyError) {
    setTimeout(() => {
      dispatch(toggleEmailVerifyError());
    }, 5000);
  }

  const renderForm = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit} autoComplete="chrome-off">
        <FormGroup
          className={classnames(
            {
              focused: focus.password,
            },
            'mb-3'
          )}
        >
          <Input
            name="password"
            placeholder="Create Password"
            type="password"
            error={errors.password}
            value={values.password}
            alternative={false}
            outlined={false}
            onChange={handleChange}
            focused={focus.password}
            touched={touched.password}
            onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
            leftIcon={<i className="fas fa-lock" />}
            autoComplete="chrome-off"
          />
        </FormGroup>
        <FormGroup
          className={classnames(
            {
              focused: focus.password_confirmation,
            },
            'mb-3'
          )}
        >
          <Input
            name="password_confirmation"
            placeholder="Confirm Password"
            type="password"
            focused={focus.password_confirmation}
            error={errors.password_confirmation}
            value={values.password_confirmation}
            onChange={handleChange}
            alternative={false}
            outlined={false}
            touched={touched.password_confirmation}
            onFocus={e => setFocus({ ...focus, [e.target.name]: true })}
            onBlur={e => {
              handleBlur(e);
              setFocus({ ...focus, [e.target.name]: false });
            }}
            leftIcon={<i className="fas fa-lock" />}
            autoComplete="chrome-off"
          />
        </FormGroup>
        <Row className="my-4">
          <Col xs="12">
            <Input
              id="accepted_tc"
              name="accepted_tc"
              type="checkbox"
              inputClassName="h-100"
              value="yes"
              error={errors.accepted_tc}
              checked={get(values.accepted_tc, '0') === 'yes' ? true : false}
              onChange={handleChange}
              touched={touched.accepted_tc}
              label={
                <span className="text-muted">
                  I agree with the{' '}
                  <a href="/privacy" target="_blank">
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href="/terms" target="_blank">
                    Terms and Conditions
                  </a>
                </span>
              }
            />
          </Col>
        </Row>
        <div className="text-center">
          <Button
            block
            className="mt-4"
            color="primary"
            type="submit"
            loading={isSubmitLoading}
          >
            Create Account
          </Button>
        </div>
      </form>
    );
  };

  return (
    <>
      {emailVerifyError && (
        <Alert
          text={emailVerifyMessage}
          dismiss={true}
          fade={true}
          color="danger"
        />
      )}
      <AuthCardHeader
        head="Create an Account"
        title="Create a password to set up your Strike account"
      />
      <Formik
        initialValues={{
          email: params.email,
          token: params.token,
          password: '',
          password_confirmation: '',
          accepted_tc: '',
        }}
        validationSchema={registerSchema}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          const data = {
            email: values.email,
            token: values.token,
            password: values.password,
            password_confirmation: values.password_confirmation,
            accepted_tc: values.accepted_tc ? true : false,
            terms_and_conditions: TERMS_AND_CONDITIONS,
            privacy_policy: PRIVACY,
          };
          setIsSubmitLoading(true);
          dispatch(setPassword(data))
            .then(res => {
              if (submitCallback) {
                submitCallback(res);
              }
            })
            .finally(() => {
              setIsSubmitLoading(false);
            });
        }}
      >
        {renderForm}
      </Formik>
    </>
  );
};

export default VerifyEmailForm;
