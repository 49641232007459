import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';

const Avatar = ({ url = '', className = '', ...rest }) => {
  return (
    <img
      className={cs('avatar rounded-circle', className)}
      alt="avatar"
      src={url}
      {...rest}
    />
  );
};

Avatar.prototype = {
  url: PropTypes.string,
};

export default Avatar;
