import React, { useEffect, useState } from 'react';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import BaseTable from 'components/Table';
import cx from 'classnames';
import classes from './PartnerDashboard.module.scss';
import InfoCircle from 'assets/img/icons/info-circle-dark-icon.svg';
import get from 'lodash/get';
import LeadModal from 'views/pages/dashboards/PartnerDashboard/LeadModal';
import { clearReferralCommentsData } from 'store/actions/partners';
import { useDispatch, useSelector } from 'react-redux';
import history from 'helpers/history';
import { withTime } from 'helpers/times';
import { withCurrency } from 'helpers/constants';

const PartnersTable = ({ headerText = '', data = [], type = '' }) => {
  const dispatch = useDispatch();
  const [isModal, setIsModal] = useState(false);
  const [tableData, setTableData] = useState(data);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'asc',
  });
  const [leadId, setLeadId] = useState(null);
  const [activeComment, setActiveComment] = useState(null);

  const parentId = useSelector(({ auth }) => auth.user.company.id, null);
  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const rowEvents = {
    onClick: (e, row) => {
      // onRowClick(row.id);
    },
  };

  const getSortByValue = (obj, field) => {
    switch (field) {
      case 'copy':
        return new Date(obj['timestamp']);
      default:
        return obj[field];
    }
  };

  useEffect(() => {
    const clone = [...tableData];
    clone.sort((a, b) => {
      if (sortBy.order === 'asc') {
        if (
          getSortByValue(a, sortBy.dataField) <
          getSortByValue(b, sortBy.dataField)
        ) {
          return -1;
        }
        if (
          getSortByValue(a, sortBy.dataField) >
          getSortByValue(b, sortBy.dataField)
        ) {
          return 1;
        }
        return 0;
      } else {
        if (
          getSortByValue(a, sortBy.dataField) <
          getSortByValue(b, sortBy.dataField)
        ) {
          return 1;
        }
        if (
          getSortByValue(a, sortBy.dataField) >
          getSortByValue(b, sortBy.dataField)
        ) {
          return -1;
        }
        return 0;
      }
    });
    setTableData(clone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const handleTableChange = async (type, { sortOrder, sortField }) => {
    setSortBy({
      dataField: sortField,
      order: sortOrder,
    });
  };

  const handleFetchReferralActivities = row => {
    setLeadId(get(row, 'lead_id'));
    setIsModal(true);
  };

  const NoDataIndication = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div
          className={cx(
            'd-flex justify-content-between align-items-center flex-column w-100'
          )}
        >
          <div className={classes.emptyStateHeader}></div>
          <div className={classes.noData}>
            <div className={classes.iconWrapper}>
              <i className="fa fa-address-card" aria-hidden="true"></i>
            </div>
          </div>
          <p className={cx(classes.defaultText)}>None at this time.</p>
        </div>
      </div>
    );
  };

  const getDetails = (status, timestamp, on_hold = false) => {
    switch (status) {
      case 'active_referrals':
        return `${on_hold ? 'On Hold, ' : ''}Active as of ${withTime(
          timestamp,
          { tz: userTimeZone }
        )}`;
      case 'disqualified':
        return `Disqualified as of ${withTime(timestamp, {
          tz: userTimeZone,
        })}`;
      case 'silent':
        return `Silent since ${withTime(timestamp, { tz: userTimeZone })}`;
      case 'closed':
        return `Closed on ${withTime(timestamp, { tz: userTimeZone })}`;
      default:
        return '-';
    }
  };
  const totalValue = tableData.reduce(
    (total, item) => total + item.potential_earning,
    0
  );
  function earningsFormatter(column, colIndex) {
    return (
      <div
        id={`recent-story-name-${colIndex}`}
        className={cx(' text-break')}
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingLeft: '2.1rem',
        }}
      >
        {withCurrency(totalValue, {
          isShorted: true,
          minimumFractionDigits: 2,
        })}
      </div>
    );
  }
  const borderLeftStyle = type => {
    if (type === 'in_progress_type') {
      return '5px solid #2c90ec';
    }
    if (type === 'needs_attention_type') {
      return '5px solid #bdbdbd';
    }
    if (type === 'closed_type') {
      return '5px solid #6fcf97';
    }
    if (type === 'disqualified_type') {
      return '5px solid #eb6363';
    }
  };
  return (
    <>
      {isModal ? (
        <LeadModal
          parentId={parentId}
          leadId={leadId}
          isOpen={isModal}
          modalType={'referral'}
          closeModal={() => {
            setIsModal(false);
            dispatch(clearReferralCommentsData());
            history.push('?');
          }}
          currentTab={'comment'}
          comment={activeComment}
        />
      ) : null}
      <div className={classes.partnerDetailsTableWrapper}>
        <h1 className="font-weight-700">{headerText}</h1>
        <Card className={cx(classes.viewedStories)}>
          <CardBody
            id="recently-viewed-stories-card-body"
            className={cx(
              'p-0',
              classes.cardBody,
              classes.scroll,
              {
                [classes[type]]: tableData.length > 0,
              },
              `${type}`
            )}
          >
            <BaseTable
              keyField="id"
              remote
              noDataIndication={NoDataIndication}
              search={false}
              bordered={false}
              paginationOptions={false}
              data={tableData}
              onTableChange={handleTableChange}
              classes="mb-0"
              wrapperClasses={cx(classes.tableWrapper, 'table-responsive')}
              columns={[
                {
                  dataField: 'name',
                  text: 'Lead Name',
                  sort: true,
                  // hidden: studyDashboard || tasksDashboard || clientDashboard,
                  sortCaret: renderSortCaret,
                  classes: (cell, row) => {
                    return classes[row.status];
                  },
                  headerStyle: {
                    width: '30%',
                  },
                  style: {
                    width: '30%',
                  },
                  footerStyle: {
                    width: '30%',
                    borderLeft: borderLeftStyle(type),
                  },
                  footer: 'Total Potential Earnings',
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div className="truncate-ellipsis">
                          <span
                            id={`recent-story-name-${get(row, 'id')}`}
                            className={cx('m-0')}
                            title={cell ? cell : '-'}
                          >
                            {cell ? cell : '-'}
                          </span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'copy',
                  text: 'Status',
                  sort: true,
                  sortCaret: renderSortCaret,
                  headerStyle: {
                    width: type !== 'disqualified_type' ? '28%' : '70%',
                  },
                  style: {
                    width: type !== 'disqualified_type' ? '28%' : '70%',
                  },
                  footerStyle: {
                    width: type !== 'disqualified_type' ? '28%' : '70%',
                  },
                  footer: '',
                  formatter: (cell, row) => {
                    return (
                      <>
                        <div className="truncate-ellipsis">
                          <span
                            id={`recent-story-name-${get(row, 'id')}`}
                            className={cx('m-0')}
                            title={cell ? cell : '-'}
                          >
                            {cell ? cell : '-'}
                          </span>
                        </div>
                      </>
                    );
                  },
                },
                {
                  dataField: 'potential_earning',
                  text: (
                    <span className="d-inline-flex">
                      <span title="Potential Earnings">Potential Earnings</span>
                      <img
                        id="potential-earnings-info"
                        src={InfoCircle}
                        className="ml-1"
                        alt="InfoCircle"
                      />
                      <UncontrolledTooltip
                        target={`potential-earnings-info`}
                        placement="bottom-end"
                        innerClassName={classes.earningsInfoTooltip}
                        boundariesElement="viewport"
                        hideArrow
                        autohide={false}
                      >
                        <p>
                          <b>Potential Earnings</b> - The amount Strike
                          estimates you will earn if the estimated credits are
                          utilized and paid for by the client.
                        </p>
                      </UncontrolledTooltip>
                    </span>
                  ),
                  sort: true,
                  sortCaret: renderSortCaret,
                  hidden: type === 'disqualified_type',
                  footer: withCurrency(totalValue, {
                    isShorted: true,
                    minimumFractionDigits: 2,
                  }),
                  footerFormatter: earningsFormatter,
                  formatter: (cell, row) => {
                    return (
                      <div className="truncate-ellipsis">
                        <span
                          id={`recent-story-name-${get(row, 'id')}`}
                          // className={cx('text-break')}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            marginLeft: '2rem',
                          }}
                          title={
                            cell
                              ? withCurrency(cell, {
                                  isShorted: true,
                                  minimumFractionDigits: 2,
                                })
                              : '-'
                          }
                        >
                          {cell
                            ? withCurrency(cell, {
                                isShorted: true,
                                minimumFractionDigits: 2,
                              })
                            : '-'}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  dataField: '',
                  text: '',
                  hidden: type === 'disqualified_type',
                  headerStyle: {
                    width: '8%',
                  },
                  style: {
                    width: '8%',
                  },
                  footerStyle: {
                    width: '8%',
                  },
                  formatter: (cell, row) => {
                    return (
                      <div
                        onClick={() => handleFetchReferralActivities(row)}
                        className={cx(classes.addIcon)}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          flex: 1,
                        }}
                      >
                        <i
                          className="fa fa-plus-circle lead-more-info"
                          aria-hidden="true"
                          leadname={row?.name}
                          lead_id={row?.lead_id}
                        ></i>
                      </div>
                    );
                  },
                },
              ]}
              rowEvents={rowEvents}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default PartnersTable;
