import React, { useState, useRef, useEffect } from 'react';

const TooltipContainer = ({ top, left, align, component }) => {
  const [styles, setStyles] = useState({
    position: 'absolute',
    top: top + 'px',
    left: left + 'px',
    zIndex: 1,
  });
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setWidth(ref.current.clientWidth);
  }, []);

  useEffect(() => {
    switch (align) {
      case 'right':
        setStyles({
          position: 'absolute',
          top,
          left: `${left - width - 20}px`,
          zIndex: 1,
        });
        break;

      default:
        setStyles({ position: 'absolute', top, left: left + 'px', zIndex: 1 });
        break;
    }
  }, [align, left, top, width]);

  return (
    <div ref={ref} style={styles}>
      {component}
    </div>
  );
};

export default TooltipContainer;
