import React, { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import cs from 'classnames';
import classes from './DocumentInfoSection.module.scss';
import Button from 'components/Button';
import { cloneDeep, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-use';
import { useParams } from 'react-router/cjs/react-router.min';
import queryString from 'query-string';
import { ReactComponent as PreviewIcon } from 'assets/img/icons/eye-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/img/icons/download-icon.svg';
import useBreakpoint from 'helpers/useBreakPoint';
import {
  clearDocumentUrl,
  downloadDocument,
  fetchDocumentStudyTypes,
  uploadStudyDocument,
} from 'store/actions/documents';
import socket from 'helpers/socket';
import { clearCommentsData } from 'store/actions/Story/comments';
import { deleteAttachedFile } from 'store/actions/clientProfile';
import { AlertPopupHandler } from 'components/AlertPopup';
import { downloadAllDocuments } from 'store/actions/fulfillment';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import moment from 'moment';
import BaseTable from 'components/Table';
import Modal from 'components/FormFields/Modal';
import DocumentUpload from 'views/pages/ClientStudyDashboard/DocumentUploadModal/DocumentUpload';
import DocumentViewer from 'components/Fulfillment/Modals/DocumentCommentModal/DocumentViewer';
import history from 'helpers/history';
import DocumentMoveModal from '../DocumentMoveModal/DocumentMoveModal';

const DocumentInfoSection = ({
  userType,
  params,
  initiativeId,
  files,
  setFiles,
  parentId,
  isStudyAssigned,
  getDocs,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isFileViewerOpen, setFileViewerOpen] = useState(false);
  const [fileViewerData, setFileViewerData] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );
  const downloadLoading = useGetFieldFromObjects(
    'documents',
    'downloadDoc.isInProgress',
    false
  );

  const documentLoading = useSelector(({ documents }) =>
    get(documents, 'documentStudyTypes.isInProgress', false)
  );

  useEffect(() => {
    if (parentId) {
      socket.joinAndListenComments('DocumentType', parentId, params);
      return () => {
        socket.leaveComments();
        dispatch(clearCommentsData());
      };
    }
    // used when notification is sent to user
    // const leftColumn = document.getElementById('storyModal-leftColumn');
    // if (leftColumn) leftColumn.scrollTop = 0;
    // setFiles(handleGetDocumentData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const handleDocumentDelete = async id => {
    await deleteAttachedFile(id);
    const index = files.documents.findIndex(d => d.id === id);
    const updatedFiles = {
      ...files,
      documents: [
        ...files.documents?.slice(0, index),
        ...files.documents?.slice(index + 1),
      ],
    };
    setFiles(updatedFiles);
  };

  const handleDeleteModal = id => {
    AlertPopupHandler.open({
      onConfirm: () => handleDocumentDelete(id),
      confirmBtnText: `Delete`,
      text: (
        <div className="d-flex flex-column">
          <div>Do you want to delete this file?</div>
          <div>
            This file will be unrecoverable and version history will be lost.{' '}
          </div>
        </div>
      ),
    });
  };
  const handleMoveModal = row => {
    setOpenMoveModal(true);
    setSelectedDocument(row);
  };
  const handleDownload = (index, row) => {
    dispatch(downloadDocument(files.documents[index]));
  };

  const NoDataIndication = () => {
    return (
      <div className={classes.noDataIndication}>
        {!documentLoading && (
          <>
            <p>There are no documents uploaded. </p>
            {userType === 'admin' && (
              <Button
                outline
                disabled={!isStudyAssigned}
                rightIcon={<i className="fas fa-file"></i>}
                className={classes.uploadButton}
                onClick={() => setShowUploadModal(true)}
              >
                Upload Document
              </Button>
            )}
          </>
        )}
      </div>
    );
  };

  const handleFileViewer = () => {
    setFileViewerOpen(false);
    setFileViewerData(null);

    dispatch(clearDocumentUrl());
  };

  const handlePreviewAll = () => {
    if (files?.documents?.length > 0) {
      setFileViewerOpen(true);
      setFileViewerData(files?.documents);
    }
  };

  const handleDownloadAll = async () => {
    await dispatch(
      downloadAllDocuments(initiativeId, {
        doc_type_ids: [files.id],
      })
    );
  };

  const onDrop = acceptedFiles => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  const handleRemoveFile = index => {
    const fileClone = cloneDeep(files);
    fileClone.splice(index, 1);
    setUploadedFiles(fileClone);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    if (uploadedFiles.length > 0) {
      uploadedFiles.forEach((attachment, index) => {
        formData.append(`attachments[${index}]`, attachment);
      });
    }
    formData.append('initiative_id', initiativeId);
    formData.append('document_type_id', files.id);
    const response = await dispatch(uploadStudyDocument(formData));

    if (response?.status) {
      getDocs();
      setShowUploadModal(false);
    }
  };
  return (
    <Card
      className={cs(classes.fileTable, classes.container, {
        [classes.height]: userType === 'admin',
      })}
    >
      {showUploadModal ? (
        <Modal
          size="lg"
          backdrop={['static']}
          scrollable
          fade={false}
          title={
            <p>
              Add your <strong>{files.name}</strong>
            </p>
          }
          toggle={() => setShowUploadModal(false)}
          isOpen={showUploadModal}
          className={classes.modalWrapperUpload}
          headerClassName={classes.modalHeaderUpload}
        >
          <DocumentUpload
            files={uploadedFiles}
            next={handleUpload}
            prev={() => setShowUploadModal(false)}
            onDrop={onDrop}
            removeFile={handleRemoveFile}
            userType={userType}
          />
        </Modal>
      ) : null}
      {openMoveModal ? (
        <DocumentMoveModal
          toggle={() => {
            setOpenMoveModal(false);
            setSelectedDocument(null);
          }}
          isOpen={openMoveModal}
          document={selectedDocument}
          onMoveSuccess={getDocs}
        ></DocumentMoveModal>
      ) : null}
      {isFileViewerOpen ? (
        <DocumentViewer
          handleDownloadAll={handleDownloadAll}
          isOpen={isFileViewerOpen}
          toggle={handleFileViewer}
          title={currentStudy?.name}
          files={fileViewerData}
        />
      ) : null}

      <CardHeader className={classes.modalHeader}>
        <div className={classes.folderTitle}>
          <div className="d-flex align-items-center">
            <i className="fas fa-folder-open" />
            <div>{'Uploaded Docs '}</div>
          </div>
        </div>
        {userType === 'admin' && (
          <div className={classes.adminAction}>
            <Button
              outline
              rightIcon={<i className="fas fa-file"></i>}
              color="default"
              className={classes.uploadButton}
              disabled={!isStudyAssigned}
              onClick={() => setShowUploadModal(true)}
            >
              Upload Document
            </Button>

            <span>
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light"
                  href="#pablo"
                  role="button"
                  size="sm"
                  color=""
                  onClick={e => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className={cs(classes.dropdownMenu)}>
                  <DropdownItem
                    onClick={handleDownloadAll}
                    className={classes.downloadAllDocuments}
                    disabled={downloadLoading}
                  >
                    <span>Download All</span>
                    <DownloadIcon />
                  </DropdownItem>
                  <DropdownItem
                    className={classes.downloadAllDocuments}
                    onClick={handlePreviewAll}
                  >
                    <span>Preview All</span>
                    <PreviewIcon />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </span>
          </div>
        )}
        {userType === 'client' && !pathname.includes('/admin/studies') && (
          <div>
            <Button
              outline
              leftIcon={<i className="fa fa-envelope" aria-hidden="true"></i>}
              color="primary"
              className={classes.redirectButton}
              onClick={() => {
                history.push(`/admin/studies/${initiativeId}`);
              }}
            >
              Go to Study
            </Button>
          </div>
        )}
      </CardHeader>
      <BaseTable
        keyField="id"
        bootstrap4
        remote
        loading={documentLoading}
        noDataIndication={NoDataIndication}
        showHeaderWithNoData
        search={false}
        bordered={false}
        paginationOptions={false}
        wrapperClasses={classes.tableHeader}
        data={files?.documents || []}
        columns={[
          {
            dataField: 'name',
            text: 'Documents',
            formatter: (cell, row, index) => (
              <div
                className={cs(
                  classes.fileName,
                  'table-data  d-flex justify-content-between align-items-center'
                )}
                onClick={() => {
                  if (row?.mime_type === 'application/zip') {
                    handleDownload(index, row);
                  } else {
                    setFileViewerData([row]);
                    setFileViewerOpen(true);
                  }
                }}
              >
                <span id={`file-name-${row.id}`} className="p-0">
                  {cell}
                </span>
              </div>
            ),
          },
          {
            dataField: 'size',
            text: 'Size',
            formatter: (cell, row, index) => {
              const size = Math.round(+cell / 1024);
              return (
                <div
                  className={cs(
                    classes.tData,
                    'table-data d-flex justify-content-between align-items-center'
                  )}
                >
                  <span
                    onClick={() => {
                      setFileViewerData([row]);
                      setFileViewerOpen(true);
                    }}
                    className={cs(classes.cursor)}
                  >
                    {size} KB
                  </span>
                </div>
              );
            },
          },
          {
            dataField: 'created_at',
            text: 'Date',
            formatter: (cell, row, index) => {
              return (
                <div
                  className={cs(
                    classes.tData,
                    'table-data d-flex justify-content-between align-items-center'
                  )}
                >
                  <span className={classes.cursor}>
                    {moment(cell).format('MM/DD/YYYY, h:mm A')}
                  </span>
                  <span className="p-0">
                    <UncontrolledDropdown className="p-0 m-0">
                      <DropdownToggle
                        className="btn-icon-only text-light p-0 m-0"
                        href="#pablo"
                        role="button"
                        size="sm"
                        color=""
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fas fa-ellipsis-v" />
                      </DropdownToggle>
                      <DropdownMenu className={cs(classes.dropdownMenu)}>
                        <DropdownItem
                          className={classes.downloadText}
                          onClick={() => handleDownload(index, row)}
                          disabled={downloadLoading}
                        >
                          <DownloadIcon />
                          <span>Download</span>
                        </DropdownItem>
                        {userType === 'client' && (
                          <DropdownItem
                            onClick={() => handleDeleteModal(row.id)}
                            className={classes.deleteText}
                          >
                            <i
                              className="fas fa fa-trash"
                              aria-hidden="true"
                            ></i>
                            <span>Delete File</span>
                          </DropdownItem>
                        )}
                        <DropdownItem
                          className={classes.downloadText}
                          onClick={() => {
                            setFileViewerData([row]);
                            setFileViewerOpen(true);
                          }}
                        >
                          <PreviewIcon /> <span>Preview</span>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => handleMoveModal(row)}
                          className={classes.deleteText}
                        >
                          <i
                            className="fas fa fa-file-export"
                            aria-hidden="true"
                          ></i>
                          <span>Move</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </span>
                </div>
              );
            },
          },
        ]}
      />
    </Card>
  );
};

export default DocumentInfoSection;
