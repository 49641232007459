import React from 'react';
import classes from './CustomAccordion.module.scss';
import cx from 'classnames';

function CustomAccordion({
  children,
  header = '',
  dropIconPosition = 'left',
  index,
  activeIndex,
  onSelect,
  customHeaderStyle = null,
  customArrowStyle = null,
  customChildContainerStyle = null,
  customActiveContentStyle = null,
}) {
  function toggleAccordion() {
    onSelect(index);
  }

  const getDropdownIcon = () => {
    return (
      <div className={classes.iconWrapper}>
        <i
          className={cx('fa fa-chevron-down', {
            [classes.activeIcon]: activeIndex === index,
          })}
          style={customArrowStyle}
          aria-hidden="true"
        ></i>
      </div>
    );
  };

  return (
    <div className={classes.accordionWrapper}>
      <div
        className={cx(classes.accordionHeader)}
        style={customHeaderStyle}
        onClick={toggleAccordion}
      >
        {dropIconPosition === 'left' && getDropdownIcon()}
        <div style={{ width: '100%' }}>{header}</div>
        {dropIconPosition === 'right' && getDropdownIcon()}
      </div>
      <div
        className={cx(classes.accordionContent, {
          [classes.activeContent]: activeIndex === index,
        })}
        style={customActiveContentStyle}
      >
        <div
          className={classes.childrenWrapper}
          style={customChildContainerStyle}
        >
          {' '}
          {children}
        </div>
      </div>
    </div>
  );
}

export default CustomAccordion;
