import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormGroup } from 'reactstrap';
import { Formik } from 'formik';
import { get } from 'lodash';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import RSelect from 'components/FormFields/RSelect';
import FormModal from 'components/FormFields/FormModal';
import FileDropZone from 'components/FormFields/FileDropZone';
import {
  fetchAgreementTypes,
  clearEditAgreement,
} from 'store/actions/clientProfile';

const AgreementForm = ({ closeModal, viewMode, submitValues, isModalOpen }) => {
  const dispatch = useDispatch();
  const editAgreement = useSelector(
    ({ clientProfile }) => clientProfile.editAgreement
  );
  const agreementTypes = useSelector(
    ({ clientProfile }) => clientProfile.agreementTypes
  );

  const types = get(agreementTypes, 'data', {});

  useEffect(() => {
    // If we have already fetched types we do not need to make the request for types
    if (!types || !Object.keys(types).length) {
      dispatch(fetchAgreementTypes());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let initialValues = {
    name: '',
    agreement_type_id: null,
    link: '',
    attachment: {},
  };

  if (viewMode === 'edit') {
    const agreementType = get(editAgreement, 'data.agreement_type', null);
    initialValues = {
      ...initialValues,
      name: get(editAgreement, 'data.name', ''),
      link: get(editAgreement, 'data.link', '') || '', // adding or condition because API returns null for field
      agreement_type_id: agreementType
        ? { text: agreementType.type, id: agreementType.id }
        : null,
      attachment: get(editAgreement, 'data.attachment', {}),
    };
  }

  const isLoading = get(editAgreement, 'isInProgress', false);
  const AgreementSchema = Yup.object().shape({
    name: Yup.string().required('Agreement Name is required'),
    link: Yup.string().url('Please enter a valid URL'),
  });

  const renderForm = formProps => {
    const {
      setFieldValue,
      values,
      errors,
      touched,
      handleSubmit,
      isValid,
      handleReset,
    } = formProps;
    const showLoader = viewMode === 'edit' && isLoading;
    return (
      <FormModal
        toggle={() => {
          handleReset();
          closeModal();
          dispatch(clearEditAgreement());
        }}
        isOpen={isModalOpen}
        heading={viewMode === 'edit' ? 'Edit Agreement' : 'Create an Agreement'}
        submit={{
          onClick: handleSubmit,
          isValid: isValid,
          buttonText: `${
            viewMode === 'edit' ? 'Save Changes' : 'Create Agreement'
          } `,
        }}
        cancel={{
          onClick: () => {
            handleReset();
            closeModal();
            dispatch(clearEditAgreement());
          },
          buttonText: 'Cancel',
        }}
      >
        <div>
          <Form role="form" onSubmit={handleSubmit}>
            <FormikInput
              groupClassName="mb-3"
              name="name"
              placeholder="Agreement Name*"
              type="text"
              showContentLoader={showLoader}
            />
            <FormGroup className="mb-3">
              <RSelect
                options={Object.keys(types).map(k => ({
                  text: types[k],
                  id: k,
                }))}
                placeholder="Agreement Type"
                error={errors.agreement_type_id}
                touched={touched.agreement_type_id}
                value={values.agreement_type_id}
                onChange={value => {
                  setFieldValue('agreement_type_id', value);
                }}
                showContentLoader={showLoader}
              />
            </FormGroup>
            <FormikInput
              groupClassName="mb-3"
              name="link"
              placeholder="Agreement Link"
              type="text"
              showContentLoader={showLoader}
            />
            <FormGroup className="mb-3">
              <FileDropZone
                handleFileChange={attachment => {
                  setFieldValue('attachment', attachment[0]);
                }}
                onRemove={attachment => {
                  setFieldValue('attachment', attachment[0]);
                }}
                inputProps={{
                  multiple: false,
                }}
                attachment={values.attachment}
                showContentLoader={showLoader}
              />
            </FormGroup>
          </Form>
        </div>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={AgreementSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        const status = await submitValues(values);
        if (status) {
          resetForm();
        }
      }}
    >
      {renderForm}
    </Formik>
  );
};

export default AgreementForm;
