import Request from './request';
import get from 'lodash/get';

export const fetchDocuments = async (page, sort, query) => {
  return Request.call({
    url: '/ocr',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
    },
  });
};

export const uploadDocuments = async data => {
  return Request.call({
    url: `/ocr`,
    method: 'POST',
    data,
  });
};
export const fetchDocumentDetails = async id => {
  return Request.call({
    url: `/ocr/${id}`,
    method: 'GET',
  });
};

export const deleteDocument = async id => {
  return Request.call({
    url: `/ocr/${id}`,
    method: 'DELETE',
  });
};

export const retryUpload = async id => {
  return Request.call({
    url: `/ocr/${id}/retry`,
    method: 'POST',
  });
};

export const ocrActivity = async (id = null, data = {}) => {
  return Request.call({
    url: id ? `/ocr/activity/${id}` : '/ocr/activity',
    method: 'POST',
    data,
  });
};
