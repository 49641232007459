import createTypes from 'redux-create-action-types';

export default createTypes(
  'DEALDESKS_LOADING',
  'DEALDESKS_SUCCESS',
  'DEALDESKS_ERROR',
  'DEALDESK_DETAIL_LOADING',
  'DEALDESK_DETAIL_SUCCESS',
  'DEALDESK_DETAIL_ERROR',
  'DEALDESK_STATUSES_LOADING',
  'DEALDESK_STATUSES_SUCCESS',
  'DEALDESK_STATUSES_ERROR',
  'DEALDESK_UPDATE_DETAIL_LOADING',
  'DEALDESK_UPDATE_DETAIL_SUCCESS',
  'DEALDESK_UPDATE_DETAIL_ERROR',
  'DEALDESK_DETAIL_ACTIVITIES_LOADING',
  'DEALDESK_DETAIL_ACTIVITIES_SUCCESS',
  'DEALDESK_DETAIL_ACTIVITIES_ERROR',
  'DEALDESK_USERS_LOADING',
  'DEALDESK_USERS_SUCCESS',
  'DEALDESK_USERS_ERROR',
  'GET_CONTRACT_DETAILS_LOADING',
  'GET_CONTRACT_DETAILS_SUCCESS',
  'GET_CONTRACT_DETAILS_ERROR',
  'DEALDESK_COMMENTS_LOADING',
  'DEALDESK_COMMENTS_SUCCESS',
  'DEALDESK_COMMENTS_ERROR',
  'DEALDESK_ADD_NEW_COMMENT',
  'DEALDESK_READ_ALL_COMMENT',
  'FETCH_OPTION_LISTS_INPROGRESS',
  'FETCH_OPTION_LISTS_SUCCESS',
  'FETCH_OPTION_LISTS_FAILURE',
  'DEALDESKS_REFERRAL_LOADING',
  'DEALDESKS_REFERRAL_SUCCESS',
  'DEALDESKS_REFERRAL_ERROR',
  'DEALDESK_RELATED_ENTITY_LOADING',
  'DEALDESK_RELATED_ENTITY_SUCCESS',
  'DEALDESK_RELATED_ENTITY_ERROR',
  'DEALDESK_APPROVED_BY_USERS_LOADING',
  'DEALDESK_APPROVED_BY_USERS_SUCCESS',
  'DEALDESK_APPROVED_BY_USERS_ERROR',
  'DEALDESKS_RECURRING_DEALS_LOADING',
  'DEALDESKS_RECURRING_DEALS_SUCCESS',
  'DEALDESKS_RECURRING_DEALS_ERROR'
);
