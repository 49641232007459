import React from 'react';
import get from 'lodash/get';
import RSelectAsync from 'components/FormFields/RSelectAsync';

const SelectProject = props => {
  const { formik } = props;
  return (
    <RSelectAsync
      url="/timelogs/initiatives/search"
      getOptionLabel={option => option.name}
      placeholder="Select Project"
      onChange={(data, action) => {
        const selected = {
          id: get(data, 'id', ''),
          name: get(data, 'name', ''),
        };

        formik.setFieldValue('project', selected.id);
        formik.setFieldValue('project_name', selected.name);

        formik.setFieldValue('story', '');
        formik.setFieldValue('story_name', '');
        formik.setFieldValue('task', '');
        formik.setFieldValue('task_name', '');
      }}
      value={
        formik.values.project && formik.values.project_name
          ? { id: formik.values.project, name: formik.values.project_name }
          : null
      }
    />
  );
};

export default SelectProject;
