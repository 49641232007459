import React, { useState } from 'react';
import classes from './CreditReleaseDateModal.module.scss';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import { useDispatch } from 'react-redux';
import DatePicker from 'components/FormFields/DatePicker';
import cx from 'classnames';
import { updateCreditReleaseDate } from 'store/actions/kanbanActions';
import moment from 'moment';

const CreditReleaseDateModal = ({ isOpen, toggle, onConfirm, studyId }) => {
  const dispatch = useDispatch();
  const [creditReleaseDate, setCreditReleaseDate] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const handleDontMove = () => {
    toggle();
  };
  const onYes = () => {
    if (!creditReleaseDate) {
      setErrorMessage('Please enter credit release date.');
      return;
    }
    dispatch(
      updateCreditReleaseDate(studyId, {
        crl_release_date: creditReleaseDate.format('YYYY-MM-DD'),
      })
    );
    onConfirm();
  };
  const isValiDate = current => {
    return !current.isAfter(moment());
  };
  return (
    <Modal
      toggle={toggle}
      //   backdrop="static"
      centered
      isOpen={isOpen}
      className={classes.modal}
      size={'md'}
      noHeader
      title={''}
    >
      <ModalBody className={classes.modalBody}>
        <div className={classes.infoIcon}></div>
        <h2>Are you sure?</h2>
        <div className={classes.para}>
          <p>
            It looks like the credit release date has not been passed yet. To
            move this card, please enter your credit release date.
          </p>
        </div>

        <div className="row">
          <div className="col">
            <div className={classes.creditReleaseDate}>
              <label
                className={classes.label}
                htmlFor="creditReleaseLetterDate"
              >
                Credit Release Date
              </label>
              <div className="form-group">
                <DatePicker
                  placeholder="YYYY-MM-DD"
                  name="creditReleaseLetterDate"
                  value={creditReleaseDate}
                  isValidDate={isValiDate}
                  renderInput={(props, openCalendar) => {
                    return (
                      <div className={classes.dateWrapper}>
                        <input {...props} />
                        <i
                          className={cx('fas fa-calendar', classes.icon)}
                          onClick={openCalendar}
                        />
                      </div>
                    );
                  }}
                  onChange={dates => {
                    // const date = moment(dates).format('YYYY-MM-DD');
                    setCreditReleaseDate(dates);
                  }}
                />
                <span className="text-danger">{errorMessage}</span>
              </div>
            </div>
          </div>
        </div>
        <p className={classes.buttons}>
          <a
            href="#"
            className={cx('btn btn-link', classes.dragContainerCancelBtnStyle)}
            onClick={handleDontMove}
          >
            Don't Move
          </a>
          <a
            href="#"
            className={cx(
              'btn btn-success',
              classes.dragContainerConfirmBtnStyle
            )}
            onClick={onYes}
          >
            Yes
          </a>
        </p>
      </ModalBody>
    </Modal>
  );
};

export default CreditReleaseDateModal;
