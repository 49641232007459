import React from 'react';
import get from 'lodash/get';
import map from 'lodash/map';

const BlockedAbilities = ({ role }) => {
  return (
    get(role, 'abilities.denied', []).length !== 0 && (
      <>
        <h4>{role.title} can't:</h4>
        <ul className="fa-ul ml-4">
          {map(get(role, 'abilities.denied', []), (ability, index) => {
            return (
              <li key={index}>
                <span className="fa-li">
                  <i className="fas fa-times text-danger"></i>
                </span>
                {ability}
              </li>
            );
          })}
        </ul>
      </>
    )
  );
};

export default BlockedAbilities;
