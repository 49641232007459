import React, { useState } from 'react';
import classNames from 'classnames';
import style from './Overview.module.scss';
import Avatar from 'components/Avatar';
import calendar from 'assets/vendor/@fortawesome/fontawesome-free/svgs/solid/calendar-outline.svg';
import closeUrlImg from 'assets/img/icons/close-url.svg';
import editStudyImg from 'assets/img/icons/edit-study.svg';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import _ from 'lodash';
import { getDefaultPhotoThumbnail } from 'helpers/userThumbnail';
import { formatDate } from 'helpers/times';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import CustomAccordion from 'components/CustomAccordion';
import EditOverviewModal from './EditOverview/EditOverviewModal';

const Collaborators = collaborators => {
  const [activeIndex, setActiveIndex] = useState(0);
  const mapRoles = key => {
    switch (key) {
      case 'Client_Administrator':
        return 'Administrator';
      case 'Technical_SME':
        return "Technical SME's";
      case 'Financial_SME':
        return "Financial SME's";
      default:
        return "Technical SME's";
    }
  };
  let data = _.map(
    _.groupBy(
      _.map(collaborators.collaborators, c => {
        return { role: mapRoles(c?.roles[0]?.name), ...c };
      }),
      'role'
    ),
    (value, key) => {
      return {
        header: key,
        collaborator: value,
      };
    }
  );
  return (
    <div className={style.overviewWrapper}>
      {data &&
        data?.map((collaborator, index) => {
          return (
            <CustomAccordion
              key={index}
              index={index}
              activeIndex={activeIndex}
              onSelect={selectedIndex => {
                if (activeIndex === index) {
                  setActiveIndex(null);
                } else {
                  setActiveIndex(selectedIndex);
                }
              }}
              header={
                <div className={style.headerWrapper}>
                  <div className={style.headerContent}>
                    {collaborator.header}
                  </div>
                </div>
              }
              customHeaderStyle={{
                paddingTop: '6px',
                paddingBottom: '6px',
                paddingLeft: '0px',
                boxShadow: 'none',
                borderBottom: '1px solid #DEE2E6',
                borderRadius: '0px',
              }}
              dropIconPosition="right"
              customArrowStyle={{ color: '#525f7f', fontSize: 'medium' }}
              customChildContainerStyle={{ minWidth: '200px' }}
              customActiveContentStyle={{ paddingLeft: '20px' }}
            >
              {collaborator &&
                collaborator?.collaborator?.map((collaborator, index) => {
                  return (
                    <div key={index} className={style.collabItem}>
                      <Avatar
                        id={`collaborator-${collaborator.id}`}
                        url={
                          collaborator.avatar ??
                          getDefaultPhotoThumbnail('strike', collaborator.name)
                        }
                        className={style.avatar}
                      />
                      <div className={style.collabratorName} key={index}>
                        {collaborator.name}
                      </div>
                      <UncontrolledPopover
                        trigger="hover"
                        placement="top"
                        target={`collaborator-${collaborator.id}`}
                      >
                        <PopoverBody>
                          <div className="d-flex flex-column">
                            {collaborator?.email && (
                              <span>{collaborator.email}</span>
                            )}
                            {collaborator?.phone && (
                              <span>{collaborator.phone}</span>
                            )}
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  );
                })}
            </CustomAccordion>
          );
        })}
    </div>
  );
};

const StrikeData = members => {
  return (
    <div className={style.overviewWrapper}>
      {members &&
        members.strikeTeam.length > 0 &&
        members?.strikeTeam.map((member, index) => {
          return (
            <div key={`member${index}`} className={style.collabratorContainer}>
              <div className={style.collabItem}>
                <Avatar
                  id={`user-${member.id}`}
                  url={
                    member.avatar ??
                    getDefaultPhotoThumbnail('strike', member.name)
                  }
                  className={style.avatar}
                />
                <div className={style.collabratorName}>
                  {member.name}, {member.role}
                </div>
                <UncontrolledPopover
                  trigger="hover"
                  placement="top"
                  target={`user-${member.id}`}
                >
                  <PopoverBody>
                    <div className="d-flex flex-column">
                      {member?.email && <span>{member.email}</span>}
                      {member?.phone && <span>{member.phone}</span>}
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const Overview = () => {
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', '')
  );
  const [isEditOpen, setIsEditOpen] = useState(false);
  const dateFormat = 'MM/DD/YYYY';
  const yearsOfEngagement = get(currentStudy, 'engagement_years');
  const currentStudyYear = get(currentStudy, 'current_study');
  const projectPhase = get(currentStudy, 'project_phase');
  const kickOffDate = get(currentStudy, 'kick_of_date');
  const estimatedDeliveryDate = get(currentStudy, 'estimated_delivery_date');
  const finalReportReleaseDate = get(currentStudy, 'final_report_release_date');
  const firstYear = get(currentStudy, 'client.first_year_of_rd');
  const firstYearIncorporated = get(
    currentStudy,
    'client.first_year_incorporated'
  );
  const collaborators = get(currentStudy, 'collaborators');
  const strikeTeam = get(currentStudy, 'client.assigned_users', []);
  const firstYearRevenue = get(currentStudy, 'client.first_year_of_revenues');
  const creditReleaseDate = get(currentStudy, 'crl_release_date');
  const signedElDate = get(currentStudy, 'signed_el_date');
  const phaseDueDate = get(currentStudy, 'phase_deadline');
  const closeUrl = get(currentStudy, 'client.close_url');
  const data = [
    { header: 'YEARS OF ENGAGEMENT', description: yearsOfEngagement ?? '-' },
    {
      header: 'SIGNED E.L. DATE',
      description: formatDate(signedElDate, dateFormat) ?? '-',
      isDate: true,
    },
    { header: 'CURRENT STUDY', description: currentStudyYear ?? '-' },
    {
      header: 'KICK OFF DATE ',
      description: formatDate(kickOffDate, dateFormat) ?? '-',
      isDate: true,
    },
    {
      header: 'ESTIMATED DELIVERY DATE',
      description: formatDate(estimatedDeliveryDate, dateFormat) ?? '-',
      isDate: true,
    },
    {
      header: 'CREDIT RELEASE LETTER DATE',
      description: formatDate(creditReleaseDate, dateFormat) ?? '-',
      isDate: true,
    },
    {
      header: 'FINAL REPORT RELEASE DATE',
      description: formatDate(finalReportReleaseDate, dateFormat) ?? '-',
      isDate: true,
    },
    { header: 'FIRST YEAR R&D', description: firstYear ?? '-' },
    {
      header: 'FIRST YEAR INCORPORATED',
      description: firstYearIncorporated ?? '-',
      isDate: true,
    },
    {
      header: 'FIRST YEAR OF REVENUE',
      description: firstYearRevenue ?? '-',
      isDate: true,
    },
    {
      header: 'CLIENT TEAM',
      component: <Collaborators collaborators={collaborators} />,
    },
    {
      header: 'STRIKE TEAM',
      component: <StrikeData strikeTeam={strikeTeam} />,
    },
  ];
  const closeEdit = () => {
    setIsEditOpen(false);
  };
  return (
    <>
      <div className={style.container}>
        <div className={style.headText}>
          <span>Overview</span>
          <div className="d-flex">
            <a
              href="javascript:void(0)"
              onClick={() => {
                setIsEditOpen(true);
              }}
            >
              <img src={editStudyImg} />
            </a>
            <a
              href={closeUrl}
              className="pull-right"
              target="_blank"
              rel="noreferrer"
            >
              <div>
                <img src={closeUrlImg} />
              </div>
            </a>
          </div>
        </div>

        <div
          className={style.wrapper}
          style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
        >
          {data &&
            data?.map((item, index) => (
              <div className={style.dataContainer} key={index}>
                <span className={style.header}>{item.header}</span>
                {!!item.component && item.component}
                {item.isDate && (
                  <span className="d-flex align-items-center gap-5">
                    <span
                      className={classNames(
                        style.iconWrapper,
                        style.description,
                        'mr-2'
                      )}
                    >
                      <img src={calendar} className={style.icon} />
                    </span>
                    <span className={style.description}>
                      {item.description}
                    </span>
                  </span>
                )}
                {!item.isDate && item.description && (
                  <div className={style.description}>{item.description}</div>
                )}
              </div>
            ))}
        </div>
      </div>
      {isEditOpen && (
        <EditOverviewModal
          isOpen={isEditOpen}
          toggle={closeEdit}
        ></EditOverviewModal>
      )}
    </>
  );
};

export default Overview;
