import React from 'react';
import cx from 'classnames';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import Avatar from 'components/Avatar';
import { ReactComponent as DocumentIcon } from 'assets/img/icons/document-comment-icon.svg';
import { ReactComponent as StudyIcon } from 'assets/img/icons/study-message.svg';
import classes from './Messages.module.scss';
import parse from 'html-react-parser';
import { withTime } from 'helpers/times';

const NotificationItem = props => {
  const { notification, handleMessageClick, userTimezone } = props;

  const getMessageIcon = () => {
    switch (notification?.comment_type) {
      case 'DocumentType':
      case 'FinancialYearDocumentType':
        return <DocumentIcon />;
      case 'Company':
      case 'Initiative':
      default:
        return <StudyIcon />;
    }
  };
  const getFirstParagraphFromMessage = response => {
    response = response
      .replaceAll('<br>', '')
      .replaceAll('<blockquote>', '<p>')
      .replaceAll('</blockquote>', '</p>')
      .replaceAll('<p></p>', '');
    const regex = /<p>(.*?)<\/p>/;
    const corresp = regex.exec(response);
    const firstParagraphWithoutHtml = corresp ? corresp[1] : '';
    return firstParagraphWithoutHtml;
  };
  const getMessageTitle = notification => {
    switch (notification?.comment_type) {
      case 'DocumentType':
      case 'FinancialYearDocumentType':
        return notification.subject;
      case 'Company':
      case 'Initiative':
      default:
        var array = notification?.subject?.split(' - ');
        if (array?.length > 0) array[0] = 'Study Chat';
        return array?.length > 0 ? array.join(' - ') : '';
    }
  };
  return (
    <div
      onClick={() => handleMessageClick(notification)}
      className={classes.notificationWrapper}
    >
      <div
        className={cx(classes.notificationSidebar, {
          [classes.active]: isNil(notification?.read_at),
        })}
      >
        {!isNil(notification.read_at) && (
          <div className="d-flex flex-column h-100">
            <div className="h-50"></div>
            <div style={{ borderBottom: '1px solid #dee2e6' }}></div>
            <div className="h-50"></div>
          </div>
        )}
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.header}>
          <div className={classes.subject}>
            <div className={classes.icon}>{getMessageIcon()}</div>
            <p className={classes.title}>{getMessageTitle(notification)}</p>
          </div>
          <div className={classes.postDetails}>
            <p className={classes.date}>
              {withTime(notification?.updated_at, {
                format: 'date-time',
                tz: userTimezone,
              })}
            </p>
            <Avatar
              className={classes.avatar}
              url={notification?.created_by?.avatar}
            />
          </div>
        </div>
        <div className={classes.divider} />
        <div className={classes.content}>
          <div className={classes.message}>
            {parse(getFirstParagraphFromMessage(notification?.message))}
          </div>
          <div className={classes.messageOpenAction}>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationItem.prototype = {
  notification: PropTypes.object,
  meta: PropTypes.object,
  markRead: PropTypes.func,
};

export default NotificationItem;
