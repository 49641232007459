import React, { memo, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import CloseIcon from 'assets/img/icons/close-icon.svg';
import NewCommentIcon from 'assets/img/icons/deal-desk/new-comment-icon.svg';
import classes from './CommentFeature.module.scss';
import SendIcon from 'assets/img/icons/deal-desk/send-message-icon.svg';
import { ShowComments } from './ShowComments';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import Editor from 'components/FormFields/Editor';
import ContentEditor from 'components/ContentEditor';
import Input from 'components/FormFields/Input';
import { store } from 'store';
import { getStringFromHtml } from 'helpers/constants';
import { cleanUpMentions } from 'helpers/mentions';

import {
  callPostAddCommentDealDesk,
  callGetDealDeskById,
} from 'store/actions/dealDeskActions';
import { removeMentionsUrl, setMentionsUrl } from 'store/actions/components';
import { withTime } from 'helpers/times';

const CommentFeature = ({ field, label, value }) => {
  const dispatch = useDispatch();
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const data = dealDesk?.data;

  const id = data?.id;
  const userId = useSelector(({ auth }) => get(auth, 'user.id'));
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const clientId = get(data, 'client.id');
  const [showEditor, setShowEditor] = useState(false);
  const user = useSelector(({ auth }) => get(auth, 'user'));

  const handleChange = e => {
    setText(e.target.value);
  };

  const onSubmit = async () => {
    setSubmitted(true);
    dispatch(
      callPostAddCommentDealDesk(
        id,
        text ? cleanUpMentions(text) : getComment(),
        `<b>${label}: </b> ${value}`,
        field
      )
    );
    dispatch(callGetDealDeskById(id));
  };
  const getComment = () =>
    text.endsWith('<p><br></p>')
      ? text.replace('<p><br></p>', '')
      : cleanUpMentions(text);
  const mentionUrl = `/clients/${clientId}/mention?q=&is_client=0&is_fulfilment=1`;

  const renderContent = () => {
    if (isSubmitted) return renderCommment();

    return (
      <form name="new-comment" onSubmit={onSubmit}>
        <div className={classes.commentBox}>
          <div className={classes.title}>
            <div>New Comment</div>
            <button
              aria-label="Close"
              type="button"
              onClick={() => setShow(false)}
            >
              <img src={CloseIcon} alt="" />
            </button>
          </div>
          <div className={classes.editorWrapper} id="comment-editor">
            {showEditor ? (
              <Editor
                id="comments-editor"
                value={text}
                onChange={content => {
                  setText(content);
                }}
                onBlur={() => {
                  removeMentionsUrl();
                }}
                assignRef={ref => {
                  if (ref) {
                    const editor = ref.getEditor();
                    editor.focus();
                  }
                }}
                shouldAllowMention={true}
                mentionProps={{
                  defaultMenuOrientation: 'top',
                }}
              />
            ) : (
              <Input
                disabled={false}
                value={getStringFromHtml(text)}
                placeholder="Add a message"
                onClick={() => {
                  setShowEditor(true);
                  dispatch(setMentionsUrl(mentionUrl));
                }}
                maxLength={255}
              />
            )}
          </div>

          <button
            className={classes.btSubmit}
            style={{ float: 'right' }}
            type="submit"
          >
            <img src={SendIcon} />
          </button>
        </div>
      </form>
    );
  };

  const renderCommment = () => {
    return (
      <div className={classes.commentBox}>
        <div className={classes.title}>
          <div>{user.name}</div>
          <div className={classes.date}>{withTime(new Date())}</div>
        </div>
        <ContentEditor content={text} />
      </div>
    );
  };

  return (
    <>
      <ShowComments field={field} />
      <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false);
        }}
      >
        <div className={classes.commentFeature}>
          <img
            action="button"
            src={NewCommentIcon}
            onClick={() => setShow(true)}
          />

          {show && renderContent()}
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default memo(CommentFeature);
