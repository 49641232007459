import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_ONBOARING_DOCUMENT_TYPES_INPROGRESS',
  'FETCH_ONBOARING_DOCUMENT_TYPES_SUCCESS',
  'FETCH_ONBOARING_DOCUMENT_TYPES_FAILURE',
  'FETCH_ONBOARING_INVITE_ROLES_INPROGRESS',
  'FETCH_ONBOARING_INVITE_ROLES_SUCCESS',
  'FETCH_ONBOARING_INVITE_ROLES_FAILURE',
  'FETCH_ONBOARING_TEAM_MEMBERS_INPROGRESS',
  'FETCH_ONBOARING_TEAM_MEMBERS_SUCCESS',
  'FETCH_ONBOARING_TEAM_MEMBERS_FAILURE',
  'ONBOARDING_DELETE_TEAM_MEMBERS_INPROGRESS',
  'ONBOARDING_DELETE_TEAM_MEMBERS_SUCCESS',
  'ONBOARDING_DELETE_TEAM_MEMBERS_FAILURE',
  'ONBOARDING_INVITE_TEAM_MEMBER_INPROGRESS',
  'ONBOARDING_INVITE_TEAM_MEMBER_SUCCESS',
  'ONBOARDING_INVITE_TEAM_MEMBER_FAILURE',
  'CLIENT_SETUP_INPROGRESS',
  'CLIENT_SETUP_SUCCESS',
  'CLIENT_SETUP_FAILURE',
  'ONBOARDING_SET_COMPLETE_INPROGRESS',
  'ONBOARDING_SET_COMPLETE_SUCCESS',
  'ONBOARDING_SET_COMPLETE_FAILURE',
  'ONBOARDING_REINVITE_TEAM_MEMBERS_INPROGRESS',
  'ONBOARDING_REINVITE_TEAM_MEMBERS_SUCCESS',
  'ONBOARDING_REINVITE_TEAM_MEMBERS_FAILURE',
  'FINCH_SETUP_INPROGRESS',
  'FINCH_SETUP_SUCCESS',
  'FINCH_SETUP_FAILURE',
  'FETCH_CODAT_CONNECT_URL_INPROGRESS',
  'FETCH_CODAT_CONNECT_URL_SUCCESS',
  'FETCH_CODAT_CONNECT_URL_FAILURE',
  'CODAT_SETUP_INPROGRESS',
  'CODAT_SETUP_SUCCESS',
  'CODAT_SETUP_FAILURE',
  'FINCH_DISCONNECT_INPROGRESS',
  'FINCH_DISCONNECT_SUCCESS',
  'FINCH_DISCONNECT_FAILURE',
  'CODAT_DISCONNECT_INPROGRESS',
  'CODAT_DISCONNECT_SUCCESS',
  'CODAT_DISCONNECT_FAILURE'
);
