import React from 'react';
import { Row } from 'reactstrap';
import classes from './CompanyDates.module.scss';
import className from 'classnames';
import FormikInput from 'components/FormFields/Input/FormikInput';
import RSelect from 'components/FormFields/RSelect';
import DatePicker from 'components/FormFields/DatePicker';
import * as moment from 'moment';

const CompanyDates = ({
  industryDropdownOptions,
  companySizeOptions,
  yearsList,
  setFieldValue,
  values,
}) => (
  <>
    <Row className={classes.row}>
      <div className={classes.textInputRightMargin}>
        <RSelect
          name="industry"
          label="Industry"
          className="mb-4"
          value={values.industry}
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          options={industryDropdownOptions}
          onChange={value => {
            setFieldValue('industry', value ? value : null);
          }}
        />
      </div>
      <div className={classes.textInputLeftMargin}>
        <RSelect
          name="size"
          label="Company Size"
          className="mb-4"
          options={companySizeOptions}
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          value={values.size}
          onChange={value => {
            setFieldValue('size', value ? value : null);
          }}
        />
      </div>
    </Row>
    <Row className={classes.row}>
      <div className={classes.textInputRightMargin}>
        <label className="form-control-label">Filling Deadline</label>
        <DatePicker
          placeholder="MM/DD/YYYY"
          name="filing_deadline"
          value={values.filing_deadline}
          renderInput={(props, openCalendar) => {
            return (
              <div className={classes.dateWrapper}>
                <input {...props} />
                <i
                  className={className('fas fa-calendar', classes.icon)}
                  onClick={openCalendar}
                />
              </div>
            );
          }}
          onChange={dates => {
            const date = moment(dates).format('MM/DD/YYYY');
            setFieldValue('filing_deadline', date);
          }}
        />
      </div>
      <FormikInput
        name="founding_year"
        label="Founding Year"
        type="text"
        placeholder="YYYY"
        groupClassName={classes.textInputMiddleMargin}
      />
      <FormikInput
        name="first_year_qres_gr"
        label="First Year W/ QREs & GR"
        type="text"
        placeholder="YYYY"
        groupClassName={classes.textInputLeftMargin}
      />
      <FormikInput
        name="first_year_of_revenues"
        label="First Year Of Revenues"
        type="text"
        placeholder="YYYY"
        groupClassName={classes.textInputLeftMargin}
      />
    </Row>
    <Row className={classes.row}>
      <FormikInput
        name="project_tax_yearends"
        label="Project Tax Yearends"
        type="text"
        groupClassName={classes.textInputRightMargin}
      />
      <div className={classes.textInputMiddleMargin}>
        <label className="form-control-label">Company Yearend</label>
        <DatePicker
          placeholder="MM/DD/YYYY"
          name="company_yearend"
          id="company_yearend"
          value={values.company_yearend}
          renderInput={(props, openCalendar) => {
            return (
              <div className={classes.dateWrapper}>
                <input {...props} />
                <i
                  className={className('fas fa-calendar', classes.icon)}
                  onClick={openCalendar}
                />
              </div>
            );
          }}
          onChange={dates => {
            const date = moment(dates).format('MM/DD/YYYY');
            setFieldValue('company_yearend', date);
          }}
        />
      </div>
      <FormikInput
        name="estimated_benefit"
        label="Estimated R&D Credit Benefit"
        type="text"
        groupClassName={classes.textInputLeftMargin}
      />
    </Row>
    <Row className={className('mb-3', classes.row)}>
      <div className={classes.textInputRightMargin}>
        <RSelect
          isMulti
          name="engagement_years"
          label="Engagement Years"
          options={yearsList}
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          value={values.engagement_years}
          onChange={value => {
            setFieldValue('engagement_years', value);
          }}
        />
      </div>
      {/*<div className={classes.textInputLeftMargin}>*/}
      {/*  <RSelect*/}
      {/*    isMulti*/}
      {/*    name="initial_study_years"*/}
      {/*    label="Initial Study Years"*/}
      {/*    options={yearsList}*/}
      {/*    getOptionLabel={opt => opt.label}*/}
      {/*    getOptionValue={opt => opt.value}*/}
      {/*    value={values.initial_study_years}*/}
      {/*    onChange={value => {*/}
      {/*      setFieldValue('initial_study_years', value);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}
    </Row>
  </>
);

export default CompanyDates;
