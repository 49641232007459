import classes from '../QuickStartTour.module.scss';

export const getTimeTrackingSteps = () => [
  {
    target: '#topTimerInput',
    title: 'Create a Time Entry',
    content: 'Clicking the play button will start your timer.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    extraPlacementClass: classes.topBarIcon,
  },
  {
    target: '#topTimerInput',
    title: 'Create a Time Entry',
    content:
      'Once you are done with your task you can click stop and log a time entry.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    extraPlacementClass: classes.topBarIcon,
  },
  {
    target: '#startTimerInput',
    title: 'Create a Time Entry',
    content:
      'Time entries only require a start & end time. When you are finished, click Save Time Entry.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.startTimerInput,
  },
];
