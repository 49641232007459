import get from 'lodash/get';
import findKey from 'lodash/findKey';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import Types from 'store/types/story';
import BacklogTypes from 'store/types/backlogs';
import {
  fetchComments,
  postComment,
  deleteComment,
  updateComment,
  readComments,
  getUnreadCount,
} from 'api/story';
import { getUniqDataInOrder } from 'helpers/constants';
import NotificationHandler from 'components/Notifications/NotificationHandler';

export const fetchStoryComments = (
  storyId,
  messageId,
  isInitialCall = false,
  type,
  params,
  chatType = null
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.FETCH_STORY_COMMENTS_INPROGRESS,
    });
    // this will be used to show loader until first call is completed
    if (isInitialCall) {
      dispatch({
        type: Types.FETCH_INTITIAL_COMMENTS_INPROGRESS,
        data: true,
      });
    }
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchComments(
        storyId,
        messageId,
        type,
        params,
        chatType
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const records = get(resp, 'data', []);
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        let existingComments = [
          ...get(getState(), 'story.comments.storyComments.data.data', []),
        ];
        existingComments = existingComments.filter(
          comment =>
            comment.comment_type == type &&
            comment.parent_id == params.parent_id
        );
        dispatch({
          type: Types.FETCH_STORY_COMMENTS_SUCCESS,
          data: {
            data: {
              ...resp,
              data: uniqBy(
                [...orderBy([...records, ...existingComments], 'id', 'asc')],
                'id'
              ),
            },
            shouldMakeCall: !!records.length,
          },
        });

        if (isInitialCall) {
          dispatch({
            type: Types.FETCH_INTITIAL_COMMENTS_INPROGRESS,
            data: false,
          });
        }
        return records;
      } else {
        dispatch({
          type: Types.FETCH_STORY_COMMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STORY_COMMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const setStoryCommentData = (comments, setUnreadFrom = false) => {
  return dispatch => {
    const orderedComments = getUniqDataInOrder([...comments]);
    dispatch({
      type: Types.SET_STORY_COMMENTS,
      data: {
        updatedComments: orderedComments,
      },
    });
    if (setUnreadFrom) {
      dispatch({
        type: Types.SET_STORY_COMMENT_UNREAD_FROM,
        data:
          orderedComments && orderedComments.length
            ? orderedComments[0].id
            : null,
      });
    }
  };
};

export const clearCommentsUnreadFrom = () => ({
  type: Types.SET_STORY_COMMENT_UNREAD_FROM,
  data: null,
});

export const fetchMoreStoryComments = (storyId, messageId, type, params) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.FETCH_STORY_COMMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await fetchComments(storyId, messageId, type, params);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const records = get(resp, 'data', []);
      // using orderBy to store the comments in order of the id so that we have old to new order
      let existingComments = [
        ...get(getState(), 'story.comments.storyComments.data.data', []),
      ];
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_STORY_COMMENTS_SUCCESS,
          data: {
            data: getUniqDataInOrder([...records, ...existingComments]),
            shouldMakeCall: !!records.length,
          },
        });
      } else {
        dispatch({
          type: Types.FETCH_STORY_COMMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_STORY_COMMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const clearCommentsData = () => ({
  type: Types.CLEAR_STORY_COMMENTS,
});

export const postCommentForStory = (
  storyId,
  comment,
  type,
  props,
  chatType = null
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.POST_STORY_COMMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await postComment(storyId, comment, type, props, chatType);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data', {});
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        const existingComments = get(
          getState(),
          'story.comments.storyComments.data.data',
          []
        );
        dispatch({
          type: Types.POST_STORY_COMMENT_SUCCESS,
          data: getUniqDataInOrder([...existingComments, record]),
        });
        return resp;
      } else {
        dispatch({
          type: Types.POST_STORY_COMMENT_FAILURE,
          message: message,
        });
        return resp;
      }
    } catch (error) {
      dispatch({
        type: Types.POST_STORY_COMMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const updateCommentForStory = (commentId, comment) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.POST_STORY_COMMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await updateComment(commentId, comment);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const record = get(resp, 'data', {});
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        const existingComments = get(
          getState(),
          'story.comments.storyComments.data.data',
          []
        );
        dispatch({
          type: Types.POST_STORY_COMMENT_SUCCESS,
          data: getUniqDataInOrder([
            ...existingComments.map(c => (c.id === commentId ? record : c)),
          ]),
        });
      } else {
        dispatch({
          type: Types.POST_STORY_COMMENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.POST_STORY_COMMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteStoryComment = (commentId, customMessage = {}) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.DELETE_STORY_COMMENT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteComment(commentId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        const existingComments = get(
          getState(),
          'story.comments.storyComments.data.data',
          []
        );
        dispatch({
          type: Types.DELETE_STORY_COMMENT_SUCCESS,
          data: getUniqDataInOrder([
            ...existingComments.filter(c => c.id !== commentId),
          ]),
        });
        NotificationHandler.open({
          title: 'Comment Deleted Successfully',
          message: message,
          operation: 'success',
          ...customMessage,
        });
      } else {
        dispatch({
          type: Types.DELETE_STORY_COMMENT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_STORY_COMMENT_FAILURE,
        message: error,
      });
    }
  };
};

export const markCommentsAsRead = (comments, storyId) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.MARK_READ_COMMENTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await readComments(comments);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.MARK_READ_COMMENTS_SUCCESS,
        });
        const sectionData = get(getState(), 'backlog.itemsState.data', {});
        const sectionId = findKey(sectionData, section => {
          const stories = get(section, 'data', []);
          return stories.findIndex(s => s.id === storyId);
        });
        if (sectionId) {
          const stories = get(
            getState(),
            `backlog.itemsState.data.${sectionId}.data`,
            []
          );
          const foundIndex = stories.findIndex(s => s.id === storyId);
          dispatch({
            type: BacklogTypes.UPDATE_STORY_DATA,
            data: {
              items: [
                ...stories.splice(foundIndex, 0, {
                  ...stories[foundIndex],
                  unread_comments:
                    stories[foundIndex].unread_comments - comments.length > 0
                      ? stories[foundIndex].unread_comments - comments.length
                      : 0,
                }),
              ],
              sectionId,
            },
          });
        }
      } else {
        dispatch({
          type: Types.MARK_READ_COMMENTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.MARK_READ_COMMENTS_FAILURE,
        message: error,
      });
    }
  };
};

export const updateCommentsFromSocket = (eventType, comment) => {
  return (dispatch, getState) => {
    const comments = get(
      getState(),
      'story.comments.storyComments.data.data',
      []
    );
    const existingUnreadFrom = get(
      getState(),
      'story.comments.storyComments.unreadFrom',
      null
    );
    let updatedComments = [];
    let unreadFrom = null;
    switch (eventType) {
      case 'posted': {
        updatedComments = [...comments, comment];
        if (!existingUnreadFrom) {
          unreadFrom = comment.id;
        }
        break;
      }
      case 'edited': {
        updatedComments = [
          ...comments.map(c => (c.id === comment.id ? comment : c)),
        ];
        break;
      }
      case 'deleted': {
        updatedComments = [...comments.filter(c => c.id !== comment.id)];
        break;
      }
      default:
        break;
    }
    dispatch({
      type: Types.SET_STORY_COMMENTS,
      data: {
        updatedComments,
        ...(unreadFrom ? { unreadFrom } : {}),
      },
    });
  };
};

export const updateTypingUsers = user => {
  return (dispatch, getState) => {
    let users = get(getState(), 'story.comments.typingUsers.users', []);
    const foundUser = users.findIndex(u => u.id === user.id);
    if (foundUser > -1) {
      if (user.typing) {
        users = [...users.map(u => (u.id === user.id ? user : u))];
      } else {
        users = [...users.filter(u => u.id !== user.id)];
      }
    } else {
      if (user.typing) {
        users = [...users, user];
      }
    }
    dispatch({
      type: Types.SET_TYPING_USERS,
      data: users,
    });
  };
};

export const getUnreadMessagesCount = () => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_UNREAD_COUNT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getUnreadCount();
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      const count = get(resp, 'count');
      // using orderBy to store the comments in order of the id so that we have old to new order
      if (status) {
        dispatch({
          type: Types.FETCH_UNREAD_COUNT_SUCCESS,
          count,
        });
      } else {
        dispatch({
          type: Types.FETCH_UNREAD_COUNT_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_UNREAD_COUNT_FAILURE,
        message: error,
      });
    }
  };
};
