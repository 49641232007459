import React from 'react';
import classes from 'views/pages/QuickActions/QuickActions.module.scss';
import cs from 'classnames';
import { useHistory } from 'react-router-dom';

const ViewKanban = () => {
  const history = useHistory();

  const handlePageChange = () => {
    history.push('/admin/kanban/personal');
  };

  return (
    <div className={classes.actionBox} onClick={handlePageChange}>
      <div className={cs(classes.gradient, classes.viewKanban)} />
      <div className={classes.box}>
        <i className={cs('ni ni-chart-bar-32 text-xl', classes.kanbanIcon)} />
        <h2>View My Kanban</h2>
      </div>
    </div>
  );
};

export default ViewKanban;
