import React from 'react';
import BaseContentLoader from 'react-content-loader';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import replace from 'lodash/replace';

const LineLoader = props => {
  const { number = 1, height = 10, ...rest } = props;
  const heightNumber = parseFloat(height);
  const heightUnit = replace(height, heightNumber, '');

  const SPACE_BETWEEN_RECT = 10;
  const svgHeight =
    heightNumber * number + (number - 1) * SPACE_BETWEEN_RECT + heightUnit;
  return (
    <BaseContentLoader speed={3} width="100%" height={svgHeight} {...rest}>
      {range(0, number).map(i => {
        let y = heightNumber * i + i * SPACE_BETWEEN_RECT + heightUnit;
        return (
          <rect
            key={i}
            x="0"
            y={y}
            rx="3"
            ry="3"
            width="100%"
            height={height}
          />
        );
      })}
    </BaseContentLoader>
  );
};

LineLoader.prototype = {
  number: PropTypes.number, // this is number of lines we want to render
  height: PropTypes.string, // height of each line
};

export default LineLoader;
