import React, { useState, useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
import classes from './StoryAssignments.module.scss';
import { Card, CardBody, Row, Col, Progress } from 'reactstrap';
import Owner from './Fields/Owner';
import DueDate from './Fields/DueDate';
import Type from './Fields/Type';
import BudgetHours from './Fields/BudgetHours';
import CheckList from './Fields/CheckList';
import useWindowSize from 'react-use/lib/useWindowSize';
import cs from 'classnames';
import { Formik } from 'formik';

import {
  doPatchUpdateAssignment,
  doDeleteAssignment,
  doPostDuplicateAssignment,
  doPostCompleteAssignment,
  doPostRestoreAssignment,
} from 'store/actions/Story/assignments';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import Dropdowns from 'components/Dropdowns';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import Dependency from './Fields/Dependency';
import Button from 'components/Button';
import pick from 'lodash/pick';
import Deliverables from './Fields/Deliverables';
import { analyticsConstants } from 'helpers/analytics';

const AssignmentItem = props => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const {
    projectId,
    storyId,
    task,
    reload,
    provided,
    isEditAllowed,
    analyticsSendEvent,
  } = props;
  const { width } = useWindowSize();
  const [smallView, setSmallView] = useState(false);
  const [loadingAssignment, setLoadingAssigment] = useState(false);
  const ref = useRef(null);
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));
  const taskTypeChange = useSelector(({ assignmentTypeCustomizations }) =>
    get(assignmentTypeCustomizations, 'assignmentTypes.typeModified', 0)
  );
  useEffect(() => {
    const widthDiv = ref.current.clientWidth;

    if (widthDiv > 259) setSmallView(false);
    else setSmallView(true);
  }, [width]);

  const initialValues = {
    ...pick(task, ['budget_hours', 'task_type', 'user', 'date_due', 'id']),
  };

  const handleOnSubmit = async (values, actions) => {
    let data = {};

    data.budget_hours = values.budget_hours;
    if (get(values, 'task_type.id')) data.task_type_id = values.task_type.id;
    if (get(values, 'user.id')) data.user_id = values.user.id;
    if (get(values, 'date_due'))
      data.date_due = moment(values.date_due, 'YYYY-MM-DD').format(
        'YYYY/MM/DD'
      );
    await dispatch(doPatchUpdateAssignment(storyId, task.id, data));
    reload();
  };

  useEffect(() => {
    let values = {
      ...pick(task, ['budget_hours', 'task_type', 'user', 'date_due', 'id']),
    };
    formRef.current.setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);

  const checklists = get(task, 'checklists', []) || [];
  const completedChecklists =
    checklists.filter(item => item.status === true) || [];
  const progressChecklist =
    completedChecklists.length > 0
      ? Math.round(
          (completedChecklists.length / checklists.length) * 100 * 10
        ) / 10
      : 0;
  const health = get(task, 'health');

  let startAt = get(task, 'started_at');
  startAt = startAt
    ? 'Started - ' + moment(startAt).format('MMM DD, YYYY')
    : 'Not Started';

  const completedAt = get(task, 'completed_at');
  const isDependency = get(task, 'is_locked', false);

  return (
    <div
      className={cs(classes.AssignmentItem, {
        [classes.active]: !!get(task, 'started_at') || completedAt,
      })}
      ref={ref}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          handleOnSubmit(values, actions);
        }}
      >
        {formik => {
          const owner = formik.values.user;
          return (
            <>
              <div
                className={cs(classes.start, {
                  [classes.active]: !!get(task, 'started_at') || completedAt,
                })}
              >
                {isDependency === true && <i className="fas fa-lock"></i>}
              </div>
              <div className={classes.head}>
                {startAt}
                <Dependency
                  task={task}
                  smallView={smallView}
                  reload={reload}
                  isEditAllowed={isEditAllowed}
                  isDependency={isDependency}
                  analyticsSendEvent={analyticsSendEvent}
                />
              </div>
              <div className={cs(classes.body)}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12} className="d-flex align-items-start ">
                        {isEditAllowed && (
                          <i
                            {...provided.dragHandleProps}
                            className={cs(
                              'fas fa-grip-vertical',
                              classes.dragIcon
                            )}
                          />
                        )}

                        <Type
                          isEditAllowed={isEditAllowed}
                          smallView={smallView}
                          formik={formik}
                          analyticsSendEvent={analyticsSendEvent}
                          taskTypeChange={taskTypeChange}
                        />

                        {isEditAllowed && (
                          <Dropdowns
                            dropdownClasses="ml-auto"
                            className="text-light ml-1 "
                            text={<i className="fas fa-ellipsis-v" />}
                            size="sm"
                            caret={false}
                            role="button"
                            color=""
                            options={[
                              {
                                text: 'Duplicate Assignment',
                                onClick: async () => {
                                  analyticsSendEvent({
                                    action:
                                      analyticsConstants.action
                                        .duplicate_assignment,
                                  });
                                  await dispatch(
                                    doPostDuplicateAssignment(storyId, task.id)
                                  );
                                  reload(true);
                                },
                              },
                              {
                                text: 'Delete Assignment',
                                onClick: () => {
                                  AlertPopupHandler.open({
                                    onConfirm: async () => {
                                      analyticsSendEvent({
                                        action:
                                          analyticsConstants.action
                                            .delete_assignment,
                                      });

                                      await dispatch(
                                        doDeleteAssignment(storyId, task.id)
                                      );
                                      reload();
                                    },
                                    confirmBtnText: 'Delete Assignment',
                                    text: `If you delete this assignment all information within it will be lost. Do you want to continue?`,
                                    data: {},
                                  });
                                },
                              },
                            ]}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={smallView ? 12 : 6}>
                        <Owner
                          isEditAllowed={isEditAllowed}
                          projectId={projectId}
                          smallView={smallView}
                          formik={formik}
                          analyticsSendEvent={analyticsSendEvent}
                        />
                      </Col>
                      <Col md={smallView ? 12 : 6}>
                        <DueDate
                          formik={formik}
                          isEditAllowed={isEditAllowed}
                          analyticsSendEvent={analyticsSendEvent}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <CheckList
                          task={task}
                          reload={reload}
                          isEditAllowed={isEditAllowed}
                          isOwner={owner && owner.id === currentUser.id}
                          isDependency={isDependency}
                          analyticsSendEvent={analyticsSendEvent}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <BudgetHours
                          formik={formik}
                          isEditAllowed={isEditAllowed}
                          analyticsSendEvent={analyticsSendEvent}
                        />
                      </Col>
                    </Row>
                    {!isDependency && (
                      <Row>
                        <Col md={12}>
                          <Deliverables
                            task={task}
                            isOwner={owner && owner.id === currentUser.id}
                            reload={reload}
                            analyticsSendEvent={analyticsSendEvent}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md={smallView ? 12 : 6}>
                        <div className={classes.subTitle}>
                          Completed Items: {completedChecklists.length}/
                          {checklists.length}
                        </div>
                        <Progress
                          max="100"
                          value={
                            progressChecklist > 100 ? 100 : progressChecklist
                          }
                          color={progressChecklist > 100 ? 'danger' : 'success'}
                        />
                      </Col>
                      <Col md={smallView ? 12 : 6}>
                        <div className={classes.subTitle}>
                          Consumed Hours: {health.consumed_hrs}/
                          {health.total_hrs}
                        </div>
                        <Progress
                          max="100"
                          value={health.progress}
                          color="success"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
              <div
                className={cs(classes.footer, {
                  [classes.readyComplete]:
                    owner &&
                    owner.id === currentUser.id &&
                    !completedAt &&
                    !isDependency &&
                    checklists.length === completedChecklists.length,
                  [classes.readyRestore]:
                    (isEditAllowed || (owner && owner.id === currentUser.id)) &&
                    completedAt,
                })}
              >
                <span>
                  {completedAt
                    ? 'Completed - ' +
                      moment(completedAt).format('MMM DD, YYYY')
                    : 'Not Completed'}
                </span>

                {owner &&
                  owner.id === currentUser.id &&
                  !completedAt &&
                  !isDependency &&
                  checklists.length === completedChecklists.length && (
                    <Button
                      type="button"
                      color="primary"
                      size="sm"
                      onClick={async () => {
                        setLoadingAssigment(true);
                        analyticsSendEvent({
                          action: analyticsConstants.action.complete_assignment,
                        });
                        await dispatch(
                          doPostCompleteAssignment(storyId, task.id)
                        );
                        setLoadingAssigment(false);
                        reload();
                      }}
                      loading={loadingAssignment}
                    >
                      Complete Assignment
                    </Button>
                  )}

                {(isEditAllowed || (owner && owner.id === currentUser.id)) &&
                  completedAt && (
                    <button
                      type="button"
                      className="btn-link btn font-weight-light p-0"
                      onClick={async e => {
                        e.preventDefault();
                        analyticsSendEvent({
                          action:
                            analyticsConstants.action
                              .restore_completed_assignment,
                        });
                        await dispatch(
                          doPostRestoreAssignment(storyId, task.id)
                        );
                        reload();
                      }}
                    >
                      Restore to In Progress
                    </button>
                  )}
              </div>
              <div
                className={cs(classes.end, { [classes.active]: !!completedAt })}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};

AssignmentItem.propTypes = {};
AssignmentItem.defaultProps = {};

export default AssignmentItem;
