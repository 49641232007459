import React from 'react';
import classnames from 'classnames';
import classes from '../TaskModal.module.scss';
import PropTypes from 'prop-types';

const Info = ({ title, value, type = 'text' }) => {
  return (
    <div className={classnames('mb-3', classes['description'])}>
      <h4 id="storyModalStoryName" className="mt-0">
        {title}
      </h4>
      {type === 'text' ? (
        <p>{value}</p>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: value }} />
      )}
    </div>
  );
};

Info.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default Info;
