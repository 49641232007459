import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cs from 'classnames';
import { useClickAway } from 'react-use';
import { CardFooter } from 'reactstrap';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import Editor from 'components/FormFields/Editor';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import Input from 'components/FormFields/Input';
import {
  postCommentForStory,
  updateCommentForStory,
} from 'store/actions/Story/comments';
import { removeMentionsUrl, setMentionsUrl } from 'store/actions/components';
import classes from '../Tabs.module.scss';
import { cleanUpMentions } from 'helpers/mentions';
import socket from 'helpers/socket';
import { analyticsConstants } from 'helpers/analytics';
const CommentsFooter = ({
  projectId,
  editComment,
  storyId,
  handleScrollToBottom,
  viewMode,
  resetData,
  setShowEditor,
  showEditor,
  analyticsSendEvent,
}) => {
  const dispatch = useDispatch();

  const [comment, setComment] = useState('');
  const commentRef = useRef(null);
  const [assignRef, setAssignRef] = useState(null);
  const isCommentEmpty = isEmpty(comment) || isEqual(comment, '<p><br></p>');

  const isPosting = useSelector(({ story }) =>
    get(story, 'comments.postStoryComment.isInProgress', false)
  );

  /*
  Uncomment this if handleEnter feature  is required
  //prevComment will be used when user presses enter to send as API data
  const prevComment = usePrevious(comment);
  const isMentionOpen = useSelector(({ components }) =>
    get(components, 'isMentionOpen', false)
  );
  */
  const loggedInUser = useSelector(({ auth }) => get(auth, 'user', null));

  useEffect(() => {
    if (viewMode === 'edit') {
      setComment(editComment.message);
      setShowEditor(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);

  useEffect(() => {
    // added this so that when component is removed we remove the assignRef
    return () => {
      setAssignRef(null);
    };
  }, []);

  const getComment = () =>
    comment.endsWith('<p><br></p>')
      ? comment.replace('<p><br></p>', '')
      : cleanUpMentions(comment);

  const closeComment = () => {
    dispatch(removeMentionsUrl());
    socket.stopTypingWhisper();
    setShowEditor(false);
    if (viewMode === 'edit') {
      resetData();
    }
  };

  const handlePostComment = async () => {
    if (isPosting) {
      // if already posting comment we are returning to avoid duplicate comments
      return;
    }
    if (viewMode === 'edit') {
      analyticsSendEvent({
        action: analyticsConstants.action.edit_comment_on_story,
      });
      await dispatch(
        updateCommentForStory(
          editComment.id,
          comment ? cleanUpMentions(comment) : getComment()
        )
      );
    } else {
      analyticsSendEvent({
        action: analyticsConstants.action.comment_on_story,
      });
      await dispatch(
        postCommentForStory(
          storyId,
          comment ? cleanUpMentions(comment) : getComment(),
          'Story'
        )
      );
    }
    setComment('');
    closeComment();
    // Adding timeout so that content from comments get rendered (For image it takes little time so adding timeout)
    setTimeout(() => handleScrollToBottom(), 500);
  };

  /*
    This function handles the enter key to post feature, if required please uncomment and add onKeyDown event to editor
   */
  /*const handleKeyDown = e => {
    if (!assignRef.getText().trim()) {
      e.stopPropagation();
      return;
    }
    // this is to prevent mention enter key event
    if (isMentionOpen) {
      dispatch(setCloseMentions());
      e.stopPropagation();
      return;
    }
    if (e.keyCode === 13 && !e.ctrlKey && !e.shiftKey) {
      /!*
        When pressing enter quill adds the line break if you press enter in between on comment
        So to prevent that we are storing prevComment and when user presses enter we use this
        as the comment values
       *!/
      setComment(prevComment);
      e.preventDefault();
      e.stopPropagation();
      handlePostComment(prevComment);
    }
  };*/

  const isUserAllowedPostComments = true; //useAccess([permissions.POST_COMMENTS]);
  useClickAway(commentRef, closeComment);

  return (
    <div
      className={cs(classes.commentsFooter, {
        [classes.commentsEditor]: showEditor,
      })}
    >
      <CardFooter className="px-3 pt-3 pb-0 h-100 w-100">
        {showEditor ? (
          <div
            className={'d-flex flex-column-reverse position-relative h-100'}
            ref={commentRef}
          >
            <div className={'w-100 mt-3 d-flex justify-content-between'}>
              <Button onClick={closeComment} color="link">
                Cancel
              </Button>
              <Button
                type="submit"
                onClick={handlePostComment}
                // the last check is used when user adds a mention, if we remove that then on adding ONLY mention we can't post comment
                disabled={
                  isCommentEmpty ||
                  isPosting ||
                  (assignRef &&
                    assignRef.getText().trim().length === 0 &&
                    !isCommentEmpty &&
                    assignRef.getContents().ops.length === 1)
                }
                color={
                  isCommentEmpty ||
                  isPosting ||
                  (assignRef &&
                    assignRef.getText().trim().length === 0 &&
                    !isCommentEmpty &&
                    assignRef.getContents().ops.length === 1)
                    ? 'secondary'
                    : 'primary'
                }
                loading={isPosting}
              >
                {viewMode === 'edit' ? 'Edit' : 'Comment'}
              </Button>
            </div>
            <div className={classes.editorWrapper} id="comment-editor">
              <Editor
                id="comments-editor"
                value={comment}
                onChange={content => {
                  socket.sendTypingWhisper();
                  setComment(content);
                }}
                onBlur={() => {
                  socket.stopTypingWhisper();
                }}
                // onKeyDown={handleKeyDown}
                assignRef={ref => {
                  if (ref) {
                    const editor = ref.getEditor();
                    setAssignRef(editor);
                    editor.focus();
                    editor.setSelection(editor.getLength());
                  }
                }}
                shouldAllowMention={true}
                mentionProps={{
                  defaultMenuOrientation: 'top',
                }}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-between w-100 align-items-center">
            <Avatar url={loggedInUser.avatar} className={classes.userAvatar} />
            <Input
              placeholder="Add a comment"
              onClick={() => {
                setShowEditor(true);
                dispatch(
                  setMentionsUrl(
                    `/users/list/dropdown?status=Active&initiative=${projectId}`
                  )
                );
              }}
              maxLength={255}
              disabled={!isUserAllowedPostComments}
            />
          </div>
        )}
      </CardFooter>
    </div>
  );
};

export default CommentsFooter;
