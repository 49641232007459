import React, { useEffect, useRef } from 'react';
import classes from './TimelogReport.module.scss';
import CardsHeader from 'components/Headers/CardsHeader.js';

import { Container } from 'reactstrap';

import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  doGetBarChart,
  doGetDoughnutChart,
} from 'store/actions/timelogReportActions';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import ChartsArea from './ChartsArea';
import Filters from './Filters';
import useSearchParam from 'react-use/lib/useSearchParam';
import moment from 'moment';
import DataArea from './DataArea';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
} from 'helpers/permission';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import NoTimeLogReport from 'assets/img/theme/No_TimeLog_Report.svg';
import analytics, { analyticsConstants } from 'helpers/analytics';

const TimelogReport = props => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.time_log_reporting,
      ...rest,
    });
  };

  const valuesParam = JSON.parse(useSearchParam('values'));
  const initialValues = {
    range_date: '',
    startDate: '',
    endDate: '',
    users: [],
    clients: [],
    themes: [],
    initiatives: [],
    epics: [],
    stories: [],
    tasks: [],
    billable: '',
    description: '',
  };

  const auth = useSelector(({ auth }) => auth);
  const { id, name } = get(auth, 'user', {});

  if (!useAccess([permissions.LIST_TIMELOGS])) showUnAuhtorizedError();
  const isUserAllowedViewAllTimeLogs = useAccess([
    permissions.VIEW_ALL_TIMELOGS,
  ]);
  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_timelog_report,
    });
    let values = { ...initialValues, ...valuesParam };

    if (values.startDate) values.startDate = moment(values.startDate);
    if (values.endDate) values.endDate = moment(values.endDate);

    if (!isUserAllowedViewAllTimeLogs) values.users = [{ id, name }];

    formRef.current.setValues(values);
    formRef.current.handleSubmit();
    document.getElementById('admin-body').classList.add('d-flex');
    return () => {
      document.getElementById('admin-body').classList.remove('d-flex');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({});

  const handleOnSubmit = (values, actions) => {
    const users = values.users.map(item => item.id);
    const clients = values.clients.map(item => item.id);
    const themes = values.themes.map(item => item.id);
    const initiatives = values.initiatives.map(item => item.id);
    const epics = values.epics.map(item => item.id);
    const stories = values.stories.map(item => item.id);
    const tasks = values.tasks.map(item => item.name);
    const date_from = values.startDate
      ? values.startDate.format('YYYY/MM/DD')
      : null;
    const date_to = values.endDate ? values.endDate.format('YYYY/MM/DD') : null;
    const billable = get(values, 'billable.id') ? [values.billable.text] : null;
    const description = values.description;

    const postData = {
      users,
      clients,
      themes,
      initiatives,
      epics,
      stories,
      tasks,
      date_from,
      date_to,
      billable,
      description,
    };

    dispatch(doGetBarChart(postData));
    dispatch(doGetDoughnutChart(postData));
  };

  const doughnutChartData = useSelector(({ timelogReport }) =>
    get(timelogReport, 'doughnutChart.data.initiatives', [])
  );

  return (
    <div className={classes.root}>
      <CardsHeader
        name="Timelog Reports"
        parentName="Timelog"
        parentLink="/admin/timelog"
        isRoot={false}
      />
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={(values, actions) => {
          handleOnSubmit(values, actions);
        }}
      >
        {formik => {
          return (
            <>
              <Filters
                props={props}
                formik={formik}
                handleOnSubmit={handleOnSubmit}
                analyticsSendEvent={analyticsSendEvent}
              />
              <div
                className={cx(
                  isEmpty(doughnutChartData) &&
                    'd-flex align-items-center h-100 justify-content-center'
                )}
              >
                {/* Render Charts */}
                {isEmpty(doughnutChartData) ? (
                  <img
                    className={cx(classes.noTimeLogImg, 'm-auto')}
                    src={NoTimeLogReport}
                    alt="No Time Logged"
                  />
                ) : (
                  <>
                    <Container fluid className={classes.chartContainer}>
                      <ChartsArea />
                    </Container>
                    <DataArea
                      formik={formik}
                      analyticsSendEvent={analyticsSendEvent}
                    />
                  </>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default TimelogReport;
