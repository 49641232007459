import React, { useState, useEffect } from 'react';

import classes from '../../DataArea.module.scss';
import cs from 'classnames';
import { secondsToHHMMSS } from 'helpers/times';
import get from 'lodash/get';

// eslint-disable-next-line no-extend-native
Number.prototype.format = function(n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

const RowReport = props => {
  const {
    groupsOpen,
    onChangeGroupsOpen,
    formik,
    group,
    subgroup,
    setTab,
  } = props;

  const [open, setOpen] = useState(false);

  const groupData = props.data;
  const subGroupData = props.subData;

  useEffect(() => {
    setOpen(props.openAll);
  }, [props.openAll]);

  useEffect(() => {
    let listGroupsOpen = groupsOpen;

    const groupId = get(groupData, 'group_id', null);

    const index = listGroupsOpen.indexOf(groupId);

    if (open === true) {
      if (index === -1) listGroupsOpen.push(groupId);
    } else {
      if (index !== -1) listGroupsOpen.splice(index, 1);
    }

    onChangeGroupsOpen(listGroupsOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const convertGroupToField = (group, item) => {
    let field = '';
    switch (group) {
      case 'user':
        field = 'users';
        break;

      case 'client':
        field = 'clients';
        break;

      case 'sow':
        field = 'themes';
        break;

      case 'project':
        field = 'initiatives';
        break;

      case 'epic':
        field = 'epics';
        break;

      case 'story':
        field = 'stories';
        break;

      case 'task':
        field = 'tasks';
        break;

      case 'timelog':
        formik.setFieldValue('description', item.subgroup_title);
        break;

      default:
        break;
    }

    return field;
  };

  const handleClickSubGroup = item => {
    // subgroup
    let field = convertGroupToField(subgroup.value, item);
    if (field)
      formik.setFieldValue(field, [
        {
          id: item.subgroup_id,
          name: item.subgroup_title,
        },
      ]);

    // group
    field = convertGroupToField(group.value, item);
    if (field)
      formik.setFieldValue(field, [
        {
          id: item.group_id,
          name: item.group_title,
        },
      ]);

    setTab('detail');
  };

  const displaySubGroup = () => {
    return subGroupData.map(item => (
      <tr className={classes.subGroup} key={`subgroup-${item.subgroup_id}`}>
        <td>
          <span
            onClick={() => {
              handleClickSubGroup(item);
            }}
          >
            {item.subgroup_title}
          </span>
        </td>
        <td>{secondsToHHMMSS(item.duration * 3600)}</td>
        <td>{item.dollar_amount ? item.dollar_amount.format(2) : 0} USD</td>
      </tr>
    ));
  };

  return (
    <>
      <tr>
        <td>
          <button
            className={cs('btn btn-link', classes.btnGroup)}
            type="button"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <i className="fas fa-minus-circle"></i>
            ) : (
              <i className="fas fa-plus-circle"></i>
            )}
            {groupData.group_title || 'Unassiged'}
          </button>
        </td>
        <td>
          <b>{secondsToHHMMSS(groupData.duration * 3600)}</b>
        </td>
        <td>
          {groupData.dollar_amount ? groupData.dollar_amount.format(2) : 0} USD
        </td>
      </tr>
      {open ? displaySubGroup() : null}
    </>
  );
};

RowReport.propTypes = {};
RowReport.defaultProps = {};

export default RowReport;
