import { useFinchConnect } from 'react-finch-connect';

const useFinch = (onSuccess, onError, onClose) => {
  return useFinchConnect({
    clientId: process.env.REACT_APP_FINCH_CLIENT_ID,
    products: [
      'company',
      'directory',
      'individual',
      'employment',
      'payment',
      'pay_statement',
    ],
    onSuccess,
    onError,
    onClose,
  });
};

export default useFinch;
