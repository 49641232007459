import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment-timezone';
import TimeLogBarChart from './TimeLogBarChart';
import TimeLogDoughnutChart from './TimeLogDoughnutChart';
import classes from './TimelogReport.module.scss';

import cs from 'classnames';
import { Col, Row, Card, CardBody, CardTitle, CardHeader } from 'reactstrap';
import clickIcon from 'assets/img/icons/clock-white.svg';
import invoiceIcon from 'assets/img/icons/invoice-white.svg';
import useBreakPoint from 'helpers/useBreakPoint';
const dateFormat = 'YYYY-MM-DD';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const ChartsArea = () => {
  const isMobile = useBreakPoint('xs', 'down');

  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const doughnutChart = useSelector(({ timelogReport }) =>
    get(timelogReport, 'doughnutChart')
  );
  const barChart = useSelector(({ timelogReport }) =>
    get(timelogReport, 'barChart')
  );
  const fromDate = get(barChart, 'data.date_from', null);
  const toDate = get(barChart, 'data.date_end', null);

  let titleChart = '';
  let percent_text = '';

  if (fromDate && toDate) {
    titleChart =
      moment(fromDate)
        .tz(timezone)
        .format('MMM DD, YYYY') +
      ' - ' +
      moment(toDate)
        .tz(timezone)
        .format('MMM DD, YYYY');

    if (
      moment(fromDate)
        .tz(timezone)
        .format(dateFormat) ===
        moment()
          .tz(timezone)
          .startOf('month')
          .format(dateFormat) &&
      moment(toDate)
        .tz(timezone)
        .format(dateFormat) ===
        moment()
          .tz(timezone)
          .endOf('month')
          .format(dateFormat)
    ) {
      titleChart = 'This Month';
      percent_text = 'Since last month';
    } else if (
      moment(fromDate)
        .tz(timezone)
        .format(dateFormat) ===
        moment()
          .tz(timezone)
          .startOf('week')
          .format(dateFormat) &&
      moment(toDate)
        .tz(timezone)
        .format(dateFormat) ===
        moment()
          .tz(timezone)
          .endOf('week')
          .format(dateFormat)
    ) {
      titleChart = 'This Week';
      percent_text = 'Since last week';
    }
  }

  const percentage_change_total = get(
    doughnutChart,
    'data.percentage_change_total',
    ''
  );

  const percentage_change_billable = get(
    doughnutChart,
    'data.percentage_change_billable',
    ''
  );
  return (
    <Row className="equal-height">
      <Col md={6} className="equal-height">
        <Card>
          <CardHeader>
            <h6 className="text-gray m-0">HOURS LOGGED</h6>
            <h2 className="mb-0">{titleChart}</h2>
          </CardHeader>
          <CardBody
            className={cs('d-flex align-items-center', classes.barChart)}
          >
            <TimeLogBarChart />
          </CardBody>
        </Card>
      </Col>
      <Col md={6} className="equal-height">
        <Card>
          <CardHeader>
            <h6 className="text-gray m-0">HOURS BREAKDOWN</h6>
            <h2 className="mb-0">{titleChart}</h2>
          </CardHeader>
          <CardBody className={cs({ 'd-flex align-items-center': !isMobile })}>
            <Row>
              <Col
                lg={6}
                className={cs(
                  'd-flex align-items-center',
                  classes.doughnutChart
                )}
              >
                <TimeLogDoughnutChart />
              </Col>
              <Col lg={6} className="d-flex align-items-center">
                <Row className="mb-4">
                  <Col md={12} className="d-flex align-items-center">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col ">
                            <CardTitle className="text-uppercase text-muted mb-0 h5">
                              TOTAL HOURS
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {get(doughnutChart, 'data.total_hours', '')}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-icon text-white rounded-circle shadow">
                              <img
                                src={clickIcon}
                                alt="Clock"
                                width="20px"
                                height="20px"
                              />
                            </div>
                          </Col>
                        </Row>

                        {percent_text && (
                          <p className="mt-3 mb-0 text-sm">
                            <span
                              className={cs(' mr-2', {
                                'text-success': percentage_change_total > 0,
                                'text-danger': percentage_change_total < 0,
                              })}
                            >
                              {percentage_change_total > 0 ? (
                                <i className="fa fa-arrow-up" />
                              ) : (
                                <i className="fa fa-arrow-down" />
                              )}
                              {Math.abs(percentage_change_total)}%
                            </span>
                            <span className="text-nowrap">{percent_text}</span>
                          </p>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="text-uppercase text-muted mb-0 h5">
                              BILLABLE HOURS
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {get(doughnutChart, 'data.billable_hours', '')}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-icon text-white rounded-circle shadow">
                              <img
                                src={invoiceIcon}
                                alt="Invoice"
                                height="20px"
                              />
                            </div>
                          </Col>
                        </Row>
                        {percent_text && (
                          <p className="mt-3 mb-0 text-sm">
                            <span
                              className={cs(' mr-2', {
                                'text-success': percentage_change_billable > 0,
                                'text-danger': percentage_change_billable < 0,
                              })}
                            >
                              {percentage_change_billable > 0 ? (
                                <i className="fa fa-arrow-up" />
                              ) : (
                                <i className="fa fa-arrow-down" />
                              )}
                              {Math.abs(percentage_change_billable)}%
                            </span>
                            <span className="text-nowrap">{percent_text}</span>
                          </p>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ChartsArea;
