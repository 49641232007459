import React from 'react';
import Button from 'components/Button';
import Modal, { ModalBody, ModalFooter } from 'components/FormFields/Modal';
import classes from './DeleteStudyModal.module.scss';
import cx from 'classnames';
const DeleteStudyModal = ({
  isOpen,
  closeModal,
  handleConfirm,
  isAdmin,
  confirmedBy = [],
}) => {
  return (
    <Modal
      className={classes.modalWrapper}
      isOpen={isOpen}
      toggle={closeModal}
      title="Delete Study"
    >
      <ModalBody>
        {!isAdmin && (
          <div className={classes.modalBody}>
            Please confirm below that you want to delete this study. This action
            will also be confirmed by two admins.
          </div>
        )}
        {isAdmin && (
          <div className={classes.modalBody}>
            <div>
              All study deletions need to be confirmed by two admins. Please
              confirm below that you want to delete this study.
            </div>
            <div>
              <h3>Confirmed By:</h3>
              <ul>
                {confirmedBy?.map(approver => (
                  <li key={approver?.id}>
                    {approver?.name}{' '}
                    <i
                      className={cx(
                        'fa fa-check-circle',
                        classes['confirmedCheck']
                      )}
                    ></i>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter className={classes.modalFooter}>
        <Button className={classes.deleteBtn} onClick={handleConfirm}>
          Delete Study
        </Button>
        <Button
          className={classes.cancelBtn}
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteStudyModal;
