import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_PARTNERS_INPROGRESS',
  'FETCH_PARTNERS_SUCCESS',
  'FETCH_PARTNERS_FAILURE',
  'INVITE_PARTNERS_INPROGRESS',
  'INVITE_PARTNERS_SUCCESS',
  'INVITE_PARTNERS_FAILURE',
  'UPDATE_PARTNERS_INPROGRESS',
  'UPDATE_PARTNERS_SUCCESS',
  'UPDATE_PARTNERS_FAILURE',
  'DELETE_PARTNERS_INPROGRESS',
  'DELETE_PARTNERS_SUCCESS',
  'DELETE_PARTNERS_FAILURE',
  'CANCEL_INVITATION_INPROGRESS',
  'CANCEL_INVITATION_SUCCESS',
  'CANCEL_INVITATION_FAILURE',
  'RESEND_INVITATION_INPROGRESS',
  'RESEND_INVITATION_SUCCESS',
  'RESEND_INVITATION_FAILURE',
  'FETCH_CALENDLY_DETAILS_BY_USER_ID_INPROGRESS',
  'FETCH_CALENDLY_DETAILS_BY_USER_ID_SUCCESS',
  'FETCH_CALENDLY_DETAILS_BY_USER_ID_FAILURE',
  'FETCH_PARTNER_DASHBOARD_BY_USER_ID_INPROGRESS',
  'FETCH_PARTNER_DASHBOARD_BY_USER_ID_SUCCESS',
  'FETCH_PARTNER_DASHBOARD_BY_USER_ID_FAILURE',
  'INVITE_CLIENT_INPROGRESS',
  'INVITE_CLIENT_SUCCESS',
  'INVITE_CLIENT_FAILURE',
  'FETCH_PARTNER_ACTIVITIES_INPROGRESS',
  'FETCH_PARTNER_ACTIVITIES_SUCCESS',
  'FETCH_PARTNER_ACTIVITIES_FAILURE',
  'FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_INPROGRESS',
  'FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_SUCCESS',
  'FETCH_CLIENT_STUDIES_FOR_GIVEN_PARTNER_FAILURE',
  'FETCH_EARNINGS_INPROGRESS',
  'FETCH_EARNINGS_SUCCESS',
  'FETCH_EARNINGS_FAILURE',
  'FETCH_INTITIAL_REFERRAL_COMMENTS_INPROGRESS',
  'FETCH_REFERRAL_COMMENTS_INPROGRESS',
  'SET_REFERRAL_COMMENTS',
  'FETCH_REFERRAL_COMMENTS_SUCCESS',
  'FETCH_REFERRAL_COMMENTS_FAILURE',
  'FETCH_MORE_REFERRAL_COMMENTS_SUCCESS',
  'CLEAR_REFERRAL_COMMENTS',
  'POST_REFERRAL_COMMENT_INPROGRESS',
  'POST_REFERRAL_COMMENT_SUCCESS',
  'POST_REFERRAL_COMMENT_FAILURE',
  'DELETE_REFERRAL_COMMENT_INPROGRESS',
  'DELETE_REFERRAL_COMMENT_SUCCESS',
  'DELETE_REFERRAL_COMMENT_FAILURE',
  'MARK_READ_REFERRAL_COMMENTS_INPROGRESS',
  'MARK_READ_REFERRAL_COMMENTS_SUCCESS',
  'MARK_READ_REFERRAL_COMMENTS_FAILURE',
  'SET_REFERRAL_COMMENT_UNREAD_FROM',
  'SET_REFERRAL_TYPING_USERS',
  'UPDATE_PARTNER_TIMEZONE_INPROGRESS',
  'UPDATE_PARTNER_TIMEZONE_SUCCESS',
  'UPDATE_PARTNER_TIMEZONE_FAILURE',
  'POST_QUESTION_INPROGRESS',
  'POST_QUESTION_SUCCESS',
  'POST_QUESTION_FAILURE'
);
