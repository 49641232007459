import Request from './request';

export const getAssignedProjects = async (userId, { sort, page }) => {
  return Request.call({
    url: `/users/${userId}/profile/assigned-projects?sort=${sort}&page=${page}`,
    method: 'GET',
  });
};

export const getTimeSpent = async (userId, { group, startDate, endDate }) => {
  return Request.call({
    url: `/users/${userId}/profile/timelog-barchart?group=${group}&date_from=${startDate}&date_to=${endDate}`,
    method: 'GET',
  });
};

export const fetchAvailability = async userId => {
  return Request.call({
    url: `/users/${userId}/profile/weekly-hours`,
    method: 'GET',
  });
};

export const fetchTasks = async userId => {
  return Request.call({
    url: `/users/${userId}/profile/completed-tasks`,
    method: 'GET',
  });
};

export const fetchDetails = async userId => {
  return Request.call({
    url: `/users/${userId}`,
    method: 'GET',
  });
};

export const updateDetails = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}`,
    method: 'PUT',
    data,
  });
};

export const updateAvatar = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}/avatar`,
    method: 'POST',
    data,
  });
};

export const getPreferences = async () => {
  return Request.call({
    url: `/me/preferences`,
    method: 'GET',
  });
};

export const setPreferences = async data => {
  return Request.call({
    url: `/me/preferences`,
    method: 'POST',
    data,
  });
};

export const storePushSubscription = async data => {
  return Request.call({
    url: '/me/push-subscriptions',
    method: 'POST',
    data,
  });
};

export const getProfileActivities = async (id, page) => {
  return Request.call({
    url: `/users/${id}/profile/activities`,
    method: 'GET',
    params: {
      page,
    },
  });
};
