import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_DOCUMENTS_INPROGRESS',
  'FETCH_DOCUMENTS_SUCCESS',
  'FETCH_DOCUMENTS_FAILURE',
  'RETRY_DOCUMENT_BATCH_UPLOAD_INPROGRESS',
  'RETRY_DOCUMENT_BATCH_UPLOAD_SUCCESS',
  'RETRY_DOCUMENT_BATCH_UPLOAD_FAILURE',
  'UPDATE_DOCUMENT_DATA',
  'UPLOAD_DOCUMENTS_INPROGRESS',
  'UPLOAD_DOCUMENTS_SUCCESS',
  'UPLOAD_DOCUMENTS_FAILURE',
  'FETCH_DOCUMENT_BY_ID_INPROGRESS',
  'FETCH_DOCUMENT_BY_ID_SUCCESS',
  'FETCH_DOCUMENT_BY_ID_FAILURE',
  'DELETE_DOCUMENT_INPROGRESS',
  'DELETE_DOCUMENT_SUCCESS',
  'DELETE_DOCUMENT_FAILURE'
);
