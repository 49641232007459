import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import classes from './ApproveModal.module.scss';
import cs from 'classnames';
import get from 'lodash/get';
import { callPostUpdateStatusDealDeskById } from 'store/actions/dealDeskActions';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Button';

const ApproveModal = forwardRef(({ id, openSuccessModal, type }, ref) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );

  const data = dealDesk?.data;

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
  }));

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(
      callPostUpdateStatusDealDeskById(
        id,
        {
          status: type === 'deals' ? 'approved' : 'ra_sent',
          reason: '',
        },
        type === 'deals' ? 'deal-desk' : 'partner-deal-desk'
      )
    );
    openSuccessModal();
    setLoading(false);
    setOpen(false);
  };

  const renderContent = () => {
    return (
      <>
        <div className={classes.title}>Approve “{data?.client?.name}”?</div>
        <div className={classes.buttons}>
          <button
            type="button"
            className={cs('btn btn-secondary', classes.deny)}
            onClick={() => toggleModal()}
          >
            Cancel
          </button>
          <Button
            loading={loading}
            color="success"
            type="button"
            onClick={() => handleSubmit()}
          >
            Approve and Send
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      size="md"
      centered={true}
      scrollable
      fade={false}
      isOpen={isOpen}
      toggle={toggleModal}
      className={classes.modalApprove}
    >
      <ModalBody>{renderContent()}</ModalBody>
    </Modal>
  );
});

export default ApproveModal;
