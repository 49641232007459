import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import SideBar from 'views/pages/MacroOnBoarding/MacroSideBar';
import SetupForm from 'views/pages/MacroOnBoarding/SetupForm';
import Integrations from './Integrations';
import classes from './MacroOnBoarding.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import Button from 'components/Button';
import Documentation from './Documentation';
import Invitation from './Invitation';
import { findLastIndex } from 'lodash';
import get from 'lodash/get';
import cs from 'classnames';
import { completeOnboarding } from 'store/actions/onboarding';
import { useSelector, useDispatch } from 'react-redux';
import { showProfileUser } from 'store/actions/authActions';

const MacroOnBoarding = () => {
  const param = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const companyId = useSelector(({ auth }) => get(auth, 'user.company.id'));
  const { currentStep } = param;
  const [isNextButtonDisabled, setisNextButtonDisabled] = useState(false);
  const submitForm = useRef();
  const formikRef = useCallback(
    vals => {
      submitForm.current = vals;
      if (vals && isNextButtonDisabled === vals.isValid) {
        setisNextButtonDisabled(!isNextButtonDisabled);
      }
    },
    [isNextButtonDisabled]
  );

  const [sideBarContent, setSideBarContent] = useState([
    {
      label: 'Setup',
      icon: 'fas fa-home',
      description: 'Please confirm this information is correct.',
      active: 'setup',
      isLast: false,
      isCurrent: currentStep === 'setup',
      btnText: 'Save and continue',
      btnAction: async () => {
        submitForm.current.handleSubmit();
        history.push({ pathname: '/admin/onboarding/integration' });
      },
      completed: false,
      Component: SetupForm,
    },
    {
      label: 'Integrations',
      icon: 'fas fa-share-alt',
      description:
        'Connect your accounting and HR/payroll software to expedite document gathering',
      active: 'integration',
      isCurrent: currentStep === 'integration',
      isLast: false,
      btnText: 'Save and continue',
      btnAction: () => {
        return history.push('/admin/onboarding/documentation');
      },
      Component: Integrations,
      completed: false,
    },
    {
      label: 'Documentation',
      icon: 'fas fa-file',
      description:
        'Please review the information to learn about the documents we need to begin your R&D Tax study!',
      active: 'documentation',
      isCurrent: currentStep === 'documentation',
      isLast: false,
      btnText: 'Continue to Invite',
      btnAction: () => {
        return history.push('/admin/onboarding/team');
      },
      Component: Documentation,
      completed: false,
    },
    {
      label: 'Invite Team',
      icon: 'fas fa-users',
      description: `<div>Onboard your team members to begin collaborating together to get your R&D Study started!</div>
<p>   </p>
      <div>Can’t invite yet? Don’t worry! 
      You can do this later.</div>`,
      active: 'team',
      isCurrent: currentStep === 'team',
      isLast: true,
      btnText: 'Finish and Go to Dashboard',
      btnAction: async () => {
        dispatch(completeOnboarding(companyId));
        await dispatch(showProfileUser());
        return history.push('/admin/my-documents');
      },
      Component: Invitation,
      completed: false,
    },
  ]);

  const activeIndex = useMemo(() => {
    const index = findLastIndex(sideBarContent, sidebar => {
      return currentStep === sidebar.active;
    });
    if (index === -1) history.push('/admin/dashboard');
    return index;
  }, [sideBarContent, history, currentStep]);

  const activeElement = useMemo(() => {
    return get(sideBarContent, [activeIndex], {});
  }, [activeIndex, sideBarContent]);

  const Component = useMemo(() => {
    return get(activeElement, 'Component', SetupForm);
  }, [activeElement]);

  useEffect(() => {
    setSideBarContent(sideCont =>
      sideCont.map((s, index) => ({
        ...s,
        isCurrent: currentStep === s.active,
        ...(activeIndex > index
          ? {
              icon: 'fas fa-check',
              completed: true,
              description: 'Progress Saved',
            }
          : {}),
      }))
    );
  }, [activeIndex, currentStep]);

  return (
    <div className={classes.wrapper}>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent p-4"
        expand="lg"
        id="navbar-main"
      >
        <NavbarBrand href="/">
          <img alt="..." src={require('assets/img/brand/strike-logo.png')} />
        </NavbarBrand>
      </Navbar>
      <div
        className={cs('d-flex flex-column flex-lg-row', classes.contentWrapper)}
      >
        <SideBar content={sideBarContent} />
        <div className={cs('d-flex flex-column w-100 px-5')}>
          <Component {...{ formikRef }} />
        </div>
      </div>

      {/* <Button
        className={cs(classes.btn, 'float-right')}
        leftIcon={<i className="fas fa-comment-dots" />}
      >
        Chat with us
      </Button> */}
      <div className={classes.footer}>
        <Button
          color="primary"
          type="submit"
          className={'mr-4 ml-auto'}
          disabled={currentStep !== 'documentation' && isNextButtonDisabled}
          onClick={activeElement.btnAction}
        >
          {activeElement.btnText}
        </Button>
      </div>
    </div>
  );
};

export default MacroOnBoarding;
