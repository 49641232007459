import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import CompanyInfo from './CompanyInfo';
import BasicInformation from './BasicInformation';
import EssentialInformation from './EssentialInformation';
import { useDispatch } from 'react-redux';
import { getCompanyDetails, updateCompanyDetails } from 'store/actions/company';
import { useDebounce } from 'react-use';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
} from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';

const CompanySettings = () => {
  const [newDetails, setNewDetails] = useState({
    name: useGetFieldFromObjects('company', 'details.data.name'),
  });
  const dispatch = useDispatch();
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.company_settings,
      ...rest,
    });
  };
  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_company_settings,
    });
  }, []);
  const isUserAllowedViewCompanySettings = useAccess(
    permissions.VIEW_COMPANY_SETTINGS
  );
  if (!isUserAllowedViewCompanySettings) showUnAuhtorizedError();

  useEffect(() => {
    dispatch(getCompanyDetails());
  }, [dispatch, isUserAllowedViewCompanySettings]);
  useDebounce(() => dispatch(updateCompanyDetails(newDetails)), 1000, [
    newDetails,
  ]);
  const onChange = (field, value) =>
    setNewDetails(currentState => ({ ...currentState, [field]: value }));
  const isAllowedEdit = useAccess([permissions.UPDATE_COMPANY_SETTINGS]);

  return (
    <>
      <Container fluid>
        <h2 className="py-4 m-0">Company Settings</h2>
        <Row>
          <Col lg={6}>
            <CompanyInfo
              isAllowedEdit={isAllowedEdit}
              onChange={onChange}
              analyticsSendEvent={analyticsSendEvent}
            />
            <EssentialInformation
              isAllowedEdit={isAllowedEdit}
              onChange={onChange}
              analyticsSendEvent={analyticsSendEvent}
            />
          </Col>
          <Col lg={6}>
            <BasicInformation
              isAllowedEdit={isAllowedEdit}
              onChange={onChange}
              analyticsSendEvent={analyticsSendEvent}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompanySettings;
