import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

const Helmet = ({ title }) => {
  return (
    <ReactHelmet>
      <meta charSet="utf-8" />
      <title>{title ?? 'StrikeTax Portal'}</title>
      <link rel="canonical" href="https://www.striketax.com/" />
    </ReactHelmet>
  );
};

export default Helmet;
