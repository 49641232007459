import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';

const AllowedAbilites = ({ role }) => {
  return role ? (
    <>
      <h4>{role.title} can:</h4>
      <ul className="fa-ul ml-4">
        {map(get(role, 'abilities.allowed', []), (ability, index) => {
          return (
            <li key={index}>
              <span className="fa-li">
                <i className="fas fa-check text-success"></i>
              </span>
              {ability}
            </li>
          );
        })}
      </ul>
    </>
  ) : (
    <></>
  );
};

export default AllowedAbilites;
