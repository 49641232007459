import React, { useRef, useEffect } from 'react';
import { Form } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import FormModal from 'components/FormFields/FormModal';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';

const AssignmentTypeCustomizationForm = ({
  viewMode,
  closeModal,
  submitValues,
  isModalOpen,
  editValues,
}) => {
  const inputRef = useRef(null);
  let initialValues = {
    name: '',
  };

  if (viewMode === 'edit') {
    initialValues = {
      ...initialValues,
      name: editValues.name,
    };
  }
  const data = useSelector(({ auth }) => {
    const thisData = get(auth, 'user.company.task_types', []);
    return map(thisData, toLower);
  }, isEqual);

  const schema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .test(
        'duplication test',
        'This assignment type already exists.',
        value => {
          return !includes(data, toLower(value));
        }
      ),
  });

  useEffect(() => {
    setTimeout(() => inputRef.current.focus(), 50);
  }, []);

  const renderForm = ({ handleSubmit, isValid, handleReset, values }) => {
    return (
      <FormModal
        size="sm"
        toggle={closeModal}
        isOpen={isModalOpen}
        heading={
          viewMode === 'edit'
            ? 'Rename Assignment Type'
            : 'Create an Assignment Type'
        }
        submit={{
          onClick: handleSubmit,
          isValid: isValid && initialValues.name !== values.name,
          buttonText: `${viewMode === 'edit' ? 'Save' : 'Create Type'} `,
        }}
        cancel={{
          onClick: () => {
            handleReset();
            closeModal();
          },
          buttonText: 'Cancel',
        }}
      >
        <Form role="form" onSubmit={handleSubmit}>
          <FormikInput
            groupClassName="mb-3"
            name="name"
            placeholder="Assignment Type Name *"
            type="text"
            innerRef={inputRef}
          />
        </Form>
      </FormModal>
    );
  };

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(false);
        submitValues(values);
        resetForm();
      }}
    >
      {renderForm}
    </Formik>
  );
};

export default AssignmentTypeCustomizationForm;
