import React from 'react';
import classes from './ProgressBar.module.scss';
import ProgressBarPhase from './ProgressBarPhase';

const ProgressBar = ({
  projectPhase = null,
  phases,
  isFulfillment = false,
}) => {
  const DOC_PROGRESS = phases;
  let currentPhase = DOC_PROGRESS?.find(({ name }) => name === projectPhase);
  if (!currentPhase && DOC_PROGRESS && DOC_PROGRESS.length > 0)
    currentPhase = DOC_PROGRESS[0];
  return (
    <div className={classes.wrapper}>
      {DOC_PROGRESS?.map((phase, index) => {
        return (
          <ProgressBarPhase
            key={index}
            phase={{ ...phase, name: phase.name }}
            currentPhase={currentPhase}
            isFulfillment={isFulfillment}
          />
        );
      })}
    </div>
  );
};

export default ProgressBar;
