import createTypes from 'redux-create-action-types';

export default createTypes(
  'START_TIMER',
  'SET_END_RUNNINGTIME',
  'STOP_TIMER',
  'SET_CURRENT_PROJECT',
  'SET_CURRENT_STORY',
  'SET_CURRENT_TASK',
  'TOGGLE_TIME_FORMAT',
  'RECENT_ENTRIES_LOADING',
  'RECENT_ENTRIES_SUCCESS',
  'RECENT_ENTRIES_ERROR',
  'CURRENT_TIME_LOADING',
  'CURRENT_TIME_SUCCESS',
  'CURRENT_TIME_ERROR',
  'DELETE_ENTRY_LOADING',
  'DELETE_ENTRY_SUCCESS',
  'DELETE_ENTRY_ERROR',
  'GET_ENTRY_LOADING',
  'GET_ENTRY_SUCCESS',
  'GET_ENTRY_ERROR'
);
