import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import classes from './TimerRecentTimeLogs.module.scss';

import noDataIcon from 'assets/img/icons/nodata-timer.svg';
import clockIcon from 'assets/img/icons/clock-timer.svg';
import recentEntryIcon from 'assets/img/icons/recent-entry-timer.svg';
import Dropdowns from 'components/Dropdowns';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import {
  doGetRecentTimeEntries,
  doDeleteTimeEntry,
} from 'store/actions/timerActions';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from 'components/Loading';
import cs from 'classnames';
import AddManualEntryButton from './AddManualEntryButton';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { withTime } from 'helpers/times';

const TimerRecentTimeLogs = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [entries, setEntries] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useImperativeHandle(ref, () => ({
    reload() {
      if (page === 1) dispatch(doGetRecentTimeEntries(page));
      else setPage(1);
    },
  }));

  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.time_entries,
      ...rest,
    });
  };

  const runningTime = useSelector(({ timer }) => get(timer, 'runningTime'));
  const recentEntries = useSelector(({ timer }) =>
    get(timer, 'recentEntries.data')
  );
  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  useEffect(() => {
    const getData = async () => {
      await dispatch(doGetRecentTimeEntries(page));
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    };
    setIsLoading(true);
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    setEntries(get(recentEntries, 'data', []));
    setHasMore(
      get(recentEntries, 'meta.current_page') <
        get(recentEntries, 'meta.last_page')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentEntries]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <h3>Recent Timelogs</h3>
          <AddManualEntryButton
            classes={classes}
            runningTime={runningTime}
            toggle={props.toggle}
          />
        </div>
        <div className={classes.body} id="recent-entries-containter">
          {entries.length === 0 && (
            <div className={classes.noData}>
              <img src={noDataIcon} alt="empty data" />
              <h4>Click on the Start button to create a time entry!</h4>
            </div>
          )}

          {entries.length > 0 && (
            <div className={classes.content}>
              <InfiniteScroll
                pageStart={0}
                initialLoad={false}
                getScrollParent={() => {
                  return document.getElementById('recent-entries-containter');
                }}
                loadMore={() => {
                  if (isLoading === false) {
                    setPage(page + 1);
                  }
                }}
                hasMore={isLoading === false && hasMore}
                useWindow={false}
              >
                {entries.map(item => {
                  return (
                    <div className={classes.item} key={`entry-${item.id}`}>
                      <div className={classes.recentEntry}>
                        <img src={recentEntryIcon} alt="recent entry" />
                      </div>
                      <div className={classes.time}>
                        <div className={classes.fromTo}>
                          {withTime(item.date_start, {
                            tz: timezone,
                            format: 'date-time',
                          })}{' '}
                          -{' '}
                          {withTime(item.date_end, {
                            tz: timezone,
                            format: 'date-time',
                          })}
                        </div>
                        <div className={classes.duration}>
                          <img
                            src={clockIcon}
                            className={classes.clockIcon}
                            alt="clock icon"
                          />{' '}
                          {item.hms}
                          <Dropdowns
                            className=" text-light ml-1"
                            text={<i className="fas fa-ellipsis-v" />}
                            size="sm"
                            caret={false}
                            role="button"
                            color=""
                            options={[
                              {
                                text: 'Edit Time Entry',
                                onClick: async () => {
                                  props.toggle('edit', item.id);
                                },
                              },
                              {
                                text: 'Delete Time Entry',
                                onClick: () => {
                                  AlertPopupHandler.open({
                                    onConfirm: async () => {
                                      analyticsSendEvent({
                                        action:
                                          analyticsConstants.action
                                            .delete_time_entry,
                                        updated_from: 'Recent Time Entries',
                                      });
                                      await dispatch(
                                        doDeleteTimeEntry(item.id)
                                      );
                                      if (page === 1)
                                        dispatch(doGetRecentTimeEntries(page));
                                      else setPage(1);
                                    },
                                    confirmBtnText: 'Delete Time Entry',
                                    text: `You are about to delete this Time Entry. Do you want to continue?`,
                                    data: {},
                                  });
                                },
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className={classes.title}>
                        <h5>
                          {get(item, 'project.name')}
                          {get(item, 'story.name') && ` - ${item.story.name} `}
                          {get(item, 'task.name') && ` - ${item.task.name} `}
                        </h5>
                      </div>
                      <p
                        className={cs(classes.description, {
                          'text-muted': !item.description,
                        })}
                      >
                        {item.description ? (
                          item.description
                        ) : (
                          <em>Empty description</em>
                        )}
                      </p>
                    </div>
                  );
                })}
                {isLoading && <Loading key="loader" size={50} />}
              </InfiniteScroll>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default TimerRecentTimeLogs;
