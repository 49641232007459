import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import get from 'lodash/get';
import { updateActiveTourStatus } from 'store/actions/profile';
import Tours from './Tours';
import useBreakPoint from 'helpers/useBreakPoint';
import { permissions, useAccess } from 'helpers/permission';
import { getCurrentStepForTour } from './TourHelpers';

const allowedRoles = ['client'];
const QuickStartTour = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useBreakPoint('xs', 'down');
  const [isVisible, setVisible] = useState(false);
  const [isClosed, setClosed] = useState(true);
  const canSubmitDocuments = useAccess(permissions.SUBMIT_DOCUMENTS);

  const preferences = useSelector(({ profile }) =>
    get(profile, 'preference.data', [])
  );

  const onBoardingTour =
    preferences.find(p => p.category === 'onboarding_tour') || {};

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const runningTime = useSelector(({ timer }) => get(timer, 'runningTime'));
  const assignedProjects = useSelector(({ auth }) =>
    get(auth, 'user.assigned_initiatives', [])
  );

  useEffect(() => {
    if (!canSubmitDocuments) return;
    const status = get(onBoardingTour, 'value.status', '');
    if (status) {
      if (status === 'Active') {
        setVisible(true);
        setClosed(false);
      } else if (status === 'Collapsed') {
        setVisible(false);
        setClosed(false);
      } else if (status === 'Closed') {
        setVisible(false);
        setClosed(true);
      }
    }
  }, [canSubmitDocuments, onBoardingTour]);

  const checkTourAndResetToInitialStep = location => {
    if (quickStartTour && quickStartTour.activeTour) {
      const isProjectTour =
        (quickStartTour.activeTour === 'project_creation' &&
          !location.pathname.startsWith('/admin/projects')) ||
        (!location.pathname.startsWith('/admin/projects') &&
          (!location.pathname.endsWith('/backlog') ||
            !location.pathname.endsWith('/dashboard')));
      const isStoryCreationTour =
        quickStartTour.activeTour === 'story_creation' &&
        (!location.pathname.startsWith('/admin/projects') ||
          (location.pathname.startsWith('/admin/projects') &&
            (!location.pathname.endsWith('/backlog') ||
              !location.pathname.endsWith('/dashboard')) &&
            location.search &&
            !location.search.startsWith('?story')));
      const isInviteTeamTour =
        quickStartTour.activeTour === 'invite_team' &&
        (!location.pathname.startsWith('/admin/projects') ||
          (location.pathname.startsWith('/admin/projects') &&
            (!location.pathname.endsWith('/team') ||
              !location.pathname.endsWith('/dashboard'))));
      const isClientTour =
        quickStartTour.activeTour === 'client_creation' &&
        location.pathname !== '/admin/accounts';
      const isUserInviteTour =
        quickStartTour.activeTour === 'user_invitation' &&
        location.pathname !== '/admin/users';
      if (
        isProjectTour ||
        isClientTour ||
        isUserInviteTour ||
        isStoryCreationTour ||
        isInviteTeamTour
      ) {
        setTimeout(() => {
          const step = getCurrentStepForTour(quickStartTour.activeTour, {
            assignedProjects,
            runningTime,
          });
          dispatch(
            updateActiveTourStatus({
              ...quickStartTour,
              step: step || 0,
            })
          );
        }, 20);
      }
    }
  };

  /*
    This effect will handle the path change for tours, if user changes the route in between
    of the active tour they will be taken back to the initial step

    Regarding dependency:
    -  We need to update the binding on history change and quickstartTour change
   */
  useEffect(() => {
    const unlisten = history.listen(location => {
      checkTourAndResetToInitialStep(location);
    });
    return unlisten;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, quickStartTour]);

  if (isClosed || isMobile) {
    return null;
  }

  // if role is not admin and not manager then we are hiding quick start
  if (
    onBoardingTour.key &&
    allowedRoles.findIndex(k => k === onBoardingTour.key) === -1
  ) {
    return null;
  }

  return (
    <>
      <Tours />
    </>
  );
};

export default QuickStartTour;
