import React from 'react';
import {
  Badge,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import classes from './Status.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const StoryStatus = ({
  statuses,
  selectedStatus,
  disabled,
  handleChange,
  right,
  children,
  toggleClass,
  wrapperClass,
  labelField = 'status',
  storyDashboard = false,
}) => {
  const status = get(selectedStatus, labelField, 'Backlog');
  const backgroundColor = get(selectedStatus, 'color', '#CED4DA');
  const dispElement = (
    <div className={classNames('d-flex w-100 align-items-center')}>
      <Badge className="badge-dot m-0" color="">
        <i
          style={{
            // TODO: this will be used from API so do not worry about static color
            backgroundColor,
          }}
        />
      </Badge>
      <h5
        className={classNames(
          'font-weight-normal',
          'mb-0',
          !storyDashboard ? 'text-muted' : classes.statusText
        )}
      >
        {status}
      </h5>
    </div>
  );
  return (
    <UncontrolledDropdown
      className={classNames(classes.dropdown, wrapperClass)}
      disabled={disabled}
      direction="down"
    >
      <DropdownToggle
        className={classNames(
          classes.toggle,
          disabled && classes.disablePointer,
          toggleClass
        )}
      >
        {children ? children : dispElement}
      </DropdownToggle>
      <DropdownMenu right={right}>
        {orderBy(statuses, ['id'], 'asc').map((item, index) => (
          <DropdownItem
            key={index}
            onClick={() => handleChange(item)}
            className="d-flex w-100 align-items-center"
          >
            <Badge className="badge-dot m-0" color="">
              <i
                style={{
                  // TODO: this will be used from API so do not worry about static color
                  backgroundColor: item.color,
                }}
              />
            </Badge>
            <h5
              className={classNames(
                'font-weight-normal',
                'mb-0',
                !storyDashboard ? 'text-muted' : classes.statusText
              )}
            >
              {item[labelField]}
            </h5>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

StoryStatus.propTypes = {
  selectedStatus: PropTypes.shape({
    status: PropTypes.string,
    color: PropTypes.string,
  }),
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  right: PropTypes.bool,
};

StoryStatus.defaultProps = {
  selectedStatus: {},
  disabled: false,
  handleChange: () => {},
  right: false,
};

export default StoryStatus;
