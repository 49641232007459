import React from 'react';
import cx from 'classnames';
import classes from './PartnerTracker.module.scss';

const NoDataPlaceholder = ({ showEmptyStateHeader = true }) => {
  return (
    <div
      className={cx(
        'd-flex align-items-center justify-content-center',
        classes.noDataWrapper
      )}
    >
      <div
        className={cx(
          'd-flex justify-content-between align-items-center flex-column w-100'
        )}
      >
        {showEmptyStateHeader && (
          <div className={classes.emptyStateHeader}></div>
        )}
        <div className={classes.noData}>
          <div className={classes.iconWrapper}>
            <i className="fa fa-address-card" aria-hidden="true"></i>
          </div>
        </div>
        <p className={cx(classes.defaultText)}>None at this time.</p>
      </div>
    </div>
  );
};

export default NoDataPlaceholder;
