import get from 'lodash/get';

export const analyticsConstants = {
  category: {
    authentication: 'authentication',
    user_dashboard: 'dashboard',
    personal_kanban: 'personal_kanban',
    global_kanban: 'global_kanban',
    notifications: 'notifications',
    time_entries: 'time_entries',
    projects: 'projects',
    project_dashboard: 'project_dashboard',
    backlog: 'backlog',
    epics: 'epics',
    stories: 'stories',
    story_comments: 'story_comments',
    assignments: 'assignments',
    project_boards: 'project_boards',
    project_teams: 'project_teams',
    clients: 'clients',
    time_log_reporting: 'time_log_reporting',
    knowledge_base: 'knowledge_base',
    user_management: 'user_management',
    user_profile: 'user_profile',
    company_settings: 'company_settings',
    footer_links: 'footer_links',
    customizations: 'customizations',
    onboarding: 'onboarding',
  },
  action: {
    user_sign_in: 'user_sign_in',
    user_registration_user_sign_up: 'user_registration_user_sign_up',
    user_registration_set_email: 'user_registration_set_email',
    user_registration_set_password: 'user_registration_set_password',
    user_registration_set_company_info: 'user_registration_set_company_info',
    user_registration_invite_team: 'user_registration_invite_team',
    user_log_out: 'user_log_out',
    user_unlock: 'user_unlock',
    user_timeout: 'user_timeout',
    view_personal_dashboard: 'view_personal_dashboard',
    view_personal_kanban: 'view_personal_kanban',
    view_global_kanban: 'view_global_kanban',
    search_personal_kanban: 'search_personal_kanban',
    search_global_kanban: 'search_global_kanban',
    expand_personal_kanban: 'expand_personal_kanban',
    expand_global_kanban: 'expand_global_kanban',
    filter_done_personal_kanban: 'filter_done_personal_kanban',
    filter_done_global_kanban: 'filter_done_global_kanban',
    drag_assignment_personal_kanban: 'drag_assignment_personal_kanban',
    drag_incomplete_assignment_personal_kanban:
      'drag_incomplete_assignment_personal_kanban',
    drag_locked_assignment_personal_kanban:
      'drag_locked_assignment_personal_kanban',
    view_notifications: 'view_notifications',
    mark_notification_read: 'mark_notification_read',
    mark_all_notifications_read: 'mark_all_notifications_read',
    open_notification: 'open_notification',
    start_timer: 'start_timer',
    stop_timer: 'stop_timer',
    add_manual_time_entry: 'add_manual_time_entry',
    view_recent_time_entries: 'view_recent_time_entries',
    edit_time_entry: 'edit_time_entry',
    delete_time_entry: 'delete_time_entry',
    view_projects_list: 'view_projects_list',
    search_projects_list: 'search_projects_list',
    create_project: 'create_project',
    edit_project: 'edit_project',
    delete_project: 'delete_project',
    view_project_dashboard: 'view_project_dashboard',
    edit_project_overview: 'edit_project_overview',
    edit_project_owner: 'edit_project_owner',
    edit_project_status: 'edit_project_status',
    edit_project_client: 'edit_project_client',
    edit_project_sow: 'edit_project_sow',
    edit_project_budget_$: 'edit_project_budget_$',
    edit_project_budget_hours: 'edit_project_budget_hours',
    edit_project_budget_type: 'edit_project_budget_type',
    edit_project_isbillable: 'edit_project_isbillable',
    edit_project_start_date: 'edit_project_start_date',
    edit_project_end_date: 'edit_project_end_date',
    edit_project_color: 'edit_project_color',
    view_project_backlog: 'view_project_backlog',
    search_project_backlog: 'search_project_backlog',
    add_backlog_section: 'add_backlog_section',
    drag_backlog_section: 'drag_backlog_section',
    rename_backlog_section: 'rename_backlog_section',
    delete_backlog_section: 'delete_backlog_section',
    drag_backlog_story: 'drag_backlog_story',
    move_story_to_top: 'move_story_to_top',
    move_story_to_bottom: 'move_story_to_bottom',
    multiselect_move_section: 'multiselect_move_section',
    multiselect_assign_epic: 'multiselect_assign_epic',
    multiselect_update_status: 'multiselect_update_status',
    multiselect_delete: 'multiselect_delete',
    create_epic: 'create_epic',
    rename_epic: 'rename_epic',
    update_epic_color: 'update_epic_color',
    delete_epic: 'delete_epic',
    drag_epic: 'drag_epic',
    move_epic_to_top: 'move_epic_to_top',
    move_epic_to_bottom: 'move_epic_to_bottom',
    create_story: 'create_story',
    open_story: 'open_story',
    update_story_name: 'update_story_name',
    update_epic: 'update_epic',
    update_description: 'update_description',
    add_attachment: 'add_attachment',
    download_attachment: 'download_attachment',
    delete_attachment: 'delete_attachment',
    update_story_owner: 'update_story_owner',
    update_story_priority: 'update_story_priority',
    update_story_status: 'update_story_status',
    view_story_activity: 'view_story_activity',
    view_story_info: 'view_story_info',
    view_story_comments: 'view_story_comments',
    delete_story: 'delete_story',
    comment_on_story: 'comment_on_story',
    delete_comment_on_story: 'delete_comment_on_story',
    edit_comment_on_story: 'edit_comment_on_story',
    add_assignment: 'add_assignment',
    update_assignment_type: 'update_assignment_type',
    update_assignment_owner: 'update_assignment_owner',
    update_assignment_due_date: 'update_assignment_due_date',
    add_assignment_checklist_item: 'add_assignment_checklist_item',
    remove_assignment_checklist_item: 'remove_assignment_checklist_item',
    drag_assignment_checklist_item: 'drag_assignment_checklist_item',
    update_assignment_budget_hours: 'update_assignment_budget_hours',
    delete_assignment: 'delete_assignment',
    duplicate_assignment: 'duplicate_assignment',
    add_assignment_dependency: 'add_assignment_dependency',
    drag_assignment: 'drag_assignment',
    complete_assignment: 'complete_assignment',
    mark_assignment_checklist_item: 'mark_assignment_checklist_item',
    unmark_assignment_checklist_item: 'unmark_assignment_checklist_item',
    restore_completed_assignment: 'restore_completed_assignment',
    view_project_board: 'view_project_board',
    search_project_board: 'search_project_board',
    expand_project_board: 'expand_project_board',
    expand_all_stories_on_board: 'expand_all_stories_on_board',
    expand_single_story_on_board: 'expand_single_story_on_board',
    drag_card_on_board: 'drag_card_on_board',
    view_project_team: 'view_project_team',
    search_project_team: 'search_project_team',
    add_project_team_member: 'add_project_team_member',
    update_project_member_rate: 'update_project_member_rate',
    remove_project_member: 'remove_project_member',
    view_clients_list: 'view_clients_list',
    add_client: 'add_client',
    search_clients_list: 'search_clients_list',
    delete_client: 'delete_client',
    edit_client: 'edit_client',
    view_client_profile: 'view_client_profile',
    add_agreement: 'add_agreement',
    open_agreement: 'open_agreement',
    delete_agreement: 'delete_agreement',
    view_timelog_report: 'view_timelog_report',
    filter_timelog_report: 'filter_timelog_report',
    group_timelog_report: 'group_timelog_report',
    subgroup_timelog_report: 'subgroup_timelog_report',
    view_timelog_report_details: 'view_timelog_report_details',
    discount_time_entry: 'discount_time_entry',
    approve_time_entry: 'approve_time_entry',
    export_timelog_report: 'export_timelog_report',
    create_kb_article: 'create_kb_article',
    publish_kb_article: 'publish_kb_article',
    unpublish_kb_article: 'unpublish_kb_article',
    view_my_publications: 'view_my_publications',
    view_all_publications: 'view_all_publications',
    view_my_drafts: 'view_my_drafts',
    create_kb_category: 'create_kb_category',
    drag_kb_category: 'drag_kb_category',
    delete_kb_category: 'delete_kb_category',
    rename_kb_category: 'rename_kb_category',
    view_kb_article: 'view_kb_article',
    like_kb_article: 'like_kb_article',
    dislike_kb_article: 'dislike_kb_article',
    delete_kb_article: 'delete_kb_article',
    edit_kb_article: 'edit_kb_article',
    view_user_management: 'view_user_management',
    search_users: 'search_users',
    invite_users: 'invite_users',
    update_user_role: 'update_user_role',
    resend_user_invite: 'resend_user_invite',
    cancel_user_invite: 'cancel_user_invite',
    deactivate_user: 'deactivate_user',
    view_user_profile: 'view_user_profile',
    edit_user_details: 'edit_user_details',
    edit_user_avatar: 'edit_user_avatar',
    view_company_settings: 'view_company_settings',
    update_company_name: 'update_company_name',
    update_company_industry: 'update_company_industry',
    update_company_size: 'update_company_size',
    update_company_type: 'update_company_type',
    update_company_color: 'update_company_color',
    update_company_animal: 'update_company_animal',
    update_company_address: 'update_company_address',
    update_company_contact_info: 'update_company_contact_info',
    update_company_website: 'update_company_website',
    view_about_us: 'view_about_us',
    view_blog: 'view_blog',
    view_terms: 'view_terms',
    view_privacy_policy: 'view_privacy_policy',
    view_assignment_types: 'view_assignment_types',
    edit_assignment_type: 'edit_assignment_type',
    create_assignment_type: 'create_assignment_type',
    delete_assignment_type: 'delete_assignment_type',
    deactivate_assignment_type: 'deactivate_assignment_type',
    activate_assignment_type: 'activate_assignment_type',
    manage_types_from_story: 'manage_types_from_story',
    show_onboarding_drawer: 'show_onboarding_drawer',
    hide_onboarding_drawer: 'hide_onboarding_drawer',
    close_onboarding_drawer: 'close_onboarding_drawer',
    open_onboarding_drawer: 'open_onboarding_drawer',
    select_invite_your_team_onboarding_flow:
      'select_invite_your_team_onboarding_flow',
    select_create_a_project_onboarding_flow:
      'select_create_a_project_onboarding_flow',
    select_create_a_client_onboarding_flow:
      'select_create_a_client_onboarding_flow',
    select_make_a_time_entry_onboarding_flow:
      'select_make_a_time_entry_onboarding_flow',
    select_create_a_story_onboarding_flow:
      'select_create_a_story_onboarding_flow',
    select_invite_project_team_onboarding_flow:
      'select_invite_project_team_onboarding_flow',
    complete_invite_your_team_onboarding_flow:
      'complete_invite_your_team_onboarding_flow',
    complete_create_a_project_onboarding_flow:
      'complete_create_a_project_onboarding_flow',
    complete_create_a_client_onboarding_flow:
      'complete_create_a_client_onboarding_flow',
    complete_make_a_time_entry_onboarding_flow:
      'complete_make_a_time_entry_onboarding_flow',
    complete_create_a_story_onboarding_flow:
      'complete_create_a_story_onboarding_flow',
    complete_invite_project_team_onboarding_flow:
      'complete_invite_project_team_onboarding_flow',
    complete_all_onboarding_flows: 'complete_all_onboarding_flows',
    select_view_projects_onboarding_flow:
      'select_view_projects_onboarding_flow',
    select_view_personal_kanban_onboarding_flow:
      'select_view_personal_kanban_onboarding_flow',
    complete_view_projects_onboarding_flow:
      'complete_view_projects_onboarding_flow',
    complete_view_personal_kanban_onboarding_flow:
      'complete_view_personal_kanban_onboarding_flow:',
  },
  label: {
    view_story: 'view_story',
    view_project: 'view_project',
    view_timelogs: 'view_timelogs',
    view_kanban: 'view_kanban',
    edit_story_priority: 'edit_story_priority',
    edit_story_status: 'edit_story_status',
    recently_viewed_stories: 'recently_viewed_stories',
    view_timelogged_graph: 'view_timelogged_graph',
    kanban_done_time_period_switch: 'kanban_done_section_time_period_switch',
    move_story_assignment: 'move_story_assignment',
    move_locked_story_assignment: 'move_locked_story_assignment',
    move_incomplete_story_assignment: 'move_incomplete_story_assignment',
    kanban_search: 'kanban_search',
    kanban_expand: 'kanban_expand',
  },
};

class Analytics {
  setUserData = user => {
    const userData = {
      // name: user.name, // "We need to remove PII (personally identfiable information)"
      user_id: user.id,
      company_id: get(user, 'company.id'),
      company_guid: get(user, 'company.guid'),
      company_industry: get(user, 'company.industry.industry'),
      company_size: get(user, 'company.size.size'),
      company_name: get(user, 'company.name'),
      skills: user.skills,
      roles: user.roles,
      timezone: user.timezone,
      country: user.country,
      city: user.city,
      job_title: user.title,
    };
    if (process.env.REACT_APP_ENV === 'production') {
      window.gtag('set', {
        user_properties: userData,
      });
    } else {
      /* eslint-disable */
      console.log('Analytic Event: User Properties', userData);
      /* eslint-enable */
    }
  };

  sendEvent = ({ category, action, label, value, ...rest }) => {
    if (process.env.REACT_APP_ENV === 'production') {
      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
        ...rest,
      });
    } else {
      /* eslint-disable */
      console.log('Analytic Event', {
        action,
        event_category: category,
        event_label: label,
        value,
        ...rest,
      });
      /* eslint-enable */
    }
  };
}

export default new Analytics();
