import React, { useEffect } from 'react';
import { getGlobalKanbanData } from 'store/actions/kanbanActions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDetailsByUserId } from 'store/actions/profile';
import Kanban from 'views/pages/Kanban/Kanban';
import get from 'lodash/get';

const UserKanban = props => {
  const dispatch = useDispatch();
  const userId = props.match.params.id;

  useEffect(() => {
    dispatch(fetchDetailsByUserId(userId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const data = useSelector(({ kanban }) =>
    get(kanban, 'globalKanban.data', {})
  );
  const fetchKanbanData = async params => {
    await dispatch(getGlobalKanbanData({ ...params, is_personal: 1 }));
  };

  return (
    <Kanban
      title="Personal Kanban"
      fetchKanbanData={fetchKanbanData}
      data={data}
    />
  );
};

export default UserKanban;
