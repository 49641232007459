import React, { useState } from 'react';

import classes from './DataArea.module.scss';
import { Container } from 'reactstrap';
import cs from 'classnames';

import Button from 'components/Button';
import SummaryTab from './tabs/SummaryTab';
import DetailsTab from './tabs/DetailsTab';
import { analyticsConstants } from 'helpers/analytics';

const DataArea = ({ formik, analyticsSendEvent }) => {
  const [activeTab, setTab] = useState('summary');

  return (
    <div className={classes.root}>
      <Container fluid>
        <div className={classes.menu}>
          <Button
            color={activeTab === 'summary' ? 'primary' : 'secondary'}
            onClick={() => setTab('summary')}
          >
            Summary
          </Button>
          <Button
            color={activeTab === 'detail' ? 'primary' : 'secondary'}
            onClick={() => {
              activeTab !== 'detail' &&
                analyticsSendEvent({
                  action: analyticsConstants.action.view_timelog_report_details,
                });
              setTab('detail');
            }}
          >
            Details
          </Button>
        </div>
      </Container>

      <Container fluid>
        <div className={cs({ [classes.hide]: activeTab !== 'summary' })}>
          <SummaryTab
            formik={formik}
            setTab={setTab}
            analyticsSendEvent={analyticsSendEvent}
          />
        </div>
        <div className={cs({ [classes.hide]: activeTab !== 'detail' })}>
          <DetailsTab formik={formik} analyticsSendEvent={analyticsSendEvent} />
        </div>
      </Container>
    </div>
  );
};

export default DataArea;
