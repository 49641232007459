import Modal from 'components/FormFields/Modal';
import React, { useState } from 'react';
import get from 'lodash/get';
import { Button, Col, Collapse, Container, ModalBody, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from 'components/FormFields/Input';
import { components } from 'react-select';
import RSelect from 'components/FormFields/RSelect';
import classes from './InviteTeamMember.module.scss';
import cs from 'classnames';
import BlockedAbilities from './BlockedAbilities';
import AllowedAbilites from './AllowedAbilites';
import { inviteUsersInOnboarding } from 'store/actions/onboarding';
import { useDispatch } from 'react-redux';

const InviteTeamMemberForm = ({
  currentRole,
  roles,
  closeModal,
  onSubmit,
  user,
  company,
}) => {
  const dispatch = useDispatch();
  const [showUserPermission, setShowUserPermission] = useState(false);
  const inviteUserSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Must be valid email addresses'),
    firstName: Yup.string().required('First Name Required'),
    lastName: Yup.string().required('Last Name Required'),
    role: Yup.mixed().required(),
    note: Yup.string().max(250),
  });
  const OptionComponent = ({
    innerProps = {},
    additionalClassName = '',
    data,
  }) => {
    return (
      <div
        {...innerProps}
        className={cs(
          additionalClassName,
          'd-flex align-items-center',
          classes.option,
          classes[data.styleClass]
        )}
      >
        <div
          className={cs(
            'mr-3 p-3 d-flex align-items-center justify-content-center',
            classes.icon
          )}
        >
          {data.Icon}
        </div>
        <div>
          <h4 className={cs('h4', classes.title)}>{data.title}</h4>
          <p className="text-xs font-weight-400 m-0">{data.description}</p>
        </div>
      </div>
    );
  };
  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={inviteUserSchema}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        role: currentRole,
        note: `Hello! ${get(user, 'name', '')} has invited you to join ${get(
          company,
          'name',
          ''
        )}. Please accept the invitation to get started!`,
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
        resetForm();
        closeModal();
      }}
    >
      {({ isSubmitting, isValid, handleReset, values, setFieldValue }) => {
        return (
          <Modal
            scrollable
            title={`Invite ${get(currentRole, 'title', '')}`}
            isOpen={!!currentRole}
            toggle={() => {
              closeModal();
              handleReset();
            }}
          >
            <ModalBody>
              <Form>
                <Container fluid className="p-0">
                  <Row className="flex-nowrap">
                    <Col>
                      <h3 className="h3">Enter User Information</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormikInput name="firstName" placeholder="First Name" />
                    </Col>
                    <Col md={6}>
                      <FormikInput name="lastName" placeholder="Last Name" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormikInput
                        name="email"
                        placeholder="Email"
                        alternative={false}
                        leftIcon={<i className="ni ni-email-83" />}
                      />
                    </Col>
                  </Row>
                  <Row className="flex-nowrap">
                    <Col>
                      <h3 className="h3">Select User Role</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field name="role">
                        {({ field, form: { touched, errors } }) => {
                          return (
                            <RSelect
                              isSearchable={false}
                              isClearable={false}
                              touched={touched}
                              {...field}
                              options={roles}
                              getOptionLabel={option => (
                                <OptionComponent
                                  additionalClassName={cs(
                                    classes.textWrap,
                                    'h-100'
                                  )}
                                  data={option}
                                />
                              )}
                              onChange={selectedOption =>
                                setFieldValue('role', selectedOption)
                              }
                              error={get(errors, 'role')}
                              components={{
                                Control: ({ children, ...props }) => {
                                  return (
                                    <components.Control
                                      {...props}
                                      className={classes.control}
                                    >
                                      {children}
                                    </components.Control>
                                  );
                                },
                                SingleValue: ({ children, ...props }) => {
                                  return (
                                    <components.SingleValue
                                      {...props}
                                      className={classes.singleValue}
                                    >
                                      {children}
                                    </components.SingleValue>
                                  );
                                },
                                ValueContainer: ({ children, ...props }) => {
                                  return (
                                    <components.ValueContainer
                                      {...props}
                                      className={classes.valueContainer}
                                    >
                                      {children}
                                    </components.ValueContainer>
                                  );
                                },
                                Option: ({
                                  innerProps,
                                  data,
                                  getStyles,
                                  isDisabled,
                                  isFocused,
                                  isSelected,
                                  cx,
                                  ...allProps
                                }) => {
                                  return (
                                    <OptionComponent
                                      innerProps={innerProps}
                                      additionalClassName={cx({
                                        ...getStyles('option', {
                                          ...allProps,
                                          innerProps,
                                          data,
                                        }),
                                        option: true,
                                        'option--is-disabled': isDisabled,
                                        'option--is-focused': isFocused,
                                        'option--is-selected': isSelected,
                                      })}
                                      data={data}
                                    />
                                  );
                                },
                              }}
                            />
                          );
                        }}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-auto" xs="auto">
                      <Button
                        id="toggler"
                        color="link"
                        className="p-0 font-weight-normal user-permissions"
                        onClick={() => {
                          setShowUserPermission(
                            currentPermission => !currentPermission
                          );
                        }}
                      >
                        <i
                          className={cs(
                            'fas ml-1 text-xs',
                            `fa-caret-${showUserPermission ? 'up' : 'down'}`
                          )}
                        />{' '}
                        {showUserPermission ? 'Close' : 'View'} User Permissions
                      </Button>
                    </Col>
                  </Row>
                  <Collapse isOpen={showUserPermission}>
                    <Row
                      className={cs(classes.permissionListContainer)}
                      noGutters
                    >
                      <Col
                        className={cs(
                          'px-3 py-4 text-sm',
                          classes[get(values, 'role.styleClass')]
                        )}
                      >
                        <AllowedAbilites role={values.role} />
                        <BlockedAbilities role={values.role} />
                      </Col>
                    </Row>
                  </Collapse>
                  <Row>
                    <Col className="position-relative">
                      <FormikInput
                        name="note"
                        label="Add a personal note to the invitation email (optional)"
                        labelClassName={cs('mt-3', classes.noteFieldLabel)}
                        type="textarea"
                        className={classes.noteField}
                        rows={4}
                      />
                      <div
                        className={cs(
                          'text-xs text-light position-absolute',
                          classes.descriptionCharacterCount
                        )}
                      >
                        {get(values, 'note.length', 0)} / 250
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="auto ml-auto">
                      <Button
                        color="primary"
                        type="submit"
                        disabled={!isValid}
                        loading={isSubmitting}
                      >
                        Send Invite
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            </ModalBody>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default InviteTeamMemberForm;
