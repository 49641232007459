import { useEffect } from 'react';
import get from 'lodash/get';
import { useSelector, useDispatch } from 'react-redux';
import { doLogout, showLockScreen } from 'store/actions/authActions';
import { store } from 'store';

let timeOutInactivity = null;
let timeOutLogout = null;
export const lastActivityKey = 'lastActivity';
export const expirationKey = 'expiresAt';

const useInactivity = () => {
  const dispatch = useDispatch();
  const isLoggedIn = !!useSelector(state =>
    get(state, 'auth.user.auth_token', null)
  );
  const timeToLogout =
    1000 * 60 * parseInt(get(process.env, 'REACT_APP_LOGOUT_TIME', 60));
  const timeForLock =
    1000 * 60 * parseInt(get(process.env, 'REACT_APP_LOCKOUT_TIME', 60));

  // setting logout timeout to trigger logout after logout time
  const setupLogoutTimeout = () => {
    timeOutLogout = setTimeout(() => {
      // dispatching logout event
      dispatch(doLogout());
    }, timeToLogout);
    // setting logout flag to true so that we can determine the timeout is set
  };

  // setting lockscreen timeout to trigger lockscreen after lockscreen time
  const setupInactivityTimeout = () => {
    timeOutInactivity = setTimeout(() => {
      // showing lockscreen once time out is over
      dispatch(showLockScreen());

      // once lockscreen is shown we are starting timeout for the logout
      setupLogoutTimeout();
    }, timeForLock);
  };

  // used to clear timeouts on activity made from user
  const clearInactivityTimeout = () => {
    // clearing both timeouts
    clearTimeout(timeOutInactivity);
    clearTimeout(timeOutLogout);
  };

  // used to reset timeouts on activity made from user
  const resetInactivityTimeout = () => {
    // clearing timeouts first
    clearInactivityTimeout();

    const currentTime = new Date().getTime();
    // setting the current time in localstorage so we can check when last activity was made
    localStorage.setItem(lastActivityKey, currentTime);

    const state = store.getState();
    const isCurrentLoggedIn = !!get(state, 'auth.user.auth_token', null);
    // checking if user is logged in then setting up the inactivity timer again
    if (isCurrentLoggedIn) {
      setupInactivityTimeout();
    }
  };

  useEffect(() => {
    // adding all the event listeners for document so users events are monitored
    // so we can check for inactivity of user from there and lock them or logout them

    document.addEventListener('mousemove', () => {
      resetInactivityTimeout();
    });
    document.addEventListener('click', () => {
      resetInactivityTimeout();
    });
    document.addEventListener(
      'scroll',
      () => {
        resetInactivityTimeout();
      },
      true
    );
    document.addEventListener('resize', () => {
      resetInactivityTimeout();
    });
    document.addEventListener('keydown', () => {
      resetInactivityTimeout();
    });

    // this will monitor the token across the tabs
    window.onstorage = function(e) {
      // whenever user activity key is updated the item will be updated across the tabs
      if (e.key === lastActivityKey) {
        const sharedInactivityTime = localStorage.getItem(lastActivityKey);
        const isCurrentLoggedIn = !!get(
          store.getState(),
          'auth.user.auth_token',
          null
        );
        if (sharedInactivityTime && isCurrentLoggedIn) {
          // if we get the new value for the key then we are clearing timers and setting them again
          clearInactivityTimeout();
          setupInactivityTimeout();
        } else if (timeOutInactivity) {
          clearInactivityTimeout();
        }
      }
    };
    return () => {
      document.removeEventListener('mousemove', () => {});
      document.removeEventListener('click', () => {});
      document.removeEventListener('scroll', () => {});
      document.removeEventListener('resize', () => {});
      document.removeEventListener('keydown', () => {});
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    // initially when timer is not set then we are setting user time
    if (!timeOutInactivity && isLoggedIn) {
      setupInactivityTimeout();
    }
    return () => {
      clearInactivityTimeout();
    };
    // eslint-disable-next-line
  }, [isLoggedIn]);
};

export default useInactivity;
