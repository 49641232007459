import React, { useState } from 'react';
import classes from './SetupForm.module.scss';
import { Card, CardBody, Row, UncontrolledTooltip } from 'reactstrap';
import FormikInput from 'components/FormFields/Input/FormikInput';
import RSelect from 'components/FormFields/RSelect';
import cs from 'classnames';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import { addClientOnboardingSetup } from 'store/actions/onboarding';
import CheckBox from 'components/FormFields/CheckBox';

const SetupForm = ({ formikRef }) => {
  const setupData = useSelector(({ auth }) => get(auth, 'user', {}));
  const initialValues = {
    user_role: setupData?.title,
    name: setupData?.company?.name || '',
    first_year_of_revenues: setupData?.company?.first_year_of_revenues || '',
    first_year_qres_gr: setupData?.company?.first_year_qres_gr || '',
    founding_year: setupData?.company?.founding_year || '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(' '),
    user_role: Yup.string()
      .trim()
      .required(' '),
    first_year_of_revenues: Yup.string()
      .trim()
      .required(' '),
    first_year_qres_gr: Yup.string().required(' '),
    founding_year: Yup.string().required(' '),
  });
  const dropdownValues = [
    {
      label: 'CEO/Owner',
      value: 'CEO/Owner',
    },
    {
      label: 'CFO',
      value: 'CFO',
    },
    {
      label: 'Co-Founder',
      value: 'Co-Founder',
    },
    {
      label: 'COO',
      value: 'COO',
    },
    {
      label: 'CPA',
      value: 'CPA',
    },
    {
      label: 'Director',
      value: 'Director',
    },
    {
      label: 'President',
      value: 'President',
    },
    {
      label: 'Other',
      value: 'Other',
    },
  ];
  const [noFoundingYear, setNoFoundingYear] = useState(false);
  const [noFirstYear, setNoFirstYear] = useState(false);
  const dispatch = useDispatch();
  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      onSubmit={values => {
        dispatch(
          addClientOnboardingSetup(setupData.company.id, {
            ...values,
            onboarding: true,
          })
        );
      }}
      validationSchema={validationSchema}
      innerRef={formikRef}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <Card className={classes.cardContainer}>
              <CardBody className={classes.formWrapper}>
                <Row noGutters>
                  <div className={classes.number}>1</div>
                  <div className={classes.question}>
                    What is your role at the company?
                  </div>
                  <span className={classes.star}>*</span>
                </Row>
                <Row noGutters className={classes.inputField}>
                  <RSelect
                    placeholder="Select your role"
                    name="user_role"
                    id="user_role"
                    value={
                      values.user_role
                        ? { label: values.user_role, value: values.user_role }
                        : undefined
                    }
                    getOptionLabel={options => options.label}
                    getOptionValue={options => options.value}
                    options={dropdownValues}
                    onChange={option => {
                      setFieldValue('user_role', option ? option.value : '');
                    }}
                  />
                </Row>
                <Row noGutters>
                  <div className={classes.number}>2</div>
                  <div className={classes.question}>
                    What is the company’s legal name?
                  </div>
                  <span className={classes.star}>*</span>
                </Row>
                <Row noGutters className={classes.inputField}>
                  <FormikInput
                    placeholder="Enter company name"
                    className={classes.input}
                    name="name"
                  />
                </Row>
                <Row noGutters>
                  <div className={classes.number}>3</div>
                  <div className={classes.question}>
                    What year was the company incorporated?
                  </div>
                  <span className={classes.star}>*</span>
                </Row>
                <Row noGutters className={classes.inputField}>
                  <FormikInput
                    placeholder="YYYY"
                    groupClassName={classes.date}
                    name="founding_year"
                    disabled={noFoundingYear}
                  />
                  <CheckBox
                    label="I don’t know"
                    id="founding_year"
                    className="ml-3"
                    onChange={() => {
                      if (noFoundingYear) {
                        setFieldValue(
                          'founding_year',
                          values.founding_year.trim() ||
                            setupData?.company?.founding_year
                        );
                      } else {
                        setFieldValue('founding_year', ' ');
                      }
                      setNoFoundingYear(!noFoundingYear);
                    }}
                  />
                </Row>
                <Row noGutters>
                  <div className={classes.number}>4</div>
                  <div className={classes.question}>
                    What year was the company’s first year of revenues?
                  </div>
                  <span className={classes.star}>*</span>
                </Row>
                <Row noGutters className={classes.inputField}>
                  <FormikInput
                    placeholder="YYYY"
                    groupClassName={classes.date}
                    name="first_year_of_revenues"
                  />
                </Row>
                <Row noGutters>
                  <div className={classes.number}>5</div>
                  <div className={classes.question}>
                    When was your first year of R&D?
                  </div>
                  <span className={classes.star}>*</span>
                  <i
                    className={cs('fas fa-info-circle', classes.info)}
                    id="firstYearOfRND"
                  />
                  <UncontrolledTooltip
                    placement="right"
                    target="firstYearOfRND"
                  >
                    When was the first year the company had expenses (wages,
                    contractors, supplies, or materials) related to product,
                    process, software development, or any technical activities
                    involving the sciences or engineering?
                  </UncontrolledTooltip>
                </Row>
                <Row noGutters className={classes.inputField}>
                  <FormikInput
                    placeholder="YYYY"
                    groupClassName={classes.date}
                    name="first_year_qres_gr"
                    disabled={noFirstYear}
                  />
                  <CheckBox
                    label="I don’t know"
                    className="ml-3"
                    id="first_year_qres_gr"
                    onChange={() => {
                      if (noFirstYear) {
                        setFieldValue(
                          'first_year_qres_gr',
                          values.first_year_qres_gr.trim() ||
                            setupData?.company?.first_year_qres_gr
                        );
                      } else {
                        setFieldValue('first_year_qres_gr', ' ');
                      }
                      setNoFirstYear(!noFirstYear);
                    }}
                  />
                </Row>
              </CardBody>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SetupForm;
