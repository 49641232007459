import Request from './request';

export const getCategories = async params => {
  return Request.call({
    url: '/kb/categories',
    method: 'GET',
    params,
  });
};

export const createCategory = async name => {
  return Request.call({
    url: `/kb/categories`,
    method: 'POST',
    data: {
      category: name,
    },
  });
};

export const organizeCategories = async data => {
  return Request.call({
    url: `/kb/categories/organize`,
    method: 'POST',
    data,
  });
};

export const delCategoryById = async category => {
  return Request.call({
    url: `/kb/categories/${category}`,
    method: 'DELETE',
  });
};

export const getArticles = async (
  categoryId,
  status = null,
  myArticles = null,
  page = 1
) => {
  return Request.call({
    url: `/kb/categories/${categoryId}/articles`,
    method: 'GET',
    params: { status, myArticles, page },
  });
};

export const postCreateArticle = async (name, category_id, content, status) => {
  return Request.call({
    url: `/kb/articles`,
    method: 'POST',
    data: {
      name,
      category_id,
      content,
      status,
    },
  });
};

export const updateCategory = async (id, name) => {
  return Request.call({
    url: `/kb/categories/${id}`,
    method: 'PUT',
    data: {
      category: name,
    },
  });
};

export const putUpdateArticle = async (
  id,
  name,
  category_id,
  content,
  status
) => {
  return Request.call({
    url: `/kb/articles/${id}`,
    method: 'PUT',
    data: {
      name,
      category_id,
      content,
      status,
    },
  });
};

export const getArticleById = async id => {
  return Request.call({
    url: `/kb/articles/${id}`,
    method: 'GET',
  });
};

export const getArticlesMostRated = async () => {
  return Request.call({
    url: `/kb/articles/most-rated/list`,
    method: 'GET',
  });
};

export const delArticleById = async id => {
  return Request.call({
    url: `/kb/articles/${id}`,
    method: 'DELETE',
  });
};

export const postLikeDisLikeArticleById = async (id, type) => {
  switch (type) {
    case 'dislike':
      return Request.call({
        url: `/kb/articles/${id}/like?like=false`,
        method: 'POST',
      });

    case 'like':
    default:
      return Request.call({
        url: `/kb/articles/${id}/like?like=true`,
        method: 'POST',
      });
  }
};
