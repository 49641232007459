import React, { useEffect } from 'react';
import classes from './TabActivity.module.scss';
import { Col, Row } from 'reactstrap';
import { callGetDealDeskActivitiesById } from 'store/actions/dealDeskActions';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import InfiniteScroll from 'react-infinite-scroller';
import Loading from 'components/Loading';
import orderBy from 'lodash/orderBy';
import NoActivityImg from 'assets/img/theme/No_Activity.png';
import classNames from 'classnames';
import { withTime } from 'helpers/times';

export const TabActivity = ({ id }) => {
  const dispatch = useDispatch();

  const dealDesk = useSelector(({ dealDesk }) => get(dealDesk, 'activities'));

  const userTimeZone = useSelector(({ auth }) => auth.user.timezone);

  const activities = dealDesk?.data || [];
  const isLoading = dealDesk?.loading;
  const activitiesMeta = dealDesk?.meta;

  const hasMore =
    activitiesMeta?.current_page &&
    activitiesMeta?.last_page &&
    activitiesMeta?.current_page < activitiesMeta?.last_page &&
    isLoading === false;

  const loadMoreActivities = meta => {
    dispatch(
      callGetDealDeskActivitiesById(id, {
        page: meta?.current_page + 1,
        limit: 20,
      })
    );
  };

  const NoDataIndication = () => {
    return (
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div className="d-flex justify-content-between align-items-center flex-column">
          <div className={classes.imageWrapper}>
            <img
              className={classNames('m-auto w-100', classes.defaultImage)}
              src={NoActivityImg}
              alt="No Recent Activity"
            />
          </div>
          <h4
            className={classNames(
              'display-4',
              'mb-0',
              'text-center',
              'px-2',
              classes.defaultText
            )}
          >
            No Recent Activities
          </h4>
        </div>
      </div>
    );
  };

  return (
    <InfiniteScroll
      pageStart={0}
      initialLoad={false}
      loadMore={() => loadMoreActivities(activitiesMeta)}
      hasMore={hasMore}
      loader={<Loading key="loader" size={100} />}
      useWindow={true}
    >
      <div className={classes.tabActivity} id="tab-activities">
        {orderBy(activities, ['created_at'], 'desc').map(
          ({ body = [], id = null, avatar = null, created_at = null }, i) => {
            const content = body?.map(b => b?.text)?.join(' ') || '-';
            return (
              <Row key={id}>
                <Col md={12} className={classes.itemContainer}>
                  <div className={classes.avatar}>
                    <img src={avatar} />
                  </div>
                  <div>
                    <div className={classes.label}>
                      {withTime(created_at, {
                        format: 'date-time',
                        tz: userTimeZone,
                      })}
                    </div>
                    <div className={classes.value}>{content}</div>
                  </div>
                </Col>
              </Row>
            );
          }
        )}

        {isLoading === false && activities.length === 0 && NoDataIndication()}
      </div>
    </InfiniteScroll>
  );
};
