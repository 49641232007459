import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const InputHour12 = props => {
  const { name, value, onChange, AMPM } = props;
  const [data, setData] = useState(12);

  useEffect(() => {
    // 24 hours
    let hour = parseInt(value);

    if (hour > 12) hour -= 12;
    else if (hour === 0) hour = 12;

    if (hour < 10) hour = `0${hour}`;
    setData(hour);
  }, [value]);

  const validateTime = e => {
    const re = /^[0-9\b]+$/;

    let value = e.target.value;

    if (value === '00') {
      setData(12);
      return true;
    }

    if (value === '') {
      setData('');
      return true;
    }

    if (re.test(value)) {
      const iValue = parseInt(value);

      if (iValue > 12) value = 12;

      setData(value);
    }
  };

  const handleOnBlur = e => {
    let value = e.target.value;

    if (value === '' || value === '0') {
      value = '12';
      setData(value);
      onChange(value);
      return true;
    }

    let iValue = parseInt(value);

    if (iValue < 10) iValue = `0${iValue}`;

    setData(iValue);

    if (iValue || iValue === '00') {
      let hour = parseInt(iValue);

      if (AMPM === 'PM' && hour < 12) hour += 12;
      else if (AMPM === 'AM' && hour === 12) hour = 0;

      onChange(hour);
    }
  };

  return (
    <input
      autoComplete="off"
      type="text"
      name={name}
      value={data}
      maxLength="2"
      onChange={validateTime}
      onBlur={handleOnBlur}
    />
  );
};

InputHour12.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
InputHour12.defaultProps = {};

export default InputHour12;
