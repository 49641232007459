import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import {
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  CardBody,
} from 'reactstrap';
import CardsHeader from 'components/Headers/CardsHeader.js';
import BaseTable from 'components/Table';
import cx from 'classnames';
import {
  fetchContractsList,
  getContract,
  deleteContract,
  clearContractData,
  createContract,
} from 'store/actions/contract';

import classes from './Contracts.module.scss';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';

const Contracts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState({
    dataField: 'title',
    order: 'asc',
  });

  const userTimezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const contractState = useSelector(({ contract }) => contract.contractState);
  const deleteReducer = useSelector(({ contract }) => contract.deleteContract);
  const editReducer = useSelector(({ contract }) => contract.editContract);

  const contractList = get(contractState, 'data.data', []);
  const contractsMetadata = get(contractState, 'data.meta', {});

  const listLoading = get(contractState, 'isInProgress', false);
  const editLoading = get(editReducer, 'isInProgress', false);
  const deleteLoading = get(deleteReducer, 'isInProgress', false);

  const NoDataIndication = () => {
    return (
      <div
        className={cx(
          'd-flex align-items-center justify-content-center',
          classes.noDataWrapper
        )}
      >
        <div
          className={cx(
            'd-flex justify-content-between align-items-center flex-column w-100'
          )}
        >
          <div className={classes.noData}>
            <div className={classes.iconWrapper}>
              <i className="fa fa-file" aria-hidden="true"></i>
            </div>
          </div>
          <p className={classes.defaultText}>No Contracts Added Yet</p>
        </div>
      </div>
    );
  };

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort ml-2" />;
    else if (order === 'asc') return <i className="fas fa-sort-up ml-2" />;
    else if (order === 'desc') return <i className="fas fa-sort-down ml-2" />;
    return null;
  };

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;

    await dispatch(deleteContract(data.id, { page, limit: 10, sort, q: '' }));
  };

  const deleteAction = row => e => {
    e.preventDefault();
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Contract',
      text: `You are about to delete this Contract. Do you want to continue?`,
      data: row,
    });
  };

  const duplicateAction = row => async e => {
    e.preventDefault();

    const { data } = await dispatch(getContract(row.id));
    const values = {
      title: data.title,
      summary: data.summary,
      content: data.content,
      status: data.status === 'Draft' ? 0 : 1,
    };
    await dispatch(createContract(values, true));
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    await dispatch(fetchContractsList(page, 10, sort, ''));
  };

  const editAction = row => e => {
    e.preventDefault();
    dispatch(getContract(row.id));
    history.push(`/admin/contracts/create`); //TODO: data.id changes
  };

  const handleTableChange = async (
    type,
    { page, sortOrder, sortField, searchText }
  ) => {
    if (type === 'pagination') {
      setPage(page);
    } else if (type === 'sort') {
      setPage(1);
      setSortBy({
        dataField: sortField,
        order: sortOrder,
      });
    } else if (type === 'search') {
      setPage(1);
    }
    const sort = sortOrder === 'desc' ? `-${sortField}` : sortField;
    const limit = 10;
    await dispatch(fetchContractsList(page, limit, sort, searchText));
  };

  return (
    <>
      <div
        className={cx(classes.contracts, {
          [classes.noData]: contractList,
        })}
      >
        <CardsHeader name="Clients" isRoot={true} />
        <div className="px-4">
          <Card>
            <CardBody className="p-0">
              <BaseTable
                keyField="id"
                defaultSorted={[sortBy]}
                noDataIndication={NoDataIndication}
                bootstrap4
                remote
                showHeaderWithNoData
                noSearchFound={NoDataIndication}
                search={true}
                headerButtonText="Create New Contract"
                headerButtonClassName={classes.headerButton}
                headerButtonAction={() => {
                  dispatch(clearContractData());
                  history.push('/admin/contracts/create');
                }}
                bordered={false}
                loading={listLoading || editLoading || deleteLoading}
                paginationOptions={{
                  page: page,
                  totalSize: contractsMetadata.total,
                  sizePerPage: parseInt(contractsMetadata.per_page),
                }}
                data={contractList}
                columns={[
                  {
                    dataField: 'title',
                    text: 'CONTRACT',
                    sort: true,
                    classes: classes.contractName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row, index) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'author.name',
                    text: 'AUTHOR',
                    sort: true,
                    classes: classes.contractName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell ? cell : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'updated_at',
                    text: 'LAST UPDATED',
                    sort: true,
                    classes: classes.contractName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell
                          ? moment(cell)
                              .tz(userTimezone)
                              .fromNow()
                          : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'created_at',
                    text: 'CREATED ON',
                    sort: true,
                    classes: classes.contractName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        {cell
                          ? moment(cell)
                              .tz(userTimezone)
                              .fromNow()
                          : '-'}
                      </div>
                    ),
                  },
                  {
                    dataField: 'status',
                    text: 'STATUS',
                    sort: true,
                    classes: classes.contractName,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <span>{cell}</span>
                        <span>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={e => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem href="#" onClick={editAction(row)}>
                                Edit Contract
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                onClick={duplicateAction(row)}
                              >
                                Duplicate Contract
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                onClick={deleteAction(row)}
                              >
                                Delete Contract
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </span>
                      </div>
                    ),
                  },
                ]}
                onTableChange={handleTableChange}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Contracts;
