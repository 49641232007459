import moment from 'moment';

export const secondsToHHMMSS = secs => {
  let negative = false;
  if (secs < 0) {
    negative = true;
    secs = Math.abs(secs);
  }

  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  const time = [hours, minutes, seconds]
    .map(v => (v < 10 ? '0' + v : v))
    .filter((v, i) => v === '00' || i >= 0)
    .join(':');

  return negative ? `-${time}` : time;
};

export const getFormattedDate = (date, userTimezone) => {
  const NOW = moment().tz(userTimezone);
  const TODAY = NOW.clone().startOf('day');
  const YESTERDAY = NOW.clone()
    .subtract(1, 'days')
    .startOf('day');
  const A_WEEK_OLD = NOW.clone()
    .subtract(7, 'days')
    .startOf('day');

  if (
    moment(date)
      .tz(userTimezone)
      .isSame(TODAY, 'd')
  ) {
    return 'Today';
  } else if (
    moment(date)
      .tz(userTimezone)
      .isSame(YESTERDAY, 'd')
  ) {
    return 'Yesterday';
  } else if (
    moment(date)
      .tz(userTimezone)
      .isAfter(A_WEEK_OLD)
  ) {
    return moment(date)
      .tz(userTimezone)
      .format('dddd');
  } else {
    return moment(date).format('dddd, MMMM D, YYYY');
  }
};

export const daysFromNow = date => {
  const today = moment();
  const diffInDays = today.diff(date, 'days');

  if (diffInDays < 30) {
    return `${diffInDays} days ago`;
  } else {
    return moment(date).fromNow();
  }
};

export const customFromNow = date => {
  const today = moment();
  const diffInDays = today.diff(date, 'days');

  if (diffInDays < 30) {
    return moment(date).fromNow();
  } else {
    return moment(date).format('MMMM DD, YYYY, h:mm A');
  }
};

export const formatDate = (value, format) => {
  if (value && value != 'TBD') return moment(value).format(format);
  return value;
};

const today = moment();

export const withTime = (
  value,
  params = { format: 'date', tz: null, fromNow: false, customFormat: null }
) => {
  //format:date|date-time
  if (!value) return value;
  const dateFormats = {
    date: 'MM/DD/YYYY',
    'date-time': 'MM/DD/YYYY h:mm A',
  };
  const {
    format = 'date',
    tz = null,
    fromNow = false,
    customFormat = dateFormats['date'],
  } = params;

  let momentInstance = moment(value);

  if (fromNow) {
    const diffInDays = today.diff(value, 'days');

    if (diffInDays < 30) {
      return momentInstance.fromNow();
    } else {
      return momentInstance.format(dateFormats[format] || customFormat);
    }
  }

  if (tz) {
    momentInstance = momentInstance.tz(tz);
  }

  return momentInstance.format(dateFormats[format] || customFormat);
};
