import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';

import CheckBox from 'components/FormFields/CheckBox';
import classes from '../../DataArea.module.scss';

import { doDeleteTimeEntry } from 'store/actions/timerActions';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEqual } from 'lodash';
import Button from 'components/Button';
import { useAccess, permissions } from 'helpers/permission';

import { doPatchAction } from 'store/actions/timelogReportActions';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { withTime } from 'helpers/times';

const TimeCell = ({
  row,
  cell,
  reload,
  openTimeModal,
  analyticsSendEvent: analyticsSendEventTimeLog,
}) => {
  const dispatch = useDispatch();
  const timezone = useSelector(({ auth }) => get(auth, 'user.timezone'));

  const auth = useSelector(({ auth }) => auth);
  const currentUser = get(auth, 'user.id', '');

  const canEditTimeEntry = useAccess([
    permissions.EDIT_ALL_TIMELOGS,
    {
      permission: permissions.EDIT_MY_TIMELOGS,
      value: isEqual(parseInt(currentUser), parseInt(row.user.id)),
    },
  ]);
  const canDeleteTimeEntry = useAccess([
    permissions.DELETE_ALL_TIMELOGS,
    {
      permission: permissions.DELETE_MY_TIMELOGS,
      value: isEqual(parseInt(currentUser), parseInt(row.user.id)),
    },
  ]);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.time_entries,
      ...rest,
    });
  };

  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div className="mr-4">
        <strong>{row.hms}</strong>
        <br />
        <span className="text-muted">
          {withTime(row.date_start, { tz: timezone, customFormat: 'hh:mm A' })}
          {' - '}
          {withTime(row.date_end, { tz: timezone, customFormat: 'hh:mm A' })}
        </span>
      </div>
      <Button
        color={row.is_billable ? 'primary' : 'secondary'}
        size="sm"
        className="mr-3"
        onClick={async () => {
          row.is_billable &&
            analyticsSendEventTimeLog({
              action: analyticsConstants.action.discount_time_entry,
              updated_from: 'Timelog Details',
            });

          const rs = await dispatch(
            doPatchAction(row.id, !row.is_billable, 'is_billable')
          );

          if (rs.status === 1) reload();
        }}
        disabled={!useAccess([permissions.DISCOUNT_TIMELOGS])}
      >
        <i className="fas fa-dollar-sign"></i>
      </Button>
      <div id={`div-cb-entry-${row.id}`}>
        <CheckBox
          className={classes.checkbox}
          name={`cb-entry-${row.id}`}
          id={`cb-entry-${row.id}`}
          checked={row.is_approved}
          onChange={async () => {
            !row.is_approved &&
              analyticsSendEventTimeLog({
                action: analyticsConstants.action.approve_time_entry,
                updated_from: 'Timelog Details',
              });
            const rs = await dispatch(
              doPatchAction(row.id, !row.is_approved, 'is_approved')
            );

            if (rs.status === 1) reload();
          }}
          disabled={!useAccess([permissions.APPROVE_TIMELOGS])}
        />
      </div>

      {row.approver.id && (
        <UncontrolledTooltip
          placement="bottom"
          target={`#div-cb-entry-${row.id}`}
        >
          Approved by {row.approver.name}
        </UncontrolledTooltip>
      )}

      <span>
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only text-light"
            href="#pablo"
            role="button"
            size="sm"
            color=""
            onClick={e => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            {canEditTimeEntry && (
              <DropdownItem onClick={() => openTimeModal(row.id)}>
                Edit Time Entry
              </DropdownItem>
            )}
            {canDeleteTimeEntry && (
              <DropdownItem
                onClick={() => {
                  AlertPopupHandler.open({
                    onConfirm: async () => {
                      const rs = await dispatch(doDeleteTimeEntry(row.id));
                      analyticsSendEvent({
                        action: analyticsConstants.action.delete_time_entry,
                        updated_from: 'Timelog Report',
                      });
                      if (rs === 1) reload();
                    },
                    confirmBtnText: 'Delete Time Entry',
                    text: `You are about to delete this Time Entry. Do you want to continue?`,
                    data: {},
                  });
                }}
              >
                Delete Time Entry
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </span>
    </div>
  );
};

export default TimeCell;
