import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Button from 'components/Button';
import { Form, FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap';
import classes from './EditContractModal.module.scss';
import cs from 'classnames';
import * as Yup from 'yup';
import { callPostUpdateDealDeskById } from 'store/actions/dealDeskActions';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, Formik } from 'formik';
import { FormikInput } from 'components/FormFields/Input';
import RSelect from 'components/FormFields/RSelect';
import { get } from 'lodash';

const formatList = data => {
  return data.map((data, index) => ({
    id: index + 1,
    label: data,
  }));
};

const EditContractModal = forwardRef(({ id, type }, ref) => {
  const dispatch = useDispatch();

  const options = useSelector(({ dealDesk }) => {
    if (type === 'deals') {
      return formatList(get(dealDesk, 'optionList.data') || []);
    } else {
      return [{ label: '10%' }, { label: '15%' }];
    }
  });

  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    open: d => {
      setData(d);
      setOpen(true);
    },
  }));

  const toggleModal = () => {
    setOpen(!isOpen);
  };

  const handleSubmit = async values => {
    let ccArray;
    const { cc_list = [] } = values;
    if (cc_list && cc_list.length > 0) {
      ccArray = cc_list.filter(item => !!item.name && !!item.email);
    }
    setLoading(true);
    let post = {};

    post = {
      [data.name]: {
        ...values,
        cc_list: ccArray,
      },
    };
    const resp = await dispatch(
      callPostUpdateDealDeskById(
        id,
        post,
        type === 'deals' ? 'deal-desk' : 'partner-deal-desk'
      )
    );
    setLoading(false);
    setOpen(false);
  };

  const fields = data?.fields || [];

  let initialValues = {};
  let dynamicSchema = {};

  fields.forEach(field => {
    initialValues[field.name] = field.value;
  });

  fields.forEach(field => {
    if (field?.type === 'field_array') {
      if (field?.name === 'cc_list') {
        dynamicSchema[field.name] = Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
            email: Yup.string().when('name', {
              is: name => name && name.trim().length > 0,
              then: Yup.string()
                .email('Invalid email format')
                .required('This field is required.'),
              otherwise: Yup.string()
                .email('Invalid email format')
                .notRequired(),
            }),
          })
        );
      }
    } else {
      dynamicSchema[field.name] = Yup.string().when([], {
        is: () => !!field?.required,
        then: () =>
          Yup.string()
            .required('This field is required.')
            .nullable(),
        otherwise: () =>
          Yup.string()
            .nullable()
            .notRequired(),
      });
    }
  });

  const schema = Yup.object().shape(dynamicSchema);

  const getFieldInputLabel = name => {
    switch (name) {
      case 'name':
        return 'CC Name';
      case 'email':
        return 'CC Email Address';
      default:
        return '';
    }
  };

  return (
    <>
      <Modal
        size="md"
        centered={true}
        scrollable
        fade={false}
        isOpen={isOpen}
        toggle={toggleModal}
        className={classes.modalEditContract}
      >
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={schema}
          enableReinitialize
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
          }}
        >
          {({ handleSubmit, isValid, values, errors, setFieldValue }) => {
            return (
              <ModalBody className={classes.modalBody}>
                <div className={classes.title}>{data?.title}</div>
                <Form>
                  {fields.map((f, index) => (
                    <FormGroup key={index}>
                      {f?.type !== 'field_array' && (
                        <Label for={f.name}>{f.label}</Label>
                      )}
                      {f?.type === 'select' ? (
                        <RSelect
                          name={f.name}
                          isClearable={false}
                          className={cs('mb-4', classes.select)}
                          value={options.find(d => d.label === values[f.name])}
                          getOptionLabel={opt => opt.label}
                          getOptionValue={opt => opt.label}
                          placeholder={'Search for a choice'}
                          options={options}
                          menuPlacement={f.name === 'Option 2' ? 'top' : 'auto'}
                          error={errors[f.name]}
                          onChange={selectedOption => {
                            setFieldValue(f.name, selectedOption?.label);
                          }}
                        />
                      ) : f?.type === 'field_array' ? (
                        <FieldArray name="cc_list">
                          {arrayHelpers => {
                            return (
                              <div>
                                {values?.cc_list?.map((field, index) => (
                                  <div key={index}>
                                    <label htmlFor={`cc_list.${index}.name`}>
                                      {getFieldInputLabel(
                                        Object.keys(field)[0]
                                      )}
                                    </label>
                                    <FormikInput
                                      groupClassName="mb-4"
                                      name={`cc_list.${index}.name`}
                                      placeholder=""
                                      type="text"
                                      value={field.value}
                                    />
                                    <label htmlFor={`cc_list.${index}.email`}>
                                      {getFieldInputLabel(
                                        Object.keys(field)[1]
                                      )}
                                    </label>
                                    <FormikInput
                                      groupClassName="mb-4"
                                      name={`cc_list.${index}.email`}
                                      placeholder=""
                                      type="text"
                                      value={field.value}
                                    />
                                  </div>
                                ))}
                                {data?.title === 'Signatory' && (
                                  <button
                                    onClick={() => {
                                      arrayHelpers.push({
                                        name: '',
                                        email: '',
                                      });
                                    }}
                                    type="button"
                                    className={classes.addEntity}
                                  >
                                    + Add CC
                                  </button>
                                )}
                              </div>
                            );
                          }}
                        </FieldArray>
                      ) : (
                        <FormikInput
                          groupClassName="mb-4"
                          name={f.name}
                          placeholder=""
                          type="text"
                          required={!!f?.required}
                        />
                      )}
                    </FormGroup>
                  ))}
                </Form>
                <div className={classes.buttons}>
                  <button
                    type="button"
                    className={cs('btn btn-secondary', classes.deny)}
                    onClick={() => toggleModal()}
                  >
                    Cancel
                  </button>
                  <Button
                    disabled={loading}
                    loading={loading}
                    type="submit"
                    onClick={handleSubmit}
                    className={cs('btn btn-success', classes.submit)}
                  >
                    Save
                  </Button>
                </div>
              </ModalBody>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
});

export default EditContractModal;
