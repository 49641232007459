import React from 'react';
import className from 'classnames';
import classes from 'views/pages/ClientProfile/CreditBank/CreditBank.module.scss';
import { Row } from 'reactstrap';
import RSelect from 'components/FormFields/RSelect';
import { getOptions } from 'helpers/clientProfile';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

const Scope = ({ label, type, values, setFieldValue }) => {
  const yearsList = useSelector(({ metadata }) =>
    get(metadata, 'yearList.data', {})
  );
  return (
    <>
      <Row className={className('mb-0 font-weight-bold', classes.row)}>
        Scope ({label})
      </Row>
      <Row className={className('mb-0', classes.row)}>
        <div className={classes.textInputRightMargin}>
          <RSelect
            name={`scope_${type}_start_year`}
            label="Start Year"
            className="mb-3"
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            options={getOptions(yearsList)}
            value={values[`scope_${type}_start_year`]}
            onChange={value => {
              setFieldValue(`scope_${type}_start_year`, value ? value : null);
            }}
          />
        </div>
        <div className={classes.textInputLeftMargin}>
          <RSelect
            name={`scope_${type}_end_year`}
            label="End Year"
            className="mb-3"
            getOptionLabel={opt => opt.label}
            getOptionValue={opt => opt.value}
            options={getOptions(yearsList)}
            value={values[`scope_${type}_end_year`]}
            onChange={value => {
              setFieldValue(`scope_${type}_end_year`, value ? value : null);
            }}
          />
        </div>
      </Row>
    </>
  );
};

export default Scope;
