import React, { useEffect, useState } from 'react';
import classes from './DocumentBucket.module.scss';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import DocumentDetails from 'components/Fulfillment/Modals/DocumentCommentModal/DocumentDetails';
import cx from 'classnames';
import { useParams, useHistory } from 'react-router';
import get from 'lodash/get';
import analytics, { analyticsConstants } from 'helpers/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BackIcon } from 'assets/img/icons/chevron-left-icon.svg';
import DocumentInfoSection from './components/DocumentInfoSection';
import DocumentYearStatus from 'components/Fulfillment/Modals/DocumentCommentModal/DocumentYearStatus/DocumentYearStatus';
import { fetchDocumentStudyTypes } from 'store/actions/documents';
import Loading from 'components/Loading';

const DocumentBucket = () => {
  const user = useSelector(({ auth }) => get(auth, 'user'));
  const assigned_initiatives = get(user, 'assigned_initiatives', []);
  const params = useParams();
  const dispatch = useDispatch();
  const id = get(params, 'id');
  const parentId = get(params, 'parentId');
  const modalParams = {
    parent_type: 'FinancialYearDocumentType',
    parent_id: parentId,
  };
  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const [files, setFiles] = useState({});
  const currentStudyName = get(currentStudy, 'name', '');
  const isStudyAssigned = assigned_initiatives.includes(currentStudy?.id);
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.stories,
      ...rest,
    });
  };
  const history = useHistory();
  const isClient = useSelector(({ auth }) => auth.user.is_client, false);
  const userType = isClient ? 'client' : 'admin';
  const docType = files?.name;
  const loading = useSelector(({ documents }) =>
    get(documents, 'documentStudyTypes.isInProgress', false)
  );

  const createSuccessMessage = updatedDocument => {
    if (updatedDocument.status || updatedDocument.years) {
      let message = '';
      if (updatedDocument.status)
        message += `${currentStudy?.name}-${files?.name} has been marked "${updatedDocument?.status}"`;
      if (updatedDocument.years) {
        updatedDocument.years.forEach(year => {
          if (message != '')
            message += ` and Year ${year.year} in ${currentStudy?.name} - ${files?.name} set to "${year.status}"`;
          else
            message += `Year ${year.year} in ${currentStudy?.name} - ${files?.name} set to "${year.status}"`;
        });
      }
      // ?????????  setSuccessMessage(message);
    }
  };

  const handleGetDocumentData = documents => {
    const getDocData = documents?.find(
      doc => doc?.financial_year_document_id == parentId
    );

    return getDocData || [];
  };

  const getDocs = async () => {
    const { data = [] } = await dispatch(fetchDocumentStudyTypes(id));
    const getDocData = handleGetDocumentData(data);
    setFiles(getDocData);
  };

  useEffect(() => {
    getDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <div className={classes.container}>
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          <BackIcon
            className={classes.leftIcon}
            onClick={() => history.goBack()}
          />
          <div className={classes.studyName}>{currentStudyName}</div>
          <Breadcrumb
            className={cx('d-none d-md-inline-block md-4', classes.breadcrumb)}
            listClassName="breadcrumb-links"
          >
            <BreadcrumbItem>
              <div style={{ display: 'flex' }}>
                <span className={classes.dashIcon}>&nbsp; - &nbsp;</span>
                <div className={classes.docType}>
                  {currentStudyName ? currentStudyName : '-'} -{' '}
                  <strong>{docType}</strong>
                </div>
              </div>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
      {userType === 'admin' && files?.financial_year_document_id && !loading && (
        <Card>
          <CardHeader className={classes.cardHeader}>
            <h3 className="mb-0">{currentStudyName}</h3>
            <span className={classes.docType}>• {docType}</span>
          </CardHeader>
          <DocumentYearStatus
            documentData={files}
            handleDocAndYearStatusUpdate={createSuccessMessage}
            isStudyAssigned={isStudyAssigned}
            parentId={parentId}
            getDocs={getDocs}
          />
        </Card>
      )}
      <Row className={classes.chatWrapper}>
        <Col xs="8">
          <DocumentInfoSection
            params={modalParams}
            parentId={parentId}
            userType={userType}
            files={files}
            setFiles={setFiles}
            initiativeId={id}
            isStudyAssigned={isStudyAssigned}
            getDocs={getDocs}
          />
        </Col>
        <Col xs="4">
          <Card className={classes.chatCard}>
            <CardHeader className={classes.cardHeader}>
              <h3 className="mb-0">Chat</h3>
            </CardHeader>
            <DocumentDetails
              initiativeId={id}
              params={modalParams}
              parentId={modalParams.parent_id}
              userType={userType}
              comment={''}
              onTabChange={() => {}}
              analyticsSendEvent={analyticsSendEvent}
              isStudyAssigned={isStudyAssigned}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DocumentBucket;
