import classes from '../QuickStartTour.module.scss';

export const getClientCreationSteps = () => [
  {
    target: '#sidebarItem-clients',
    title: 'Create a Client',
    content:
      'Organize your projects based on your client. Time tracking and reporting build better transparency & visibility.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.sidebarIcon,
  },
  {
    target: '#addClientBtn',
    title: 'Create a Client',
    content: 'Click here to add your Client!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    placement: 'left',
    extraPlacementClass: classes.addBtn,
  },
  {
    target: '#clientNameInput',
    title: 'Create a Client',
    content: 'Creating a client is as simple as typing their name.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'left',
    extraPlacementClass: classes.createClientInput,
  },
  {
    target: '#createClientBtn',
    title: 'Create a Client',
    content: 'Click here to create your Client!',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    disableOverlay: true,
    placement: 'right',
    extraPlacementClass: classes.createClientBtn,
  },
];
