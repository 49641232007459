import React, { useState } from 'react';
import { ModalFooter } from 'reactstrap';

import classes from './DealDeskModal.module.scss';
import SendIcon from 'assets/img/icons/deal-desk/send-message-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { callPostAddCommentDealDesk } from 'store/actions/dealDeskActions';
import get from 'lodash/get';
import Editor from 'components/FormFields/Editor';
import Input from 'components/FormFields/Input';
import { getStringFromHtml } from 'helpers/constants';
import { cleanUpMentions } from 'helpers/mentions';

import { removeMentionsUrl, setMentionsUrl } from 'store/actions/components';

const DealDeskModalAddComment = ({ type }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const [showEditor, setShowEditor] = useState(false);
  const dealDesk = useSelector(({ dealDesk }) =>
    get(dealDesk, 'detailDealDesk')
  );
  const data = dealDesk?.data;
  const id = data?.id;
  const clientId = get(data, 'client.id');
  const handleChange = e => {
    setMessage(e.target.value);
  };

  const onSubmit = async e => {
    e.preventDefault();
    if (loading) return '';
    setLoading(true);
    await dispatch(
      callPostAddCommentDealDesk(
        id,
        message ? cleanUpMentions(message) : getComment(),
        null,
        null,
        type === 'deals' ? 'Initiative' : 'PartnerReferral'
      )
    );
    setMessage('');
    setLoading(false);
  };
  const getComment = () =>
    message.endsWith('<p><br></p>')
      ? message.replace('<p><br></p>', '')
      : cleanUpMentions(message);
  const mentionUrl = `/clients/${clientId}/mention?q=&is_client=0&is_fulfilment=1`;
  return (
    <form name="create-message" onSubmit={onSubmit}>
      <ModalFooter className={classes.chatBox}>
        <div className={classes.avatar}>
          {user?.avatar ? (
            <img src={user?.avatar} />
          ) : (
            `${user?.first_name?.charAt(0)}
              ${user?.last_name?.charAt(0)}`
          )}
        </div>
        <div className={classes.box} id="comment-editor">
          {showEditor ? (
            <Editor
              id="comments-editor"
              value={message}
              onChange={content => {
                setMessage(content);
              }}
              onBlur={() => {
                removeMentionsUrl();
              }}
              assignRef={ref => {
                if (ref) {
                  const editor = ref.getEditor();
                  editor.focus();
                }
              }}
              shouldAllowMention={true}
              mentionProps={{
                defaultMenuOrientation: 'top',
              }}
            />
          ) : (
            <Input
              disabled={false}
              value={getStringFromHtml(message)}
              placeholder="Add a message"
              onClick={() => {
                setShowEditor(true);
                dispatch(setMentionsUrl(mentionUrl));
              }}
              maxLength={255}
            />
          )}
          <button
            type="submit"
            id="btnSend"
            disabled={loading}
            className={classes.btnSend}
          >
            <img src={SendIcon} />
          </button>
        </div>
      </ModalFooter>
    </form>
  );
};

export default DealDeskModalAddComment;
