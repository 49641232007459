import React from 'react';
import classes from './MacroSideBar.module.scss';
import cs from 'classnames';
import parse from 'html-react-parser';

const MacroSideBarContent = ({
  icon,
  description,
  label,
  active,
  isLast,
  completed,
  onClick,
}) => {
  return (
    <div
      className={cs(classes.sideBarContent, completed && classes.hoverHand)}
      onClick={onClick}
    >
      <span
        className={cs(classes.icon, {
          [classes.active]: active,
        })}
      >
        <i className={icon} />
      </span>
      <div
        className={cs(classes.descriptionWrapper, {
          [classes.activeBorder]: completed,
          [classes.last]: isLast,
        })}
      >
        <div
          className={cs(classes.label, {
            [classes.activeLabel]: active,
          })}
        >
          {label}
        </div>
        {active && (
          <div className={classes.description}>{parse(description)}</div>
        )}
      </div>
    </div>
  );
};

export default MacroSideBarContent;
