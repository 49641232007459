import React from 'react';
import { get } from 'lodash';
import { getOcrDocumentTypes } from 'api/documentUpload';
import Types from 'store/types/documents';

export const fetchOcrDocumentTypes = () => {
  return async dispatch => {
    // eslint-disable-next-line no-undef
    try {
      const resp = await getOcrDocumentTypes();
      var i = 0;
      var data = [];
      while (resp[i++])
        data.push({
          id: resp[i - 1].type,
          label: resp[i - 1].full_name,
        });
      dispatch({
        type: Types.FETCH_OCR_DOCUMENT_TYPES_SUCCESS,
        data,
      });
    } catch (error) {}
  };
};
