import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import classes from './NoteItem.module.scss';
import cs from 'classnames';
import parser from 'html-react-parser';
import Pin from 'components/Pin';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

const acceptableRoles = ['Company_Administrator', 'Fulfilment_Director'];

const NoteItem = ({
  note,
  index,
  pinToggle,
  handleEditNote,
  handleDeleteNote,
}) => {
  const user = useSelector(({ auth }) => get(auth, 'user'));
  const userTimezone = get(user, 'timezone', '');

  const roles = get(user, 'roles', []);

  const isNoteOwner = user?.id === note.created_by;
  const isRoleAcceptable = acceptableRoles.some(role => roles.includes(role));

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownOptions = [
    ...(isNoteOwner
      ? [
          {
            text: 'Edit Note',
            onClick: note => {
              handleEditNote(note);
            },
          },
        ]
      : []),
    ...(isRoleAcceptable || isNoteOwner
      ? [
          {
            text: 'Delete Note',
            onClick: note => {
              handleDeleteNote(note.id);
            },
          },
        ]
      : []),
  ];
  const toggleDropdown = () => {
    setDropdownOpen(prevState => !prevState);
  };

  return (
    <div className={cs('ql-editor', classes.noteContainer)} key={index}>
      <Pin
        pin={!!note?.pinned_by}
        pinToggle={() => pinToggle(note)}
        className={classes.pinButton}
      />
      {note?.description && <div>{parser(note.description)}</div>}
      <div>
        <div className={classes.noteActions}>
          <div>
            {note?.edited_at && (
              <div className={classes.editedByTime}>
                Edited{' '}
                {moment(note?.edited_at)
                  .tz(userTimezone)
                  .format('MMM DD - hh:mm A')}
              </div>
            )}
          </div>
          <div>
            {!!dropdownOptions?.length && (
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className={classes.dropDown}
              >
                <DropdownToggle
                  caret={false}
                  size="sm"
                  className={cs('text-light ml-1', classes.commentsDropdown)}
                  color=""
                >
                  <i
                    className="fas fa-ellipsis-v"
                    style={{ color: '#32325D' }}
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  {dropdownOptions.map((item, index) => (
                    <DropdownItem
                      key={index}
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        item.onClick(note);
                      }}
                    >
                      {item.text}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteItem;
