import * as React from 'react';
import cs from 'classnames';
import { FormGroup } from 'reactstrap';
import { Field } from 'formik';
import TextInput from './index';
import get from 'lodash/get';

const FormikInput = props => {
  const [isFocused, setFocused] = React.useState(false);
  const { groupClassName, onBlur, ...restProps } = props;
  return (
    <FormGroup
      className={cs(groupClassName, {
        focused: isFocused,
      })}
    >
      <Field name={props.name}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors, handleBlur }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        }) => (
          <TextInput
            {...restProps}
            {...field}
            touched={get(touched, field.name)}
            error={get(touched, field.name) && get(errors, field.name)}
            onFocus={e => setFocused(true)}
            onBlur={e => {
              handleBlur(e);
              setFocused(false);
              onBlur && onBlur();
            }}
          />
        )}
      </Field>
    </FormGroup>
  );
};

export default FormikInput;
