import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Notification.module.scss';
import CardHeader from 'reactstrap/es/CardHeader';

const NotificationCount = props => {
  return (
    <CardHeader className="p-3 border-0">
      {props.count ? (
        <div
          className={classNames(
            'd-flex justify-content-between w-100 text-sm font-weight-600',
            classes.notificationHeader
          )}
        >
          <span>
            You have <span className="text-primary">{props.count}</span> unread
            notifications
          </span>
          <span
            className={classNames(
              'text-primary font-weight-300',
              classes.markAll
            )}
            onClick={props.handleMarkAllReadClick}
          >
            Mark all as Read
          </span>
        </div>
      ) : (
        <p
          className={classNames(
            'mb-0 text-sm font-weight-600',
            classes.notificationHeader
          )}
        >
          You're all caught up!
        </p>
      )}
    </CardHeader>
  );
};

NotificationCount.prototype = {
  count: PropTypes.number,
  handleMarkAllReadClick: PropTypes.func,
};

export default NotificationCount;
