import classes from '../QuickStartTour.module.scss';

export const getViewProjectsSteps = () => [
  {
    target: '#sidebarItem-projects',
    title: 'View Projects',
    content:
      'This is where you will go to view all projects you have been assigned to. Projects is where your assigned work will live and be created.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    spotlightPadding: 0,
    placement: 'right',
    extraPlacementClass: classes.sidebarIcon,
  },
  {
    target: '#projectsList',
    title: 'View Projects',
    content:
      'The project list will display all of the projects that you are assigned to and working on.',
    disableBeacon: true,
    hideFooter: true,
    hideCloseButton: true,
    spotlightClicks: true,
    shouldShowDismiss: true,
    disableOverlay: true,
    spotlightPadding: 0,
    placement: 'top',
    extraPlacementClass: classes.projectsTable,
  },
];
