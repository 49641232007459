import Types from '../types/components';

const initialState = {
  mentionUrl: '',
};

export default function ComponentReducers(state = initialState, action) {
  switch (action.type) {
    case Types.ADD_MENTION_URL:
      return {
        ...state,
        mentionUrl: action.data,
      };
    case Types.REMOVE_MENTION_URL:
      return {
        ...state,
        mentionUrl: initialState.mentionUrl,
      };
    default:
      return state;
  }
}
