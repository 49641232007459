import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import addHistoryIcon from 'assets/img/icons/add-history-timer.svg';

const AddManualEntryButton = props => {
  const { classes, runningTime } = props;
  if (runningTime)
    return (
      <>
        <button
          className={classes.addEntry}
          onClick={e => {
            e.preventDefault();
          }}
          id="btn-add-manual-entry"
        >
          <img src={addHistoryIcon} alt="add time entry" />
        </button>

        <UncontrolledTooltip
          delay={0}
          placement="bottom"
          target="btn-add-manual-entry"
        >
          You cannot manually enter time while your timer is running
        </UncontrolledTooltip>
      </>
    );
  else
    return (
      <>
        <button
          className={classes.addEntry}
          onClick={() => {
            props.toggle('add-manual');
          }}
        >
          <img src={addHistoryIcon} alt="add time entry" />
        </button>
      </>
    );
};

export default AddManualEntryButton;
