import createTypes from 'redux-create-action-types';

export default createTypes(
  'DELETE_EPIC_INPROGRESS',
  'DELETE_EPIC_SUCCESS',
  'DELETE_EPIC_FAILURE',
  'FETCH_EPICS_INPROGRESS',
  'FETCH_EPICS_SUCCESS',
  'FETCH_EPICS_FAILURE',
  'UPDATE_EPIC_STATE',
  'CREATE_EPIC_INPROGRESS',
  'CREATE_EPIC_SUCCESS',
  'CREATE_EPIC_FAILURE',
  'UPDATE_EPIC_INPROGRESS',
  'UPDATE_EPIC_SUCCESS',
  'UPDATE_EPIC_FAILURE',
  'GET_EPIC_INPROGRESS',
  'GET_EPIC_SUCCESS',
  'GET_EPIC_FAILURE',
  'GET_EPIC_RESET',
  'REORDER_EPIC_INPROGRESS',
  'REORDER_EPIC_SUCCESS',
  'RESET_CREATE_EPIC',
  'REORDER_EPIC_FAILURE'
);
