import React from 'react';
import classes from '../StoryAssignments.module.scss';
import SelectAsync from 'components/FormFields/SelectAsync';
import get from 'lodash/get';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import {
  doPostAddDependency,
  doDeleteDependency,
} from 'store/actions/Story/assignments';
import { shortName } from 'helpers/formatName';
import { analyticsConstants } from 'helpers/analytics';
const Dependency = ({
  task,
  smallView,
  reload,
  isEditAllowed,
  isDependency,
  analyticsSendEvent,
}) => {
  const dispatch = useDispatch();
  const dependency = get(task, 'dependency');

  if (dependency)
    return (
      <>
        <Badge
          pill
          className={cs(classes.addDependency, {
            [classes.active]: isDependency === false,
            [classes.locked]: isDependency === true,
          })}
          id={`div-locked-assignment-${task.id}`}
        >
          Dependency linked to {dependency.name}{' '}
          {isEditAllowed && (
            <i
              onClick={async () => {
                await dispatch(doDeleteDependency(task.id));
                reload();
              }}
              className={cs('ml-2 fas fa-times ', classes.closeIcon)}
            ></i>
          )}
        </Badge>
        <UncontrolledTooltip
          innerClassName={classes.popoverLocked}
          placement="bottom"
          target={`#div-locked-assignment-${task.id}`}
        >
          Dependent on: <b>{dependency.name}</b> <br />
          Assignee: <b>{dependency.user ? dependency.user.name : 'N/A'}</b>
        </UncontrolledTooltip>
      </>
    );

  if (!isEditAllowed) return '';

  return (
    <SelectAsync
      url={`/tasks/${task.id}/dependencies`}
      id={`assignment-depency-task-${task.id}`}
      onChange={async data => {
        analyticsSendEvent({
          action: analyticsConstants.action.add_assignment_dependency,
        });
        await dispatch(doPostAddDependency(task.id, data.id));
        reload();
      }}
      placement={smallView ? 'bottom-end' : 'bottom-start'}
      getOptionLabel={option => <DependencyItem data={option} />}
    >
      <Badge pill className={classes.addDependency}>
        + Add Dependency
      </Badge>
    </SelectAsync>
  );
};

const DependencyItem = ({ data = {} }) => {
  const user = get(data, 'user');
  return (
    <>
      <div
        className={cs(
          'd-flex align-items-center flex-wrap',
          classes.dependencyItem
        )}
      >
        <span className={classes.dependencyName}>{data.name}</span>
        {!!user && (
          <span className={cs(classes.dependencyUser, 'ml-1')}>
            - {shortName(get(user, 'name'))}
          </span>
        )}
      </div>
    </>
  );
};

export default Dependency;
