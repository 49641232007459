import * as React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import classes from './IconCheckBox.module.scss';

const IconCheckBox = props => {
  const {
    className,
    disabled,
    touched,
    error,
    label,
    checked,
    iconChecked,
    iconUncheck,
    onChange,
    ...rest
  } = props;

  const icon = checked ? iconChecked : iconUncheck;

  return (
    <>
      <div className={cs(classes.root, className)} {...rest}>
        <img src={icon} alt="icon check" onClick={onChange} />

        <label className={cs('')} onClick={onChange}>
          {label}
        </label>
      </div>

      {touched && error && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </>
  );
};

IconCheckBox.prototype = {
  checked: PropTypes.bool,
  touched: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  iconChecked: PropTypes.string.isRequired,
  iconUncheck: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

IconCheckBox.defaultProps = {
  label: null,
  touched: false,
};

export default IconCheckBox;
