import createTypes from 'redux-create-action-types';

export default createTypes(
  'FETCH_PARTNERS_DASHBOARD_INPROGRESS',
  'FETCH_PARTNERS_DASHBOARD_SUCCESS',
  'FETCH_PARTNERS_DASHBOARD_FAILURE',
  'FETCH_PARTNERS_INDUSTRIES_LIST_INPROGRESS',
  'FETCH_PARTNERS_INDUSTRIES_LIST_SUCCESS',
  'FETCH_PARTNERS_INDUSTRIES_LIST_FAILURE',
  'CREATE_PARTNER_REFERRAL_INPROGRESS',
  'CREATE_PARTNER_REFERRAL_SUCCESS',
  'CREATE_PARTNER_REFERRAL_FAILURE',
  'FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_INPROGRESS',
  'FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_SUCCESS',
  'FETCH_PARTNERS_DASHBOARD_INPROGRESS_RECORDS_FAILURE',
  'FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_INPROGRESS',
  'FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_SUCCESS',
  'FETCH_PARTNERS_DASHBOARD_SILENT_RECORDS_FAILURE',
  'FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_INPROGRESS',
  'FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_SUCCESS',
  'FETCH_PARTNERS_DASHBOARD_DISQUALIFIED_RECORDS_FAILURE',
  'FETCH_CLIENT_INDUSTRIES_LIST_INPROGRESS',
  'FETCH_CLIENT_INDUSTRIES_LIST_SUCCESS',
  'FETCH_CLIENT_INDUSTRIES_LIST_FAILURE',
  'FETCH_PARTNER_TYPE_LIST_INPROGRESS',
  'FETCH_PARTNER_TYPE_LIST_SUCCESS',
  'FETCH_PARTNER_TYPE_LIST_FAILURE',
  'FETCH_REFERRAL_DASHBOARD_INPROGRESS',
  'FETCH_REFERRAL_DASHBOARD_SUCCESS',
  'FETCH_REFERRAL_DASHBOARD_FAILURE',
  'CLEAR_PARTNER_DASHBOARD'
);
