import Request from './request';

export const fetchPartnerList = async (page, sort, query) => {
  return Request.call({
    url: '/partner',
    method: 'GET',
    params: {
      page,
      sort,
      q: query,
    },
  });
};

export const invitePartner = async partnerId => {
  return Request.call({
    url: `/partner/invitations/send/${partnerId}`,
    method: 'POST',
  });
};

export const inviteClient = async data => {
  return Request.call({
    url: `/partner/invite-client`,
    method: 'POST',
    data,
  });
};

export const postQuestion = async data => {
  return Request.call({
    url: `/partner/ask-question`,
    method: 'POST',
    data,
  });
};

export const updatePartnerProfile = async (id, data) => {
  return Request.call({
    url: `/partner/${id}`,
    method: 'PUT',
    data,
  });
};
export const updatePartnerTimeZone = async timezone => {
  return Request.call({
    url: `/partner/timezone`,
    method: 'PUT',
    data: { timezone },
  });
};

export const removePartner = async id => {
  return Request.call({
    url: `/partner/${id}/delete`,
    method: 'POST',
  });
};

export const fetchCalendlyDetails = async userId => {
  return Request.call({
    url: `/partner/${userId}/calendly`,
    method: 'GET',
  });
};

export const fetchPartnerDashboard = async () => {
  return Request.call({
    url: `/partner/dashboard`,
    method: 'GET',
  });
};

export const cancelInvitations = async id => {
  return Request.call({
    url: `/partner/invitations/cancel/${id}`,
    method: 'POST',
  });
};

export const resendInvitations = async id => {
  return Request.call({
    url: `/partner/invitations/resend/${id}`,
    method: 'POST',
  });
};

export const fetchPartnersActivity = async leadId => {
  return Request.call({
    url: `/partner/${leadId}/activities`,
    method: 'GET',
  });
};

export const fetchClientStudiesForGivenPartner = async data => {
  return Request.call({
    url: '/partner/clients',
    method: 'GET',
    params: data,
  });
};

export const fetchEarnings = async () => {
  return Request.call({
    url: '/partner/earnings',
    method: 'GET',
  });
};

export const fetchReferralComments = async (
  parentId,
  messageId,
  type = 'referral',
  lead_id,
  toMessageId,
  parentType
) => {
  return Request.call({
    url: '/comments',
    method: 'GET',
    params: {
      parent_type: parentType,
      parent_id: parentId,
      lead_id,
      type,
      ...(messageId ? { message_id: messageId } : {}),
      ...(toMessageId ? { to_message_id: toMessageId } : {}),
    },
  });
};

export const postReferralComment = async (
  parentId,
  comment,
  parent_type,
  type = 'referral',
  lead_id
) => {
  return Request.call({
    url: `/comments`,
    method: 'POST',
    data: {
      type,
      parent_id: parentId,
      message: comment,
      lead_id,
      parent_type,
    },
  });
};

export const updateReferralComment = async (commentId, comment) => {
  return Request.call({
    url: `/comments/${commentId}`,
    method: 'PUT',
    data: {
      message: comment,
    },
  });
};

export const deleteReferralComment = async commentId => {
  return Request.call({
    url: `/comments/${commentId}`,
    method: 'DELETE',
  });
};
