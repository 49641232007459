import Types from '../types/profile';
import { get } from 'lodash';
import findIndex from 'lodash/findIndex';

const initialState = {
  details: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  availability: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  tasks: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  preference: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  updateDetails: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  activities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
    data: {},
  },
  moreActivities: {
    isInProgress: false,
    isError: false,
    status: 0,
    message: '',
  },
  quickStartTour: {
    activeTour: null,
    step: null,
    isRestarted: false,
  },
};

export default function Profile(state = initialState, action) {
  switch (action.type) {
    case Types.FETCH_DETAILS_INPROGRESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: initialState.details.data,
          isInProgress: true,
        },
      };
    case Types.FETCH_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_DETAILS_INPROGRESS:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          isInProgress: true,
        },
      };
    case Types.UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          isInProgress: false,
          status: 1,
        },
        details: {
          ...state.details,
          data: action.data,
        },
      };
    case Types.UPDATE_DETAILS_FAILURE:
      return {
        ...state,
        updateDetails: {
          ...state.updateDetails,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_AVAILABILITY_STATS_INPROGRESS:
      return {
        ...state,
        availability: {
          ...state.availability,
          isInProgress: true,
        },
      };
    case Types.FETCH_AVAILABILITY_STATS_SUCCESS:
      return {
        ...state,
        availability: {
          ...state.availability,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_AVAILABILITY_STATS_FAILURE:
      return {
        ...state,
        availability: {
          ...state.availability,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_TASKS_STATS_INPROGRESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isInProgress: true,
        },
      };
    case Types.FETCH_TASKS_STATS_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_TASKS_STATS_FAILURE:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.SET_PREFERENCE_INPROGRESS:
    case Types.FETCH_PREFERENCE_INPROGRESS:
      return {
        ...state,
        preference: {
          ...state.preference,
          loading: true,
          isError: false,
        },
      };

    case Types.SET_PREFERENCE_SUCCESS:
    case Types.FETCH_PREFERENCE_SUCCESS:
      return {
        ...state,
        preference: {
          ...state.preference,
          data: action.data,
          loading: false,
          isError: false,
        },
      };

    case Types.SET_PREFERENCE_FAILURE:
    case Types.FETCH_PREFERENCE_FAILURE:
      return {
        ...state,
        preference: {
          ...state.preference,
          loading: false,
          isError: true,
          message: action.message,
        },
      };

    case Types.FETCH_PROFILE_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: true,
        },
      };
    case Types.FETCH_PROFILE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          status: 1,
          data: action.data,
        },
      };
    case Types.FETCH_PROFILE_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: {
          ...state.activities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.FETCH_MORE_PROFILE_ACTIVITIES_INPROGRESS:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: true,
        },
      };
    case Types.FETCH_MORE_PROFILE_ACTIVITIES_SUCCESS: {
      let { data } = action;
      let activities = [];
      let existingData = get(state, 'activities.data.data');
      const { data: newActivities, ...rest } = data;
      if (existingData) {
        activities = [...existingData];
        (data.data || []).forEach(activity => {
          let index = findIndex(activity, {
            id: activity.id,
          });
          if (index > -1) {
            activities[index] = activity;
          } else {
            activities.push(activity);
          }
        });
      }
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          status: 1,
        },
        activities: {
          ...state.activities,
          data: {
            ...get(state, 'activities.data', {}),
            data: [...activities],
            ...rest,
          },
        },
      };
    }
    case Types.FETCH_MORE_PROFILE_ACTIVITIES_FAILURE:
      return {
        ...state,
        moreActivities: {
          ...state.moreActivities,
          isInProgress: false,
          isError: true,
          message: action.message,
        },
      };
    case Types.UPDATE_ACTIVE_TOUR_STATE:
      return {
        ...state,
        quickStartTour: {
          ...state.quickStartTour,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
