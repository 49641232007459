import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Link } from 'react-router-dom';
import CardsHeader from 'components/Headers/CardsHeader';
import BaseTable from 'components/Table';
import classNames from 'classnames';
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Label,
  Badge,
} from 'reactstrap';
import numeral from 'numeral';
import NoProjectsImg from 'assets/img/theme/No_Projects_List.png';
import classes from './Studies.module.scss';
import {
  fetchProjectsList as fetchProjects,
  deleteProject,
  getProject as getProjectAction,
  duplicateProject,
} from 'store/actions/projects';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import history from 'helpers/history';
import useGetFieldFromObjects from 'helpers/useGetFieldFromObject';
import { updateActiveTourStatus } from 'store/actions/profile';
import {
  useAccess,
  permissions,
  showUnAuhtorizedError,
  useGetAvailablePermission,
} from 'helpers/permission';
import analytics, { analyticsConstants } from 'helpers/analytics';
import Helmet from 'components/Helmet';

const Studies = () => {
  const dispatch = useDispatch();
  if (!useAccess([permissions.LIST_PROJECTS])) {
    showUnAuhtorizedError();
  }
  const auth = useSelector(({ auth }) => auth);
  const assignedProjects = get(auth, 'user.assigned_initiatives', []);

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState({
    dataField: 'name',
    order: 'desc',
  });
  const analyticsSendEvent = ({ ...rest }) => {
    analytics.sendEvent({
      category: analyticsConstants.category.projects,
      ...rest,
    });
  };

  const projects = useGetFieldFromObjects(
    'project',
    'projectState.data.data',
    []
  );
  const loading = useGetFieldFromObjects(
    'project',
    'projectState.isInProgress',
    false
  );
  const projectMetadata = useGetFieldFromObjects(
    'project',
    'projectState.data.meta',
    {}
  );
  const isClient = useSelector(({ auth }) => auth.user.is_client);
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [viewMode, setViewMode] = useState(null);

  useEffect(() => {
    analyticsSendEvent({
      action: analyticsConstants.action.view_projects_list,
    });
  }, []);

  const confirmDelete = async data => {
    const sort =
      sortBy.order === 'desc' ? `-${sortBy.dataField}` : sortBy.dataField;
    analyticsSendEvent({ action: analyticsConstants.action.delete_project });
    await dispatch(deleteProject(data.id, { page, sort, q: '' }));
  };

  const quickStartTour = useSelector(({ profile }) =>
    get(profile, 'quickStartTour', {})
  );

  const editProject = ({ id }) => {
    dispatch(getProjectAction(id));
    setViewMode('edit');
    setShowProjectForm(true);
  };

  const viewProject = ({ id }) => {
    if (isClient) {
      history.push(`/admin/studies/${id}`);
    } else {
      history.push(`/admin/studies/${id}/dashboard`);
    }
  };

  const deleteProjectConfirm = row => () => {
    AlertPopupHandler.open({
      onConfirm: confirmDelete,
      confirmBtnText: 'Delete Study',
      text: `You are about to delete "${row.name}". Do you want to continue?`,
      data: row,
    });
  };

  const handleDuplicate = row => async () => {
    if (isLoading) return;
    setIsLoading(true);
    await dispatch(duplicateProject(row.id));
    setIsLoading(false);
  };

  const openAddProject = () => {
    setShowProjectForm(true);
  };

  const handleProjectClick = () => {
    if (
      quickStartTour &&
      quickStartTour.activeTour &&
      (quickStartTour.activeTour === 'invite_team' ||
        quickStartTour.activeTour === 'story_creation') &&
      quickStartTour.step === 1 &&
      assignedProjects.length
    ) {
      setTimeout(() => {
        dispatch(
          updateActiveTourStatus({
            step: 5,
          })
        );
      }, 1000);
    } else if (
      quickStartTour &&
      quickStartTour.activeTour &&
      quickStartTour.activeTour === 'view_projects'
    ) {
      dispatch(
        updateActiveTourStatus({
          step: null,
          activeTour: null,
        })
      );
    }
  };

  const isUserAllowedCreateProjects = useAccess(permissions.CREATE_PROJECTS);

  const isUserMemberOfProjects = useMemo(
    () => map(projects, project => includes(assignedProjects, project.id)),
    [projects, assignedProjects]
  );

  const isUserAllowedViewProject = useGetAvailablePermission(
    permissions.VIEW_ALL_PROJECTS,
    permissions.VIEW_MY_PROJECTS,
    isUserMemberOfProjects
  );

  const isUserAllowedEditProject = useGetAvailablePermission(
    permissions.EDIT_ALL_PROJECTS,
    permissions.EDIT_MY_PROJECTS,
    isUserMemberOfProjects
  );

  const isUserAllowedDeleteProject = useGetAvailablePermission(
    permissions.DELETE_ALL_PROJECTS,
    permissions.DELETE_MY_PROJECTS,
    isUserMemberOfProjects
  );

  const NoDataIndication = () => (
    <div
      className={classNames('d-flex align-items-center', classes.noDataWrapper)}
    >
      <div className="d-flex justify-content-between align-items-center flex-column w-100">
        <img
          className={classNames('mx-auto w-100', classes.image)}
          src={NoProjectsImg}
          alt="No Studies"
        />
        {isUserAllowedCreateProjects ? (
          <>
            <h4 className="display-4 mb-0 text-center px-2">
              Go ahead and create your first project!
            </h4>
            <p className="text-center">
              To add a study, click on the{' '}
              <Label
                type="sm"
                className={classNames(
                  'text-info p-0 m-0',
                  classes['add-project-label']
                )}
                color="link"
                onClick={openAddProject}
              >
                Add Study
              </Label>{' '}
              button in the upper right corner.
            </p>
          </>
        ) : (
          <h4 className="display-4 mb-0 text-center px-2 pb-2">
            You don't have any projects assigned yet!
          </h4>
        )}
      </div>
    </div>
  );

  const renderSortCaret = order => {
    if (!order) return <i className="fas fa-sort" />;
    else if (order === 'asc') return <i className="fas fa-sort-up" />;
    else if (order === 'desc') return <i className="fas fa-sort-down" />;
    return null;
  };

  return (
    <>
      <Helmet title={`${isClient ? 'Client' : 'Strike'} Portal - Studies`} />
      <div className={classes.projects}>
        <CardsHeader name="Studies" parentName="Studies" isRoot />
        <div className="px-4" id="projectsList">
          <Card>
            <CardBody className="p-0">
              <BaseTable
                id="projectsTable"
                keyField="id"
                defaultSorted={[sortBy]}
                noDataIndication={NoDataIndication}
                bootstrap4
                remote
                bordered={false}
                loading={loading}
                search={true}
                paginationOptions={{
                  page,
                  totalSize: projectMetadata.total,
                  sizePerPage: numeral(
                    get(projectMetadata, 'per_page', 50)
                  ).value(),
                }}
                data={projects}
                columns={[
                  {
                    dataField: 'name',
                    text: 'Study',
                    sort: true,
                    classes: classes['project-name-column'],
                    sortCaret: renderSortCaret,
                    formatter: (cell, row, index) => (
                      <div
                        className={classNames(
                          'd-flex justify-content-between w-100 align-items-center',
                          classes['project-name']
                        )}
                        title={cell}
                      >
                        {isUserAllowedViewProject[index] ? (
                          <Link
                            onClick={handleProjectClick}
                            to={
                              isClient
                                ? `/admin/studies/${row.id}`
                                : `/admin/studies/${row.id}/dashboard`
                            }
                          >
                            {cell}
                          </Link>
                        ) : (
                          <>{cell}</>
                        )}
                      </div>
                    ),
                  },
                  {
                    dataField: 'status',
                    text: 'Status',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row) => (
                      <div className="d-flex justify-content-between w-100 align-items-center">
                        <Badge className="badge-dot mr-4" color="">
                          <i
                            style={{
                              backgroundColor: get(cell, 'color')
                                ? get(cell, 'color')
                                : '',
                            }}
                          />
                          {row?.pending_for_deletion && (
                            <Badge color="danger" pill>
                              Pending Deletion
                            </Badge>
                          )}
                          {!row?.pending_for_deletion && (
                            <span className={classes['project-status']}>
                              {get(cell, 'status')}
                            </span>
                          )}
                        </Badge>
                      </div>
                    ),
                  },
                  {
                    dataField: 'engagement',
                    text: 'Engagement',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: cell => {
                      const engagement = get(cell, 'name');
                      return (
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          {engagement ? engagement : '-'}
                        </div>
                      );
                    },
                  },
                  {
                    dataField: 'client',
                    text: 'Client',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: cell => {
                      const client = get(cell, 'name');
                      return (
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          {client ? client : '-'}
                        </div>
                      );
                    },
                  },
                  {
                    dataField: 'owner',
                    text: 'Owner',
                    sort: true,
                    sortCaret: renderSortCaret,
                    formatter: (cell, row, index) => {
                      const userId = get(cell, 'id', '');
                      const imageLink = get(cell, 'avatar', '');
                      const userName = get(cell, 'name');
                      return (
                        <div className="d-flex justify-content-between w-100 align-items-center">
                          {userId ? (
                            <>
                              <img
                                id={`project-owner-${userId}`}
                                alt="..."
                                className="avatar avatar-sm rounded-circle"
                                src={imageLink}
                              />
                              {userName && (
                                <UncontrolledTooltip
                                  delay={0}
                                  placement="bottom"
                                  target={`project-owner-${userId}`}
                                >
                                  {userName}
                                </UncontrolledTooltip>
                              )}
                            </>
                          ) : (
                            <div
                              className={classNames(
                                'avatar-sm avatar text-lg d-flex rounded-circle align-items-center justify-content-center',
                                classes['empty-avatar']
                              )}
                            >
                              <span className="fa fa-user text-white" />
                            </div>
                          )}
                        </div>
                      );
                    },
                  },
                ]}
                onTableChange={(
                  type,
                  { page, sortOrder, sortField, searchText, ...rest }
                ) => {
                  if (type === 'pagination') {
                    setPage(page);
                  } else if (type === 'sort') {
                    setSortBy({
                      dataField: sortField,
                      order: sortOrder,
                    });
                  } else if (type === 'search') {
                    analyticsSendEvent({
                      action: analyticsConstants.action.search_projects_list,
                    });
                  }
                  const correctSortFieldName =
                    sortField === 'owner' ? 'owner_name' : sortField;
                  const sort =
                    sortOrder === 'desc'
                      ? `-${correctSortFieldName}`
                      : correctSortFieldName;
                  dispatch(fetchProjects(page, sort, searchText));
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Studies;
