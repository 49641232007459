import React, { useEffect, useState } from 'react';
import {
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Row,
  Col,
  Badge,
} from 'reactstrap';
import cx from 'classnames';
import get from 'lodash/get';
import ProgressBar from 'components/Fulfillment/ProgressBar';
import ExpenseCard from './ExpenseCard';
import Overview from './Overview';
import classes from './FulfillmentStudyDashboard.module.scss';
import { getDashboardMessages } from 'store/actions/userDashboard';
import { fetchDocumentStudyTypes } from 'store/actions/documents';
import { useParams, useHistory, useLocation } from 'react-router';
import { ReactComponent as BackIcon } from 'assets/img/icons/chevron-left-icon.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getProject as getProjectAction } from 'store/actions/projects';
import Interactions from './Interactions';
import SourceDocuments from './SourceDocuments';
import Loading from 'components/Loading';
import { formatDate } from 'helpers/times';
import Button from 'components/Button';
import KickoffModal from './KickoffModal';
import queryString from 'query-string';
import Tabs from 'components/Tabs';
import Helmet from 'components/Helmet';
import DocumentDetails from 'components/Fulfillment/Modals/DocumentCommentModal/DocumentDetails';
import { ReactComponent as TaxDeadline } from 'assets/img/icons/tax-deadline.svg';
import { ReactComponent as CreditEstimate } from 'assets/img/icons/credit-estimate.svg';
import { ReactComponent as GatherDocs } from 'assets/img/icons/gather-docs.svg';
import EditStudyModal from 'components/Fulfillment/Modals/EditStudyModal/EditStudyModal';
import { withCurrency } from 'helpers/constants';

const FulfillmentStudyDashboard = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [isRefresh, setIsRefresh] = useState(false);
  const [openKickoffModal, setOpenKickoffModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggleKickoffModal = () => setOpenKickoffModal(!openKickoffModal);
  const toggleStudyModal = () => setIsOpen(!isOpen);
  const [years, setYears] = useState([]);
  const dispatch = useDispatch();

  const params = useParams();
  const id = get(params, 'id');
  const query = queryString.parse(search);

  const tab = query?.tab || 'activity_feed'; //study_documents

  const currentStudy = useSelector(({ project }) =>
    get(project, 'getProject.data.data', null)
  );
  const yearsOfEngagement = get(currentStudy, 'engagement_years');
  const currentStudyLoading = useSelector(({ project }) =>
    get(project, 'getProject.isInProgress', false)
  );
  const studyInteractionsCount = useSelector(({ fulfillment }) =>
    get(fulfillment, 'studyInteractions.data.unresolved_count', 0)
  );

  const phases = get(currentStudy, 'phases', null);

  const projectPhase = get(currentStudy, 'project_phase', null);
  const name = get(currentStudy, 'name', '');
  const currentUser = useSelector(({ auth }) => get(auth, 'user'));

  const client = get(currentStudy, 'client');

  const taxDeadline = get(currentStudy, 'filing_deadline');
  const documentGatherBy = get(currentStudy, 'documents_gather_by');
  const creditEstimate = get(currentStudy, 'current_credit_estimate');
  const dateFormat = 'MMM DD, YYYY';
  const [modalParams, setModalParams] = useState({
    parent_type: 'Initiative',
    parent_id: id,
  });
  const [currentTab, setCurrentTab] = useState('comment');
  const [activeComment, setActiveComment] = useState(null);
  useEffect(() => {
    const yearsData = yearsOfEngagement?.split('-') || [];
    let yearsArray = [];
    if (yearsData && yearsData.length > 1)
      for (let i = yearsData[0]; i <= yearsData[1]; i++)
        yearsArray.push(parseInt(i));
    else if (yearsData.length > 0) yearsArray.push(parseInt(yearsData[0]));
    setYears(yearsArray);
    // console.log('years', years);
  }, [yearsOfEngagement]);
  const handleDocAndYearStatusUpdate = () => {
    setIsRefresh(true);
    dispatch(getProjectAction(id));
  };
  useEffect(() => {
    dispatch(getProjectAction(id));
    dispatch(getDashboardMessages('list'));
    dispatch(fetchDocumentStudyTypes(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const tabs = [
    {
      id: 'activity_feed',
      label: (
        <span className="d-flex align-items-center">
          Activity Feed{' '}
          {studyInteractionsCount > 0 && (
            <Badge className={classes.headerBadge}>
              {studyInteractionsCount > 99 ? '99+' : studyInteractionsCount}
            </Badge>
          )}
        </span>
      ),
      onClick: () => history.push(`${pathname}?tab=activity_feed`),
    },
    {
      id: 'study_documents',
      label: <span>Study Documents</span>,
      onClick: () => history.push(`${pathname}?tab=study_documents`),
    },
    {
      id: 'internal_chat',
      label: <span>Internal Chat</span>,
      onClick: () => history.push(`${pathname}?tab=internal_chat`),
    },
  ];

  if (currentStudyLoading && !isRefresh) {
    return <Loading />;
  }

  return (
    <>
      <Helmet title={'Strike-Portal - View Study'} />
      {openKickoffModal ? (
        <KickoffModal
          size="lg"
          backdrop={['static']}
          scrollable
          fade={false}
          title={'ssss'}
          toggle={toggleKickoffModal}
          isOpen={openKickoffModal}
          className={classes.modalWrapper}
          headerClassName={classes.modalHeader}
        >
          hi
        </KickoffModal>
      ) : null}
      {isOpen ? (
        <EditStudyModal
          isOpen={isOpen}
          toggle={toggleStudyModal}
          years={years.map(year => year)}
          parentId={currentStudy.id}
          studyName={currentStudy.name}
        />
      ) : null}
      <Container className={classes.container} fluid>
        <div className={classes.headerWrapper}>
          <div className={classes.header}>
            {currentUser?.is_fulfilment && (
              <BackIcon
                className={classes.leftIcon}
                onClick={() => {
                  if (history.length > 1) {
                    history.goBack();
                  } else {
                    history.push('/admin/dashboard');
                  }
                }}
              />
            )}
            <div className={classes.studyName}>{name ? name : '-'}</div>{' '}
            <Breadcrumb
              className={cx(
                'd-none d-md-inline-block ml-md-4',
                classes.breadcrumb
              )}
              listClassName="breadcrumb-links"
            >
              <BreadcrumbItem>
                <div style={{ display: 'flex' }}>
                  <a href="/admin/dashboard">
                    <i className={cx(classes.homeIcon, 'fas fa-home')} />
                  </a>
                  <span className={classes.dashIcon}>&nbsp; - &nbsp;</span>
                  <div className={classes.rightStudyName}>
                    {name ? name : '-'}
                  </div>
                  <div
                    className={cx(classes.editBtn, 'ml-2')}
                    onClick={toggleStudyModal}
                  >
                    Edit
                  </div>
                </div>
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <Button
            onClick={() => {
              history.push(`/admin/accounts/${client?.id}/overview`);
            }}
            className={classes.goToClient}
            color="secondary"
          >
            Go to Client
          </Button>
        </div>

        <ProgressBar
          phases={phases}
          projectPhase={projectPhase?.name}
          isFulfillment
        />
        <Card className={classes.card}>
          <CardBody className={classes.cardBody}>
            <Row className={classes.row}>
              <Col className={cx('col-12 col-lg-9 ', classes.content)}>
                <div className={classes.expenseCardWrapper}>
                  <ExpenseCard
                    title="Gather Docs By"
                    value={formatDate(documentGatherBy, dateFormat)}
                    color="#1da1f2"
                    icon={<GatherDocs />}
                  />
                  <ExpenseCard
                    title="Filing Deadline Date"
                    value={formatDate(taxDeadline, dateFormat)}
                    color="#1da1f2"
                    icon={<TaxDeadline />}
                  />
                  <ExpenseCard
                    title="Credit Estimate"
                    value={
                      <span>
                        {creditEstimate
                          ? `${withCurrency(creditEstimate, {
                              minimumFractionDigits: 0,
                            })}`
                          : '-'}
                      </span>
                    }
                    color="#1da1f2"
                    icon={<CreditEstimate />}
                  />
                </div>
                <div className={classes.contentWrapper}>
                  <Tabs
                    navClassName={classes.tabs}
                    tabs={tabs}
                    activeTab={tab}
                  />
                  <TabContent className={classes.content} activeTab={tab}>
                    <TabPane
                      tabId={'activity_feed'}
                      key={0}
                      tag={Container}
                      fluid
                      className={classes.tabWrapper}
                    >
                      <Interactions activeTab={tab} />
                    </TabPane>
                    <TabPane
                      tabId={'study_documents'}
                      key={1}
                      tag={Container}
                      fluid
                      className={classes.tabWrapper}
                    >
                      <SourceDocuments
                        handleDocAndYearStatusUpdate={
                          handleDocAndYearStatusUpdate
                        }
                      />
                    </TabPane>
                    <TabPane
                      tabId={'internal_chat'}
                      key={2}
                      tag={Container}
                      fluid
                      className={classes.tabWrapper}
                    >
                      <DocumentDetails
                        initiativeId={id}
                        params={modalParams}
                        parentId={modalParams.parent_id}
                        userType={'client'}
                        currentTab={currentTab}
                        comment={activeComment}
                        onTabChange={() => {}}
                        analyticsSendEvent={() => {}}
                        chatTitle={'Internal Messages'}
                        isStudyAssigned={true}
                        chatType={'internal'}
                        showNavBar={false}
                      />
                    </TabPane>
                  </TabContent>
                </div>
              </Col>

              <Col className={cx('col-12 col-lg-3', classes.tab)}>
                {/* <div className={classes.kickoffAction}>
                  <h3>Next Step</h3>
                  <Button
                    onClick={() => setOpenKickoffModal(true)}
                    color="primary"
                    className={classes.button}
                  >
                    Update Study Phase
                  </Button>
                </div> */}
                <Overview />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default FulfillmentStudyDashboard;
