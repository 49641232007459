import createTypes from 'redux-create-action-types';

export default createTypes(
  'CREATE_STORY_FROM_QUICK_ACTIONS_INPROGRESS',
  'CREATE_STORY_FROM_QUICK_ACTIONS_SUCCESS',
  'CREATE_STORY_FROM_QUICK_ACTIONS_FAILURE',
  'CREATE_PROJECT_FROM_QUICK_ACTIONS_INPROGRESS',
  'CREATE_PROJECT_FROM_QUICK_ACTIONS_SUCCESS',
  'CREATE_PROJECT_FROM_QUICK_ACTIONS_FAILURE',
  'CREATE_CLIENT_TASK_FROM_QUICK_ACTION_INPROGRESS',
  'CREATE_CLIENT_TASK_FROM_QUICK_ACTION_SUCCESS',
  'CREATE_CLIENT_TASK_FROM_QUICK_ACTION_FAILURE'
);
