import Button from 'components/Button';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import classes from './NotesContainer.module.scss';
import Notes from '../Notes/Notes';
import Modal from 'components/FormFields/Modal';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { fetchNotesList } from 'store/actions/accountOverview';

const NotesContainer = ({ setAddEditNoteModal, clientId }) => {
  const dispatch = useDispatch();
  const notes = useSelector(({ notes }) => get(notes, 'getNotes.data', []));
  const [showAllNotes, setShowAllNotes] = useState(false);
  const closeModal = () => {
    setShowAllNotes(false);
  };
  useEffect(() => {
    dispatch(fetchNotesList(clientId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={classes.notesCard}>
      <CardHeader className={classes.notesHeader}>
        <div className={classes.title}>Internal Client Notes</div>
        <Button
          className={classes.addNoteBtn}
          color="primary"
          onClick={() => {
            setAddEditNoteModal({ isOpen: true, note: null });
          }}
        >
          Add Note
        </Button>
      </CardHeader>
      <CardBody
        className={cx(classes.cardBody, {
          [classes.noNoteBody]: notes?.length === 0,
        })}
      >
        {notes?.length === 0 ? (
          <div className={classes.noNotes}>There are No Notes Available</div>
        ) : (
          <Notes
            clientId={clientId}
            setAddEditNoteModal={setAddEditNoteModal}
          />
        )}
      </CardBody>
      <Button
        onClick={() => setShowAllNotes(true)}
        className={classes.allNotesButton}
        disabled={notes?.length === 0}
      >
        View All Notes
      </Button>
      {showAllNotes && (
        <Modal
          className={classes.allNotesModal}
          isOpen={showAllNotes}
          toggle={closeModal}
          title="Internal Client Notes"
        >
          <div className={classes.modalBody}>
            <Notes
              clientId={clientId}
              setAddEditNoteModal={setAddEditNoteModal}
            />
          </div>
        </Modal>
      )}
    </Card>
  );
};

export default NotesContainer;
